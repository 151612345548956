import { FormEvent, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  emailRegex,
  TextInput,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import Checkbox from 'src/shared/components/Checkbox/Checkbox';
import PasswordInput from 'src/shared/components/inputs/PasswordInput/PasswordInput';
import { paths } from 'src/shared/routes';

import { useLazySignUpQuery } from 'src/features/auth/api';
import Captcha from 'src/features/auth/components/Captcha/Captcha';
import { changeSignUpData } from 'src/features/auth/slices/authSlice';
import { useGetPresignedUrlsQuery } from 'src/features/minIo/api';
import { PresignedUrlByKeyMapId } from 'src/features/minIo/models';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';

import './SignUp.scss';

const MAX_LENGTH = 500;
const MIN_PASSWORD_LENGTH = 8;
const MAX_PASSWORD_LENGTH = 71;

const POLICY_KEYS = ['ppp-privacy-policy.pdf'];

enum INPUT {
  NAME = 'sign-up__input--name',
  EMAIL = 'sign-up__input--email',
  PASSWORD = 'sign-up__input--password',
}

type SignUpError = {
  reason: string | null;
  exists: boolean;
};

const INITIAL_ERROR: SignUpError = { reason: null, exists: false };

const SignUp = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'signUp' });

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmed, setConfirmation] = useState<boolean>(false);
  const [captchaPassed, setCaptchaPassed] = useState<boolean>(false);

  const [nameError, setNameError] = useState<SignUpError>(INITIAL_ERROR);
  const [emailError, setEmailError] = useState<SignUpError>(INITIAL_ERROR);
  const [passwordError, setPasswordError] =
    useState<SignUpError>(INITIAL_ERROR);
  const [agreementError, setAgreementError] =
    useState<SignUpError>(INITIAL_ERROR);

  const submitButtonEnabled = useMemo(
    () =>
      confirmed &&
      captchaPassed &&
      !nameError.exists &&
      !emailError.exists &&
      !passwordError.exists &&
      name.trim().length &&
      email.trim().length &&
      password.length >= MIN_PASSWORD_LENGTH,
    [
      confirmed,
      captchaPassed,
      email,
      emailError.exists,
      name,
      nameError.exists,
      password.length,
      passwordError.exists,
    ]
  );

  const ERRORS = {
    [INPUT.NAME]: { error: nameError, setter: setNameError },
    [INPUT.EMAIL]: { error: emailError, setter: setEmailError },
    [INPUT.PASSWORD]: { error: passwordError, setter: setPasswordError },
  };

  const toggleConfirmation = () =>
    setConfirmation((p) => {
      setAgreementError({ reason: null, exists: p });
      return !p;
    });

  const [signUp] = useLazySignUpQuery();

  const policyLink =
    useGetPresignedUrlsQuery({
      messageMapId: PresignedUrlByKeyMapId.PRIVACY_POLICY,
      keys: POLICY_KEYS,
    }).data?.data[0]?.url || '/';

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (submitButtonEnabled) {
      signUp({
        name: name.trim(),
        email: email.trim(),
        password,
      }).then((result) => {
        if (result.isSuccess) {
          dispatch(changeSignUpData({ email, password, name }));
          isSmallScreen
            ? navigate(paths.confirm)
            : dispatch(changeModal(MODALS.CONFIRMATION));
        }
      });
    }
  };

  const navigateSignIn = () => {
    isSmallScreen
      ? navigate(paths.signIn, { replace: true })
      : dispatch(changeModal(MODALS.SIGN_IN));
  };

  const onChangeName = (value: string) => {
    setNameError((prev) => ({ ...prev, reason: null }));
    setName(value);
  };

  const onChangeEmail = (value: string) => {
    setEmailError((prev) => ({ ...prev, reason: null }));
    setEmail(value);
  };

  const onChangePassword = (value: string) => {
    setPasswordError((prev) => ({ ...prev, reason: null }));
    setPassword(value);
  };

  const onCaptchaVerified = () => setCaptchaPassed(true);

  const checkForEmptiness = (target: EventTarget & HTMLInputElement) => {
    const id = target.id as INPUT;
    if (!target.value.trim()) {
      ERRORS[id].setter({ reason: t('required'), exists: true });
      return true;
    } else {
      ERRORS[id].setter({ reason: null, exists: false });
      return false;
    }
  };

  const resetErrorState = (target: EventTarget & HTMLInputElement) => {
    const id = target.id as INPUT;
    ERRORS[id].setter((prev) => ({ ...prev, reason: null }));
  };

  const validateEmail = (target: EventTarget & HTMLInputElement) => {
    const empty = checkForEmptiness(target);
    if (empty) return;

    const valid = emailRegex.test(target.value);
    if (valid) {
      setEmailError(INITIAL_ERROR);
    } else {
      setEmailError({ reason: t('errors.email'), exists: true });
    }
  };

  const validatePassword = (target: EventTarget & HTMLInputElement) => {
    const empty = checkForEmptiness(target);
    if (!empty && target.value.length < MIN_PASSWORD_LENGTH) {
      setPasswordError({ reason: t('errors.password'), exists: true });
    }
  };

  const dynamicClass = (s: TemplateStringsArray, id?: INPUT) => {
    const errorReason = id ? ERRORS[id].error.reason : null;
    return errorReason ? `${s[0]} ${s[0]}--error` : s[0];
  };

  const renderError = (e: SignUpError) => (
    <div className={`sign-up__error-container${e.exists ? '--visible' : ''}`}>
      {e.exists ? (
        <span className="sign-up__error-text">{e.reason}</span>
      ) : null}
    </div>
  );

  return (
    <div className="sign-up">
      <form className="sign-up__form" onSubmit={onFormSubmit}>
        <TextInput
          value={name}
          id={INPUT.NAME}
          maxLength={MAX_LENGTH}
          className={dynamicClass`sign-up__input${INPUT.NAME}`}
          placeholder={t('placeholders.name')}
          onChange={(e) => onChangeName(e.target.value)}
          onFocus={(e) => resetErrorState(e.target)}
          onFocusLeave={(e) => checkForEmptiness(e.target)}
        />
        {renderError(nameError)}

        <TextInput
          value={email}
          id={INPUT.EMAIL}
          maxLength={MAX_LENGTH}
          className={dynamicClass`sign-up__input${INPUT.EMAIL}`}
          placeholder={t('placeholders.email')}
          onChange={(e) => onChangeEmail(e.target.value)}
          onFocus={(e) => resetErrorState(e.target)}
          onFocusLeave={(e) => validateEmail(e.target)}
        />
        {renderError(emailError)}

        <PasswordInput
          value={password}
          id={INPUT.PASSWORD}
          maxLength={MAX_PASSWORD_LENGTH}
          className={
            ' sign-up__input-password ' +
            dynamicClass`sign-up__input${INPUT.PASSWORD}`
          }
          placeholder={t('placeholders.password')}
          onChange={(e) => onChangePassword(e.target.value)}
          onFocus={(e) => resetErrorState(e.target)}
          onFocusLeave={(e) => validatePassword(e.target)}
        />
        {renderError(passwordError)}

        <div className="sign-up__agreement">
          <Checkbox
            id="user-agreement"
            checked={confirmed}
            label={
              <Trans
                i18nKey="signUp.agreement"
                components={{
                  lnk: (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      className="text-link"
                      rel="noreferrer"
                      href={policyLink}
                      target="_blank"
                    />
                  ),
                }}
              />
            }
            className={
              agreementError.exists ? 'sign-up__agreement-wrapper--error' : ''
            }
            inputClassName="sign-up__agreement-checkbox"
            labelClassName="sign-up__agreement-label"
            onChange={toggleConfirmation}
          />
        </div>

        <Captcha className="sign-up__captcha" onSuccess={onCaptchaVerified} />

        <Button
          submit
          disabled={!submitButtonEnabled}
          className="auth-button sign-up__button"
          type={BUTTON_TYPE.ACCENTED}
        >
          {t('buttons.signUp')}
        </Button>
      </form>

      <div className="sign-up__have-account">
        <Trans
          i18nKey="signUp.buttons.navSignIn"
          components={{
            btn: (
              <Button
                type={BUTTON_TYPE.TEXT}
                className="sign-up__have-account-link"
                onClick={navigateSignIn}
              />
            ),
          }}
        />
      </div>
    </div>
  );
};

export default SignUp;
