import { WMSTileLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';

import { CONTROL } from 'src/features/control/slices/controlSlice';
import { MAP } from 'src/features/map/slices/mapSlice';
import { State } from 'src/features/store/store';

const PARAMS = {
  layers: 'show:27,24,23,22',
  dpi: 96,
  transparent: true,
  format: 'png32',
  f: 'image',
  bboxSR: 102100,
  imageSR: 102100,
  tiled: true,
  size: '1024,1024',
  request: '',
  service: '',
};

const PKK_URL =
  'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreObjects/MapServer/export';
const MANUAL_URL =
  'https://' +
  window.location.host +
  '/pkk/arcgis/rest/services/PKK6/CadastreOriginal/MapServer/export';

const CadasterLayer = (): JSX.Element | null => {
  const accessability = sessionStorage.getItem('pkkAccessability') === 'true';

  const background = useSelector((s: State) => s[MAP].background);
  const cadastralVisible = useSelector(
    (s: State) => s[CONTROL].showCadastralLayer
  );

  let resultUrl = MANUAL_URL;
  const found = window.navigator.userAgent.match('YaBrowser');
  if (accessability || (found && found.length > 0)) {
    resultUrl = PKK_URL;
  }

  return cadastralVisible ? (
    <WMSTileLayer
      key={background}
      url={resultUrl}
      params={PARAMS}
      maxZoom={20}
    />
  ) : null;
};

export default CadasterLayer;
