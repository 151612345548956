import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import {
  Bar,
  BarChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';
import { HorizontalAlignmentType } from 'recharts/types/component/DefaultLegendContent';

import { PaymentsStatisticData } from 'src/features/payment/models';

import { TickObject } from '../../models';

import ReportingChartBottomLabel from '../ReportingChartBottomLabel/ReportingChartBottomLabel';
import ReportingChartLabel from '../ReportingChartLabel/ReportingChartLabel';
import ReportingChartLegend from '../ReportingChartLegend/ReportingChartLegend';
import ReportingChartTooltip from '../ReportingChartTooltip/ReportingChartTooltip';

import './ReportingBarChart.scss';

interface ReportingBarChartProps {
  data: PaymentsStatisticData[];
  className?: string;
  isSmallScreen?: boolean;
}

const ReportingBarChart = ({
  data,
  className = '',
  isSmallScreen = false,
}: ReportingBarChartProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'reporting' });

  // adding months if they haven't returned from the backend
  const reportingData = useMemo(
    () =>
      Array.from({ length: 12 }, (_, index) => {
        const element = data.find((e) => e.month === index + 1);
        return element ?? { month: index + 1, platform: 0, projects: 0 };
      }),
    [data]
  );

  return (
    <div className={clsx('reporting-bar-chart', className)}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={reportingData}
          margin={{ top: 20 }}
          barCategoryGap="15%"
        >
          <XAxis
            dataKey="month"
            tick={(tickObject: TickObject) => (
              <ReportingChartBottomLabel tickObject={tickObject} data={data} />
            )}
            axisLine={false}
          />
          <Tooltip cursor={false} content={<ReportingChartTooltip />} />
          <Legend
            layout="vertical"
            verticalAlign="top"
            align={
              (isSmallScreen ? 'middle' : 'left') as HorizontalAlignmentType
            }
            content={<ReportingChartLegend />}
          />
          <Bar
            dataKey="platform"
            name={t('platform')}
            stackId="a"
            fill="var(--tag-purple)"
          />
          <Bar
            dataKey="projects"
            name={t('projects')}
            stackId="a"
            fill="var(--green-main)"
            minPointSize={1}
          >
            {!isSmallScreen ? (
              <LabelList
                position="top"
                content={<ReportingChartLabel responseData={data} />}
              />
            ) : (
              <></>
            )}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
export default ReportingBarChart;
