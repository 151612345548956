import { useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';

// Quill Emoji are exist only in js implementation. (without types wrapper)
// So for now I disable typescript warning for emoji import.
//
// For more infromation check issue: https://github.com/zenoamaro/react-quill/issues/828
//
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Emoji from 'quill-emoji';

import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import './TextEditor.scss';

Quill.register('modules/emoji', Emoji);

export interface TextEditorProps {
  className: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  maxLength?: number;
}

const HTML_TAG_REGEX = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
const ACCEPTED_KEYS = ['Control', 'Backspace', 'Delete'];
const TOOLBAR_OPTIONS = [
  [{ header: [1, 2, 3, false] }],
  ['bold', 'italic', 'link'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['emoji'],
  ['clean'],
];

const TextEditor = ({
  className = '',
  placeholder,
  value,
  onChange,
  onBlur = () => null,
  maxLength,
}: TextEditorProps) => {
  const reactQuillRef = useRef<ReactQuill>(null);

  return (
    <div className={`quill-text-editor-wrapper ${className}`} onBlur={onBlur}>
      <ReactQuill
        className="quill-text-editor"
        ref={reactQuillRef}
        placeholder={placeholder}
        modules={{
          toolbar: {
            container: TOOLBAR_OPTIONS,
          },
          'emoji-toolbar': true,
          'emoji-textarea': false,
          'emoji-shortname': true,
        }}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={(event) => {
          if (
            maxLength &&
            value.replace(HTML_TAG_REGEX, '').length > maxLength &&
            !ACCEPTED_KEYS.includes(event.key)
          ) {
            event.preventDefault();
          }
        }}
      />
    </div>
  );
};

export default TextEditor;
