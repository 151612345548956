import { useTranslation } from 'react-i18next';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { REQUEST_STATUS } from 'src/features/request/enums';
import { Tag } from 'src/features/request/models';

import './ProfileParticipationsTableRow.scss';

export interface ProfileParticipationsTableContent {
  date: string;
  project: { id: string; name: string };
  requestStatus: {
    status: REQUEST_STATUS;
    message?: string;
  };
  requestBody: {
    tags: Tag[];
    message?: string;
  };
  refuseAction: () => void;
}

export interface ProfileParticipationsTableRowProps {
  data: ProfileParticipationsTableContent;
  className?: string;
}

const ProfileParticipationsTableRow = ({
  data,
  className,
}: ProfileParticipationsTableRowProps) => {
  const { t } = useTranslation('app', {
    keyPrefix: 'profile.participations.row',
  });

  const renderStatus = (status: REQUEST_STATUS, message?: string) => {
    return (
      <span
        className={`profile-participations-table-row__status_${data.requestStatus.status.toLowerCase()}`}
      >
        <p>
          {t(status.toLowerCase())}
          {status === REQUEST_STATUS.REJECTED && message !== '' ? (
            <>
              {` ${t('rejectedWithComment')}`}
              <p>{`«${message}»`}</p>
            </>
          ) : null}
        </p>
      </span>
    );
  };

  const renderBody = (tags: Tag[], message?: string) => {
    return (
      <span className="profile-participations-table-row__request-body">
        <p>
          <b className="profile-participations-table-row__request-body_bold">
            {`${t('role')} `}
          </b>
          {tags.map((r, index) => {
            return `${r.name}${tags.length - 1 > index ? ', ' : ''}`;
          })}
        </p>

        {message ? (
          <>
            <p>
              <b className="profile-participations-table-row__request-body_bold">
                {`${t('comment')} `}
              </b>
            </p>
            <p>{message}</p>
          </>
        ) : (
          <p className="profile-participations-table-row__request-body_bold">
            {t('commentNotFound')}
          </p>
        )}
      </span>
    );
  };

  return (
    <div className={`profile-participations-table-row ${className}`}>
      <span
        className={`profile-participations-table-row__date_${data.requestStatus.status.toLowerCase()}`}
      >
        {data.date}
      </span>

      <Button
        className={`profile-participations-table-row__project-name_${data.requestStatus.status.toLowerCase()}`}
        onClick={() =>
          window.open(paths.projectById(data.project.id), '_blank')
        }
        type={BUTTON_TYPE.TEXT_SECONDARY}
      >
        <p>{data.project.name}</p>
      </Button>

      {renderStatus(data.requestStatus.status, data.requestStatus.message)}

      {renderBody(data.requestBody.tags, data.requestBody.message)}

      <Button
        className="profile-participations-table-row__project-refuce-action"
        onClick={data.refuseAction}
        type={BUTTON_TYPE.TEXT_SECONDARY}
        icon
        fill
      >
        <Icon icon={IconType.Cross} />
        {t('refuse')}
      </Button>
    </div>
  );
};

export default ProfileParticipationsTableRow;
