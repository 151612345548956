import { Trans } from 'react-i18next';

import { Icon, IconType } from '@platform-for-public-places/components-library';

import { PKK_URL } from 'src/features/pkk/api/pkkApi';

import './PkkDoesNotResponseInfo.scss';

export interface PkkDoesNotResponseInfoProps {
  className: string;
}

const PkkDoesNotResponseInfo = ({
  className = '',
}: PkkDoesNotResponseInfoProps) => {
  return (
    <div className={`pkk-does-not-response-info ${className}`}>
      <Icon
        className="pkk-does-not-response-info__icon"
        icon={IconType.Error}
      />
      <p className="pkk-does-not-response-info__text">
        <Trans
          i18nKey="onlyMapLayout.pkkDoesNotResponse"
          components={{
            lnk: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                className="pkk-does-not-response-info__text--link"
                href={PKK_URL}
                rel="noreferrer"
                target="_blank"
              />
            ),
          }}
        />
      </p>
    </div>
  );
};

export default PkkDoesNotResponseInfo;
