import Control from 'react-leaflet-custom-control';
import { useSelector } from 'react-redux';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { StatusFilterMobileControls } from 'src/features/control/components/StatusFilterMobileControls/StatusFilterMobileControls';
import ZoomControl from 'src/features/control/components/ZoomControl/ZoomControl';
import { CONTROL } from 'src/features/control/slices/controlSlice';
import DrawControl from 'src/features/draw/components/DrawControl/DrawControl';
import { DRAW } from 'src/features/draw/slices/drawSlice';
import { State } from 'src/features/store/store';

const BottomLeftControl = () => {
  const isSmallScreen = useCheckSmallScreen();
  const isDrawEnable = useSelector((s: State) => s[DRAW].isDrawEnable);
  const mobileMenuOpen = useSelector((s: State) => s[CONTROL].mobileMenuOpen);

  return (
    <Control position={'bottomleft'}>
      {isSmallScreen ? (
        <>
          {!(isDrawEnable || mobileMenuOpen) ? (
            <StatusFilterMobileControls />
          ) : null}
        </>
      ) : (
        <>
          <DrawControl />
          <ZoomControl />
        </>
      )}
    </Control>
  );
};

export default BottomLeftControl;
