import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from 'src/app/images/logo.svg';
import Burger from 'src/shared/components/Burger/Burger';
import { paths } from 'src/shared/routes';

import MobileSidebar from '../MobileSidebar/MobileSidebar';

const MobileHeaderWithLogo = () => {
  return (
    <header className="mobile-header">
      <Link
        to={paths.frontPage}
        className="mobile-header__link"
        title={process.env.REACT_APP_VERSION}
      >
        <Logo className="mobile-header__logo" />
      </Link>
      <Burger className="burger__menu">
        <MobileSidebar />
      </Burger>
    </header>
  );
};

export default MobileHeaderWithLogo;
