export const toShortNumber = (value: number, locale = 'ru') =>
  Intl.NumberFormat(locale, {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(value);

export const toShortCurrencyNumber = (
  value: number,
  locale = 'ru',
  currency = 'RUB'
) =>
  Intl.NumberFormat(locale, {
    style: 'currency',
    notation: 'compact',
    maximumFractionDigits: 1,
    currency,
  }).format(value);

export const formatNumber = (value: number, locale = 'ru') =>
  value < 1000000
    ? value.toLocaleString().replace(',', ' ')
    : toShortNumber(value, locale);

export const truncateZeroDecimal = (value?: number) =>
  (value ?? 0) % 1 === 0 ? 0 : 2;
