import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  ErrorResponse,
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import { useCheckIfUserAuthorized } from 'src/features/hooks';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import {
  changeProjectJoined,
  setProjectCountOfJoined,
} from 'src/features/project/slices/projectsLayerSlice';
import { useChangePlanForEventMutation } from 'src/features/reaction/api';

import './JoinButton.scss';

const ANIMATION_DURATION = 1000;

interface JoinButtonProps {
  projectId: string;
  changeJoinLocal: () => void;
  disabled?: boolean;
  wasJoined?: boolean;
  className?: string;
  showText?: boolean;
  showCount?: boolean;
  countOfJoined: number;
}

const JoinButton = ({
  projectId,
  changeJoinLocal,
  disabled = false,
  wasJoined = false,
  className = '',
  showText = true,
  showCount = true,
  countOfJoined,
}: JoinButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSmallScreen = useCheckSmallScreen();

  const [buttonExtraClassName, setButtonExtraClassName] = useState('');
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  const { isSuccess: whoamiSuccess } = useWhoamiQuery();

  const [changePlanForEvent, { isLoading: isChangePlanLoading }] =
    useChangePlanForEventMutation();

  const toggleJoin = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(undefined);
      setButtonExtraClassName('');
    } else if (!isChangePlanLoading) {
      changePlanForEvent({ projectId }).then((response) => {
        if (!(response as ErrorResponse)?.error) {
          setButtonExtraClassName(
            wasJoined
              ? 'join-button--clicked-unjoin'
              : 'join-button--clicked-join'
          );
          setTimeoutId(
            setTimeout(() => {
              setButtonExtraClassName('');
              setTimeoutId(undefined);
            }, ANIMATION_DURATION)
          );
          dispatch(changeProjectJoined());
          dispatch(
            setProjectCountOfJoined(
              wasJoined ? countOfJoined - 1 : countOfJoined + 1
            )
          );
          changeJoinLocal();
        }
      });
    }
  };

  const requestIJoin = useCheckIfUserAuthorized(toggleJoin);

  const onJoinClick = () => {
    requestIJoin().then(() => {
      if (!whoamiSuccess) {
        isSmallScreen
          ? navigate(paths.signIn)
          : dispatch(changeModal(MODALS.SIGN_IN));
      }
    });
  };

  const renderText = () => {
    return t('detailedProject.join');
  };

  const renderCount = () => {
    if (countOfJoined === 0) {
      return '';
    } else {
      return countOfJoined;
    }
  };

  return isSmallScreen ? (
    <Button
      className={`join-button ${buttonExtraClassName} ${className}`}
      type={BUTTON_TYPE.SECONDARY}
      onClick={onJoinClick}
      disabled={disabled}
    >
      {showText ? renderText() : null}
      <Icon
        className={`join-button__icon${
          wasJoined ? ' join-button__icon--joined' : ''
        }`}
        icon={wasJoined ? IconType.HandFilled : IconType.HandUnfilled}
      />
      {showCount ? renderCount() : null}
    </Button>
  ) : (
    <Button
      className={`join-button ${buttonExtraClassName} ${className}`}
      type={BUTTON_TYPE.PRIMARY}
      onClick={onJoinClick}
      disabled={disabled}
    >
      {showText ? renderText() : null}
      <Icon
        className={`join-button__icon${
          wasJoined ? ' join-button__icon--joined' : ''
        }`}
        icon={wasJoined ? IconType.HandFilled : IconType.HandUnfilled}
      />
      {showCount ? renderCount() : null}
    </Button>
  );
};

export default JoinButton;
