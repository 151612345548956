import { useState } from 'react';

import { randNumber, randUuid } from '@ngneat/falso';
import {
  ActorBaseResponse,
  FakeResponse,
} from '@platform-for-public-places/components-library';

import { fetchMock } from 'src/shared/mock/utils';

import {
  CreatePaymentRequest,
  CreatePaymentResponse,
  CreateProjectBudgetRequest,
  CreateProjectBudgetResponse,
  GetDonationDetailsRequest,
  GetDonationDetailsResponse,
  GetPaymentsStatisticRequest,
  GetPaymentsStatisticResponse,
  GetProjectEstimationRequest,
  GetProjectEstimationResponse,
  GetRecurrentUserPaymentsRequest,
  GetRecurrentUserPaymentsResponse,
  GetTotalAmountUserPaymentsResponse,
  GetUserPaymentRequest,
  GetUserPaymentResponse,
  NewPaymentConfirmationRedirect,
  PaymentConfirmationRedirect,
  PaymentConfirmationType,
  PaymentStatus,
} from '../models';

interface FakeGetPaymentsStatisticResponse extends FakeResponse {
  data: ActorBaseResponse<GetPaymentsStatisticResponse>;
}

interface FakeGetDonationDetailsResponse extends FakeResponse {
  data: ActorBaseResponse<GetDonationDetailsResponse>;
}

interface FakeGetProjectEstimationResponse extends FakeResponse {
  data: ActorBaseResponse<GetProjectEstimationResponse>;
  refetch: () => void;
}

interface FakeCreateProjectBudgetResponse extends FakeResponse {
  data: ActorBaseResponse<CreateProjectBudgetResponse>;
}

interface FakeCreatePaymentResponse extends FakeResponse {
  data: ActorBaseResponse<
    Partial<CreatePaymentResponse<PaymentConfirmationRedirect>>
  >;
}

interface FakeGetUserPaymentResponse extends FakeResponse {
  data: ActorBaseResponse<GetUserPaymentResponse>;
  isLoading: boolean;
}

interface FakeGetRecurrentUserPaymentsResponse extends FakeResponse {
  data: ActorBaseResponse<GetRecurrentUserPaymentsResponse>;
}

interface FakeGetTotalAmountUserPaymentsResponse extends FakeResponse {
  data: ActorBaseResponse<GetTotalAmountUserPaymentsResponse>;
}

export const useLazyGetPaymentsStatisticQuery = (): [
  (
    request: GetPaymentsStatisticRequest
  ) => Promise<FakeGetPaymentsStatisticResponse>,
  FakeGetPaymentsStatisticResponse
] => {
  const [response, setResponse] = useState<FakeGetPaymentsStatisticResponse>({
    isSuccess: false,
    isFetching: true,
    isError: false,
    data: {
      data: {
        paymentsStatistic: [],
      },
      success: true,
      errors: [],
    },
  });

  const trigger = (request: GetPaymentsStatisticRequest) =>
    new Promise<FakeGetPaymentsStatisticResponse>((resolve) => {
      fetchMock(
        `payments-statistic/get-payments-statistic-${request.page}.json`
      )
        .then((data) => {
          const resp = {
            isSuccess: true,
            isFetching: false,
            isError: false,
            data,
          };
          setResponse(resp);
          resolve(response);
        })
        .catch(() => {
          resolve(response);
          console.warn(
            `payments-statistic/get-payments-statistic-${request.page}.json: file does not exist`
          );
        });
    });

  return [trigger, response];
};

export const useGetDonationDetailsQuery = (
  request: GetDonationDetailsRequest
): FakeGetDonationDetailsResponse => {
  const [response, setResponse] = useState<FakeGetDonationDetailsResponse>({
    isSuccess: false,
    isFetching: true,
    isError: false,
    data: {
      data: {
        paymentDetails: {
          budget: 0,
          projectId: '',
          donationTotal: 0,
          link: undefined,
          name: undefined,
        },
      },
      success: true,
      errors: [],
    },
  });

  if (request.projectId && response.isFetching) {
    fetchMock('donation/get-project-payment-details.json').then((data) => {
      setResponse({
        isSuccess: true,
        isFetching: false,
        isError: false,
        data,
      });
    });
  }

  return response;
};

export const useLazyGetDonationDetailsQuery = (): [
  (
    request: GetDonationDetailsRequest
  ) => Promise<FakeGetDonationDetailsResponse>,
  FakeGetDonationDetailsResponse
] => {
  const [response, setResponse] = useState<FakeGetDonationDetailsResponse>({
    isSuccess: false,
    isFetching: true,
    isError: false,
    data: {
      data: {
        paymentDetails: {
          budget: 0,
          projectId: '',
          donationTotal: 0,
          link: undefined,
          name: undefined,
        },
      },
      success: true,
      errors: [],
    },
  });

  const trigger = () =>
    new Promise<FakeGetDonationDetailsResponse>((resolve) => {
      fetchMock('donation/get-project-payment-details.json')
        .then((data) => {
          const resp = {
            isSuccess: true,
            isFetching: false,
            isError: false,
            data,
          };
          setResponse(resp);
          resolve(response);
        })
        .catch(() => {
          resolve(response);
          console.log('file does not exist');
        });
    });

  return [trigger, response];
};

export const useGetProjectEstimationQuery = (
  request: GetProjectEstimationRequest
): FakeGetProjectEstimationResponse => {
  const [response, setResponse] = useState<FakeGetProjectEstimationResponse>({
    isSuccess: false,
    isFetching: true,
    isError: false,
    refetch: () => null,
    data: {
      data: {
        estimation: {
          budget: 0,
          projectId: '',
          link: '',
        },
      },
      success: true,
      errors: [],
    },
  });

  if (request.projectId && response.isFetching) {
    fetchMock('get-project-estimation.json').then((data) => {
      setResponse({
        isSuccess: true,
        isFetching: false,
        isError: false,
        refetch: () => null,
        data,
      });
    });
  }

  return response;
};

export const useLazyCreateProjectBudgetQuery = (): [
  (
    request: CreateProjectBudgetRequest
  ) => Promise<FakeCreateProjectBudgetResponse>,
  FakeCreateProjectBudgetResponse
] => {
  const [response, setResponse] = useState<FakeCreateProjectBudgetResponse>({
    isSuccess: false,
    isError: false,
    isFetching: true,
    data: {
      data: {
        projectId: '',
        budget: 0,
      },
      success: false,
      errors: [],
    },
  });

  const trigger = () => {
    const resp = {
      isSuccess: true,
      isError: false,
      isFetching: false,
      data: {
        data: {
          projectId: randUuid(),
          budget: randNumber({ min: 0.1, max: 10000 }),
        },
        success: true,
        errors: [],
      },
    };
    setResponse(resp);
    return Promise.resolve(resp);
  };

  return [trigger, response];
};

export const useLazyCreatePaymentQuery = (): [
  (
    request: CreatePaymentRequest<NewPaymentConfirmationRedirect>
  ) => Promise<FakeCreatePaymentResponse> & {
    unwrap: () => Promise<
      ActorBaseResponse<
        Partial<CreatePaymentResponse<PaymentConfirmationRedirect>>
      >
    >;
  },
  FakeCreatePaymentResponse
] => {
  const [response, setResponse] = useState<FakeCreatePaymentResponse>({
    data: {
      data: {},
      success: true,
      errors: [],
    },
    isSuccess: false,
    isFetching: true,
    isError: false,
  });

  const trigger = (
    request: CreatePaymentRequest<NewPaymentConfirmationRedirect>
  ) => {
    if (!response.isSuccess) {
      const _response: FakeCreatePaymentResponse = {
        data: {
          data: {
            payment: {
              ...request,
              id: randUuid(),
              status: PaymentStatus.PENDING,
              created_at: new Date(performance.now()).toISOString(),
              test: true,
              paid: false,
              refundable: false,
              confirmation: {
                type: PaymentConfirmationType.REDIRECT,
                confirmation_url: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
              },
              recipient: {
                account_id: '1234',
                gateway_id: '1234',
              },
            },
          },
          success: true,
          errors: [],
        },
        isSuccess: true,
        isFetching: false,
        isError: false,
      };

      setResponse(_response);
      return {
        ...Promise.resolve(_response),
        unwrap: () =>
          response.data ? Promise.resolve(_response.data) : Promise.reject(),
      };
    } else {
      return {
        ...Promise.resolve(response),
        unwrap: () =>
          response.data ? Promise.resolve(response.data) : Promise.reject(),
      };
    }
  };

  return [trigger, response];
};

export const useLazyGetUserPaymentsQuery = (): [
  (request: GetUserPaymentRequest) => Promise<FakeGetUserPaymentResponse> & {
    unwrap: () => Promise<ActorBaseResponse<GetUserPaymentResponse>>;
  },
  FakeGetUserPaymentResponse
] => {
  const [response, setResponse] = useState<FakeGetUserPaymentResponse>({
    isSuccess: false,
    isFetching: true,
    isLoading: false,
    isError: false,
    data: {
      data: {
        aggregators: { count: 0 },
        payments: [],
      },
      success: true,
      errors: [],
    },
  });
  const trigger = (request: GetUserPaymentRequest) => {
    if (!response.isSuccess) {
      const fetchResponse = fetchMock(
        `user-payments/get-user-payments-${request.page}.json`
      ).then((data: ActorBaseResponse<GetUserPaymentResponse>) => {
        const resp = {
          isLoading: false,
          isSuccess: true,
          isFetching: false,
          isError: false,
          data,
        };
        setResponse(resp);
        return Promise.resolve(resp);
      });
      return {
        ...Promise.resolve(fetchResponse),
        unwrap: () => Promise.resolve(fetchResponse.then((r) => r.data)),
      };
    } else {
      return {
        ...Promise.resolve(response),
        unwrap: () => Promise.resolve(response.data),
      };
    }
  };

  return [trigger, response];
};

export const useLazyGetRecurrentUserPaymentsQuery = (): [
  (
    request: GetRecurrentUserPaymentsRequest
  ) => Promise<FakeGetRecurrentUserPaymentsResponse> & {
    unwrap: () => Promise<ActorBaseResponse<GetRecurrentUserPaymentsResponse>>;
  },
  FakeGetRecurrentUserPaymentsResponse
] => {
  const [response, setResponse] =
    useState<FakeGetRecurrentUserPaymentsResponse>({
      isSuccess: false,
      isFetching: true,
      isError: false,
      data: {
        data: {
          aggregators: { count: 0 },
          recurrentPayments: [],
        },
        success: true,
        errors: [],
      },
    });
  const trigger = (request: GetRecurrentUserPaymentsRequest) => {
    if (!response.isSuccess) {
      const fetchResponse = fetchMock(
        `recurrent-user-payments/get-recurrent-user-payments-${request.page}.json`
      ).then((data: ActorBaseResponse<GetRecurrentUserPaymentsResponse>) => {
        const resp = {
          isSuccess: true,
          isFetching: false,
          isError: false,
          data,
        };
        setResponse(resp);
        return Promise.resolve(resp);
      });
      return {
        ...Promise.resolve(fetchResponse),
        unwrap: () => Promise.resolve(fetchResponse.then((r) => r.data)),
      };
    } else {
      return {
        ...Promise.resolve(response),
        unwrap: () => Promise.resolve(response.data),
      };
    }
  };

  return [trigger, response];
};

export const useGetTotalAmountUserPaymentsQuery =
  (): FakeGetTotalAmountUserPaymentsResponse => {
    const [response, setResponse] =
      useState<FakeGetTotalAmountUserPaymentsResponse>({
        isSuccess: false,
        isFetching: true,
        isError: false,
        data: {
          data: {
            total: 0,
          },
          success: true,
          errors: [],
        },
      });

    if (response.isFetching) {
      fetchMock('user-payments/get-total-amount-user-payments.json').then(
        (data) => {
          setResponse({
            isSuccess: true,
            isFetching: false,
            isError: false,
            data,
          });
        }
      );
    }

    return response;
  };

export const useStopSupportingRecurrentPaymentMutation = (): [
  () => Promise<ActorBaseResponse<void>> & {
    unwrap: () => Promise<ActorBaseResponse<void>>;
  },
  FakeResponse
] => {
  const [response] = useState<FakeResponse>({
    isSuccess: false,
    isFetching: false,
    isError: false,
  });

  const trigger = (): Promise<ActorBaseResponse<void>> & {
    unwrap: () => Promise<ActorBaseResponse<void>>;
  } => {
    return {
      ...Promise.resolve({
        data: undefined,
        success: true,
        errors: [],
      }),
      unwrap: () =>
        Promise.resolve({
          data: undefined,
          success: true,
          errors: [],
        }),
    };
  };

  return [trigger, response];
};

export const useLazyRequestAccessToRecurrentPaymentsQuery = (): [
  () => Promise<ActorBaseResponse<void>> & {
    unwrap: () => Promise<ActorBaseResponse<void>>;
  },
  FakeResponse
] => {
  const [response] = useState<FakeResponse>({
    isSuccess: false,
    isFetching: false,
    isError: false,
  });

  const trigger = (): Promise<ActorBaseResponse<void>> & {
    unwrap: () => Promise<ActorBaseResponse<void>>;
  } => {
    return {
      ...Promise.resolve({
        data: undefined,
        success: true,
        errors: [],
      }),
      unwrap: () =>
        Promise.resolve({
          data: undefined,
          success: true,
          errors: [],
        }),
    };
  };

  return [trigger, response];
};
