import { BaseRequestData } from '../request/models';

export enum COMMENT_UPDATING_STAGE {
  NONE,
  PENDING,
  FINISHED,
}

export interface CreateCommentRequest {
  projectId: string;
  text: string;
  name: string;
}

export interface CreateCommentResponse {
  commentId: string;
  requestId: string;
}

export interface Comment {
  approved: boolean;
  name: string;
  text: string;
  project_commentID: string;
  userId: string;
  projectId: string;
  createdAt: string; // ISO 8601 + 0000
  updatedAt: string; // ISO 8601 + 0000
}

export interface CommentRequestBody {
  projectName: string;
  comment: Comment;
}

export interface CommentAndRequest {
  comment: Comment;
  request?: BaseRequestData<CommentRequestBody>;
}

export interface GetCommentsRequest {
  projectId: string;
  pageSize: number;
  page: number;
  archived?: boolean;
}

export interface GetCommentsResponse {
  aggregators: { count: number }; // count of approved
  comments: CommentAndRequest[];
}

export interface UpdateCommentRequest {
  text: string;
  commentId: string;
}

export interface UpdateCommentResponse {
  requestId: string;
}

export interface DeleteCommentRequest {
  commentId: string;
}
