import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  MessageRequestForm,
} from '@platform-for-public-places/components-library';

import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import { MembersIncomingRowProps } from '../../models';

import './MembersIncomingRow.scss';

const MembersIncomingRow = ({ data, className }: MembersIncomingRowProps) => {
  const projectType = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );

  const { t } = useTranslation('app', {
    keyPrefix: `creation.${projectType}.team`,
  });

  const [showDeclineForm, setShowDeclineForm] = useState(false);

  const onSubmit = () => {
    data.actions.reject();
    setShowDeclineForm(false);
  };

  const onCancel = () => setShowDeclineForm(false);

  return (
    <div className={`members-incoming-row ${className}`}>
      <span className="members-incoming-row__item">{data.date}</span>

      <Button
        id="link"
        className="members-incoming-row__item"
        type={BUTTON_TYPE.TEXT_DARK}
        onClick={data.specialist.expand}
      >
        {data.specialist.name}
      </Button>

      <div className="members-incoming-row__item" id="list">
        {data.roles.map((r, i) => (
          <span key={`${i}-${r}`}>{r}</span>
        ))}
      </div>

      <span className="members-incoming-row__item">
        {data.comment || t('noComment')}
      </span>

      {showDeclineForm ? (
        <MessageRequestForm
          className="members-incoming-row__decline-form"
          placeholder={t('declineComment')}
          message={data.message?.data || ''}
          setMessage={data.message?.setData || (() => null)}
          onSendClick={onSubmit}
          onCancelClick={onCancel}
        />
      ) : (
        <div className="members-incoming-row__item" id="actions">
          <Button
            className="actions__approve"
            type={BUTTON_TYPE.TEXT}
            onClick={data.actions.approve}
          >
            <Icon icon={IconType.CheckmarkTiny} />
          </Button>
          <Button
            className="actions__reject"
            type={BUTTON_TYPE.TEXT}
            onClick={() => setShowDeclineForm(true)}
          >
            <Icon icon={IconType.Cross} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default MembersIncomingRow;
