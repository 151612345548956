import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { useLazyLogoutQuery, useWhoamiQuery } from 'src/features/auth/api';
import { USER_ROLE } from 'src/features/auth/models';
import { useCheckUser } from 'src/features/hooks';

import './ProfilePane.scss';

interface ProfilePanProps {
  onClose: () => void;
}

const ProfilePane = ({ onClose }: ProfilePanProps): JSX.Element => {
  const navigate = useNavigate();
  const isAdmin = useCheckUser([{ role: USER_ROLE.ADMIN }]);
  const { data, isSuccess } = useWhoamiQuery();
  const [requestLogout] = useLazyLogoutQuery();
  const { t } = useTranslation('app', { keyPrefix: 'profilePan' });

  const username = useMemo(() => {
    if (isSuccess && data.data.profile?.name) {
      return data.data.profile.name;
    } else {
      return '-';
    }
  }, [isSuccess, data?.data.profile?.name]);

  const toProfile = () => {
    navigate(paths.profile);
    onClose();
  };

  const logout = () => {
    onClose();
    requestLogout().then(() => {
      navigate(paths.map);
      document.location.reload();
    });
  };

  return (
    <>
      <div className="profile-pane">
        <h3 className="profile-pane__username">{username}</h3>
        <Button
          className="profile-pane__profile"
          type={BUTTON_TYPE.TEXT}
          onClick={toProfile}
        >
          {t('profile')}
        </Button>
        {isAdmin ? (
          <Button
            className="profile-pane__profile"
            type={BUTTON_TYPE.TEXT}
            onClick={() => window.location.assign(paths.admin)}
          >
            {t('admin')}
          </Button>
        ) : null}
        <Button
          className="profile-pane__logout"
          type={BUTTON_TYPE.TEXT}
          onClick={logout}
        >
          <Icon icon={IconType.Arrow} />
          {t('logout')}
        </Button>
      </div>
      <button className="profile-pane--overlay" onClick={onClose} />
    </>
  );
};

export default ProfilePane;
