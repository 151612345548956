import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  PROJECT_STATUS,
  Status,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import {
  useGetConfirmedParticipantsInfoQuery,
  useGetEventParticipantsInfoQuery,
} from 'src/features/project/api';
import {
  changeProjectJoined,
  setProjectCountOfJoined,
  setProjectJoined,
} from 'src/features/project/slices/projectsLayerSlice';
import JoinButton from 'src/features/reaction/components/JoinButton/JoinButton';

import { useEventParticipants } from 'src/pages/project/DetailedProjectInfo/component/hooks';

import EventParticipantsList, {
  TYPE_RENDER,
} from './EventParticipantsList/EventParticipantsList';

export interface EventParticipantsProps {
  archived?: boolean;
  projectId: string;
  isAdmin: boolean;
  displayInitiator?: boolean;
  isInitiatorProjectOwner: boolean;
  initiatorName?: string;
  initiatorId?: string;
  onUserClick?: (userId: string) => void;
  emptyTeamLabel?: string;
  memberButtonComponent?: (memberId: string) => JSX.Element;
  memberContainerClassName?: string;
  hideTeamMembers?: boolean;
  previewMemberIdByRedirect?: string;
  status: Status;
}

const EventParticipants = ({
  archived,
  projectId,
  isAdmin,
  displayInitiator = true,
  isInitiatorProjectOwner,
  initiatorName,
  initiatorId,
  onUserClick,
  previewMemberIdByRedirect = '',
  status,
}: EventParticipantsProps) => {
  const dispatch = useDispatch();
  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'detailedProject' });

  const { data: eventParticipantsInfo, refetch: refetchEventParticipantsInfo } =
    useGetEventParticipantsInfoQuery(
      { projectId: projectId as string, archived },
      { skip: !projectId }
    );
  const {
    data: confirmedParticipantsInfo,
    refetch: refetchConfirmedParticipantsInfo,
  } = useGetConfirmedParticipantsInfoQuery(
    { projectId: projectId as string, archived },
    { skip: !projectId }
  );

  const [membersMore, setMembersMore] = useState(false);
  const [wasJoined, setWasJoined] = useState<boolean>(
    eventParticipantsInfo?.data.joinedToEventInfo === null
      ? false
      : !!eventParticipantsInfo?.data.joinedToEventInfo.joined
  );
  const [countOfJoined, setCountOfJoined] = useState<number>(
    eventParticipantsInfo?.data.joinedToEventInfo === null
      ? 0
      : eventParticipantsInfo?.data.joinedToEventInfo.countOfJoined ?? 0
  );
  const [countOfConfirmed, setCountOfConfirmed] = useState<number>(
    confirmedParticipantsInfo?.data.confirmedToEventInfo === null
      ? 0
      : confirmedParticipantsInfo?.data.confirmedToEventInfo.countOfConfirmed ??
          0
  );

  const {
    hasNextPage,
    isLoading,
    participants,
    participantsCatalog,
    refetchParticipants,
  } = useEventParticipants({
    archived,
    projectId,
    isSmallScreen,
    status,
  });

  useEffect(() => {
    refetchConfirmedParticipantsInfo();
    if (eventParticipantsInfo) {
      setWasJoined(eventParticipantsInfo.data.joinedToEventInfo.joined);
      setCountOfJoined(
        eventParticipantsInfo.data.joinedToEventInfo.countOfJoined
      );
      dispatch(
        setProjectJoined(eventParticipantsInfo.data.joinedToEventInfo.joined)
      );
      dispatch(
        setProjectCountOfJoined(
          eventParticipantsInfo.data.joinedToEventInfo.countOfJoined
        )
      );
    }
  }, [dispatch, eventParticipantsInfo, refetchConfirmedParticipantsInfo]);

  useEffect(() => {
    if (confirmedParticipantsInfo) {
      setCountOfConfirmed(
        confirmedParticipantsInfo.data.confirmedToEventInfo.countOfConfirmed
      );
    }
  }, [confirmedParticipantsInfo]);

  const renderInitiator = () => {
    return (
      <button
        className="member__block member__block-initiator"
        onClick={() => initiatorId && onUserClick?.(initiatorId)}
      >
        <Trans
          i18nKey="detailedProject.initiatorMobile"
          values={{
            name: `${initiatorName || ''} ${
              isInitiatorProjectOwner ? t('you') : ''
            }`,
          }}
          components={{
            span: <span className="member__role" />,
          }}
        />
      </button>
    );
  };

  const changeJoinLocal = () => {
    setWasJoined((prev) => !prev);
    dispatch(changeProjectJoined());
    refetchEventParticipantsInfo();
    refetchParticipants?.();
  };

  return (
    <>
      {displayInitiator && isSmallScreen ? renderInitiator() : null}
      <Button
        type={BUTTON_TYPE.TEXT_DARK}
        onClick={() => {
          setMembersMore((prev) => !prev);
        }}
        icon
        fill
        className={`members__title members__button ${
          membersMore ? 'members__button--open' : ''
        }`}
        disabled={!isSmallScreen}
      >
        {isSmallScreen ? (
          <Trans
            i18nKey={
              status.value === PROJECT_STATUS.FINISHED
                ? 'detailedProject.confirmedParticipants'
                : 'detailedProject.possibleParticipants'
            }
            values={{
              count:
                status.value === PROJECT_STATUS.FINISHED
                  ? countOfConfirmed
                  : countOfJoined,
            }}
          />
        ) : (
          <Trans
            i18nKey={
              status.value === PROJECT_STATUS.FINISHED
                ? 'detailedProject.thanks'
                : 'detailedProject.supportWithDeeds'
            }
          />
        )}
        {isSmallScreen ? <Icon icon={IconType.Chevron} /> : null}
      </Button>
      <EventParticipantsList
        projectId={projectId}
        isAdmin={isAdmin}
        isSmallScreen={isSmallScreen}
        isInitiatorProjectOwner={isInitiatorProjectOwner}
        hideTeamMembers={isSmallScreen ? !membersMore : false}
        onUserClick={onUserClick}
        initiatorName={initiatorName}
        initiatorId={initiatorId}
        typeRender={
          isSmallScreen
            ? TYPE_RENDER.HIDE_TEAM_MEMBERS
            : TYPE_RENDER.PREVIEW_MEMBER_WITH_BUTTON_SHOW_MORE
        }
        displayInitiator={!isSmallScreen}
        previewTeamMembers={!isSmallScreen}
        previewMemberIdByRedirect={previewMemberIdByRedirect}
        hasParticipantsNextPage={hasNextPage}
        participants={participants}
        userCatalog={participantsCatalog}
        isLoading={isLoading}
        countOfJoined={countOfJoined}
        countOfConfirmed={countOfConfirmed}
        status={status}
      />
      {status.value !== PROJECT_STATUS.FINISHED && !isInitiatorProjectOwner ? (
        <JoinButton
          projectId={projectId}
          changeJoinLocal={changeJoinLocal}
          wasJoined={wasJoined}
          className="detailed-info__button--join"
          countOfJoined={countOfJoined}
        />
      ) : null}
    </>
  );
};

export default EventParticipants;
