import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { RequisitesData } from '../models';

export const REQUISITES_API = 'REQUISITES_API';

export const requisitesApi = createApi({
  reducerPath: REQUISITES_API,
  baseQuery: retry(
    fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URLS }),
    { maxRetries: 0 }
  ),
  endpoints: (builder) => ({
    getRequisites: builder.query<RequisitesData, string>({
      query: (url) => url,
    }),
  }),
});

export const { useGetRequisitesQuery } = requisitesApi;
export const requisitesMiddleware = requisitesApi.middleware;

export default requisitesApi.reducer;
