import { ProjectTeamRequests } from 'src/features/project/models';
import { REQUEST_STATUS } from 'src/features/request/enums';
import { ProjectSpecialist } from 'src/features/user/models';

import { EditingFooterProps } from './components/EditingFooter/EditingFooter';

export interface EditingOutletProps {
  renderFooter?: (props: EditingFooterProps) => JSX.Element;
}

export interface MembersTableProps {
  placeholder: React.ReactNode;
  data: ProjectTeamRequests;
  className?: string;
  requests?: [];
  requestAdditionalData: RequestAdditionalData;
  error?: boolean;
  infinite?: boolean;
  onEndReached?: () => void;
}

export interface RequestAdditionalData {
  message?: string;
  onError?: () => void;
  onReject?: (specialist: ProjectSpecialist) => void;
  onApprove?: (specialist: ProjectSpecialist) => void;
  setMessage?: (data: string) => void;
}

export interface MembersIncomingTableContent {
  date: string;
  specialist: {
    name: string;
    expand: () => void;
  };
  roles: string[];
  comment?: string;
  actions: {
    approve: () => void;
    reject: () => void;
  };
  message?: {
    data?: string;
    setData?: (data: string) => void;
  };
}

export interface MembersOutgoingTableContent {
  date: string;
  specialist: {
    name: string;
    expand: () => void;
  };
  roles: string[];
  status: {
    requestStatus: REQUEST_STATUS;
    comment?: string;
    revoke: () => void;
  };
}

export interface MembersRowProps {
  className?: string;
}

export interface MembersOutgoingRowProps extends MembersRowProps {
  data: MembersOutgoingTableContent;
}

export interface MembersIncomingRowProps extends MembersRowProps {
  data: MembersIncomingTableContent;
}

export enum MobileListSelector {
  TEAM,
  INCOMING,
  OUTGOING,
}

export enum TABLE_TAB {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}
