import { useSelector } from 'react-redux';

import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import SuccessfulCreationWidget from './widgets/SuccessfulCreationWidget';

const successfulCreationWidgetsMap = new Map<string, () => JSX.Element>([
  ['successfulCreationWidget', SuccessfulCreationWidget],
]);

const SuccessfulCreation = () => {
  const configCreationSteps = useSelector(
    (s: State) => s[PROJ_CREATE].configCreationSteps
  );
  const currentCreationStep = useSelector(
    (s: State) => s[PROJ_CREATE].currentCreationStep
  );

  const SuccessfulCreationWidget = successfulCreationWidgetsMap.get(
    configCreationSteps[currentCreationStep]?.type
  );

  return SuccessfulCreationWidget?.() || null;
};

export default SuccessfulCreation;
