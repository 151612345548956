import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import {
  BaseRequestData,
  RequestToProjectParticipantRequestBody,
} from 'src/features/request/models';

import './MoreInfoStatus.scss';

interface MoreInfoStatusProps {
  setBottomSheetVisible: (value: boolean) => void;
  data: BaseRequestData<RequestToProjectParticipantRequestBody>;
  status: JSX.Element;
  refuseAction: (request: string) => void;
}
const MoreInfoStatus = ({
  setBottomSheetVisible,
  data,
  status,
  refuseAction,
}: MoreInfoStatusProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('app', {
    keyPrefix: 'profile.participations.row',
  });

  return (
    <div className="more-info-status">
      <p className="more-info-status__comment">{status}</p>
      <button
        className="more-info-status__project"
        onClick={() => {
          navigate(
            paths.projectById(data.requestBody.projectSpecialist.projectId)
          );
        }}
      >
        <p>
          {`${t('project')} `}
          <span className="more-info-status__project-name">
            {data.requestBody.projectName}
          </span>
        </p>
      </button>

      <p className="more-info-status__role-name">
        {`${t('role')} `}
        <span>
          {data.requestBody.projectSpecialist.tags.map((r, index) => {
            return `${r.name}${
              data.requestBody.projectSpecialist.tags.length - 1 > index
                ? ', '
                : ''
            }`;
          })}
        </span>
      </p>
      <p className="more-info-status__user-comment">
        {`${t('comment')} `}
        <span>{data.requestBody.message}</span>
      </p>
      <Button
        onClick={() => {
          refuseAction(data.requestID);
          setBottomSheetVisible(false);
        }}
        type={BUTTON_TYPE.TEXT_SECONDARY}
        icon
        fill
      >
        <Icon icon={IconType.Cross} />
        {t('refuse')}
      </Button>
    </div>
  );
};

export default MoreInfoStatus;
