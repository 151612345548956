import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { COMMENT_UPDATING_STAGE, CommentAndRequest } from '../models';

export const COMMENT = 'COMMENT';

interface CommentState {
  newComment?: CommentAndRequest;
  updatingComment?: CommentAndRequest | null;
  updatingStage: COMMENT_UPDATING_STAGE;
}

const initialState: CommentState = {
  updatingStage: COMMENT_UPDATING_STAGE.NONE,
};

export const commentSlice = createSlice({
  name: COMMENT,
  initialState,
  reducers: {
    setNewComment: (
      state: CommentState,
      action: PayloadAction<CommentAndRequest>
    ) => {
      state.newComment = action.payload;
    },
    setUpdatingComment: (
      state: CommentState,
      action: PayloadAction<CommentAndRequest | null>
    ) => {
      state.updatingComment = action.payload;
    },
    setUpdatingStage: (
      state: CommentState,
      action: PayloadAction<COMMENT_UPDATING_STAGE>
    ) => {
      state.updatingStage = action.payload;
    },
    reset: (state: CommentState) => {
      state.newComment = initialState.newComment;
    },
  },
});

export const { setNewComment, setUpdatingComment, setUpdatingStage, reset } =
  commentSlice.actions;

export default commentSlice.reducer;
