import { useTranslation } from 'react-i18next';

import RecoveryPasswordSuccess from 'src/features/auth/components/RecoveryPasswordSuccess/RecoveryPasswordSuccess';

import AuthModal from '../AuthModal/AuthModal';

const RecoveryPasswordSuccessModal = () => {
  const { t } = useTranslation('app', { keyPrefix: 'recoveryPasswordSuccess' });

  return (
    <AuthModal header={t('header')}>
      <RecoveryPasswordSuccess />
    </AuthModal>
  );
};

export default RecoveryPasswordSuccessModal;
