import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';
import clsx from 'clsx';

import { changeDrawHint, DRAW } from 'src/features/draw/slices/drawSlice';
import { useCheckPkkAccessability, useClearCreation } from 'src/features/hooks';
import { ModalBase, ModalSmallScreen } from 'src/features/modal/ModalBase';
import { State } from 'src/features/store/store';

import Header from '../components/Header/Header';

import './BaseLayout.scss';

interface BaseLayoutProps {
  hideCreation?: boolean;
  hideFooter?: boolean;
}

const BaseLayout = ({
  hideCreation = false,
  hideFooter,
}: BaseLayoutProps): JSX.Element => {
  useCheckPkkAccessability();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const clearCreation = useClearCreation();
  const isSmallScreen = useCheckSmallScreen();
  const location = useLocation();

  const isDrawEnable = useSelector((s: State) => s[DRAW].isDrawEnable);

  const isHomePage = location.pathname === '/';

  useEffect(() => {
    dispatch(changeDrawHint(t('drawTool.firstPointTooltipCreating')));
  }, [dispatch, t]);

  useEffect(() => {
    clearCreation();
  }, [clearCreation]);

  const mainClassName = (className: TemplateStringsArray) => {
    const drawModifier = isDrawEnable
      ? `${className[0]} ${className[0]}--drawing`
      : className[0];
    return hideFooter
      ? `${drawModifier} ${className[0]}--fullscreen`
      : drawModifier;
  };

  return isSmallScreen ? (
    <>
      <main className={mainClassName`base-layout`}>
        <Outlet />
        <ModalSmallScreen />
      </main>
    </>
  ) : (
    <>
      <Header
        hideCreation={hideCreation}
        className={clsx({ 'fixed-header': isHomePage })}
      />
      <main className={mainClassName`base-layout`}>
        <Outlet />
        <ModalBase />
      </main>
    </>
  );
};

export default BaseLayout;
