import { filterToNumeric } from './components/filters/filter';
import { FormattedNumericInput } from './components/FormattedNumericInput';
import { formatTelephoneNumber } from './components/formatters/formatters';

interface TelephoneInputProps {
  numericValue: string;
  onNumericChange: (value: string) => void;
  className: string;
  placeholer: string;
}

export const TelephoneInput = ({
  numericValue,
  onNumericChange,
  className,
  placeholer,
}: TelephoneInputProps) => {
  return (
    <FormattedNumericInput
      className={className}
      filter={filterToNumeric}
      placeholer={placeholer}
      formatter={formatTelephoneNumber()}
      numericValue={numericValue}
      onNumericChange={onNumericChange}
    />
  );
};

export default TelephoneInput;
