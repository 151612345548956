import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';

import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import { REQUEST_STATUS } from 'src/features/request/enums';
import { State } from 'src/features/store/store';

import { MembersOutgoingRowProps } from '../../models';

import './MembersOutgoingRow.scss';

const MembersOutgoingRow = ({ data, className }: MembersOutgoingRowProps) => {
  const projectType = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );
  const { t } = useTranslation('app', {
    keyPrefix: `creation.${projectType}.team`,
  });

  const renderAwaitingStatus = () => (
    <span className="members-outgoing-row__status--awaiting">
      {t('status.active')}
    </span>
  );

  const renderRejectedStatus = () => (
    <span className="members-outgoing-row__status--rejected">
      {data.status.comment?.trim()
        ? t('status.rejected', { comment: data.status.comment })
        : t('status.rejectedSilent')}
    </span>
  );

  const statusRenderers = {
    [REQUEST_STATUS.ACTIVE]: renderAwaitingStatus,
    [REQUEST_STATUS.REJECTED]: renderRejectedStatus,
    [REQUEST_STATUS.EXECUTED]: () => null,
  };

  return (
    <div className={`members-outgoing-row ${className}`}>
      <span className="members-outgoing-row__item">{data.date}</span>

      <Button
        id="link"
        className="members-outgoing-row__item"
        type={BUTTON_TYPE.TEXT_DARK}
        onClick={data.specialist.expand}
      >
        {data.specialist.name}
      </Button>

      <div className="members-outgoing-row__item" id="list">
        {data.roles.map((r, i) => (
          <span key={`${i}-${r}`}>{r}</span>
        ))}
      </div>

      <div className="members-outgoing-row__item" id="status">
        {statusRenderers[data.status.requestStatus]()}
        <Button type={BUTTON_TYPE.TEXT} onClick={data.status.revoke}>
          <Icon icon={IconType.Cross} />
        </Button>
      </div>
    </div>
  );
};

export default MembersOutgoingRow;
