import { Circles } from 'react-loader-spinner';

import clsx from 'clsx';

import './Spinner.scss';

type SpinnerProps = {
  className?: string;
  visible?: boolean;
  fixed?: boolean;
};

const Spinner = ({ className, visible, fixed }: SpinnerProps) => {
  return (
    <Circles
      visible={visible}
      wrapperClass={clsx(className, 'spinner', { 'spinner--fixed': fixed })}
      color={'var(--green-main)'}
    />
  );
};

export default Spinner;
