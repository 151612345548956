import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import ProjectsLayer from 'src/features/map/components/ProjectsLayer/ProjectsLayer';
import { ProjectFilterOption } from 'src/features/searchWithFilter/filter/models';
import { FILTER } from 'src/features/searchWithFilter/filter/slices/filterSlice';
import { State } from 'src/features/store/store';

const ProjectListLayer = () => {
  const { allTypes, allStatus } = useSelector((s: State) => s[FILTER]);

  return useMemo(() => {
    return (
      <>
        {allTypes?.map((v) => {
          return (
            <ProjectsLayer
              key={v.id}
              type={v}
              statuses={allStatus?.find((f) => f.type === v.id)?.statuses}
            />
          );
        })}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTypes?.length, allStatus]);
};

export default ProjectListLayer;

// Next feature with archived (now not used)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ArchivedListLayer = () => {
  const archivedListType: ProjectFilterOption[] = [];
  return (
    <>
      {archivedListType.map((v) => {
        return <ProjectsLayer key={v.id} archived={true} type={v} />;
      })}
    </>
  );
};
