import { Navigate } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import SignUpForm from 'src/features/auth/components/SignUp/SignUp';
import { MODALS } from 'src/features/modal/models';

import './SignUp.scss';

const SignUp = () => {
  const isSmallScreen = useCheckSmallScreen();

  return isSmallScreen ? (
    <div className="sign-up-page">
      <SignUpForm />
    </div>
  ) : (
    <Navigate to={paths.map} replace state={{ modalState: MODALS.SIGN_UP }} />
  );
};

export default SignUp;
