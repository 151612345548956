import {
  NOTIFICATION_PAYLOAD,
  NOTIFICATION_TYPE,
} from '@platform-for-public-places/components-library';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const NOTIFICATION = 'NOTIFICATION';

interface NotificationState {
  isVisible: boolean;
  notification: NOTIFICATION_PAYLOAD;
}

const initialState: NotificationState = {
  isVisible: false,
  notification: {
    message: '',
    type: NOTIFICATION_TYPE.NOTIFICATION,
  },
};

export const notificationSlice = createSlice({
  name: NOTIFICATION,
  initialState,
  reducers: {
    setVisible: (state: NotificationState, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload;
    },
    setNotification: (
      state: NotificationState,
      action: PayloadAction<NOTIFICATION_PAYLOAD>
    ) => {
      state.notification = action.payload;
    },
  },
});

export const { setVisible, setNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
