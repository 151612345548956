import { useTranslation } from 'react-i18next';

import SignUp from 'src/features/auth/components/SignUp/SignUp';

import AuthModal from '../AuthModal/AuthModal';

const SignUpModal = () => {
  const { t } = useTranslation('app', { keyPrefix: 'signUp' });

  return (
    <AuthModal header={t('header')}>
      <SignUp />
    </AuthModal>
  );
};

export default SignUpModal;
