import { Navigate } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import ForgotPasswordSentPage from 'src/features/auth/components/ForgotPasswordSent/ForgotPasswordSent';

const ForgotPasswordSent = () => {
  const isSmallScreen = useCheckSmallScreen();

  return isSmallScreen ? (
    <div className="forgot-password-sent-page">
      <ForgotPasswordSentPage />
    </div>
  ) : (
    <Navigate to={paths.map} replace />
  );
};

export default ForgotPasswordSent;
