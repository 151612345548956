import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { AUTH } from 'src/features/auth/slices/authSlice';
import { State } from 'src/features/store/store';

import { ReactComponent as SuccessImage } from './img/success.svg';

import './Confirm.scss';

const Confirm = () => {
  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'confirmEmail' });

  const signUpData = useSelector((state: State) => state[AUTH].signUpData);

  return signUpData ? (
    <div className="confirm-email">
      {!isSmallScreen && (
        <h2 className="confirm-email__header">{t('header')}</h2>
      )}
      <p className="confirm-email__guidance">
        <Trans
          i18nKey="confirmEmail.guidance"
          values={{ email: signUpData.email }}
          components={{
            span: <span className="confirm-email__guidance-email" />,
          }}
        />
      </p>
      <SuccessImage className="confirm-email__success-image" />
    </div>
  ) : null;
};

export default Confirm;
