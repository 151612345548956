import { useSelector } from 'react-redux';

import Controls from 'src/features/control/Controls';
import DrawTool from 'src/features/draw/components/DrawTool/DrawTool';
import CadasterLayer from 'src/features/map/components/CadasterLayer/CadasterLayer';
import MapLayers from 'src/features/map/components/MapLayers/MapLayers';
import MapProjectZoom from 'src/features/map/components/MapProjectZoom/MapProjectZoom';
import MapStateUpdater from 'src/features/map/components/MapStateUpdater/MapStateUpdater';
import ProjectListLayer from 'src/features/map/components/ProjectsLayer/ProjectListLayer';
import { SearchPanelMap } from 'src/features/map/components/SearchPane/SearchPane';
import { MAP } from 'src/features/map/slices/mapSlice';
import CadasterPolygons from 'src/features/pkk/components/CadasterPolygons/CadasterPolygons';
import { State } from 'src/features/store/store';

import MapProvider from 'src/pages/main/Map/MapProvider';

import './Map.scss';

const Map = (): JSX.Element => {
  const background = useSelector((s: State) => s[MAP].background);
  return (
    <div className="map">
      <SearchPanelMap />
      <MapProvider>
        <Controls />
        <MapLayers layer={background} />
        <ProjectListLayer />
        <CadasterLayer />
        <CadasterPolygons />
        <DrawTool />
        <MapStateUpdater />
        <MapProjectZoom />
      </MapProvider>
    </div>
  );
};

export default Map;
