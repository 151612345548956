import { useTranslation } from 'react-i18next';

import { ReactComponent as DonationSuccessImage } from './img/donationSuccess.svg';

import './DonationSuccess.scss';

const DonationSuccess = () => {
  const { t } = useTranslation('app', { keyPrefix: 'paymentSuccess' });

  return (
    <div className="donation-success">
      <h2 className="donation-success__header">{t('header')}</h2>
      <h3 className="donation-success__text">{t('text')}</h3>
      <DonationSuccessImage />
    </div>
  );
};

export default DonationSuccess;
