import { LatLngBounds, Point } from 'leaflet';

import { convertFromWgs84ToGoogle } from './coordinatesConverter';

export const convertBoundsToString = (bounds: LatLngBounds) => {
  const convertedNorthWest = convertFromWgs84ToGoogle({
    lat: bounds.getNorthWest().lat,
    lng: bounds.getNorthWest().lng,
  });
  const convertedSouthEast = convertFromWgs84ToGoogle({
    lat: bounds.getSouthEast().lat,
    lng: bounds.getSouthEast().lng,
  });
  return `${convertedNorthWest.x},${convertedSouthEast.y},${convertedSouthEast.x},${convertedNorthWest.y}`;
};

export const convertSizeToString = (size: Point) => {
  return `${size.x},${size.y}`;
};
