import { ReactElement } from 'react';

import Box from 'src/shared/components/Box/Box';
import Typography from 'src/shared/components/Typography/Typography';

type FilterWithHeaderProps = {
  children: ReactElement;
  label: string;
};

const FilterWithHeader = ({ children, label }: FilterWithHeaderProps) => {
  return (
    <Box flexDirection={'column'} gap={'1em'}>
      <Typography type={'heading-h5'}>{label}</Typography>
      {children}
    </Box>
  );
};

export default FilterWithHeader;
