import {
  ActorBaseResponse,
  BaseResponse,
  MinIoUrl,
  UserProfile,
} from '@platform-for-public-places/components-library';

import { REQUEST_STATUS } from 'src/features/request/enums';

export enum UserType {
  developer = 'developer',
  implementer = 'implementer',
  all = 'all',
}

interface Tag {
  name: string;
  tagId: string;
}

export interface ProjectSpecialistBase {
  name: string;
  userId: string;
  avatar?: MinIoUrl[] | string;
  roles?: UserType[];
  isDeveloper?: boolean;
  isImplementer?: boolean;
  requestStatus?: REQUEST_STATUS;
  agreed?: boolean;
  message?: string;
  tags?: Tag[];
}

export interface ProjectSpecialist extends ProjectSpecialistBase {
  id: string;
}

export interface ProjectSpecialists {
  specialists: ProjectSpecialist[];
  count: number;
}

export interface GetUserInfoRequest {
  searchString: string;
  type: string;
  pageSize: number;
  page: number;
}

export interface GetUserCountResponse {
  all: number;
  developers: number;
  implementers: number;
}

export interface GetUserCountRequest {
  searchString: string;
}

export interface GetUserResponse extends BaseResponse {
  data: ActorBaseResponse<UserProfile>;
}

export interface GetUsersResponse extends BaseResponse {
  data: ActorBaseResponse<UserProfile[]>;
}

export interface PortfolioLink {
  link: string;
}

export interface PortfolioFile {
  key?: string;
  name?: string;
}

export interface UpdateProfileRequest {
  profileId: string;
  job: string;
  name: string;
  about: string;
  avatar: string;
  socialLinks: {
    vk: string;
    email?: string;
    phone: string;
    telegram: string;
    whatsapp: string;
  };
  portfolio?: PortfolioLink | PortfolioFile | null;
}

export interface UpdateProfileResponse {
  profileId: string;
}

export interface EventParticipant {
  name: string;
  joined: boolean;
  confirmed: boolean;
  userId: string;
  avatarKey?: MinIoUrl[] | string;
  projectId: string;
}

export interface EventParticipants {
  eventParticipants: EventParticipant[];
  count: number;
}
