import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDispatch, useSelector } from 'react-redux';

import {
  NOTIFICATION_PAYLOAD,
  NOTIFICATION_TYPE,
  NotificationPopup,
  Table,
  TableData,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';
import { ru } from 'date-fns/locale';

import {
  NOTIFICATION,
  setVisible,
} from 'src/features/notification/slices/notificationSlice';
import {
  useLazyDeleteRequestByIdRequestQuery,
  useLazyGetUserRequestsToParticipateQuery,
} from 'src/features/request/api';
import {
  BaseRequestData,
  ParticipantsRequestsResponse,
  RequestToProjectParticipantRequestBody,
} from 'src/features/request/models';
import { State } from 'src/features/store/store';

import ProfileParticipationsTableRow, {
  ProfileParticipationsTableContent,
} from './components/ProfileParticipationsTableRow/ProfileParticipationsTableRow';
import StatusRequest from './components/StatusRequest/StatusRequest';

import './ProfileParticipations.scss';

const PAGE_SIZE = 10;

const ProfileParticipations = () => {
  const dispatch = useDispatch();
  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'profile.participations' });

  const [page, setPage] = useState(1);
  const [isError, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [requests, setRequests] = useState<ParticipantsRequestsResponse[]>([]);
  const [notification, setNotification] = useState<NOTIFICATION_PAYLOAD>({
    message: '',
    type: NOTIFICATION_TYPE.NOTIFICATION,
  });

  const isVisible = useSelector((s: State) => s[NOTIFICATION].isVisible);

  const [getRequests] = useLazyGetUserRequestsToParticipateQuery();
  const [deleteRequestByIdRequest] = useLazyDeleteRequestByIdRequestQuery();

  const hideNotification = () => dispatch(setVisible(false));

  const [getProjectRequests] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => {
      setLoading(true);
      getRequests({
        arguments: {
          pageSize: PAGE_SIZE,
          page,
        },
      })
        .then((response) => {
          setRequests((prev) => [
            ...prev,
            ...(response.data?.data.projectSpecialists ?? []),
          ]);
          setPage((prev) => prev + 1);
          setHasNextPage(
            response.data?.data.projectSpecialists.length === PAGE_SIZE
          );
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    },
    disabled: isError,
  });

  const onRequestActionSuccess = useCallback(() => {
    setNotification({
      message: t('notifications.success'),
      type: NOTIFICATION_TYPE.NOTIFICATION,
    });
    dispatch(setVisible(true));
  }, [dispatch, t]);

  const onRequestActionError = useCallback(() => {
    setNotification({
      message: t('notifications.failed'),
      type: NOTIFICATION_TYPE.ERROR,
    });
    dispatch(setVisible(true));
  }, [dispatch, t]);

  const refuseMembership = (request: string) => {
    deleteRequestByIdRequest({
      requestId: request,
    })
      .unwrap()
      .then(({ success, errors }) => {
        if (!success || errors.length) {
          onRequestActionError();
        } else {
          onRequestActionSuccess();
        }
      })
      .then(() => {
        setRequests(
          (prev) =>
            prev.filter((value) => value.request.requestID !== request) ?? []
        );
      })
      .catch(() => onRequestActionError());
  };

  const prepareTableData = (): TableData<ProfileParticipationsTableContent> => {
    const columns = [
      { src: 'date', label: t('table.date') },
      { src: 'project', label: t('table.project') },
      { src: 'requestStatus', label: t('table.requestStatus') },
      { src: 'requestBody', label: t('table.requestBody') },
      { src: 'refuse', label: t('table.refuse') },
    ];

    const content: ProfileParticipationsTableContent[] = requests.map((e) => {
      const r =
        e.request as BaseRequestData<RequestToProjectParticipantRequestBody>;
      const rb = r.requestBody as RequestToProjectParticipantRequestBody;

      const baseContent = {
        date: new Date(r.updatedAt).toLocaleDateString(ru.code),
        project: { name: rb.projectName, id: rb.projectSpecialist.projectId },
        requestStatus: { status: r.status, message: r.message },
        requestBody: { tags: rb.projectSpecialist.tags, message: rb.message },
        refuseAction: () => {
          refuseMembership(r.requestID);
        },
      };

      return { ...baseContent };
    });
    return { columns, content };
  };

  const renderMobileProfile = () => {
    return requests.map((v) => (
      <StatusRequest
        key={v.request.entityId}
        data={v.request}
        refuseAction={refuseMembership}
      />
    ));
  };
  return (
    <div className="profile-participations">
      {isSmallScreen ? (
        <>
          {requests.length ? (
            renderMobileProfile()
          ) : (
            <p className="profile-participations__empty-list-mobile">
              {t('emptyPersonList')}
            </p>
          )}
        </>
      ) : (
        <>
          <h2 className="profile-layout__title profile-participations__title">
            {t('tabName')}
          </h2>
          <Table<ProfileParticipationsTableContent>
            data={prepareTableData()}
            placeholder={
              <p className="profile-participations__no-active-requests">
                <Trans
                  i18nKey={t('noActiveRequests')}
                  components={{ b: <b /> }}
                />
              </p>
            }
            rowComponent={ProfileParticipationsTableRow}
            headerClass="profile-participations__header"
            bodyClass="profile-participations__table"
            columnsClass="profile-participations__table-columns"
          />
        </>
      )}

      {hasNextPage ? (
        <div
          className="project-requests__loading-trigger"
          ref={getProjectRequests}
        />
      ) : null}

      <NotificationPopup
        type={notification.type}
        isVisible={isVisible}
        onTimeoutFinished={hideNotification}
        className="project-requests__notification"
      >
        {notification.message}
      </NotificationPopup>
    </div>
  );
};

export default ProfileParticipations;
