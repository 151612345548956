import './Card.scss';

export enum CARD_TYPE {
  LARGE = 'large',
  SMALL = 'small',
}

interface CardProps {
  children?: React.ReactNode;
  className?: string;
  type?: CARD_TYPE;
}

export const Card = ({
  children,
  className = '',
  type = CARD_TYPE.LARGE,
}: CardProps) => (
  <div className={`card card_${type} ${className}`}>{children}</div>
);
