import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetDiaryEntryResponse } from '../models';

export const DIARY = 'DIARY';

interface DiaryState {
  diary?: GetDiaryEntryResponse;
}

const initialState: DiaryState = {};

export const diarySlice = createSlice({
  name: DIARY,
  initialState: initialState,
  reducers: {
    setDiary: (
      state: DiaryState,
      action: PayloadAction<GetDiaryEntryResponse>
    ) => {
      state.diary = action.payload;
    },
    resetDiary: (state: DiaryState) => {
      state.diary = initialState.diary;
    },
  },
});

export const { setDiary, resetDiary } = diarySlice.actions;

export default diarySlice.reducer;
