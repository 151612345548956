import { useTranslation } from 'react-i18next';

import {
  Button,
  BUTTON_TYPE,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import './EditingFooter.scss';

export interface EditingFooterProps {
  onCancel: () => void;
  onSave: () => void;
  valid?: boolean;
  modified: boolean;
}

const EditingFooter = ({
  onCancel,
  onSave,
  valid,
  modified,
}: EditingFooterProps): JSX.Element | null => {
  const { t } = useTranslation();
  const isSmallScreen = useCheckSmallScreen();

  return modified ? (
    <div className="edit-layout-footer__submit">
      <p className="edit-layout-footer__text">{t('editing.submit.title')}</p>
      <div className="edit-layout-footer__buttons">
        <Button
          type={
            isSmallScreen
              ? BUTTON_TYPE.PRIMARY_OUTLINE
              : BUTTON_TYPE.TEXT_SECONDARY
          }
          onClick={onCancel}
          className="edit-layout-footer__button"
        >
          <span>{t('editing.submit.cancel')}</span>
        </Button>
        <Button
          disabled={!valid}
          type={BUTTON_TYPE.PRIMARY}
          className="edit-layout-footer__button"
          onClick={onSave}
        >
          <span>{t('editing.submit.save')}</span>
        </Button>
      </div>
    </div>
  ) : null;
};

export default EditingFooter;
