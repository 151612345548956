import { useTranslation } from 'react-i18next';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';
import clsx from 'clsx';

import ModalWindow from '../ModalWindow/ModalWindow';

import './AuthModal.scss';

interface AuthModalProps {
  className?: string;
  children: React.ReactNode;
  strict?: boolean;
  onClose?: () => void;
  header: string;
  onClickBack?: () => void | undefined;
}

const AuthModal = ({
  className,
  children,
  strict,
  onClose,
  header,
  onClickBack,
}: AuthModalProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'authModal' });

  return (
    <ModalWindow strict={strict} onClose={onClose}>
      <div
        className={clsx(
          'auth-modal',
          {
            'auth-modal_with-back-button': onClickBack,
          },
          className
        )}
      >
        {onClickBack ? (
          <Button
            className="auth-modal__back-button"
            type={BUTTON_TYPE.TEXT_DARK}
            onClick={onClickBack}
            icon
          >
            <Icon icon={IconType.Chevron} />
            <span>{t('backButton')}</span>
          </Button>
        ) : null}
        <h2 className="auth-modal__header">{header}</h2>
        {children}
      </div>
    </ModalWindow>
  );
};

export default AuthModal;
