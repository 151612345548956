import {
  ActorBaseResponse,
  MinIoUrl,
} from '@platform-for-public-places/components-library';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import {
  GeneratePresignedUrlsRequest,
  GetPresignedUrlsRequest,
  GetStaticPresignedUrlsRequest,
} from '../models';

export const MINIO_URL_API = 'MINIO_URL_API';

export const minIoUrlApi = createApi({
  reducerPath: MINIO_URL_API,
  baseQuery: retry(
    fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URLS }),
    { maxRetries: 0 }
  ),
  endpoints: (builder) => ({
    generatePresignedUrls: builder.query<
      ActorBaseResponse<MinIoUrl[]>,
      GeneratePresignedUrlsRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'generate-presigned-url',
          arguments: request,
        },
      }),
    }),
    getImagesPresignedUrls: builder.query<
      ActorBaseResponse<MinIoUrl[]>,
      GetPresignedUrlsRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'get-images-presigned-url-by-key',
          arguments: request,
        },
      }),
    }),
    getPresignedUrls: builder.query<
      ActorBaseResponse<MinIoUrl[]>,
      GetStaticPresignedUrlsRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: request.messageMapId,
          arguments: {
            keys: request.keys,
          },
        },
      }),
    }),
  }),
});

export const {
  useLazyGeneratePresignedUrlsQuery,
  useGetPresignedUrlsQuery,
  useLazyGetPresignedUrlsQuery,
  useGetImagesPresignedUrlsQuery,
  useLazyGetImagesPresignedUrlsQuery,
} = minIoUrlApi;
export const minIoUrlMiddleware = minIoUrlApi.middleware;

export default minIoUrlApi.reducer;
