import {
  useChangeFollowMutation as mockUseChangeFollowMutation,
  useChangeLikeMutation as mockUseChangeLikeMutation,
  useChangePlanForEventMutation as mockUseChangePlanForEventMutation,
  useGetProjectLikesQuery as mockUseGetProjectLikesQuery,
} from './mockReactionApi';
import {
  useChangeFollowMutation as realUseChangeFollowMutation,
  useChangeLikeMutation as realUseChangeLikeMutation,
  useChangePlanForEventMutation as realUseChangePlanForEventMutation,
  useGetProjectLikesQuery as realUseGetProjectLikesQuery,
} from './reactionApi';

export const useChangeLikeMutation =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseChangeLikeMutation
    : realUseChangeLikeMutation;

export const useGetProjectLikesQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetProjectLikesQuery
    : realUseGetProjectLikesQuery;

export const useChangeFollowMutation =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseChangeFollowMutation
    : realUseChangeFollowMutation;

export const useChangePlanForEventMutation =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseChangePlanForEventMutation
    : realUseChangePlanForEventMutation;
