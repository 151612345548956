import { useTranslation } from 'react-i18next';

import {
  SPECIFIC_LAYER,
  Status,
} from '@platform-for-public-places/components-library';
import { ru } from 'date-fns/locale';

import ProjectStatusTile from '../ProjectPreviewContainer/ProjectStatusTile/ProjectStatusTile';

import './StatusWithTimeline.scss';

export type StatusWithTimelineProps = {
  className?: string;
  isMobile?: boolean;
  status: Status;
  startDate: string;
  endDate: string;
};

const dateOptions: Intl.DateTimeFormatOptions = {
  month: 'long',
  day: 'numeric',
};

const timeOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
};

const StatusWithTimeline = ({
  className = '',
  isMobile = false,
  status,
  startDate,
  endDate,
}: StatusWithTimelineProps) => {
  const { t } = useTranslation('app', {
    keyPrefix: 'controls.layers.legend.event',
  });

  const converDateToString = (date: string) => {
    const curDate = new Date(date);
    const dateString = curDate.toLocaleDateString(ru.code, dateOptions);
    const timeString = curDate.toLocaleTimeString(ru.code, timeOptions);
    return `${dateString}, ${timeString}`;
  };

  return (
    <div className={`status-with-timeline ${className}`}>
      <ProjectStatusTile
        className="status-with-timeline__status"
        statusName={t(status.value)}
        status={status.value}
        layer={SPECIFIC_LAYER.EVENT}
      />
      <p className="status-with-timeline__timeline">
        {`${converDateToString(startDate)} - ${
          isMobile ? '\n' : ''
        }${converDateToString(endDate)}`}
      </p>
    </div>
  );
};

export default StatusWithTimeline;
