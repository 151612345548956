import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import { setMobileMenuOpen } from 'src/features/control/slices/controlSlice';
import { useCheckIfUserAuthorized, useClearCreation } from 'src/features/hooks';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { resetProjectInfo } from 'src/features/project/slices/projectsLayerSlice';

import './CreateProjectButton.scss';

interface CreateProjectButtonProps {
  className?: string;
  children?: JSX.Element;
  text?: string;
}

const CreateProjectButton = ({
  className = '',
  children,
  text,
}: CreateProjectButtonProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clearCreation = useClearCreation();

  const { isSuccess } = useWhoamiQuery();
  const mobile = useCheckSmallScreen();

  const onSuccess = () => {
    navigate(paths.creation);
  };

  const requestCreation = useCheckIfUserAuthorized(onSuccess);

  const onCreateClick = () => {
    dispatch(setMobileMenuOpen(false));
    if (!isSuccess) {
      mobile ? navigate(paths.signIn) : dispatch(changeModal(MODALS.SIGN_IN));
    } else {
      requestCreation().then(() => {
        dispatch(resetProjectInfo());
        clearCreation();
      });
    }
  };

  return children ? (
    <Button
      className={`create-project-button ${className}`}
      onClick={onCreateClick}
      type={BUTTON_TYPE.TEXT}
    >
      {children}
    </Button>
  ) : (
    <Button
      type={BUTTON_TYPE.ACCENTED}
      className={`create-project-button ${className}`}
      onClick={onCreateClick}
    >
      {text}
    </Button>
  );
};

export default CreateProjectButton;
