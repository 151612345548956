export enum PROJECT_STATUS {
  FRAMED = 'framed', // Идея оформлена
  PLANNED = 'planned', // Запланировано
  RUNNING = 'running', // Идёт
  ACCEPTED = 'accepted', // Принят на платформу
  APPROVED = 'approved', // Согласован
  IMPLEMENTED = 'implemented', // Реализован
  FINISHED = 'finished', // Завершён
  TRANSFERRED = 'transferred', // Передан на баланс города
}

export const STAGES = {
  [PROJECT_STATUS.FRAMED]: 0,
  [PROJECT_STATUS.ACCEPTED]: 1,
  [PROJECT_STATUS.APPROVED]: 2,
  [PROJECT_STATUS.IMPLEMENTED]: 3,
  [PROJECT_STATUS.FINISHED]: 4,
  [PROJECT_STATUS.TRANSFERRED]: 5,
};

export const PUBLIC_STATUSES = [
  PROJECT_STATUS.ACCEPTED,
  PROJECT_STATUS.PLANNED,
  PROJECT_STATUS.RUNNING,
  PROJECT_STATUS.APPROVED,
  PROJECT_STATUS.IMPLEMENTED,
  PROJECT_STATUS.FINISHED,
  PROJECT_STATUS.TRANSFERRED,
];
