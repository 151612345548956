import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { paths } from 'src/shared/routes';
import { areaWidgetsMap } from 'src/shared/widgets/area';

import {
  useCreateGeodataMutation,
  useGetProjectGeodataQuery,
} from 'src/features/project/api';
import { CreateGeodataRequest } from 'src/features/project/models';
import {
  PROJ_CREATE,
  setCenter,
  setCenterEdit,
  setFeature,
  setFeatureEdit,
  setMeetingPoint,
} from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import { EditingOutletProps } from 'src/pages/layouts/EditLayout/models';
import { useIsArchived } from 'src/pages/project/hooks';

import './EditingArea.scss';

const STEP_NAME = 'area';

const EditingArea = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { renderFooter }: EditingOutletProps = useOutletContext();

  const [createGeodata] = useCreateGeodataMutation();

  const archived = useIsArchived();

  const projectId = useSelector((s: State) => s[PROJ_CREATE].projectId);

  const center = useSelector((s: State) => s[PROJ_CREATE].center);
  const centerEdit = useSelector((s: State) => s[PROJ_CREATE].centerEdit);
  const feature = useSelector((s: State) => s[PROJ_CREATE].feature);
  const featureEdit = useSelector((s: State) => s[PROJ_CREATE].featureEdit);
  const meetingPoint = useSelector((s: State) => s[PROJ_CREATE].meetingPoint);

  const configSteps = useSelector(
    (s: State) => s[PROJ_CREATE].configEditingSteps
  );
  const areaStep = configSteps.find((step) => step.name === STEP_NAME);

  const { data: projectGeodata, refetch } = useGetProjectGeodataQuery(
    { projectId: projectId as string, archived },
    { skip: !projectId }
  );

  const AreaWidgetComponent = areaWidgetsMap.get(areaStep?.type ?? '');

  const initData = () => {
    if (projectGeodata?.data.geodata) {
      !feature && dispatch(setFeature(projectGeodata.data.geodata.polygon));
      !feature && dispatch(setFeatureEdit(projectGeodata.data.geodata.polygon));
      !center && dispatch(setCenter(projectGeodata.data.geodata.center));
      !center && dispatch(setCenterEdit(projectGeodata.data.geodata.center));
      !meetingPoint &&
        dispatch(
          setMeetingPoint(projectGeodata.data.geodata.meetingPoint ?? '')
        );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initData, [dispatch, projectGeodata?.data.geodata]);

  const valid = !!feature;
  const modified = useMemo(
    () =>
      feature !== featureEdit ||
      center !== centerEdit ||
      meetingPoint !== projectGeodata?.data.geodata?.meetingPoint,
    [
      feature,
      featureEdit,
      center,
      centerEdit,
      meetingPoint,
      projectGeodata?.data.geodata?.meetingPoint,
    ]
  );

  const onCancel = () => {
    if (projectGeodata?.data.geodata && modified) {
      dispatch(setFeature(projectGeodata.data.geodata.polygon));
      dispatch(setCenter(projectGeodata.data.geodata.center));
      dispatch(
        setMeetingPoint(projectGeodata?.data.geodata.meetingPoint ?? '')
      );
      setTimeout(() => {
        if (projectGeodata.data.geodata) {
          dispatch(setFeature(projectGeodata.data.geodata.polygon));
          dispatch(setFeatureEdit(projectGeodata.data.geodata.polygon));
          dispatch(setCenter(projectGeodata.data.geodata.center));
          dispatch(setCenterEdit(projectGeodata.data.geodata.center));
        }
      }, 1);
    }
  };

  const onSave = () => {
    if (feature && center && projectId) {
      dispatch(setFeatureEdit(feature));
      dispatch(setCenterEdit(center));
      const request: CreateGeodataRequest = {
        geojson: {
          type: feature.type,
          geometry: feature.geometry,
          properties: {
            projectId,
            center: center.geometry.coordinates,
          },
        },
        meetingPoint: meetingPoint?.trim(),
      };
      createGeodata(request).then(() => {
        refetch();
      });
    }
  };

  if (!areaStep) {
    navigate(paths.map);
  }

  return (
    <>
      {areaStep ? AreaWidgetComponent?.({ configStep: areaStep }) : null}
      {renderFooter?.({ onCancel, onSave, valid, modified })}
    </>
  );
};

export default EditingArea;
