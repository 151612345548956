import { useState } from 'react';

import {
  ActorBaseResponse,
  FakeResponse,
} from '@platform-for-public-places/components-library';

import { fetchMock } from 'src/shared/mock/utils';

import { InstructionData } from '../models';

interface FakeGetInstructionsResponse extends FakeResponse {
  data: ActorBaseResponse<InstructionData[]>;
}

export const useGetInstructionsQuery = (
  url: string
): FakeGetInstructionsResponse => {
  const [response, setResponse] = useState<FakeGetInstructionsResponse>({
    isSuccess: false,
    isFetching: true,
    isError: false,
    data: {
      data: [],
      success: true,
      errors: [],
    },
  });
  if (!response.isSuccess) {
    fetchMock(url, true).then((data) =>
      setResponse({
        isSuccess: true,
        isFetching: false,
        isError: false,
        data: {
          ...data,
        },
      })
    );
  }

  return response;
};
