import { Navigate, useNavigate } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import EmailConfirmedMessage from 'src/features/auth/components/EmailConfirmed/EmailConfirmed';

import './EmailConfirmed.scss';

const EmailConfirmed = () => {
  const navigate = useNavigate();
  const isSmallScreen = useCheckSmallScreen();

  return isSmallScreen ? (
    <div className="email-confirmed-page">
      <EmailConfirmedMessage
        onClick={() => navigate(paths.signIn, { replace: true })}
      />
    </div>
  ) : (
    <Navigate to={paths.map} replace />
  );
};

export default EmailConfirmed;
