import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';

import Spinner from 'src/shared/components/Spinner/Spinner';
import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import { useClearCreation } from 'src/features/hooks';
import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import ProjectImage from './img/default.svg';
import EventImage from './img/event.svg';
import GovImage from './img/gov.svg';
import NewsImage from './img/news.svg';

import './SuccessfulCreationWidget.scss';

interface LocationProps {
  prevUrl: string;
}

interface ContactsProps {
  value: string | undefined;
  icon: IconType;
  link: string;
}

const imageSources: Record<string, string> = {
  event: EventImage,
  default: ProjectImage,
  gov: GovImage,
  news: NewsImage,
};

const SuccessfulCreationWidget = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const clearCreation = useClearCreation();
  const currentTypeProject = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );
  const { t } = useTranslation('app', {
    keyPrefix: `creation.${currentTypeProject}.thanks`,
  });

  const CONTACTS: ContactsProps[] = [
    {
      value: process.env.REACT_APP_SUPPORT_EMAIL,
      icon: IconType.Letter,
      link: `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`,
    },
    {
      value: process.env.REACT_APP_PHONE_NUMBER,
      icon: IconType.Phone,
      link: `tel:${process.env.REACT_APP_PHONE_NUMBER}`,
    },
  ];

  const { isLoading, isSuccess } = useWhoamiQuery();

  useEffect(() => {
    const locationState = location.state as LocationProps;
    if (!locationState?.prevUrl) {
      navigate(paths.map);
    }
  }, [location, location.state, navigate]);

  useEffect(() => {
    if (!isLoading && !isSuccess) {
      navigate(paths.map);
    }
  }, [isSuccess, isLoading, navigate]);

  const renderTitle = () => {
    return <h2 className="successful-creation__title">{t('title')}</h2>;
  };

  const currentCreationStep = useSelector(
    (s: State) => s[PROJ_CREATE].currentCreationStep
  );
  const configCreationSteps = useSelector(
    (s: State) => s[PROJ_CREATE].configCreationSteps
  );

  const renderCongratulation = () => {
    return (
      <p className="successful-creation__congratulation">
        {t('congratulation')}
      </p>
    );
  };

  const renderImage = () => {
    return (
      <img
        src={imageSources[currentTypeProject]}
        className={`successful-creation__image successful-creation__image-${currentTypeProject}`}
        alt={'congratulations'}
      />
    );
  };

  const renderButtonToProjects = () => {
    return (
      <Button
        className="successful-creation__button"
        type={BUTTON_TYPE.ACCENTED}
        onClick={() => {
          clearCreation();

          navigate(paths.userProjects, {
            replace: undefined,
            state: { prevUrl: null },
          });
        }}
      >
        {t('toProjects')}
      </Button>
    );
  };

  const renderThanks = () => {
    return <h2 className="successful-creation__thanks">{t('thanks')}</h2>;
  };

  const renderContacts = () => {
    return (
      <div className="successful-creation__contacts">
        <p className="successful-creation__description">{t('contacts')}</p>
        <ul className="successful-creation__links">
          {CONTACTS.map((c) => {
            return (
              <li key={c.value}>
                <a
                  className="successful-creation__link"
                  href={c.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon
                    className="successful-creation__link-icon"
                    icon={c.icon}
                  />
                  {c.value}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const renderMap = new Map<string, () => JSX.Element>([
    ['title', renderTitle],
    ['congratulation', renderCongratulation],
    ['contacts', renderContacts],
    ['image', renderImage],
    ['buttonToProjects', renderButtonToProjects],
    ['thanks', renderThanks],
  ]);

  return !isLoading && isSuccess ? (
    <div className="successful-creation">
      {configCreationSteps[currentCreationStep].properties.visible.map(
        (item: string) => renderMap.get(item)?.()
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default SuccessfulCreationWidget;
