import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import PasswordInput from 'src/shared/components/inputs/PasswordInput/PasswordInput';
import { paths, routes } from 'src/shared/routes';

import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';

import { useConfirmPasswordRecoveryMutation } from '../../api';

import './RecoveryPassword.scss';

const MIN_PASSWORD_LENGTH = 8;

const RecoveryPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'recoveryPassword' });

  const [searchParams] = useSearchParams();
  const token = searchParams.get(routes.tokenId) ?? '';

  useEffect(() => {
    if (!token) {
      navigate(paths.map, { replace: true });
    }
  }, [navigate, token]);

  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const [confirmPasswordRecovery] = useConfirmPasswordRecoveryMutation();

  const onChangePassword = (value: string) => {
    if (error) {
      setError(false);
    }
    setPassword(value);
  };

  const onClickSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    confirmPasswordRecovery({
      recoveryTokenId: token,
      password,
    }).then(() => {
      isSmallScreen
        ? navigate(paths.recoverPasswordSuccess, { replace: true })
        : dispatch(changeModal(MODALS.RECOVERY_PASSWORD_SUCCESS));
    });
  };

  const submitButtonEnabled = useMemo(
    () => password.length >= MIN_PASSWORD_LENGTH,
    [password.length]
  );

  return (
    <div className="recovery-password">
      <h3 className="auth-title recovery-password__title">{t('title')}</h3>
      <p className="auth-description recovery-password__description">
        {t('description')}
      </p>
      <form className="recovery-password__form" onSubmit={onClickSubmit}>
        <PasswordInput
          value={password}
          className={`recovery-password__input${error ? 'error' : ''}`}
          placeholder={t('passwordPlaceholder')}
          onChange={(e) => onChangePassword(e.target.value)}
        />
        <Button
          submit
          disabled={!submitButtonEnabled}
          className="auth-button recovery-password__button"
          type={BUTTON_TYPE.ACCENTED}
        >
          {t('save')}
        </Button>
      </form>
    </div>
  );
};

export default RecoveryPassword;
