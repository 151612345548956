import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, IconType } from '@platform-for-public-places/components-library';

import { Cadaster } from 'src/features/pkk/models';

import { useGetInfoAboutAreaByIdQuery } from '../../api/pkkApi';

import './CadasterInformation.scss';

interface CadasterInformationProps {
  cadaster: Cadaster;
}

const CadasterInformation = ({
  cadaster,
}: CadasterInformationProps): JSX.Element => {
  const { t } = useTranslation();
  const [isOpen, changeOpen] = useState(false);
  const { data } = useGetInfoAboutAreaByIdQuery(cadaster.id);
  const fullCadasterData = data || cadaster;

  const renderKeyValue = (key: string, value: string): JSX.Element => (
    <h5 className="cadaster-information__key">
      {key}
      <span className="cadaster-information__value">{value}</span>
    </h5>
  );

  const renderCadasterFields = (): JSX.Element => (
    <>
      {renderKeyValue(
        t('cadasterInformation.district'),
        fullCadasterData.district ? fullCadasterData.district : '-'
      )}
      {renderKeyValue(
        t('cadasterInformation.address'),
        fullCadasterData.address ? fullCadasterData.address : '-'
      )}
      {renderKeyValue(
        t('cadasterInformation.objectType'),
        fullCadasterData.objectType
          ? t(`cadasterInformation.objectTypes.${fullCadasterData.objectType}`)
          : '-'
      )}
      {renderKeyValue(
        t('cadasterInformation.areaType'),
        fullCadasterData.areaType
          ? t(`cadasterInformation.areaTypes.${fullCadasterData.areaType}`)
          : '-'
      )}
      {renderKeyValue(
        t('cadasterInformation.ownershipForm'),
        fullCadasterData.ownershipForm
          ? t(
              `cadasterInformation.ownershipForms.${fullCadasterData.ownershipForm}`
            )
          : '-'
      )}
      {renderKeyValue(
        t('cadasterInformation.landType'),
        fullCadasterData.landType
          ? t(`cadasterInformation.landTypes.${fullCadasterData.landType}`)
          : '-'
      )}
      {renderKeyValue(
        t('cadasterInformation.allowedFor'),
        fullCadasterData.allowedFor ? fullCadasterData.allowedFor : '-'
      )}
    </>
  );

  return (
    <div
      className={`cadaster-information cadaster-information${
        isOpen ? '--open' : '--close'
      }`}
    >
      <div className="cadaster-information__short-info">
        {renderKeyValue(t('cadasterInformation.number'), cadaster.number)}
        <button
          className="cadaster-information__collapse-button"
          onClick={() => changeOpen(!isOpen)}
        >
          <Icon icon={IconType.Chevron} />
        </button>
      </div>
      <div className="cadaster-information__full-info">
        {renderCadasterFields()}
      </div>
    </div>
  );
};

export default CadasterInformation;
