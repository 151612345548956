import { BottomSheet } from 'react-spring-bottom-sheet';

import './StatusCard.scss';

interface StatusCardProps {
  contentInsideCard: () => JSX.Element;
  contentBottomSheet: () => JSX.Element;
  bottomSheetVisible: boolean;
  setBottomSheetVisible: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
}

const StatusCard = ({
  contentInsideCard,
  contentBottomSheet,
  bottomSheetVisible,
  setBottomSheetVisible,
  className = '',
}: StatusCardProps) => {
  return (
    <>
      <button
        onClick={() => setBottomSheetVisible(true)}
        className={`status-card ${className}`}
      >
        {contentInsideCard()}
      </button>
      <BottomSheet
        open={bottomSheetVisible}
        blocking
        onDismiss={() => setBottomSheetVisible(false)}
        scrollLocking
      >
        {contentBottomSheet()}
      </BottomSheet>
    </>
  );
};

export default StatusCard;
