import { useEffect, useMemo, useState } from 'react';
import { useMap } from 'react-leaflet';
import { useSelector } from 'react-redux';

import CanvasOverlay from 'src/shared/components/CanvasOverlay/CanvasOverlay';

import { DRAW } from 'src/features/draw/slices/drawSlice';
import { MAP } from 'src/features/map/slices/mapSlice';
import { State } from 'src/features/store/store';

import {
  useGetInfoAboutAreaByIdQuery,
  useGetPolygonForAreaByParamsQuery,
} from '../../api/pkkApi';

import { PKK } from '../../slices/pkkSlice';

const OPACITY = 0.3;
const COLORS = new Map<string, string>([
  ['100', '#F2B04E'],
  ['200', '#67B0F3'],
]);
const DEFAULT_COLOR = '#959AB2';

interface CadasterPolygonProps {
  id: string;
}

const CadasterPolygon = ({ id }: CadasterPolygonProps): JSX.Element | null => {
  const map = useMap();
  const bbox = useSelector((s: State) => s[MAP].bbox);
  const size = useSelector((s: State) => s[MAP].size);
  const [canvas] = useState<HTMLCanvasElement>(
    document.createElement('canvas')
  );

  const { data: cadasterData } = useGetInfoAboutAreaByIdQuery(id);
  const { data: polygonData } = useGetPolygonForAreaByParamsQuery({
    id,
    bbox,
    size,
  });

  useEffect(() => {
    if (polygonData && cadasterData) {
      const { contentType, imageData, width, height } = polygonData;
      const { ownershipForm } = cadasterData;
      const url = `data:${contentType};base64,${imageData}`;
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.width = width;
      img.height = height;
      img.addEventListener(
        'load',
        () => {
          if (ctx) {
            ctx.drawImage(img, 0, 0);
            ctx.globalCompositeOperation = 'source-in';
            ctx.fillStyle = ownershipForm
              ? (COLORS.get(ownershipForm) as string)
              : DEFAULT_COLOR;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.globalCompositeOperation = 'source-over';
          }
        },
        false
      );
      img.src = url;
    }
  }, [polygonData, cadasterData, canvas]);

  return polygonData && cadasterData ? (
    <CanvasOverlay
      canvas={canvas}
      bounds={map.getBounds()}
      opacity={OPACITY}
      width={canvas.width}
      height={canvas.height}
    />
  ) : null;
};

const CadasterPolygons = (): JSX.Element | null => {
  const cadasters = useSelector((s: State) => s[PKK].cadasters);
  const wasDrawn = useSelector((state: State) => !!state[DRAW].drawnShape);

  const polygons = useMemo(() => {
    if (cadasters && wasDrawn) {
      return cadasters.map((item) => {
        return <CadasterPolygon key={item.id} id={item.id} />;
      });
    }
    return null;
  }, [cadasters, wasDrawn]);

  return <>{polygons}</>;
};

export default CadasterPolygons;
