import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import EmailConfirmed from 'src/features/auth/components/EmailConfirmed/EmailConfirmed';
import ModalWindow from 'src/features/modal/components/ModalWindow/ModalWindow';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';

const EmailConfirmedModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mobile = useCheckSmallScreen();

  const navigateSignIn = () => {
    if (mobile) {
      navigate(paths.signIn);
    } else {
      dispatch(changeModal(MODALS.SIGN_IN));
      navigate(paths.map);
    }
  };

  return (
    <ModalWindow onClose={navigateSignIn}>
      <EmailConfirmed onClick={navigateSignIn} />
    </ModalWindow>
  );
};

export default EmailConfirmedModal;
