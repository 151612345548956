import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { changeModal } from 'src/features/modal/slices/modalSlice';

import './ModalWindow.scss';

interface ModalWindowProps {
  children: React.ReactNode;
  strict?: boolean;
  className?: string;
  onClose?: () => void;
}

function ModalWindow({
  children,
  strict,
  className = '',
  onClose,
}: ModalWindowProps) {
  const dispatch = useDispatch();

  const close = () => {
    if (!strict) {
      dispatch(changeModal(null));
      onClose?.();
    }
  };

  useEffect(() => {
    return () => window.history.replaceState({}, document.title);
  });

  return (
    <div className={`modal-window ${className}`}>
      <div aria-hidden className="modal-window__background" onClick={close} />
      {children}
    </div>
  );
}

export default ModalWindow;
