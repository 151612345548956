import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import './ArchivedBadge.scss';

const ArchivedBadge = (): JSX.Element => {
  const { t } = useTranslation('app', { keyPrefix: 'detailedProject' });

  return <div className="archived-badge">{t('archive')}</div>;
};

export default memo(ArchivedBadge);
