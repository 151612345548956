import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from 'src/shared/components/Typography/Typography';

import { SearchContext } from 'src/features/searchWithFilter/filter/search/SearchProvider';

const NumberProjectSearch = () => {
  const { t } = useTranslation('app', { keyPrefix: 'searchFilter' });
  const { projectsCount } = useContext(SearchContext);
  return (
    <Typography type={'heading-h6'}>
      {`${t('projects')} (${projectsCount})`}
    </Typography>
  );
};

export default NumberProjectSearch;
