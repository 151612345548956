import { To, useNavigate } from 'react-router-dom';

import { Icon, IconType } from '@platform-for-public-places/components-library';

import Burger from 'src/shared/components/Burger/Burger';

import MobileSidebar from '../MobileSidebar/MobileSidebar';

export interface MobileHeaderWithTitleProps {
  title: string;
  path?: string;
  replace?: boolean;
  hideBackButton?: boolean;
}

const MobileHeaderWithTitle = ({
  title,
  path,
  replace = false,
  hideBackButton = false,
}: MobileHeaderWithTitleProps): JSX.Element => {
  const navigate = useNavigate();

  const backPath = path ? path : -1;

  return (
    <header className="mobile-header mobile-header--with-title">
      {!hideBackButton && (
        <button
          className="mobile-header__return-button"
          onClick={() => {
            navigate(backPath as To, { replace });
          }}
        >
          <Icon icon={IconType.Chevron} />
        </button>
      )}
      <h1 className="mobile-header__header">{title}</h1>
      <Burger>
        <MobileSidebar />
      </Burger>
    </header>
  );
};

export default MobileHeaderWithTitle;
