import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Error,
  OrderedStatus,
  PROJECT_STATUS,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import {
  useGetProjectByIdQuery,
  useGetProjectInfoByIdQuery,
} from 'src/features/project/api';

export const useGetProjectAndCheck = (id?: string, archived = false) => {
  const navigate = useNavigate();

  const project = useGetProjectByIdQuery(
    { projectId: id as string, archived },
    { skip: !id }
  );

  useEffect(() => {
    if ((project?.error as Error)?.status === 404) {
      navigate(paths.map);
    }
  }, [navigate, project]);

  return project;
};

export const useGetProjectInfoAndCheck = (id?: string, archived = false) => {
  const navigate = useNavigate();

  const project = useGetProjectInfoByIdQuery(
    { projectId: id as string, archived },
    { skip: !id }
  );

  useEffect(() => {
    if ((project?.error as Error)?.status === 404) {
      navigate(paths.map);
    }
  }, [navigate, project]);

  return project;
};

export const filterEnabledStatuses = (
  array: OrderedStatus[] | undefined,
  overview?: boolean
) => {
  const statuses = array ?? [];
  let filterPredicate = (status: OrderedStatus) => !status.disabled;
  if (overview) {
    filterPredicate = (status: OrderedStatus) =>
      !status.disabled && status.name !== PROJECT_STATUS.FRAMED;
  }
  return statuses.filter(filterPredicate).map((step) => step.name);
};
