import { Navigate } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import AutopaymentsManagementSentPage from 'src/features/autopayments/components/AutopaymentsManagementSent/AutopaymentsManagementSent';

import './AutopaymentsManagementSent.scss';

const AutopaymentsManagementSent = () => {
  const isSmallScreen = useCheckSmallScreen();

  return isSmallScreen ? (
    <div className="autopayments-management-sent-page">
      <AutopaymentsManagementSentPage />
    </div>
  ) : (
    <Navigate to={paths.projects} replace />
  );
};

export default AutopaymentsManagementSent;
