import { useState } from 'react';

import { randUuid } from '@ngneat/falso';
import {
  ActorBaseResponse,
  FakeResponse,
} from '@platform-for-public-places/components-library';

import { fetchMock } from 'src/shared/mock/utils';

import {
  CreateCommentResponse,
  DeleteCommentRequest,
  GetCommentsRequest,
  GetCommentsResponse,
} from '../models';

interface FakeGetCommentsResponse extends FakeResponse {
  data: ActorBaseResponse<GetCommentsResponse>;
}

interface FakeCreateCommentResponse extends FakeResponse {
  data: ActorBaseResponse<CreateCommentResponse>;
}

export const useLazyCreateCommentQuery = (): [
  () => Promise<FakeCreateCommentResponse>,
  FakeCreateCommentResponse
] => {
  const [response, setResponse] = useState<FakeCreateCommentResponse>({
    isSuccess: false,
    isFetching: true,
    isError: false,
    data: {
      data: {
        commentId: '',
        requestId: '',
      },
      errors: [],
      success: false,
    },
  });

  const trigger = () => {
    const resp = {
      isSuccess: true,
      isFetching: false,
      isError: false,
      data: {
        data: {
          commentId: randUuid(),
          requestId: randUuid(),
        },
        errors: [],
        success: false,
      },
    };
    setResponse(resp);
    return Promise.resolve(resp);
  };
  return [trigger, response];
};

export const useLazyGetCommentsQuery = (): [
  (request: GetCommentsRequest) => Promise<FakeGetCommentsResponse>,
  FakeGetCommentsResponse
] => {
  const [response, setResponse] = useState<FakeGetCommentsResponse>({
    isSuccess: false,
    isFetching: true,
    isLoading: true,
    isError: false,
    data: {
      data: {
        aggregators: { count: 0 },
        comments: [],
      },
      errors: [],
      success: false,
    },
  });

  const trigger = (request: GetCommentsRequest) =>
    fetchMock(`get-comments/get-comments-${request.page}.json`)
      .then((data) => {
        const resp: FakeGetCommentsResponse = {
          isSuccess: true,
          isFetching: false,
          isLoading: false,
          isError: false,
          data,
        };

        setResponse(resp);
        return Promise.resolve(resp);
      })
      .catch(() =>
        Promise.reject({
          ...response,
          isSuccess: false,
          isFetching: false,
          isLoading: false,
          isError: true,
        })
      );

  return [trigger, response];
};

export const useUpdateCommentMutation = (): [
  () => Promise<FakeResponse>,
  FakeResponse
] => {
  const [response, setResponse] = useState<FakeResponse>({
    isSuccess: false,
    isFetching: true,
    isError: false,
  });

  const trigger = () => {
    const resp: FakeResponse = {
      isSuccess: true,
      isFetching: false,
      isError: false,
    };
    setResponse(resp);
    return Promise.resolve(resp);
  };
  return [trigger, response];
};

export const useDeleteCommentMutation = (): [
  (request: DeleteCommentRequest) => Promise<FakeResponse>,
  FakeResponse
] => {
  const [response, setResponse] = useState<FakeResponse>({
    isSuccess: false,
    isFetching: true,
    isError: false,
  });

  const trigger = () => {
    const resp: FakeResponse = {
      isSuccess: true,
      isFetching: false,
      isError: false,
    };
    setResponse(resp);
    return Promise.resolve(resp);
  };
  return [trigger, response];
};
