import { ActorBaseResponse } from '@platform-for-public-places/components-library';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import {
  AcceptSpecialistsByProjectIdRequest,
  AcceptSpecialistsByProjectIdResponse,
  BaseGetRequestResponse,
  BaseGetRequestsResponse,
  BaseRequestData,
  BaseRequestModel,
  Category,
  CreateMemberRequestRequest,
  CreateMemberRequestResponse,
  GetRequestByEntityAndInitiatorRequest,
  GetRequestsByTypeAndInitiatorIdRequest,
  GetUserRequestsToParticipate,
  ParticipantsRequestsWrapperResponse,
  RequestStandardRequest,
  RequestToProjectParticipantRequestBody,
  SpecialistRequestBody,
  TagsResponse,
} from '../models';

import { REQUEST_TYPE } from '../enums';

export const REQUEST_API = 'REQUEST_API';

export const requestApi = createApi({
  reducerPath: REQUEST_API,
  baseQuery: retry(
    fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URLS }),
    { maxRetries: 0 }
  ),
  endpoints: (builder) => ({
    createSpecialistRequest: builder.query<
      ActorBaseResponse<void>,
      BaseRequestModel<SpecialistRequestBody>
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'request/create-or-update',
          type: REQUEST_TYPE.SPECIALIST,
          ...request,
        },
      }),
    }),
    getRequestsByTypeAndInitiatorIdRequest: builder.query<
      ActorBaseResponse<
        BaseGetRequestsResponse<
          BaseRequestData<RequestToProjectParticipantRequestBody>
        >
      >,
      GetRequestsByTypeAndInitiatorIdRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'request/get-request-by-type-and-initiator-id',
          ...request,
        },
      }),
    }),
    getUserRequestsToParticipate: builder.query<
      ActorBaseResponse<ParticipantsRequestsWrapperResponse>,
      GetUserRequestsToParticipate
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-specialist/get-user-requests-to-projects',
          arguments: request,
        },
      }),
    }),
    getSpecialistRequest: builder.query<
      ActorBaseResponse<
        BaseGetRequestResponse<BaseRequestData<SpecialistRequestBody>>
      >,
      GetRequestByEntityAndInitiatorRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'request/get-request-by-initiator-id-and-entity-id',
          ...request,
        },
      }),
    }),
    rejectRequestByIdRequest: builder.query<
      ActorBaseResponse<{ requestId: string }>,
      RequestStandardRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'request/reject',
          ...request,
        },
      }),
    }),
    deleteRequestByIdRequest: builder.query<
      ActorBaseResponse<{ requestId: string }>,
      RequestStandardRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'request/delete',
          ...request,
        },
      }),
    }),
    acceptSpecialistsByRequestId: builder.query<
      ActorBaseResponse<AcceptSpecialistsByProjectIdResponse>,
      AcceptSpecialistsByProjectIdRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'request/execute',
          ...request,
        },
      }),
    }),
    getCategoryWithTags: builder.query<ActorBaseResponse<Category[]>, void>({
      query: () => ({
        method: 'POST',
        body: {
          messageMapId: 'project-specialist-tag/get-all-tags',
        },
      }),
      transformResponse: (
        response: ActorBaseResponse<TagsResponse>
      ): ActorBaseResponse<Category[]> => {
        const value: Category[] = response.data.categories.map((data) => {
          return {
            label: data.name,
            value: data.categoryId,
            options: data.tags.map((tags) => {
              return {
                value: tags.tagId,
                label: tags.name,
                isDisabled: false,
              };
            }),
          };
        });
        return {
          ...response,
          data: value,
        };
      },
    }),
    createMemberRequest: builder.query<
      ActorBaseResponse<CreateMemberRequestResponse>,
      CreateMemberRequestRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-specialist/add-user-to-project',
          arguments: request,
        },
      }),
    }),
  }),
});

export const {
  useGetCategoryWithTagsQuery,
  useGetSpecialistRequestQuery,
  useLazyCreateMemberRequestQuery,
  useLazyGetSpecialistRequestQuery,
  useLazyCreateSpecialistRequestQuery,
  useLazyRejectRequestByIdRequestQuery,
  useLazyAcceptSpecialistsByRequestIdQuery,
  useLazyGetUserRequestsToParticipateQuery,
  useLazyDeleteRequestByIdRequestQuery,
} = requestApi;

export const requestMiddleware = requestApi.middleware;

export default requestApi.reducer;
