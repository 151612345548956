import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const COOKIE = 'COOKIE';

interface CookieState {
  showCookieCard: boolean;
  isAnimationActive: boolean;
}

const initialState: CookieState = {
  showCookieCard: !JSON.parse(localStorage.getItem(COOKIE) || 'false'),
  isAnimationActive: false,
};

export const cookieSlice = createSlice({
  name: COOKIE,
  initialState,
  reducers: {
    setShowCookieCard(state: CookieState, action: PayloadAction<boolean>) {
      state.showCookieCard = action.payload;
    },
    setAnimationActive(state: CookieState, action: PayloadAction<boolean>) {
      state.isAnimationActive = action.payload;
    },
  },
});

export const { setShowCookieCard, setAnimationActive } = cookieSlice.actions;

export default cookieSlice.reducer;
