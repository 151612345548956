/* eslint-disable prettier/prettier */
import authApiReducer, { AUTH_API } from 'src/features/auth/api/authApi';
import authReducer, { AUTH } from 'src/features/auth/slices/authSlice';
import commentsApiReducer, {
  COMMENTS_API,
} from 'src/features/comments/api/commentsApi';
import commentReducer, {
  COMMENT,
} from 'src/features/comments/slices/commentSlice';
import controlReducer, {
  CONTROL,
} from 'src/features/control/slices/controlSlice';
import cookieReducer, { COOKIE } from 'src/features/cookies/slices/cookieSlice';
import diaryApiReducer, { DIARY_API } from 'src/features/diary/api/diaryApi';
import diaryReducer, { DIARY } from 'src/features/diary/slices/diarySlice';
import drawReducer, { DRAW } from 'src/features/draw/slices/drawSlice';
import exhibitionApiReducer, {
  EXHIBITION_API,
} from 'src/features/exhibition/api/exhibitionApi';
import filesApiReducer, { FILES_API } from 'src/features/files/api/filesApi';
import instructionApiReducer, {
  INSTRUCTION_API,
} from 'src/features/instruction/api/instructionApi';
import mapApiReducer, { MAP_API } from 'src/features/map/api/mapApi';
import mapReducer, { MAP } from 'src/features/map/slices/mapSlice';
import minioApiReducer, { MINIO_API } from 'src/features/minIo/api/minIoApi';
import minioUrlApiReducer, {
  MINIO_URL_API,
} from 'src/features/minIo/api/minIoUrlApi';
import modalReducer, { MODAL } from 'src/features/modal/slices/modalSlice';
import photosReducer, {
  PHOTOS,
} from 'src/features/modal/slices/photosModalSlice';
import notificationReducer, {
  NOTIFICATION,
} from 'src/features/notification/slices/notificationSlice';
import paymentApiReducer, {
  PAYMENT_API,
} from 'src/features/payment/api/paymentApi';
import pkkApiReducer, { PKK_API } from 'src/features/pkk/api/pkkApi';
import pkkReducer, { PKK } from 'src/features/pkk/slices/pkkSlice';
import projectApiReducer, {
  PROJECT_API,
} from 'src/features/project/api/projectApi';
import projectCreateReducer, {
  PROJ_CREATE,
} from 'src/features/project/slices/creatingProjectSlice';
import projectEditReducer, {
  PROJ_EDIT,
} from 'src/features/project/slices/editingProjectSlice';
import projectsLayerReducer, {
  PROJ_LAYER,
} from 'src/features/project/slices/projectsLayerSlice';
import reactionApiReducer, {
  REACTION_API,
} from 'src/features/reaction/api/reactionApi';
import requestApiReducer, {
  REQUEST_API,
} from 'src/features/request/api/requestApi';
import requisitesApiReducer, {
  REQUISITES_API,
} from 'src/features/requisites/api/requisitesApi';
import filterReducer, { FILTER } from 'src/features/searchWithFilter/filter/slices/filterSlice';
import filterDataReducer, {
  FILTER_DATA,
} from 'src/features/searchWithFilter/filter/slices/filterSliceData';
import statusApiReducer, {
  STATUS_API,
} from 'src/features/status/api/statusApi';
import userApiReducer, { USER_API } from 'src/features/user/api/userApi';

const rootReducer = {
  [MAP_API]: mapApiReducer,
  [MAP]: mapReducer,
  [PKK]: pkkReducer,
  [AUTH]: authReducer,
  [DRAW]: drawReducer,
  [MODAL]: modalReducer,
  [DIARY]: diaryReducer,
  [PHOTOS]: photosReducer,
  [FILTER]: filterReducer,
  [COOKIE]: cookieReducer,
  [CONTROL]: controlReducer,
  [COMMENT]: commentReducer,
  [PROJ_EDIT]: projectEditReducer,
  [FILTER_DATA]: filterDataReducer,
  [PROJ_LAYER]: projectsLayerReducer,
  [NOTIFICATION]: notificationReducer,
  [PROJ_CREATE]: projectCreateReducer,

  [PKK_API]: pkkApiReducer,
  [AUTH_API]: authApiReducer,
  [USER_API]: userApiReducer,
  [DIARY_API]: diaryApiReducer,
  [FILES_API]: filesApiReducer,
  [MINIO_API]: minioApiReducer,
  [STATUS_API]: statusApiReducer,
  [PROJECT_API]: projectApiReducer,
  [PAYMENT_API]: paymentApiReducer,
  [REQUEST_API]: requestApiReducer,
  [REACTION_API]: reactionApiReducer,
  [COMMENTS_API]: commentsApiReducer,
  [MINIO_URL_API]: minioUrlApiReducer,
  [EXHIBITION_API]: exhibitionApiReducer,
  [REQUISITES_API]: requisitesApiReducer,
  [INSTRUCTION_API]: instructionApiReducer,
};

export default rootReducer;
