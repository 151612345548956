import { useTranslation } from 'react-i18next';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { TYPE_SEARCH_PAGE } from 'src/features/map/components/SearchPane/components/PaginationList/PaginationList';
import SearchProvider from 'src/features/searchWithFilter/filter/search/SearchProvider';

import { SearchPaneUserProjects } from 'src/pages/main/ProjectsCatalog/ProjectsCatalog';

import './UserProjects.scss';

const UserProjects = (): JSX.Element => {
  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'profile.projects' });

  return (
    <div className="user-projects">
      {isSmallScreen ? null : (
        <h2 className="profile-layout__title user-projects__title">
          {t('title')}
        </h2>
      )}
      <SearchProvider typePage={TYPE_SEARCH_PAGE.USER_PROJECTS}>
        <SearchPaneUserProjects />
      </SearchProvider>
    </div>
  );
};
export default UserProjects;
