import {
  useGetImagesPresignedUrlsQuery as realUseGetImagesPresignedUrlsQuery,
  useGetPresignedUrlsQuery as realUseGetPresignedUrlsQuery,
  useLazyGeneratePresignedUrlsQuery as realUseLazyGeneratePresignedUrlsQuery,
  useLazyGetImagesPresignedUrlsQuery as realUseLazyGetImagesPresignedUrlsQuery,
  useLazyGetPresignedUrlsQuery as realUseLazyGetPresignedUrlsQuery,
} from './minIoUrlApi';
import {
  useGetImagesPresignedUrlsQuery as mockUseGetImagesPresignedUrlsQuery,
  useGetPresignedUrlsQuery as mockUseGetPresignedUrlsQuery,
  useLazyGeneratePresignedUrlsQuery as mockUseLazyGeneratePresignedUrlsQuery,
  useLazyGetImagesPresignedUrlsQuery as mockUseLazyGetImagesPresignedUrlsQuery,
  useLazyGetPresignedUrlsQuery as mockUseLazyGetPresignedUrlsQuery,
} from './mockMinIoUrlApi';

export const useLazyGeneratePresignedUrlsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGeneratePresignedUrlsQuery
    : realUseLazyGeneratePresignedUrlsQuery;

export const useGetPresignedUrlsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetPresignedUrlsQuery
    : realUseGetPresignedUrlsQuery;

export const useLazyGetPresignedUrlsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetPresignedUrlsQuery
    : realUseLazyGetPresignedUrlsQuery;

export const useGetImagesPresignedUrlsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetImagesPresignedUrlsQuery
    : realUseGetImagesPresignedUrlsQuery;

export const useLazyGetImagesPresignedUrlsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetImagesPresignedUrlsQuery
    : realUseLazyGetImagesPresignedUrlsQuery;
