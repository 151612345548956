import { SORTING_ORDER_DIRECTION } from '@platform-for-public-places/components-library';

export enum PAYMENT_METHOD {
  YOO_MONEY = 'YooMoney',
  ALPHA_BANK = 'alphaBank',
  SBP = 'sbp',
  CASH = 'cash',
}

export enum RECURRENT_PAYMENT_STATUS {
  ACTIVE = 'active',
  NOT_ACTIVE = 'notActive',
}

export enum PAYMENT_STATUS {
  COMPLETED = 'completed',
  NOT_COMPLETED = 'notCompleted',
  CANCELED = 'canceled',
}

export enum PAYMENT_TYPE {
  ALL = 'all',
  RECURRENT = 'recurrent',
}

export interface PaymentDetails {
  budget?: number;
  projectId?: string;
  donationTotal?: number;
  link?: string;
  name?: string;
}

export interface PaymentsStatisticData {
  month: number;
  platform: number;
  projects: number;
}

export interface PaymentsReportsData {
  key: string;
  fileName: string;
}

export interface PaymentsStatistic {
  year: number;
  statistic: PaymentsStatisticData[];
  reports: PaymentsReportsData[];
  total: number;
}

export interface GetPaymentsStatisticResponse {
  paymentsStatistic: PaymentsStatistic[];
}

export interface GetPaymentsStatisticRequest {
  pageSize: number;
  page: number;
}

export interface GetDonationDetailsResponse {
  paymentDetails: PaymentDetails;
}

export interface GetDonationDetailsRequest {
  projectId: string;
  archived?: boolean;
}

export interface GetProjectEstimationResponse {
  estimation: PaymentDetails;
}

export interface GetProjectEstimationRequest {
  projectId: string;
}

export interface CreateProjectBudgetRequest {
  projectId: string;
  budget: number;
  link?: string;
}

export interface CreateProjectBudgetResponse {
  projectId: string;
  budget: number;
  link?: string;
}

export enum PaymentStatus {
  PENDING = 'pending',
  WAITING_FOR_CAPTURE = 'waiting_for_capture',
  SUCCEEDED = 'succeeded',
  CANCELED = 'canceled',
}

export enum PaymentConfirmationType {
  EMBEDDED = 'embedded',
  EXTERNAL = 'external',
  MOBILE_APPLICATION = 'mobile_application',
  QR = 'qr',
  REDIRECT = 'redirect',
}

export enum PaymentReceiptRegistrationStatus {
  PENDING = 'pending',
  SUCCEEDED = 'succeeded',
  CANCELED = 'canceled',
}

export enum PaymentCancellationParty {
  YOO_MONEY = 'yoo_money',
  PAYMENT_NETWORK = 'payment_network',
  MERCHANT = 'merchant',
}

export interface PaymentAmount {
  value: string;
  currency: string; // ISO 4217
}

export interface PaymentRecipient {
  account_id: string;
  gateway_id: string;
}

interface PaymentConfirmationBase {
  type: PaymentConfirmationType;
}

export interface PaymentConfirmationEmbedded extends PaymentConfirmationBase {
  confirmation_token: string;
}

export interface PaymentConfirmationExternal {
  type?: string;
}

export interface PaymentConfirmationMobile extends PaymentConfirmationBase {
  confirmation_url: string;
}

export interface PaymentConfirmationQR extends PaymentConfirmationBase {
  confirmation_data: string;
}

export interface PaymentConfirmationRedirect extends PaymentConfirmationBase {
  confirmation_url: string;
  enforce?: boolean;
  return_url?: string;
}

export interface NewPaymentConfirmationRedirect {
  type: string;
  locale?: string;
  returnUrlConfirmation: string;
  returnUrlFailed: string;
  returnUrlPassed: string;
}

export type PaymentConfirmation =
  | PaymentConfirmationEmbedded
  | PaymentConfirmationExternal
  | PaymentConfirmationMobile
  | PaymentConfirmationQR
  | PaymentConfirmationRedirect
  | NewPaymentConfirmationRedirect;

// Up to 16 keys, 32 chars per key, 512 chars per value
export interface PaymentMetadata {
  userId?: string;
  projectId?: string;
  projectName?: string;
  projectLink?: string;
  email?: string;
}

// https://yookassa.ru/developers/payment-acceptance/after-the-payment/declined-payments#cancellation-details-reason
export interface PaymentCancellationDetails {
  party: PaymentCancellationParty;
  reason: string;
}

export interface PaymentAuthDetails {
  rrn?: string;
  auth_code?: string;
  three_d_secure: {
    applied: boolean;
  };
}

export interface Payment<T extends PaymentConfirmation> {
  id: string;
  status: PaymentStatus;
  amount: PaymentAmount;
  income_amount?: PaymentAmount;
  description?: string;
  recipient: PaymentRecipient;
  captured_at?: string; // ISO 8601, UTC
  created_at: string; // ISO 8601, UTC
  expires_at?: string; // ISO 8601, UTC
  confirmation?: T;
  test: boolean;
  refunded_amount?: PaymentAmount;
  paid: boolean;
  refundable: boolean;
  receipt_registration?: PaymentReceiptRegistrationStatus;
  metadata?: PaymentMetadata;
  cancellation_details?: PaymentCancellationDetails;
  authorization_details?: PaymentAuthDetails;
  merchant_customer_id?: string;
}

export type CreatePaymentRequest<T extends PaymentConfirmation> = Omit<
  Payment<T>,
  | 'id'
  | 'status'
  | 'income_amount'
  | 'recipient'
  | 'captured_at'
  | 'created_at'
  | 'expires_at'
  | 'test'
  | 'refunded_amount'
  | 'paid'
  | 'refundable'
  | 'receipt_registration'
  | 'cancellation_details'
  | 'authorization_details'
> & {
  recipient?: Omit<PaymentRecipient, 'account_id'>;
  payment_token?: string;
  payment_method_id?: string;
  save_payment_method?: boolean;
  capture?: boolean;
  client_ip?: string;
  recurrent?: boolean;
};

export interface CreatePaymentResponse<T extends PaymentConfirmation> {
  payment: Payment<T>;
}

export interface UserPaymentData {
  date: string;
  paymentId: string;
  amount: number;
  payer: string | null;
  paymentType: PAYMENT_METHOD;
  projectId: string | null;
  projectName: string;
  status?: PAYMENT_STATUS;
}

export interface GetUserPaymentResponse {
  aggregators: {
    count: number;
  };
  payments: UserPaymentData[];
}

export enum SORTING_RECURRENT_USER_PAYMENTS_ORDER_BY {
  NEXT_PAYMENT_DATE = 'nextPaymentDate',
  AMOUNT = 'amount',
  PROJECT_NAME = 'projectName',
  PAYMENT_METHOD = 'paymentMethod',
  STATUS = 'status',
}

export interface GetRecurrentUserPaymentsRequest {
  token: string | null;
  searchString: string;
  pageSize: number;
  page: number;
  orderBy?: SORTING_RECURRENT_USER_PAYMENTS_ORDER_BY;
  orderDirection: SORTING_ORDER_DIRECTION;
}

export interface RecurrentUserPaymentData {
  recurrent_paymentsID: string;
  nextPaymentDate: string;
  projectId: string | null;
  projectName: string;
  amount: number;
  paymentMethod: PAYMENT_METHOD;
  status: RECURRENT_PAYMENT_STATUS;
}

export interface GetRecurrentUserPaymentsResponse {
  aggregators: {
    count: number;
  };
  recurrentPayments: RecurrentUserPaymentData[];
}

export enum SORTING_USER_PAYMENTS_ORDER_BY {
  DATE = 'date',
  AMOUNT = 'amount',
  PROJECT_NAME = 'projectName',
  PAYMENT_TYPE = 'paymentType',
  STATUS = 'status',
}

export interface GetUserPaymentRequest {
  searchString: string;
  pageSize: number;
  page: number;
  orderBy?: SORTING_USER_PAYMENTS_ORDER_BY;
  orderDirection: SORTING_ORDER_DIRECTION;
}

export interface GetTotalAmountUserPaymentsRequest {
  searchString: string;
}

export interface GetTotalAmountUserPaymentsResponse {
  total: number | null;
}

export interface StopSupportingRecurrentPaymentRequest {
  recurrentPaymentsId: string;
  token: string | null;
}

export interface RequestAccessToRecurrentPaymentsRequest {
  email: string;
}
