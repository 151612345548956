import {
  useGetAvailableStatusesByProjectIdQuery as mockUseGetAvailableStatusesByProjectIdQuery,
  useGetStatusesQuery as mockUseGetStatusesQuery,
  useGetStatusQuery as mockUseGetStatusQuery,
  useSetStatusMutation as mockUseSetStatusMutation,
} from './mockStatusApi';
import {
  useDisableProjectFlowStepMutation as realUseDisableProjectFlowStepMutation,
  useEnableProjectFlowStepMutation as realUseEnableProjectFlowStepMutation,
  useGetAvailableStatusesByProjectIdQuery as realUseGetAvailableStatusesByProjectIdQuery,
  useGetStatusesQuery as realUseGetStatusesQuery,
  useGetStatusQuery as realUseGetStatusQuery,
  useSetStatusMutation as realUseSetStatusMutation,
} from './statusApi';

export const useGetStatusesQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetStatusesQuery
    : realUseGetStatusesQuery;

export const useGetStatusQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetStatusQuery
    : realUseGetStatusQuery;

export const useGetAvailableStatusesByProjectIdQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetAvailableStatusesByProjectIdQuery
    : realUseGetAvailableStatusesByProjectIdQuery;

export const useSetStatusMutation =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseSetStatusMutation
    : realUseSetStatusMutation;

export const useDisableProjectFlowStepMutation =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? realUseDisableProjectFlowStepMutation
    : realUseDisableProjectFlowStepMutation;

export const useEnableProjectFlowStepMutation =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? realUseEnableProjectFlowStepMutation
    : realUseEnableProjectFlowStepMutation;
