import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { SpringEvent } from 'react-spring-bottom-sheet/dist/types';

import {
  Button,
  BUTTON_TYPE,
  GetFilesResponse,
  GetProjectResponse,
  Icon,
  IconType,
  MinIoUrl,
  NOTIFICATION_TYPE,
  NotificationPopup,
  PROJECT_STATUS,
  useCheckSmallScreen,
  useKeyboardVisible,
  UserCard,
  UserProfile,
} from '@platform-for-public-places/components-library';
import clsx from 'clsx';
import { Feature, Point } from 'geojson';

import { CATEGORY, DONATION, FILE_ID, USER_ID } from 'src/app/constants';
import CatalogAvatar from 'src/shared/components/CatalogUsers/components/CatalogAvatar/CatalogAvatar';
import PhotoCategoriesTabs from 'src/shared/components/PhotoCategoriesTabs/PhotoCategoriesTabs';
import ProjectPhotoMock from 'src/shared/components/ProjectPhotoMock/ProjectPhotoMock';
import Spinner from 'src/shared/components/Spinner/Spinner';
import TextEditorPreview from 'src/shared/components/TextEditorPreview/TextEditorPreview';
import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import { USER_ROLE } from 'src/features/auth/models';
import CommentCreation from 'src/features/comments/components/CommentCreation/CommentCreation';
import CommentsList from 'src/features/comments/components/CommentsList/CommentsList';
import { VIEW_CARD } from 'src/features/control/enums';
import { setCurrentCard } from 'src/features/control/slices/controlSlice';
import { useGetDiaryEntriesQuery } from 'src/features/diary/api';
import DiaryEntry from 'src/features/diary/components/DiaryEntry/DiaryEntry';
import {
  useGetFilesQuery,
  useGetProjectCoverQuery,
  useLazyGetFilesQuery,
} from 'src/features/files/api';
import { downloadURI, isDownloadFile } from 'src/features/files/download';
import { FileCategories, PhotoFileCategories } from 'src/features/files/enums';
import {
  useCheckUser,
  useGetProjectsWithImages,
  useTranslationStatus,
  useUserCardTranslation,
} from 'src/features/hooks';
import MobileShare from 'src/features/mobile/components/MobileShare/MobileShare';
import SignInModal from 'src/features/modal/components/SignInModal/SignInModal';
import {
  BackdoorPayload,
  BypassPayload,
  MODALS,
} from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import {
  changeOpenedIndex,
  changePhotos,
} from 'src/features/modal/slices/photosModalSlice';
import {
  NOTIFICATION,
  setNotification,
  setVisible,
} from 'src/features/notification/slices/notificationSlice';
import Donation from 'src/features/payment/components/Donation/Donation';
import { PAYMENT_TYPE } from 'src/features/payment/models';
import {
  useGetAreaInfoByPolygonQuery,
  useGetInfoAboutAreaByIdQuery,
} from 'src/features/pkk/api/pkkApi';
import {
  useGetEventParticipantsInfoQuery,
  useGetProjectCommentsQuery,
  useGetProjectDescriptionQuery,
  useGetProjectGeodataQuery,
  useGetProjectSubscriptionQuery,
  useGetProjectTimelineQuery,
  useLazyGetProjectsBySpecialistQuery,
} from 'src/features/project/api';
import ArchivedBadge from 'src/features/project/components/ArchivedBadge/ArchivedBadge';
import MeetingPoint from 'src/features/project/components/MeetingPoint/MeetingPoint';
import PhotoCarousel from 'src/features/project/components/PhotoCarousel/PhotoCarousel';
import ProjectStatus from 'src/features/project/components/ProjectStatus/ProjectStatus';
import ProjectStatusVertical from 'src/features/project/components/ProjectStatusVertical/ProjectStatusVertical';
import StatusWithTimeline from 'src/features/project/components/StatusWithTimeline/StatusWithTimeline';
import SwiperCarousel from 'src/features/project/components/SwiperCarousel/SwiperCarousel';
import { useGetProjectInfoAndCheck } from 'src/features/project/hooks';
import { SPECIFIC_LAYER } from 'src/features/project/models';
import {
  changeProjectFollow,
  changeProjectJoined,
  changeProjectLiked,
  setCurrentProjectInfo,
  setProjectComments,
  setProjectCountOfJoined,
  setProjectFollowed,
  setProjectGeodata,
  setProjectIdea,
  setProjectJoined,
  setProjectLiked,
  setProjectLikes,
  setProjectName,
  setProjectStatus,
} from 'src/features/project/slices/projectsLayerSlice';
import { useGetProjectLikesQuery } from 'src/features/reaction/api';
import FollowButton from 'src/features/reaction/components/FollowButton/FollowButton';
import JoinButtonCompact from 'src/features/reaction/components/JoinButtonCompact/JoinButtonCompact';
import LikeButton from 'src/features/reaction/components/LikeButton/LikeButton';
import { useLazyAcceptSpecialistsByRequestIdQuery } from 'src/features/request/api';
import {
  useGetAvailableStatusesByProjectIdQuery,
  useGetStatusesQuery,
  useGetStatusQuery,
} from 'src/features/status/api';
import { State } from 'src/features/store/store';
import { useLazyGetProfileQuery } from 'src/features/user/api';
import { PROJECTS_PAGE_SIZE } from 'src/features/user/constants';

import SharePlate from 'src/pages/project/DetailedProjectInfo/component/SharePlate/SharePlate';

import ProjectDocs from './component/ProjectDocs/ProjectDocs';
import ProjectMembersWrapper, {
  ProjectType,
} from './component/ProjectMembersWrapper/MembersHandler/ProjectMembersWrapper';
import { useIsArchived } from '../hooks';

import './DetailedProjectInfo.scss';

const DOCS_LIMIT = 50;
const PHOTO_LIMIT = 10;
const PHOTO_OFFSET = 0;
const DOCS_OFFSET = 0;
const DIARY_PAGE = 1;
const DIARY_PAGE_SIZE = 1;
const NOT_EMPTY_STRING = 'meow';

const COMMENTS_TRIGGER_TIMEOUT = 300;
const PAGE_LOADING_DELAY = 500;
const COMMENTS_ANCHOR = 'comments';

interface LayerProjectType {
  [layer: string]: ProjectType;
}

const layerProjectType: LayerProjectType = {
  default: ProjectType.Default,
  event: ProjectType.Event,
};

interface StateProps {
  goOnMap: boolean;
}

const DetailedProjectInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const archived = useIsArchived();
  const mobile = useCheckSmallScreen();
  const { id, requestId } = useParams();
  const [searchParams] = useSearchParams();
  const keyboardVisible = useKeyboardVisible();
  const translateStatus = useTranslationStatus();
  const userCardTitles = useUserCardTranslation();
  const { pathname, search, state } = useLocation();
  const { ref: inViewRef, inView } = useInView({ threshold: 0.5 });
  const { t } = useTranslation('app', { keyPrefix: 'detailedProject' });

  const docsLinkRef = useRef<HTMLAnchorElement>(null);
  const docsDownloadRef = useRef<HTMLButtonElement>(null);

  const [getUserProfile] = useLazyGetProfileQuery();
  const { data: projectInfo, refetch: refetchProjectInfo } =
    useGetProjectInfoAndCheck(id, archived);
  const { data: projectGeodata } = useGetProjectGeodataQuery(
    { projectId: id as string, archived },
    { skip: !id, refetchOnMountOrArgChange: true }
  );
  const { data: projectComments } = useGetProjectCommentsQuery(
    { projectId: id as string, archived },
    { skip: !id }
  );
  const { data: projectStatus } = useGetStatusQuery(
    { projectId: id as string, archived },
    { skip: !id }
  );

  const { data: projectTimeline } = useGetProjectTimelineQuery(
    { projectId: id as string, archived },
    { skip: !id, refetchOnMountOrArgChange: true }
  );

  const timeline = projectTimeline?.data.timeline;

  const { data: userData, isLoading, isSuccess: authorized } = useWhoamiQuery();
  const [accept] = useLazyAcceptSpecialistsByRequestIdQuery();

  const { data: projectLikes, refetch: projectLikesRefetch } =
    useGetProjectLikesQuery({
      projectId: id as string,
      archived,
    });
  const { data: projectSubscription, refetch: projectSubscriptionRefetch } =
    useGetProjectSubscriptionQuery(
      { projectId: id as string, archived },
      { skip: !id }
    );
  const { data: projectDescription } = useGetProjectDescriptionQuery(
    { projectId: id as string, archived },
    { skip: !id }
  );
  const { data: availableStatuses } = useGetAvailableStatusesByProjectIdQuery(
    { projectId: id as string, archived },
    { skip: !id }
  );

  const { data: eventParticipantsInfo, refetch: refetchEventParticipantsInfo } =
    useGetEventParticipantsInfoQuery(
      {
        projectId: projectInfo ? projectInfo?.data.projectInfo.projectID : '',
        archived,
      },
      { skip: !projectInfo?.data.projectInfo.projectID }
    );

  const eventParticipants = eventParticipantsInfo?.data.joinedToEventInfo;

  useEffect(() => {
    if (projectInfo && !isLoading) {
      dispatch(setCurrentProjectInfo(projectInfo.data));
      dispatch(setProjectStatus(projectStatus?.data.statusInfo.status));
      dispatch(
        setProjectFollowed(projectSubscription?.data.subscriptionInfo.followed)
      );
      dispatch(setProjectComments(projectComments?.data.commentInfo.comments));
      dispatch(setProjectLikes(projectLikes?.data.likeInfo.likes));
      dispatch(setProjectLiked(projectLikes?.data.likeInfo.liked));
      dispatch(setProjectGeodata(projectGeodata?.data.geodata));
      dispatch(setProjectName(projectDescription?.data.description.name));
      dispatch(setProjectIdea(projectDescription?.data.description.idea));
      dispatch(setProjectJoined(eventParticipants?.joined));
      dispatch(setProjectCountOfJoined(eventParticipants?.countOfJoined));
      if (requestId && authorized) {
        accept({ requestId }).then(() =>
          navigate(paths.projectById(projectInfo.data.projectInfo.projectID))
        );
      }
    }
  }, [
    accept,
    dispatch,
    navigate,
    authorized,
    isLoading,
    projectInfo,
    projectComments,
    requestId,
    projectStatus,
    projectSubscription,
    projectLikes,
    projectGeodata,
    projectDescription,
    eventParticipants?.joined,
    eventParticipants?.countOfJoined,
  ]);

  const isVisible = useSelector((s: State) => s[NOTIFICATION].isVisible);
  const notification = useSelector((s: State) => s[NOTIFICATION].notification);

  const [modal, setModal] = useState<boolean>(false);
  const [openComments, setOpenComments] = useState<boolean>(true);
  const [bottomSheetVisible, setBottomSheetVisible] = useState(false);
  const [showSharePlate, setShowSharePlate] = useState<boolean>(false);
  const [likesCount, setLikesCount] = useState<number>(
    projectLikes?.data.likeInfo === null
      ? 0
      : projectLikes?.data.likeInfo.likes ?? 0
  );
  const [wasLiked, setWasLiked] = useState<boolean>(
    projectLikes?.data.likeInfo === null
      ? false
      : !!projectLikes?.data.likeInfo.liked
  );
  const [wasFollowed, setWasFollowed] = useState<boolean>(
    projectSubscription?.data.subscriptionInfo === null
      ? false
      : !!projectSubscription?.data.subscriptionInfo.followed
  );
  const [wasJoined, setWasJoined] = useState<boolean>(
    eventParticipants ? eventParticipants.joined : false
  );
  const [countOfJoined, setCountOfJoined] = useState<number>(
    eventParticipants === null ? 0 : eventParticipants?.countOfJoined ?? 0
  );
  const [selectedUser, setSelectedUser] = useState<UserProfile | null>(null);
  const [photoCategory, setPhotoCategory] = useState<PhotoFileCategories>(
    FileCategories.PHOTO_BEFORE
  );

  const layer = projectInfo?.data.projectInfo.layer;

  const wasRedirectActionCalled = useRef(false);
  const [projectsPage, setProjectsPage] = useState<number>(1);
  const [projectsError] = useState<boolean>(false);
  const [projectsLoading, setProjectsLoading] = useState<boolean>(false);
  const [hasProjectsNextPage, setHasProjectsNextPage] = useState<boolean>(true);
  const [projects, setProjects] = useState<GetProjectResponse[]>([]);
  const [initialMemberId, setInitialMemberId] = useState<string>('');

  const [getProjectsBySpecialist] = useLazyGetProjectsBySpecialistQuery();
  const [getProjectsWithImages] = useGetProjectsWithImages();

  const [getProjects] = useInfiniteScroll({
    loading: projectsLoading,
    hasNextPage: hasProjectsNextPage,
    onLoadMore: () => {
      setProjectsLoading(true);
      getProjectsBySpecialist({
        userId: selectedUser?.userId ?? '',
        statuses: [
          PROJECT_STATUS.ACCEPTED,
          PROJECT_STATUS.APPROVED,
          PROJECT_STATUS.IMPLEMENTED,
          PROJECT_STATUS.FINISHED,
          PROJECT_STATUS.TRANSFERRED,
        ],
        page: projectsPage,
        pageSize: PROJECTS_PAGE_SIZE,
      }).then((response) => {
        if (response.data?.data) {
          getProjectsWithImages(response.data?.data ?? []).then((res) =>
            setProjects((prev) => [...prev, ...res])
          );
          setProjectsPage(projectsPage + 1);
          setProjectsLoading(false);
          setHasProjectsNextPage(
            response.data?.data.length === PROJECTS_PAGE_SIZE
          );
        }
      });
    },
    disabled: projectsError,
  });

  const clearProjects = () => {
    setHasProjectsNextPage(true);
    setProjectsPage(0);
    setProjects([]);
  };

  useEffect(() => {
    if (requestId && !isLoading && !authorized) {
      if (mobile) {
        const _state = state as BypassPayload | undefined;
        const payload: BackdoorPayload = { backdoor: `${pathname}${search}` };
        if (!_state?.bypass) {
          navigate(paths.signIn, { state: payload, replace: true });
        }
      } else {
        setModal(true);
      }
    }
  }, [
    navigate,
    isLoading,
    mobile,
    authorized,
    requestId,
    pathname,
    search,
    state,
  ]);

  useEffect(() => {
    if (location.pathname.endsWith(DONATION) && projectInfo) {
      dispatch(changeModal(MODALS.PAYMENT));
      if (mobile && id) {
        navigate(paths.projectMobileDonationById(id));
      }
    }
  }, [dispatch, location.pathname, projectInfo, id, mobile, navigate]);

  const isNews =
    (projectStatus?.data.statusInfo.status?.value as string) ===
    SPECIFIC_LAYER.NEWS;
  const isEvent = projectInfo?.data.projectInfo.layer === SPECIFIC_LAYER.EVENT;

  const { data: areaInfo } = useGetAreaInfoByPolygonQuery(
    projectGeodata?.data.geodata?.center as Feature<Point>,
    { skip: !projectGeodata?.data.geodata?.center }
  );

  const { data: pkkInfo } = useGetInfoAboutAreaByIdQuery(
    areaInfo?.[0]?.id as string,
    { skip: !areaInfo?.[0] }
  );

  const canCreateComment = useMemo(
    () => !archived && userData?.success,
    [archived, userData?.success]
  );

  useEffect(() => {
    refetchProjectInfo();
    const openComments = searchParams.get('comments');
    if (openComments === 'true') {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
      }, PAGE_LOADING_DELAY);
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [refetchProjectInfo, searchParams]);

  const beforePhotos = useGetFilesQuery(
    {
      entityId: id as string,
      category: FileCategories.PHOTO_BEFORE,
      limit: PHOTO_LIMIT,
      offset: PHOTO_OFFSET,
      archived,
    },
    { skip: !id }
  );

  const {
    data: photosCover,
    isFetching: coverFetching,
    isSuccess: coverSuccess,
  } = useGetProjectCoverQuery(
    { projectId: id as string, archived },
    { skip: !id }
  );

  const [photos, setPhotos] = useState<GetFilesResponse[]>([]);

  const [getPhotos] = useLazyGetFilesQuery();

  const onTabClick = useCallback(
    (category: PhotoFileCategories) => {
      if (category !== photoCategory && projectInfo) {
        setPhotoCategory(category);
        getPhotos({
          entityId: projectInfo.data.projectInfo.projectID,
          category: category,
          limit: PHOTO_LIMIT,
          offset: PHOTO_OFFSET,
          archived,
        })
          .unwrap()
          .then((photos) => setPhotos(photos?.data ?? []));
      }
    },
    [archived, getPhotos, photoCategory, projectInfo]
  );

  useEffect(() => {
    setPhotos(beforePhotos.data?.data ?? []);
  }, [beforePhotos]);

  const coverUrl =
    coverSuccess && !coverFetching ? photosCover.data.cover?.url : null;

  const docs = useGetFilesQuery(
    {
      entityId: id as string,
      category: FileCategories.DOCUMENT,
      limit: DOCS_LIMIT,
      offset: DOCS_OFFSET,
      archived,
    },
    { skip: !id }
  );

  const conceptDesign = useGetFilesQuery(
    {
      entityId: id as string,
      category: FileCategories.DESIGN,
      limit: PHOTO_LIMIT,
      offset: DOCS_OFFSET,
      archived,
    },
    { skip: !id }
  );

  const statuses = useGetStatusesQuery(
    { projectId: id as string, archived },
    { skip: !id }
  );

  const diaryEntries = useGetDiaryEntriesQuery(
    {
      projectId: id as string,
      page: DIARY_PAGE,
      pageSize: DIARY_PAGE_SIZE,
      archived,
    },
    { skip: !id, refetchOnMountOrArgChange: true }
  );

  const initiatorOrAdmin = useCheckUser([
    { role: USER_ROLE.ADMIN },
    {
      role: USER_ROLE.USER,
      userId: projectInfo?.data.projectInfo.ownerId ?? NOT_EMPTY_STRING,
    },
  ]);

  const renderMainActionInFooterMobile = (): JSX.Element => {
    if (initiatorOrAdmin) {
      return archived ? (
        <></>
      ) : (
        <Button
          type={BUTTON_TYPE.TEXT_LIGHT}
          className="detailed-info__edit"
          icon
          onClick={() => navigate(paths.editProjectMenuById(id ?? ''))}
        >
          <Icon icon={IconType.Pencil} />
          <span>{t('edit')}</span>
        </Button>
      );
    }

    return (
      <FollowButton
        projectId={id as string}
        changeFollowLocal={changeFollowLocal}
        wasFollowed={wasFollowed}
        className="detailed-info__button--follow"
        disabled={
          archived ||
          projectStatus?.data.statusInfo.status?.value === PROJECT_STATUS.FRAMED
        }
      />
    );
  };

  const editAllowed = !archived && initiatorOrAdmin;

  const isAdmin = useCheckUser([{ role: USER_ROLE.ADMIN }]);

  const reload = () => document.location.reload();

  const showOnMap = () => {
    if (projectInfo && projectGeodata) {
      if (projectGeodata.data.geodata?.polygon.geometry.coordinates) {
        dispatch(setProjectGeodata(projectGeodata.data.geodata));
        dispatch(setCurrentProjectInfo(projectInfo.data));
        dispatch(setCurrentCard(VIEW_CARD.OVERVIEW));
      }
      if (mobile) {
        navigate(paths.map);
      } else {
        navigate(
          archived
            ? `${paths.map}?projectId=${projectInfo.data.projectInfo.projectID}&archived=true`
            : `${paths.map}?projectId=${projectInfo.data.projectInfo.projectID}`
        );
      }
    }
  };

  const onClickBack = () => {
    const locationState = state as StateProps;
    if (!locationState || locationState.goOnMap) {
      showOnMap();
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    setLikesCount(
      projectLikes?.data.likeInfo === null
        ? 0
        : projectLikes?.data.likeInfo.likes ?? 0
    );
    setWasLiked(
      projectLikes?.data.likeInfo === null
        ? false
        : !!projectLikes?.data.likeInfo.liked
    );
  }, [projectLikes]);

  useEffect(() => {
    setWasJoined(
      eventParticipants === null ? false : !!eventParticipants?.joined
    );
    setCountOfJoined(
      eventParticipants === null ? 0 : eventParticipants?.countOfJoined ?? 0
    );
  }, [eventParticipants]);

  useEffect(() => {
    if (projectSubscription) {
      setWasFollowed(projectSubscription.data.subscriptionInfo.followed);
    }
  }, [projectSubscription]);

  const navigateComments = () => {
    if (id) {
      navigate(paths.commentsByProjectId(id));
    }
  };

  // Show visited project on mobile map on unmount
  useEffect(() => {
    return () => {
      dispatch(setCurrentProjectInfo(projectInfo?.data || null));
    };
  }, [dispatch, projectInfo]);

  // Close photos modal on unmount
  useEffect(() => {
    return () => {
      dispatch(changeModal(null));
      dispatch(changePhotos([]));
      dispatch(changeOpenedIndex(0));
    };
  }, [dispatch]);

  // Get user id from query to show modal of user
  useEffect(() => {
    if (!initialMemberId) {
      const queryParams = new URLSearchParams(location.search);
      const userId = queryParams.get(USER_ID);
      if (userId && projectInfo) {
        setInitialMemberId(userId);
      }
    }
  }, [initialMemberId, location.search, projectInfo]);

  const onPhotoClick = useCallback(
    (photos: GetFilesResponse[], openIndex: number) => {
      dispatch(changeModal(MODALS.PICTURE));
      dispatch(changePhotos(photos));
      dispatch(changeOpenedIndex(openIndex));
    },
    [dispatch]
  );

  const checkRef = (
    refAnchorElement: RefObject<HTMLAnchorElement>,
    refButtonElement: RefObject<HTMLButtonElement>
  ) => {
    if (!refAnchorElement.current && refButtonElement.current) {
      return refButtonElement.current.clientHeight;
    }
    if (refAnchorElement.current && !refButtonElement.current) {
      return refAnchorElement.current.clientHeight;
    }
    if (refAnchorElement.current && refButtonElement.current) {
      return Math.max(
        refAnchorElement.current.clientHeight,
        refButtonElement.current.clientHeight
      );
    }
    return 0;
  };

  const renderDocs = (docsq: GetFilesResponse[]) =>
    docsq.map((value, i) => {
      return isDownloadFile(value.name ?? '') ? (
        <button
          key={i}
          className="link-doc"
          onClick={() => downloadURI(value.url, value.name)}
          ref={docsDownloadRef}
        >
          <Icon icon={IconType.Docs} className="links-doc__icon" />
          <span className="link-doc__text">{value.name ?? value.url}</span>
        </button>
      ) : (
        <a
          href={value.url}
          target="_blank"
          key={i}
          ref={docsLinkRef}
          download
          className="link-doc"
          rel="noreferrer"
        >
          <Icon icon={IconType.Docs} className="links-doc__icon" />
          <span className="link-doc__text">{value.name ?? value.url}</span>
        </a>
      );
    });

  const getData = useCallback(() => {
    let data: GetFilesResponse[] = [];

    if (conceptDesign.data?.data) {
      data = [...data, ...conceptDesign.data.data];
    }
    if (docs.data?.data) {
      data = [...data, ...docs.data.data];
    }
    return data;
  }, [conceptDesign.data?.data, docs.data?.data]);

  const changeLikeLocal = () => {
    setWasLiked((prev) => !prev);
    setLikesCount((prev) => (wasLiked ? prev - 1 : prev + 1));
    refetchProjectInfo();
    projectLikesRefetch();
    dispatch(changeProjectLiked());
  };

  const followReactionNotifications = useCallback(
    (reactionMessage: string) => {
      dispatch(
        setNotification({
          message: t(reactionMessage),
          type: NOTIFICATION_TYPE.NOTIFICATION,
        })
      );
      dispatch(setVisible(true));
    },
    [dispatch, t]
  );

  const changeFollowLocal = () => {
    const reactionMessage = wasFollowed
      ? 'reaction.follow.unfollowNotification'
      : 'reaction.follow.followNotification';
    setWasFollowed((prev) => !prev);
    followReactionNotifications(reactionMessage);
    refetchProjectInfo();
    projectSubscriptionRefetch();
    dispatch(changeProjectFollow());
  };

  const changeJoinLocal = () => {
    setWasJoined((prev) => !prev);
    setCountOfJoined((prev) => (wasJoined ? prev - 1 : prev + 1));
    dispatch(changeProjectJoined());
    refetchEventParticipantsInfo();
  };

  const scrollToComments = () =>
    document
      .getElementById(COMMENTS_ANCHOR)
      ?.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const toggleComments = () => {
    setOpenComments((prev) => {
      if (!prev) {
        setTimeout(() => scrollToComments(), COMMENTS_TRIGGER_TIMEOUT);
      }
      return !prev;
    });
  };

  const watchOpened = (s: TemplateStringsArray) =>
    openComments ? `${s[0]}--open` : s[0];

  const watchKeyboardAndVisibility = (s: TemplateStringsArray) => {
    let res = s[0];
    if (keyboardVisible) {
      res = res.concat(` ${s[0]}--shrinked`);
    }
    if (!inView) {
      if (canCreateComment) {
        res = res.concat(` ${s[0]}--with-header-full`);
      } else {
        res = res.concat(` ${s[0]}--with-header`);
      }
    }
    return res;
  };

  const isPhotoCategory = useCallback((category: PhotoFileCategories) => {
    const photoFileCategoriesValues = [
      FileCategories.PHOTO_BEFORE,
      FileCategories.PHOTO_CONCEPT,
      FileCategories.PHOTO_AFTER,
    ];
    return photoFileCategoriesValues.includes(category);
  }, []);

  useEffect(() => {
    if (wasRedirectActionCalled.current || !projectInfo) {
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    const fileId = queryParams.get(FILE_ID);
    const category = queryParams.get(CATEGORY);

    if (
      category &&
      fileId &&
      isPhotoCategory(category as PhotoFileCategories)
    ) {
      onTabClick(category as PhotoFileCategories);
      const initialIndex = photos.findIndex(
        (photo) => photo.project_filesID === fileId
      );
      if (initialIndex !== -1) {
        wasRedirectActionCalled.current = true;
        onPhotoClick(photos, initialIndex);
      }
    }
  }, [
    location.search,
    projectInfo,
    isPhotoCategory,
    onTabClick,
    photos,
    onPhotoClick,
  ]);

  useEffect(() => {
    if (wasRedirectActionCalled.current || !projectInfo) {
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    const fileId = queryParams.get(FILE_ID);
    const category = queryParams.get(CATEGORY);

    if (
      category &&
      fileId &&
      [FileCategories.DOCUMENT, FileCategories.DESIGN].includes(
        category as FileCategories
      )
    ) {
      const file = getData().find((file) => file.project_filesID === fileId);
      if (file) {
        wasRedirectActionCalled.current = true;
        if (isDownloadFile(file.name ?? '')) {
          downloadURI(file.url, file.name);
        } else {
          window.open(file.url, '_blank', 'noreferrer');
        }
      }
    }
  }, [location.search, projectInfo, getData]);

  const hideNotification = () => dispatch(setVisible(false));

  const renderStatusWithTimeline = (isMobile = false) => {
    return projectStatus?.data.statusInfo.status &&
      projectInfo?.data.projectInfo.layer === SPECIFIC_LAYER.EVENT &&
      timeline ? (
      <StatusWithTimeline
        className="detailed-info__status-with-timeline"
        status={projectStatus?.data.statusInfo.status}
        startDate={timeline.startDate}
        endDate={timeline.endDate}
        isMobile={isMobile}
      />
    ) : null;
  };

  const renderMeetingPoint = (isMobile = false) => {
    return isEvent &&
      (projectGeodata?.data.geodata?.meetingPoint || pkkInfo?.address) ? (
      <MeetingPoint
        className="detailed-info__meeting-point"
        isMobile={isMobile}
      >
        {projectGeodata?.data.geodata?.meetingPoint || pkkInfo?.address}
      </MeetingPoint>
    ) : null;
  };

  const onClickManageAutopaymentsButton = () => {
    if (authorized) {
      navigate(paths.profilePayments, {
        state: { paymentType: PAYMENT_TYPE.RECURRENT },
      });
    } else {
      mobile
        ? navigate(paths.autopaymentsManagement)
        : dispatch(changeModal(MODALS.AUTOPAYMENTS_MANAGEMENT));
    }
  };

  const renderDesktop = () => (
    <div className="detailed-info">
      <Button
        className="detailed-info__back"
        type={BUTTON_TYPE.TEXT_DARK}
        onClick={onClickBack}
        icon
      >
        <Icon icon={IconType.Arrow} />
        <span>{t('back')}</span>
      </Button>
      <main className="detailed-info__main">
        <div className="detailed-info__content">
          <div className="detailed-info__title-wrapper">
            <h2 className="detailed-info__title">
              {projectDescription?.data.description.name}
            </h2>
            <Button
              disabled={archived}
              className="detailed-info__show-map"
              type={BUTTON_TYPE.ACCENTED_OUTLINE}
              onClick={showOnMap}
              icon
            >
              <Icon icon={IconType.Marker} />
              <span>{t('showOnMap')}</span>
            </Button>
            {archived ? <ArchivedBadge /> : null}
          </div>
          {renderStatusWithTimeline()}
          {renderMeetingPoint()}
          <h3 className="detailed-info__address">{pkkInfo?.address}</h3>
          {isNews ? null : (
            <PhotoCategoriesTabs
              layer={projectInfo?.data.projectInfo.layer}
              category={photoCategory}
              onTabClick={onTabClick}
            />
          )}
          {photos?.length ? (
            <PhotoCarousel
              reordered
              className="detailed-info__photo-carousel"
              photos={photos}
            />
          ) : (
            <ProjectPhotoMock
              category={photoCategory}
              className="detailed-info__photo-mock"
            />
          )}

          <div className="detailed-info__description">
            {projectDescription?.data.description.idea ? (
              <TextEditorPreview>
                {projectDescription?.data.description.idea}
              </TextEditorPreview>
            ) : null}
          </div>

          <div className="detailed-info__diary">
            <h3 className="detailed-info__diary-title">{t('diaryTitle')}</h3>
            <p className="detailed-info__diary-description">
              {t('diaryDescription')}
            </p>
            {diaryEntries?.data?.data.documents.length ? (
              <div className="detailed-info__diary-entries">
                {
                  <>
                    <DiaryEntry
                      projectId={diaryEntries.data.data.documents[0].id}
                      editable={false}
                      deletable={false}
                      key={diaryEntries.data.data.documents[0].id}
                      entry={diaryEntries.data.data.documents[0]}
                      onPhotoClick={onPhotoClick}
                    />
                    <Button
                      icon
                      disabled={archived}
                      className="diary-entry__button"
                      type={BUTTON_TYPE.TEXT_SECONDARY}
                      onClick={() =>
                        navigate(paths.diaryById(id || ''), {
                          state: { goBack: true },
                        })
                      }
                    >
                      <span>{t('openDiary')}</span>
                      <Icon icon={IconType.Arrow} />
                    </Button>
                  </>
                }
              </div>
            ) : (
              <span className="detailed-info__diary-no-data">
                {t('diaryNoData')}
              </span>
            )}
          </div>

          <div className="detailed-info__comments" id={COMMENTS_ANCHOR}>
            <button
              className={watchOpened`detailed-info__comments-spoiler`}
              onClick={toggleComments}
            >
              <span>
                {t('comments', {
                  count: projectComments?.data.commentInfo.comments || 0,
                })}
              </span>
              <Icon icon={IconType.Chevron} />
            </button>
            {canCreateComment ? (
              <CommentCreation
                projectId={id ?? ''}
                className={watchOpened`detailed-info__comments-form`}
              />
            ) : null}
            <CommentsList
              archived={archived}
              projectId={id ?? ''}
              className={watchOpened`detailed-info__comments-list`}
            />
          </div>
        </div>
        <aside className="detailed-info__side">
          {editAllowed ? (
            <div className="detailed-info__side-buttons">
              <Button
                type={BUTTON_TYPE.TEXT_SECONDARY}
                className="detailed-info__edit"
                icon
                onClick={() => navigate(paths.editProjectStatusById(id ?? ''))}
              >
                <Icon icon={IconType.Pencil} />
                <span>{t('edit')}</span>
              </Button>
            </div>
          ) : null}

          <div className="detailed-info__buttons--left">
            <FollowButton
              projectId={id as string}
              changeFollowLocal={changeFollowLocal}
              wasFollowed={wasFollowed}
              className="detailed-info__button--follow"
              disabled={
                archived ||
                projectStatus?.data.statusInfo.status?.value ===
                  PROJECT_STATUS.FRAMED
              }
            />
            <LikeButton
              projectId={id as string}
              changeLikeLocal={changeLikeLocal}
              wasLiked={wasLiked}
              likesCount={likesCount}
              className="detailed-info__button--like"
              disabled={
                archived ||
                projectStatus?.data.statusInfo.status?.value ===
                  PROJECT_STATUS.FRAMED
              }
            />
            {archived ? null : (
              // TODO(Nariman): Нужно добавить новый тип кнопки для share(как IconButton в MUI) в библотеку компонентов
              <Button
                className="detailed-info__button--share"
                type={BUTTON_TYPE.TEXT_DARK}
                fill
                icon
                onClick={() => {
                  setShowSharePlate(!showSharePlate);
                  navigator.clipboard.writeText(
                    `${window.location.origin}${paths.projectById(id ?? '')}`
                  );
                }}
              >
                <Icon icon={IconType.Share} />
              </Button>
            )}
            {showSharePlate ? (
              <SharePlate
                className="detailed-info__share-plate"
                projectId={id || ''}
                setClickedOutside={() => setShowSharePlate(false)}
              />
            ) : null}
          </div>
          <div className="detailed-info__members">
            {projectInfo && projectStatus?.data.statusInfo.status ? (
              <ProjectMembersWrapper
                archived={archived}
                isAdmin={isAdmin}
                projectId={projectInfo.data.projectInfo.projectID}
                isInitiatorProjectOwner={
                  userData?.data?.userID ===
                  projectInfo.data.projectInfo.ownerId
                }
                initiatorName={projectInfo.data.projectInfo.owner?.name}
                initiatorId={projectInfo.data.projectInfo.ownerId}
                previewMemberIdByRedirect={initialMemberId}
                status={projectStatus.data.statusInfo.status}
                projectType={layerProjectType[layer || '']}
              />
            ) : null}
          </div>
          {isNews || isEvent ? null : (
            <div className="detailed-info__timeline">
              <h3 className="detailed-info__timeline-title">{t('timeline')}</h3>
              <ProjectStatusVertical
                className="detailed-info__timeline-chart"
                statuses={statuses.data?.data || []}
                grayscale={archived}
                availableStatuses={availableStatuses?.data}
                projectType={projectInfo?.data.projectInfo.layer}
              />
            </div>
          )}
          {projectInfo ? (
            <div className="detailed-info__donation">
              <Donation
                archived={archived}
                title={t('donation.title')}
                description={t('donation.description')}
                buttonText={t('donation.button')}
                projectId={projectInfo.data.projectInfo.projectID}
              />
              <p className={clsx('text-link', 'button_text-link')}>
                <button
                  className="link-button detailed-info__donation-btn"
                  onClick={onClickManageAutopaymentsButton}
                >
                  {t('donation.toManageAutopayments')}
                </button>
              </p>
            </div>
          ) : null}
          {!isEvent ? (
            <div className="detailed-info__docs">
              <ProjectDocs
                title={t('docsTitle')}
                renderDocs={() => renderDocs(getData())}
                style={{
                  maxHeight: `${
                    docsLinkRef?.current || docsDownloadRef?.current
                      ? checkRef(docsLinkRef, docsDownloadRef) *
                        getData().length
                      : '256'
                  }px`,
                }}
              />
            </div>
          ) : null}
        </aside>
      </main>

      {modal ? <SignInModal strict onSuccess={reload} /> : null}

      <NotificationPopup
        className="detailed-info__notification"
        type={notification.type}
        isVisible={isVisible}
        onTimeoutFinished={hideNotification}
      >
        {notification.message}
      </NotificationPopup>
    </div>
  );

  const closeBottomSheet = (event: SpringEvent) => {
    if (event.type === 'CLOSE') {
      setSelectedUser(null);
    }
  };

  const renderMobile = () => (
    <>
      <div
        className={`detailed-info-fixed-header${
          inView ? '' : ' detailed-info-fixed-header--visible'
        }`}
      >
        <h1 className="detailed-info-fixed-header__project-name">
          {projectDescription?.data.description.name}
        </h1>
        <button
          className="detailed-info-fixed-header__close-button"
          onClick={onClickBack}
        >
          <Icon icon={IconType.Cross} />
        </button>
      </div>
      <div className={watchKeyboardAndVisibility`detailed-info`}>
        <div
          className={`detailed-info__image-header${
            inView ? '' : ' detailed-info__image-header--hidden'
          }`}
        >
          <div className="detailed-info__image-wrapper">
            {coverUrl ? (
              <img
                className="detailed-info__image-background"
                src={coverUrl}
                alt="Cover"
              />
            ) : null}
          </div>
          <div className="detailed-info__header">
            <h1 className="detailed-info__project-name">
              {projectDescription?.data.description.name}
            </h1>
            <button
              className="detailed-info__close-button--header"
              onClick={onClickBack}
            >
              <Icon icon={IconType.Cross} />
            </button>
          </div>
          <div className="detailed-info__header-control" ref={inViewRef}>
            <div className="detailed-info__buttons--left">
              {inView &&
              projectStatus?.data.statusInfo.status?.value !==
                PROJECT_STATUS.FRAMED ? (
                <LikeButton
                  projectId={id as string}
                  changeLikeLocal={changeLikeLocal}
                  wasLiked={wasLiked}
                  likesCount={likesCount}
                  className="detailed-info__button--like"
                />
              ) : null}
              <Button
                className="detailed-info__button--comment"
                type={BUTTON_TYPE.TEXT_LIGHT}
                onClick={navigateComments}
                icon
                disabled={!inView}
              >
                <Icon icon={IconType.Comment} />
                <span>{projectComments?.data.commentInfo.comments || 0}</span>
              </Button>
              {projectStatus?.data.statusInfo.status?.value !==
                PROJECT_STATUS.FINISHED && isEvent ? (
                <JoinButtonCompact
                  projectId={id as string}
                  changeJoinLocal={changeJoinLocal}
                  wasJoined={wasJoined}
                  className="detailed-info__button--join"
                  countOfJoined={countOfJoined}
                />
              ) : null}
            </div>
            {editAllowed && projectInfo?.data.projectInfo.ownerId ? (
              <Button
                type={BUTTON_TYPE.TEXT_LIGHT}
                className="detailed-info__edit"
                icon
                onClick={() => navigate(paths.editProjectMenuById(id ?? ''))}
                disabled={!inView}
              >
                <Icon icon={IconType.Pencil} />
                <span>{t('edit')}</span>
              </Button>
            ) : null}
          </div>
        </div>
        <div
          className={`detailed-info__content${
            inView ? '' : ' detailed-info__content--full'
          }`}
        >
          {renderStatusWithTimeline(true)}
          <h3 className="detailed-info__address">{pkkInfo?.address}</h3>
          {renderMeetingPoint(true)}
          {projectInfo && projectStatus?.data.statusInfo.status ? (
            <ProjectMembersWrapper
              isAdmin={isAdmin}
              projectId={projectInfo.data.projectInfo.projectID}
              isInitiatorProjectOwner={
                userData?.data?.userID === projectInfo.data.projectInfo.ownerId
              }
              initiatorName={projectInfo.data.projectInfo.owner?.name}
              initiatorId={projectInfo.data.projectInfo.ownerId}
              onUserClick={(userId: string) => {
                setBottomSheetVisible(true);
                getUserProfile(userId).then((response) => {
                  setSelectedUser(response.data?.data || null);
                });
              }}
              previewMemberIdByRedirect={initialMemberId}
              status={projectStatus?.data.statusInfo.status}
              projectType={layerProjectType[layer || '']}
            />
          ) : null}
          {isNews || isEvent ? null : (
            <ProjectStatus
              className="detailed-info__timeline-chart"
              statuses={statuses.data?.data || []}
              availableStatuses={availableStatuses?.data}
              projectType={projectInfo?.data.projectInfo.layer}
            />
          )}
          {isNews ? null : (
            <PhotoCategoriesTabs
              layer={projectInfo?.data.projectInfo.layer}
              category={photoCategory}
              onTabClick={onTabClick}
            />
          )}
          {photos?.length ? (
            <SwiperCarousel
              className="detailed-info__photos"
              photos={photos}
              clickable
            />
          ) : (
            <ProjectPhotoMock
              category={photoCategory}
              className="detailed-info__photo-mock"
            />
          )}
          <div className="detailed-info__description">
            {projectDescription?.data.description.idea ? (
              <TextEditorPreview>
                {projectDescription?.data.description.idea}
              </TextEditorPreview>
            ) : null}
          </div>
          <div className="detailed-info__diary">
            <h3 className="detailed-info__diary-title">{t('diaryTitle')}</h3>
            <p className="detailed-info__diary-description">
              {t('diaryDescription')}
            </p>
            {diaryEntries?.data?.data.documents.length ? (
              <div className="diary__entries">
                {
                  <>
                    <DiaryEntry
                      projectId={diaryEntries.data.data.documents[0].id}
                      editable={false}
                      deletable={false}
                      key={diaryEntries.data.data.documents[0].id}
                      entry={diaryEntries.data.data.documents[0]}
                      onPhotoClick={onPhotoClick}
                    />
                    <Button
                      icon
                      disabled={archived}
                      className="diary-entry__button"
                      type={BUTTON_TYPE.TEXT_SECONDARY}
                      onClick={() =>
                        navigate(paths.diaryById(id || ''), {
                          state: { goBack: true },
                        })
                      }
                    >
                      <span>{t('openDiary')}</span>
                      <Icon icon={IconType.Arrow} />
                    </Button>
                  </>
                }
              </div>
            ) : (
              <span className="detailed-info__diary-no-data">
                {t('diaryNoData')}
              </span>
            )}
          </div>
          {projectInfo ? (
            <>
              <Donation
                archived={archived}
                title={t('donation.title')}
                description={t('donation.description')}
                buttonText={t('donation.button')}
                projectId={projectInfo.data.projectInfo.projectID}
              />
              {!isEvent ? (
                <ProjectDocs
                  title={t('docsTitle')}
                  renderDocs={() => renderDocs(getData())}
                />
              ) : null}
            </>
          ) : null}
        </div>
      </div>
      <div className={watchKeyboardAndVisibility`detailed-info-fixed-footer`}>
        <div className="detailed-info-fixed-footer__control">
          <div className="detailed-info__buttons--left">
            <LikeButton
              projectId={id as string}
              changeLikeLocal={changeLikeLocal}
              wasLiked={wasLiked}
              likesCount={likesCount}
              className="detailed-info__button--like"
              disabled={
                projectStatus?.data.statusInfo.status?.value ===
                PROJECT_STATUS.FRAMED
              }
            />
            <Button
              className="detailed-info__button--comment"
              type={BUTTON_TYPE.TEXT_SECONDARY}
              onClick={navigateComments}
              icon
            >
              <Icon icon={IconType.Comment} />
              <span>{projectComments?.data.commentInfo.comments || 0}</span>
            </Button>
            {projectStatus?.data.statusInfo.status?.value !==
              PROJECT_STATUS.FINISHED && isEvent ? (
              <JoinButtonCompact
                projectId={id as string}
                changeJoinLocal={changeJoinLocal}
                wasJoined={wasJoined}
                className="detailed-info__button--join"
                countOfJoined={countOfJoined}
              />
            ) : null}
            <MobileShare className="detailed-info__button--share" />
          </div>
          {renderMainActionInFooterMobile()}
        </div>
        {canCreateComment ? (
          <CommentCreation
            extended={keyboardVisible}
            projectId={id ?? ''}
            className="detailed-info__comments-form"
            refreshNewComments={false}
          />
        ) : null}
      </div>
      <BottomSheet
        open={bottomSheetVisible}
        blocking
        onDismiss={() => {
          setBottomSheetVisible(false);
          clearProjects();
        }}
        scrollLocking={false}
        onSpringEnd={closeBottomSheet}
      >
        {selectedUser ? (
          <UserCard
            imageComponent={
              <CatalogAvatar
                rerender
                avatar={(selectedUser.avatar as MinIoUrl[])?.[0].key ?? ''}
              />
            }
            showProjects
            user={selectedUser}
            titles={userCardTitles}
            translateStatus={translateStatus}
            getProjectPath={paths.projectById}
            projectsRef={getProjects}
            projects={projects}
            hasNextPage={hasProjectsNextPage}
          />
        ) : null}
      </BottomSheet>

      <NotificationPopup
        className="detailed-info__notification notification-popup_animation-1s"
        type={notification.type}
        isVisible={isVisible}
        onTimeoutFinished={hideNotification}
        notificationTimeout={1000}
      >
        {notification.message}
      </NotificationPopup>
    </>
  );

  const renderResult = (): JSX.Element => {
    return mobile ? renderMobile() : renderDesktop();
  };

  return projectInfo ? (
    renderResult()
  ) : (
    <div className="detailed-project-info__spinner">
      <Spinner />
    </div>
  );
};

export default DetailedProjectInfo;
