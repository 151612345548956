import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import {
  GetFilesResponse,
  TextInput,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';
import { ru } from 'date-fns/locale';

import { COMPRESSOR_URL_A, COMPRESSOR_URL_B } from 'src/app/constants';

import {
  useCreateFileKeysMutation,
  useGetFilesQuery,
} from 'src/features/files/api';
import { FileCategories } from 'src/features/files/enums';
import StageHeader from 'src/features/layout/components/StageHeader/StageHeader';
import {
  useGetProjectEstimationQuery,
  useLazyCreateProjectBudgetQuery,
} from 'src/features/payment/api';
import {
  PROJ_CREATE,
  setBudget as setProjectBudget,
  setBudgetDocs,
} from 'src/features/project/slices/creatingProjectSlice';
import { PROJ_LAYER } from 'src/features/project/slices/projectsLayerSlice';
import { State } from 'src/features/store/store';

import { EditingOutletProps } from 'src/pages/layouts/EditLayout/models';

import BudgetDoc from './components/BudgetDoc';

import './EditingBudget.scss';

const DOCS_LIMIT = 50;
const DOCS_OFFSET = 0;
const MAX_SUM_SYMBOLS = 10;

const EditingBudget = (): JSX.Element => {
  const dispatch = useDispatch();
  const isSmallScreen = useCheckSmallScreen();
  const { renderFooter }: EditingOutletProps = useOutletContext();

  const projectId = useSelector((s: State) => s[PROJ_CREATE].projectId);
  const projectBudget = useSelector((s: State) => s[PROJ_CREATE].budget);
  const budgetDocs = useSelector((s: State) => s[PROJ_CREATE].budgetDocs);
  const project = useSelector((s: State) => s[PROJ_LAYER].currentProject);

  const projectType = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );

  const { t } = useTranslation('app', {
    keyPrefix: `editing.${projectType}.budget`,
  });

  const [createBudget] = useLazyCreateProjectBudgetQuery();

  const [createFileKeysMutation, createFileKeysData] =
    useCreateFileKeysMutation();

  const { data: projectEstimation, refetch } = useGetProjectEstimationQuery(
    { projectId: projectId as string },
    { skip: !projectId }
  );

  const [modified, setModified] = useState<boolean>(false);
  const [sumWarning, setSumWarning] = useState<boolean>(false);
  const [budget, setBudget] = useState<number | undefined>(projectBudget);
  const [budgetString, setBudgetString] = useState<string | undefined>(
    budget?.toLocaleString(ru.code)
  );
  const [localFiles, setLocalFiles] = useState<GetFilesResponse[]>(budgetDocs);
  const [localLink, setLocalLink] = useState<string>(
    projectEstimation?.data.estimation.link ?? ''
  );

  const { data: remoteDocs } = useGetFilesQuery(
    {
      entityId: project?.id as string,
      category: FileCategories.BUDGET_FILE,
      limit: DOCS_LIMIT,
      offset: DOCS_OFFSET,
    },
    { skip: !project?.id }
  );

  const initData = () => {
    setBudget(projectBudget);
    setBudgetString(projectBudget?.toLocaleString(ru.code));
    setLocalLink(projectEstimation?.data.estimation.link ?? '');
    if (remoteDocs?.data.length) {
      dispatch(setBudgetDocs(remoteDocs.data));
      setLocalFiles(remoteDocs.data);
    }

    if (projectEstimation?.data.estimation.budget) {
      dispatch(setProjectBudget(projectEstimation?.data.estimation.budget));
    }
  };

  useEffect(initData, [
    dispatch,
    projectBudget,
    remoteDocs?.data,
    projectEstimation?.data.estimation.link,
    projectEstimation?.data.estimation.budget,
  ]);

  const valid = useMemo(() => {
    const divergesFromRemote =
      (projectBudget !== budget && (localLink !== '' || localFiles.length)) ||
      (projectBudget === budget &&
        localLink !== projectEstimation?.data.estimation.link);

    const requiredFilled =
      !!budget && budget > 0 && (!!localFiles.length || !!localLink);

    return requiredFilled && !!divergesFromRemote;
  }, [
    budget,
    localLink,
    projectBudget,
    localFiles.length,
    projectEstimation?.data.estimation.link,
  ]);

  const arrayEquals = (a: GetFilesResponse[], b: GetFilesResponse[]) => {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a[0]?.key === b[0]?.key
    );
  };

  const onDocumentSave = async () => {
    if (!arrayEquals(localFiles, budgetDocs)) {
      if (projectId) {
        return createFileKeysMutation({
          entityId: projectId,
          category: FileCategories.BUDGET_FILE,
          newFiles: {
            list: localFiles.map((f) => ({
              key: f.key,
              name: f.name || 'file',
            })),
          },
        }).then(() => {
          dispatch(setBudgetDocs(localFiles));
          createFileKeysData.reset();
        });
      }
    }
    return Promise;
  };

  const updateBudget = (projectId: string, budget: number, link?: string) => {
    if (link !== '') {
      return createBudget({ projectId, budget, link });
    }
    return createBudget({ projectId, budget });
  };

  const onBudgetSave = () => {
    if (projectId && budget) {
      return updateBudget(projectId, budget, localLink).then((result) => {
        if (result.isSuccess) {
          dispatch(setProjectBudget(budget));
          refetch();
        }
        return result;
      });
    }
  };

  const onCancel = () => {
    setModified(false);
    setBudget(projectBudget);
    setBudgetString(projectBudget?.toLocaleString(ru.code));
    setSumWarning(false);
    setLocalFiles(remoteDocs?.data || []);
    setLocalLink(projectEstimation?.data.estimation.link ?? '');
  };

  const onSave = () => {
    setModified(false);
    onDocumentSave().then(onBudgetSave);
  };

  const checkSumInput = () => {
    if (!budget) {
      setSumWarning(true);
    }
  };

  const onSumChange = (value: string) => {
    setModified(true);
    const _value = value.replace('\u00A0', '').replace(',', '.');
    let output = '';
    for (let i = 0; i < _value.length && i < MAX_SUM_SYMBOLS; i++) {
      const charCode = _value.charCodeAt(i);
      if (
        (48 <= charCode && charCode <= 57) ||
        charCode == 46 ||
        charCode == 44
      ) {
        output += _value[i];
      }
    }
    const lastCharCode = output.charCodeAt(output.length - 1);
    if (lastCharCode !== 46 && lastCharCode !== 44) {
      const v = parseFloat(output);
      setBudgetString(v.toLocaleString(ru.code));
      if (v !== budget && v !== 0) {
        setBudget(v);
      }
    } else {
      setBudgetString(output);
    }
  };

  const onFiles = (files: GetFilesResponse[]) => {
    setModified(true);
    setLocalFiles((prev) => [...prev, ...files]);
    refetch();
  };

  const onLink = (link: string) => {
    setModified(true);
    setLocalLink(link);
    refetch();
  };

  const onDelete = () => {
    setModified(true);
    setLocalFiles([]);
    setLocalLink('');
  };

  const createLink = (href: string) => (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a className="text-link" rel="noreferrer" target="_blank" href={href} />
  );

  const hint = localFiles.length ? null : (
    <p className="budget__hint">
      <Trans
        i18nKey={`editing.${projectType}.budget.budgetHint`}
        components={{
          linkA: createLink(COMPRESSOR_URL_A),
          linkB: createLink(COMPRESSOR_URL_B),
          accent: <span className="budget__hint-accent" />,
        }}
      />
    </p>
  );

  return (
    <>
      <div className="budget">
        {isSmallScreen ? null : <StageHeader header={t('title')} />}
        <h2 className="budget__subtitle">{t('description')}</h2>
        <BudgetDoc
          files={localFiles}
          link={localLink}
          onFiles={onFiles}
          onLink={onLink}
          onDelete={onDelete}
        />
        {hint}
        <h2 className="budget__subtitle">{t('sum.description')}</h2>
        <TextInput
          className={`budget__input ${
            sumWarning ? 'budget__input--error' : ''
          }`}
          type="text"
          placeholder={t('sum.placeholder')}
          value={budget && budgetString ? budgetString : ''}
          onChange={(e) => onSumChange(e.target.value)}
          onFocus={() => setSumWarning(false)}
          onFocusLeave={checkSumInput}
        />
        {sumWarning ? (
          <span className="budget-input__error">{t('sum.warning')}</span>
        ) : null}
      </div>
      {renderFooter?.({ onCancel, onSave, valid, modified })}
    </>
  );
};

export default EditingBudget;
