import { useSelector } from 'react-redux';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { VIEW_CARD } from 'src/features/control/enums';
import { CONTROL } from 'src/features/control/slices/controlSlice';
import { DRAW } from 'src/features/draw/slices/drawSlice';
import PkkDoesNotResponseInfo from 'src/features/map/components/PkkDoesNotResponseInfo/PkkDoesNotResponseInfo';
import InformationCard from 'src/features/project/components/InformationCard/InformationCard';
import { State } from 'src/features/store/store';

const MobileInfoBlock = () => {
  const wasDraw = useSelector((state: State) => !!state[DRAW].drawnShape);
  const isDrawEnable = useSelector((s: State) => s[DRAW].isDrawEnable);
  const currentCard = useSelector((s: State) => s[CONTROL].currentCard);
  const isSmallScreen = useCheckSmallScreen();
  return isSmallScreen ? (
    <>
      <CadastralErrorInfo />
      {wasDraw || isDrawEnable
        ? currentCard === VIEW_CARD.LAND_INFORMATION && <InformationCard />
        : null}
    </>
  ) : null;
};

export default MobileInfoBlock;

const CadastralErrorInfo = () => {
  const pkkAccessability =
    sessionStorage.getItem('pkkAccessability') === 'true';
  const showCadastralLayerError = useSelector(
    (s: State) => s[CONTROL].showCadastralLayerError
  );
  return !pkkAccessability && showCadastralLayerError ? (
    <PkkDoesNotResponseInfo className="map-do-not-response-card" />
  ) : null;
};
