import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDispatch, useSelector } from 'react-redux';

import {
  Table,
  TableData,
} from '@platform-for-public-places/components-library';
import { ru } from 'date-fns/locale';

import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { ProjectTeamRequests } from 'src/features/project/models';
import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import {
  useLazyAcceptSpecialistsByRequestIdQuery,
  useLazyRejectRequestByIdRequestQuery,
} from 'src/features/request/api';
import { Tag } from 'src/features/request/models';
import { setSelectedUserId } from 'src/features/searchWithFilter/filter/slices/filterSliceData';
import { State } from 'src/features/store/store';
import { ProjectSpecialist } from 'src/features/user/models';

import { MembersIncomingTableContent, MembersTableProps } from '../../models';

import MembersInboxRow from '../MembersIncomingRow/MembersIncomingRow';

import './MembersIncomingTable.scss';

const MembersIncomingTable = ({
  className,
  data,
  placeholder,
  requestAdditionalData,
  error = false,
  infinite = false,
  onEndReached,
}: MembersTableProps) => {
  const dispatch = useDispatch();
  const projectType = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );
  const { t } = useTranslation('app', {
    keyPrefix: `creation.${projectType}.team`,
  });

  const [loading, setLoading] = useState<boolean>(false);

  const onLoadMore = () => {
    setLoading(true);
    onEndReached?.();
    setLoading(false);
  };

  const [tailRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: data.specialists.length < data.count,
    disabled: error,
    onLoadMore,
  });

  const [rejectRequest] = useLazyRejectRequestByIdRequestQuery();
  const [acceptRequest] = useLazyAcceptSpecialistsByRequestIdQuery();

  const getTagsNames = (tags: Tag[] = []) => tags.map((tag) => tag.name);

  const onMemberClick = useCallback(
    (userId: string | undefined) => {
      dispatch(setSelectedUserId(userId ?? ''));
      dispatch(changeModal(MODALS.SPECIALIST_USER_CARD));
    },
    [dispatch]
  );

  const approveSpecialist = (
    requestId: string,
    specialist: ProjectSpecialist
  ) => {
    acceptRequest({ requestId })
      .unwrap()
      .then(({ data, success, errors }) => {
        if (!success || errors.length) {
          requestAdditionalData.onError?.();
        } else {
          requestAdditionalData.onApprove?.({
            ...specialist,
            tags: data.request.requestBody.projectSpecialist.tags,
            roles: data.request.requestBody.projectSpecialist.roles,
          });
        }
      })
      .catch(() => requestAdditionalData.onError?.());
  };

  const rejectSpecialist = (
    requestId: string,
    specialist: ProjectSpecialist
  ) => {
    rejectRequest({
      requestId,
      message: requestAdditionalData.message,
    })
      .unwrap()
      .then((response) => {
        if (!response.success || response.errors.length) {
          requestAdditionalData.onError?.();
        } else {
          requestAdditionalData.onReject?.(specialist);
        }
      })
      .catch(() => requestAdditionalData.onError?.());
  };

  const getData = (
    data: ProjectTeamRequests
  ): MembersIncomingTableContent[] => {
    return data.specialists.map(({ request: r, specialist }) => {
      return {
        date: new Date(r.updatedAt).toLocaleDateString(ru.code),
        specialist: {
          name: specialist.name,
          expand: () => onMemberClick(r.requestBody.projectSpecialist.userId),
        },
        roles: getTagsNames(r.requestBody.projectSpecialist.tags),
        comment: r.requestBody.message,
        actions: {
          approve: () => approveSpecialist(r.requestID, specialist),
          reject: () => rejectSpecialist(r.requestID, specialist),
        },
        message: {
          data: requestAdditionalData.message,
          setData: requestAdditionalData.setMessage,
        },
      };
    });
  };

  const prepareTableData = (): TableData<MembersIncomingTableContent> => ({
    columns: [
      { src: 'date', label: t('tableColumns.date') },
      { src: 'specialist', label: t('tableColumns.specialist') },
      { src: 'role', label: t('tableColumns.role') },
      { src: 'comment', label: t('tableColumns.comment') },
      { src: 'action', label: t('tableColumns.action') },
    ],
    content: getData(data),
  });

  return (
    <>
      <Table<MembersIncomingTableContent>
        className={className}
        placeholder={placeholder}
        data={prepareTableData()}
        rowComponent={MembersInboxRow}
        headerClass="members-incoming-table__header"
        columnsClass="members-incoming-table__columns"
        bodyClass="members-incoming-table__body"
      />
      {infinite && onEndReached ? <div ref={tailRef} /> : null}
    </>
  );
};

export default MembersIncomingTable;
