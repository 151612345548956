import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AvailableStatusesResponse,
  Icon,
  IconType,
  PROJECT_STATUS,
  SPECIFIC_LAYER,
  STAGES,
  Status as ProjectStatus,
  timestampToString,
} from '@platform-for-public-places/components-library';

import { filterEnabledStatuses } from '../../hooks';

import './ProjectStatusVertical.scss';

interface ProjectStatusVerticalProps {
  statuses: ProjectStatus[];
  className?: string;
  grayscale?: boolean;
  availableStatuses?: AvailableStatusesResponse;
  projectType?: SPECIFIC_LAYER;
}

const ProjectStatusVertical = ({
  statuses,
  className = '',
  grayscale = false,
  availableStatuses,
  projectType = SPECIFIC_LAYER.DEFAULT,
}: ProjectStatusVerticalProps) => {
  const { t } = useTranslation();

  const [visibleHint, setVisibleHint] = useState<number | null>(null);

  const last = useMemo(() => statuses[statuses.length - 1], [statuses]);
  const hints: string[] = useMemo(
    () =>
      availableStatuses?.flowSteps.map((step) =>
        t(`hints.projectStatus.${projectType}.${step.name}`)
      ) ?? [],
    [t, projectType, availableStatuses]
  );
  const availableSteps = availableStatuses
    ? filterEnabledStatuses(availableStatuses?.flowSteps)
    : Object.values(PROJECT_STATUS);
  const pointsCount = availableSteps.length;
  const barsCount = availableSteps.length - 1;

  const drawBarRow = () => {
    const parts: JSX.Element[] = [];
    for (let i = 0; i < barsCount; i++) {
      if (statuses.length && last) {
        const passed = i < STAGES[last.value];
        const computedClass = `project-status-vertical__bar-part${
          passed ? '--passed' : ''
        }`;
        parts.push(<div key={i} className={computedClass} />);
      } else {
        parts.push(
          <div key={i} className="project-status-vertical__bar-part" />
        );
      }
    }
    return parts;
  };

  const drawPoints = () => {
    const points: JSX.Element[] = [];
    for (let i = 0; i < pointsCount; i++) {
      if (statuses.length && last) {
        const passed = i === 0 || i <= STAGES[last.value];
        points.push(
          <div
            className="project-status-vertical__icon-wrapper"
            key={i}
            onMouseOver={() => setVisibleHint(i)}
            onMouseOut={() => setVisibleHint(null)}
            onFocus={() => setVisibleHint(i)}
            onBlur={() => setVisibleHint(null)}
          >
            <Icon
              key={i}
              icon={IconType.StatusPoint}
              className={`project-status-vertical__point${
                passed ? '--passed' : ''
              }`}
            />
          </div>
        );
      } else {
        points.push(
          <div
            className="project-status-vertical__icon-wrapper"
            key={i}
            onMouseOver={() => setVisibleHint(i)}
            onMouseOut={() => setVisibleHint(null)}
            onFocus={() => setVisibleHint(i)}
            onBlur={() => setVisibleHint(null)}
          >
            <Icon
              key={i}
              icon={IconType.StatusPoint}
              className="project-status-vertical__point"
            />
          </div>
        );
      }
    }
    return points;
  };

  const drawStrings = () =>
    availableSteps.map((status, i) => {
      const passed = last && i <= STAGES[last.value];
      return (
        <div key={i} className="project-status-vertical__hint-wrapper">
          <span
            className={`project-status-vertical__status${
              passed ? '--passed' : ''
            }`}
            onMouseOver={() => setVisibleHint(i)}
            onMouseOut={() => setVisibleHint(null)}
            onFocus={() => setVisibleHint(i)}
            onBlur={() => setVisibleHint(null)}
          >
            {t(`cards.overview.projectStatus.${projectType}.${status}`)}
          </span>
          <div
            className={`project-status-vertical__hint${
              i !== visibleHint ? ' project-status-vertical__hint--hidden' : ''
            }`}
          >
            {hints[i]}
          </div>
        </div>
      );
    });

  const drawDates = () => {
    if (last && availableSteps.length > 0) {
      const statusesDates = statuses.map((value, i) => (
        <span
          key={i}
          onMouseOver={() => setVisibleHint(i)}
          onMouseOut={() => setVisibleHint(null)}
          onFocus={() => setVisibleHint(i)}
          onBlur={() => setVisibleHint(null)}
        >
          {timestampToString(value.updated, true)}
        </span>
      ));
      const lastDate = last.updated;
      for (let i = statuses.length; i < availableSteps.length; i++) {
        statusesDates.push(
          <span
            key={i}
            onMouseOver={() => setVisibleHint(i)}
            onMouseOut={() => setVisibleHint(null)}
            onFocus={() => setVisibleHint(i)}
            onBlur={() => setVisibleHint(null)}
          >
            {i <= last.order && timestampToString(lastDate, true)}
          </span>
        );
      }
      return statusesDates;
    }
  };

  const dynamicClass = (s: TemplateStringsArray) => {
    const modifier = grayscale ? `${s[0]}--grayscale` : '';
    return `${s[0]} ${modifier} ${className}`;
  };

  return (
    <div className={dynamicClass`project-status-vertical`}>
      <div className="project-status-vertical__dates">{drawDates()}</div>
      <div className="project-status-vertical__bar-row">{drawBarRow()}</div>
      <div className="project-status-vertical__point-row">{drawPoints()}</div>
      <div className="project-status-vertical__text-row">{drawStrings()}</div>
    </div>
  );
};

export default ProjectStatusVertical;
