export const convertBudgetToPercents = (
  totalDonation: number,
  totalBudget: number
): number => {
  if (!totalDonation) {
    return 0;
  }
  if (!totalBudget) {
    return 25;
  }
  return Number.parseFloat(((totalDonation / totalBudget) * 100).toFixed(1));
};
