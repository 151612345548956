import { useEffect, useMemo, useState } from 'react';
import {
  DayPickerProvider,
  NavigationProvider as DPNavigationProvider,
  SelectSingleEventHandler,
} from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  GetFilesResponse,
  Icon,
  IconType,
  MIME_JPEG,
  MIME_PNG,
  MinIoUrl,
  PROJECT_STATUS,
  TextArea,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';
import { ru } from 'date-fns/locale';

import { EMPTY_EDITOR_STRING } from 'src/app/constants';
import DatePicker from 'src/shared/components/DatePicker/DatePicker';
import FileInput from 'src/shared/components/inputs/FileInput/FileInput';
import TextEditor from 'src/shared/components/TextEditor/TextEditor';
import TextEditorPreview from 'src/shared/components/TextEditorPreview/TextEditorPreview';
import { htmlToMarkdown, markdownToHtml } from 'src/shared/converters';
import { paths } from 'src/shared/routes';

import { USER_ROLE } from 'src/features/auth/models';
import { GetDiaryEntryResponse } from 'src/features/diary/models';
import { useGetFilesQuery } from 'src/features/files/api';
import { FileCategories } from 'src/features/files/enums';
import { useCheckUser, useTranslationStatus } from 'src/features/hooks';
import { useGetProjectInfoAndCheck } from 'src/features/project/hooks';

import {
  useIsArchived,
  useUploadFileFromLink,
  useUploadFiles,
} from 'src/pages/project/hooks';

import {
  useDeleteDiaryEntryMutation,
  useUpsertDiaryEntryMutation,
} from '../../api';

import { setDiary } from '../../slices/diarySlice';

import { ReactComponent as Marker } from './img/marker.svg';

import 'react-day-picker/dist/style.css';
import './DiaryEntry.scss';

interface DiaryEntryProps {
  projectId: string;
  entry?: GetDiaryEntryResponse;
  initEditing?: boolean;
  creatable?: boolean;
  editable?: boolean;
  deletable?: boolean;
  onPhotoClick?: (photos: GetFilesResponse[], openIndex: number) => void;
  onDeleteClick?: (entryId: string, isResponseSuccess: boolean) => void;
  onSubmitClick?: () => void;
  resetDiary?: () => void;
}

enum ERRORS {
  EMPTY = 'error.fieldEmpty',
  TOO_MATCH_SYMBOLS = 'error.tooLongIdea',
}

export const MAX_PHOTOS = 10;
export const FILES_LIMIT = 10;
export const FILES_OFFSET = 0;
export const HTML_TAG_REGEX = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
export const MILLSECS_FACTOR = 1000;
export const ANIMATION_CYCLE = 11000; // animation-delay (10s) + animation-duration (1s)
export const MAX_TEXT_SYMBOLS = 3000;
export const MAX_TITLE_SYMBOLS = 500;
export const ACCEPTABLE_TEXT_REGEX =
  /^<[p|h]{1}[1-3]{0,1}>[<br>|\s]+<\/[p|h]{1}[1-3]{0,1}>$/;
export const ACCEPTABLE_FILES_TYPES = `${MIME_PNG}, ${MIME_JPEG}`;

/**
 * This implementation is used for rendering diary enties on desktop and mobile devices.
 * Also this implementation can create and edit entries on desktop.
 */
const DiaryEntry = ({
  projectId,
  entry,
  initEditing = false,
  creatable = false,
  editable = false,
  deletable = false,
  resetDiary = () => null,
  onPhotoClick = () => null,
  onDeleteClick = () => null,
  onSubmitClick = () => null,
}: DiaryEntryProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mobile = useCheckSmallScreen();
  const archived = useIsArchived();
  const { id } = useParams();
  const isAdmin = useCheckUser([{ role: USER_ROLE.ADMIN }]);

  const { data: projectInfo } = useGetProjectInfoAndCheck(id, archived);

  const projectType = projectInfo?.data.projectInfo.layer;

  const translateStatus = useTranslationStatus(projectType);

  const { t } = useTranslation('app', {
    keyPrefix: `editing.${projectType}.diary`,
  });

  const {
    data: fetchedPhotos,
    isFetching: isFetchingPhotos,
    isSuccess: isSuccessPhotos,
    refetch,
  } = useGetFilesQuery(
    {
      entityId: entry?.id as string,
      category: FileCategories.DIARY_FILE,
      limit: FILES_LIMIT,
      offset: FILES_OFFSET,
    },
    { skip: !entry?.id }
  );

  const [createEntry] = useUpsertDiaryEntryMutation();
  const [updateEntry] = useUpsertDiaryEntryMutation();
  const [deleteEntry] = useDeleteDiaryEntryMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const [title, setTitle] = useState<string>(entry?.title ?? '');
  const [titleError, setTitleError] = useState<string>('');

  const [text, setText] = useState<string>(markdownToHtml(entry?.text ?? ''));
  const [textError, setTextError] = useState<string>('');

  const [photos, setPhotos] = useState<MinIoUrl[]>(fetchedPhotos?.data || []);
  const [photosError, setPhotosError] = useState<string>('');

  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [datePickerError, setDatePickerError] = useState<string>('');
  const [date, setDate] = useState<Date | undefined>(
    entry ? new Date(entry?.created * MILLSECS_FACTOR) : undefined // undefined is day-picker requirement
  );

  const [showHint, setShowHint] = useState<boolean>(false);

  const [modified, setModified] = useState(false);
  const [editing, setEditing] = useState<boolean>(initEditing);
  const [creationMode, setCreationMode] = useState<boolean>(false);
  const [actionsVisible, setActionsVisibility] = useState<boolean>(false);

  useEffect(() => {
    if (!isFetchingPhotos && isSuccessPhotos && fetchedPhotos?.data) {
      setPhotos(fetchedPhotos.data);
    }
  }, [isFetchingPhotos, isSuccessPhotos, fetchedPhotos?.data]);

  const checkTitleInput = () => {
    const trimmedTitle = title.trim();
    setTitle(trimmedTitle);
    const length = trimmedTitle.length;
    if (!length) {
      setTitleError(t(ERRORS.EMPTY));
    } else if (trimmedTitle.length > MAX_TITLE_SYMBOLS) {
      setTitleError(t(ERRORS.TOO_MATCH_SYMBOLS));
    } else {
      setTitleError('');
    }
  };

  const onTitleChange = (value: string) => {
    if (titleError) {
      setTitleError('');
    }
    setTitle(value);
    setModified(true);
  };

  const checkTextInput = () => {
    const trimmedText = text.trim();

    if (trimmedText.length === 0 || trimmedText.match(ACCEPTABLE_TEXT_REGEX)) {
      setTextError(t(ERRORS.EMPTY));
    } else if (
      trimmedText.replace(HTML_TAG_REGEX, '').length > MAX_TEXT_SYMBOLS
    ) {
      setTextError(t(ERRORS.TOO_MATCH_SYMBOLS));
    } else {
      setTextError('');
    }
  };

  const onTextChange = (value: string) => {
    if (textError) {
      setTextError('');
    }
    setText(value);
    setModified(true);
  };

  const photosOverflow = useMemo(
    () => photos.length >= MAX_PHOTOS,
    [photos.length]
  );

  const uploadFiles = useUploadFiles(
    [MIME_JPEG, MIME_PNG],
    (files) => {
      setPhotos((prev) => [...prev, ...files]);
    },
    setPhotosError
  );

  const uploadFileFromLink = useUploadFileFromLink(
    [MIME_JPEG, MIME_PNG],
    (file) => {
      setPhotos((prev) => {
        if (prev.length < MAX_PHOTOS) {
          return [...prev, file];
        }
        return prev;
      });
    },
    setPhotosError
  );

  const toggleActions = () => setActionsVisibility((p) => !p);

  const enableEditing = () => {
    if (mobile && entry) {
      dispatch(setDiary(entry));
      navigate(paths.editProjectByIdDiaryCreation(entry.projectId));
    } else {
      setActionsVisibility(false);
      setEditing(true);
      setTimeout(() => setModified(false), 1); // Setting in end of event loop for garantee flag changing.
    }
  };

  const sendDeleteEntryRequest = () => {
    if (!mobile && entry?.id) {
      deleteEntry({ projectDiaryEntryId: entry.id }).then((response) => {
        onDeleteClick(entry.id, !('error' in response));
      });
    }
  };

  const resetErros = () => {
    setTitleError('');
    setTextError('');
    setPhotosError('');
  };

  const onCreatableAbort = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setEditing(false);
    setCreationMode(false);
    setShowPicker(false);
    setDatePickerError('');
    setTitle('');
    setText('');
    resetErros();
    setDate(undefined); // undefined is day-picker requirement
    setPhotos([]);
  };

  const onAbort = () => {
    resetErros();
    setEditing(false);
    setShowPicker(false);
    setDate(entry ? new Date(entry?.created * MILLSECS_FACTOR) : undefined); // undefined is day-picker requirement
    setTitle(entry?.title ?? '');
    setText(markdownToHtml(entry?.text ?? ''));
    if (fetchedPhotos?.data) {
      setPhotos(fetchedPhotos?.data || []);
    }
  };

  const onCreatableSubmit = () => {
    if (date) {
      createEntry({
        category: FileCategories.DIARY_FILE,
        projectId: projectId,
        date: date.toISOString(),
        name: title,
        comment: htmlToMarkdown(text),
        newFiles: {
          list: photos.map((photo) => ({ key: photo.key })),
        },
      }).then(() => {
        onCreatableAbort();
        resetDiary();
        setShowHint(true);
        setTimeout(() => setShowHint(false), ANIMATION_CYCLE);
      });
    }
  };

  const onSubmit = () => {
    resetErros();
    setEditing(false);
    if (entry?.id && date) {
      updateEntry({
        category: FileCategories.DIARY_FILE,
        projectId,
        diaryEntryId: entry.id,
        date: date.toISOString(),
        name: title,
        comment: htmlToMarkdown(text),
        newFiles: {
          list: photos.map((photo) => ({ key: photo.key })),
        },
      }).then((response) => {
        if (!('error' in response)) {
          onSubmitClick();
        }
      });
    }
  };

  const onDatePick: SelectSingleEventHandler = (_, selectedDay) => {
    setDate(selectedDay);
    setShowPicker(false);
    setModified(true);
  };

  const hideDatePicker = () => {
    setShowPicker(false);
    if (!date) {
      setDatePickerError(t('error.dateNotSelected'));
    } else {
      setDatePickerError('');
    }
  };

  const removePhoto = (image: MinIoUrl) => {
    setPhotos((prev) => {
      setPhotosError('');
      setModified(true);
      return prev.filter((element) => element !== image);
    });
  };

  const createClass = (s: TemplateStringsArray) => {
    let resultClassName = s[0];
    if (editing) {
      resultClassName = `${resultClassName} ${s[0]}--editing`;
    }
    if (entry && entry.autoCreation) {
      resultClassName = `${resultClassName} ${s[0]}--status`;
    }
    if (creatable) {
      resultClassName = `${resultClassName} ${s[0]}--creatable`;
    }
    return resultClassName;
  };

  const renderEditButton = () => (
    <Button
      className="diary-entry__edit"
      type={BUTTON_TYPE.TEXT_SECONDARY}
      onClick={enableEditing}
    >
      <Icon icon={IconType.Pencil} />
      <span className="diary-entry__edit-label">{t('edit')}</span>
    </Button>
  );

  const renderDesktopEditButton = () => {
    if (!(mobile || editing || !editable)) {
      return renderEditButton();
    }
  };

  const renderActions = () => {
    const renderPopUp = () => (
      <div className="diary-entry__popup">
        <Button
          className="diary-entry__popup-trigger"
          type={BUTTON_TYPE.TEXT_SECONDARY}
          onClick={toggleActions}
        >
          <Icon icon={IconType.Dots} />
        </Button>
        {actionsVisible ? (
          <div className="diary-entry__popup-actions">
            <Button
              className="diary-entry__popup-action--edit"
              type={BUTTON_TYPE.TEXT_SECONDARY}
              onClick={enableEditing}
            >
              <Icon icon={IconType.Pencil} />
              <span>{t('edit')}</span>
            </Button>
            <Button
              className="diary-entry__popup-action--delete"
              type={BUTTON_TYPE.TEXT_SECONDARY}
              onClick={sendDeleteEntryRequest}
            >
              <Icon icon={IconType.Bin} />
              <span>{t('delete')}</span>
            </Button>
          </div>
        ) : null}
      </div>
    );

    if (mobile && !editing) {
      if (deletable) {
        return renderPopUp();
      } else if (editable) {
        return renderEditButton();
      }
    }

    return null;
  };

  const renderPhotos = (photos?: GetFilesResponse[]): JSX.Element => {
    const renderPhotosView = () => {
      if (photos && photos.length) {
        const smallClass =
          !mobile && photos.length > 3 ? 'diary-entry__photos--small' : '';
        return (
          <div className={`diary-entry__photos ${smallClass}`}>
            {photos.map(({ key, url }, i) => {
              return (
                <button key={key} onClick={() => onPhotoClick(photos, i)}>
                  <img src={url} alt={`Diary ${i + 1}`} />
                </button>
              );
            })}
          </div>
        );
      } else {
        return <></>;
      }
    };

    const renderPhotosEdit = () => {
      return (
        <>
          <FileInput
            erase
            multiple
            disabled={photosOverflow}
            accept={ACCEPTABLE_FILES_TYPES}
            buttonType={BUTTON_TYPE.SECONDARY}
            className="diary-entry__file"
            error={photosError}
            overflow={photosOverflow}
            onError={setPhotosError}
            onLink={(link) => {
              uploadFileFromLink(link);
              setModified(true);
            }}
            onFiles={(files) => {
              uploadFiles(files, MAX_PHOTOS - (photos?.length || 0));
              setModified(true);
            }}
          />

          {photos?.length ? (
            <div className="diary-entry__photos-preview">
              {photos.map((p, index) => (
                <div
                  key={`photos-${index}`}
                  className="diary-entry__preview-image"
                >
                  <img src={p.url} alt={'preview'} />
                  <div className="diary-entry__overlay">
                    <button onClick={() => removePhoto(p)}>
                      <Icon icon={IconType.Cross} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </>
      );
    };

    return (editing ? renderPhotosEdit : renderPhotosView)();
  };

  const renderSubmitButton = (text: string, onClick: () => void) => {
    return (
      <Button
        disabled={
          !date ||
          !modified ||
          title.length < 1 ||
          text.length < 1 ||
          titleError !== '' ||
          textError !== '' ||
          EMPTY_EDITOR_STRING === text
        }
        className="diary-entry__action"
        type={BUTTON_TYPE.PRIMARY}
        onClick={() => {
          checkTextInput();
          checkTitleInput();
          if (!(textError || titleError)) {
            onClick();
          }
        }}
      >
        {text}
      </Button>
    );
  };

  const renderCancelButton = (onClick: () => void) => {
    return (
      <Button
        className="diary-entry__action"
        type={BUTTON_TYPE.TEXT_SECONDARY}
        onClick={onClick}
      >
        {t('cancel')}
      </Button>
    );
  };

  const renderDeleteButton = () => {
    if (!isAdmin || creatable) {
      return;
    }

    return (
      <Button
        className="diary-entry__delete"
        type={BUTTON_TYPE.TEXT_SECONDARY}
        onClick={sendDeleteEntryRequest}
      >
        <Icon icon={IconType.Bin} />
        <span>{t('delete')}</span>
      </Button>
    );
  };

  const renderEditFooterButtons = () => {
    if (!editing) {
      return;
    }

    return (
      <div className="diary-entry__actions">
        {creatable
          ? renderSubmitButton(t('publish'), onCreatableSubmit)
          : renderSubmitButton(t('save'), onSubmit)}

        {creatable
          ? renderCancelButton(onCreatableAbort)
          : renderCancelButton(onAbort)}

        {renderDeleteButton()}
      </div>
    );
  };

  const renderDatePicker = () => {
    if (!showPicker) {
      return;
    }

    return (
      <DayPickerProvider initialProps={{ mode: 'single' }}>
        <DPNavigationProvider>
          <DatePicker
            className="diary-entry__date-picker"
            selected={date}
            onSelect={onDatePick}
            onOutsideClick={hideDatePicker}
          />
        </DPNavigationProvider>
      </DayPickerProvider>
    );
  };

  const renderDiaryEntryText = (text: string) => {
    if (!editing) {
      return <TextEditorPreview>{htmlToMarkdown(text)}</TextEditorPreview>;
    }

    return (
      <TextEditor
        className={createClass`diary-entry__text`}
        placeholder={t('textPlaceholder')}
        value={text}
        maxLength={MAX_TEXT_SYMBOLS}
        onChange={onTextChange}
        onBlur={checkTextInput}
      />
    );
  };

  const renderHint = () => {
    if (!showHint) {
      return;
    }

    return <span className="diary-entry__hint">{t('hint')}</span>;
  };

  const renderCreationButton = () => (
    <Button
      className="diary-entry__creation-button"
      type={BUTTON_TYPE.PRIMARY}
      onClick={() => {
        setCreationMode(true);
        setEditing(true);
      }}
    >
      <Icon icon={IconType.Feather} />
      <span>{t('addEntry')}</span>
    </Button>
  );

  const renderDiaryEntry = () => (
    <div className={createClass`diary-entry`}>
      <header className={createClass`diary-entry__header`}>
        <Marker className={createClass`diary-entry__marker`} />
        {renderDatePicker()}
        <div
          className={`diary-entry__date-and-edit${mobile ? '--mobile' : ''}`}
        >
          <Button
            disabled={!editing}
            className={createClass`diary-entry__date`}
            type={BUTTON_TYPE.SECONDARY}
            onClick={() => setShowPicker(true)}
          >
            <Icon icon={IconType.Calendar} />
            {date ? date.toLocaleDateString(ru.code) : t('pickDate')}
          </Button>
          <span
            className={`diary-entry__pick-date-error diary-entry__pick-date-error${
              !date && datePickerError ? '--active' : ''
            }`}
          >
            {datePickerError}
          </span>

          {renderActions()}
        </div>
        <div className="diary-entry__title-wrapper">
          <TextArea
            disabled={!editing}
            className={createClass`diary-entry__title`}
            value={
              entry && entry.autoCreation && projectType
                ? translateStatus(title as PROJECT_STATUS)
                : title
            }
            fitContent={!editing}
            maxLength={MAX_TITLE_SYMBOLS}
            placeholder={t('titlePlaceholder')}
            onChange={(e) => onTitleChange(e.target.value)}
            onBlur={() => checkTitleInput()}
            initialEditingHeight={90}
            initialHeight={mobile ? 20 : 25}
          />
          <span className={`diary-entry__error${titleError ? '--active' : ''}`}>
            {titleError}
          </span>
        </div>

        {renderDesktopEditButton()}
      </header>
      <article className={createClass`diary-entry__article`}>
        <div
          className={`diary-entry__text-wrapper${editing ? '--editing' : ''}`}
        >
          {renderDiaryEntryText(text)}
          <span className={`diary-entry__error${textError ? '--active' : ''}`}>
            {textError}
          </span>
        </div>

        {renderPhotos(photos)}

        {renderEditFooterButtons()}
      </article>
      <div className="diary-entry__hint-container">{renderHint()}</div>
    </div>
  );

  const render =
    creatable && !creationMode ? renderCreationButton : renderDiaryEntry;

  return render();
};

export default DiaryEntry;
