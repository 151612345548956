import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  Table,
  TableData,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import {
  RECURRENT_PAYMENT_STATUS,
  RecurrentUserPaymentData,
} from 'src/features/payment/models';

import RecurrentUserPaymentsTableRow, {
  RecurrentUserPaymentsTableContent,
} from './components/RecurrentUserPaymentsTableRow';

import './RecurrentUserPaymentsTable.scss';

interface RecurrentUserPaymentsTableProps {
  recurrentUserPayments: RecurrentUserPaymentData[];
  changeSortDirection: (src: string) => void;
  stopSupportPayment: (paymentId: string, projectName: string) => void;
  showPlaceholder?: boolean;
}

const RecurrentUserPaymentsTable = ({
  recurrentUserPayments,
  changeSortDirection,
  stopSupportPayment,
  showPlaceholder = true,
}: RecurrentUserPaymentsTableProps) => {
  const { t } = useTranslation('app', {
    keyPrefix: `profile.payments.recurrent`,
  });
  const prepareRecurrentUserPaymentsTableData = useMemo(() => {
    return (): TableData<RecurrentUserPaymentsTableContent> => {
      const columns = [
        {
          src: 'projectName',
          label: t('table.projectName'),
          sortable: true,
          changeSortDirection,
        },
        {
          src: 'amount',
          label: t('table.amount'),
          sortable: true,
          changeSortDirection,
        },
        {
          src: 'nextPaymentDate',
          label: t('table.nextPaymentDate'),
          sortable: true,
          changeSortDirection,
        },
        {
          src: 'paymentMethod',
          label: t('table.paymentMethod'),
          sortable: true,
          changeSortDirection,
        },
        {
          src: 'status',
          label: t('table.status'),
          sortable: true,
          changeSortDirection,
        },
        {
          src: 'stopSupport',
          label: t('table.action'),
        },
      ];

      const content: RecurrentUserPaymentsTableContent[] =
        recurrentUserPayments.map((payment) => ({
          ...payment,
          stopSupport:
            payment.status === RECURRENT_PAYMENT_STATUS.ACTIVE
              ? () =>
                  stopSupportPayment(
                    payment.recurrent_paymentsID,
                    payment.projectName
                  )
              : undefined,
        }));

      return { columns, content };
    };
  }, [t, changeSortDirection, recurrentUserPayments, stopSupportPayment]);
  return (
    <Table<RecurrentUserPaymentsTableContent>
      placeholder={
        showPlaceholder ? (
          <Trans
            i18nKey="profile.payments.emptyPaymentsMessage.desktop"
            components={{
              lnkCatalog: (
                <Link
                  className="text-link"
                  to={paths.projects}
                  rel="noreferrer"
                  target="_blank"
                />
              ),
              lnkFrontPage: (
                <Link
                  className="text-link"
                  to={paths.frontPageDonation}
                  rel="noreferrer"
                  target="_blank"
                />
              ),
            }}
          />
        ) : null
      }
      data={prepareRecurrentUserPaymentsTableData()}
      rowComponent={RecurrentUserPaymentsTableRow}
      columnsClass="recurrent-user-payments__table-columns"
      headerClass="recurrent-user-payments-table__header"
      headerCellClass="recurrent-user-payments__header-cell"
    />
  );
};

export default RecurrentUserPaymentsTable;
