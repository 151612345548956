import { PROJECT_STATUS } from 'src/features/status/enums';

const PERCENTS = {
  [PROJECT_STATUS.FRAMED]: 10,
  [PROJECT_STATUS.PLANNED]: 20,
  [PROJECT_STATUS.RUNNING]: 30,
  [PROJECT_STATUS.ACCEPTED]: 40,
  [PROJECT_STATUS.APPROVED]: 50,
  [PROJECT_STATUS.IMPLEMENTED]: 60,
  [PROJECT_STATUS.FINISHED]: 80,
  [PROJECT_STATUS.TRANSFERRED]: 100,
};

export const convertStatusToPercents = (v?: PROJECT_STATUS) =>
  v ? PERCENTS[v] : 0;
