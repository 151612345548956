import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  TextInput,
} from '@platform-for-public-places/components-library';

import './SharePlate.scss';

const SHARE_URL_TELEGRAM = 'https://t.me/share/url';
const SHARE_URL_VK = 'https://vk.com/share.php';
const SHARE_URL_CLASSMATES = 'https://connect.ok.ru/offer';

export interface SharePlateProps {
  className?: string;
  shareText?: string;
  setClickedOutside?: () => void;
  projectId: string;
}

const SharePlate = ({
  className = '',
  shareText = '',
  setClickedOutside = () => null,
  projectId,
}: SharePlateProps) => {
  const { t } = useTranslation('app', {
    keyPrefix: 'detailedProject.sharePlate',
  });

  const [link, setLink] = useState<string>(
    `https://${window.location.hostname}/projects/${projectId}`
  );

  return (
    <>
      <div
        aria-hidden
        className="share-plate__background"
        onClick={() => setClickedOutside()}
      />
      <div className={`share-plate ${className}`}>
        <h4 className="share-plate__title">{t('shareTitle')}</h4>
        <div className="share-plate__links">
          <TextInput
            className="share-plate__link-input"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
          <Button
            className="share-plate__link-copy-button"
            type={BUTTON_TYPE.SECONDARY}
            icon
            onClick={() => {
              navigator.clipboard.writeText(link);
            }}
          >
            <Icon icon={IconType.Link} />
          </Button>
        </div>
        <div className="share-plate__contacts">
          <a
            className="share-plate__contact--telegram"
            href={`${SHARE_URL_TELEGRAM}?url=${link}&text=${shareText}`}
            rel="noreferrer external"
            target="_blank"
          >
            <Icon icon={IconType.Telegram} />
          </a>
          <a
            className="share-plate__contact--vk"
            href={`${SHARE_URL_VK}?url=${link}`}
            rel="noreferrer external"
            target="_blank"
          >
            <Icon icon={IconType.Vk} />
          </a>
          <a
            className="share-plate__contact--classmates"
            href={`${SHARE_URL_CLASSMATES}?url=${link}&title=${shareText}`}
            rel="noreferrer external"
            target="_blank"
          >
            <Icon icon={IconType.Classmates} />
          </a>
        </div>
      </div>
    </>
  );
};

export default SharePlate;
