export const extensionForDownload = [
  'doc',
  'docx',
  'xls',
  'xlsx',
  'md',
  'txt',
  'csv',
];

export const isDownloadFile = (fileName: string) => {
  const extensionFile = fileName.split('.').pop();
  return extensionFile ? extensionForDownload.includes(extensionFile) : false;
};

export const downloadURI = (
  url: string | undefined,
  fileName: string | undefined
) => {
  url
    ? fetch(url, {
        method: 'GET',
        referrerPolicy: 'no-referrer',
      })
        .then((res) => res.blob())
        .then((res) => {
          const aElement = document.createElement('a');
          aElement.setAttribute('download', fileName ?? '');
          const href = URL.createObjectURL(res);
          aElement.href = href;
          aElement.setAttribute('target', '_blank');
          aElement.click();
          URL.revokeObjectURL(href);
        })
    : () => null;
};
