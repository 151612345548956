import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import Spinner from 'src/shared/components/Spinner/Spinner';
import { paths } from 'src/shared/routes';

import MobileHeader from 'src/features/mobile/components/MobileHeader/MobileHeader';
import Donation from 'src/features/payment/components/Donation/Donation';
import { useGetProjectInfoAndCheck } from 'src/features/project/hooks';

import { useIsArchived } from '../hooks';

import './DonationProjectMobile.scss';

const DonationProjectMobile = () => {
  const isSmallScreen = useCheckSmallScreen();
  const { id } = useParams();
  const archived = useIsArchived();
  const { t } = useTranslation('app', { keyPrefix: 'detailedProject' });
  const { data: projectInfo } = useGetProjectInfoAndCheck(id, archived);

  return projectInfo && id ? (
    isSmallScreen ? (
      <div className="donation-page-mobile">
        <MobileHeader
          title={t('donation.title')}
          path={paths.projectById(id)}
        />
        <Donation
          archived={archived}
          title={''}
          description={t('donation.description')}
          buttonText={t('donation.button')}
          projectId={projectInfo.data.projectInfo.projectID}
          isDefaultBehaviour={false}
        />
      </div>
    ) : (
      <Navigate to={paths.projectDonationById(id)} replace />
    )
  ) : (
    <div className={'donation-spinner'}>
      <Spinner visible />
    </div>
  );
};

export default DonationProjectMobile;
