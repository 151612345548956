import proj4 from 'proj4';

export interface Wgs84Coordinates {
  lat: number;
  lng: number;
}

export interface GoogleCoordinates {
  x: number;
  y: number;
}

export const convertFromWgs84ToGoogle = ({
  lat,
  lng,
}: Wgs84Coordinates): GoogleCoordinates => {
  const res = proj4('WGS84', 'GOOGLE', [lng, lat]);
  return {
    x: res[0],
    y: res[1],
  };
};

export const convertFromGoogleToWgs84 = ({
  x,
  y,
}: GoogleCoordinates): Wgs84Coordinates => {
  const res = proj4('GOOGLE', 'WGS84', [x, y]);
  return {
    lat: res[1],
    lng: res[0],
  };
};
