import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';
import booleanIntersects from '@turf/boolean-intersects';
import { Feature, Polygon } from 'geojson';
import L from 'leaflet';

import Box from 'src/shared/components/Box/Box';
import Spinner from 'src/shared/components/Spinner/Spinner';
import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import { setCurrentCard } from 'src/features/control/slices/controlSlice';
import { DrawnShape } from 'src/features/draw/models';
import {
  changeDrawEnable,
  changeNeedClear,
  DRAW,
  resetDraw,
} from 'src/features/draw/slices/drawSlice';
import {
  useCheckIfUserAuthorized,
  useDisablePropagationRef,
} from 'src/features/hooks';
import { ACTION_TYPE } from 'src/features/map/enums';
import { MAP } from 'src/features/map/slices/mapSlice';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { useGetAreaInfoByPolygonQuery } from 'src/features/pkk/api/pkkApi';
import CadasterInformation from 'src/features/pkk/components/CadasterInformation/CadasterInformation';
import { changeCadasters } from 'src/features/pkk/slices/pkkSlice';
import {
  setCenter,
  setFeature,
} from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import {
  PROJ_LAYER,
  setCurrentProjectInfo,
} from '../../slices/projectsLayerSlice';

import { ProjectsInfoWithGeodata, SPECIFIC_LAYER } from '../../models';

import ProjectPreviewHorizontal from '../ProjectPreviewHorizontal/ProjectPreviewHorizontal';

import './InformationCard.scss';

const MAX_PKK_DATA = 5;
//todo разнести, очень много логики в одном, выделить провайдер
const InformationCard = (): JSX.Element => {
  const { t } = useTranslation('app', { keyPrefix: 'cards.information' });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cardRef = useDisablePropagationRef();
  const isSmallScreen = useCheckSmallScreen();

  const [pkkFailure, setPkkFailure] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  const [intersected, setIntersected] = useState<ProjectsInfoWithGeodata[]>([]);
  const [intersectedError, setIntersectedError] = useState<boolean>(true);

  const action = useSelector((s: State) => s[MAP].action);
  const drawnShape = useSelector((s: State) => s[DRAW].drawnShape);

  const projects = useSelector((s: State) => s[PROJ_LAYER].projectsLayerList);

  const { isSuccess: whoamiSuccess, data: whoamiData } = useWhoamiQuery();

  const {
    data: pkkData,
    error: pkkError,
    isLoading: pkkLoading,
    isFetching: pkkFetching,
  } = useGetAreaInfoByPolygonQuery(drawnShape?.polygon as Feature<Polygon>, {
    skip: !drawnShape,
  });

  const scrollabelAreaRef = useRef<HTMLDivElement>(null);
  const municipalCreator =
    whoamiData?.data.specificLayer === SPECIFIC_LAYER.MUNICIPAL;

  useEffect(() => {
    if (pkkFetching || pkkError) {
      setTimeoutId(
        setTimeout(() => {
          setPkkFailure(true);
        }, 5000)
      );
    } else {
      setPkkFailure(false);
      clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pkkError, pkkFetching]);

  useEffect(() => () => clearTimeout(timeoutId), [timeoutId]);

  const pkkOverload =
    !!pkkData && !municipalCreator && pkkData.length > MAX_PKK_DATA;

  const creationRestricted =
    pkkLoading || (!pkkFailure && (!pkkData || pkkOverload));

  const filterProjects = (
    projects: ProjectsInfoWithGeodata[],
    drawnShape: DrawnShape
  ) => {
    return projects.filter((p) =>
      booleanIntersects(drawnShape.polygon, p.geo_data.polygon)
    );
  };

  useEffect(() => {
    //todo  зачем?
    if (drawnShape) {
      const filteredProjects = filterProjects(
        Object.values(projects).reduce((acc, item) => [...acc, ...item], []),
        drawnShape
      );

      setIntersected(filteredProjects);
      setIntersectedError(filteredProjects.length !== 0);
    } else {
      setIntersected([]);
      setIntersectedError(false);
    }
  }, [drawnShape, projects]);

  useEffect(() => {
    if (scrollabelAreaRef.current) {
      L.DomEvent.disableScrollPropagation(scrollabelAreaRef.current);
    }
  }, [scrollabelAreaRef]);

  useEffect(() => {
    dispatch(changeCadasters(pkkData || []));
  }, [dispatch, pkkData]);

  const onSuccess = useCallback(() => {
    dispatch(changeNeedClear());
    if (drawnShape) {
      dispatch(setFeature(drawnShape.polygon));
      dispatch(setCenter(drawnShape.center));
    }
    dispatch(resetDraw());
    if (action == ACTION_TYPE.CREATE) {
      navigate(paths.creation);
      return;
    }
  }, [action, dispatch, drawnShape, navigate]);

  const requestCreation = useCheckIfUserAuthorized(onSuccess);

  const onCreateClick = () => {
    dispatch(setCurrentProjectInfo(null));
    requestCreation().then(() => {
      if (!whoamiSuccess) {
        isSmallScreen
          ? navigate(paths.signIn)
          : dispatch(changeModal(MODALS.SIGN_IN));
      }
    });
  };

  const onResetClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(changeNeedClear());
    dispatch(resetDraw());
    dispatch(setCurrentCard(null));
    if (drawnShape) {
      dispatch(changeDrawEnable(true));
    }
  };

  const renderCadasterInformation = () => {
    return pkkData?.map((cadaster) => (
      <CadasterInformation key={cadaster.number} cadaster={cadaster} />
    ));
  };

  const drawProjectInfo = () => {
    const noProjectMessage = (
      <p className="project-info__message information-card__message">
        {t('noProjectMessage')}
      </p>
    );

    const intersectedProjects = (
      <div className="project-info__intersections">
        {intersected.map((project) => (
          <ProjectPreviewHorizontal
            key={project.projectId}
            projectId={project.projectId}
          />
        ))}
      </div>
    );

    const renderIntersectedInfo = () =>
      intersectedError ? (
        <div className="information-card__info-block">
          <Icon
            className="information-card__message--error-icon"
            icon={IconType.Error}
          />
          <p className="information-card__message--error">{t('intersected')}</p>
        </div>
      ) : (
        <div className="information-card__info-block information-card__message--fade-away">
          <Icon
            className="information-card__message--error-icon"
            icon={IconType.StrokeCheck}
          />
          <p className="information-card__message--load">{t('loaded')}</p>
        </div>
      );

    return isSmallScreen ? (
      renderIntersectedInfo()
    ) : (
      <div className="project-info">
        <h3 className="project-info__header  information-card__header--small">
          {t('projectInfo')}
        </h3>
        {intersected.length ? intersectedProjects : noProjectMessage}
      </div>
    );
  };

  const renderPkkError = () => {
    return (
      <div className="cadaster-info__pkk-error">
        <Icon
          className="information-card__message--error-icon"
          icon={IconType.Error}
        />
        {t('pkkError')}
      </div>
    );
  };
  const drawPkkInfo = () => {
    return (
      <div className="cadaster-info">
        <h3 className="cadaster-info__header information-card__header--small">
          {t('cadasterInfo')}
        </h3>
        {pkkFailure ? renderPkkError() : null}
        {renderCadasterInformation()}
      </div>
    );
  };

  const drawContent = () => {
    if (drawnShape) {
      if (pkkLoading) {
        return (
          <Box justifyContent={'center'}>
            <Spinner className={'information-card__spinner'} />
          </Box>
        );
      } else if (pkkOverload) {
        return (
          <p className="information-card__message--error">
            {t('toBigAreaMessage')}
          </p>
        );
      } else {
        return (
          <>
            {drawProjectInfo()}
            {drawPkkInfo()}
            {intersectedError ? (
              <div className="information-card__intersected-error-message">
                <Icon
                  className="information-card__message--error-icon"
                  icon={IconType.Error}
                />
                <p className={'information-card__intersected-info'}>
                  {t('intersect_info')}
                </p>
              </div>
            ) : null}
          </>
        );
      }
    } else {
      return (
        <p className="information-card__message">{t('notFinishedMessage')}</p>
      );
    }
  };

  const renderMessage = () => {
    if (drawnShape) {
      if (pkkLoading) {
        return (
          <div className="information-card__info-block">
            <Spinner className={'information-card__spinner'} />
            <p className="information-card__message--fetching">
              {t('fetching')}
            </p>
          </div>
        );
      } else if (pkkFailure) {
        return (
          <div className="information-card__info-block">
            <Icon
              className="information-card__message--error-icon information-card__message--pkk-error-icon"
              icon={IconType.Error}
            />
            <p className="information-card__message--error information-card__message--pkk-error">
              {t('pkkError')}
            </p>
          </div>
        );
      } else {
        if (pkkData && pkkData.length > MAX_PKK_DATA) {
          return (
            <div className="information-card__info-block">
              <Icon
                className="information-card__message--error-icon"
                icon={IconType.Error}
              />
              <p className="information-card__message--error">
                {t('toBigAreaMessage')}
              </p>
            </div>
          );
        } else {
          return drawProjectInfo();
        }
      }
    }
  };

  return isSmallScreen ? (
    <div
      className={`information-card__mobile ${
        action == ACTION_TYPE.CREATE ? '' : 'information-card__mobile_edit'
      }`}
      ref={cardRef}
    >
      {renderMessage()}
      <div className="information-card__button-block">
        <Button
          type={BUTTON_TYPE.SECONDARY}
          className="information-card__reset"
          onClick={onResetClick}
        >
          {t('buttons.reset')}
        </Button>
        <Button
          disabled={creationRestricted}
          type={BUTTON_TYPE.PRIMARY}
          className="information-card__create"
          onClick={onCreateClick}
        >
          {t(`buttons.${action == ACTION_TYPE.CREATE ? 'create' : 'save'}`)}
        </Button>
      </div>
    </div>
  ) : (
    <div className="information-card" ref={cardRef}>
      <header className="information-card__header">{t('header')}</header>
      <div ref={scrollabelAreaRef} className="information-card__content">
        {drawContent()}
      </div>
      <div className="information-card__button-block">
        <Button
          type={BUTTON_TYPE.SECONDARY}
          className="information-card__reset"
          onClick={onResetClick}
        >
          {t('buttons.reset')}
        </Button>
        <Button
          disabled={creationRestricted}
          type={BUTTON_TYPE.PRIMARY}
          className="information-card__create"
          onClick={onCreateClick}
        >
          {t(`buttons.${action == ACTION_TYPE.CREATE ? 'create' : 'save'}`)}
        </Button>
      </div>
    </div>
  );
};

export default InformationCard;
