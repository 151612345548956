import { useTranslation } from 'react-i18next';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  stringUTCDateToUTCDate,
} from '@platform-for-public-places/components-library';

import { truncateZeroDecimal } from 'src/shared/functions/numbers';
import { paths } from 'src/shared/routes';

import {
  PAYMENT_METHOD,
  RECURRENT_PAYMENT_STATUS,
} from 'src/features/payment/models';

export interface RecurrentUserPaymentsTableContent {
  nextPaymentDate: string;
  projectId: string | null;
  projectName: string;
  amount: number;
  paymentMethod: PAYMENT_METHOD;
  status?: RECURRENT_PAYMENT_STATUS;
  stopSupport?: () => void;
}

export interface RecurrentUserPaymentsTableRowProps {
  data: RecurrentUserPaymentsTableContent;
  className?: string;
}

import './RecurrentUserPaymentsTableRow.scss';

const RecurrentUserPaymentsTableRow = ({
  data,
  className,
}: RecurrentUserPaymentsTableRowProps) => {
  const { t } = useTranslation('app', {
    keyPrefix: 'profile.payments.recurrent.row',
  });

  const paymentSystemTranslation = Object.values(PAYMENT_METHOD).includes(
    data.paymentMethod
  )
    ? t(`paymentMethod.${data.paymentMethod}`)
    : t('paymentMethod.unknown');

  const paymentStatusTranslation =
    data.status && Object.values(RECURRENT_PAYMENT_STATUS).includes(data.status)
      ? t(`status.${data.status}`)
      : t('status.unknown');

  return (
    <div className={`recurrent-user-payments-table-row ${className}`}>
      <a
        className="recurrent-user-payments-table-row__link"
        href={
          data.projectId ? paths.projectById(data.projectId) : paths.frontPage
        }
        rel="noopener noreferrer"
        target="_blank"
      >
        {data.projectName}
      </a>

      <span>
        {t('amount', {
          amount: data.amount,
          formatParams: {
            amount: {
              maximumFractionDigits: truncateZeroDecimal(data.amount),
            },
          },
        })}
      </span>

      {data.stopSupport ? (
        <span>
          {t('nextPaymentDate', {
            nextPaymentDate: stringUTCDateToUTCDate(data.nextPaymentDate),
            formatParams: {
              nextPaymentDate: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              },
            },
          })}
        </span>
      ) : (
        <span></span>
      )}

      <span>{paymentSystemTranslation}</span>

      <span
        className={`recurrent-user-payments-table-row__status--${data.status}`}
      >
        {paymentStatusTranslation}
      </span>

      {data.stopSupport ? (
        <Button
          className="recurrent-user-payments-table-row__stop-support-button"
          onClick={data.stopSupport}
          type={BUTTON_TYPE.TEXT_RED}
          icon
          fill
        >
          <Icon
            className="recurrent-user-payments-table-row__button-icon"
            icon={IconType.Cross}
          />
          {t('actions.stopSupport')}
        </Button>
      ) : null}
    </div>
  );
};

export default RecurrentUserPaymentsTableRow;
