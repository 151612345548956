import { ChangeEventHandler, ReactElement } from 'react';

import './Checkbox.scss';

interface CheckboxProps {
  id: string;
  checked?: boolean;
  disabled?: boolean;
  label?: string | ReactElement;
  tabIndex?: number;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const mixUp = (s: TemplateStringsArray, additional = '') => {
  return additional ? `${s[0]} ${additional}` : s[0];
};

const Checkbox = ({
  id,
  checked,
  disabled,
  label,
  tabIndex,
  className,
  inputClassName,
  labelClassName,
  onChange,
}: CheckboxProps): JSX.Element => (
  <div className={mixUp`checkbox${className}`}>
    <input
      id={id}
      type="checkbox"
      checked={checked}
      disabled={disabled}
      tabIndex={tabIndex}
      onChange={onChange}
      className={mixUp`checkbox__input${inputClassName}`}
    />
    {label ? (
      <label htmlFor={id} className={mixUp`checkbox__label${labelClassName}`}>
        {label}
      </label>
    ) : null}
  </div>
);

export default Checkbox;
