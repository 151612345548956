import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  useCheckSmallScreen,
  useJWToken,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { useLazyConfirmSignUpQuery } from 'src/features/auth/api';
import { SignUpConfirmationToken } from 'src/features/auth/models';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';

import BaseLayout from 'src/pages/layouts/BaseLayout/BaseLayout';

const MILLSECS_FACTOR = 1000;
const TOKEN = 'token';

const TokenInterceptorLayout = (): JSX.Element => {
  const [params] = useSearchParams();
  const mobile = useCheckSmallScreen();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const parseToken = useJWToken();
  const [confirmSignUp] = useLazyConfirmSignUpQuery();

  const [fulfilled, setFulfulled] = useState<boolean>(false);

  const navigateSignIn = useCallback(() => {
    dispatch(changeModal(MODALS.SIGN_IN));
    navigate(paths.map);
  }, [dispatch, navigate]);

  useEffect(() => {
    const token = params.get(TOKEN);
    if (!token || fulfilled) return;

    const tokenObject = parseToken<SignUpConfirmationToken>(token);
    const expired = tokenObject.exp < Date.now() / MILLSECS_FACTOR;

    if (expired) {
      navigateSignIn();
    } else {
      confirmSignUp({ userId: tokenObject.sub })
        .then(() => {
          setFulfulled(true);
          mobile
            ? navigate(paths.confirmed)
            : dispatch(changeModal(MODALS.EMAIL_CONFIRMED));
        })
        .catch(() => navigateSignIn());
    }
  }, [
    confirmSignUp,
    dispatch,
    fulfilled,
    mobile,
    navigate,
    navigateSignIn,
    params,
    parseToken,
  ]);

  return <BaseLayout />;
};

export default TokenInterceptorLayout;
