import { slide as Menu } from 'react-burger-menu';
import { useDispatch, useSelector } from 'react-redux';

import { Icon, IconType } from '@platform-for-public-places/components-library';

import {
  CONTROL,
  setMobileMenuOpen,
} from 'src/features/control/slices/controlSlice';
import { State } from 'src/features/store/store';

import './Burger.scss';

interface BurgerProps {
  children?: React.ReactNode;
  className?: string;
  onOpen?: () => void;
  onClose?: () => void;
}

const Burger = ({ children, className, onOpen, onClose }: BurgerProps) => {
  const dispatch = useDispatch();

  const mobileMenuOpen = useSelector((s: State) => s[CONTROL].mobileMenuOpen);

  const onOpenMenu = () => {
    dispatch(setMobileMenuOpen(true));
    onOpen?.();
  };
  const onOpenClose = () => {
    dispatch(setMobileMenuOpen(false));
    onClose?.();
  };

  return (
    <Menu
      isOpen={mobileMenuOpen}
      onOpen={onOpenMenu}
      onClose={onOpenClose}
      customCrossIcon={false}
      customBurgerIcon={
        <Icon icon={IconType.Menu} className="header__link-icon" />
      }
      right
      className={`${className} menu`}
    >
      {children}
    </Menu>
  );
};

export default Burger;
