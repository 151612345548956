/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react';

import {
  createLayerComponent,
  MediaOverlayProps,
  updateMediaOverlay,
} from '@react-leaflet/core';
import {
  DomUtil,
  ImageOverlay,
  LatLngBounds,
  Util,
  VideoOverlay as LeafletVideoOverlay,
} from 'leaflet';

const CanvasOverlay = ImageOverlay.extend({
  options: {},

  _initImage: function () {
    const wasElementSupplied = this._url.tagName === 'CANVAS';
    const canvas = (this._image = wasElementSupplied
      ? this._url
      : DomUtil.create('canvas'));

    DomUtil.addClass(canvas, 'leaflet-image-layer');
    if (this._zoomAnimated) {
      DomUtil.addClass(canvas, 'leaflet-zoom-animated');
    }
    if (this.options.className) {
      DomUtil.addClass(canvas, this.options.className);
    }

    canvas.onselectstart = Util.falseFn;
    canvas.onmousemove = Util.falseFn;
  },
});

function canvasOverlay(
  canvas: HTMLCanvasElement,
  bounds: L.LatLngBoundsExpression,
  options?: any
) {
  return new (CanvasOverlay as any)(canvas, bounds, options);
}

export interface CanvasOverlayProps extends MediaOverlayProps {
  children?: ReactNode;
  canvas: HTMLCanvasElement;
  width?: number;
  height?: number;
  bounds: LatLngBounds;
}

export default createLayerComponent<LeafletVideoOverlay, CanvasOverlayProps>(
  function createCanvasOverlay({ bounds, canvas, ...options }, ctx) {
    const instance = new (canvasOverlay as any)(canvas, bounds, options);
    const { width, height } = options;
    canvas.width = width || canvas.width;
    canvas.height = height || canvas.height;
    return { instance, context: { ...ctx, overlayContainer: instance } };
  },
  function updateCanvasOverlay(overlay, props, prevProps) {
    updateMediaOverlay(overlay, props, prevProps);

    if (
      (props.width && prevProps.width !== props.width) ||
      prevProps.height !== props.height
    ) {
      const canvas = overlay.getElement() as unknown as HTMLCanvasElement;
      if (typeof props.width === 'number') {
        canvas.width = props.width;
      }
      if (typeof props.height === 'number') {
        canvas.height = props.height;
      }
    }
  }
);
