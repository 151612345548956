import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { routes } from 'src/shared/routes';

import RecurrentUserPaymentsInfo from 'src/features/payment/components/RecurrentUserPaymentsInfo/RecurrentUserPaymentsInfo';
import WarningMessage from 'src/features/payment/components/WarningMessage/WarningMessage';

import './RecurrentUserPayments.scss';

const RecurrentUserPayments = () => {
  const { t } = useTranslation('app', {
    keyPrefix: `profile.payments`,
  });
  const navigate = useNavigate();
  const isSmallScreen = useCheckSmallScreen();

  useEffect(() => {
    if (!isSmallScreen) {
      navigate(routes.profile.payments, {
        replace: true,
      });
    }
  });

  return (
    <div className="recurrent-user-payments">
      <WarningMessage
        className="recurrent-user-payments__warning-message"
        text={t('warningMessage')}
      />
      <RecurrentUserPaymentsInfo />
    </div>
  );
};

export default RecurrentUserPayments;
