import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';
import plural from 'plural-ru';

import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import CommentCreation from 'src/features/comments/components/CommentCreation/CommentCreation';
import CommentsList from 'src/features/comments/components/CommentsList/CommentsList';
import MobileHeader from 'src/features/mobile/components/MobileHeader/MobileHeader';
import { useGetProjectAndCheck } from 'src/features/project/hooks';

import './Comments.scss';

const Comments = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const mobile = useCheckSmallScreen();
  const { data: project } = useGetProjectAndCheck(id);
  const { t } = useTranslation('app', { keyPrefix: 'comments' });

  const { data: userData } = useWhoamiQuery();

  const canCreateComment = useMemo(
    () => userData?.success,
    [userData?.success]
  );

  useEffect(() => {
    if (!id || !mobile) {
      navigate(paths.map);
    }
  }, [id, mobile, navigate]);

  const header = useMemo(() => {
    const key = plural(
      project?.data.comments || 0,
      'headerOne',
      'headerMore',
      'headerMany'
    );
    return `${project?.data.comments || 0} ${t(key)}`;
  }, [project?.data.comments, t]);

  return (
    <div className="comments-screen">
      <MobileHeader
        title={header}
        path={paths.projectById(id as string)}
        replace
      />
      <div className="comments-screen__list">
        <CommentsList projectId={id as string} />
      </div>
      {canCreateComment ? (
        <CommentCreation
          className="comments-screen__form"
          projectId={id as string}
        />
      ) : null}
    </div>
  );
};

export default Comments;
