import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { components, OptionProps, SingleValue } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';

import clsx from 'clsx';

import { DEBOUNCE_TIMEOUT } from 'src/app/constants';

import MobileHeader from 'src/features/mobile/components/MobileHeader/MobileHeader';
import { useLazyGetPaymentsStatisticQuery } from 'src/features/payment/api';
import { PaymentsStatistic } from 'src/features/payment/models';

import { filterStatistic } from '../../functions';
import AnnualReportDocs from '../AnnualReportDocs/AnnualReportDocs';
import ReportingBarChart from '../ReportingBarChart/ReportingBarChart';

import './ReportingMobile.scss';

const PAGE_SIZE_CHART_REPORTS = 5;

export type OptionType = {
  value: number;
  label: string;
};

const Option = (props: OptionProps<OptionType>) => {
  return (
    <components.Option {...props}>
      {props.children?.toString().slice(0, 4)}
    </components.Option>
  );
};

const ReportingMobile = (): JSX.Element => {
  const { t } = useTranslation('app', { keyPrefix: 'reporting' });

  const [value, setValue] = useState<OptionType | null>(null);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentsStatistic, setPaymentsStatistic] = useState<
    PaymentsStatistic[]
  >([]);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const [getPaymentsStatistic] = useLazyGetPaymentsStatisticQuery();

  const loadStatistic = async (page: number) => {
    const response = await getPaymentsStatistic({
      page,
      pageSize: PAGE_SIZE_CHART_REPORTS,
    });

    const statistic = response.data?.data.paymentsStatistic;

    setPaymentsStatistic((prev) => [...prev, ...(statistic ?? [])]);
    setCurrentPage((prev) => prev + 1);

    const filtredStatistic = filterStatistic(statistic ?? []);
    const data = filtredStatistic.map((annualStatisctic, index) => {
      return {
        value: (currentPage - 1) * PAGE_SIZE_CHART_REPORTS + index,
        label: t('year', { year: annualStatisctic.year.toString() }),
      };
    });
    return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await loadStatistic(1);
      if (data) {
        setOptions(data);
        setValue(data[0]);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPageOptions = async () => {
    const data = await loadStatistic(currentPage);
    return {
      options: data ?? [],
      hasMore: data?.length === PAGE_SIZE_CHART_REPORTS,
    };
  };

  const annualStatisctic = paymentsStatistic[value?.value ?? 0];

  return (
    <div className="reporting-mobile">
      <MobileHeader title={t('title')} />
      {paymentsStatistic.length ? (
        <div className="reporting-mobile__annual-report">
          <header className="reporting-mobile__header">
            <AsyncPaginate
              className={clsx('reporting-mobile__paginate', {
                'reporting-mobile__paginate_selected': isMenuOpen,
              })}
              isSearchable={false}
              debounceTimeout={DEBOUNCE_TIMEOUT}
              value={value}
              options={options}
              loadOptions={loadPageOptions}
              onChange={(value) => setValue(value as SingleValue<OptionType>)}
              onMenuOpen={() => setIsMenuOpen(true)}
              onMenuClose={() => setIsMenuOpen(false)}
              components={{ Option }}
            />
            <h3 className="reporting-mobile__total-count">
              <Trans
                t={t}
                i18nKey="totalCount"
                values={{
                  amount: annualStatisctic.total,
                  formatParams: { amount: { maximumFractionDigits: 0 } },
                }}
                components={{
                  span: <span />,
                }}
              />
            </h3>
          </header>
          <ReportingBarChart data={annualStatisctic.statistic} isSmallScreen />
          <AnnualReportDocs
            reports={annualStatisctic.reports}
            className="reporting-mobile__annual_report_docs"
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ReportingMobile;
