import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  Table,
  TableData,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { UserPaymentData } from 'src/features/payment/models';

import AllUserPaymentsTableRow, {
  AllUserPaymentsTableContent,
} from './components/AllUserPaymentsTableRow/AllUserPaymentsTableRow';

import './AllUserPaymentsTable.scss';

interface AllUserPaymentsTableProps {
  allUserPayments: UserPaymentData[];
  changeSortDirection: (src: string) => void;
  showPlaceholder?: boolean;
}

const AllUserPaymentsTable = ({
  allUserPayments,
  changeSortDirection,
  showPlaceholder = true,
}: AllUserPaymentsTableProps) => {
  const { t } = useTranslation('app', {
    keyPrefix: `profile.payments.all`,
  });

  const prepareAllUserPaymentsTableData = useMemo(() => {
    return (): TableData<AllUserPaymentsTableContent> => {
      const columns = [
        {
          src: 'date',
          label: t('table.date'),
          sortable: true,
          changeSortDirection,
        },
        {
          src: 'amount',
          label: t('table.amount'),
          sortable: true,
          changeSortDirection,
        },
        {
          src: 'projectName',
          label: t('table.projectName'),
          sortable: true,
          changeSortDirection,
        },
        {
          src: 'paymentType',
          label: t('table.paymentType'),
          sortable: true,
          changeSortDirection,
        },
      ];

      const content: AllUserPaymentsTableContent[] = allUserPayments.map(
        (payment) => ({
          createdAt: payment.date,
          amount: payment.amount,
          projectId: payment.projectId,
          projectName: payment.projectName,
          paymentType: payment.paymentType,
        })
      );

      return { columns, content };
    };
  }, [allUserPayments, t, changeSortDirection]);

  return (
    <Table<AllUserPaymentsTableContent>
      placeholder={
        showPlaceholder ? (
          <Trans
            i18nKey="profile.payments.emptyPaymentsMessage.desktop"
            components={{
              lnkCatalog: (
                <Link
                  className="text-link"
                  to={paths.projects}
                  rel="noreferrer"
                  target="_blank"
                />
              ),
              lnkFrontPage: (
                <Link
                  className="text-link"
                  to={paths.frontPageDonation}
                  rel="noreferrer"
                  target="_blank"
                />
              ),
            }}
          />
        ) : null
      }
      data={prepareAllUserPaymentsTableData()}
      rowComponent={AllUserPaymentsTableRow}
      columnsClass="all-user-payments__table-columns"
      headerClass="all-user-payments-table__header"
      headerCellClass="all-user-payments__header-cell"
    />
  );
};

export default AllUserPaymentsTable;
