import { memo, useCallback, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { GeoJSON, Marker } from 'react-leaflet';

import {
  Icon as IconCustom,
  IconType,
} from '@platform-for-public-places/components-library';
import { Feature } from 'geojson';
import { DivIcon, LatLng, Point } from 'leaflet';

import { ProjectsInfoWithGeodata } from 'src/features/project/models';
import { ProjectFilterOption } from 'src/features/searchWithFilter/filter/models';

import './Locator.scss';

const convertPointToLatLng = (point: number[]) =>
  new LatLng(point[1], point[0]);

interface LocatorProps {
  project: ProjectsInfoWithGeodata;
  pane: string;
  type?: ProjectFilterOption;
  onClick?: (projectId: string) => void;
  icon: IconType;
}
const Locator = ({
  project,
  pane,
  type,
  icon,
  onClick,
}: LocatorProps): JSX.Element => {
  const eventHandlers = useMemo(
    () => ({ click: () => onClick?.(project.projectId) }),
    [onClick, project.projectId]
  );

  const GeoJSONPolygon = useCallback(() => {
    return (
      <GeoJSON
        pane={pane}
        data={project.geo_data?.polygon as Feature}
        eventHandlers={eventHandlers}
        pathOptions={{
          color: `var(${type?.color})`,
          fillColor: `var(${type?.color})`,
        }}
      />
    );
  }, [eventHandlers, pane, project.geo_data?.polygon, type]);
  return (
    <>
      <Marker
        pane={pane}
        icon={
          new DivIcon({
            html: generateSvgIcon(type?.color ?? 'black', icon),
            iconSize: new Point(64, 64),
            className: 'locator-icon',
          })
        }
        position={convertPointToLatLng(
          project.geo_data?.center.geometry.coordinates as number[]
        )}
        zIndexOffset={10000}
        eventHandlers={eventHandlers}
      />
      <GeoJSONPolygon />
    </>
  );
};

export default memo(Locator);

const generateSvgIcon = (color: string, iconType: IconType) => {
  const typeProjectSvg = ReactDOMServer.renderToString(
    <IconCustom
      icon={iconType}
      className={'marker'}
      color={'var(--main-white)'}
    />
  );
  return `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 64 64">
  <path fill=var(${color})  d="m47.628 43.496-16 15.628-7.814-6.697c-2.233-2.977-6.772-8.93-7.07-8.93-.297 0-3.845-5.458-5.581-8.187-.248-4.34-.596-13.172 0-13.767.595-.595 2.728-5.21 3.72-7.442l6.698-6.326 8.93-.744h7.07l8.558 2.605 6.326 10.79 1.488 8.559c-.372 1.86-1.19 5.73-1.488 6.325-.298.596-3.349 5.706-4.837 8.186Z"/><path fill=var(${color}) fill-rule="evenodd" d="M32 8.333A18.667 18.667 0 0 0 13.333 27c0 6.947 4.722 14.297 9.993 20.228A80.954 80.954 0 0 0 32 55.588a80.954 80.954 0 0 0 8.674-8.359c5.271-5.931 9.993-13.282 9.993-20.229A18.667 18.667 0 0 0 32 8.333ZM32 59c-1.6 2.133-1.6 2.133-1.601 2.132h-.002l-.005-.005-.016-.011-.054-.041a52.762 52.762 0 0 1-.91-.715 86.289 86.289 0 0 1-10.073-9.588C13.946 44.702 8.001 36.053 8.001 27a24 24 0 0 1 48 0c0 9.053-5.945 17.702-11.34 23.772a86.289 86.289 0 0 1-10.073 9.588 52.762 52.762 0 0 1-.91.715l-.053.04-.016.012-.005.004-.002.001S33.6 61.133 32 59Zm0 0 1.6 2.133a2.667 2.667 0 0 1-3.2 0L32 59Z" clip-rule="evenodd"/>
${
  `<svg x="27%" y="21%" width="30px" height="30px"` +
  typeProjectSvg.split('<svg')[1]
}
</svg>`;
};
