import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { paths } from 'src/shared/routes';

import { RecurrentUserPaymentData } from 'src/features/payment/models';

import StatusRecurrentPayment from './components/StatusRecurrentPayment';

import './RecurrentUserPaymentsList.scss';

interface AllUserPaymentsProps {
  recurrentUserPayments: RecurrentUserPaymentData[];
  action: (paymentId: string, projectName: string) => void;
}

const RecurrentUserPaymentsList = ({
  recurrentUserPayments,
  action,
}: AllUserPaymentsProps) => {
  const renderPlaceholder = () => {
    if (recurrentUserPayments.length === 0) {
      return (
        <p className="recurrent-user-payments-list__placeholder">
          <Trans
            i18nKey={'profile.payments.emptyPaymentsMessage.mobile'}
            components={{
              lnkCatalog: (
                <Link
                  className="text-link"
                  to={paths.projects}
                  rel="noreferrer"
                  target="_blank"
                />
              ),
              lnkFrontPage: (
                <Link
                  className="text-link"
                  to={paths.frontPageDonation}
                  rel="noreferrer"
                  target="_blank"
                />
              ),
            }}
          />
        </p>
      );
    } else {
      return null;
    }
  };
  const renderPaymentsList = () => {
    return recurrentUserPayments.map((payment: RecurrentUserPaymentData) => (
      <StatusRecurrentPayment
        key={payment.recurrent_paymentsID}
        payment={payment}
        action={action}
      />
    ));
  };

  return (
    <div className="recurrent-user-payments-list">
      {renderPlaceholder()}
      {renderPaymentsList()}
    </div>
  );
};

export default RecurrentUserPaymentsList;
