import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import { YANDEX_ZEN } from 'src/shared/constants/externalLinks';
import { paths } from 'src/shared/routes';

import { useGetPresignedUrlsQuery } from 'src/features/minIo/api';
import { PresignedUrlByKeyMapId } from 'src/features/minIo/models';
import { useGetRequisitesQuery } from 'src/features/requisites/api';

import { ReactComponent as Logo } from 'src/pages/main/FrontPage/components/FrontPageFooter/images/logo-7bits.svg';

import './FrontPageFooter.scss';

const NAVIGATION = [
  { path: paths.map, key: 'header.map' },
  { path: paths.projects, key: 'header.catalogProject' },
  { path: paths.members, key: 'header.catalogParticipation' },
  { path: paths.instruction, key: 'header.instruction' },
  { path: paths.reporting, key: 'header.reporting' },
];

const SUBSCRIPTIONS = [
  {
    key: 'footer.subscribe.telegram',
    icon: IconType.Telegram,
    link: process.env.REACT_APP_TELEGRAM,
  },
  {
    key: 'footer.subscribe.vk',
    icon: IconType.Vk,
    link: process.env.REACT_APP_VK,
  },
];

const REQUISITES_KEY = ['requisites.pdf'];
const REQUISITES_INFO_KEY = ['requisites.json'];

interface FrontPageFooterProps {
  newsProjectId: string;
}

export const FrontPageFooter = ({
  newsProjectId,
}: FrontPageFooterProps): JSX.Element => {
  const { t } = useTranslation();
  const isSmallScreen = useCheckSmallScreen();

  const requisitesInfoLink = useGetPresignedUrlsQuery({
    messageMapId: PresignedUrlByKeyMapId.PAYMENT_DOCS,
    keys: REQUISITES_INFO_KEY,
  });

  const { data: requisitesData, isSuccess: isRequisitesSuccess } =
    useGetRequisitesQuery(requisitesInfoLink.data?.data[0]?.url as string, {
      skip: !requisitesInfoLink.isSuccess || requisitesInfoLink.isFetching,
    });

  const REQUISITES_DATA = [
    {
      key: '',
      value: requisitesData?.name,
    },
    {
      key: t('footer.contacts.requisites.INN'),
      value: requisitesData?.INN,
    },
    {
      key: t('footer.contacts.requisites.OGRN'),
      value: requisitesData?.OGRN,
    },
    {
      key: t('footer.contacts.requisites.OKPO'),
      value: requisitesData?.OKPO,
    },
  ];

  const requisitesInfo: string = isRequisitesSuccess
    ? REQUISITES_DATA.map((data) => ` ${data.key} ${data.value}`).join()
    : '';

  const { data: requisitesLinks } = useGetPresignedUrlsQuery({
    messageMapId: PresignedUrlByKeyMapId.PAYMENT_DOCS,
    keys: REQUISITES_KEY,
  });

  const requisitesUrl = useMemo(
    () => requisitesLinks?.data[0]?.url ?? '/',
    [requisitesLinks?.data]
  );

  const CONTACTS = [
    {
      key: 'footer.contacts.call',
      value: process.env.REACT_APP_PHONE_NUMBER,
      icon: IconType.Phone,
      link: isSmallScreen ? `tel:${process.env.REACT_APP_PHONE_NUMBER}` : '',
    },
    {
      key: 'footer.contacts.write',
      value: process.env.REACT_APP_SUPPORT_EMAIL,
      icon: IconType.Letter,
      link: `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`,
    },
    {
      key: 'footer.contacts.address',
      value: process.env.REACT_APP_ADDRESS,
      icon: IconType.Marker,
      link: `/map?projectId=${newsProjectId}`,
    },
    {
      key: 'footer.contacts.requisites.header',
      value: requisitesInfo,
      icon: IconType.Docs,
      link: requisitesUrl,
    },
  ];

  return (
    <footer className="front-page-footer">
      <ul className="front-page-footer__links">
        {NAVIGATION.map((n) => (
          <li key={n.key}>
            <Link to={n.path} className="front-page-footer__link">
              {t(n.key)}
            </Link>
          </li>
        ))}
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href={YANDEX_ZEN}
            className="front-page-footer__link"
          >
            <Icon
              icon={IconType.YandexZen}
              className="front-page-footer__link-icon"
            />
            {t('header.bestPractices')}
          </a>
        </li>
      </ul>
      <div className="front-page-footer__contacts">
        {CONTACTS.map((c, i) => {
          let value = (
            <a href={c.link} target="_blank" rel="noreferrer">
              {c.value}
            </a>
          );
          if (!isSmallScreen && i === 0) {
            value = <p>{c.value}</p>;
          }
          return (
            <li key={c.key}>
              <h1 className="footer-contacts__label">{t(c.key)}</h1>
              <div className="footer-contacts__container">
                <Icon icon={c.icon} />
                {value}
              </div>
            </li>
          );
        })}
      </div>
      <div className="front-page-footer__subscribe">
        <h1 className="footer-subscribe__label">
          {t('footer.subscribe.label')}
        </h1>
        {SUBSCRIPTIONS.map((s) => (
          <li key={s.key}>
            <div className="footer-subscriptions__container">
              <Icon icon={s.icon} />
              <a href={s.link} target="_blank" rel="noreferrer">
                {t(s.key)}
              </a>
            </div>
          </li>
        ))}
      </div>

      <div className="front-page-footer__development">
        <Logo className="front-page-footer__logo" />
        <div className="footer-development__text">
          <Trans
            i18nKey="footer.development"
            components={{
              lnk: (
                <a
                  className="footer-development__link"
                  rel="noreferrer"
                  href={process.env.REACT_APP_7BITS}
                  target="_blank"
                >
                  <i></i>
                </a>
              ),
            }}
          />
        </div>
      </div>

      <span className="front-page-footer__city">{t('footer.city')}</span>
    </footer>
  );
};
