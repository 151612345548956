import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  ErrorResponse,
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import { useCheckIfUserAuthorized } from 'src/features/hooks';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';

import { useChangeFollowMutation } from '../../api/reactionApi';

import './FollowButton.scss';

const ANIMATION_DURATION = 1000;

interface FollowButtonProps {
  projectId: string;
  changeFollowLocal: () => void;
  disabled?: boolean;
  wasFollowed?: boolean;
  className?: string;
  showText?: boolean;
}

const FollowButton = ({
  projectId,
  changeFollowLocal,
  disabled = false,
  wasFollowed = false,
  className = '',
  showText = true,
}: FollowButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSmallScreen = useCheckSmallScreen();

  const [buttonExtraClassName, setButtonExtraClassName] = useState('');
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  const { isSuccess: whoamiSuccess } = useWhoamiQuery();

  const [changeFollow] = useChangeFollowMutation();

  const toggleFollow = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(undefined);
      setButtonExtraClassName('');
    } else {
      changeFollow({ projectId }).then((response) => {
        if (!(response as ErrorResponse)?.error) {
          setButtonExtraClassName(
            wasFollowed
              ? ' follow-button--clicked-unfollow'
              : ' follow-button--clicked-follow'
          );
          setTimeoutId(
            setTimeout(() => {
              setButtonExtraClassName('');
              setTimeoutId(undefined);
            }, ANIMATION_DURATION)
          );
          changeFollowLocal();
        }
      });
    }
  };

  const requestFollow = useCheckIfUserAuthorized(toggleFollow);

  const onFollowClick = () => {
    requestFollow().then(() => {
      if (!whoamiSuccess) {
        isSmallScreen
          ? navigate(paths.signIn)
          : dispatch(changeModal(MODALS.SIGN_IN));
      }
    });
  };

  const renderText = () => {
    return wasFollowed ? t('reaction.follow.off') : t('reaction.follow.on');
  };

  return isSmallScreen ? (
    <Button
      className={`follow-button${buttonExtraClassName} ${className}`}
      type={BUTTON_TYPE.TEXT_SECONDARY}
      onClick={onFollowClick}
      disabled={disabled}
    >
      <Icon
        className={`follow-button__icon${
          wasFollowed ? ' follow-button__icon--followed' : ''
        }`}
        icon={IconType.Bell}
      />
      {showText ? renderText() : null}
    </Button>
  ) : (
    <Button
      className={`follow-button${buttonExtraClassName} ${className}`}
      type={BUTTON_TYPE.SECONDARY}
      onClick={onFollowClick}
      disabled={disabled}
    >
      <Icon
        className={`follow-button__icon${
          wasFollowed ? ' follow-button__icon--followed' : ''
        }`}
        icon={IconType.Bell}
      />
      {showText ? renderText() : null}
    </Button>
  );
};

export default FollowButton;
