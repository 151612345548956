import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Icon, IconType } from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import { setMobileMenuOpen } from 'src/features/control/slices/controlSlice';

import './ProfileHeader.scss';

interface ProfileHeaderProps {
  className?: string;
}

const ProfileHeader = ({ className = '' }: ProfileHeaderProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation('app', { keyPrefix: 'header' });

  const { data: whoamiData, isSuccess: isWhoamiSuccess } = useWhoamiQuery();

  const username = useMemo(() => {
    if (isWhoamiSuccess && whoamiData.data.profile?.name) {
      return whoamiData.data.profile.name;
    } else {
      return '';
    }
  }, [isWhoamiSuccess, whoamiData?.data.profile?.name]);

  return (
    <NavLink
      onClick={() => dispatch(setMobileMenuOpen(false))}
      className={`${className} profile-header`}
      to={paths.profile}
    >
      <Icon icon={IconType.User} className="profile-header__icon" />
      <p className="profile-header__user-info">
        {isWhoamiSuccess ? username : t('signIn')}
      </p>
    </NavLink>
  );
};

export default ProfileHeader;
