import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import SignInForm from 'src/features/auth/components/SignIn/SignIn';
import { BackdoorPayload, BypassPayload } from 'src/features/modal/models';

import './SignIn.scss';

const SignIn = () => {
  const isSmallScreen = useCheckSmallScreen();
  const { state } = useLocation();
  const navigate = useNavigate();

  const _state = state as BackdoorPayload | undefined;

  const comeBack = () => {
    if (_state?.backdoor) {
      const payload: BypassPayload = { bypass: true };
      navigate(_state.backdoor, { state: payload, replace: true });
    } else {
      navigate(paths.map, { replace: true });
    }
  };

  return isSmallScreen ? (
    <div className="sign-in-page">
      {_state?.backdoor ? <SignInForm onSuccess={comeBack} /> : <SignInForm />}
    </div>
  ) : (
    <Navigate to={paths.map} replace />
  );
};

export default SignIn;
