import { useEffect } from 'react';

import './CircleProgressBar.scss';

const OFFSET_COUNT_ELEMENT = 280;

interface CircleProgressBarProps {
  collected?: number;
  total?: number;
  archived?: boolean;
}

const CircleProgressBar = ({
  collected = 0,
  total = 0,
  archived = false,
}: CircleProgressBarProps) => {
  const percent = Number(((collected / total) * 100).toFixed(1));

  useEffect(() => {
    const percentValue =
      percent < 100
        ? OFFSET_COUNT_ELEMENT - (OFFSET_COUNT_ELEMENT * percent) / 100
        : 0;
    document.documentElement.style.setProperty(
      '--progress',
      percentValue.toString()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collected, total]);

  const dynamicClass = (s: TemplateStringsArray) => {
    const _archived = archived ? `${s[0]}--archived` : '';
    return `${s[0]} ${_archived}`;
  };

  const percentClass = (s: TemplateStringsArray) => {
    const zero = percent === 0 ? `${s[0]}-zero` : '';
    const size = percent.toString().length > 3 ? `${s[0]}-more` : '';
    return `${s[0]} ${zero} ${size}`;
  };

  return (
    <div className={dynamicClass`circle-progress-bar__card`}>
      <svg width="100%" height="100%" viewBox="0 0 100 100">
        <circle cx="50%" cy="50%" r="44%"></circle>
        <circle
          cx="50%"
          cy="50%"
          r="44%"
          className="circle-progress-bar__circle-offset"
        ></circle>
      </svg>
      <h2 className={percentClass`circle-progress-bar__number`}>
        {`${percent}%`}
      </h2>
    </div>
  );
};

export default CircleProgressBar;
