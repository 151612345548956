import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, Navigate, useOutletContext } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import StageHeader from 'src/features/layout/components/StageHeader/StageHeader';
import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import { CreationOutletProps } from '../../models';

import './CreationAlgorithm.scss';

enum ALGORITHM_STAGE {
  IDEA = 'idea',
  MODERATION = 'moderation',
  DESIGNING = 'designing',
  APPROVING = 'approving',
  IMPLEMENTATION = 'implementation',
  COMMISSIONING = 'commissioning',
  TRANSFER = 'transfer',
}

const STAGE = 1;

const CreationAlgorithm = (): JSX.Element => {
  const isSmallScreen = useCheckSmallScreen();
  const { renderFooter }: CreationOutletProps = useOutletContext();
  const configCreationSteps = useSelector(
    (s: State) => s[PROJ_CREATE].configCreationSteps
  );
  const currentProjectType = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );
  const { t } = useTranslation('app', {
    keyPrefix: `creation.${currentProjectType}.algorithm`,
  });

  // TODO(Nariman): Update render using configCreationSteps when config will be ready
  const renderStage = (wrapperClassName: string) =>
    Object.values(ALGORITHM_STAGE).map((value) => (
      <Link
        key={value}
        to={paths.instruction}
        id={value}
        target="_blank"
        className="creation-algorithm__stage"
      >
        <div className="creation-algorithm__stage-icon" />
        <div className={isSmallScreen ? wrapperClassName : ''}>
          <h4 className="creation-algorithm__stage-title">
            {t(`steps.${value}.title`)}
          </h4>
          {value !== ALGORITHM_STAGE.TRANSFER && (
            <>
              <h4 className="creation-algorithm__stage-duration">
                {t(`steps.${value}.duration`)}
              </h4>
              <p className="creation-algorithm__stage-description">
                {t(`steps.${value}.description`)}
              </p>
            </>
          )}
        </div>
      </Link>
    ));

  return currentProjectType ? (
    <div className="creation-algorithm">
      <div className="creation-algorithm__scroll">
        <StageHeader
          className="creation-algorithm__title"
          header={`${isSmallScreen ? `${STAGE}. ` : ''}${t('title')}`}
        />
        <p className="creation-algorithm__description">
          <Trans
            i18nKey={`creation.${currentProjectType}.algorithm.description`}
            components={{
              instructions: (
                <Link
                  className="text-link creation-algorithm__description--link"
                  to={paths.instruction}
                  target="_blank"
                />
              ),
            }}
          />
        </p>
        <div className="creation-algorithm__scheme">
          {renderStage('creation-algorithm__stage-block-description')}
          {isSmallScreen ? null : (
            <div id="next" className="creation-algorithm__stage">
              <h4 className="creation-algorithm__stage-title">
                {t('steps.next.title')}
              </h4>
              {renderFooter?.({
                className: 'creation-algorithm__button-navigation',
                nextButtonText: t('steps.next.initProject'),
                valid: configCreationSteps.length !== 0,
              })}
            </div>
          )}
        </div>
      </div>
      {isSmallScreen
        ? renderFooter?.({
            className: '',
            nextButtonText: t('steps.next.initProject'),
            valid: configCreationSteps.length !== 0,
          })
        : null}
    </div>
  ) : (
    <Navigate to={paths.creation} />
  );
};

export default CreationAlgorithm;
