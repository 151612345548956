import React, { useRef, useState } from 'react';

import { Filter } from './filters/filter';
import { Formatter } from './formatters/formatters';
import FormattedInput from './FormattedInput';

type NumericInputProps = Parameters<typeof FormattedInput>[0];

interface FormattedNumericInputProps
  extends Omit<NumericInputProps, 'formattedValue' | 'onChange'> {
  filter?: Filter;
  formatter?: Formatter;
  numericValue: string;
  placeholer: string;
  onNumericChange: (value: string) => void;
}

/**
 * Expects a `numericValue` string containing either only number characters or
 * only characters that can be used to represent a number. This string will be
 * formatted for display. The owner will be notified when the numeric string
 * value changes by `onNumericChange`.
 *
 * For example, feed in a string like "1234567890" with the correct filters and
 * formatters and it will be displayed as "1 23 456 78 90".
 * @param props Props for this component.
 */
export function FormattedNumericInput({
  filter = (v) => v,
  formatter = (v) => v,
  onBlur,
  onNumericChange,
  placeholer,
  numericValue,
  ...props
}: FormattedNumericInputProps) {
  const numeric = useRef(filter(numericValue));
  const [displayValue, setDisplayValue] = useState(
    formatter(filter(numericValue))
  );

  function handleBlur(evt: React.FocusEvent<HTMLInputElement>) {
    setDisplayValue(formatter(filter(evt.target.value)));

    if (onBlur) {
      onBlur(evt);
    }
  }

  const handleChange: NumericInputProps['onChange'] = (value, changeType) => {
    const next = formatter(filter(value, numeric.current));
    const nextDisplay = changeType !== 'add' ? value : next;

    setDisplayValue(nextDisplay);

    const nextNumeric = filter(nextDisplay);
    if (numeric.current !== nextNumeric) {
      numeric.current = nextNumeric;
      onNumericChange(nextNumeric);
    }
  };

  return (
    <FormattedInput
      formattedValue={displayValue}
      onBlur={handleBlur}
      placeholer={placeholer}
      onChange={handleChange}
      {...props}
    />
  );
}
