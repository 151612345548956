import { useTranslation } from 'react-i18next';

import {
  Icon,
  IconType,
  ProgressBar,
  PROJECT_STATUS,
  Status,
} from '@platform-for-public-places/components-library';
import { ru } from 'date-fns/locale';

import { currency } from 'src/app/constants';
import { convertBudgetToPercents } from 'src/shared/converters/budgetConverter';

import { SPECIFIC_LAYER, Timeline } from '../../models';

import ProjectStatusTile from './ProjectStatusTile/ProjectStatusTile';

import './ProjectPreviewContainer.scss';

interface ProjectPreviewProps {
  image?: string;
  name: string;
  projectId: string;
  wasLiked?: boolean;
  likesCount?: number;
  comments?: number;
  status?: Status;
  large?: boolean;
  className?: string;
  statusClassName?: string;
  layer?: SPECIFIC_LAYER;
  donationTotal?: number;
  budget?: number;
  timeline?: Timeline;
  joined?: boolean;
  countOfJoined?: number;
}

const options: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

const ProjectPreviewContainer = ({
  image,
  name,
  comments,
  status,
  wasLiked = false,
  likesCount = 0,
  large = false,
  className = '',
  statusClassName = '',
  layer,
  donationTotal = 0,
  budget = 0,
  timeline,
  joined = false,
  countOfJoined = 0,
}: ProjectPreviewProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'cards.overview' });

  const applySize = (s: string) => (large ? `${s} ${s}--large` : s);

  const renderBudgetInfo = (
    donationTotal: number,
    budget: number,
    currency: string
  ) => {
    return (
      <div className="budget-info">
        <p className="budget-info__collected">
          {t('collected', {
            collected: donationTotal,
            currency: currency,
          })}
        </p>
        {budget ? (
          <p className="budget-info__amount">
            {t('outOf', {
              outOf: budget,
              currency: currency,
            })}
          </p>
        ) : null}
      </div>
    );
  };

  const renderStatus = () => {
    if (large) {
      return (
        <>
          <div className={`project-preview__status ${statusClassName}`}>
            {renderBudgetInfo(donationTotal, budget, currency['ru'])}
          </div>
          <ProgressBar
            percents={convertBudgetToPercents(donationTotal, budget)}
            className="project-preview__progress"
          />
        </>
      );
    }
  };

  const renderPlannedStatus = (startDate: string) =>
    new Date(startDate).toLocaleString(ru.code, options).replace(',', '');

  const renderRunningStatus = (endDate: string) =>
    `${t('projectStatus.timeline.until')} ${new Date(endDate)
      .toLocaleString(ru.code, options)
      .replace(',', '')}`;

  const renderStatusName = (
    status: PROJECT_STATUS,
    layer: SPECIFIC_LAYER,
    timeline?: Timeline
  ) => {
    if (layer === SPECIFIC_LAYER.EVENT && timeline) {
      const { startDate, endDate } = timeline;

      switch (status) {
        case PROJECT_STATUS.PLANNED:
          return renderPlannedStatus(startDate);

        case PROJECT_STATUS.RUNNING:
          return renderRunningStatus(endDate);

        default:
          return t(`projectStatus.event.${status}`);
      }
    }

    return t(`projectStatus.${layer}.${status}`);
  };

  const renderReaction = (
    icon: IconType,
    type: string,
    count?: number,
    active?: boolean
  ) => {
    return (
      <div className={applySize('project-preview__reaction-container')}>
        <Icon
          className={`project-preview__reaction-icon${
            active
              ? ` project-preview__reaction-icon--active project-preview__reaction-icon--active_${type}`
              : ''
          }`}
          icon={icon}
        />
        <p className="project-preview__reaction-text">{count || 0}</p>
      </div>
    );
  };

  return (
    <div className={`${applySize('project-preview')} ${className}`}>
      <div className="project-preview__cover">
        <img
          src={image ?? '/staticImages/mock.svg'}
          alt={name}
          className={applySize('project-preview__image')}
        />
        {layer && status ? (
          <ProjectStatusTile
            status={status.value}
            statusName={renderStatusName(status.value, layer, timeline)}
            layer={layer}
          />
        ) : null}
      </div>
      <p className={applySize('project-preview__title')}>{name}</p>
      {status ? renderStatus() : null}
      <div className={applySize('project-preview__reactions')}>
        {renderReaction(IconType.Heart, 'like', likesCount, wasLiked)}
        {renderReaction(IconType.Comment, 'comment', comments)}
        {layer === SPECIFIC_LAYER.EVENT &&
          renderReaction(
            joined ? IconType.HandFilled : IconType.HandUnfilled,
            'joined',
            countOfJoined,
            joined
          )}
      </div>
    </div>
  );
};

export default ProjectPreviewContainer;
