import { useMemo } from 'react';
import { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook';
import { Link } from 'react-router-dom';

import { PROJECT_STATUS } from '@platform-for-public-places/components-library';

import {
  useGetFilesQuery,
  useGetProjectCoverQuery,
} from 'src/features/files/api';
import { FileCategories } from 'src/features/files/enums';
import { useGetDonationDetailsQuery } from 'src/features/payment/api';
import {
  useGetEventParticipantsInfoQuery,
  useGetProjectCommentsQuery,
  useGetProjectTimelineQuery,
} from 'src/features/project/api';
import { ProjectCatalogData } from 'src/features/project/models';
import { useGetProjectLikesQuery } from 'src/features/reaction/api';
import LikeButtonCompact from 'src/features/reaction/components/LikeButtonCompact/LikeButtonCompact';
import { useGetStatusQuery } from 'src/features/status/api';

import ProjectPreviewContainer from '../ProjectPreviewContainer';

import './ProjectPreviewCatalog.scss';

const PHOTO_LIMIT = 1;
const PHOTO_OFFSET = 0;

interface ProjectPreviewProps {
  linkTo: string;
  project: ProjectCatalogData;
  className?: string;
  statusClassName?: string;
  wasLiked?: boolean;
  likesCount?: number;
  large?: boolean;
  ref?: UseInfiniteScrollHookRefCallback;
}

const ProjectPreviewCatalog = ({
  linkTo,
  project,
  className = '',
  statusClassName = '',
  large = false,
  ref = undefined,
}: ProjectPreviewProps) => {
  const {
    data: photosCover,
    isFetching: coverFetching,
    isSuccess: coverSuccess,
  } = useGetProjectCoverQuery({
    projectId: project.projectId,
  });

  const { data: photos } = useGetFilesQuery(
    {
      entityId: project.projectId,
      category: FileCategories.PHOTO_BEFORE,
      limit: PHOTO_LIMIT,
      offset: PHOTO_OFFSET,
    },
    {
      skip: coverFetching || (coverSuccess && !!photosCover?.data.cover),
    }
  );

  const { data: commentInfo } = useGetProjectCommentsQuery({
    projectId: project.projectId,
  });

  const { data: likeInfo } = useGetProjectLikesQuery({
    projectId: project.projectId,
  });

  const { data: donationDetails } = useGetDonationDetailsQuery({
    projectId: project.projectId,
  });

  const { data: statusInfo } = useGetStatusQuery({
    projectId: project.projectId,
  });

  const { data: timelineInfo } = useGetProjectTimelineQuery({
    projectId: project.projectId,
  });

  const { data: eventParticipantsInfo } = useGetEventParticipantsInfoQuery({
    projectId: project.projectId,
  });

  const url = useMemo(() => {
    if (photosCover?.data.cover) {
      return photosCover.data.cover.url;
    } else if (photos?.data.length) {
      return photos.data[0].url;
    } else {
      return undefined;
    }
  }, [photos?.data, photosCover?.data.cover]);

  return (
    <div className="project-preview-catalog">
      {statusInfo?.data.statusInfo?.status?.value ===
      PROJECT_STATUS.FRAMED ? null : (
        <LikeButtonCompact
          className="project-preview-catalog__like"
          projectId={project.projectId}
          wasLiked={likeInfo?.data.likeInfo?.liked}
        />
      )}
      <Link
        to={linkTo}
        className="project-preview-catalog__link"
        state={{ goOnMap: false }}
        ref={ref}
      >
        <ProjectPreviewContainer
          image={url}
          name={project.name}
          status={statusInfo?.data.statusInfo?.status}
          large={large}
          className={className}
          statusClassName={statusClassName}
          projectId={project.projectId}
          wasLiked={likeInfo?.data.likeInfo?.liked}
          likesCount={likeInfo?.data.likeInfo?.likes}
          comments={commentInfo?.data.commentInfo?.comments}
          layer={project.layer}
          donationTotal={donationDetails?.data.paymentDetails?.donationTotal}
          budget={donationDetails?.data.paymentDetails?.budget}
          timeline={timelineInfo?.data.timeline}
          joined={eventParticipantsInfo?.data.joinedToEventInfo?.joined}
          countOfJoined={
            eventParticipantsInfo?.data.joinedToEventInfo?.countOfJoined
          }
        />
      </Link>
    </div>
  );
};

export default ProjectPreviewCatalog;
