import { useSelector } from 'react-redux';

import { MODAL as MODAL } from 'src/features/modal/slices/modalSlice';
import { State } from 'src/features/store/store';

import AutopaymentsManagementModal from './components/AutopaymentsManagementModal/AutopaymentsManagementModal';
import AutopaymentsManagementSentModal from './components/AutopaymentsManagementSentModal/AutopaymentsManagementSentModal';
import ConfirmModal from './components/ConfirmModal/ConfirmModal';
import DetailedPlatformTeamMember from './components/DetailedPlatformTeamMember/DetailedPlatformTeamMember';
import DonationFailureModal from './components/DonationFailureModal/DonationFailureModal';
import DonationModal from './components/DonationModal/DonationModal';
import DonationSuccessModal from './components/DonationSuccessModal/DonationSuccessModal';
import EmailConfirmedModal from './components/EmailConfirmedModal/EmailConfirmedModal';
import ForgotPasswordModal from './components/ForgotPasswordModal/ForgotPasswordModal';
import ForgotPasswordSentModal from './components/ForgotPasswordSentModal/ForgotPasswordSentModal';
import RequestFormAddToProject from './components/FormAddToProject/FormAddToProject';
import PictureModal from './components/PictureModal/PictureModal';
import RecoveryPasswordModal from './components/RecoveryPasswordModal/RecoveryPasswordModal';
import RecoveryPasswordSuccessModal from './components/RecoveryPasswordSuccessModal/RecoveryPasswordSuccessModal';
import SignInModal from './components/SignInModal/SignInModal';
import SignUpModal from './components/SignUpModal/SignUpModal';
import UserCardModal from './components/UserCardModal/UserCardModal';
import { MODALS } from './models';
import { useLockBodyScroll } from '../hooks';

const modalMap = {
  [MODALS.PICTURE]: <PictureModal />,
  [MODALS.SIGN_IN]: <SignInModal />,
  [MODALS.SIGN_UP]: <SignUpModal />,
  [MODALS.CONFIRMATION]: <ConfirmModal />,
  [MODALS.EMAIL_CONFIRMED]: <EmailConfirmedModal />,
  [MODALS.INITIATORS_USER_CARD]: <UserCardModal />,
  [MODALS.FORGOT_PASSWORD]: <ForgotPasswordModal />,
  [MODALS.FORGOT_PASSWORD_SENT]: <ForgotPasswordSentModal />,
  [MODALS.RECOVERY_PASSWORD]: <RecoveryPasswordModal />,
  [MODALS.RECOVERY_PASSWORD_SUCCESS]: <RecoveryPasswordSuccessModal />,
  [MODALS.SPECIALIST_USER_CARD]: <UserCardModal />,
  [MODALS.DETAILED_PLATFORM_TEAM_MEMBER]: <DetailedPlatformTeamMember />,
  [MODALS.FORM_ADD_TO_PROJECT]: <RequestFormAddToProject />,
  [MODALS.PAYMENT]: <DonationModal />,
  [MODALS.PAYMENT_SUCCESS]: <DonationSuccessModal />,
  [MODALS.PAYMENT_FAILURE]: <DonationFailureModal />,
  [MODALS.AUTOPAYMENTS_MANAGEMENT]: <AutopaymentsManagementModal />,
  [MODALS.AUTOPAYMENTS_MANAGEMENT_SENT]: <AutopaymentsManagementSentModal />,
};

const mobileModalMap = {
  [MODALS.PICTURE]: <PictureModal />,
};

export const ModalBase = () => {
  const openedModal = useSelector((s: State) => s[MODAL].openedModal);
  useLockBodyScroll(!!openedModal);
  return openedModal !== null ? modalMap[openedModal] : null;
};

export const ModalSmallScreen = () => {
  const openedModal = useSelector((s: State) => s[MODAL].openedModal);
  return openedModal === MODALS.PICTURE ? mobileModalMap[openedModal] : null;
};
