import { useTranslation } from 'react-i18next';

import { CategoryButton } from '@platform-for-public-places/components-library';

import { FileCategories, PhotoFileCategories } from 'src/features/files/enums';
import { SPECIFIC_LAYER } from 'src/features/project/models';

import './PhotoCategoriesTabs.scss';
interface PhotoCategoriesTabsProps {
  layer?: SPECIFIC_LAYER;
  category: PhotoFileCategories;
  onTabClick: (category: PhotoFileCategories) => void;
}

interface PhotosTabs {
  title: string;
  onClick: () => void;
  selected: boolean;
  className?: string;
}

const PhotoCategoriesTabs = ({
  layer,
  category,
  onTabClick,
}: PhotoCategoriesTabsProps): JSX.Element => {
  const { t } = useTranslation('app', {
    keyPrefix: 'detailedProject.photoCategories',
  });

  const photosTabs: Record<SPECIFIC_LAYER, PhotosTabs[]> = {
    [SPECIFIC_LAYER.DEFAULT]: [
      {
        title: 'before',
        onClick: () => onTabClick(FileCategories.PHOTO_BEFORE),
        selected: category === FileCategories.PHOTO_BEFORE,
      },
      {
        title: 'concept',
        onClick: () => onTabClick(FileCategories.PHOTO_CONCEPT),
        selected: category === FileCategories.PHOTO_CONCEPT,
      },
      {
        title: 'after',
        onClick: () => onTabClick(FileCategories.PHOTO_AFTER),
        selected: category === FileCategories.PHOTO_AFTER,
      },
    ],
    [SPECIFIC_LAYER.EVENT]: [
      {
        title: 'before',
        onClick: () => onTabClick(FileCategories.PHOTO_BEFORE),
        selected: category === FileCategories.PHOTO_BEFORE,
      },
      {
        title: 'after',
        onClick: () => onTabClick(FileCategories.PHOTO_AFTER),
        selected: category === FileCategories.PHOTO_AFTER,
      },
    ],
    [SPECIFIC_LAYER.MUNICIPAL]: [
      {
        title: 'before',
        onClick: () => onTabClick(FileCategories.PHOTO_BEFORE),
        selected: category === FileCategories.PHOTO_BEFORE,
      },
      {
        title: 'concept',
        onClick: () => onTabClick(FileCategories.PHOTO_CONCEPT),
        selected: category === FileCategories.PHOTO_CONCEPT,
      },
      {
        title: 'after',
        onClick: () => onTabClick(FileCategories.PHOTO_AFTER),
        selected: category === FileCategories.PHOTO_AFTER,
      },
    ],
    [SPECIFIC_LAYER.NEWS]: [],
  };

  const tabs = layer ? photosTabs[layer] : photosTabs[SPECIFIC_LAYER.DEFAULT];

  return (
    <div className="detailed-info__categories">
      {tabs.map((type) => (
        <CategoryButton
          key={type.title}
          className={type.className}
          onClick={type.onClick}
          selected={type.selected}
        >
          {t(type.title)}
        </CategoryButton>
      ))}
    </div>
  );
};

export default PhotoCategoriesTabs;
