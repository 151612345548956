import { useEffect, useState } from 'react';
import {
  DayPickerProvider,
  NavigationProvider as DPNavigationProvider,
  SelectSingleEventHandler,
} from 'react-day-picker';
import { useTranslation } from 'react-i18next';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';
import { ru } from 'date-fns/locale';

import DatePicker from '../DatePicker/DatePicker';

import './DatePickerButton.scss';

interface TimePickerProps {
  className?: string;
  date?: Date;
  setDate?: (value: Date) => void;
  disabled?: boolean;
  onFocus?: () => void;
  onFocusLeave?: (value?: string) => void;
}

const DatePickerButton = ({
  className,
  date,
  disabled = false,
  setDate = () => ({}),
  onFocus = () => ({}),
  onFocusLeave = () => ({}),
}: TimePickerProps): JSX.Element => {
  const [showPicker, setShowPicker] = useState(false);
  const [newDate, setNewDate] = useState<Date | undefined>(date);

  const { t } = useTranslation('app', { keyPrefix: 'datePicker' });

  useEffect(() => {
    setNewDate(date);
  }, [date]);

  const onDatePick: SelectSingleEventHandler = (_, selectedDay) => {
    onFocusLeave(newDate?.toISOString() ?? selectedDay.toISOString());
    setDate(selectedDay);
    setNewDate(selectedDay);
    setShowPicker(false);
  };

  const onOutsideClick = () => {
    setShowPicker(false);
    onFocusLeave(newDate?.toISOString());
  };

  const onOpenModal = () => {
    onFocus();
    setShowPicker(true);
  };

  const renderDatePicker = () => {
    return (
      <DayPickerProvider initialProps={{ mode: 'single' }}>
        <DPNavigationProvider>
          <DatePicker
            className="date-picker-button__date-picker"
            selected={newDate}
            onSelect={onDatePick}
            onOutsideClick={onOutsideClick}
          />
        </DPNavigationProvider>
      </DayPickerProvider>
    );
  };

  return (
    <div className={`date-picker-button ${className}`}>
      <Button
        disabled={disabled}
        className={`date-picker-button__date`}
        type={BUTTON_TYPE.SECONDARY}
        onClick={() => onOpenModal()}
      >
        <Icon icon={IconType.Calendar} />
        {newDate ? newDate.toLocaleDateString(ru.code) : t('pickDate')}
      </Button>
      {showPicker ? renderDatePicker() : null}
    </div>
  );
};

export default DatePickerButton;
