import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { paths } from 'src/shared/routes';
import { areaWidgetsMap } from 'src/shared/widgets/area';

import { useCreateGeodataMutation } from 'src/features/project/api';
import { CreateGeodataRequest } from 'src/features/project/models';
import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import { CreationOutletProps } from '../../models';

const CreationArea = () => {
  const navigate = useNavigate();
  const { renderFooter }: CreationOutletProps = useOutletContext();

  const currentStep = useSelector(
    (s: State) => s[PROJ_CREATE].currentCreationStep
  );
  const configSteps = useSelector(
    (s: State) => s[PROJ_CREATE].configCreationSteps
  );

  const center = useSelector((s: State) => s[PROJ_CREATE].center);
  const feature = useSelector((s: State) => s[PROJ_CREATE].feature);
  const meetingPoint = useSelector((s: State) => s[PROJ_CREATE].meetingPoint);

  useEffect(() => {
    if (configSteps.length === 0) {
      navigate(paths.creation);
    }
  }, [navigate, configSteps]);

  const AreaWidgetComponent = areaWidgetsMap.get(
    configSteps[currentStep] ? configSteps[currentStep].type : ''
  );

  const valid = useMemo(() => !!feature, [feature]);

  const [createGeodata] = useCreateGeodataMutation();

  const updateGeodata = (projectId: string) => {
    if (feature && center) {
      const request: CreateGeodataRequest = {
        geojson: {
          type: feature.type,
          geometry: feature.geometry,
          properties: {
            projectId,
            center: center.geometry.coordinates,
          },
        },
        meetingPoint,
      };
      createGeodata(request);
    }
  };

  return (
    <>
      {AreaWidgetComponent?.({ configStep: configSteps[currentStep] }) || null}
      {renderFooter?.({
        updateStepData: updateGeodata,
        valid,
        className: 'area-selection__footer',
      })}
    </>
  );
};

export default CreationArea;
