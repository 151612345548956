import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SearchContext } from 'src/features/searchWithFilter/filter/search/SearchProvider';
import CheckBoxFilterList from 'src/features/searchWithFilter/filter/searchFilters/CheckBoxFilterList/CheckBoxFilterList';
import FilterWithHeader from 'src/features/searchWithFilter/filter/searchFilters/FilterList/FilterWithHeader';
import { FILTER } from 'src/features/searchWithFilter/filter/slices/filterSlice';
import { State } from 'src/features/store/store';

const FilterByType = () => {
  const { t } = useTranslation('app', { keyPrefix: 'searchFilter' });
  return (
    <FilterWithHeader label={t('byType')}>
      <TypeFilters />
    </FilterWithHeader>
  );
};

export default FilterByType;

type TypeFiltersProps = {
  className?: string;
};
export const TypeFilters = ({ className }: TypeFiltersProps) => {
  const { allTypes } = useSelector((s: State) => s[FILTER]);
  const { onTypeChange } = useContext(SearchContext);
  const { type } = useSelector((s: State) => s[FILTER]);
  return (
    <CheckBoxFilterList
      className={className}
      listValues={allTypes ?? []}
      selectedList={type}
      onChange={onTypeChange}
    />
  );
};
