import {
  ActorBaseResponse,
  GetCoverResponse,
  GetFilesResponse,
} from '@platform-for-public-places/components-library';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import {
  CreateFileKeysRequest,
  GetCoverRequest,
  GetFilesRequest,
  SetProjectCoverRequest,
} from '../models';

export const FILES_API = 'FILES_API';

const filesApi = createApi({
  reducerPath: FILES_API,
  baseQuery: retry(
    fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URLS }),
    { maxRetries: 0 }
  ),
  tagTypes: ['FILES', 'COVER'],
  endpoints: (builder) => ({
    getFiles: builder.query<
      ActorBaseResponse<GetFilesResponse[]>,
      GetFilesRequest
    >({
      query: (request) => {
        const { archived, ...req } = request;
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-projects/get-files-of-archived-project'
              : 'files/get-files',
            arguments: req,
          },
        };
      },
      providesTags: ['FILES'],
    }),
    createFileKeys: builder.mutation<
      ActorBaseResponse<void>,
      CreateFileKeysRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'files/file-keys-upsert',
          arguments: request,
        },
      }),
      invalidatesTags: ['FILES'],
    }),
    getProjectCover: builder.query<
      ActorBaseResponse<GetCoverResponse>,
      GetCoverRequest
    >({
      query: (request) => {
        const { archived, ...req } = request;
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-project-cover/get-project-cover'
              : 'project-cover/get-project-cover',
            arguments: req,
          },
        };
      },
      providesTags: ['COVER'],
    }),
    setProjectCover: builder.mutation<
      ActorBaseResponse<void>,
      SetProjectCoverRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-cover/set-cover-to-project',
          arguments: request,
        },
      }),
      invalidatesTags: ['COVER'],
    }),
  }),
});

export const {
  useGetFilesQuery,
  useLazyGetFilesQuery,
  useCreateFileKeysMutation,
  useGetProjectCoverQuery,
  useLazyGetProjectCoverQuery,
  useSetProjectCoverMutation,
} = filesApi;

export const filesInvalidator = filesApi.util.invalidateTags;

export const filesMiddleware = filesApi.middleware;

export default filesApi.reducer;
