import { LegendProps } from 'recharts';

import './ReportingChartLegend.scss';

const ReportingChartLegend = ({ payload }: LegendProps) => {
  return (
    <ul className="reporting-chart-legend">
      {payload?.reverse().map((element) => (
        <li className="reporting-chart-legend__element" key={element.value}>
          <div
            className={`reporting-chart-legend__marker reporting-chart-legend__${element.dataKey}`}
          />
          <p className="reporting-chart-legend__element-name">
            {element.value}
          </p>
        </li>
      ))}
    </ul>
  );
};

export default ReportingChartLegend;
