import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  timestampToString,
  timestampToTimeString,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import { REQUEST_STATUS } from 'src/features/request/enums';

import './SpecialistRequestCard.scss';

const STATUS_REJECTED = REQUEST_STATUS.REJECTED;

interface SpecialistRequestProps {
  onButtonClick: () => void;
  dateTime: string;
  status: REQUEST_STATUS;
  comment?: string;
  className?: string;
}

const SpecialistRequest = ({
  onButtonClick,
  className,
  dateTime,
  status,
  comment,
}: SpecialistRequestProps): JSX.Element => {
  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', {
    keyPrefix: 'profile.specialist.request',
  });

  const [opened, setOpened] = useState<boolean>(false);

  const onClick = () => {
    setOpened(!opened);
    onButtonClick();
  };

  const requestStatus = status.toLowerCase();
  const date = timestampToString(Date.parse(dateTime));
  const time = timestampToTimeString(Date.parse(dateTime));

  return (
    <div
      className={`specialist-request-container ${className} specialist-request_${requestStatus}`}
    >
      <div className="specialist-request__text">
        <span className="specialist-request__date-time">{`${date} ${time}`}</span>
        <p className="specialist-request__status">
          {t(requestStatus)}
          <span className="specialist-request__status_rejected">
            {`${
              comment && requestStatus === STATUS_REJECTED.toLowerCase()
                ? comment
                : ''
            }`}
          </span>
        </p>
      </div>
      <Button
        className="specialist-request__button"
        onClick={onClick}
        type={BUTTON_TYPE.TEXT_SECONDARY}
      >
        {isSmallScreen ? null : t('open')}
        <Icon
          icon={IconType.Chevron}
          className={opened ? 'specialist-request__button-icon_opened' : ''}
        />
      </Button>
    </div>
  );
};

export default SpecialistRequest;
