import { useTranslation } from 'react-i18next';

import {
  Icon,
  IconType,
  ProgressBar,
  PROJECT_STATUS,
  SPECIFIC_LAYER,
} from '@platform-for-public-places/components-library';

import { convertBudgetToPercents } from 'src/shared/converters/budgetConverter';

import { useGetProjectCoverQuery } from 'src/features/files/api';
import { useGetDonationDetailsQuery } from 'src/features/payment/api';
import { useGetProjectDescriptionQuery } from 'src/features/project/api';
import { useOpenHookCard } from 'src/features/project/components/OverviewCard/openCardHook';
import { useGetProjectInfoAndCheck } from 'src/features/project/hooks';
import { useGetStatusQuery } from 'src/features/status/api';

import ProjectStatusTile from '../ProjectPreviewContainer/ProjectStatusTile/ProjectStatusTile';

import './ProjectPreviewHorizontal.scss';

interface IntersectedProjectProps {
  projectId: string;
  className?: string;
}

const ProjectPreviewHorizontal = ({
  projectId,
  className = '',
}: IntersectedProjectProps): JSX.Element => {
  const { openOverviewCard } = useOpenHookCard();
  const { t } = useTranslation('app', { keyPrefix: 'cards.information' });
  const { data: projectInfo } = useGetProjectInfoAndCheck(projectId);
  const { data: projectDescription } = useGetProjectDescriptionQuery(
    { projectId },
    { skip: !projectId }
  );
  const { data: projectStatus } = useGetStatusQuery(
    { projectId },
    { skip: !projectId }
  );
  const { data: donationInfo } = useGetDonationDetailsQuery(
    { projectId },
    { skip: !projectId }
  );

  const { data: photosCover } = useGetProjectCoverQuery({ projectId });

  return (
    <button
      onClick={() => {
        openOverviewCard(projectId);
      }}
      className={`project-preview-horizontal ${className}`}
    >
      <ProjectStatusTile
        status={
          projectStatus?.data.statusInfo.status?.value ?? PROJECT_STATUS.FRAMED
        }
        layer={projectInfo?.data.projectInfo.layer ?? SPECIFIC_LAYER.DEFAULT}
        className="project-preview-horizontal__status-tile"
      />
      <img
        src={photosCover?.data.cover?.url ?? '/staticImages/mock.svg'}
        alt={t('cover')}
        className="project-preview-horizontal__cover"
      />
      <div className="project-preview-horizontal__content">
        <h5 className="project-preview-horizontal__name">
          {projectDescription?.data.description.name}
        </h5>
        <div className="project-preview-horizontal__status">
          <span>
            {t('budget.collected', {
              collected: donationInfo?.data.paymentDetails.donationTotal ?? 0,
            })}
          </span>
          {donationInfo?.data.paymentDetails.budget ? (
            <span>
              {t('budget.outOf', {
                outOf: donationInfo.data.paymentDetails.budget,
              })}
            </span>
          ) : null}
        </div>
        <ProgressBar
          percents={convertBudgetToPercents(
            donationInfo?.data.paymentDetails.donationTotal || 0,
            donationInfo?.data.paymentDetails.budget || 0
          )}
          className="project-preview-horizontal__progress"
        />
        <button
          className="project-preview-horizontal__button"
          onClick={() => openOverviewCard(projectId)}
        >
          <span>{t('projectCard')}</span>
          <Icon icon={IconType.Arrow} />
        </button>
      </div>
    </button>
  );
};

export default ProjectPreviewHorizontal;
