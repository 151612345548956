import {
  Icon,
  PROJECT_STATUS,
  SPECIFIC_LAYER,
} from '@platform-for-public-places/components-library';

import { ICONS } from 'src/features/project/models';

import './ProjectStatusTile.scss';

interface ProjectStatusTileProps {
  className?: string;
  statusName?: string;
  status: PROJECT_STATUS;
  layer: SPECIFIC_LAYER;
}

const ProjectStatusTile = ({
  className = '',
  statusName,
  status,
  layer,
}: ProjectStatusTileProps): JSX.Element => {
  const applyType = (s: string) => (layer ? `${s} ${s}--${layer}` : s);

  return (
    <div className={`${applyType('project-status-tile')} ${className}`}>
      <Icon icon={ICONS[status]} className="project-status-tile__type-marker" />
      <p className="project-status-tile__status-name">{statusName}</p>
    </div>
  );
};

export default ProjectStatusTile;
