import { useEffect, useMemo, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import { isDeepEquals } from 'src/shared/functions/equals';
import { participantsWidgetsMap } from 'src/shared/widgets/participants';

import {
  useLazyGetAllParticipantsQuery,
  useUpdateConfirmedParticipantsMutation,
} from 'src/features/project/api';
import {
  PROJ_CREATE,
  setAllParticipants,
} from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';
import { EventParticipant } from 'src/features/user/models';

import { EditingOutletProps } from 'src/pages/layouts/EditLayout/models';
import { PARTICIPANTS_FIRST_PAGE_NUMBER } from 'src/pages/project/constants';
import { useIsArchived } from 'src/pages/project/hooks';

const STEP_NAME = 'participants';

const PAGE_SIZE_TEAM = 10;

const EditingParticipants = () => {
  const [isParticipantsLoading, setIsParticipantsLoading] = useState(false);
  const [isParticipantsError, setIsParticipantsError] = useState(false);
  const [hasParticipantsNextPage, setHasParticipantsNextPage] = useState(true);
  const [participantsPage, setParticipantsPage] = useState(
    PARTICIPANTS_FIRST_PAGE_NUMBER
  );
  const [participants, setParticipants] = useState<EventParticipant[]>([]);

  const dispatch = useDispatch();
  const archived = useIsArchived();

  const { renderFooter }: EditingOutletProps = useOutletContext();

  const projectId = useSelector((s: State) => s[PROJ_CREATE].projectId);
  const configSteps = useSelector(
    (s: State) => s[PROJ_CREATE].configEditingSteps
  );
  const participantsStep = configSteps.find((step) => step.name === STEP_NAME);
  const ParticipantsWidgetComponent = participantsWidgetsMap.get(
    participantsStep?.type ?? ''
  );

  const allParticipants = useSelector(
    (s: State) => s[PROJ_CREATE].allParticipants
  );

  const [getAllParticipants] = useLazyGetAllParticipantsQuery();

  const [updateConfirmedParticipants] =
    useUpdateConfirmedParticipantsMutation();

  const valid = !!participants;

  const modified = useMemo(
    () => isDeepEquals(participants, allParticipants),
    [participants, allParticipants]
  );

  useEffect(() => {
    if (!hasParticipantsNextPage) {
      dispatch(setAllParticipants(participants));
    }
  }, [dispatch, hasParticipantsNextPage, participants]);

  const [allParticipantsTrigger] = useInfiniteScroll({
    loading: isParticipantsLoading,
    hasNextPage: hasParticipantsNextPage,
    onLoadMore: () => {
      setIsParticipantsLoading(true);
      getAllParticipants({
        projectId: projectId as string,
        page: participantsPage,
        pageSize: PAGE_SIZE_TEAM,
        archived,
      })
        .unwrap()
        .then(({ data }) => {
          if (data) {
            setParticipants((prev) => [...prev, ...data.eventParticipants]);
            setParticipantsPage(participantsPage + 1);
            setHasParticipantsNextPage(
              data.eventParticipants.length === PAGE_SIZE_TEAM
            );
          }
        })
        .catch(() => setIsParticipantsError(true))
        .finally(() => setIsParticipantsLoading(false));
    },
    disabled: isParticipantsError,
  });

  const onCancel = () => {
    dispatch(setAllParticipants(participants));
  };

  const onSave = () => {
    updateConfirmedParticipants({
      participants: allParticipants,
      projectId: projectId as string,
    }).then(() => {
      setIsParticipantsLoading(false);
      setIsParticipantsError(false);
      setHasParticipantsNextPage(true);
      setParticipantsPage(PARTICIPANTS_FIRST_PAGE_NUMBER);
      setParticipants([]);
    });
  };

  return (
    <>
      {participantsStep
        ? ParticipantsWidgetComponent?.({
            configStep: participantsStep,
            participantsList: allParticipantsTrigger,
          })
        : null}
      {renderFooter?.({ onCancel, onSave, valid, modified })}
    </>
  );
};

export default EditingParticipants;
