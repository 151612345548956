import {
  MobileFooterProps,
  USER_ROLE,
} from '@platform-for-public-places/components-library';

import { paths, routes } from 'src/shared/routes';

import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import {
  setCurrentProject,
  setCurrentProjectInfo,
} from 'src/features/project/slices/projectsLayerSlice';
import { store } from 'src/features/store/store';

import history from './history';

export const PROFILE_SIDE_MENU_TABS = [
  {
    path: routes.profile.info,
    title: 'profile.info.tabName',
    roles: [USER_ROLE.ADMIN, USER_ROLE.USER],
  },
  {
    path: routes.profile.becomeSpecialist,
    title: 'profile.specialist.tabName',
    roles: [USER_ROLE.USER],
  },
];

export const MOBILE_FOOTER_PATHS: MobileFooterProps = {
  paths: {
    members: routes.root.members,
    projects: routes.root.projects,
    map: routes.root.map,
    profile: routes.profile.index,
    instruction: routes.root.instruction,
  },
};

export const onHeaderSignInClick = () =>
  store.dispatch(changeModal(MODALS.SIGN_IN));

export const onCreateClick = (isSuccessWhoami: boolean, mobile: boolean) => {
  if (!isSuccessWhoami) {
    mobile
      ? history.push(paths.signIn)
      : store.dispatch(changeModal(MODALS.SIGN_IN));
  } else {
    history.push(paths.algorithm);
    store.dispatch(setCurrentProject(null));
    store.dispatch(setCurrentProjectInfo(null));
  }
};

export const HEADER_PATHS = {
  frontPage: paths.frontPage,
  members: paths.members,
  notifications: paths.notifications,
  projects: paths.projects,
  map: paths.map,
  profile: paths.profile,
  instruction: paths.instruction,
  admin: paths.admin,
};

export const ARCHIVED_MARK = 'archived';

export enum PROJECT_STEP {
  STATUS = 'status',
  IDEA = 'idea',
  AREA = 'area',
  PHOTOS = 'photos',
  DOCS = 'docs',
  TEAM = 'team',
  DIARY = 'diary',
  BUDGET = 'budget',
}

export const MAX_UPLOAD_BYTES = 10485760; // 10MB
export const MAX_IDEA_LENGTH = 3000;

export const COMPRESSOR_URL_A = 'https://www.youcompress.com';
export const COMPRESSOR_URL_B = 'https://www.wecompress.com';

export const DEBOUNCE_TIMEOUT = 500;

export const EMPTY_EDITOR_STRING = '<p><br></p>';

export const DONATION = 'donation';
export const USER_ID = 'userId';
export const FILE_ID = 'fileId';
export const CATEGORY = 'category';

export const BBOX_COOKIE_KEY = 'BBOX';
export const ZOOM_COOKIE_KEY = 'ZOOM';

interface Currency {
  [name: string]: string;
}

export const currency: Currency = {
  ru: '₽',
};
