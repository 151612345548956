import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { useLazyCheckUserIsMemberOfProjectQuery } from 'src/features/project/api';

import TeamMembersList, {
  TYPE_RENDER,
} from './TeamMembersList/TeamMembersList';

import './TeamMembers.scss';

export interface TeamMembersProps {
  archived?: boolean;
  projectId: string;
  isAdmin: boolean;
  displayInitiator?: boolean;
  isInitiatorProjectOwner: boolean;
  initiatorName?: string;
  initiatorId?: string;
  onUserClick?: (userId: string) => void;
  emptyTeamLabel?: string;
  memberButtonComponent?: (memberId: string) => JSX.Element;
  memberContainerClassName?: string;
  hideTeamMembers?: boolean;
  previewMemberIdByRedirect?: string;
}

const TeamMembers = ({
  archived,
  projectId,
  isAdmin,
  displayInitiator = true,
  isInitiatorProjectOwner,
  initiatorName,
  initiatorId,
  onUserClick,
  previewMemberIdByRedirect = '',
}: TeamMembersProps) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'detailedProject' });

  const [teamMore, setTeamMore] = useState(false);
  const [isMember, setIsMember] = useState(false);

  const { isSuccess } = useWhoamiQuery();
  const [сheckForInvolvement] = useLazyCheckUserIsMemberOfProjectQuery();

  useEffect(() => {
    if (!archived) {
      сheckForInvolvement({ projectId })
        .unwrap()
        .then(({ data }) => setIsMember(data.isMember))
        .catch(() => setIsMember(false));
    }
  }, [archived, projectId, сheckForInvolvement]);

  const renderInitiator = () => {
    return (
      <button
        className="member__block member__block-initiator"
        onClick={() => initiatorId && onUserClick?.(initiatorId)}
      >
        <Trans
          i18nKey="detailedProject.initiatorMobile"
          values={{
            name: `${initiatorName || ''} ${
              isInitiatorProjectOwner ? t('you') : ''
            }\n`,
          }}
          components={{
            span: <span className="member__role" />,
          }}
        />
      </button>
    );
  };

  const openSupportForm = () => {
    if (isSmallScreen) {
      if (!isSuccess) {
        navigate(paths.profile);
      } else if (id) {
        navigate(paths.support(id));
      }
    } else if (!isSuccess) {
      dispatch(changeModal(MODALS.SIGN_IN));
    } else {
      dispatch(changeModal(MODALS.FORM_ADD_TO_PROJECT));
    }
  };

  const renderSupportButton = () => {
    return !(isInitiatorProjectOwner || isMember || archived) ? (
      <Button
        type={BUTTON_TYPE.PRIMARY}
        className={`members__support-button ${
          isSmallScreen ? 'members__support-button_mobile' : ''
        }`}
        onClick={openSupportForm}
      >
        {t('support')}
      </Button>
    ) : null;
  };
  return (
    <>
      {displayInitiator && isSmallScreen ? renderInitiator() : null}
      <Button
        type={BUTTON_TYPE.TEXT_DARK}
        onClick={() => {
          setTeamMore((prev) => !prev);
        }}
        icon
        fill
        className={`members__title members__button ${
          teamMore ? 'members__button--open' : ''
        }`}
        disabled={!isSmallScreen}
      >
        {t('members')}
        {isSmallScreen ? <Icon icon={IconType.Chevron} /> : null}
      </Button>
      <TeamMembersList
        archived={archived}
        projectId={projectId}
        isAdmin={isAdmin}
        isSmallScreen={isSmallScreen}
        isInitiatorProjectOwner={isInitiatorProjectOwner}
        hideTeamMembers={isSmallScreen ? !teamMore : false}
        onUserClick={onUserClick}
        initiatorName={initiatorName}
        initiatorId={initiatorId}
        typeRender={
          isSmallScreen
            ? TYPE_RENDER.HIDE_TEAM_MEMBERS
            : TYPE_RENDER.PREVIEW_MEMBER_WITH_BUTTON_SHOW_MORE
        }
        displayInitiator={!isSmallScreen}
        previewTeamMembers={!isSmallScreen}
        previewMemberIdByRedirect={previewMemberIdByRedirect}
      />
      {isSmallScreen && !teamMore ? null : renderSupportButton()}
    </>
  );
};

export default TeamMembers;
