import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { ru } from 'date-fns/locale';

import { paths } from 'src/shared/routes';
import { datesWidgetsMap, getDateWithTime } from 'src/shared/widgets/dates';

import {
  useCreateProjectTimelineMutation,
  useGetProjectTimelineQuery,
} from 'src/features/project/api';
import {
  PROJ_CREATE,
  setDates,
} from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import { EditingOutletProps } from 'src/pages/layouts/EditLayout/models';

const STEP_NAME = 'dates';

const timeOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
};

const EditingDates = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { renderFooter }: EditingOutletProps = useOutletContext();

  const projectId = useSelector((s: State) => s[PROJ_CREATE].projectId);
  const startDate = useSelector((s: State) => s[PROJ_CREATE].startDate);
  const endDate = useSelector((s: State) => s[PROJ_CREATE].endDate);
  const startTime = useSelector((s: State) => s[PROJ_CREATE].startTime);
  const endTime = useSelector((s: State) => s[PROJ_CREATE].endTime);

  const [valid, setValid] = useState<boolean>(false);

  const [updateDates] = useCreateProjectTimelineMutation();
  const configSteps = useSelector(
    (s: State) => s[PROJ_CREATE].configEditingSteps
  );

  const { data: projectTimeline, refetch: refetchProjectTimeline } =
    useGetProjectTimelineQuery(
      { projectId: projectId as string },
      { skip: !projectId }
    );

  const initData = () => {
    const start = projectTimeline?.data.timeline.startDate
      ? new Date(projectTimeline?.data.timeline.startDate)
      : null;
    const end = projectTimeline?.data.timeline.endDate
      ? new Date(projectTimeline?.data.timeline.endDate)
      : null;

    const startDate = start?.toString() ?? '';
    const startTime = start?.toLocaleTimeString(ru.code, timeOptions) ?? '';

    const endDate = end?.toString() ?? '';
    const endTime = end?.toLocaleTimeString(ru.code, timeOptions) ?? '';

    dispatch(setDates({ startDate, endDate, startTime, endTime }));
  };

  useEffect(initData, [dispatch, projectTimeline?.data.timeline]);

  const datesStep = configSteps.find((step) => step.name === STEP_NAME);

  const onSave = () => {
    if (valid && projectId) {
      setValid(false);
      updateDates({
        projectId,
        startDate: getDateWithTime(
          new Date(startDate),
          startTime
        ).toISOString(),
        endDate: getDateWithTime(new Date(endDate), endTime).toISOString(),
      }).then(() => refetchProjectTimeline());
    }
  };

  const onCancel = () => {
    initData();
  };

  const modified = useMemo(() => {
    if (startDate || startTime || endTime || endDate) {
      if (!projectTimeline?.data.timeline) {
        return true;
      } else {
        return (
          projectTimeline.data.timeline.startDate !==
            getDateWithTime(new Date(startDate), startTime).toISOString() ||
          projectTimeline.data.timeline.endDate !==
            getDateWithTime(new Date(endDate), endTime).toISOString()
        );
      }
    }
    return false;
  }, [endDate, endTime, projectTimeline?.data.timeline, startDate, startTime]);

  if (!datesStep) {
    navigate(paths.map);
  }

  const DatesWidget = datesWidgetsMap.get(datesStep?.type ?? '');

  return (
    <div className="editing-dates">
      {datesStep
        ? DatesWidget?.({
            stepConfig: datesStep,
            setIsValid: setValid,
          })
        : null}
      {renderFooter?.({ onCancel, onSave, valid, modified })}
    </div>
  );
};

export default EditingDates;
