import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Accordion from 'src/shared/components/Accrodion/Accordion';
import Box from 'src/shared/components/Box/Box';
import Typography from 'src/shared/components/Typography/Typography';

import { useProjectStatuses } from 'src/features/searchWithFilter/filter/lib/statusService';
import { SearchContext } from 'src/features/searchWithFilter/filter/search/SearchProvider';
import CheckBoxFilterList from 'src/features/searchWithFilter/filter/searchFilters/CheckBoxFilterList/CheckBoxFilterList';
import FilterWithHeader from 'src/features/searchWithFilter/filter/searchFilters/FilterList/FilterWithHeader';
import { FILTER } from 'src/features/searchWithFilter/filter/slices/filterSlice';
import { State } from 'src/features/store/store';

const FilterByStatus = () => {
  const { t } = useTranslation('app', { keyPrefix: 'searchFilter' });
  return (
    <FilterWithHeader label={t('byStatus')}>
      <StatusFilters />
    </FilterWithHeader>
  );
};

export default FilterByStatus;

type StatusFiltersProps = {
  className?: string;
};

export const StatusFilters = ({ className }: StatusFiltersProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'searchFilter' });

  const { allTypes } = useSelector((s: State) => s[FILTER]);

  const { getStatusByConfig } = useProjectStatuses();
  const { type, status } = useSelector((s: State) => s[FILTER]);

  const { onStatusChange } = useContext(SearchContext);
  return (
    <Box className={className} flexDirection={'column'} gap={'1em'}>
      {type?.length && allTypes ? (
        allTypes
          .filter((f) => type?.includes(f.id))
          .map((type) => {
            const statuses = getStatusByConfig(type.id);
            return statuses.length ? (
              <Accordion
                id={`${type.id}`}
                label={
                  <Typography type={'heading-h6'}>{type.label}</Typography>
                }
                key={type.id}
              >
                <CheckBoxFilterList
                  listValues={statuses}
                  color={type.color}
                  onChange={onStatusChange}
                  selectedList={status}
                />
              </Accordion>
            ) : (
              <></>
            );
          })
      ) : (
        <Typography type={'body2'}>{t('emptyStatus')}</Typography>
      )}
    </Box>
  );
};
