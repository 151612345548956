import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';
import clsx from 'clsx';

import Box from 'src/shared/components/Box/Box';

import {
  changeCadastralLayerErrorVisibility,
  changeCadastralLayerVisibility,
  CONTROL,
} from 'src/features/control/slices/controlSlice';
import { useDisablePropagationRef } from 'src/features/hooks';
import PkkDoesNotResponseInfo from 'src/features/map/components/PkkDoesNotResponseInfo/PkkDoesNotResponseInfo';
import { BACKGROUND_TYPE } from 'src/features/map/enums';
import { changeBackground, MAP } from 'src/features/map/slices/mapSlice';
import { State } from 'src/features/store/store';

import './LayerControl.scss';

//todo вынести ошибку кадастра
const LayerControl = (): JSX.Element => {
  const dispatch = useDispatch();
  const isSmallScreen = useCheckSmallScreen();
  const controlRef = useDisablePropagationRef();

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  const background = useSelector((s: State) => s[MAP].background);

  const { showCadastralLayer, showCadastralLayerError } = useSelector(
    (s: State) => s[CONTROL]
  );

  const pkkAccessability =
    sessionStorage.getItem('pkkAccessability') === 'true';

  useEffect(() => {
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        dispatch(changeCadastralLayerErrorVisibility(false));
      }, 5000)
    );
    // With correct useEffect dependencies for call browser goes to the infinite loop
    // eslint-disable-next-line
  }, [showCadastralLayerError]);

  const toggleCadastral = () => {
    if (!showCadastralLayer) {
      dispatch(changeCadastralLayerErrorVisibility(true));
    }
    dispatch(changeCadastralLayerVisibility(!showCadastralLayer));
  };

  const toggleBackground = () => {
    dispatch(
      changeBackground(
        background === BACKGROUND_TYPE.SCHEME
          ? BACKGROUND_TYPE.SATELLITE
          : BACKGROUND_TYPE.SCHEME
      )
    );
  };

  return (
    <div className="layer-control" ref={controlRef}>
      <button className="layer-control__button" onClick={toggleBackground}>
        <Icon
          className={clsx('layer-control__icon', {
            'layer-control__icon--active-map':
              background === BACKGROUND_TYPE.SATELLITE,
          })}
          icon={IconType.MapDetailed}
        />
      </button>
      <Box alignItems={'start'} gap={'1em'}>
        <PkkDoesNotResponseInfo
          className={`layer-control__do-not-response-card${
            !isSmallScreen && !pkkAccessability && showCadastralLayerError
              ? ''
              : '--invisible'
          }`}
        />
        <button className="layer-control__button" onClick={toggleCadastral}>
          <Icon
            className={clsx('layer-control__icon', {
              'layer-control__icon--active-polygon': showCadastralLayer,
            })}
            icon={IconType.Polygon}
          />
        </button>
      </Box>
    </div>
  );
};

export default LayerControl;
