import { ActorBaseResponse } from '@platform-for-public-places/components-library';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import {
  CreatePaymentRequest,
  CreatePaymentResponse,
  CreateProjectBudgetRequest,
  CreateProjectBudgetResponse,
  GetDonationDetailsRequest,
  GetDonationDetailsResponse,
  GetPaymentsStatisticRequest,
  GetPaymentsStatisticResponse,
  GetProjectEstimationRequest,
  GetProjectEstimationResponse,
  GetRecurrentUserPaymentsRequest,
  GetRecurrentUserPaymentsResponse,
  GetTotalAmountUserPaymentsRequest,
  GetTotalAmountUserPaymentsResponse,
  GetUserPaymentRequest,
  GetUserPaymentResponse,
  NewPaymentConfirmationRedirect,
  PaymentConfirmationRedirect,
  RequestAccessToRecurrentPaymentsRequest,
  StopSupportingRecurrentPaymentRequest,
} from '../models';

export const PAYMENT_API = 'PAYMENT_API';

export const paymentApi = createApi({
  reducerPath: PAYMENT_API,
  baseQuery: retry(
    fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URLS }),
    { maxRetries: 0 }
  ),
  endpoints: (builder) => ({
    getPaymentsStatistic: builder.query<
      ActorBaseResponse<GetPaymentsStatisticResponse>,
      GetPaymentsStatisticRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'payments-statistic/get-statistic-by-years',
          arguments: request,
        },
        url: '',
        params: { chain: 'payments-statistic__get-statistic-by-years' },
      }),
    }),
    getDonationDetails: builder.query<
      ActorBaseResponse<GetDonationDetailsResponse>,
      GetDonationDetailsRequest
    >({
      query: (request) => {
        const { archived, ...req } = request;
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-projects/get-project-payment-details'
              : 'project-payment/get-project-payment-details',
            arguments: req,
          },
          url: '',
          params: {
            chain: archived
              ? 'archived-projects/get-project-payment-details'
              : 'project-payment/get-project-payment-details',
          },
        };
      },
    }),
    getProjectEstimation: builder.query<
      ActorBaseResponse<GetProjectEstimationResponse>,
      GetProjectEstimationRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-payment/get-estimation-by-project-id',
          arguments: request,
        },
        url: '',
        params: { chain: 'project-payment__get-estimation-by-project-id' },
      }),
    }),
    createProjectBudget: builder.query<
      ActorBaseResponse<CreateProjectBudgetResponse>,
      CreateProjectBudgetRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-payment/upsert-estimation',
          arguments: request,
        },
        url: '',
        params: { chain: 'project-payment__upsert-estimation' },
      }),
    }),
    createPayment: builder.query<
      ActorBaseResponse<CreatePaymentResponse<PaymentConfirmationRedirect>>,
      CreatePaymentRequest<NewPaymentConfirmationRedirect>
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-payment/create-payment',
          arguments: { request },
        },
        url: '',
        params: { chain: 'project-payment__create-payment' },
      }),
    }),
    getUserPayments: builder.query<
      ActorBaseResponse<GetUserPaymentResponse>,
      GetUserPaymentRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'user-payments/get-user-payments',
          arguments: request,
        },
        url: '',
        params: { chain: 'user-payments__get-user-payments' },
      }),
    }),
    getRecurrentUserPayments: builder.query<
      ActorBaseResponse<GetRecurrentUserPaymentsResponse>,
      GetRecurrentUserPaymentsRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: request.token
            ? 'payments-recurrent/get-recurrent-payments-by-token'
            : 'payments/get-user-recurrent-payments',
          arguments: request,
        },
        url: '',
        params: {
          chain: request.token
            ? 'payments-recurrent__get-recurrent-payments-by-token'
            : 'payments__get-user-recurrent-payments',
        },
      }),
    }),
    getTotalAmountUserPayments: builder.query<
      ActorBaseResponse<GetTotalAmountUserPaymentsResponse>,
      GetTotalAmountUserPaymentsRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'user-payments/get-total-amount-user-payments',
          arguments: request,
        },
        url: '',
        params: { chain: 'user-payments/get-total-amount-user-payments' },
      }),
    }),
    stopSupportingRecurrentPayment: builder.mutation<
      ActorBaseResponse<void>,
      StopSupportingRecurrentPaymentRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: request.token
            ? 'payments-recurrent/delete-recurrent-payment-by-token'
            : 'payments/delete-recurrent-payment',
          arguments: request,
        },
        url: '',
        params: {
          chain: request.token
            ? 'payments-recurrent__delete-recurrent-payment-by-token'
            : 'payments__delete-recurrent-payment',
        },
      }),
    }),
    requestAccessToRecurrentPayments: builder.query<
      ActorBaseResponse<void>,
      RequestAccessToRecurrentPaymentsRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId:
            'payments-recurrent/request-access-to-recurrent-payments',
          arguments: request,
        },
        url: '',
        params: {
          chain: 'payments-recurrent__request-access-to-recurrent-payments',
        },
      }),
    }),
  }),
});

export const {
  useLazyGetPaymentsStatisticQuery,
  useGetProjectEstimationQuery,
  useLazyCreateProjectBudgetQuery,
  useGetDonationDetailsQuery,
  useGetTotalAmountUserPaymentsQuery,
  useLazyGetDonationDetailsQuery,
  useLazyCreatePaymentQuery,
  useLazyGetUserPaymentsQuery,
  useLazyGetRecurrentUserPaymentsQuery,
  useStopSupportingRecurrentPaymentMutation,
  useLazyRequestAccessToRecurrentPaymentsQuery,
} = paymentApi;

export const paymentMiddleware = paymentApi.middleware;

export default paymentApi.reducer;
