import {
  useDeleteDiaryEntryMutation as realUseDeleteDiaryEntryMutation,
  useGetDiaryEntriesQuery as realUseGetDiaryEntriesQuery,
  useLazyGetDiaryEntriesQuery as realUseLazyGetDiaryEntriesQuery,
  useUpsertDiaryEntryMutation as realUseUpsertDiaryEntryMutation,
} from './diaryApi';
import {
  useDeleteDiaryEntryMutation as mockUseDeleteDiaryEntryMutation,
  useGetDiaryEntriesQuery as mockUseGetDiaryEntriesQuery,
  useLazyGetDiaryEntriesQuery as mockUseLazyGetDiaryEntriesQuery,
  useUpsertDiaryEntryMutation as mockUseUpsertDiaryEntryMutation,
} from './mockDiaryApi';

export const useLazyGetDiaryEntriesQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetDiaryEntriesQuery
    : realUseLazyGetDiaryEntriesQuery;

export const useUpsertDiaryEntryMutation =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseUpsertDiaryEntryMutation
    : realUseUpsertDiaryEntryMutation;

export const useGetDiaryEntriesQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetDiaryEntriesQuery
    : realUseGetDiaryEntriesQuery;

export const useDeleteDiaryEntryMutation =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseDeleteDiaryEntryMutation
    : realUseDeleteDiaryEntryMutation;
