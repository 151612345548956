import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  MinIoUrl,
  NOTIFICATION_PAYLOAD,
  NOTIFICATION_TYPE,
  NotificationPopup,
  PROJECT_STATUS,
  timestampToString,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import Digest from 'src/shared/components/Digest/Digest';
import { paths } from 'src/shared/routes';

import { USER_ROLE } from 'src/features/auth/models';
import { useLazyGetDiaryEntriesQuery } from 'src/features/diary/api';
import DiaryEntry from 'src/features/diary/components/DiaryEntry/DiaryEntry';
import { GetDiaryEntriesResponse } from 'src/features/diary/models';
import { resetDiary as resetDiaryAction } from 'src/features/diary/slices/diarySlice';
import { useCheckUser, useTranslationStatus } from 'src/features/hooks';
import StageHeader from 'src/features/layout/components/StageHeader/StageHeader';
import PictureModal from 'src/features/modal/components/PictureModal/PictureModal';
import { MODALS } from 'src/features/modal/models';
import { changeModal, MODAL } from 'src/features/modal/slices/modalSlice';
import {
  changeOpenedIndex,
  changePhotos,
} from 'src/features/modal/slices/photosModalSlice';
import {
  NOTIFICATION,
  setVisible,
} from 'src/features/notification/slices/notificationSlice';
import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import { PROJ_LAYER } from 'src/features/project/slices/projectsLayerSlice';
import { State } from 'src/features/store/store';

import './Diary.scss';

const PAGE_SIZE = 20;

const Diary = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSmallScreen = useCheckSmallScreen();

  const [page, setPage] = useState<number>(0);
  const [error] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [currentEntryIndex, setEntryIndex] = useState<number>(0);
  const [notification, setNotification] = useState<NOTIFICATION_PAYLOAD>({
    message: '',
    type: NOTIFICATION_TYPE.NOTIFICATION,
  });
  const [digestVisible, setDigestVisible] = useState<boolean>(false);
  const [posts, setPosts] = useState<GetDiaryEntriesResponse>({
    documents: [],
  });

  const openedModal = useSelector((s: State) => s[MODAL].openedModal);
  const isVisible = useSelector((s: State) => s[NOTIFICATION].isVisible);
  const projectInfo = useSelector(
    (s: State) => s[PROJ_LAYER].currentProjectInfo
  );

  const projectType = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );

  const { t } = useTranslation('app', {
    keyPrefix: `editing.${projectType}.diary`,
  });

  const [getDiaryEntries] = useLazyGetDiaryEntriesQuery();

  const translateStatus = useTranslationStatus(projectType);
  const admin = useCheckUser([{ role: USER_ROLE.ADMIN }]);
  const owner = useCheckUser([
    { role: USER_ROLE.USER, userId: projectInfo?.projectInfo.ownerId },
  ]);

  const onPhotoClick = (photos: MinIoUrl[], openIndex: number) => {
    dispatch(changeModal(MODALS.PICTURE));
    dispatch(changePhotos(photos));
    dispatch(changeOpenedIndex(openIndex));
  };

  const onDigestClick = (index: number) => {
    setDigestVisible(false);
    setEntryIndex(index);
  };

  const onDeleteClick = (entryId: string, isResponseSuccess: boolean) => {
    if (isResponseSuccess) {
      setPosts({
        documents: posts.documents.filter((entry) => entry.id !== entryId),
      });
    } else {
      revealNotification();
    }
  };

  const revealNotification = () => {
    setNotification({
      message: t('error.deleteError'),
      type: NOTIFICATION_TYPE.ERROR,
    });
    dispatch(setVisible(true));
  };

  const hideNotification = () => dispatch(setVisible(false));

  const resetDiary = () => {
    setPage(0);
    setPosts({ documents: [] });
    setHasNextPage(true);
  };

  const entries = (): JSX.Element[] | null =>
    projectInfo
      ? posts.documents.map((e) => (
          <DiaryEntry
            projectId={projectInfo.projectInfo.projectID}
            key={e.id}
            entry={e}
            editable={admin || owner}
            deletable={!isSmallScreen && admin}
            onSubmitClick={resetDiary}
            onPhotoClick={onPhotoClick}
            onDeleteClick={onDeleteClick}
          />
        ))
      : null;

  const entriesDigest = (): JSX.Element[] =>
    posts.documents.map((e, i) => (
      <Digest
        key={e.id}
        index={i}
        title={
          e.autoCreation && projectType
            ? translateStatus(e.title as PROJECT_STATUS)
            : e.title
        }
        findingClassName="diary-entry"
        current={currentEntryIndex}
        onClick={onDigestClick}
      >
        <span className="digest__date">
          {timestampToString(e.created, true)}
        </span>
      </Digest>
    ));

  const visibleClass = (s: TemplateStringsArray) => {
    return digestVisible ? `${s[0]} ${s[0]}--visible` : s[0];
  };

  const loadMore = () => {
    if (id && !loading) {
      setLoading(true);
      getDiaryEntries({
        page: page + 1,
        autoCreation: false,
        pageSize: PAGE_SIZE,
        projectId: id as string,
      })
        .then((resp) => {
          if (resp.isSuccess) {
            setPosts((previous) => ({
              documents: [
                ...previous.documents,
                ...(resp.data?.data.documents || []),
              ],
            }));
          }
          if (
            !resp.data ||
            (resp.data && resp.data.data.documents.length < PAGE_SIZE)
          ) {
            setHasNextPage(false);
          }
        })
        .catch(() => setHasNextPage(false))
        .finally(() => {
          setPage(page + 1);
          setLoading(false);
        });
    }
  };

  const [getDiaries] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: error,
  });

  const [getDigests] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: error,
  });

  const renderDesktop = () => (
    <div className="diary">
      <div className="diary__content">
        <StageHeader
          className="diary__title"
          header={t('title')}
          subheader={t('description')}
        />

        {projectInfo ? (
          <DiaryEntry
            projectId={projectInfo.projectInfo.projectID}
            creatable={true}
            editable={true}
            deletable={false}
            resetDiary={resetDiary}
          />
        ) : null}

        <div className="diary__entries">
          {entries()}
          {hasNextPage ? (
            <div className="digest-list__loading-trigger" ref={getDiaries} />
          ) : null}
        </div>
      </div>

      <aside className="diary__side">
        <h3 className="side__title">{t('myEntries')}</h3>
        <div className="side__entries">
          {entriesDigest()}
          {posts.documents.length && hasNextPage ? (
            <div className="digest-list__loading-trigger" ref={getDigests} />
          ) : null}
        </div>
      </aside>

      {openedModal === MODALS.PICTURE ? <PictureModal /> : null}

      <NotificationPopup
        type={notification.type}
        isVisible={isVisible}
        onTimeoutFinished={hideNotification}
      >
        {notification.message}
      </NotificationPopup>
    </div>
  );

  const onMobileCreateClick = () => {
    dispatch(resetDiaryAction());
    navigate(paths.editProjectByIdDiaryCreation(id as string));
  };

  const renderMobile = () => (
    <div className="diary">
      <p className="diary__description">{t('description')}</p>
      <div className={visibleClass`diary__digest-dropdown`}>
        <Button
          type={BUTTON_TYPE.TEXT_SECONDARY}
          className="diary__digest-controls"
          onClick={() => setDigestVisible((p) => !p)}
        >
          <h4>{t('myEntries')}</h4>
          <Icon icon={IconType.Chevron} />
        </Button>
        {digestVisible ? (
          <div className="diary__digest-list">
            {entriesDigest()}
            {hasNextPage ? (
              <div className="digest-list__loading-trigger" ref={getDigests} />
            ) : null}
          </div>
        ) : null}
      </div>
      <div className={visibleClass`diary__content`}>
        <div className="diary__entries">
          {entries()}
          {hasNextPage ? (
            <div className="digest-list__loading-trigger" ref={getDiaries} />
          ) : null}
        </div>
      </div>
      <Button
        className="diary__create-button"
        type={BUTTON_TYPE.PRIMARY}
        onClick={onMobileCreateClick}
        icon
      >
        <Icon icon={IconType.Feather} />
        <span>{t('addEntry')}</span>
      </Button>
      {openedModal === MODALS.PICTURE ? <PictureModal /> : null}
    </div>
  );

  return isSmallScreen ? renderMobile() : renderDesktop();
};

export default Diary;
