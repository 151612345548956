import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';

import { FileLinkDoc } from '../../models';

import { downloadURI, isDownloadFile } from '../../download';

import './FilePreview.scss';

interface FilePreviewProps {
  onRemoveClick: () => void;
  document: FileLinkDoc;
  className?: string;
}

const FilePreview = ({
  onRemoveClick,
  document,
  className,
}: FilePreviewProps) => {
  const renderContent = () => {
    return (
      <>
        <Icon icon={IconType.Docs} className="file-preview__docs-icon" />
        <span className="file-preview__name">
          {document.link ?? document.name}
        </span>
      </>
    );
  };

  return (
    <div className={`file-preview__container ${className ?? ''}`}>
      {isDownloadFile(document.name ?? '') ? (
        <button
          className="file-preview__doc"
          onClick={() =>
            downloadURI(document.link ?? document.url, document.name)
          }
        >
          {renderContent()}
        </button>
      ) : (
        <a
          rel="noreferrer"
          href={document.link ?? document.url}
          target="_blank"
          className="file-preview__doc"
          download
        >
          {renderContent()}
        </a>
      )}
      <Button
        className="file-preview__remove-button"
        onClick={onRemoveClick}
        type={BUTTON_TYPE.TEXT}
      >
        <Icon icon={IconType.Cross} />
      </Button>
    </div>
  );
};

export default FilePreview;
