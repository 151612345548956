import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, IconType } from '@platform-for-public-places/components-library';

import { MAX_UPLOAD_BYTES } from 'src/app/constants';
import CatalogAvatar from 'src/shared/components/CatalogUsers/components/CatalogAvatar/CatalogAvatar';

import './PhotoUploader.scss';

interface PhotoUploaderProps {
  image?: string;
  className?: string;
  maxBytes?: number;
  error?: string;
  onFile: (files: File[]) => void;
  onError?: (error: string) => void;
  setLocalPhoto?: () => void;
}

const PhotoUploader = ({
  image,
  className,
  maxBytes = MAX_UPLOAD_BYTES,
  error = '',
  onFile,
  onError,
  setLocalPhoto,
}: PhotoUploaderProps) => {
  const { t } = useTranslation();
  const fileInput = useRef<HTMLInputElement>(null);
  const [img, setImg] = useState<string | undefined>(image);

  useEffect(() => {
    setImg(image);
  }, [image]);

  const onFileSubmitted = (files: FileList | null) => {
    if (!files) return [];
    if (maxBytes) {
      const _files: File[] = [];
      const file = files.item(0);
      if (file && file.size <= maxBytes) {
        _files.push(file);
      } else {
        onError?.(t('photoUploader.errors.largeFile'));
      }
      onFile(_files);
    } else {
      onFile(Array.from(files));
    }
    fileInput.current && (fileInput.current.value = '');
  };

  const getText = (error: string) => {
    return (
      <div className="photo-uploader__text-container">
        <h1 className={`photo-uploader__title${error !== '' ? '_error' : ''}`}>
          {error ? t('photoUploader.errors.title') : t('photoUploader.title')}
        </h1>
        <p className={`photo-uploader__text${error !== '' ? '_error' : ''}`}>
          {error ? error : t('photoUploader.text')}
        </p>
      </div>
    );
  };

  return (
    <div className={`photo-uploader ${className}`}>
      <input
        ref={fileInput}
        className="photo-uploader__input"
        type="file"
        id="photo-input"
        accept=".jpg, .png"
        onChange={(e) => onFileSubmitted(e.target.files)}
      />
      <label htmlFor="photo-input" className={'photo-uploader-label'}>
        {img ? (
          <div className="photo-uploader__image-wrapper">
            <CatalogAvatar
              rerender
              avatar={img}
              className="photo-uploader__image"
              alt="specialist"
              url={false}
            />
            <Icon
              icon={IconType.Image}
              className="photo-uploader__image-icon"
            />
          </div>
        ) : (
          <Icon icon={IconType.PhotoUploader} />
        )}
        <button
          className={
            img
              ? 'photo-uploader__image-cross'
              : 'photo-uploader__image-cross--hiden'
          }
          onClick={() => {
            setImg(undefined);
            setLocalPhoto && setLocalPhoto();
          }}
          type="reset"
        >
          <Icon icon={IconType.CrossRound} />
        </button>
        {getText(error)}
      </label>
    </div>
  );
};

export default PhotoUploader;
