import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import Spinner from 'src/shared/components/Spinner/Spinner';

import { REQUEST_STATUS } from 'src/features/request/enums';
import {
  FILTER_DATA,
  setTeam,
} from 'src/features/searchWithFilter/filter/slices/filterSliceData';
import { State } from 'src/features/store/store';
import { ProjectSpecialist } from 'src/features/user/models';

type StatusMap = { [value: string]: JSX.Element };

const statusMap: StatusMap = {
  ['ACTIVE']: <Spinner className={'team-members-status__spinner'} />,
  ['REJECTED']: (
    <Icon className="team-user-status__error" icon={IconType.Cross} />
  ),
  ['EXECUTED']: <Icon icon={IconType.StrokeCheck} />,
};

enum StatusMobileColor {
  REJECTED = 'team-user-status__rejected',
  ACTIVE = 'team-user-status__active',
  EXECUTED = 'team-user-status__execute',
}

interface StatusTeamUsersProps {
  user: ProjectSpecialist;
}

const StatusTeamUsers = ({ user }: StatusTeamUsersProps) => {
  const dispatch = useDispatch();
  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'creation.team' });

  const team = useSelector((s: State) => s[FILTER_DATA].team);

  enum acceptStatusText {
    REJECTED = 'status.rejected',
    ACTIVE = 'status.active',
    EXECUTED = 'status.executed',
  }

  const renderStatusTeamUser = (status: string, showIcon: boolean) => {
    return (
      <div className="team-user__accept-status">
        {showIcon ? statusMap[status] : null}
        <p
          className={`accept-status__text ${
            StatusMobileColor[
              user.requestStatus as keyof typeof StatusMobileColor
            ]
          }`}
        >
          {t(
            acceptStatusText[
              status as keyof typeof acceptStatusText
            ] as unknown as string
          )}
          {status === REQUEST_STATUS.REJECTED && user.message
            ? `«${user.message}»`
            : null}
        </p>
      </div>
    );
  };

  const renderType = (type: string) => {
    return type.length ? t(type.toLowerCase()) : '';
  };

  const deleteUser = () => {
    const users = team.filter((value) => {
      if (value.id !== user.id) {
        return value;
      }
    });
    dispatch(setTeam(users as ProjectSpecialist[]));
  };

  return isSmallScreen ? (
    <div className="team-user">
      <div
        className={`team-user__button-block ${
          StatusMobileColor[
            user.requestStatus as keyof typeof StatusMobileColor
          ]
        }`}
      >
        <div className="team__label-and-name">
          <span className="team__initiator-label">
            {renderType(user?.roles ? user?.roles[0] : '')}
          </span>
          <span className="team__initiator-name">{user.name}</span>
        </div>
        <Button
          type={BUTTON_TYPE.TEXT_SECONDARY}
          icon
          onClick={() => {
            deleteUser();
          }}
        >
          <Icon icon={IconType.Bin} />
        </Button>
      </div>
      {renderStatusTeamUser(user.requestStatus || REQUEST_STATUS.ACTIVE, false)}
    </div>
  ) : (
    <div className="team-user">
      <div className="team__label-and-name">
        <span className="team__initiator-label">
          {renderType(user?.roles ? user?.roles[0] : '')}
        </span>
        <span className="team__initiator-name">{user.name}</span>
      </div>
      <div className="team-user__button-block">
        <Button
          type={BUTTON_TYPE.TEXT_SECONDARY}
          icon
          onClick={() => {
            deleteUser();
          }}
        >
          <Icon icon={IconType.Bin} />
        </Button>
        {renderStatusTeamUser(
          user.requestStatus || REQUEST_STATUS.ACTIVE,
          true
        )}
      </div>
    </div>
  );
};
export default StatusTeamUsers;
