import {
  useDeleteCommentMutation as realUseDeleteCommentMutation,
  useLazyCreateCommentQuery as realUseLazyCreateCommentQuery,
  useLazyGetCommentsQuery as realUseLazyGetCommentsQuery,
  useUpdateCommentMutation as realUseUpdateCommentMutation,
} from './commentsApi';
import {
  useDeleteCommentMutation as mockUseDeleteCommentMutation,
  useLazyCreateCommentQuery as mockUseLazyCreateCommentQuery,
  useLazyGetCommentsQuery as mockUseLazyGetCommentsQuery,
  useUpdateCommentMutation as mockUseUpdateCommentMutation,
} from './mockCommentsApi';

export const useLazyCreateCommentQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyCreateCommentQuery
    : realUseLazyCreateCommentQuery;

export const useLazyGetCommentsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetCommentsQuery
    : realUseLazyGetCommentsQuery;

export const useUpdateCommentMutation =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseUpdateCommentMutation
    : realUseUpdateCommentMutation;

export const useDeleteCommentMutation =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseDeleteCommentMutation
    : realUseDeleteCommentMutation;
