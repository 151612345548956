import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import AutopaymentsManagementForm from 'src/features/autopayments/components/AutopaymentsManagementForm/AutopaymentsManagementForm';
import AuthModal from 'src/features/modal/components/AuthModal/AuthModal';
import { changeModal } from 'src/features/modal/slices/modalSlice';

import './AutopaymentsManagementModal.scss';

const AutopaymentsManagementModal = () => {
  const { t } = useTranslation('app', {
    keyPrefix: 'autopaymentsManagementModal',
  });

  const dispatch = useDispatch();

  const onClickBack = () => {
    dispatch(changeModal(null));
  };

  return (
    <AuthModal
      className="autopayments-management-modal"
      header={t('header')}
      onClickBack={onClickBack}
    >
      <AutopaymentsManagementForm />
    </AuthModal>
  );
};

export default AutopaymentsManagementModal;
