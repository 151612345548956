import { ActorBaseResponse } from '@platform-for-public-places/components-library';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import {
  DeleteDiaryEntryRequest,
  GetDiaryEntriesRequest,
  GetDiaryEntriesResponse,
  UpsertDiaryEntryRequest,
  UpsertDiaryEntryResponse,
} from '../models';

export const DIARY_API = 'DIARY_API';

export const diaryApi = createApi({
  reducerPath: DIARY_API,
  baseQuery: retry(
    fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URLS }),
    { maxRetries: 0 }
  ),
  endpoints: (builder) => ({
    getDiaryEntries: builder.query<
      ActorBaseResponse<GetDiaryEntriesResponse>,
      GetDiaryEntriesRequest
    >({
      query: (request) => {
        const { archived, ...req } = request;
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-projects/get-diary-entries'
              : 'project-diary/get-diary-entries',
            arguments: req,
          },
        };
      },
    }),
    upsertDiaryEntry: builder.mutation<
      ActorBaseResponse<UpsertDiaryEntryResponse>,
      UpsertDiaryEntryRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-diary/upsert-project-diary',
          arguments: request,
        },
      }),
    }),
    deleteDiaryEntry: builder.mutation<
      ActorBaseResponse<object>,
      DeleteDiaryEntryRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-diary/delete-project-diary-entry',
          arguments: request,
        },
      }),
    }),
  }),
});

export const {
  useGetDiaryEntriesQuery,
  useLazyGetDiaryEntriesQuery,
  useUpsertDiaryEntryMutation,
  useDeleteDiaryEntryMutation,
} = diaryApi;

export const diaryMiddleware = diaryApi.middleware;

export default diaryApi.reducer;
