import { useState } from 'react';

export const useEmailInput = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const onEmailChange = (value: string) => {
    if (emailError) {
      setEmailError(false);
    }
    setEmail(value);
  };

  return { email, emailError, onEmailChange };
};
