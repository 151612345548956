import { Navigate } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import ConfirmMessage from 'src/features/auth/components/Confirm/Confirm';

import './Confirm.scss';

const Confirm = () => {
  const isSmallScreen = useCheckSmallScreen();

  return isSmallScreen ? (
    <div className="confirm-page">
      <ConfirmMessage />
    </div>
  ) : (
    <Navigate to={paths.map} replace />
  );
};

export default Confirm;
