import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { useLazyLogoutQuery, useWhoamiQuery } from 'src/features/auth/api';
import { USER_ROLE } from 'src/features/auth/models';

import './SideMenu.scss';

const TABS = {
  profile: {
    path: paths.profileInfo,
    title: 'profile.info.tabName',
    roles: [USER_ROLE.ADMIN, USER_ROLE.USER],
  },
  projects: {
    path: paths.profileProjects,
    title: 'profile.projects.tabName',
    roles: [USER_ROLE.ADMIN, USER_ROLE.USER],
  },
  participations: {
    path: paths.profileParticipations,
    title: 'profile.participations.tabName',
    roles: [USER_ROLE.ADMIN, USER_ROLE.USER],
  },
  payments: {
    path: paths.profilePayments,
    title: 'profile.payments.tabName',
    roles: [USER_ROLE.ADMIN, USER_ROLE.USER],
  },
  specialist: {
    path: paths.becomeSpecialist,
    title: 'profile.specialist.tabName',
    roles: [USER_ROLE.USER],
  },
};

const SideMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const user = useWhoamiQuery();
  const [requestLogout] = useLazyLogoutQuery();

  const logout = () => {
    requestLogout().then(() => {
      navigate(paths.map);
      document.location.reload();
    });
  };

  const renderSteps = () => {
    return Object.entries(TABS).map(([key, value]) => {
      return value.roles.includes(user.data?.data?.role as USER_ROLE) ? (
        <NavLink
          to={`${value.path}`}
          className="profile-layout-side-menu__step"
          key={key}
        >
          <>
            {t(value.title)}
            <Icon
              className="profile-layout-side-menu__step-icon"
              icon={IconType.Chevron}
            />
          </>
        </NavLink>
      ) : null;
    });
  };

  return (
    <aside className="profile-layout-side-menu">
      <div className="profile-layout-side-menu__back-wrapper">
        <Button
          className="profile-layout-side-menu__back"
          type={BUTTON_TYPE.TEXT_DARK}
          onClick={() => navigate(paths.map)}
          icon
        >
          <Icon icon={IconType.Arrow} />
          <span>{t('profile.backButton')}</span>
        </Button>
      </div>
      <div className="profile-layout-side-menu__steps">{renderSteps()}</div>
      <Button
        className="profile-layout-side-menu__logout"
        type={BUTTON_TYPE.TEXT_DARK}
        onClick={logout}
      >
        <Icon icon={IconType.Exit} />
        <span>{t('profilePan.logout')}</span>
      </Button>
    </aside>
  );
};

export default SideMenu;
