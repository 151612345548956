/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  components as defaultComponents,
  MenuListProps,
  SelectInstance,
} from 'react-select';
import { AsyncPaginate, wrapMenuList } from 'react-select-async-paginate';

import {
  useCheckSmallScreen,
  UserProfile,
} from '@platform-for-public-places/components-library';

import { DEBOUNCE_TIMEOUT } from 'src/app/constants';
import CatalogAvatar from 'src/shared/components/CatalogUsers/components/CatalogAvatar/CatalogAvatar';

import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import { FILTER_DATA } from 'src/features/searchWithFilter/filter/slices/filterSliceData';
import { State } from 'src/features/store/store';
import { useLazyGetUsersQuery } from 'src/features/user/api';
import { ProjectSpecialist } from 'src/features/user/models';

import { ListUser } from './models';

import './SearchDropdown.scss';

const PAGE_SIZE = 10;

interface SearchDropdownProps {
  participant: ListUser | undefined;
  setParticipant: (value: ListUser) => void;
  className?: string;
  onFocus?: () => void;
  onBlur?: () => void;
}

const SearchDropdown = ({
  participant,
  setParticipant,
  onFocus,
  onBlur,
  className = '',
}: SearchDropdownProps) => {
  const { t } = useTranslation();
  const isSmallScreen = useCheckSmallScreen();

  const projectType = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );

  const team = useSelector((s: State) => s[FILTER_DATA].team);

  const [getParticipantsInfo] = useLazyGetUsersQuery();

  const getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  };

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const selectRef = useRef<SelectInstance>(null);

  const openMenu = () => setMenuIsOpen(true);

  const closeMenu = () => {
    setMenuIsOpen(false);
    selectRef.current?.blur();
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const loadOptions = async (search: string, page: number) => {
    try {
      const response = await getParticipantsInfo({
        searchString: `%${search}%`,
        type: '',
        pageSize: PAGE_SIZE,
        page,
      });
      const data = response.data?.data.map((value) => {
        return { value: value.userId, data: value, label: value.name };
      });

      return {
        options: data ?? [],
        hasMore: response.data?.data.length === PAGE_SIZE,
      };
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const renderUsers = useCallback(
    (innerProps: any) => {
      return (
        <>
          {innerProps && innerProps[0]?.props ? (
            innerProps.map((value: any, i: any) => {
              const user: UserProfile = value?.props?.data.data;
              return (
                <button
                  className={'search-dropdown__user-card'}
                  key={i}
                  onClick={() => {
                    setParticipant({
                      ...value?.props?.data,
                      data: user,
                    });
                    closeMenu();
                  }}
                >
                  <div className="search-dropdown__user-main-info">
                    <CatalogAvatar
                      className={'search-dropdown__user-avatar'}
                      avatar={user.avatar as string}
                    />
                    <p className="search-dropdown__user-name">{user.name}</p>
                  </div>
                  <div className="search-dropdown__user-tags">
                    {user.isDeveloper ? (
                      <p className="search-dropdown__user-tag">
                        {t('userCatalog.tags.developer')}
                      </p>
                    ) : null}
                    {user.isImplementer ? (
                      <p className="search-dropdown__user-tag">
                        {t('userCatalog.tags.implementer')}
                      </p>
                    ) : null}
                  </div>
                </button>
              );
            })
          ) : (
            <p className="search-dropdown__empty-data">
              {t('userCatalog.emptyData')}
            </p>
          )}
        </>
      );
    },
    [setParticipant, t]
  );

  const loadPageOptions = async (
    search: any,
    prevOptions: any, //need field!
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptions(search, page).then(
      (options) => {
        return {
          ...options,
          options: options.options.filter(
            (option) =>
              option.data.userId === participant?.data.userId ||
              !team.find(
                (user: ProjectSpecialist) => user.userId === option.data.userId
              )
          ),
        };
      }
    );
    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };
  const defaultAdditional = {
    page: 1,
  };

  const Menu = useCallback(
    (props: any): JSX.Element => {
      return (
        <defaultComponents.MenuList
          {...props}
          className="search-dropdown__user-list"
        >
          {renderUsers(props.children)}
        </defaultComponents.MenuList>
      );
    },
    [renderUsers]
  );

  const MenuList = useMemo(() => wrapMenuList(Menu), [Menu]);

  return (
    <div className={`search-dropdown__input ${className}`}>
      <AsyncPaginate
        debounceTimeout={DEBOUNCE_TIMEOUT}
        selectRef={selectRef}
        className={`search-dropdown__paginate${menuIsOpen ? '_selected' : ''}`}
        key={JSON.stringify(team)}
        additional={defaultAdditional}
        loadOptions={loadPageOptions}
        onBlur={onBlur}
        placeholder={
          isSmallScreen
            ? t(`creation.${projectType}.team.placeholderMobile`)
            : t(`creation.${projectType}.team.placeholder`)
        }
        value={participant || ''}
        isSearchable={!isSmallScreen}
        onMenuClose={closeMenu}
        onMenuOpen={() => {
          onFocus?.();
          openMenu();
        }}
        menuIsOpen={menuIsOpen}
        maxMenuHeight={(windowSize.innerHeight / 100) * 18}
        components={{
          MenuList: MenuList as unknown as ComponentType<
            MenuListProps<any, any, any>
          >,
        }}
      />
    </div>
  );
};

export default SearchDropdown;
