import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon, IconType } from '@platform-for-public-places/components-library';

import {
  CONTROL,
  setSearchPanelState,
} from 'src/features/control/slices/controlSlice';
import { useDisablePropagationRef } from 'src/features/hooks';
import {
  SearchFilterInputOnlyButtons,
  SearchPanelState,
} from 'src/features/map/components/SearchPane/components/SearchFilterInput/SearchFilterInput';
import { State } from 'src/features/store/store';

import './SearchPaneControl.scss';

const SearchPaneControl = (): ReactElement => {
  const searchPanelState = useSelector(
    (s: State) => s[CONTROL].searchPanelState
  );

  return searchPanelState === SearchPanelState.CLOSE ? (
    <SearchFilterInputOnlyButtons />
  ) : (
    <ShowLessButton searchPanelState={searchPanelState} />
  );
};

export default SearchPaneControl;

const ShowLessButton = ({
  searchPanelState,
}: {
  searchPanelState: SearchPanelState;
}) => {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(setSearchPanelState());
  };
  const controlRef = useDisablePropagationRef();

  const controlIcon =
    searchPanelState === SearchPanelState.CLOSE
      ? IconType.Search
      : IconType.Chevron;

  const dynamicClass = (s: TemplateStringsArray) =>
    searchPanelState === SearchPanelState.CLOSE
      ? s[0]
      : `${s[0]} ${s[0]}--chevron`;
  return (
    <button
      className={'search-pane-control'}
      onClick={() => onClick()}
      ref={controlRef}
    >
      <Icon
        className={dynamicClass`search-pane-control__icon`}
        icon={controlIcon}
      />
    </button>
  );
};
