import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import CircleProgressBar from 'src/shared/components/CircleProgressBar/CircleProgressBar';

import { useLazyGetFilesQuery } from 'src/features/files/api';
import { downloadURI } from 'src/features/files/download';
import { FileCategories } from 'src/features/files/enums';

import { useLazyGetDonationDetailsQuery } from '../../api/index';

import { PaymentDetails } from '../../models';

import './DonationTotalBudget.scss';

const DEFAULT_AMOUNT = 0;
const DOCS_LIMIT = 1;
const DOCS_OFFSET = 0;

interface DonationTotalBudgetProps {
  className?: string;
  archived?: boolean;
}

const DonationTotalBudget = ({
  className = '',
  archived = false,
}: DonationTotalBudgetProps) => {
  const { id } = useParams();
  const { t } = useTranslation('app', {
    keyPrefix: 'detailedProject.donation',
  });

  const [getBudgetDocs] = useLazyGetFilesQuery();
  const [getDonationDetails] = useLazyGetDonationDetailsQuery();

  const [data, setData] = useState<PaymentDetails>({});

  useEffect(() => {
    getDonationDetails({
      projectId: id ? id : '',
      archived,
    }).then((value) => {
      if (value.data && value.data.data.paymentDetails) {
        setData(value.data.data.paymentDetails);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data && !data.link) {
      getBudgetDocs({
        entityId: id as string,
        category: FileCategories.BUDGET_FILE,
        limit: DOCS_LIMIT,
        offset: DOCS_OFFSET,
      })
        .unwrap()
        .then((value) => {
          if (value?.data[0] && value?.data[0].url) {
            setData((prev) => ({
              ...prev,
              link: value?.data[0].url,
              name: value?.data[0].name,
            }));
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBudgetDocs, data]);

  const formatAmount = (amount: string) => {
    return amount
      .split('')
      .reverse()
      .reduce((prev, v, i) => (i % 3 === 0 ? `${v} ${prev}` : v + prev));
  };

  const renderAmount = (className: string, amount?: number) => {
    const value = amount ? formatAmount(amount.toFixed()) : DEFAULT_AMOUNT;

    const dynamicClass = (s: TemplateStringsArray) => {
      const size = checkStringLength() ? `${s[0]}-more` : '';
      const _archived = archived ? `${s[0]}--archived` : '';
      return `${s[0]} ${size} ${_archived} ${className}`;
    };

    return (
      <span className={dynamicClass`total-budget__amount`}>{`${value} ₽`}</span>
    );
  };

  const checkStringLength = () => {
    return data.donationTotal && data.budget
      ? data.donationTotal.toString().length + data.budget.toString().length >
          10
      : false;
  };

  const renderAmountOfTotal = () => {
    return (
      <p className="total-budget__amount">
        {renderAmount(
          data && data.donationTotal && data.donationTotal !== DEFAULT_AMOUNT
            ? 'total-budget__amount-collected'
            : 'total-budget__amount-total',
          data.donationTotal
        )}
        {' / '}
        {renderAmount('', data.budget)}
      </p>
    );
  };

  const renderTotalBudget = () => {
    return data.link && data.budget ? (
      <div className="total-budget__budget-info">
        <div className="total-budget__budget-info-progress">
          <CircleProgressBar
            collected={data.donationTotal ? data.donationTotal : DEFAULT_AMOUNT}
            total={data.budget}
            archived={archived}
          />
        </div>
        <div className="total-budget__budget-info-data">
          {renderAmountOfTotal()}
          {
            <button onClick={() => downloadURI(data.link, data.name)}>
              {t('estimate')}
            </button>
          }
        </div>
      </div>
    ) : (
      <p className="total-budget__collected">
        {t('collected')}
        {renderAmount('total-budget__amount-collected', data.donationTotal)}
      </p>
    );
  };

  return (
    <div className={`${className}`}>
      {data.donationTotal || data.link ? (
        renderTotalBudget()
      ) : (
        <div className="total-budget__empty-budget">{t('emptyBudget')}</div>
      )}
    </div>
  );
};
export default DonationTotalBudget;
