import { useTranslation } from 'react-i18next';

import { PAYMENT_METHOD } from 'src/features/payment/models';

export const usePaymentStatusTranslation = (
  paymentMethod: PAYMENT_METHOD
): string => {
  const { t } = useTranslation('app', {
    keyPrefix: 'profile.payments.all.row',
  });
  return Object.values(PAYMENT_METHOD).includes(paymentMethod)
    ? t(`paymentType.${paymentMethod}`)
    : t('paymentType.unknown');
};
