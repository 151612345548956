import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { VIEW_CARD } from 'src/features/control/enums';
import { setCurrentCard } from 'src/features/control/slices/controlSlice';
import { useLazyGetProjectInfoByIdQuery } from 'src/features/project/api';
import { setCurrentProjectInfo } from 'src/features/project/slices/projectsLayerSlice';

import { useIsArchived } from 'src/pages/project/hooks';

const MapProjectWatcher = ({
  children,
}: {
  children: ReactElement | ReactElement[];
}) => {
  const dispatch = useDispatch();
  const archived = useIsArchived();
  const [searchParams] = useSearchParams();
  const [getProjectInfo] = useLazyGetProjectInfoByIdQuery();

  useEffect(() => {
    const projectId = searchParams.get('projectId');
    if (projectId) {
      getProjectInfo({ projectId, archived }).then((response) => {
        if (response.data?.data) {
          dispatch(setCurrentProjectInfo(response.data.data));
          dispatch(setCurrentCard(VIEW_CARD.OVERVIEW));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchParams]);

  /*  useEffect(() => {
//todo return archive feature
    if (archived) {
      dispatch(changeArchivedProjectsVisibility(true));
    }
  }, [archived, dispatch]);*/

  return <>{children}</>;
};

export default MapProjectWatcher;
