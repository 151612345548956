import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Cadaster } from '../models';

export const PKK = 'PKK';

export interface PkkState {
  cadasters: Cadaster[];
}

const initialState: PkkState = {
  cadasters: [],
};

const pkkSlice = createSlice({
  name: PKK,
  initialState,
  reducers: {
    changeCadasters: (state: PkkState, action: PayloadAction<Cadaster[]>) => {
      state.cadasters = action.payload;
    },
  },
});

export const { changeCadasters } = pkkSlice.actions;
export default pkkSlice.reducer;
