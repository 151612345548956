import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  TextInput,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { MODALS } from 'src/features/modal/models';
import { changeEmail, changeModal } from 'src/features/modal/slices/modalSlice';

import { useGetPasswordRecoveryLinkMutation } from '../../api';

import './ForgotPassword.scss';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'forgotPassword' });

  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const [getPasswordRecoveryLink] = useGetPasswordRecoveryLinkMutation();

  const onChangeEmail = (value: string) => {
    if (error) {
      setError(false);
    }
    setEmail(value);
  };

  const goToNextModal = () => {
    dispatch(changeEmail(email));
    dispatch(changeModal(MODALS.FORGOT_PASSWORD_SENT));
  };

  const onClickSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    getPasswordRecoveryLink({ email }).then(() => {
      isSmallScreen
        ? navigate(paths.forgotPasswordSent, {
            replace: true,
            state: { email: email.trim() },
          })
        : goToNextModal();
    });
  };

  return (
    <div className="forgot-password">
      <h3 className="auth-title forgot-password__title">{t('title')}</h3>
      <p className="auth-description forgot-password__description">
        {t('description')}
      </p>
      <form className="forgot-password__form" onSubmit={onClickSubmit}>
        <TextInput
          value={email}
          className={`auth-input forgot-password__input${error ? 'error' : ''}`}
          placeholder={t('emailPlaceholder')}
          onChange={(e) => onChangeEmail(e.target.value)}
          type="email"
        />
        <Button
          submit
          disabled={!email}
          className="auth-button forgot-password__button"
          type={BUTTON_TYPE.ACCENTED}
        >
          {t('continue')}
        </Button>
      </form>
    </div>
  );
};

export default ForgotPassword;
