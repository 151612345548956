import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SearchPanelState } from 'src/features/map/components/SearchPane/components/SearchFilterInput/SearchFilterInput';
import { VariantFilterMobile } from 'src/features/searchWithFilter/filter/searchFilters/StatusButton/enum';

import { VIEW_CARD } from '../enums';

export const CONTROL = 'CONTROL';

export interface ControlState {
  showCadastralLayer: boolean;
  showCadastralLayerError: boolean;
  currentCard: VIEW_CARD | null;
  searchPanelState: SearchPanelState;
  mobileMenuOpen: boolean;
  mobileVariantFilter: VariantFilterMobile | null;
}

const initialState: ControlState = {
  mobileMenuOpen: false,
  searchPanelState: SearchPanelState.CLOSE,
  showCadastralLayer: false,
  showCadastralLayerError: false,
  currentCard: null,
  mobileVariantFilter: null,
};

export const controlSlice = createSlice({
  name: CONTROL,
  initialState,
  reducers: {
    setSearchPanelState: (
      state: ControlState,
      action: PayloadAction<SearchPanelState | undefined>
    ) => {
      if (action.payload !== undefined && action.payload > -1) {
        state.searchPanelState = action.payload;
      } else {
        state.searchPanelState =
          state.searchPanelState === SearchPanelState.CLOSE
            ? SearchPanelState.OPEN
            : SearchPanelState.CLOSE;
      }
    },
    changeCadastralLayerVisibility: (
      state: ControlState,
      action: PayloadAction<boolean>
    ) => {
      state.showCadastralLayer = action.payload;
    },
    changeCadastralLayerErrorVisibility: (
      state: ControlState,
      action: PayloadAction<boolean>
    ) => {
      state.showCadastralLayerError = action.payload;
    },

    setCurrentCard: (
      state: ControlState,
      action: PayloadAction<VIEW_CARD | null>
    ) => {
      state.currentCard = action.payload;
    },
    setMobileMenuOpen: (
      state: ControlState,
      action: PayloadAction<boolean>
    ) => {
      state.mobileMenuOpen = action.payload;
    },
    setMobileVariantFilter: (
      state: ControlState,
      action: PayloadAction<VariantFilterMobile>
    ) => {
      state.mobileVariantFilter = action.payload;
    },
  },
});

export const {
  setMobileMenuOpen,
  setSearchPanelState,
  changeCadastralLayerVisibility,
  changeCadastralLayerErrorVisibility,
  setCurrentCard,
  setMobileVariantFilter,
} = controlSlice.actions;

export default controlSlice.reducer;
