import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { IconType } from '@platform-for-public-places/components-library';

import { USER_ROLE } from 'src/features/auth/models';
import { useCheckUser } from 'src/features/hooks';
import { TYPE_SEARCH_PAGE } from 'src/features/map/components/SearchPane/components/PaginationList/PaginationList';
import {
  useGetProjectTypesQuery,
  useLazyGetProjectStatusesByTypeQuery,
} from 'src/features/project/api/index';
import {
  ProjectFilterOption,
  ProjectFilterStatus,
} from 'src/features/searchWithFilter/filter/models';
import {
  FILTER,
  setAllStatus,
} from 'src/features/searchWithFilter/filter/slices/filterSlice';
import { State } from 'src/features/store/store';

export const DEFAULT_PROJECT_STATUS_CATALOG = [
  'a2703ba4-99f5-4c23-980b-f22b4478f569',
  '64fccbce-acb1-4fa1-b2f2-b4155c84ca6d',
  '0f4b8a70-9cb1-4038-b1f5-57756bb465b6',
  '99eaabfe-61f1-47ab-8fe4-a8a73b78abe6',
  'b97f9dc2-6c6a-4837-a5ef-4b41a07031c4',
];

export const DEFAULT_PROJECT_STATUS_MAP = [
  'a2703ba4-99f5-4c23-980b-f22b4478f569',
  '64fccbce-acb1-4fa1-b2f2-b4155c84ca6d',
  '0f4b8a70-9cb1-4038-b1f5-57756bb465b6',
  '99eaabfe-61f1-47ab-8fe4-a8a73b78abe6',
  'b97f9dc2-6c6a-4837-a5ef-4b41a07031c4',

  'ef4b8a70-9cb1-4038-b1f5-57756bb465b6',
  '0b99f891-0eca-4fc2-9055-9b63aca67c06',
  'e4fccbce-acb1-4fa1-b2f2-b4155c84ca6d',
  'e2703ba4-99f5-4c23-980b-f22b4478f569',
];

export const DEFAULT_PROJECT_STATUS_AUTH = [
  '9b99f891-0eca-4fc2-9055-9b63aca67c06',
  'eb99f891-0eca-4fc2-9055-9b63aca67c06',
];

export const useSelectDefaultStatus = (typePage: TYPE_SEARCH_PAGE) => {
  const isUser = useCheckUser([
    { role: USER_ROLE.USER },
    { role: USER_ROLE.ADMIN },
  ]);
  const baseListStatus =
    typePage === TYPE_SEARCH_PAGE.PROJECT_CATALOG
      ? DEFAULT_PROJECT_STATUS_CATALOG
      : DEFAULT_PROJECT_STATUS_MAP;
  const list = [
    ...baseListStatus,
    ...(isUser ? DEFAULT_PROJECT_STATUS_AUTH : []),
  ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => list, [list?.length, isUser]);
};

export const useGetAllProjectStatus = () => {
  const { getStatusesByType } = useMergeStatusAndConfig();
  const dispatch = useDispatch();
  const { data: projectTypes } = useGetProjectTypesQuery('read');
  const isUser = useCheckUser([
    { role: USER_ROLE.USER },
    { role: USER_ROLE.ADMIN },
  ]);
  useEffect(() => {
    if (projectTypes) {
      const promiseList = projectTypes?.data.types?.map((v) => {
        return getStatusesByType(v);
      });
      Promise.all(promiseList).then((value) => {
        dispatch(
          setAllStatus(
            value.filter((f) => f !== undefined) as ProjectFilterStatus[]
          )
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectTypes, isUser]);
};

export const useProjectStatuses = () => {
  const { allStatus } = useSelector((s: State) => s[FILTER]);
  const getStatusByConfig = (type: string): ProjectFilterOption[] => {
    const value = allStatus?.find((f) => f.type === type);
    return value?.statuses ?? [];
  };
  return { getStatusByConfig };
};

const useMergeStatusAndConfig = () => {
  const { t } = useTranslation('app', {
    keyPrefix: `cards.overview.projectStatus`, //todo fix prefix path
  });
  const [trigger] = useLazyGetProjectStatusesByTypeQuery();

  const config = [
    {
      name: 'framed',
      label: t('framed'),
      flowID: '5b6a0d2f-8447-4f21-9061-eac84d70a94e',
      flow_stepID: 'eb99f891-0eca-4fc2-9055-9b63aca67c06',
      icon: IconType.FRAMED,
    },
    {
      name: 'planned',
      label: t('planned'),
      flowID: '5b6a0d2f-8447-4f21-9061-eac84d70a94e',
      flow_stepID: 'e2703ba4-99f5-4c23-980b-f22b4478f569',
      icon: IconType.PLANNED,
    },
    {
      name: 'running',
      label: t('running'),
      flowID: '5b6a0d2f-8447-4f21-9061-eac84d70a94e',
      flow_stepID: 'e4fccbce-acb1-4fa1-b2f2-b4155c84ca6d',
      icon: IconType.RUNNING,
    },
    {
      name: 'finished',
      label: t('finished'),
      flowID: '5b6a0d2f-8447-4f21-9061-eac84d70a94e',
      flow_stepID: 'ef4b8a70-9cb1-4038-b1f5-57756bb465b6',
      icon: IconType.FINISHED,
    },

    {
      name: 'framed',
      label: t('finished'),
      flowID: '5b6a0d2f-8447-4f21-9061-eac84d70a94b',
      flow_stepID: '9b99f891-0eca-4fc2-9055-9b63aca67c06',
      icon: IconType.FRAMED,
    },
    {
      name: 'accepted',
      label: t('accepted'),
      flowID: '5b6a0d2f-8447-4f21-9061-eac84d70a94b',
      flow_stepID: 'a2703ba4-99f5-4c23-980b-f22b4478f569',
      icon: IconType.ACCEPTED,
    },
    {
      name: 'approved',
      label: t('approved'),
      flowID: '5b6a0d2f-8447-4f21-9061-eac84d70a94b',
      flow_stepID: '64fccbce-acb1-4fa1-b2f2-b4155c84ca6d',
      icon: IconType.APPROVED,
    },
    {
      name: 'implemented',
      label: t('implemented'),
      flowID: '5b6a0d2f-8447-4f21-9061-eac84d70a94b',
      flow_stepID: '0f4b8a70-9cb1-4038-b1f5-57756bb465b6',
      icon: IconType.IMPLEMENTED,
    },
    {
      name: 'finished',
      label: t('finished'),
      flowID: '5b6a0d2f-8447-4f21-9061-eac84d70a94b',
      flow_stepID: '99eaabfe-61f1-47ab-8fe4-a8a73b78abe6',
      icon: IconType.FINISHED,
    },
    {
      name: 'transferred',
      label: t('transferred'),
      flowID: '5b6a0d2f-8447-4f21-9061-eac84d70a94b',
      flow_stepID: 'b97f9dc2-6c6a-4837-a5ef-4b41a07031c4',
      icon: IconType.TRANSFERRED,
    },

    {
      name: 'framed',
      label: t('finished'),
      flowID: '6b6a0d2f-8447-4f21-9061-eac84d70a94b',
      flow_stepID: '3b99f891-0eca-4fc2-9055-9b63aca67c06',
      icon: IconType.FRAMED,
    },
    {
      name: 'accepted',
      label: t('accepted'),
      flowID: '6b6a0d2f-8447-4f21-9061-eac84d70a94b',
      flow_stepID: '32703ba4-99f5-4c23-980b-f22b4478f569',
      icon: IconType.ACCEPTED,
    },
    {
      name: 'approved',
      label: t('approved'),
      flowID: '6b6a0d2f-8447-4f21-9061-eac84d70a94b',
      flow_stepID: '34fccbce-acb1-4fa1-b2f2-b4155c84ca6d',
      icon: IconType.APPROVED,
    },
    {
      name: 'implemented',
      label: t('implemented'),
      flowID: '6b6a0d2f-8447-4f21-9061-eac84d70a94b',
      flow_stepID: '3f4b8a70-9cb1-4038-b1f5-57756bb465b6',
      icon: IconType.IMPLEMENTED,
    },
    {
      name: 'finished',
      label: t('finished'),
      flowID: '6b6a0d2f-8447-4f21-9061-eac84d70a94b',
      flow_stepID: '39eaabfe-61f1-47ab-8fe4-a8a73b78abe6',
      icon: IconType.FINISHED,
    },
    {
      name: 'transferred',
      label: t('transferred'),
      flowID: '6b6a0d2f-8447-4f21-9061-eac84d70a94b',
      flow_stepID: '397f9dc2-6c6a-4837-a5ef-4b41a07031c4',
      icon: IconType.TRANSFERRED,
    },

    {
      name: 'headquarters',
      label: t('headquarters'),
      flowID: '5b6a0d2f-8447-4f21-9061-eac84d70a94c',
      flow_stepID: '0b99f891-0eca-4fc2-9055-9b63aca67c06',
      icon: IconType.Headquarters,
    },
  ];

  const getStatusesByType = async (
    type: string
  ): Promise<ProjectFilterStatus | undefined> => {
    const data = await trigger(type);
    return {
      type: type,
      statuses:
        data?.data?.data?.flowSteps.map((v) => ({
          label: t(`${type}.${v.name}`),
          id: v.flow_stepID,
          icon: config.find((f) => f.flow_stepID === v.flow_stepID)?.icon,
        })) ?? [],
    };
  };
  return { getStatusesByType };
};
