import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { paths } from 'src/shared/routes';

import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import CreationTeamWidget from './widgets/CreationTeamWidget/CreationTeamWidget';

const teamWidgetsMap = new Map<string, () => JSX.Element>([
  ['creationTeamWidget', CreationTeamWidget],
]);

const CreationTeam = () => {
  const navigate = useNavigate();

  const currentStepIndex = useSelector(
    (s: State) => s[PROJ_CREATE].currentCreationStep
  );
  const stepConfigs = useSelector(
    (s: State) => s[PROJ_CREATE].configCreationSteps
  );

  useEffect(() => {
    if (stepConfigs.length === 0) {
      navigate(paths.creation);
    }
  }, [navigate, stepConfigs]);

  const TeamWidget = teamWidgetsMap.get(
    stepConfigs[currentStepIndex] ? stepConfigs[currentStepIndex].type : ''
  );
  return TeamWidget?.() || null;
};

export default CreationTeam;
