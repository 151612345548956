import { useTranslation } from 'react-i18next';

import { ReactComponent as PhotoAfter } from 'src/shared/components/ProjectPhotoMock/images/project-photo-after-mock.svg';
import { ReactComponent as PhotoBefore } from 'src/shared/components/ProjectPhotoMock/images/project-photo-before-mock.svg';
import { ReactComponent as PhotoConcept } from 'src/shared/components/ProjectPhotoMock/images/project-photo-concept-mock.svg';

import { FileCategories, PhotoFileCategories } from 'src/features/files/enums';

import './ProjectPhotoMock.scss';

interface ProjectPhotoMockProps {
  category: PhotoFileCategories;
  className?: string;
}

const MOCKS = {
  [FileCategories.PHOTO_BEFORE]: {
    key: 'before',
    image: <PhotoBefore className="project-photo-mock__image" />,
  },
  [FileCategories.PHOTO_CONCEPT]: {
    key: 'concept',
    image: <PhotoConcept className="project-photo-mock__image" />,
  },
  [FileCategories.PHOTO_AFTER]: {
    key: 'after',
    image: <PhotoAfter className="project-photo-mock__image" />,
  },
};

const ProjectPhotoMock = ({
  category,
  className = '',
}: ProjectPhotoMockProps): JSX.Element => {
  const { t } = useTranslation('app', {
    keyPrefix: 'detailedProject.photoMock',
  });

  return (
    <div className={`project-photo-mock ${className}`}>
      {MOCKS[category].image}
      <p className="project-photo-mock__label">{t(MOCKS[category].key)}</p>
    </div>
  );
};

export default ProjectPhotoMock;
