import { UserProfile } from '@platform-for-public-places/components-library';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProjectCatalogData } from 'src/features/project/models';
import { ProjectSpecialist } from 'src/features/user/models';

import { ProjectFilterOption } from '../models';

export const FILTER_DATA = 'FILTER_DATA';

interface FilterState {
  participants: UserProfile[];
  projects: ProjectCatalogData[];
  participantsType: string;
  team: ProjectSpecialist[];
  teamPrevious: ProjectSpecialist[];
  teamError: boolean;
  projectsType?: ProjectFilterOption;
  selectedUserId: string;
}

const initialState: FilterState = {
  projects: [],
  participants: [],
  team: [],
  teamPrevious: [],
  teamError: false,
  participantsType: '',
  selectedUserId: '',
};

export const filterSliceData = createSlice({
  name: FILTER_DATA,
  initialState,
  reducers: {
    setParticipantsType: (
      state: FilterState,
      action: PayloadAction<string>
    ) => {
      state.participantsType = action.payload;
    },
    setProjectsType: (
      state: FilterState,
      action: PayloadAction<ProjectFilterOption>
    ) => {
      state.projectsType = action.payload;
    },
    setProjects: (
      state: FilterState,
      action: PayloadAction<ProjectCatalogData[]>
    ) => {
      state.projects = action.payload;
    },
    setParticipants: (
      state: FilterState,
      action: PayloadAction<UserProfile[]>
    ) => {
      state.participants = action.payload;
    },
    setTeam: (
      state: FilterState,
      action: PayloadAction<ProjectSpecialist[]>
    ) => {
      state.team = action.payload;
    },
    setTeamPrevious: (
      state: FilterState,
      action: PayloadAction<ProjectSpecialist[]>
    ) => {
      state.teamPrevious = action.payload;
    },
    setSelectedUserId: (state: FilterState, action: PayloadAction<string>) => {
      state.selectedUserId = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  setParticipantsType,
  setProjectsType,
  setProjects,
  setParticipants,
  setTeam,
  setTeamPrevious,
  setSelectedUserId,
  reset,
} = filterSliceData.actions;

export default filterSliceData.reducer;
