import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ru } from 'date-fns/locale';

import StatusCard from 'src/shared/components/StatusCard/StatusCard';

import { REQUEST_STATUS } from 'src/features/request/enums';
import {
  BaseRequestData,
  RequestToProjectParticipantRequestBody,
} from 'src/features/request/models';

import MoreInfoStatus from '../MoreInfoStatus/MoreInfoStatus';

import './StatusRequest.scss';

const STATUS_COLOR = {
  [REQUEST_STATUS.REJECTED]: 'team-user-status__rejected',
  [REQUEST_STATUS.ACTIVE]: 'team-user-status__active',
  [REQUEST_STATUS.EXECUTED]: 'team-user-status__execute',
};

interface StatusRequestProps {
  data: BaseRequestData<RequestToProjectParticipantRequestBody>;
  refuseAction: (request: string) => void;
}

const StatusRequest = ({ data, refuseAction }: StatusRequestProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'profile.participations' });

  const [bottomSheetVisible, setBottomSheetVisible] = useState(false);

  const status = data.status || REQUEST_STATUS.ACTIVE;
  const statusValue = t(`status.${status.toLowerCase()}`);
  const dateValue = new Date(data.updatedAt).toLocaleDateString(ru.code);

  const getClassStyle = (s: TemplateStringsArray, mod?: string) => {
    const modificator = mod ? `${s[0]}--${mod}` : '';
    return `${s[0]} ${modificator} ${STATUS_COLOR[status]}`;
  };

  const renderStatusComment = (extended = false) => {
    const classPayload = extended
      ? getClassStyle`status-block__comment`
      : getClassStyle`status-block__comment${'digest'}`;

    return (
      <div className={classPayload}>
        {extended ? (
          <span className="status-block__comment-date">{dateValue}</span>
        ) : null}
        {statusValue}
        {status === REQUEST_STATUS.REJECTED && data.message
          ? `${t('status.rejectedComment')}«${data.message}»`
          : null}
      </div>
    );
  };

  const renderContentInsideCard = () => (
    <>
      <div className={getClassStyle`status-block__title`}>
        <p className={getClassStyle`status-block__date`}>{dateValue}</p>
        <p className="status-block__project-name">
          {data.requestBody.projectName}
        </p>
      </div>
      {renderStatusComment()}
    </>
  );

  const renderContentBottomSheet = () => (
    <MoreInfoStatus
      setBottomSheetVisible={setBottomSheetVisible}
      data={data}
      refuseAction={refuseAction}
      status={renderStatusComment(true)}
    />
  );

  return (
    <StatusCard
      bottomSheetVisible={bottomSheetVisible}
      setBottomSheetVisible={setBottomSheetVisible}
      contentInsideCard={() => renderContentInsideCard()}
      contentBottomSheet={() => renderContentBottomSheet()}
    />
  );
};

export default StatusRequest;
