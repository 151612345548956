import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';
import clsx from 'clsx';

import { paths } from 'src/shared/routes';

import StageHeader from 'src/features/layout/components/StageHeader/StageHeader';
import {
  useGetProjectTypesQuery,
  useLazyGetProjectStepsQuery,
} from 'src/features/project/api';
import {
  setConfigCreationSteps,
  setCurrentCreationStep,
  setCurrentTypeProject,
} from 'src/features/project/slices/creatingProjectSlice';

import { DEFAULT_TYPE_PROJECT } from '../../CreationLayout';

import './Creation.scss';

const Creation = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('app', { keyPrefix: 'creation.creationPage' });
  const { data: projectTypes } = useGetProjectTypesQuery('create');
  const [getProjectSteps] = useLazyGetProjectStepsQuery();
  const [checkedType, setCheckedType] = useState<string>(DEFAULT_TYPE_PROJECT);

  const elementsCount = projectTypes?.data.types.length || 0;

  const gridClassModifier = `grid-${Math.min(elementsCount, 6)}-elements`;

  const capitalizeFirstLetter = (inputString: string): string => {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  };

  const getIconTypeByKey = (key: string): IconType => {
    return IconType[key as keyof typeof IconType];
  };

  const submitProjectType = () => {
    getProjectSteps({
      type: checkedType,
    })
      .unwrap()
      .then((response) => {
        dispatch(setCurrentTypeProject(checkedType));
        dispatch(setCurrentCreationStep(0));
        dispatch(setConfigCreationSteps(response.data.creationSteps));
        navigate(paths.creationStep(response.data.creationSteps[0].name), {
          replace: true,
          state: { prevUrl: null },
        });
      });
  };

  const renderType = (type: string) => (
    <button
      className={clsx('project-type', {
        'project-type--active': type === checkedType,
        'project-type--small': elementsCount > 5,
      })}
      key={type}
      onClick={() => {
        setCheckedType(type);
      }}
    >
      <div
        className={clsx('project-type__content', {
          'project-type__content--small': elementsCount > 5,
        })}
      >
        <Icon
          icon={getIconTypeByKey(capitalizeFirstLetter(type))}
          className={clsx(
            'project-type__icon',
            `project-type__icon-${gridClassModifier}`
          )}
        />
        <div className="project-type__description">
          <h6 className="project-type__title">{t(`${type}.title`)}</h6>
          <p className="project-type__subtitle">{t(`${type}.subtitle`)}</p>
        </div>
      </div>
    </button>
  );

  const renderTypes = () => (
    <div
      className={clsx(
        'creation__types',
        'creation__types-scroll',
        `creation__types-${gridClassModifier}`
      )}
    >
      {projectTypes?.data.types.map((type: string) => renderType(type))}
    </div>
  );

  return (
    <div className={clsx('creation', `creation-${gridClassModifier}`)}>
      <StageHeader
        className="creation__header"
        header={t('title')}
        subheader={t('subtitle')}
      />
      {renderTypes()}
      <Button
        className="creation__continue-button"
        type={BUTTON_TYPE.ACCENTED}
        onClick={() => submitProjectType()}
      >
        {t('continue')}
      </Button>
    </div>
  );
};

export default Creation;
