import { useEffect } from 'react';
import { useMap, useMapEvent } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';

import { useLocalStorage } from '@uidotdev/usehooks';

import { BBOX_COOKIE_KEY, ZOOM_COOKIE_KEY } from 'src/app/constants';
import {
  convertBoundsToString,
  convertSizeToString,
} from 'src/shared/converters';
import { setCookie } from 'src/shared/cookies';

import { CONTROL } from 'src/features/control/slices/controlSlice';
import { COOKIE } from 'src/features/cookies/slices/cookieSlice';
import { filesInvalidator } from 'src/features/files/api/filesApi';
import {
  changeBbox,
  changeSize,
  changeZoom,
} from 'src/features/map/slices/mapSlice';
import { State } from 'src/features/store/store';

const THIRTY_ONE_DAY_IN_SECONDS = 2678400;

const cookieOptions = {
  domain: document.location.hostname,
  maxAge: THIRTY_ONE_DAY_IN_SECONDS,
};

const MapStateUpdater = (): null => {
  const map = useMap();
  const dispatch = useDispatch();

  const [allowedCookie] = useLocalStorage<boolean>(COOKIE);

  const searchPanelState = useSelector(
    (s: State) => s[CONTROL].searchPanelState
  );

  // This useEffect is necessary to update the map size during the animation of the left side menu
  //TODO: Optimize this code
  useEffect(() => {
    const interval = setInterval(() => {
      map.invalidateSize();
    });

    setTimeout(() => {
      clearInterval(interval);
    }, 600);
  }, [map, searchPanelState]);

  useEffect(() => {
    dispatch(filesInvalidator(['FILES']));
    dispatch(changeBbox(convertBoundsToString(map.getBounds())));
    dispatch(changeSize(convertSizeToString(map.getSize())));
    dispatch(changeZoom(map.getZoom()));
    if (allowedCookie) {
      setCookie(
        BBOX_COOKIE_KEY,
        convertBoundsToString(map.getBounds()),
        cookieOptions
      );
      setCookie(ZOOM_COOKIE_KEY, `${map.getZoom()}`, cookieOptions);
    }
  }, [allowedCookie, dispatch, map]);

  useMapEvent('moveend', () => {
    dispatch(changeBbox(convertBoundsToString(map.getBounds())));
    dispatch(changeSize(convertSizeToString(map.getSize())));
    dispatch(changeZoom(map.getZoom()));
    if (allowedCookie) {
      setCookie(
        BBOX_COOKIE_KEY,
        convertBoundsToString(map.getBounds()),
        cookieOptions
      );
      setCookie(ZOOM_COOKIE_KEY, `${map.getZoom()}`, cookieOptions);
    }
  });

  return null;
};

export default MapStateUpdater;
