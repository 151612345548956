import { ReactElement } from 'react';

import clsx from 'clsx';

import 'src/features/searchWithFilter/filter/searchFilters/StatusButton/StatusButton.scss';
import Indicator from 'src/features/searchWithFilter/filter/searchFilters/Indicator/Indicator';
type StatusButtonProps = {
  className?: string;
  children: ReactElement | string;
  onClick: () => void;
  active: boolean;
  indicator: boolean;
};

const StatusButton = ({
  children,
  onClick,
  active,
  indicator,
  className,
}: StatusButtonProps) => {
  return (
    <button
      className={clsx('status-button', className, {
        'status-button--active': active,
      })}
      onClick={onClick}
    >
      {indicator ? <Indicator show={indicator} /> : null}
      {children}
    </button>
  );
};

export default StatusButton;
