import { Trans, useTranslation } from 'react-i18next';

import { TextInput } from '@platform-for-public-places/components-library';
import clsx from 'clsx';

import Checkbox from 'src/shared/components/Checkbox/Checkbox';

import './SupportBlock.scss';

interface SupportBlockProps {
  isSupported: boolean;
  onSupported: () => void;
  showEmailInput: boolean;
  email: string;
  onEmailChange: (value: string) => void;
  onEmailFocusLeave: () => void;
  emailError: string;
  onClickSignInButton: () => void;
  archived: boolean;
  className: string;
}

const SupportBlock = ({
  isSupported,
  onSupported,
  showEmailInput,
  email,
  onEmailChange,
  onEmailFocusLeave,
  emailError,
  onClickSignInButton,
  archived,
  className,
}: SupportBlockProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'recurrent.payment' });

  return (
    <div className={clsx(className, 'support-block')}>
      <Checkbox
        id={'support'}
        className="support-block__checkbox"
        checked={isSupported}
        onChange={onSupported}
        disabled={archived}
        label={t('checkboxes.support')}
      />
      {showEmailInput ? (
        <div className="support-block__email-block">
          <TextInput
            className={clsx('support-block__email', {
              'support-block__email--error': emailError,
            })}
            type="email"
            placeholder={t('placeholders.email')}
            value={email}
            onChange={(e) => onEmailChange(e.target.value)}
            onFocusLeave={onEmailFocusLeave}
            disabled={archived}
          />
          <div
            className={clsx({
              'support-block__email--visible': emailError,
            })}
          >
            {emailError ? (
              <p className="support-block__error-text">
                <Trans
                  i18nKey={emailError}
                  components={{
                    btn: (
                      <button
                        className="support-block__error-btn"
                        onClick={onClickSignInButton}
                      />
                    ),
                  }}
                />
              </p>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SupportBlock;
