import { Navigate } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import AutopaymentsManagementForm from 'src/features/autopayments/components/AutopaymentsManagementForm/AutopaymentsManagementForm';
import { MODALS } from 'src/features/modal/models';

import './AutopaymentsManagement.scss';

const AutopaymentsManagement = () => {
  const isSmallScreen = useCheckSmallScreen();

  return isSmallScreen ? (
    <div className="autopayments-management-page">
      <AutopaymentsManagementForm />
    </div>
  ) : (
    <Navigate
      to={paths.projects}
      replace
      state={{ modalState: MODALS.AUTOPAYMENTS_MANAGEMENT }}
    />
  );
};

export default AutopaymentsManagement;
