import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import MobileHeader from 'src/features/mobile/components/MobileHeader/MobileHeader';
import { MODALS } from 'src/features/modal/models';
import { changeModal, MODAL } from 'src/features/modal/slices/modalSlice';
import DonationFailure from 'src/features/payment/components/DonationFailure/DonationFailure';
import { State } from 'src/features/store/store';

import './DonationFailurePage.scss';

const DonationFailurePage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'paymentFailure' });

  const openedModal = useSelector((s: State) => s[MODAL].openedModal);

  const redirectPath = id ? paths.projectById(id) : paths.frontPage;

  useEffect(() => {
    if (!isSmallScreen && openedModal !== MODALS.PAYMENT_FAILURE) {
      dispatch(changeModal(MODALS.PAYMENT_FAILURE));
    }
  });

  if (isSmallScreen) {
    return (
      <div className="donation-failure-page">
        <MobileHeader title={t('mobileHeader')} path={redirectPath} replace />
        <DonationFailure />
      </div>
    );
  } else {
    return <Navigate to={redirectPath} replace />;
  }
};

export default DonationFailurePage;
