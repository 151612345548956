import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { GetFilesResponse } from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';
import { filesUploaderWidgetsMap } from 'src/shared/widgets/files';

import {
  useCreateFileKeysMutation,
  useLazyGetFilesQuery,
  useSetProjectCoverMutation,
} from 'src/features/files/api';
import { FileCategories } from 'src/features/files/enums';
import { PHOTOS_TYPE } from 'src/features/project/enums';
import {
  PROJ_CREATE,
  setDesigns,
  setPhotos,
  setPhotosCover,
} from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import { CreationOutletProps } from 'src/pages/layouts/CreationLayout/models';

const PHOTO_LIMIT = 10;
const PHOTO_OFFSET = 0;
const REFETCH_TIMEOUT = 500;

const CreationUploadFiles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { renderFooter }: CreationOutletProps = useOutletContext();

  const designs = useSelector((s: State) => s[PROJ_CREATE].designs);
  const photosCover = useSelector((s: State) => s[PROJ_CREATE].photosCover);
  const photosBefore = useSelector((s: State) => s[PROJ_CREATE].photosBefore);

  const currentProjectType = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );
  const currentStepIndex = useSelector(
    (s: State) => s[PROJ_CREATE].currentCreationStep
  );
  const stepConfigs = useSelector(
    (s: State) => s[PROJ_CREATE].configCreationSteps
  );
  const stepConfig = stepConfigs[currentStepIndex];

  const { t } = useTranslation('app', {
    keyPrefix: `creation.${currentProjectType}.docs`,
  });

  const [getPhotos] = useLazyGetFilesQuery();
  const [updateFiles] = useCreateFileKeysMutation();
  const [updatePhotos] = useCreateFileKeysMutation();
  const [updateCover] = useSetProjectCoverMutation();

  useEffect(() => {
    if (stepConfigs.length === 0) {
      navigate(paths.creation);
    }
  }, [navigate, stepConfigs]);

  const onPhotosSave = (projectId: string, photos: GetFilesResponse[]) => {
    if (projectId) {
      return updatePhotos({
        entityId: projectId,
        category: FileCategories.PHOTO_BEFORE,
        newFiles: {
          list:
            photos?.map((photo) => ({
              key: photo.key,
              name: photo.name || '',
            })) || [],
        },
      })
        .unwrap()
        .then(() => {
          dispatch(setPhotos({ photos, type: PHOTOS_TYPE.BEFORE }));
          Promise.resolve();
        })
        .catch(() => Promise.reject());
    } else {
      return Promise.reject();
    }
  };

  const onCoverSave = (projectId: string, c: GetFilesResponse) => {
    if (
      projectId &&
      c.project_filesID &&
      c.project_filesID !== photosCover?.project_filesID
    ) {
      updateCover({
        projectId,
        fileId: c.project_filesID,
      })
        .unwrap()
        .then(() => dispatch(setPhotosCover(c)));
    }
  };

  const onFilesSave = (projectId: string, files: GetFilesResponse[]) => {
    if (projectId) {
      updateFiles({
        entityId: projectId,
        category: FileCategories.DESIGN,
        newFiles: {
          list: designs?.map((f) => ({
            key: f.key,
            name: f.name || 'file',
          })),
        },
      })
        .unwrap()
        .then(() => dispatch(setDesigns(files)));
    }
  };

  const uploadDocuments = (projectId: string) => {
    onFilesSave(projectId, designs);

    onPhotosSave(projectId, photosBefore).then(() => {
      if (projectId) {
        setTimeout(() => {
          getPhotos({
            entityId: projectId,
            category: FileCategories.PHOTO_BEFORE,
            limit: PHOTO_LIMIT,
            offset: PHOTO_OFFSET,
          })
            .unwrap()
            .then(({ data }) => {
              if (data.length) {
                dispatch(setPhotos({ photos: data, type: PHOTOS_TYPE.BEFORE }));
                const updatedCover = data.find(
                  (p) => p.key === photosCover?.key
                );
                if (updatedCover) {
                  dispatch(setPhotosCover(updatedCover));
                  onCoverSave(projectId, updatedCover);
                }
              }
            });
        }, REFETCH_TIMEOUT);
      }
    });

    if (photosCover) {
      onCoverSave(projectId, photosCover);
    } else if (photosBefore.length) {
      onCoverSave(projectId, photosBefore[0]);
    }
  };

  const valid = useMemo(() => !!photosBefore.length, [photosBefore]);

  const FilesUploaderWidgetComponent = filesUploaderWidgetsMap.get(
    stepConfig ? stepConfig.type : ''
  );

  return (
    <>
      {FilesUploaderWidgetComponent?.({ stepConfig, t }) ?? null}
      {renderFooter?.({ updateStepData: uploadDocuments, valid }) ?? null}
    </>
  );
};

export default CreationUploadFiles;
