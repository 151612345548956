import {
  Geodata,
  GetProjectResponse,
  Status,
} from '@platform-for-public-places/components-library';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  GetProjectBriefResponse,
  GetProjectInfoResponse,
  ProjectsInfoWithGeodata,
} from 'src/features/project/models';

export const PROJ_LAYER = 'PROJ_LAYER';

export interface ProjectsLayerState {
  currentProject: GetProjectResponse | null;
  currentProjectInfo: GetProjectInfoResponse | null;
  projectsLayerList: Record<string, ProjectsInfoWithGeodata[]>;
  archivedDefaultProjectsList: GetProjectBriefResponse[];
  comments?: number;
  followed?: boolean;
  geodata?: Geodata;
  idea?: string;
  likes?: number;
  liked?: boolean;
  countOfJoined?: number;
  joined?: boolean;
  meetingPoint?: string;
  name?: string;
  status?: Status;
}

const initialState: ProjectsLayerState = {
  currentProject: null,
  currentProjectInfo: null,
  projectsLayerList: {},
  archivedDefaultProjectsList: [],
  comments: 0,
  followed: false,
  idea: '',
  likes: 0,
  liked: false,
  countOfJoined: 0,
  joined: false,
  meetingPoint: '',
  name: '',
};

export const projectsLayerSlice = createSlice({
  name: PROJ_LAYER,
  initialState,
  reducers: {
    setCurrentProjectInfo: (
      state: ProjectsLayerState,
      action: PayloadAction<GetProjectInfoResponse | null>
    ) => {
      state.currentProjectInfo = action.payload;
    },
    setProjectIdea: (
      state: ProjectsLayerState,
      action: PayloadAction<string | undefined>
    ) => {
      state.idea = action.payload;
    },
    setProjectName: (
      state: ProjectsLayerState,
      action: PayloadAction<string | undefined>
    ) => {
      state.name = action.payload;
    },
    setProjectFollowed: (
      state: ProjectsLayerState,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.followed = action.payload;
    },
    setProjectGeodata: (
      state: ProjectsLayerState,
      action: PayloadAction<Geodata | undefined>
    ) => {
      state.geodata = action.payload;
    },
    setProjectMeetingPoint: (
      state: ProjectsLayerState,
      action: PayloadAction<string | undefined>
    ) => {
      state.meetingPoint = action.payload;
    },
    setProjectComments: (
      state: ProjectsLayerState,
      action: PayloadAction<number | undefined>
    ) => {
      state.comments = action.payload;
    },
    setProjectStatus: (
      state: ProjectsLayerState,
      action: PayloadAction<Status | undefined>
    ) => {
      state.status = action.payload;
    },
    changeProjectFollow: (state: ProjectsLayerState) => {
      if (state.currentProject) {
        state.currentProject.followed = !state.currentProject.followed;
      }
    },
    changeProjectLiked: (state: ProjectsLayerState) => {
      if (state.currentProject) {
        state.currentProject.liked = !state.currentProject.liked;
      }
      state.liked = !state.liked;
    },
    changeProjectJoined: (state: ProjectsLayerState) => {
      if (state.currentProject) {
        state.currentProject.joined = !state.currentProject.joined;
      }
      state.joined = !state.joined;
    },
    setProjectLikes: (
      state: ProjectsLayerState,
      action: PayloadAction<number | undefined>
    ) => {
      state.likes = action.payload;
    },
    setProjectLiked: (
      state: ProjectsLayerState,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.liked = action.payload;
    },
    setProjectJoined: (
      state: ProjectsLayerState,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.joined = action.payload;
    },
    setProjectCountOfJoined: (
      state: ProjectsLayerState,
      action: PayloadAction<number | undefined>
    ) => {
      state.countOfJoined = action.payload;
    },
    setDefaultProjects: (
      state: ProjectsLayerState,
      action: PayloadAction<Record<string, ProjectsInfoWithGeodata[]>>
    ) => {
      state.projectsLayerList = action.payload;
    },
    addProjectsByKey: (
      state: ProjectsLayerState,
      action: PayloadAction<{
        reset?: boolean;
        key: string;
        data: ProjectsInfoWithGeodata[];
      }>
    ) => {
      const prevData = state.projectsLayerList[action.payload.key];
      const newState = state.projectsLayerList;

      newState[action.payload.key] =
        prevData && !action.payload.reset
          ? [...prevData, ...action.payload.data]
          : action.payload.data;

      state.projectsLayerList = newState;
    },
    removeProjectsByKey: (
      state: ProjectsLayerState,
      action: PayloadAction<string>
    ) => {
      state.projectsLayerList[action.payload] = [];
    },
    setArchivedDefaultProjects: (
      state: ProjectsLayerState,
      action: PayloadAction<GetProjectBriefResponse[]>
    ) => {
      state.archivedDefaultProjectsList = action.payload;
    },

    setCurrentProject: (
      state: ProjectsLayerState,
      action: PayloadAction<GetProjectResponse | null>
    ) => {
      state.currentProject = action.payload;
    },
    updateCurrentProjectGeodata: (
      state: ProjectsLayerState,
      action: PayloadAction<Geodata>
    ) => {
      if (state.currentProject) {
        state.currentProject.geo_data = action.payload;
      }
    },
    resetLists: (state: ProjectsLayerState) => {
      return {
        ...initialState,
        currentProject: state.currentProject,
      };
    },
    resetProjectInfo: (state: ProjectsLayerState) => {
      return {
        ...initialState,
        projectsLayerList: state.projectsLayerList,
        archivedDefaultProjectsList: state.archivedDefaultProjectsList,
      };
    },
  },
});

export const {
  setProjectLiked,
  setProjectIdea,
  setProjectName,
  setProjectFollowed,
  setProjectJoined,
  setCurrentProjectInfo,
  setProjectLikes,
  setProjectComments,
  setProjectGeodata,
  setProjectMeetingPoint,
  setProjectStatus,
  changeProjectFollow,
  changeProjectLiked,
  changeProjectJoined,
  setProjectCountOfJoined,
  setDefaultProjects,
  addProjectsByKey,
  removeProjectsByKey,
  setCurrentProject,
  updateCurrentProjectGeodata,
  resetLists,
  resetProjectInfo,
} = projectsLayerSlice.actions;

export default projectsLayerSlice.reducer;
