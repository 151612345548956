import { CSSProperties } from 'react';
import { useDispatch } from 'react-redux';

import { GetFilesResponse } from '@platform-for-public-places/components-library';

import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import {
  changeOpenedIndex,
  changePhotos,
} from 'src/features/modal/slices/photosModalSlice';

import './PhotoCarousel.scss';

interface PhotoCarouselProps {
  photos: GetFilesResponse[];
  className?: string;
  reordered?: boolean;
  inref?: (node: HTMLButtonElement) => void;
  style?: CSSProperties | undefined;
}

const PhotoCarousel = ({
  photos,
  className,
  reordered = false,
  inref,
  style,
}: PhotoCarouselProps): JSX.Element => {
  const dispatch = useDispatch();

  const single = photos.length === 1;
  const reorderedCl = reordered ? '--reordered' : '';
  const coverClass = `photo-carousel__cover${reorderedCl}`;
  const singleClass = single ? 'photo-carousel__cover--single' : '';

  const renderPhotos = () => {
    const _photos = [...photos];
    const cover = _photos.shift();
    const content = _photos.map((url, i) => (
      <button
        className="photo-carousel__button photo-carousel__preview"
        key={i + 1}
        onClick={() => {
          dispatch(changeModal(MODALS.PICTURE));
          dispatch(changePhotos(photos));
          dispatch(changeOpenedIndex(i + 1));
        }}
      >
        <img
          key={i}
          src={url.url}
          alt={`Issue ${i + 2}`}
          className="photo-carousel__content"
        />
      </button>
    ));

    return (
      <>
        <button
          ref={inref}
          className={`photo-carousel__button ${coverClass} ${singleClass}`}
          onClick={() => {
            dispatch(changeModal(MODALS.PICTURE));
            dispatch(changePhotos(photos));
            dispatch(changeOpenedIndex(0));
          }}
        >
          <img
            src={cover?.url}
            alt="Issue 1"
            className="photo-carousel__content"
          />
        </button>
        {!single && (
          <div className={`photo-carousel__block--preview${reorderedCl}`}>
            {content}
          </div>
        )}
      </>
    );
  };

  const renderStubs = () => (
    <>
      <div className={`photo-carousel__stub  ${coverClass} ${singleClass}`} />
      {!single && (
        <div className={`photo-carousel__block--preview${reorderedCl}`}>
          <div className="photo-carousel__stub photo-carousel__preview" />
          <div className="photo-carousel__stub photo-carousel__preview" />
          <div className="photo-carousel__stub photo-carousel__preview" />
        </div>
      )}
    </>
  );

  const render = photos.length ? renderPhotos : renderStubs;

  return (
    <div className={`photo-carousel${reorderedCl} ${className}`} style={style}>
      {render()}
    </div>
  );
};

export default PhotoCarousel;
