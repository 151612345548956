import { ReactElement } from 'react';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import './Typography.scss';
type TypographyProps = {
  className?: string;
  children?: string | ReactElement | ReactElement[];
  type: 'body2' | 'body3' | 'heading-h5' | 'h5' | 'heading-h6';
};

const Typography = ({ className, children, type }: TypographyProps) => {
  const isSmallScreen = useCheckSmallScreen();
  return (
    <p
      className={`${className ?? ''} typography ${
        isSmallScreen ? 'mobile-' : ''
      }typography__${type}`}
    >
      {children}
    </p>
  );
};

export default Typography;
