import DatesWidget, { DatesWidgetProps } from './DatesWidget/DatesWidget';

export const getDateWithTime = (date: Date, time: string) => {
  const tempDate: Date = date;
  if (time) {
    tempDate.setHours(Number(time.substring(0, 2)));
    tempDate.setMinutes(Number(time.substring(3, 5)));
  }
  return tempDate;
};

export const datesWidgetsMap = new Map<
  string,
  (props: DatesWidgetProps) => JSX.Element
>([['datesWidget', DatesWidget]]);
