import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  ActorBaseResponse,
  BaseResponse,
  GetProjectResponse,
  PROJECT_STATUS,
  UserProfile,
} from '@platform-for-public-places/components-library';
import L from 'leaflet';

import {
  useLazyGetFilesQuery,
  useLazyGetProjectCoverQuery,
} from 'src/features/files/api';
import { FileCategories } from 'src/features/files/enums';
import { SPECIFIC_LAYER } from 'src/features/project/models';
import { setTeam } from 'src/features/searchWithFilter/filter/slices/filterSliceData';

import { useLazyWhoamiQuery, useWhoamiQuery } from './auth/api';
import { useLazyGetImagesPresignedUrlsQuery } from './minIo/api';
import { useLazyGetProfileQuery } from './user/api';

import { AUTH, changeSignInSuccess } from './auth/slices/authSlice';
import { resetCreation } from './project/slices/creatingProjectSlice';

import { USER_ROLE } from './auth/models';

import { State } from './store/store';

interface GetProfileResponse extends BaseResponse {
  data: ActorBaseResponse<UserProfile>;
}

interface Condition {
  role?: USER_ROLE;
  userId?: string;
}

const PHOTO_LIMIT = 1;
const PHOTO_OFFSET = 0;

export const useCheckIfUserAuthorized = (onSuccess: () => void) => {
  const dispatch = useDispatch();
  const [whoami, { isError, isSuccess }] = useLazyWhoamiQuery();

  const signInSuccess = useSelector((s: State) => s[AUTH].signInSuccess);

  useEffect(() => {
    if (isError && !isSuccess && signInSuccess) {
      dispatch(changeSignInSuccess(false));
      onSuccess();
    }
  }, [signInSuccess, dispatch, onSuccess, isError, isSuccess]);

  return () =>
    whoami().then((resp) => {
      if (resp.isSuccess) {
        onSuccess();
      }
      return resp;
    });
};

export const useClearCreation = () => {
  const dispatch = useDispatch();
  const clearCreation = () => {
    dispatch(resetCreation());
    dispatch(setTeam([]));
  };

  return clearCreation;
};

export const useCheckUser = (
  conditions: Condition[],
  spareFirstCheck = false
) => {
  const whoami = useWhoamiQuery();
  const [isValid, setValid] = useState<boolean>(spareFirstCheck);

  useEffect(() => {
    if (whoami.data) {
      setValid(
        conditions.some(
          (condition) =>
            (!condition.role || condition.role === whoami.data?.data.role) &&
            (!condition.userId || condition.userId === whoami.data?.data.userID)
        )
      );
    } else if (whoami.isError) {
      setValid(false);
    }
  }, [conditions, whoami.data, whoami.isError]);

  return isValid;
};

export const useUpdateProfile = (): [
  (arg0: string) => void,
  GetProfileResponse | undefined
] => {
  const [getProfile] = useLazyGetProfileQuery();
  const [getFile] = useLazyGetImagesPresignedUrlsQuery();
  const [profile, setProfile] = useState<GetProfileResponse>();

  const trigger = useCallback(
    (id: string) => {
      getProfile(id).then((prof) => {
        const profileData = prof as GetProfileResponse;
        if (profileData?.data?.data?.portfolio?.link) {
          setProfile(profileData);
        } else {
          getFile({
            keys: [profileData?.data?.data?.portfolio?.key ?? ''],
          }).then((file) => {
            const prof = profileData as GetProfileResponse;
            if (profileData !== undefined) {
              const prs: GetProfileResponse = {
                data: {
                  errors: prof.data.errors,
                  success: prof.data.success,
                  data: {
                    ...prof.data.data,
                    portfolio: {
                      name: prof.data.data.portfolio?.name,
                      key: prof.data.data.portfolio?.key,
                      link: file.data?.data[0].url,
                    },
                  },
                },
                isError: prof.isError,
                isSuccess: prof.isSuccess,
                isFetching: prof.isFetching,
              };
              setProfile(prs);
            }
          });
        }
      });
    },
    [getFile, getProfile]
  );

  return [trigger, profile];
};

export const useTranslationStatus = (
  projectType: string = SPECIFIC_LAYER.DEFAULT
) => {
  const { t } = useTranslation('app', {
    keyPrefix: `cards.overview.projectStatus`,
  });
  return (status?: PROJECT_STATUS) =>
    status ? t(`${projectType}.${status}`) : '';
};

export const useUserCardTranslation = () => {
  const { t } = useTranslation('app', {
    keyPrefix: 'userCatalog',
  });
  return {
    companyName: t('companyName'),
    contacts: t('contacts'),
    projects: t('projects'),
    aboutMe: t('aboutMe'),
    portfolio: t('portfolio'),
    noProject: t('noProject'),
    tags: {
      developer: t('tags.developer'),
      implementer: t('tags.implementer'),
    },
  };
};

export const useGetProjectsWithImages = () => {
  const [getProjectFiles] = useLazyGetFilesQuery();
  const [getProjectCover] = useLazyGetProjectCoverQuery();

  const trigger = useCallback(
    (projectData: GetProjectResponse[]) => {
      const modifiedArray = projectData.map(async (project) => {
        const projectId = project.projectId as string;
        const photos =
          projectId &&
          getProjectFiles({
            entityId: projectId,
            category: FileCategories.PHOTO_BEFORE,
            limit: PHOTO_LIMIT,
            offset: PHOTO_OFFSET,
          }).then((result) => result.data?.data);
        const cover =
          projectId &&
          getProjectCover({ projectId }).then((result) => result.data?.data);
        return Promise.all([photos, cover]).then((result) => {
          return Promise.resolve({
            ...project,
            photosCover: result[1],
            photos: result[0],
          });
        });
      });
      return Promise.all(modifiedArray);
    },
    [getProjectCover, getProjectFiles]
  );

  return [trigger];
};

export const useLockBodyScroll = (lock: boolean) => {
  useEffect(() => {
    if (lock) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [lock]);
};

export const useCheckPkkAccessability = () => {
  fetch('https://pkk.rosreestr.ru/')
    .then(
      () => {
        // resolve
        sessionStorage.setItem('pkkAccessability', 'true');
      },
      () => {
        // reject
        sessionStorage.setItem('pkkAccessability', 'false');
      }
    )
    .catch(() => {
      sessionStorage.setItem('pkkAccessability', 'false');
    });
};

export const useDisablePropagationRef = () => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      L.DomEvent.disableClickPropagation(ref.current).disableScrollPropagation(
        ref.current
      );
    }
  });

  return ref;
};
