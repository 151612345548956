export enum REQUEST_STATUS {
  EXECUTED = 'EXECUTED',
  ACTIVE = 'ACTIVE',
  REJECTED = 'REJECTED',
}

export enum REQUEST_TYPE {
  SPECIALIST = 'CHANGE-PROFILE-AND-ADD-TO-SPECIALIST-CATALOG',
  COMMENT = 'COMMENT',
  PROJECT_SPECIALIST = 'PROJECT_SPECIALIST',
  PROJECT_PARTICIPANTS = 'PROJECT_PARTICIPANTS',
}
