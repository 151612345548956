import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Circles } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import {
  Error as ActorError,
  NOTIFICATION_PAYLOAD,
  NOTIFICATION_TYPE,
  NotificationPopup,
  SORTING_ORDER_DIRECTION,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import {
  NOTIFICATION,
  setVisible,
} from 'src/features/notification/slices/notificationSlice';
import {
  useLazyGetRecurrentUserPaymentsQuery,
  useStopSupportingRecurrentPaymentMutation,
} from 'src/features/payment/api';
import {
  RECURRENT_PAYMENT_STATUS,
  RecurrentUserPaymentData,
  SORTING_RECURRENT_USER_PAYMENTS_ORDER_BY,
} from 'src/features/payment/models';
import { State } from 'src/features/store/store';

import RecurrentUserPaymentsList from './components/RecurrentUserPaymentsList/RecurrentUserPaymentsList';
import RecurrentUserPaymentsTable from './components/RecurrentUserPaymentsTable/RecurrentUserPaymentsTable';

import './RecurrentUserPaymentsInfo.scss';

const PAGE_SIZE_DESKTOP = 20;
const PAGE_SIZE_MOBILE = 10;

const TOKEN_SEARCH_PARAM = 'token';

const NOTIFICATION_TIMEOUT = 4000;

export interface RecurrentUserPaymentsInfoProps {
  className?: string;
}

const RecurrentUserPaymentsInfo = ({
  className = '',
}: RecurrentUserPaymentsInfoProps) => {
  const isSmallScreen = useCheckSmallScreen();

  const [searchParams] = useSearchParams();
  const token = searchParams.get(TOKEN_SEARCH_PARAM);

  const dispatch = useDispatch();
  const { t } = useTranslation('app', {
    keyPrefix: `profile.payments.recurrent`,
  });

  const [recurrentUserPaymentsPage, setRecurrentUserPaymentsPage] =
    useState<number>(1);
  const [hasNextRecurrentPaymentsPage, setHasNextRecurrentPaymentsPage] =
    useState<boolean>(true);
  const [recurrentUserPayments, setRecurrentUserPayment] = useState<
    RecurrentUserPaymentData[]
  >([]);

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [orderBy, setOrderBy] =
    useState<SORTING_RECURRENT_USER_PAYMENTS_ORDER_BY>(
      SORTING_RECURRENT_USER_PAYMENTS_ORDER_BY.STATUS
    );
  const [orderDirection, setOrderDirection] = useState<SORTING_ORDER_DIRECTION>(
    SORTING_ORDER_DIRECTION.ASC
  );

  const [notification, setNotification] = useState<NOTIFICATION_PAYLOAD>({
    message: '',
    type: NOTIFICATION_TYPE.NOTIFICATION,
  });

  const isVisible = useSelector((s: State) => s[NOTIFICATION].isVisible);

  const [
    getRecurrentUserPayments,
    { isUninitialized: isUninitializedGetRecurrentUserPayments },
  ] = useLazyGetRecurrentUserPaymentsQuery();

  const [stopSupportingRecurrentPayments] =
    useStopSupportingRecurrentPaymentMutation();

  const showNotification = useCallback(
    (
      notificationText: string | React.ReactElement,
      notificationType: NOTIFICATION_TYPE
    ) => {
      setNotification({
        message: notificationText,
        type: notificationType,
      });
      dispatch(setVisible(true));
    },
    [dispatch]
  );

  const onStopSupportActionSuccess = useCallback(
    (projectName: string) => {
      showNotification(
        t(`notifications.stopSupport.success`, { projectName }),
        NOTIFICATION_TYPE.NOTIFICATION
      );
    },
    [showNotification, t]
  );

  const onStopSupportActionError = useCallback(() => {
    showNotification(
      t(`notifications.stopSupport.failed`),
      NOTIFICATION_TYPE.ERROR
    );
  }, [showNotification, t]);

  const onTokenExpiredActionError = useCallback(() => {
    showNotification(
      t(`notifications.getRecurrentPayments.failed`),
      NOTIFICATION_TYPE.ERROR
    );
  }, [showNotification, t]);

  const changeSortDirection = (src: string) => {
    if (orderBy !== src) {
      setOrderBy(src as SORTING_RECURRENT_USER_PAYMENTS_ORDER_BY);
      setOrderDirection(SORTING_ORDER_DIRECTION.ASC);
    } else {
      if (orderDirection === SORTING_ORDER_DIRECTION.ASC) {
        setOrderDirection(SORTING_ORDER_DIRECTION.DESC);
      } else {
        setOrderDirection(SORTING_ORDER_DIRECTION.ASC);
      }
    }
    resetScroll();
  };

  const stopSupportPayment = (paymentId: string, projectName: string) => {
    stopSupportingRecurrentPayments({ recurrentPaymentsId: paymentId, token })
      .unwrap()
      .then(({ success }) => {
        if (success) {
          onStopSupportActionSuccess(projectName);
          setRecurrentUserPayment((prevPayments) =>
            prevPayments.map((payment) =>
              payment.recurrent_paymentsID === paymentId
                ? { ...payment, status: RECURRENT_PAYMENT_STATUS.NOT_ACTIVE }
                : payment
            )
          );
        } else {
          onStopSupportActionError();
        }
      })
      .catch(() => onStopSupportActionError());
  };

  const resetScroll = () => {
    setError(false);
    setLoading(false);

    setHasNextRecurrentPaymentsPage(true);
    setRecurrentUserPaymentsPage(1);
    setRecurrentUserPayment([]);
  };
  const hideNotification = () => dispatch(setVisible(false));

  const [getRecurrentUserPaymentsByPages] = useInfiniteScroll({
    loading,
    hasNextPage: hasNextRecurrentPaymentsPage,
    onLoadMore: () => {
      setLoading(true);
      const pageSize = isSmallScreen ? PAGE_SIZE_MOBILE : PAGE_SIZE_DESKTOP;
      getRecurrentUserPayments({
        token,
        searchString: '%%',
        page: recurrentUserPaymentsPage,
        pageSize,
        orderBy,
        orderDirection,
      })
        .unwrap()
        .then(({ data }) => {
          setRecurrentUserPayment((prev) => [
            ...prev,
            ...(data.recurrentPayments ?? []),
          ]);
          setRecurrentUserPaymentsPage((prev) => prev + 1);
          setHasNextRecurrentPaymentsPage(
            data.recurrentPayments.length === pageSize
          );
        })
        .catch((err: ActorError) => {
          if (err.status === 403) {
            onTokenExpiredActionError();
          }
          setError(true);
        })
        .finally(() => setLoading(false));
    },
    disabled: error,
  });

  const renderTable = () => (
    <RecurrentUserPaymentsTable
      recurrentUserPayments={recurrentUserPayments}
      changeSortDirection={changeSortDirection}
      stopSupportPayment={stopSupportPayment}
      showPlaceholder={!loading && !hasNextRecurrentPaymentsPage}
    />
  );

  const renderList = () => (
    <RecurrentUserPaymentsList
      recurrentUserPayments={recurrentUserPayments}
      action={stopSupportPayment}
    />
  );

  const renderPayments = () => (isSmallScreen ? renderList() : renderTable());

  return (
    <div className={`recurrent-user-payments-info ${className}`}>
      {loading ? (
        <Circles
          wrapperClass={`recurrent-user-payments-info__loader recurrent-user-payments-info__loader_${
            isSmallScreen ? 'mobile' : 'desktop'
          }`}
        />
      ) : null}
      {isUninitializedGetRecurrentUserPayments ? null : renderPayments()}
      {hasNextRecurrentPaymentsPage ? (
        <div
          className="all-user-payments-info__loading-trigger"
          ref={getRecurrentUserPaymentsByPages}
        />
      ) : null}
      <NotificationPopup
        type={notification.type}
        notificationTimeout={NOTIFICATION_TIMEOUT}
        isVisible={isVisible}
        onTimeoutFinished={hideNotification}
        className="recurrent-user-payments-info__notification"
      >
        {notification.message}
      </NotificationPopup>
    </div>
  );
};

export default RecurrentUserPaymentsInfo;
