import {
  useCreateGeodataMutation as mockUseCreateGeodataMutation,
  useCreateProjectTimelineMutation as mockUseCreateProjectTimelineMutation,
  useDeleteProjectSpecialistMutation as mockUseDeleteProjectSpecialistMutation,
  useGetAllParticipantsQuery as mockUseGetAllParticipantsQuery,
  useGetConfirmedParticipantsInfoQuery as mockUseGetConfirmedParticipantsInfoQuery,
  useGetConfirmedParticipantsQuery as mockUseGetConfirmedParticipantsQuery,
  useGetEventParticipantsInfoQuery as mockUseGetEventParticipantsInfoQuery,
  useGetEventParticipantsQuery as mockUseGetEventParticipantsQuery,
  useGetProjectByIdQuery as mockUseGetProjectByIdQuery,
  useGetProjectCommentsQuery as mockUseGetProjectCommentsQuery,
  useGetProjectDescriptionQuery as mockUseGetProjectDescriptionQuery,
  useGetProjectGeodataQuery as mockUseGetProjectGeodataQuery,
  useGetProjectIncomingTeamQuery as mockUseGetProjectIncomingTeamQuery,
  useGetProjectInfoByIdQuery as mockUseGetProjectInfoByIdQuery,
  useGetProjectOutgoingTeamQuery as mockUseGetProjectOutgoingTeamQuery,
  useGetProjectsCategoriesCountQuery as mockUseGetProjectsCategoriesCountQuery,
  useGetProjectsQuery as mockUseGetProjectsQuery,
  useGetProjectSubscriptionQuery as mockUseGetProjectSubscriptionQuery,
  useGetProjectTeamQuery as mockUseGetProjectTeamQuery,
  useGetProjectTimelineQuery as mockUseGetProjectTimelineQuery,
  useGetProjectTypesQuery as mockUseGetProjectTypesQuery,
  useLazyCheckUserIsMemberOfProjectQuery as mockUseLazyCheckUserIsMemberOfProjectQuery,
  useLazyCreateDescriptionQuery as mockUseLazyCreateDescriptionQuery,
  useLazyCreateProjectQuery as mockUseLazyCreateProjectQuery,
  useLazyCreateProjectSpecialistQuery as mockUseLazyCreateProjectSpecialistQuery,
  useLazyGetAllParticipantsQuery as mockUseLazyGetAllParticipantsQuery,
  useLazyGetConfirmedParticipantsQuery as mockUseLazyGetConfirmedParticipantsQuery,
  useLazyGetEventParticipantsQuery as mockUseLazyGetEventParticipantsQuery,
  useLazyGetProjectByIdQuery as mockUseLazyGetProjectByIdQuery,
  useLazyGetProjectIncomingTeamQuery as mockUseLazyGetProjectIncomingTeamQuery,
  useLazyGetProjectInfoByIdQuery as mockUseLazyGetProjectInfoByIdQuery,
  useLazyGetProjectOutgoingTeamQuery as mockUseLazyGetProjectOutgoingTeamQuery,
  useLazyGetProjectsBySpecialistQuery as mockUseLazyGetProjectsBySpecialistQuery,
  useLazyGetProjectsCatalogInfoQuery as mockUseLazyGetProjectsCatalogInfoQuery,
  useLazyGetProjectsInfoWithGeodataQuery as mockUseLazyGetProjectsInfoWithGeodataQuery,
  useLazyGetProjectsQuery as mockUseLazyGetProjectsQuery,
  useLazyGetProjectStepsQuery as mockUseLazyGetProjectStepsQuery,
  useLazyGetProjectTeamQuery as mockUseLazyGetProjectTeamQuery,
  useLazyGetProjectTimelineQuery as mockUseLazyGetProjectTimelineQuery,
  useLazyGetUserProjectsQuery as mockUseLazyGetUserProjectsQuery,
  useResetSubscriptionsMutation as mockUseResetSubscriptionsMutation,
  useUpdateConfirmedParticipantsMutation as mockUseUpdateConfirmedParticipantsMutation,
} from './mockProjectApi';
import {
  useCreateGeodataMutation as realUseCreateGeodataMutation,
  useCreateProjectTimelineMutation as realUseCreateProjectTimelineMutation,
  useDeleteProjectSpecialistMutation as realUseDeleteProjectSpecialistMutation,
  useGetAllParticipantsQuery as realUseGetAllParticipantsQuery,
  useGetConfirmedParticipantsInfoQuery as realUseGetConfirmedParticipantsInfoQuery,
  useGetConfirmedParticipantsQuery as realUseGetConfirmedParticipantsQuery,
  useGetEventParticipantsInfoQuery as realUseGetEventParticipantsInfoQuery,
  useGetEventParticipantsQuery as realUseGetEventParticipantsQuery,
  useGetProjectByIdQuery as realUseGetProjectByIdQuery,
  useGetProjectCommentsQuery as realUseGetProjectCommentsQuery,
  useGetProjectDescriptionQuery as realUseGetProjectDescriptionQuery,
  useGetProjectGeodataQuery as realUseGetProjectGeodataQuery,
  useGetProjectIncomingTeamQuery as realUseGetProjectIncomingTeamQuery,
  useGetProjectInfoByIdQuery as realUseGetProjectInfoByIdQuery,
  useGetProjectOutgoingTeamQuery as realUseGetProjectOutgoingTeamQuery,
  useGetProjectsCategoriesCountQuery as realUseGetProjectsCategoriesCountQuery,
  useGetProjectsQuery as realUseGetProjectsQuery,
  useGetProjectSubscriptionQuery as realUseGetProjectSubscriptionQuery,
  useGetProjectTeamQuery as realUseGetProjectTeamQuery,
  useGetProjectTimelineQuery as realUseGetProjectTimelineQuery,
  useGetProjectTypesQuery as realUseGetProjectTypesQuery,
  useLazyCheckUserIsMemberOfProjectQuery as realUseLazyCheckUserIsMemberOfProjectQuery,
  useLazyCreateDescriptionQuery as realUseLazyCreateDescriptionQuery,
  useLazyCreateProjectQuery as realUseLazyCreateProjectQuery,
  useLazyCreateProjectSpecialistQuery as realUseLazyCreateProjectSpecialistQuery,
  useLazyGetAllParticipantsQuery as realUseLazyGetAllParticipantsQuery,
  useLazyGetConfirmedParticipantsQuery as realUseLazyGetConfirmedParticipantsQuery,
  useLazyGetEventParticipantsQuery as realUseLazyGetEventParticipantsQuery,
  useLazyGetProjectByIdQuery as realUseLazyGetProjectByIdQuery,
  useLazyGetProjectIncomingTeamQuery as realUseLazyGetProjectIncomingTeamQuery,
  useLazyGetProjectInfoByIdQuery as realUseLazyGetProjectInfoByIdQuery,
  useLazyGetProjectOutgoingTeamQuery as realUseLazyGetProjectOutgoingTeamQuery,
  useLazyGetProjectsBySpecialistQuery as realUseLazyGetProjectsBySpecialistQuery,
  useLazyGetProjectsCatalogInfoQuery as realUseLazyGetProjectsCatalogInfoQuery,
  useLazyGetProjectsInfoWithGeodataQuery as realUseLazyGetProjectsInfoWithGeodataQuery,
  useLazyGetProjectsQuery as realUseLazyGetProjectsQuery,
  useLazyGetProjectStatusesByTypeQuery as realUseLazyGetProjectStatusesByTypeQuery,
  useLazyGetProjectStepsQuery as realUseLazyGetProjectStepsQuery,
  useLazyGetProjectTeamQuery as realUseLazyGetProjectTeamQuery,
  useLazyGetProjectTimelineQuery as realUseLazyGetProjectTimelineQuery,
  useLazyGetUserProjectsQuery as realUseLazyGetUserProjectsQuery,
  useResetSubscriptionsMutation as realUseResetSubscriptionsMutation,
  useUpdateConfirmedParticipantsMutation as realUseUpdateConfirmedParticipantsMutation,
} from './projectApi';

export const useLazyCreateProjectQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyCreateProjectQuery
    : realUseLazyCreateProjectQuery;
export const useLazyCreateDescriptionQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyCreateDescriptionQuery
    : realUseLazyCreateDescriptionQuery;
export const useCreateGeodataMutation =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseCreateGeodataMutation
    : realUseCreateGeodataMutation;
export const useCreateProjectTimelineMutation =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseCreateProjectTimelineMutation
    : realUseCreateProjectTimelineMutation;
export const useGetProjectTimelineQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetProjectTimelineQuery
    : realUseGetProjectTimelineQuery;
export const useLazyGetProjectTimelineQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetProjectTimelineQuery
    : realUseLazyGetProjectTimelineQuery;
export const useLazyCreateProjectSpecialistQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyCreateProjectSpecialistQuery
    : realUseLazyCreateProjectSpecialistQuery;
export const useLazyCheckUserIsMemberOfProjectQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyCheckUserIsMemberOfProjectQuery
    : realUseLazyCheckUserIsMemberOfProjectQuery;
export const useGetProjectTeamQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetProjectTeamQuery
    : realUseGetProjectTeamQuery;
export const useLazyGetProjectTeamQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetProjectTeamQuery
    : realUseLazyGetProjectTeamQuery;
export const useGetProjectIncomingTeamQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetProjectIncomingTeamQuery
    : realUseGetProjectIncomingTeamQuery;
export const useLazyGetProjectIncomingTeamQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetProjectIncomingTeamQuery
    : realUseLazyGetProjectIncomingTeamQuery;
export const useGetProjectOutgoingTeamQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetProjectOutgoingTeamQuery
    : realUseGetProjectOutgoingTeamQuery;
export const useLazyGetProjectOutgoingTeamQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetProjectOutgoingTeamQuery
    : realUseLazyGetProjectOutgoingTeamQuery;
export const useDeleteProjectSpecialistMutation =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseDeleteProjectSpecialistMutation
    : realUseDeleteProjectSpecialistMutation;
export const useLazyGetProjectByIdQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetProjectByIdQuery
    : realUseLazyGetProjectByIdQuery;
export const useGetProjectByIdQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetProjectByIdQuery
    : realUseGetProjectByIdQuery;
export const useGetProjectsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetProjectsQuery
    : realUseGetProjectsQuery;
export const useLazyGetProjectsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetProjectsQuery
    : realUseLazyGetProjectsQuery;
export const useGetProjectsCategoriesCountQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetProjectsCategoriesCountQuery
    : realUseGetProjectsCategoriesCountQuery;
export const useLazyGetProjectsCatalogInfoQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetProjectsCatalogInfoQuery
    : realUseLazyGetProjectsCatalogInfoQuery;
export const useLazyGetProjectsBySpecialistQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetProjectsBySpecialistQuery
    : realUseLazyGetProjectsBySpecialistQuery;
export const useLazyGetUserProjectsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetUserProjectsQuery
    : realUseLazyGetUserProjectsQuery;
export const useResetSubscriptionsMutation =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseResetSubscriptionsMutation
    : realUseResetSubscriptionsMutation;
export const useGetProjectTypesQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetProjectTypesQuery
    : realUseGetProjectTypesQuery;
export const useLazyGetProjectStepsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetProjectStepsQuery
    : realUseLazyGetProjectStepsQuery;
export const useGetProjectDescriptionQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetProjectDescriptionQuery
    : realUseGetProjectDescriptionQuery;
export const useGetProjectGeodataQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetProjectGeodataQuery
    : realUseGetProjectGeodataQuery;
export const useGetProjectCommentsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetProjectCommentsQuery
    : realUseGetProjectCommentsQuery;
export const useGetProjectInfoByIdQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetProjectInfoByIdQuery
    : realUseGetProjectInfoByIdQuery;
export const useGetProjectSubscriptionQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetProjectSubscriptionQuery
    : realUseGetProjectSubscriptionQuery;
export const useLazyGetProjectInfoByIdQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetProjectInfoByIdQuery
    : realUseLazyGetProjectInfoByIdQuery;
export const useGetEventParticipantsInfoQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetEventParticipantsInfoQuery
    : realUseGetEventParticipantsInfoQuery;
export const useGetConfirmedParticipantsInfoQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetConfirmedParticipantsInfoQuery
    : realUseGetConfirmedParticipantsInfoQuery;
export const useGetEventParticipantsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetEventParticipantsQuery
    : realUseGetEventParticipantsQuery;
export const useLazyGetEventParticipantsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetEventParticipantsQuery
    : realUseLazyGetEventParticipantsQuery;
export const useGetConfirmedParticipantsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetConfirmedParticipantsQuery
    : realUseGetConfirmedParticipantsQuery;
export const useLazyGetConfirmedParticipantsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetConfirmedParticipantsQuery
    : realUseLazyGetConfirmedParticipantsQuery;
export const useGetAllParticipantsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetAllParticipantsQuery
    : realUseGetAllParticipantsQuery;
export const useLazyGetAllParticipantsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetAllParticipantsQuery
    : realUseLazyGetAllParticipantsQuery;
export const useUpdateConfirmedParticipantsMutation =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseUpdateConfirmedParticipantsMutation
    : realUseUpdateConfirmedParticipantsMutation;

export const useLazyGetProjectStatusesByTypeQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? realUseLazyGetProjectStatusesByTypeQuery
    : realUseLazyGetProjectStatusesByTypeQuery;

export const useLazyGetProjectsInfoWithGeodataQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetProjectsInfoWithGeodataQuery
    : realUseLazyGetProjectsInfoWithGeodataQuery;
