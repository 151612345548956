import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  TextInput,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';
import { useLocalStorage } from '@uidotdev/usehooks';

import PasswordInput from 'src/shared/components/inputs/PasswordInput/PasswordInput';
import { paths } from 'src/shared/routes';

import { useLazySignInQuery } from 'src/features/auth/api';
import { changeSignInSuccess } from 'src/features/auth/slices/authSlice';
import {
  COOKIE,
  setAnimationActive,
  setShowCookieCard,
} from 'src/features/cookies/slices/cookieSlice';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';

import './SignIn.scss';

interface SignInProps {
  strict?: boolean;
  onSuccess?: () => void;
}

const SignIn = ({ strict, onSuccess }: SignInProps): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'signIn' });

  const [allowedCookie] = useLocalStorage<boolean>(COOKIE);

  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [password, setPassword] = useState('');

  const [signIn] = useLazySignInQuery();

  const successSignInDesktop = () => {
    dispatch(changeSignInSuccess(true));
    dispatch(changeModal(null));
    // Перемещение в конец стека вызовов,
    // чтобы навигация на flow создания проекта успела сработать
    setTimeout(() => document.location.reload(), 1);
  };

  const successSignInMobile = () => {
    dispatch(changeSignInSuccess(true));
    navigate(paths.map, { replace: true });
  };

  const navigateSignUp = () => {
    isSmallScreen
      ? navigate(paths.signUp, { replace: true })
      : dispatch(changeModal(MODALS.SIGN_UP));
  };

  const navigateForgotPassword = () => {
    isSmallScreen
      ? navigate(paths.forgotPassword, { replace: true })
      : dispatch(changeModal(MODALS.FORGOT_PASSWORD));
  };

  const onChangeEmail = (value: string) => {
    if (error) {
      setError(false);
    }
    setEmail(value);
  };

  const onChangePassword = (value: string) => {
    if (error) {
      setError(false);
    }
    setPassword(value);
  };

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (allowedCookie) {
      if (email && password) {
        signIn({ email: email.trim(), password }).then((result) => {
          if (result.isSuccess) {
            if (onSuccess) {
              onSuccess();
            } else {
              isSmallScreen ? successSignInMobile() : successSignInDesktop();
            }
          } else if (result.isError) {
            setError(true);
          }
        });
      }
    } else {
      dispatch(setShowCookieCard(true));
      dispatch(setAnimationActive(true));
    }
  };

  const dynamicClass = (s: TemplateStringsArray, modifier: string) => {
    const m = modifier ? `${s[0]}--${modifier}` : '';
    const base = `${s[0]} ${m}`;
    return error ? `${base} ${s[0]}--error` : base;
  };

  return (
    <div className="sign-in">
      {strict ? null : (
        <div className="sign-in__no-account">
          <span className="sign-in__no-account-label">
            {t('redirectLabel')}
          </span>
          <Button
            type={BUTTON_TYPE.TEXT}
            className="sign-in__no-account-link"
            onClick={navigateSignUp}
          >
            {t('buttons.navSignUp')}
          </Button>
        </div>
      )}

      <form className="sign-in__form" onSubmit={onFormSubmit}>
        <TextInput
          value={email}
          className={dynamicClass`sign-in__input${'email'}`}
          placeholder={t('inputs.email')}
          onChange={(e) => onChangeEmail(e.target.value)}
        />

        <PasswordInput
          value={password}
          className={dynamicClass`sign-in__input${'password'}`}
          placeholder={t('inputs.password')}
          onChange={(e) => onChangePassword(e.target.value)}
        />

        <div className={`sign-in__error-container${error ? '--visible' : ''}`}>
          <p className="sign-in__error-message">{t('error')}</p>
        </div>

        <div className="sign-in__button-container">
          <Button
            onClick={navigateForgotPassword}
            className="sign-in__forgot-password-button"
            type={BUTTON_TYPE.ACCENTED_OUTLINE}
          >
            {t('buttons.forgotPassword')}
          </Button>
          <Button
            submit
            disabled={!email || !password}
            className="auth-button sign-in__button"
            type={BUTTON_TYPE.ACCENTED}
          >
            {t('buttons.signIn')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SignIn;
