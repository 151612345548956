import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DrawnShape } from '../models';

export const DRAW = 'DRAW';

export interface DrawState {
  isDrawEnable: boolean;
  showInformation: boolean;
  needClear: boolean;
  drawnShape: DrawnShape | null;
  drawHint: string;
}

const initialState: DrawState = {
  isDrawEnable: false,
  showInformation: false,
  needClear: false,
  drawnShape: null,
  drawHint: '',
};

export const drawSlice = createSlice({
  name: DRAW,
  initialState,
  reducers: {
    changeDrawEnable: (state: DrawState, action: PayloadAction<boolean>) => {
      state.isDrawEnable = action.payload;
    },
    changeShowInformation: (
      state: DrawState,
      action: PayloadAction<boolean>
    ) => {
      state.showInformation = action.payload;
    },
    finishDraw: (state: DrawState, action: PayloadAction<DrawnShape>) => {
      state.isDrawEnable = false;
      state.drawnShape = action.payload;
    },
    setDraw: (state: DrawState, action: PayloadAction<DrawnShape>) => {
      state.drawnShape = action.payload;
    },
    resetDraw: (state: DrawState) => {
      state.drawnShape = null;
    },
    changeNeedClear: (state: DrawState) => {
      state.needClear = !state.needClear;
    },
    changeDrawHint: (state: DrawState, action: PayloadAction<string>) => {
      state.drawHint = action.payload;
    },
  },
});

export const {
  setDraw,
  resetDraw,
  finishDraw,
  changeDrawHint,
  changeNeedClear,
  changeDrawEnable,
  changeShowInformation,
} = drawSlice.actions;

export default drawSlice.reducer;
