import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  TextArea,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import MobileHeader from 'src/features/mobile/components/MobileHeader/MobileHeader';
import SignInModal from 'src/features/modal/components/SignInModal/SignInModal';
import { BackdoorPayload, BypassPayload } from 'src/features/modal/models';
import { useLazyRejectRequestByIdRequestQuery } from 'src/features/request/api';

import image from './img/deny-comment.svg';

import './Deny.scss';

const MAX_MESSAGE_SIZE = 500;

const Deny = () => {
  const navigate = useNavigate();
  const { requestId } = useParams();
  const isSmallScreen = useCheckSmallScreen();
  const { pathname, search, state } = useLocation();
  const { t } = useTranslation('app', { keyPrefix: 'deny' });

  const { isSuccess, isLoading } = useWhoamiQuery();
  const [reject] = useLazyRejectRequestByIdRequestQuery();

  const [message, setMessage] = useState<string>('');
  const [modal, setModal] = useState<boolean>(false);

  const sendMessage = () => {
    if (requestId) {
      reject({ requestId, message }).then(() => navigate(paths.map));
    }
  };

  const reload = () => document.location.reload();

  useEffect(() => {
    if (requestId && !isLoading && !isSuccess) {
      if (isSmallScreen) {
        const _state = state as BypassPayload | undefined;
        const payload: BackdoorPayload = { backdoor: `${pathname}${search}` };
        if (!_state?.bypass) {
          navigate(paths.signIn, { state: payload, replace: true });
        }
      } else {
        setModal(true);
      }
    }
  }, [
    navigate,
    isLoading,
    isSmallScreen,
    isSuccess,
    requestId,
    pathname,
    search,
    state,
  ]);

  return (
    <div className="deny">
      {isSmallScreen ? (
        <>
          <div className="deny__header">
            <MobileHeader title={t('title')} />
          </div>
          <h1 className="deny__title">{t('title')}</h1>
          <p className="deny__text">{t('text')}</p>
        </>
      ) : (
        <>
          <h1 className="deny__title">{t('title')}</h1>
          <p className="deny__text">{t('text')}</p>
        </>
      )}
      <TextArea
        className="deny__text-area"
        value={message}
        maxLength={MAX_MESSAGE_SIZE}
        onChange={(e) => setMessage(e.target.value)}
        placeholder={t('placeholder')}
      />
      <div className="deny__button-container">
        <Button
          className="deny__button"
          type={BUTTON_TYPE.PRIMARY}
          onClick={sendMessage}
        >
          {t('send')}
        </Button>
      </div>
      <img src={image} className="deny__image" alt={'comment'} />
      {modal ? <SignInModal strict onSuccess={reload} /> : null}
    </div>
  );
};

export default Deny;
