import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import {
  ListType,
  ListUser,
} from 'src/shared/components/SearchDropdown/models';
import SearchDropdown from 'src/shared/components/SearchDropdown/SearchDropdown';
import Selector from 'src/shared/components/selectors/Selector/Selector';

import ModalWindow from 'src/features/modal/components/ModalWindow/ModalWindow';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { useLazyCreateProjectSpecialistQuery } from 'src/features/project/api';
import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';
import { UserType } from 'src/features/user/models';

import './AddPersonToTeam.scss';

interface AddPersonToTeamProps {
  onClose?: () => void;
  onSubmit?: () => void;
  setBottomSheetVisible?: (value: boolean) => void;
}

const AddPersonToTeam = ({
  onClose,
  onSubmit,
  setBottomSheetVisible,
}: AddPersonToTeamProps) => {
  const dispatch = useDispatch();
  const isSmallScreen = useCheckSmallScreen();

  const projectId = useSelector((s: State) => s[PROJ_CREATE].projectId);

  const [participant, setParticipant] = useState<ListUser>();
  const [type, setType] = useState<ListType>();

  const projectType = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );

  const { t: tt } = useTranslation('app', {
    keyPrefix: 'userCatalog.tags',
  });
  const { t: tf } = useTranslation('app', {
    keyPrefix: 'formAddToProject',
  });
  const { t } = useTranslation('app', {
    keyPrefix: `creation.${projectType}.team`,
  });

  const [createProjectSpecialist] = useLazyCreateProjectSpecialistQuery();

  const specialistTypes = useMemo(
    () => [
      {
        label: tt('developer'),
        value: [UserType.developer],
      },
      {
        label: tt('implementer'),
        value: [UserType.implementer],
      },
    ],
    [tt]
  );

  const renderTypeSelector = () => (
    <div className="dropdown-container">
      <h5 className={'add-person-modal__dropdown-title'}>{t('selectType')}</h5>
      <Selector
        defaultOptions={specialistTypes}
        value={type || ''}
        placeholder={t('placeholderRole')}
        onChange={(v) => {
          setType(v as ListType);
        }}
        className={
          'selector__dropdown search-dropdown__selector add-person-modal__select-type'
        }
      />
    </div>
  );

  const renderSearchDropdown = () => (
    <div className="dropdown-container">
      <h5 className={'add-person-modal__dropdown-title'}>
        {t('selectPerson')}
      </h5>
      <SearchDropdown
        participant={participant}
        className={'add-person-modal__dropdown-person'}
        setParticipant={setParticipant}
      />
    </div>
  );

  const sendRequest = () => {
    if (projectId && participant?.data.userId && type?.value) {
      createProjectSpecialist({
        projectId: projectId,
        userId: participant?.data.userId,
        roles: type?.value,
      }).then(() => onSubmit?.());
    }
  };

  return isSmallScreen ? (
    <div className={'add-person-modal__container-mobile'}>
      <div className="add-person-modal__block">
        <h1 className={'add-person-modal__title'}>{t('addMember')}</h1>
        {renderSearchDropdown()}
        {renderTypeSelector()}
        <Button
          className="add-person-modal__button"
          type={BUTTON_TYPE.PRIMARY}
          disabled={!participant || !type}
          onClick={() => {
            onClose?.();
            dispatch(changeModal(null));
            sendRequest();
            setBottomSheetVisible?.(false);
          }}
        >
          {tf('button')}
        </Button>
      </div>
    </div>
  ) : (
    <ModalWindow>
      <div className={'add-person-modal__container'}>
        <div className="add-person-modal__close-wrapper">
          <button
            className="request-form__close"
            onClick={() => {
              onClose?.();
              dispatch(changeModal(null));
            }}
          >
            <Icon icon={IconType.Cross} />
          </button>
        </div>
        <div className="add-person-modal__block">
          <h1 className={'add-person-modal__title'}>{t('addMember')}</h1>
          {renderSearchDropdown()}
          {renderTypeSelector()}
          <Button
            className="add-person-modal__button"
            type={BUTTON_TYPE.PRIMARY}
            disabled={!participant || !type}
            onClick={() => {
              onClose?.();
              dispatch(changeModal(null));
              sendRequest();
            }}
          >
            {tf('button')}
          </Button>
        </div>
      </div>
    </ModalWindow>
  );
};
export default AddPersonToTeam;
