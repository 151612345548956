import { useTranslation } from 'react-i18next';

import './MeetingPoint.scss';

export type MeetingPointProps = {
  className?: string;
  isMobile?: boolean;
  children?: string;
};

const MeetingPoint = ({
  className = '',
  isMobile = false,
  children,
}: MeetingPointProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'cards.overview' });

  return isMobile ? (
    <p className={`meeting-point meeting-point--reverted ${className}`}>
      <span className="meeting-point__name">{children}</span>
      <span className="meeting-point__label">{t('meetingPoint')}</span>
    </p>
  ) : (
    <p className={`meeting-point ${className}`}>
      <span className="meeting-point__label">{`${t('meetingPoint')}: `}</span>
      <span className="meeting-point__name">{children}</span>
    </p>
  );
};

export default MeetingPoint;
