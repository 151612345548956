import { createContext, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { TYPE_SEARCH_PAGE } from 'src/features/map/components/SearchPane/components/PaginationList/PaginationList';
import {
  SortingOptions,
  SortingOrderOptions,
} from 'src/features/searchWithFilter/filter/models';
import {
  SORTING_ORDER_BY,
  SORTING_ORDER_DIRECTION,
} from 'src/features/searchWithFilter/filter/searchFilters/FilterOrder/enums';
import {
  setSortingOrderDirection,
  setSortingOrderType,
} from 'src/features/searchWithFilter/filter/slices/filterSlice';

const useGetSortData = () => {
  const { t } = useTranslation('app', { keyPrefix: 'projectsList' });

  const nameOrderList: SortingOrderOptions[] = [
    {
      value: SORTING_ORDER_DIRECTION.ASC,
      label: t('sorting.order.name.asc'),
    },
    {
      value: SORTING_ORDER_DIRECTION.DESC,
      label: t('sorting.order.name.desc'),
    },
  ];

  const dateOrderList: SortingOrderOptions[] = [
    {
      value: SORTING_ORDER_DIRECTION.ASC,
      label: t('sorting.order.date.asc'),
    },
    {
      value: SORTING_ORDER_DIRECTION.DESC,
      label: t('sorting.order.date.desc'),
    },
  ];
  const sortingPageMap = {
    [TYPE_SEARCH_PAGE.PROJECT_CATALOG]: [
      SORTING_ORDER_BY.NAME,
      SORTING_ORDER_BY.APPROVED_AT,
    ],
    [TYPE_SEARCH_PAGE.PROJECT_MAP]: [
      SORTING_ORDER_BY.NAME,
      SORTING_ORDER_BY.APPROVED_AT,
    ],
    [TYPE_SEARCH_PAGE.USER_PROJECTS]: [
      SORTING_ORDER_BY.NAME,
      SORTING_ORDER_BY.CREATED_AT,
      SORTING_ORDER_BY.UPDATED_AT,
    ],
  };
  const sortTypeMap: Record<SORTING_ORDER_BY, SortingOptions> = {
    [SORTING_ORDER_BY.NAME]: {
      value: SORTING_ORDER_BY.NAME,
      label: t('sorting.sortBy.name'),
      order: nameOrderList,
    },
    [SORTING_ORDER_BY.APPROVED_AT]: {
      value: SORTING_ORDER_BY.APPROVED_AT,
      label: t('sorting.sortBy.dateAprovedAt'),
      order: dateOrderList,
    },
    [SORTING_ORDER_BY.CREATED_AT]: {
      value: SORTING_ORDER_BY.CREATED_AT,
      label: t('sorting.sortBy.dateAprovedAt'),
      order: dateOrderList,
    },
    [SORTING_ORDER_BY.UPDATED_AT]: {
      value: SORTING_ORDER_BY.UPDATED_AT,
      label: t('sorting.sortBy.dateUpdatedAt'),
      order: dateOrderList,
    },
  };

  return { sortingPageMap, sortTypeMap };
};

type FilterOrderContextType = {
  currentTypeSortOrderOptions?: SortingOptions;
  typeSortingList?: SortingOptions[];
  changeSortType?: (type: SORTING_ORDER_BY) => void;
  changeSortDirection?: (type: SORTING_ORDER_DIRECTION) => void;
};
export const FilterOrderContext = createContext<FilterOrderContextType>({});
export const FilterOrderProvider = ({
  children,
  typePage,
}: {
  children: ReactElement;
  typePage: TYPE_SEARCH_PAGE;
}) => {
  const dispatch = useDispatch();

  const { sortingPageMap, sortTypeMap } = useGetSortData();
  const typeSortingList: SortingOptions[] = sortingPageMap[typePage].map(
    (v) => sortTypeMap[v]
  );

  const [currentTypeSortOrderOptions, setCurrentTypeSortOrderOptions] =
    useState<SortingOptions>(typeSortingList[0]);

  const changeSortType = (type: SORTING_ORDER_BY) => {
    setCurrentTypeSortOrderOptions(sortTypeMap[type]);
    dispatch(setSortingOrderType(type));
  };
  const changeSortDirection = (direction: SORTING_ORDER_DIRECTION) => {
    dispatch(setSortingOrderDirection(direction));
  };

  useEffect(() => {
    dispatch(setSortingOrderDirection(typeSortingList[0].order[0].value));
    dispatch(setSortingOrderType(typeSortingList[0].value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FilterOrderContext.Provider
      value={{
        currentTypeSortOrderOptions,
        typeSortingList,
        changeSortType,
        changeSortDirection,
      }}
    >
      {children}
    </FilterOrderContext.Provider>
  );
};
