import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  retry,
} from '@reduxjs/toolkit/query/react';
import { Feature } from 'geojson';

import { convertFromGoogleToWgs84 } from 'src/shared/converters';

import {
  Cadaster,
  GetPolygonParams,
  GetPolygonPkkQueryParams,
  PkkAreaInfo,
  PkkAreasInfo,
  PolygonResponse,
} from '../models';

export const PKK_API = 'PKK_API';

export const MANUAL_URL = '/pkk';
export const PKK_URL = 'https://pkk.rosreestr.ru/';

const getFormDataFromFeature = (feature: Feature): FormData => {
  const res = new FormData();
  res.append('sq', JSON.stringify(feature.geometry));
  res.append('tolerance', '1');
  res.append('inBounds', 'true');
  res.append('searchInUserObjects', 'true');
  return res;
};

const convertGetPolygonParamsToGetPolygonPkkQueryParams = ({
  id,
  bbox,
  size,
}: GetPolygonParams): GetPolygonPkkQueryParams => ({
  bbox: bbox,
  bboxSR: 102100,
  imageSR: 102100,
  size: size,
  dpi: 96,
  format: 'png32',
  transparent: true,
  layers: 'show:6,7,8,9',
  layerDefs: `{"6":"id='${id}'","7":"id='${id}'","8":"id='${id}'","9":"id='${id}'"}`,
  f: 'json',
});

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const accessability = sessionStorage.getItem('pkkAccessability') === 'true';

  let resultUrl = MANUAL_URL;
  const found = window.navigator.userAgent.match('YaBrowser');

  if (accessability || (found && found.length > 0)) {
    resultUrl = PKK_URL;
  }

  const rawBaseQuery = fetchBaseQuery({ baseUrl: resultUrl });
  return rawBaseQuery(args, api, extraOptions);
};

export const pkkApi = createApi({
  reducerPath: PKK_API,
  baseQuery: retry(dynamicBaseQuery, {
    maxRetries: 5,
  }),
  endpoints: (builder) => ({
    getAreaInfoByPolygon: builder.query<Cadaster[], Feature>({
      query: (polygon) => ({
        url: 'api/features/1',
        method: 'POST',
        body: getFormDataFromFeature(polygon),
      }),
      transformResponse: (response: PkkAreasInfo): Cadaster[] =>
        response.features.map((elem) => {
          const coordinates = convertFromGoogleToWgs84({
            x: elem.center.x,
            y: elem.center.y,
          });
          return {
            number: elem.attrs.cn,
            id: elem.attrs.id,
            center: [coordinates.lat, coordinates.lng],
          };
        }),
    }),
    getInfoAboutAreaById: builder.query<Cadaster, string>({
      query: (id) => ({
        url: `api/features/1/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: PkkAreaInfo): Cadaster => {
        const coordinates = convertFromGoogleToWgs84({
          x: response.feature.center.x,
          y: response.feature.center.y,
        });
        return {
          id: response.feature.attrs.id,
          number: response.feature.attrs.cn,
          center: [coordinates.lat, coordinates.lng],
          district: response.feature.attrs.kvartal_cn,
          address: response.feature.attrs.address,
          objectType: response.feature.attrs.area_type,
          areaType: response.feature.attrs.statecd,
          ownershipForm: response.feature.attrs.fp?.toString(),
          landType: response.feature.attrs.category_type,
          allowedFor: response.feature.attrs.util_by_doc,
        };
      },
    }),
    getPolygonForAreaByParams: builder.query<PolygonResponse, GetPolygonParams>(
      {
        query: (params) => ({
          url: 'arcgis/rest/services/PKK6/CadastreSelected/MapServer/export',
          method: 'GET',
          params: convertGetPolygonParamsToGetPolygonPkkQueryParams(params),
        }),
      }
    ),
  }),
});

export const {
  useGetAreaInfoByPolygonQuery,
  useGetInfoAboutAreaByIdQuery,
  useGetPolygonForAreaByParamsQuery,
} = pkkApi;
export const pkkMiddleware = pkkApi.middleware;

export default pkkApi.reducer;
