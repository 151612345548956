import {
  Geodata,
  GetFilesResponse,
  GetProjectResponse,
  IconType,
  OrderedStatus,
  PROJECT_STATUS,
  SORTING_ORDER_DIRECTION,
  UserInfo,
  UserProfile,
} from '@platform-for-public-places/components-library';
import { Feature, Polygon, Position } from 'geojson';

import { PHOTOS_TYPE } from 'src/features/project/enums';
import { REQUEST_STATUS, REQUEST_TYPE } from 'src/features/request/enums';
import { BaseRequestData } from 'src/features/request/models';
import { SORTING_ORDER_BY } from 'src/features/searchWithFilter/filter/searchFilters/FilterOrder/enums';
import {
  EventParticipant,
  ProjectSpecialist,
  ProjectSpecialistBase,
  UserType,
} from 'src/features/user/models';

import { StatusMap } from '../status/models';

export enum SPECIFIC_LAYER { //todo delete
  DEFAULT = 'default',
  MUNICIPAL = 'gov',
  NEWS = 'news',
  EVENT = 'event',
}

export const ICONS = {
  [PROJECT_STATUS.FRAMED]: IconType.FRAMED,
  [PROJECT_STATUS.ACCEPTED]: IconType.ACCEPTED,
  [PROJECT_STATUS.APPROVED]: IconType.APPROVED,
  [PROJECT_STATUS.IMPLEMENTED]: IconType.IMPLEMENTED,
  [PROJECT_STATUS.FINISHED]: IconType.FINISHED,
  [PROJECT_STATUS.TRANSFERRED]: IconType.TRANSFERRED,
  [PROJECT_STATUS.PLANNED]: IconType.PLANNED,
  [PROJECT_STATUS.RUNNING]: IconType.RUNNING,
  [PROJECT_STATUS.NEWS]: IconType.Headquarters,
};

export interface Timeline {
  startDate: string;
  endDate: string;
}

export interface ProjectParticipantRequestBody {
  message?: string;
  projectName: string;
  projectSpecialist: ProjectSpecialist;
}

export interface CreateProjectRequest {
  type: string;
}

export interface CreateProjectResponseModel {
  projectId: string;
}

export interface CreateDescriptionRequest {
  projectId: string;
  name: string;
  idea: string;
}

interface CreateGeodataProperties {
  center: number[];
  projectId: string;
}

export interface CreateGeodataRequest {
  geojson: Feature<Polygon, CreateGeodataProperties>;
  meetingPoint?: string;
}

export interface CreateProjectTimelineRequest {
  projectId: string;
  startDate: string;
  endDate: string;
}

export interface GetProjectTimelineRequest {
  projectId: string;
  archived?: boolean;
}

export interface GetProjectTimelineResponse {
  timeline: Timeline;
}

export interface ProjectDatesProperties {
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
}

export interface CreateProjectSpecialistRequest {
  projectId: string;
  userId: string;
  roles: UserType[];
}

export interface CheckUserIsMemberOfProjectRequest {
  projectId: string;
}

export interface GetProjectTeamRequest {
  projectId: string;
  page: number;
  pageSize: number;
  archived?: boolean;
}

export interface ProjectTeamRequests {
  specialists: {
    request: BaseRequestData<ProjectParticipantRequestBody>;
    specialist: ProjectSpecialist;
  }[];
  count: number;
}

export interface GetProjectTeamRequestsResponse {
  projectSpecialists: {
    request: BaseRequestData<ProjectParticipantRequestBody>;
    specialist: ProjectSpecialistResponse;
  }[];
  aggregators: {
    count: number;
  };
}

export interface DeleteProjectSpecialistRequest {
  projectSpecialistId: string;
}

export interface CreateProjectSpecialistResponse {
  requestId: string;
  projectSpecialistId: string;
}

export interface CheckUserIsMemberOfProjectResponse {
  isMember: boolean;
}

export interface ProjectSpecialistResponse extends ProjectSpecialistBase {
  project_specialistID: string;
}

export interface GetProjectTeamResponse {
  projectSpecialists: { specialist: ProjectSpecialistResponse }[];
  aggregators: {
    count: number;
  };
}

export interface SpecialistsByProjectIdRequestInfo {
  createdAt: string;
  entityId: string;
  initiatorId: string;
  requestBody: {
    projectName: string;
    projectSpecialist: ProjectSpecialistResponse;
  };
  requestID: string;

  status: REQUEST_STATUS;
  type: REQUEST_TYPE;
  updatedAt: string;
  message?: string;
}

export interface GetProjectsRequest {
  pageSize: number;
  page: number;
  layer?: string;
  searchString?: string;
  archived?: boolean;
}

export type GetProjectBriefResponse = Omit<GetProjectResponse, 'status'> & {
  status?: OrderedStatus;
};

interface Aggregators {
  count: number;
}

interface TempGeodata {
  polygon: string;
  point: string;
}

interface TempGeodataWithCenter {
  polygon: string;
  center: string;
}

interface TempGeodataWithCenterCoordinates {
  polygon: Position[][];
  point: Position;
  meetingPoint?: string;
}

interface TempGetProjectsData {
  id: string;
  name?: string;
  idea?: string;
  geo_data?: TempGeodata;
  layer?: SPECIFIC_LAYER;
  status?: OrderedStatus & { flow_stepID: string };
}

interface ProjectType {
  type: string;
  typeId: string;
}

type StatusProjectCatalog = {
  updated: number;
  flow_stepID: string;
};
export type ProjectCatalogData = {
  accepted: number;
  layer: SPECIFIC_LAYER;
  name: string;
  projectId: string;

  status: StatusProjectCatalog;
  type: ProjectType;
};
export interface ProjectCatalogDataResponse {
  aggregators: Aggregators;
  projectInfo: ProjectCatalogData[];
}

export interface GetUserProjectResponse {
  aggregators: Aggregators;
  projects: UserProjectData[];
}

export interface UserProjectData {
  name: string;
  layer: SPECIFIC_LAYER;
  liked: boolean;
  likes: number;
  owner: boolean;
  status: StatusProjectCatalog;
  accepted: number;
  comments: number;
  projectId: string;
  countOfJoined?: number;
  joined?: boolean;
  amount?: number;
  budget?: number;
  timeline?: Timeline;
  type: ProjectType;
}

export interface TempGetProjectsResponse {
  aggregators: Aggregators;
  documents: TempGetProjectsData[];
}

export interface GetProjectsResponse {
  aggregators: Aggregators;
  documents: GetProjectResponse[];
}

export interface GetProjectRequest {
  projectId: string;
  archived?: boolean;
}

export type GetProjectsBriefResponse = Omit<
  GetProjectsResponse,
  'documents'
> & {
  documents: GetProjectBriefResponse[];
};

export interface TempGetProjectData {
  id: string;
  initiator?: UserInfo;
  name?: string;
  idea?: string;
  geo_data?: TempGeodataWithCenter;
  cover?: string;
  status?: StatusProjectCatalog;
  likes?: number;
  comments: number;
}

export interface TempGetProjectGeodata {
  geodata?: TempGeodataWithCenterCoordinates;
}

export interface GetProjectsCategoriesCountResponse {
  all: number;
  ongoing: number;
  completed: number;
}

export interface GetProjectsCategoriesCountRequest {
  searchString: string;
  types: string[];
  status: string[];
}

export interface GetProjectsCatalogInfoRequest {
  isUserPage: boolean;
  searchString: string;
  types: string[];
  flowStepIds: string[];
  pageSize: number;
  page: number;
  orderBy?: SORTING_ORDER_BY;
  orderDirection: SORTING_ORDER_DIRECTION;
}

export interface GetUserProjectRequest {
  pageSize: number;
  page: number;
  searchString: string;
  orderBy?: SORTING_ORDER_BY;
  orderDirection: SORTING_ORDER_DIRECTION;
}

export interface GetProjectsBySpecialistRequest {
  userId: string;
  statuses: PROJECT_STATUS[];
  page: number;
  pageSize: number;
}

export interface PhotosPayload {
  photos: GetFilesResponse[];
  type: PHOTOS_TYPE;
}

export interface ProjectFlowStep {
  name: string;
  order: number;
  flowID: string;
  flow_stepID: string;
}

export interface GetProjectFlowStepsResponse {
  flowSteps: ProjectFlowStep[];
}
export interface GetProjectTypesResponse {
  types: string[];
}

export interface GetProjectStepsRequest {
  type: string;
}

export interface ProperitesWidget {
  visible: string[];
}
export interface StepConfig {
  name: string;
  type: string;
  properties: ProperitesWidget;
}

export interface GetProjectDescriptionRequest {
  projectId: string;
  archived?: boolean;
}

export interface GetProjectDescriptionResponse {
  description: {
    projectID: string;
    name: string;
    idea: string;
  };
}

export interface GetProjectStepsResponse {
  creationSteps: StepConfig[];
  editingSteps: StepConfig[];
}

export interface GetProjectGeodataRequest {
  projectId: string;
  archived?: boolean;
}

export interface GetProjectGeodataResponse {
  geodata?: Geodata;
}

export interface GetProjectCommentsRequest {
  projectId: string;
  archived?: boolean;
}

export interface GetProjectCommentsResponse {
  commentInfo: {
    comments: number;
  };
}

export interface GetProjectInfoRequest {
  projectId: string;
  archived?: boolean;
}

export interface GetProjectInfoResponse {
  projectInfo: {
    projectID: string;
    layer?: SPECIFIC_LAYER;
    type?: {
      type: string;
    };
    ownerId?: string;
    owner?: UserProfile;
  };
}

export interface GetProjectSubscriptionRequest {
  projectId: string;
  archived?: boolean;
}

export interface GetProjectSubscriptionResponse {
  subscriptionInfo: {
    followed: boolean;
  };
}

export interface GetEventParticipantsInfoRequest {
  projectId: string;
  archived?: boolean;
}

export interface GetEventParticipantsInfoResponse {
  joinedToEventInfo: {
    joined: boolean;
    projectId: string;
    countOfJoined: number;
  };
}

export interface GetConfirmedParticipantsInfoRequest {
  projectId: string;
  archived?: boolean;
}

export interface GetConfirmedParticipantsInfoResponse {
  confirmedToEventInfo: {
    projectId: string;
    countOfConfirmed: number;
  };
}

export interface GetEventParticipantsRequest {
  projectId: string;
  page: number;
  pageSize: number;
  archived?: boolean;
}

export interface GetEventParticipantsResponse {
  eventParticipants: EventParticipant[];
  aggregators: {
    count: number;
  };
}

export interface GetConfirmedParticipantsRequest {
  projectId: string;
  page: number;
  pageSize: number;
  archived?: boolean;
}

export interface GetConfirmedParticipantsResponse {
  eventParticipants: EventParticipant[];
  aggregators: {
    count: number;
  };
}

export interface GetAllParticipantsRequest {
  projectId: string;
  page: number;
  pageSize: number;
  archived?: boolean;
}

export interface GetAllParticipantsResponse {
  eventParticipants: EventParticipant[];
  aggregators: {
    count: number;
  };
}

export interface UpdateConfirmedParticipantsRequest {
  projectId: string;
  participants: EventParticipant[];
}

export interface TempProjectsInfoWithGeodata {
  projectId: string;
  name: string;
  type: ProjectType;
  layer: string;
  status: StatusMap;
  geo_data?: TempGeodata;
}

export interface ProjectsInfoWithGeodata {
  projectId: string;
  name: string;
  type: ProjectType;
  layer: string;
  status: StatusMap;
  geo_data: Geodata;
}

export interface GetProjectsInfoWithGeodataRequest {
  searchString?: string;
  page: number;
  pageSize: number;
  flowStepIds: string[];
  types: string[];
}

export interface TempGetProjectsInfoWithGeodataResponse {
  projectInfo: TempProjectsInfoWithGeodata[];
  aggregators: Aggregators;
}

export interface GetProjectsInfoWithGeodataResponse {
  projectInfo: ProjectsInfoWithGeodata[];
  aggregators: Aggregators;
}
