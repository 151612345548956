import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';
import clsx from 'clsx';

import {
  CONTROL,
  setSearchPanelState,
} from 'src/features/control/slices/controlSlice';
import { SearchPanelState } from 'src/features/map/components/SearchPane/components/SearchFilterInput/SearchFilterInput';
import Indicator from 'src/features/searchWithFilter/filter/searchFilters/Indicator/Indicator';
import { FILTER } from 'src/features/searchWithFilter/filter/slices/filterSlice';
import { State } from 'src/features/store/store';

import './FilterButton.scss';

type FilterButtonProps = {
  className?: string;
};
const FilterButton = ({ className }: FilterButtonProps) => {
  const defaultValue = useCheckFilter();
  const searchPanelState = useSelector(
    (s: State) => s[CONTROL].searchPanelState
  );
  const dispatch = useDispatch();

  return (
    <Button
      className={clsx(className, 'search-filter-button', {
        'search-filter-button_active':
          searchPanelState === SearchPanelState.OPEN_WITH_FILTER,
      })}
      onClick={() => {
        dispatch(
          setSearchPanelState(
            searchPanelState === SearchPanelState.OPEN_WITH_FILTER
              ? SearchPanelState.OPEN
              : SearchPanelState.OPEN_WITH_FILTER
          )
        );
      }}
      type={BUTTON_TYPE.TEXT_LIGHT}
    >
      <Icon icon={IconType.Filter} className={`search-filter-button__icon`} />
      <Indicator
        show={!defaultValue}
        className={'search-filter-button__indicator'}
      />
    </Button>
  );
};

const useCheckFilter = () => {
  const { type, status } = useSelector((s: State) => s[FILTER]);
  return !(type?.length && status?.length);
};

export default FilterButton;
