import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import RecoveryPassword from 'src/features/auth/components/RecoveryPassword/RecoveryPassword';
import AuthModal from 'src/features/modal/components/AuthModal/AuthModal';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';

const RecoveryPasswordModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('app', { keyPrefix: 'recoveryPassword' });

  const onClickBack = () => {
    dispatch(changeModal(MODALS.SIGN_IN));
  };

  return (
    <AuthModal header={t('header')} onClickBack={onClickBack}>
      <RecoveryPassword />
    </AuthModal>
  );
};

export default RecoveryPasswordModal;
