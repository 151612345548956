import DonationFailure from 'src/features/payment/components/DonationFailure/DonationFailure';

import ModalWindow from '../ModalWindow/ModalWindow';

import './DonationFailureModal.scss';

const DonationFailureModal = () => {
  return (
    <ModalWindow>
      <div className="donation-failure-modal">
        <DonationFailure />
      </div>
    </ModalWindow>
  );
};

export default DonationFailureModal;
