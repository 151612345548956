import { useEffect, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useSelector } from 'react-redux';

import {
  GetProjectResponse,
  MinIoUrl,
  PROJECT_STATUS,
  UserCard,
} from '@platform-for-public-places/components-library';

import CatalogAvatar from 'src/shared/components/CatalogUsers/components/CatalogAvatar/CatalogAvatar';
import { paths } from 'src/shared/routes';

import {
  useGetProjectsWithImages,
  useTranslationStatus,
  useUserCardTranslation,
} from 'src/features/hooks';
import { useLazyGetProjectsBySpecialistQuery } from 'src/features/project/api';
import { FILTER_DATA } from 'src/features/searchWithFilter/filter/slices/filterSliceData';
import { State } from 'src/features/store/store';
import { useLazyGetProfileQuery } from 'src/features/user/api';

import ModalWindow from '../ModalWindow/ModalWindow';

const PAGE_SIZE = 10;

const UserCardModal = () => {
  const userId = useSelector((s: State) => s[FILTER_DATA].selectedUserId);

  const translateStatus = useTranslationStatus();
  const userCardTitles = useUserCardTranslation();

  const [projectsPage, setProjectsPage] = useState<number>(1);
  const [projectsError] = useState<boolean>(false);
  const [projectsLoading, setProjectsLoading] = useState<boolean>(false);
  const [hasProjectsNextPage, setHasProjectsNextPage] = useState<boolean>(true);
  const [projects, setProjects] = useState<GetProjectResponse[]>([]);

  const [getProjectsBySpecialist] = useLazyGetProjectsBySpecialistQuery();

  const [getProjectsWithImages] = useGetProjectsWithImages();

  const [getProjects] = useInfiniteScroll({
    loading: projectsLoading,
    hasNextPage: hasProjectsNextPage,
    onLoadMore: () => {
      setProjectsLoading(true);
      getProjectsBySpecialist({
        userId: userId,
        statuses: [
          PROJECT_STATUS.ACCEPTED,
          PROJECT_STATUS.APPROVED,
          PROJECT_STATUS.IMPLEMENTED,
          PROJECT_STATUS.FINISHED,
          PROJECT_STATUS.TRANSFERRED,
        ],
        page: projectsPage,
        pageSize: PAGE_SIZE,
      }).then((response) => {
        getProjectsWithImages(response.data?.data ?? []).then((res) =>
          setProjects((prev) => [...prev, ...res])
        );
        setProjectsPage(projectsPage + 1);
        setProjectsLoading(false);
        setHasProjectsNextPage(response.data?.data.length === PAGE_SIZE);
      });
    },
    disabled: projectsError,
  });

  const [getUserProfile, getUserProfileResponse] = useLazyGetProfileQuery();

  useEffect(() => {
    getUserProfile(userId);
  }, [getUserProfile, userId]);

  return (
    <ModalWindow>
      {getUserProfileResponse.data?.data ? (
        <UserCard
          className="user-card-modal"
          user={getUserProfileResponse.data?.data}
          imageComponent={
            <CatalogAvatar
              avatar={
                (getUserProfileResponse.data?.data.avatar as MinIoUrl[])?.[0]
                  .key
                  ? (
                      getUserProfileResponse.data?.data.avatar as MinIoUrl[]
                    )?.[0].url
                  : ''
              }
              url={false}
            />
          }
          showProjects
          titles={userCardTitles}
          translateStatus={translateStatus}
          getProjectPath={paths.projectById}
          projectsRef={getProjects}
          projects={projects}
          hasNextPage={hasProjectsNextPage}
        />
      ) : null}
    </ModalWindow>
  );
};

export default UserCardModal;
