import {
  useGetDonationDetailsQuery as mockUseGetDonationDetailsQuery,
  useGetProjectEstimationQuery as mockUseGetProjectEstimationQuery,
  useGetTotalAmountUserPaymentsQuery as mockUseGetTotalAmountUserPaymentsQuery,
  useLazyCreatePaymentQuery as mockUseLazyCreatePaymentQuery,
  useLazyCreateProjectBudgetQuery as mockUseLazyCreateProjectBudgetQuery,
  useLazyGetDonationDetailsQuery as mockUseLazyGetDonationDetailsQuery,
  useLazyGetPaymentsStatisticQuery as mockUseLazyGetPaymentsStatisticQuery,
  useLazyGetRecurrentUserPaymentsQuery as mockUseLazyGetRecurrentUserPaymentsQuery,
  useLazyGetUserPaymentsQuery as mockUseLazyGetUserPaymentsQuery,
  useLazyRequestAccessToRecurrentPaymentsQuery as mockUseLazyRequestAccessToRecurrentPaymentsQuery,
  useStopSupportingRecurrentPaymentMutation as mockUseStopSupportingRecurrentPaymentMutation,
} from './mockPaymentApi';
import {
  useGetDonationDetailsQuery as realUseGetDonationDetailsQuery,
  useGetProjectEstimationQuery as realUseGetProjectEstimationQuery,
  useGetTotalAmountUserPaymentsQuery as realGetTotalAmountUserPaymentsQuery,
  useLazyCreatePaymentQuery as realUseLazyCreatePaymentQuery,
  useLazyCreateProjectBudgetQuery as realUseLazyCreateProjectBudgetQuery,
  useLazyGetDonationDetailsQuery as realUseLazyGetDonationDetailsQuery,
  useLazyGetPaymentsStatisticQuery as realUseLazuGetPaymentsStatisticQuery,
  useLazyGetRecurrentUserPaymentsQuery as realUseLazyGetRecurrentUserPaymentsQuery,
  useLazyGetUserPaymentsQuery as realUseLazyGetUserPaymentsQuery,
  useLazyRequestAccessToRecurrentPaymentsQuery as realUseLazyRequestAccessToRecurrentPaymentsQuery,
  useStopSupportingRecurrentPaymentMutation as realUseStopSupportingRecurrentPaymentMutation,
} from './paymentApi';

export const useLazyGetPaymentsStatisticQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetPaymentsStatisticQuery
    : realUseLazuGetPaymentsStatisticQuery;

export const useGetProjectEstimationQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetProjectEstimationQuery
    : realUseGetProjectEstimationQuery;

export const useLazyCreateProjectBudgetQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyCreateProjectBudgetQuery
    : realUseLazyCreateProjectBudgetQuery;

export const useGetDonationDetailsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetDonationDetailsQuery
    : realUseGetDonationDetailsQuery;

export const useGetTotalAmountUserPaymentsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetTotalAmountUserPaymentsQuery
    : realGetTotalAmountUserPaymentsQuery;

export const useLazyGetDonationDetailsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetDonationDetailsQuery
    : realUseLazyGetDonationDetailsQuery;
export const useLazyCreatePaymentQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyCreatePaymentQuery
    : realUseLazyCreatePaymentQuery;

export const useLazyGetUserPaymentsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetUserPaymentsQuery
    : realUseLazyGetUserPaymentsQuery;

export const useLazyGetRecurrentUserPaymentsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetRecurrentUserPaymentsQuery
    : realUseLazyGetRecurrentUserPaymentsQuery;

export const useStopSupportingRecurrentPaymentMutation =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseStopSupportingRecurrentPaymentMutation
    : realUseStopSupportingRecurrentPaymentMutation;

export const useLazyRequestAccessToRecurrentPaymentsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyRequestAccessToRecurrentPaymentsQuery
    : realUseLazyRequestAccessToRecurrentPaymentsQuery;
