import { Link } from 'react-router-dom';

import './ArrowLink.scss';

interface ArrowLinkProps {
  text: string;
  link: string;
  className?: string;
}

export const ArrowLink = ({
  text,
  link,
  className = '',
}: ArrowLinkProps): JSX.Element => {
  return (
    <Link className={`arrow-button ${className}`} to={link}>
      <span className="arrow-button__text">{text}</span>
      <div className="arrow-button__icon" />
    </Link>
  );
};
