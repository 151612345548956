import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import 'src/features/searchWithFilter/filter/searchFilters/FilterOrder/FilterOrderCards/FilterOrderCards.scss';
import FilterItem from 'src/features/searchWithFilter/components/FilterItem/FilterItem';
import { FilterOrderContext } from 'src/features/searchWithFilter/filter/searchFilters/FilterOrder/FilterOrderProvider';
import { FILTER } from 'src/features/searchWithFilter/filter/slices/filterSlice';
import { State } from 'src/features/store/store';

const FilterOrderCards = () => {
  const { t } = useTranslation('app', { keyPrefix: 'projectsList' });

  const { sortingOrderType, sortingOrderDirection } = useSelector(
    (s: State) => s[FILTER]
  );

  const {
    currentTypeSortOrderOptions,
    typeSortingList,
    changeSortDirection,
    changeSortType,
  } = useContext(FilterOrderContext);

  return (
    <>
      <FilterOrderCard
        title={t('sorting.sortBy.label')}
        selectedValue={sortingOrderType}
        listOptions={typeSortingList}
        onChange={changeSortType}
      />
      <FilterOrderCard
        title={t('sorting.order.label')}
        selectedValue={sortingOrderDirection}
        listOptions={currentTypeSortOrderOptions?.order}
        onChange={changeSortDirection}
      />
    </>
  );
};

const FilterOrderCard = <P, T extends { value: P; label: string }>({
  title,
  selectedValue,
  listOptions,
  onChange,
}: {
  title: string;
  selectedValue: P;
  listOptions?: T[];
  onChange?: (value: P) => void;
}): JSX.Element => {
  return (
    <>
      <header className="filter-card__header">{title}</header>
      <ul className="filter-card__filter-category">
        {listOptions?.map((item, key) => (
          <li key={key} className="filter-category__item">
            <FilterItem
              onClick={() => {
                onChange && onChange(item.value);
              }}
              name={item.label}
              isSelected={selectedValue === item.value}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export default FilterOrderCards;
