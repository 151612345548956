import ReactMarkdown from 'react-markdown';

import './TextEditorPreview.scss';

interface TextEditorPreviewProps {
  className?: string;
  children: string;
}

// Children should be stirng in markdown format
const TextEditorPreview = ({
  className = '',
  children,
}: TextEditorPreviewProps) => {
  return (
    <div className={`text-editor-preview ${className}`}>
      <ReactMarkdown linkTarget="_blank">{children}</ReactMarkdown>
    </div>
  );
};

export default TextEditorPreview;
