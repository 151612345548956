import { UserProfile } from '@platform-for-public-places/components-library';

import { SPECIFIC_LAYER } from '../project/models';

export interface SignInRequest {
  email: string;
  password: string;
}

export interface SignUpRequest {
  email: string;
  password: string;
  name: string;
  socialLink?: string;
}

export interface ConfirmSignUpRequest {
  userId: string;
}

export interface GetPasswordRecoveryLinkRequest {
  email: string;
}

export enum USER_ROLE {
  USER = 'USER',
  ADMIN = 'ADMIN',
}

export interface WhoamiResponse {
  userID: string;
  role: string;
  email: string;
  profile?: UserProfile;
  specificLayer?: SPECIFIC_LAYER;
}

export interface ConfirmPasswordRecoveryRequest {
  recoveryTokenId: string;
  password: string;
}

export interface VerifyCaptchaRequest {
  token: string;
}

export interface VerifyCaptchaResponse {
  success: boolean;
  challenge_ts: string; // ISO format yyyy-MM-dd'T'HH:mm:ssZZ
  hostname: string;
  credit?: boolean;
  'error-codes'?: string[];
}

export interface SignUpConfirmationToken {
  sub: string;
  exp: number;
}
