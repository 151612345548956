import {
  ActorBaseResponse,
  Geodata,
  GetProjectResponse,
  PROJECT_STATUS,
} from '@platform-for-public-places/components-library';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { BaseRequestData } from 'src/features/request/models';
import {
  ProjectSpecialist,
  ProjectSpecialists,
} from 'src/features/user/models';

import {
  CheckUserIsMemberOfProjectRequest,
  CheckUserIsMemberOfProjectResponse,
  CreateDescriptionRequest,
  CreateGeodataRequest,
  CreateProjectRequest,
  CreateProjectResponseModel,
  CreateProjectSpecialistRequest,
  CreateProjectSpecialistResponse,
  CreateProjectTimelineRequest,
  DeleteProjectSpecialistRequest,
  GetAllParticipantsRequest,
  GetAllParticipantsResponse,
  GetConfirmedParticipantsInfoRequest,
  GetConfirmedParticipantsInfoResponse,
  GetConfirmedParticipantsRequest,
  GetConfirmedParticipantsResponse,
  GetEventParticipantsInfoRequest,
  GetEventParticipantsInfoResponse,
  GetEventParticipantsRequest,
  GetEventParticipantsResponse,
  GetProjectBriefResponse,
  GetProjectCommentsRequest,
  GetProjectCommentsResponse,
  GetProjectDescriptionRequest,
  GetProjectDescriptionResponse,
  GetProjectFlowStepsResponse,
  GetProjectGeodataRequest,
  GetProjectGeodataResponse,
  GetProjectInfoRequest,
  GetProjectInfoResponse,
  GetProjectRequest,
  GetProjectsBriefResponse,
  GetProjectsBySpecialistRequest,
  GetProjectsCatalogInfoRequest,
  GetProjectsCategoriesCountRequest,
  GetProjectsCategoriesCountResponse,
  GetProjectsInfoWithGeodataRequest,
  GetProjectsInfoWithGeodataResponse,
  GetProjectsRequest,
  GetProjectStepsRequest,
  GetProjectStepsResponse,
  GetProjectSubscriptionRequest,
  GetProjectSubscriptionResponse,
  GetProjectTeamRequest,
  GetProjectTeamRequestsResponse,
  GetProjectTeamResponse,
  GetProjectTimelineRequest,
  GetProjectTimelineResponse,
  GetProjectTypesResponse,
  GetUserProjectRequest,
  GetUserProjectResponse,
  ProjectCatalogDataResponse,
  ProjectParticipantRequestBody,
  ProjectTeamRequests,
  SPECIFIC_LAYER,
  TempGetProjectData,
  TempGetProjectGeodata,
  TempGetProjectsInfoWithGeodataResponse,
  TempGetProjectsResponse,
  UpdateConfirmedParticipantsRequest,
} from '../models';

export const PROJECT_API = 'PROJECT_API';

const transformTeamRequestResponse = (
  response: ActorBaseResponse<GetProjectTeamRequestsResponse>
): ActorBaseResponse<ProjectTeamRequests> => {
  const specialists: {
    request: BaseRequestData<ProjectParticipantRequestBody>;
    specialist: ProjectSpecialist;
  }[] = response.data.projectSpecialists.map((specialist) => {
    return {
      specialist: {
        ...specialist.specialist,
        id: specialist.specialist.project_specialistID,
      },
      request: specialist.request,
    };
  });
  return {
    data: {
      specialists,
      count: response.data.aggregators.count,
    },
    success: response.success,
    errors: response.errors,
  };
};

export const projectsApi = createApi({
  reducerPath: PROJECT_API,
  baseQuery: retry(
    fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URLS }),
    { maxRetries: 0 }
  ),
  tagTypes: ['PROJECTS'],
  endpoints: (builder) => ({
    createProject: builder.query<
      ActorBaseResponse<CreateProjectResponseModel>,
      CreateProjectRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-creation/create-project',
          arguments: request,
        },
      }),
    }),
    createDescription: builder.query<
      ActorBaseResponse<void>,
      CreateDescriptionRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'description/create-description',
          arguments: request,
        },
      }),
    }),
    createGeodata: builder.mutation<
      ActorBaseResponse<void>,
      CreateGeodataRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'geodata/create-geodata',
          arguments: request,
        },
      }),
      invalidatesTags: ['PROJECTS'],
    }),
    createProjectTimeline: builder.mutation<
      ActorBaseResponse<void>,
      CreateProjectTimelineRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-timeline/create-project-timeline',
          arguments: request,
        },
      }),
      invalidatesTags: ['PROJECTS'],
    }),
    getProjectTimeline: builder.query<
      ActorBaseResponse<GetProjectTimelineResponse>,
      GetProjectTimelineRequest
    >({
      query: ({ archived, ...req }) => {
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-project-timeline/get-timeline'
              : 'project-timeline/get-timeline',
            arguments: req,
          },
        };
      },
    }),
    createProjectSpecialist: builder.query<
      ActorBaseResponse<CreateProjectSpecialistResponse>,
      CreateProjectSpecialistRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-specialist/create-project-specialist',
          arguments: request,
        },
      }),
    }),
    checkUserIsMemberOfProject: builder.query<
      ActorBaseResponse<CheckUserIsMemberOfProjectResponse>,
      CheckUserIsMemberOfProjectRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-specialist/check-user-is-member-of-project',
          arguments: request,
        },
      }),
    }),
    getProjectTeam: builder.query<
      ActorBaseResponse<ProjectSpecialists>,
      GetProjectTeamRequest
    >({
      query: (request) => {
        const { archived, ...req } = request;
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-projects/get-project-team'
              : 'project-specialist/get-project-team',
            arguments: req,
          },
        };
      },
      transformResponse: (
        response: ActorBaseResponse<GetProjectTeamResponse>
      ): ActorBaseResponse<ProjectSpecialists> => {
        const specialists: ProjectSpecialist[] =
          response.data.projectSpecialists.map((specialist) => {
            return {
              ...specialist.specialist,
              id: specialist.specialist.project_specialistID,
            };
          });
        return {
          data: {
            specialists,
            count: response.data.aggregators.count,
          },
          success: response.success,
          errors: response.errors,
        };
      },
    }),
    getProjectIncomingTeam: builder.query<
      ActorBaseResponse<ProjectTeamRequests>,
      GetProjectTeamRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-specialist/get-project-incoming-team',
          arguments: request,
        },
      }),
      transformResponse: transformTeamRequestResponse,
    }),
    getProjectOutgoingTeam: builder.query<
      ActorBaseResponse<ProjectTeamRequests>,
      GetProjectTeamRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-specialist/get-project-outgoing-team',
          arguments: request,
        },
      }),
      transformResponse: transformTeamRequestResponse,
    }),
    deleteProjectSpecialist: builder.mutation<
      ActorBaseResponse<void>,
      DeleteProjectSpecialistRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-specialist/delete-project-specialist',
          arguments: request,
        },
      }),
    }),
    getProjectById: builder.query<
      ActorBaseResponse<GetProjectResponse>,
      GetProjectRequest
    >({
      query: (request) => {
        const { archived, ...req } = request;
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-projects/get-project-by-id'
              : 'project/get-project-by-id',
            arguments: req,
          },
        };
      },
      transformResponse: (
        response: ActorBaseResponse<TempGetProjectData>
      ): ActorBaseResponse<GetProjectResponse> => ({
        data: {
          ...response.data,
          status: { order: 1, value: PROJECT_STATUS.APPROVED, updated: 1 }, //todo fix (mock)
          geo_data: response.data?.geo_data
            ? ({
                polygon: {
                  type: 'Feature',
                  geometry: {
                    type: 'Polygon',
                    coordinates: JSON.parse(response.data.geo_data.polygon),
                  },
                },
                center: {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: JSON.parse(response.data.geo_data.center),
                  },
                },
              } as Geodata)
            : undefined,
        },
        success: response.success,
        errors: response.errors,
      }),
      providesTags: ['PROJECTS'],
    }),
    getProjects: builder.query<
      ActorBaseResponse<GetProjectsBriefResponse>,
      GetProjectsRequest
    >({
      query: ({ layer = SPECIFIC_LAYER.DEFAULT, archived, ...request }) => ({
        method: 'POST',
        body: {
          messageMapId: archived
            ? 'archived-projects/get-projects-with-description-and-geodata'
            : 'geodata/get-projects-with-description-and-geodata',
          arguments: {
            ...request,
            layer,
          },
        },
      }),
      keepUnusedDataFor: 0,
      transformResponse: (
        response: ActorBaseResponse<TempGetProjectsResponse>
      ): ActorBaseResponse<GetProjectsBriefResponse> => {
        const filteredData = response.data.documents.filter(
          (doc) => doc.geo_data?.point && doc.geo_data?.polygon
        );
        const documents: GetProjectBriefResponse[] = filteredData.map(
          (doc) => ({
            ...doc,
            status: { ...doc.status, flowStepID: doc.status?.flow_stepID },
            geo_data: {
              polygon: {
                type: 'Feature',
                geometry: {
                  type: 'Polygon',
                  coordinates: JSON.parse(doc.geo_data?.polygon || ''),
                },
              },
              center: {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: JSON.parse(doc.geo_data?.point || ''),
                },
              },
            },
          })
        ) as GetProjectBriefResponse[];
        return {
          data: {
            aggregators: response.data.aggregators,
            documents,
          },
          success: response.success,
          errors: response.errors,
        };
      },
      providesTags: ['PROJECTS'],
    }),
    getProjectsBySpecialist: builder.query<
      ActorBaseResponse<GetProjectResponse[]>,
      GetProjectsBySpecialistRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-specialist/get-projects-by-specialist',
          arguments: request,
        },
      }),
    }),
    getProjectsCategoriesCount: builder.query<
      ActorBaseResponse<GetProjectsCategoriesCountResponse>,
      GetProjectsCategoriesCountRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-catalog/get-projects-categories-count',
          arguments: request,
        },
      }),
    }),
    getProjectsCatalogInfo: builder.query<
      ActorBaseResponse<ProjectCatalogDataResponse>,
      GetProjectsCatalogInfoRequest
    >({
      query: ({ isUserPage, ...request }) => ({
        method: 'POST',
        body: {
          messageMapId: isUserPage
            ? 'user-projects/get-user-projects'
            : 'project-catalog/get-projects-catalog-info',
          arguments: request,
        },
      }),
    }),
    getUserProjects: builder.query<
      ActorBaseResponse<GetUserProjectResponse>,
      GetUserProjectRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'user-projects/get-user-projects',
          arguments: request,
        },
      }),
    }),
    resetSubscriptions: builder.mutation<ActorBaseResponse<void>, void>({
      query: () => ({
        method: 'POST',
        body: {
          messageMapId: 'subscribe-to-project/reset-subscriptions',
        },
      }),
      invalidatesTags: ['PROJECTS'],
    }),
    getProjectTypes: builder.query<
      ActorBaseResponse<GetProjectTypesResponse>,
      'read' | 'create'
    >({
      query: (type) => ({
        method: 'POST',
        body: {
          messageMapId: `project-types/get-project-types-for-${
            type === 'read' ? 'reading' : 'creating'
          }`,
        },
      }),
    }),
    getProjectStatusesByType: builder.query<
      ActorBaseResponse<GetProjectFlowStepsResponse>,
      string
    >({
      query: (type: string) => ({
        method: 'POST',
        body: {
          messageMapId:
            'project-types/get-flow-steps-by-project-type-for-reading',
          arguments: {
            page: 1, // todo change when in will be need
            pageSize: 20,
            type: type,
          },
        },
        url: '',
        params: { chain: 'project-types__get-project-types-for-creating' },
      }),
    }),
    getProjectDescription: builder.query<
      ActorBaseResponse<GetProjectDescriptionResponse>,
      GetProjectDescriptionRequest
    >({
      query: (request) => {
        const { archived, ...req } = request;
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-description/get-description'
              : 'description/get-description',
            arguments: req,
          },
        };
      },
    }),
    getProjectSteps: builder.query<
      ActorBaseResponse<GetProjectStepsResponse>,
      GetProjectStepsRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-steps/get-project-creation-steps',
          arguments: request,
        },
      }),
    }),
    getProjectGeodata: builder.query<
      ActorBaseResponse<GetProjectGeodataResponse>,
      GetProjectGeodataRequest
    >({
      query: (request) => {
        const { archived, ...req } = request;
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-project-geodata/get-geodata'
              : 'project-geodata/get-geodata',
            arguments: req,
          },
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (
        response: ActorBaseResponse<TempGetProjectGeodata>
      ): ActorBaseResponse<GetProjectGeodataResponse> => ({
        data: {
          ...response.data,
          geodata: response.data.geodata
            ? ({
                meetingPoint: response.data.geodata.meetingPoint,
                polygon: {
                  type: 'Feature',
                  geometry: {
                    type: 'Polygon',
                    coordinates: response.data.geodata.polygon,
                  },
                },
                center: {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: response.data.geodata.point,
                  },
                },
              } as Geodata)
            : undefined,
        },
        errors: response.errors,
        success: response.success,
      }),
    }),
    getProjectComments: builder.query<
      ActorBaseResponse<GetProjectCommentsResponse>,
      GetProjectCommentsRequest
    >({
      query: (request) => {
        const { archived, ...req } = request;
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-comment/get-comment-info-by-project-id'
              : 'comment/get-comment-info-by-project-id',
            arguments: req,
          },
        };
      },
    }),
    getProjectInfoById: builder.query<
      ActorBaseResponse<GetProjectInfoResponse>,
      GetProjectInfoRequest
    >({
      query: (request) => {
        const { archived, ...req } = request;
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-project/get-project-info-by-project-id'
              : 'project/get-project-info-by-project-id',
            arguments: req,
          },
        };
      },
    }),
    getProjectSubscription: builder.query<
      ActorBaseResponse<GetProjectSubscriptionResponse>,
      GetProjectSubscriptionRequest
    >({
      query: (request) => {
        const { archived, ...req } = request;
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-subscribe-to-project/get-subscription-info-by-project-id'
              : 'subscribe-to-project/get-subscription-info-by-project-id',
            arguments: req,
          },
        };
      },
    }),
    getEventParticipantsInfo: builder.query<
      ActorBaseResponse<GetEventParticipantsInfoResponse>,
      GetEventParticipantsInfoRequest
    >({
      query: (request) => {
        const { archived, ...req } = request;
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-join-to-event/get-joined-to-event-info-by-project-id'
              : 'join-to-event/get-joined-to-event-info-by-project-id',
            arguments: req,
          },
        };
      },
    }),
    getConfirmedParticipantsInfo: builder.query<
      ActorBaseResponse<GetConfirmedParticipantsInfoResponse>,
      GetConfirmedParticipantsInfoRequest
    >({
      query: (request) => {
        const { archived, ...req } = request;
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-join-to-event/get-confirmed-to-event-info-by-project-id'
              : 'join-to-event/get-confirmed-to-event-info-by-project-id',
            arguments: req,
          },
        };
      },
    }),
    getEventParticipants: builder.query<
      ActorBaseResponse<GetEventParticipantsResponse>,
      GetEventParticipantsRequest
    >({
      query: (request) => {
        const { archived, ...req } = request;
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-join-to-event/get-event-participants'
              : 'join-to-event/get-event-participants',
            arguments: req,
          },
        };
      },
    }),
    getConfirmedParticipants: builder.query<
      ActorBaseResponse<GetConfirmedParticipantsResponse>,
      GetConfirmedParticipantsRequest
    >({
      query: (request) => {
        const { archived, ...req } = request;
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-join-to-event/get-confirmed-participants'
              : 'join-to-event/get-confirmed-participants',
            arguments: req,
          },
        };
      },
    }),
    getAllParticipants: builder.query<
      ActorBaseResponse<GetAllParticipantsResponse>,
      GetAllParticipantsRequest
    >({
      query: (request) => {
        const { archived, ...req } = request;
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-join-to-event/get-all-participants'
              : 'join-to-event/get-all-participants',
            arguments: req,
          },
        };
      },
    }),
    updateConfirmedParticipants: builder.mutation<
      ActorBaseResponse<void>,
      UpdateConfirmedParticipantsRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'join-to-event/update-confirmed-participants',
          arguments: request,
        },
      }),
    }),
    getProjectsInfoWithGeodata: builder.query<
      ActorBaseResponse<GetProjectsInfoWithGeodataResponse>,
      GetProjectsInfoWithGeodataRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'geodata/get-projects-info-with-geodata',
          arguments: request,
        },
        url: '',
        params: { chain: 'geodata__get-projects-info-with-geodata' },
      }),
      keepUnusedDataFor: 0,
      transformResponse: (
        response: ActorBaseResponse<TempGetProjectsInfoWithGeodataResponse>
      ): ActorBaseResponse<GetProjectsInfoWithGeodataResponse> => ({
        ...response,
        data: {
          ...response.data,
          projectInfo: response.data.projectInfo.map((item) => ({
            ...item,
            geo_data: {
              polygon: {
                type: 'Feature',
                geometry: {
                  type: 'Polygon',
                  coordinates: JSON.parse(item.geo_data?.polygon ?? '[]'),
                },
              },
              center: {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: JSON.parse(item.geo_data?.point ?? '[]'),
                },
              },
            } as Geodata,
          })),
        },
      }),
      providesTags: ['PROJECTS'],
    }),
  }),
});

export const {
  useLazyCreateProjectSpecialistQuery,
  useLazyCheckUserIsMemberOfProjectQuery,
  useGetProjectTeamQuery,
  useLazyGetProjectTeamQuery,
  useGetProjectIncomingTeamQuery,
  useLazyGetProjectIncomingTeamQuery,
  useGetProjectOutgoingTeamQuery,
  useLazyGetProjectOutgoingTeamQuery,
  useDeleteProjectSpecialistMutation,
  useGetProjectsQuery,
  useGetProjectByIdQuery,
  useGetProjectDescriptionQuery,
  useLazyGetProjectsQuery,
  useCreateGeodataMutation,
  useCreateProjectTimelineMutation,
  useGetProjectTimelineQuery,
  useLazyGetProjectTimelineQuery,
  useLazyCreateProjectQuery,
  useLazyGetProjectByIdQuery,
  useLazyCreateDescriptionQuery,
  useGetProjectsCategoriesCountQuery,
  useLazyGetProjectsCatalogInfoQuery,
  useLazyGetProjectsBySpecialistQuery,
  useLazyGetUserProjectsQuery,
  useResetSubscriptionsMutation,
  useGetProjectTypesQuery,
  useLazyGetProjectStepsQuery,
  useGetProjectGeodataQuery,
  useGetProjectCommentsQuery,
  useGetProjectSubscriptionQuery,
  useGetProjectInfoByIdQuery,
  useLazyGetProjectInfoByIdQuery,
  useGetEventParticipantsInfoQuery,
  useGetConfirmedParticipantsInfoQuery,
  useGetEventParticipantsQuery,
  useLazyGetEventParticipantsQuery,
  useGetConfirmedParticipantsQuery,
  useLazyGetConfirmedParticipantsQuery,
  useGetAllParticipantsQuery,
  useLazyGetAllParticipantsQuery,
  useUpdateConfirmedParticipantsMutation,
  useLazyGetProjectStatusesByTypeQuery,
  useLazyGetProjectsInfoWithGeodataQuery,
} = projectsApi;

export const { resetApiState } = projectsApi.util;

export const projectMiddleware = projectsApi.middleware;

export default projectsApi.reducer;
