export const routes = {
  root: {
    index: '/',
    map: 'map',
    admin: 'admin',
    projects: 'projects',
    members: 'members',
    reporting: 'reporting',
    instruction: 'instruction',
    notifications: 'notifications',
    confirmation: 'confirmation',
    donation: 'donation',
  },
  project: {
    creationIndex: '/creation',
    editingIndex: '/edit',
    status: 'status',
    menu: 'menu',
    algorithm: 'algorithm',
    idea: 'idea',
    area: 'area',
    photos: 'photos',
    participants: 'participants',
    docs: 'docs',
    team: 'team',
    dates: 'dates',
    thanks: 'thanks',
    map: 'map',
    diary: 'diary',
    diaryCreation: 'creation',
    budget: 'budget',
    comments: 'comments',
    deny: 'deny',
    unsubscribe: 'unsubscribe',
    accept: 'accept',
    support: 'support',
    donation: 'donation',
    donationMobile: 'donation-mobile',
  },
  profile: {
    index: '/profile',
    menu: 'menu',
    signIn: 'signin',
    signUp: 'signup',
    confirm: 'confirm',
    confirmed: 'confirmed',
    forgotPassword: 'forgot-password',
    forgotPasswordSent: 'forgot-password-sent',
    recover: 'recover',
    recoverSuccess: 'recover-success',
    info: 'info',
    participations: 'participations',
    projects: 'projects',
    payments: 'payments',
    subscriptions: 'subscriptions',
    becomeSpecialist: 'specialist',
    autopaymentsManagement: 'autopayments-management',
    autopaymentsManagementSent: 'autopayments-management-sent',
  },
  payments: {
    index: '/payments',
    all: 'all',
    recurrent: 'recurrent',
    requestAccessToRecurrentPayments: 'request-access-to-recurrent-payments',
  },
  wildcard: '*',
  id: ':id',
  diaryId: ':diaryId',
  projectId: 'projectId',
  comments: 'comments',
  tokenId: 'tokenId',
  requestId: ':requestId',
  donationSuccess: 'donation-success',
  donationFailure: 'donation-failure',
};

export const paths = {
  frontPage: routes.root.index,
  frontPageDonation: `${routes.root.index}#${routes.root.donation}`,
  map: `${routes.root.index}${routes.root.map}`,
  mapWithRecoveryToken: (token: string) =>
    `${routes.root.index}${routes.root.map}?${routes.tokenId}=${token}`,
  projects: `${routes.root.index}${routes.root.projects}`,
  members: `${routes.root.index}${routes.root.members}`,
  reporting: `${routes.root.index}${routes.root.reporting}`,
  instruction: `${routes.root.index}${routes.root.instruction}`,
  notifications: `${routes.root.index}${routes.root.notifications}`,
  confirmation: `${routes.root.index}${routes.root.confirmation}`,
  creation: `${routes.project.creationIndex}`,
  algorithm: `${routes.project.creationIndex}/${routes.project.algorithm}`,
  idea: `${routes.project.creationIndex}/${routes.project.idea}`,
  area: `${routes.project.creationIndex}/${routes.project.area}`,
  docs: `${routes.project.creationIndex}/${routes.project.docs}`,
  team: `${routes.project.creationIndex}/${routes.project.team}`,
  dates: `${routes.project.creationIndex}/${routes.project.dates}`,
  thanks: `${routes.project.creationIndex}/${routes.project.thanks}`,
  selectAreaMap: `${routes.project.creationIndex}/${routes.project.area}/${routes.project.map}`,
  admin: `${routes.root.index}${routes.root.admin}`,

  status: `${routes.project.editingIndex}/${routes.project.status}`,
  menu: `${routes.project.editingIndex}/${routes.project.menu}`,
  ideaEdit: `${routes.project.editingIndex}/${routes.project.idea}`,
  areaEdit: `${routes.project.editingIndex}/${routes.project.area}`,
  areaMapEdit: `${routes.project.editingIndex}/${routes.project.area}/${routes.project.map}`,
  photosEdit: `${routes.project.editingIndex}/${routes.project.photos}`,
  docsEdit: `${routes.project.editingIndex}/${routes.project.docs}`,
  teamEdit: `${routes.project.editingIndex}/${routes.project.team}`,
  diary: `${routes.project.editingIndex}/${routes.project.diary}`,
  diaryCreation: `${routes.project.editingIndex}/${routes.project.diary}/${routes.project.diaryCreation}`,
  budgetEdit: `${routes.project.editingIndex}/${routes.project.budget}`,

  creationStep: (step: string) => `${paths.creation}/${step}`,
  editingStep: (step: string, projectId: string) =>
    `${routes.project.editingIndex}/${step}/${projectId}`,
  projectOverviewCardById: (id: string) =>
    `${paths.map}?${routes.projectId}=${id}`,
  projectById: (id: string) => `${paths.projects}/${id}`,
  projectDonationById: (id: string) =>
    `${paths.projects}/${id}/${routes.project.donation}`,
  projectMobileDonationById: (id: string) =>
    `${paths.projects}/${id}/${routes.project.donationMobile}`,
  projectByIdWithComments: (id: string) =>
    `${paths.projects}/${id}?${routes.comments}=true`,
  diaryById: (id: string) => `${paths.projects}/${routes.project.diary}/${id}`,
  editProjectStatusById: (id: string) => `${paths.status}/${id}`,
  editProjectMenuById: (id: string) => `${paths.menu}/${id}`,
  editProjectByIdDiaryCreation: (id: string) =>
    `${paths.diary}/${id}/${routes.project.diaryCreation}`,
  commentsByProjectId: (id: string) =>
    `${paths.projects}/${id}/${routes.project.comments}`,
  support: (id: string) => `${paths.projects}/${id}/${routes.project.support}`,

  donationFailureProject: (id: string) =>
    `${paths.projectById(id)}/${routes.donationFailure}`,
  donationFailurePlatform: `${routes.root.index}${routes.donationFailure}`,

  profile: `${routes.profile.index}`,
  profileMenu: `${routes.profile.index}/${routes.profile.menu}`,
  signIn: `${routes.profile.index}/${routes.profile.signIn}`,
  signUp: `${routes.profile.index}/${routes.profile.signUp}`,
  confirm: `${routes.profile.index}/${routes.profile.confirm}`,
  confirmed: `${routes.profile.index}/${routes.profile.confirmed}`,
  recoverPassword: `${routes.profile.index}/${routes.profile.recover}`,
  recoverPasswordSuccess: `${routes.profile.index}/${routes.profile.recoverSuccess}`,
  forgotPassword: `${routes.profile.index}/${routes.profile.forgotPassword}`,
  forgotPasswordSent: `${routes.profile.index}/${routes.profile.forgotPasswordSent}`,
  profileInfo: `${routes.profile.index}/${routes.profile.info}`,
  profileParticipations: `${routes.profile.index}/${routes.profile.participations}`,
  profilePayments: `${routes.profile.index}/${routes.profile.payments}`,
  profileAllPayments: `${routes.profile.index}${routes.payments.index}/${routes.payments.all}`,
  profileRecurrentPayments: `${routes.profile.index}${routes.payments.index}/${routes.payments.recurrent}`,
  profileProjects: `${routes.profile.index}/${routes.profile.projects}`,
  userProjects: `${routes.profile.index}/${routes.profile.projects}`,
  userSubscriptions: `${routes.profile.index}/${routes.profile.subscriptions}`,
  becomeSpecialist: `${routes.profile.index}/${routes.profile.becomeSpecialist}`,
  autopaymentsManagement: `${routes.profile.index}/${routes.profile.autopaymentsManagement}`,
  autopaymentsManagementSent: `${routes.profile.index}/${routes.profile.autopaymentsManagementSent}`,
  allPayments: `${routes.payments.index}/${routes.payments.all}`,
  recurrentPayments: `${routes.payments.index}/${routes.payments.recurrent}`,
};
