import { ReactElement } from 'react';

import { Status } from '@platform-for-public-places/components-library';

import EventParticipants from './EventParticipants/EventParticipants';
import TeamMembers from './TeamMembers/TeamMembers';

export enum ProjectType {
  Default,
  Event,
}

export interface ProjectMembersWrapperProps {
  archived?: boolean;
  projectId: string;
  isAdmin: boolean;
  displayInitiator?: boolean;
  isInitiatorProjectOwner: boolean;
  initiatorName?: string;
  initiatorId?: string;
  onUserClick?: (userId: string) => void;
  emptyTeamLabel?: string;
  memberButtonComponent?: (memberId: string) => JSX.Element;
  memberContainerClassName?: string;
  hideTeamMembers?: boolean;
  previewMemberIdByRedirect?: string;
  status: Status;
  projectType?: ProjectType;
}

const projectMembersMap: Record<
  ProjectType,
  (props: ProjectMembersWrapperProps) => ReactElement
> = {
  [ProjectType.Default]: ({
    archived,
    projectId,
    isAdmin,
    isInitiatorProjectOwner,
    initiatorName,
    initiatorId,
    onUserClick,
    previewMemberIdByRedirect = '',
  }: ProjectMembersWrapperProps): ReactElement => {
    return (
      <TeamMembers
        isAdmin={isAdmin}
        projectId={projectId}
        isInitiatorProjectOwner={isInitiatorProjectOwner}
        initiatorName={initiatorName}
        initiatorId={initiatorId}
        onUserClick={onUserClick}
        previewMemberIdByRedirect={previewMemberIdByRedirect}
        archived={archived}
      />
    );
  },
  [ProjectType.Event]: ({
    archived,
    projectId,
    isAdmin,
    isInitiatorProjectOwner,
    initiatorName,
    initiatorId,
    previewMemberIdByRedirect = '',
    status,
    onUserClick,
  }: ProjectMembersWrapperProps): ReactElement => {
    return (
      <EventParticipants
        isAdmin={isAdmin}
        projectId={projectId}
        isInitiatorProjectOwner={isInitiatorProjectOwner}
        initiatorName={initiatorName}
        initiatorId={initiatorId}
        previewMemberIdByRedirect={previewMemberIdByRedirect}
        status={status}
        onUserClick={onUserClick}
        archived={archived}
      />
    );
  },
};

const ProjectMembersWrapper = ({
  archived,
  projectId,
  isAdmin,
  displayInitiator = true,
  isInitiatorProjectOwner,
  initiatorName,
  initiatorId,
  onUserClick,
  previewMemberIdByRedirect = '',
  projectType = ProjectType.Default,
  status,
}: ProjectMembersWrapperProps): JSX.Element => {
  const getComponentByType = (projectType: ProjectType) => {
    return projectMembersMap[projectType]({
      archived,
      projectId,
      isAdmin,
      displayInitiator,
      isInitiatorProjectOwner,
      initiatorName,
      initiatorId,
      onUserClick,
      previewMemberIdByRedirect,
      projectType,
      status,
    });
  };

  return <>{getComponentByType(projectType)}</>;
};

export default ProjectMembersWrapper;
