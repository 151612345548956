import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Icon,
  IconType,
  MinIoUrl,
  UserCard,
} from '@platform-for-public-places/components-library';

import CatalogAvatar from 'src/shared/components/CatalogUsers/components/CatalogAvatar/CatalogAvatar';
import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import {
  useTranslationStatus,
  useUpdateProfile,
  useUserCardTranslation,
} from 'src/features/hooks';

import './Profile.scss';

const Profile = () => {
  const translateStatus = useTranslationStatus();
  const { t } = useTranslation('app', { keyPrefix: 'profile' });
  const userCardTitles = useUserCardTranslation();

  const { isSuccess: isAuthorized, data: user, isFetching } = useWhoamiQuery();
  const [getProfile, profileData] = useUpdateProfile();

  useEffect(() => {
    if (!isFetching && isAuthorized && user?.data.userID) {
      getProfile(user.data.userID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, isFetching, user?.data.userID]);

  const renderProfile = () => {
    if (profileData && !profileData.isFetching && profileData.isSuccess) {
      const { data: profile } = profileData.data;
      return profile.portfolio ? (
        <div className="profile">
          <h2 className="profile-layout__title profile__title">
            {t('info.tabName')}
          </h2>
          <UserCard
            className="profile__user-card"
            user={profile}
            imageComponent={
              <CatalogAvatar
                avatar={
                  (profile.avatar as MinIoUrl[])?.[0].key
                    ? (profile.avatar as MinIoUrl[])?.[0].url
                    : ''
                }
                url={false}
              />
            }
            titles={userCardTitles}
            translateStatus={translateStatus}
            getProjectPath={paths.projectById}
          />
        </div>
      ) : (
        <div className="profile">
          <h2 className="profile-layout__title profile__title">
            {t('info.tabName')}
          </h2>
          <h3 className="profile__name">{profile.name || '—'}</h3>
          <div className="profile__contact">
            <Icon className="profile__contact-icon" icon={IconType.Email} />
            <span className="profile__contact-value">
              {profile.socialLinks?.email || '—'}
            </span>
          </div>
        </div>
      );
    } else return null;
  };

  return renderProfile();
};

export default Profile;
