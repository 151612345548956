import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  BUTTON_TYPE,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';
import { useLocalStorage } from '@uidotdev/usehooks';

import { useGetPresignedUrlsQuery } from 'src/features/minIo/api';
import { PresignedUrlByKeyMapId } from 'src/features/minIo/models';
import { State } from 'src/features/store/store';

import {
  COOKIE,
  setAnimationActive,
  setShowCookieCard,
} from '../../slices/cookieSlice';

import './CookieCard.scss';

const POLICY_KEYS = ['ppp-privacy-policy.pdf'];

const CookieCard = (): JSX.Element => {
  const { t } = useTranslation('app', { keyPrefix: 'cards.cookie' });
  const isSmallScreen = useCheckSmallScreen();
  const dispatch = useDispatch();
  const showCookieCard = useSelector((s: State) => s[COOKIE].showCookieCard);
  const isAnimationActive = useSelector(
    (s: State) => s[COOKIE].isAnimationActive
  );

  const [, setAllowedCookie] = useLocalStorage<boolean>(COOKIE, false);

  const policyLink =
    useGetPresignedUrlsQuery({
      messageMapId: PresignedUrlByKeyMapId.PRIVACY_POLICY,
      keys: POLICY_KEYS,
    }).data?.data[0]?.url || '/';

  const checkCookieConsent = () => {
    return showCookieCard ? '' : 'cookie-card_disable';
  };

  const checkActivationAnumation = () => {
    return isAnimationActive ? 'cookie-card_animation_wobble' : '';
  };

  const onRejectClick = () => {
    dispatch(setShowCookieCard(false));
    setAllowedCookie(false);
  };

  const onAcceptClick = () => {
    dispatch(setShowCookieCard(false));
    setAllowedCookie(true);
  };

  useEffect(() => {
    if (isAnimationActive) {
      setTimeout(() => {
        dispatch(setAnimationActive(false));
      }, 1000);
    }
  }, [isAnimationActive, dispatch]);

  return isSmallScreen ? (
    <div
      className={`cookie-card cookie-card_mobile ${checkCookieConsent()} ${checkActivationAnumation()}`}
    >
      <header className="cookie-card__header">{t('header')}</header>
      <div className="cookie-card__content">
        <p className="cookie-card__message">
          <Trans
            t={t}
            i18nKey="text"
            components={{
              lnk: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  className="cookie-card__link"
                  rel="noreferrer"
                  href={policyLink}
                  target="_blank"
                />
              ),
            }}
          />
        </p>
      </div>
      <div className="cookie-card__button-block">
        <Button
          onClick={onRejectClick}
          type={BUTTON_TYPE.SECONDARY}
          className="cookie-card__reject"
        >
          {t('buttons.reject')}
        </Button>
        <Button
          onClick={onAcceptClick}
          type={BUTTON_TYPE.PRIMARY}
          className="cookie-card__accept"
        >
          {t('buttons.accept')}
        </Button>
      </div>
    </div>
  ) : (
    <div
      className={`cookie-card ${checkCookieConsent()} ${checkActivationAnumation()}`}
    >
      <header className="cookie-card__header">{t('header')}</header>
      <div className="cookie-card__content">
        <p className="cookie-card__message">
          <Trans
            t={t}
            i18nKey="text"
            components={{
              lnk: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  className="cookie-card__link"
                  rel="noreferrer"
                  href={policyLink}
                  target="_blank"
                />
              ),
            }}
          />
        </p>
      </div>
      <div className="cookie-card__button-block">
        <Button
          onClick={onRejectClick}
          type={BUTTON_TYPE.SECONDARY}
          className="cookie-card__reject"
        >
          {t('buttons.reject')}
        </Button>
        <Button
          onClick={onAcceptClick}
          type={BUTTON_TYPE.PRIMARY}
          className="cookie-card__accept"
        >
          {t('buttons.accept')}
        </Button>
      </div>
    </div>
  );
};

export default CookieCard;
