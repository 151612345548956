import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  GetFilesResponse,
  MIME_CSV,
  MIME_DOC,
  MIME_DOCM,
  MIME_DOCX,
  MIME_JPEG,
  MIME_ODT,
  MIME_PDF,
  MIME_PNG,
  MIME_TXT,
  MIME_XLS,
  MIME_XLSX,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import FileInput from 'src/shared/components/inputs/FileInput/FileInput';

import FilePreview from 'src/features/files/components/FilePreview/FilePreview';
import { FileLinkDoc } from 'src/features/files/models';
import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import { useUploadFiles } from 'src/pages/project/hooks';

const MAX_DOCS = 1;
const BUDGET_TYPES = [
  MIME_PNG,
  MIME_JPEG,
  MIME_PDF,
  MIME_CSV,
  MIME_XLS,
  MIME_XLSX,
  MIME_DOC,
  MIME_DOCM,
  MIME_ODT,
  MIME_DOCX,
  MIME_TXT,
];

interface BudgetDocProps {
  files: GetFilesResponse[];
  link: string;
  onFiles: (files: GetFilesResponse[]) => void;
  onLink: (link: string) => void;
  onDelete: () => void;
}

const BudgetDoc = ({
  files,
  link,
  onFiles,
  onLink,
  onDelete,
}: BudgetDocProps) => {
  const { t } = useTranslation();
  const isSmallScreen = useCheckSmallScreen();

  const [docsError, setDocsError] = useState<string>('');

  const docsOverflow = useMemo(() => files.length >= MAX_DOCS, [files.length]);

  const acceptString = BUDGET_TYPES.join(', ');

  const projectType = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );

  const uploadFiles = useUploadFiles(
    BUDGET_TYPES,
    onFiles,
    setDocsError,
    docsError || t(`editing.${projectType}.budget.badFileFormat`)
  );

  const onDocsLink = (link: string) => onLink(link);

  const onDocsFiles = (files: File[]) => uploadFiles(files, MAX_DOCS);

  const getBudgetDocWrapper = () => {
    const doc = link ? { link: link } : (files[0] as FileLinkDoc);
    return (
      <FilePreview
        onRemoveClick={onDelete}
        document={doc}
        className="budget-doc__preview"
      />
    );
  };

  const onLinkBlur = () => {
    if (!link) {
      setDocsError(t('fileInput.required'));
    }
  };

  return (
    <>
      {!files.length && link === '' ? (
        <FileInput
          erase
          multiple
          accept={acceptString}
          disabled={docsOverflow}
          error={docsError}
          onLink={onDocsLink}
          onFiles={onDocsFiles}
          onError={setDocsError}
          onBlur={onLinkBlur}
          showMobileLinkInput
          placeholder={
            isSmallScreen
              ? t(`editing.${projectType}.budget.fileLinkPlaceholder`)
              : t(`editing.${projectType}.budget.fileInputPlaceholder`)
          }
        />
      ) : (
        getBudgetDocWrapper()
      )}
    </>
  );
};

export default BudgetDoc;
