import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDispatch, useSelector } from 'react-redux';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { SpringEvent } from 'react-spring-bottom-sheet/dist/types';

import {
  Button,
  BUTTON_TYPE,
  CategoryButton,
  GetProjectResponse,
  Icon,
  IconType,
  MinIoUrl,
  NOTIFICATION_PAYLOAD,
  NOTIFICATION_TYPE,
  NotificationPopup,
  PROJECT_STATUS,
  TextArea,
  useCheckSmallScreen,
  UserCard,
  UserProfile,
} from '@platform-for-public-places/components-library';

import CatalogAvatar from 'src/shared/components/CatalogUsers/components/CatalogAvatar/CatalogAvatar';
import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import { USER_ROLE } from 'src/features/auth/models';
import {
  useCheckUser,
  useGetProjectsWithImages,
  useTranslationStatus,
  useUserCardTranslation,
} from 'src/features/hooks';
import StageHeader from 'src/features/layout/components/StageHeader/StageHeader';
import AddPersonToTeam from 'src/features/modal/components/AddPersonToTeam/AddPersonToTeam';
import {
  NOTIFICATION,
  setVisible,
} from 'src/features/notification/slices/notificationSlice';
import {
  useDeleteProjectSpecialistMutation,
  useGetProjectTeamQuery,
  useLazyGetProjectIncomingTeamQuery,
  useLazyGetProjectOutgoingTeamQuery,
  useLazyGetProjectsBySpecialistQuery,
} from 'src/features/project/api';
import { ProjectTeamRequests } from 'src/features/project/models';
import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import { setMemberToAdd } from 'src/features/project/slices/editingProjectSlice';
import { PROJ_LAYER } from 'src/features/project/slices/projectsLayerSlice';
import {
  useLazyAcceptSpecialistsByRequestIdQuery,
  useLazyRejectRequestByIdRequestQuery,
} from 'src/features/request/api';
import {
  FILTER_DATA,
  setSelectedUserId,
  setTeam,
} from 'src/features/searchWithFilter/filter/slices/filterSliceData';
import { State } from 'src/features/store/store';
import { useLazyGetProfileQuery } from 'src/features/user/api';
import { ProjectSpecialist } from 'src/features/user/models';

import { TYPE_RENDER } from 'src/pages/project/DetailedProjectInfo/component/ProjectMembersWrapper/MembersHandler/EventParticipants/EventParticipantsList/EventParticipantsList';
import TeamMembersList from 'src/pages/project/DetailedProjectInfo/component/ProjectMembersWrapper/MembersHandler/TeamMembers/TeamMembersList/TeamMembersList';

import { MobileListSelector, TABLE_TAB } from '../../models';

import { MemberRequestCardType } from '../../components/MemberRequestCard/MemberRequestCard';
import MemberRequestsList from '../../components/MemberRequestsList/MemberRequestsList';
import MembersIncomingTable from '../../components/MembersIncomingTable/MembersIncomingTable';
import MembersOutgoingTable from '../../components/MembersOutgoingTable/MembersOutgoingTable';

import './EditingTeam.scss';

const TABLES = {
  [TABLE_TAB.INCOMING]: MembersIncomingTable,
  [TABLE_TAB.OUTGOING]: MembersOutgoingTable,
};

const INITIAL_COUNT = -1;

const noRequests: ProjectTeamRequests = {
  specialists: [],
  count: INITIAL_COUNT,
};

const PAGE = 1;
const PAGE_SIZE = 20;

const EditingTeam = () => {
  const dispatch = useDispatch();
  const isSmallScreen = useCheckSmallScreen();
  const currentProjectType = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );

  const { t } = useTranslation('app', {
    keyPrefix: `creation.${currentProjectType}.team`,
  });

  const isAdmin = useCheckUser([{ role: USER_ROLE.ADMIN }]);

  const { data: whoami } = useWhoamiQuery();
  const [getUserProfile] = useLazyGetProfileQuery();
  const [getIncoming] = useLazyGetProjectIncomingTeamQuery();
  const [getOutgoing] = useLazyGetProjectOutgoingTeamQuery();
  const [acceptRequest] = useLazyAcceptSpecialistsByRequestIdQuery();
  const [deleteProjectSpecialist] = useDeleteProjectSpecialistMutation();
  const [rejectTeamMemberRequest] = useLazyRejectRequestByIdRequestQuery();

  const [message, setMessage] = useState('');
  const [currentTable, setCurrentTable] = useState<TABLE_TAB>(
    TABLE_TAB.INCOMING
  );
  const [selectedUser, setSelectedUser] = useState<UserProfile | null>(null);
  const [selectedRequestId, setSelectedRequestId] = useState<string | null>(
    null
  );
  const [notification, setNotification] = useState<NOTIFICATION_PAYLOAD>({
    message: '',
    type: NOTIFICATION_TYPE.NOTIFICATION,
  });
  const [activeMobileSelector, setActiveMobileSelector] =
    useState<MobileListSelector>(MobileListSelector.TEAM);
  const [addUserSheetVisible, setAddUserSheetVisible] = useState(false);
  const [userInfoSheetVisible, setUserInfoSheetVisible] = useState(false);
  const [addPersonVisible, setAddPersonVisible] = useState<boolean>(false);
  const [displayRejectSheet, setDisplayRejectSheet] = useState<boolean>(false);
  const [rejectComment, setRejectComment] = useState<string>('');

  const [incomingPage, setIncomingPage] = useState<number>(0);
  const [outgoingPage, setOutgoingPage] = useState<number>(0);
  const [incomingError, setIncomingError] = useState<boolean>(false);
  const [outgoingError, setOutgoingError] = useState<boolean>(false);
  const [incomingRequests, setIncomingRequests] =
    useState<ProjectTeamRequests>(noRequests);
  const [outgoingRequests, setOutgoingRequests] =
    useState<ProjectTeamRequests>(noRequests);

  const team = useSelector((s: State) => s[FILTER_DATA].team);
  const projectId = useSelector((s: State) => s[PROJ_CREATE].projectId);
  const isVisible = useSelector((s: State) => s[NOTIFICATION].isVisible);
  const projectInfo = useSelector(
    (s: State) => s[PROJ_LAYER].currentProjectInfo
  );

  const currentProject = projectInfo?.projectInfo;

  const translateStatus = useTranslationStatus();
  const userCardTitles = useUserCardTranslation();

  const [projectsPage, setProjectsPage] = useState<number>(1);
  const [projectsError] = useState<boolean>(false);
  const [projectsLoading, setProjectsLoading] = useState<boolean>(false);
  const [hasProjectsNextPage, setHasProjectsNextPage] = useState<boolean>(true);
  const [projects, setProjects] = useState<GetProjectResponse[]>([]);

  const [getProjectsBySpecialist] = useLazyGetProjectsBySpecialistQuery();
  const [getProjectsWithImages] = useGetProjectsWithImages();

  const [getProjects] = useInfiniteScroll({
    loading: projectsLoading,
    hasNextPage: hasProjectsNextPage,
    onLoadMore: () => {
      if (selectedUser) {
        setProjectsLoading(true);
        getProjectsBySpecialist({
          userId: selectedUser.userId,
          statuses: [
            PROJECT_STATUS.ACCEPTED,
            PROJECT_STATUS.APPROVED,
            PROJECT_STATUS.IMPLEMENTED,
            PROJECT_STATUS.FINISHED,
            PROJECT_STATUS.TRANSFERRED,
          ],
          page: projectsPage,
          pageSize: PAGE_SIZE,
        }).then((response) => {
          if (response.data?.data) {
            getProjectsWithImages(response.data?.data ?? []).then((res) =>
              setProjects((prev) => [...prev, ...res])
            );
            setProjectsPage(projectsPage + 1);
            setProjectsLoading(false);
            setHasProjectsNextPage(response.data?.data.length === PAGE_SIZE);
          }
        });
      }
    },
    disabled: projectsError,
  });

  const clearProjects = () => {
    setHasProjectsNextPage(true);
    setProjectsPage(0);
    setProjects([]);
  };

  const { data: remoteTeam } = useGetProjectTeamQuery(
    {
      projectId: projectId as string,
      pageSize: PAGE_SIZE,
      page: PAGE,
    },
    { skip: !projectId }
  );

  const initData = () => {
    if (remoteTeam?.data.specialists.length) {
      dispatch(setTeam(remoteTeam.data.specialists));
    }
  };

  useEffect(initData, [dispatch, remoteTeam?.data.specialists]);

  const fetchIncomingRequests = useCallback(() => {
    if (
      projectId &&
      (incomingRequests.count === INITIAL_COUNT ||
        incomingRequests.specialists.length < incomingRequests.count)
    ) {
      getIncoming({ projectId, page: incomingPage + 1, pageSize: PAGE_SIZE })
        .unwrap()
        .then(({ data: reqs, success, errors }) => {
          if (success && !errors.length && reqs.specialists.length) {
            setIncomingPage((prev) => prev + 1);
            setIncomingRequests((prev) => ({
              ...prev,
              specialists: [...prev.specialists, ...reqs.specialists],
              count: reqs.count,
            }));
          } else {
            setIncomingError(true);
          }
        })
        .catch(() => setIncomingError(true));
    }
  }, [
    getIncoming,
    incomingPage,
    incomingRequests.count,
    incomingRequests.specialists.length,
    projectId,
  ]);

  const fetchOutgoingRequests = useCallback(() => {
    if (
      projectId &&
      (outgoingRequests.count === INITIAL_COUNT ||
        outgoingRequests.specialists.length < outgoingRequests.count)
    ) {
      getOutgoing({ projectId, page: outgoingPage + 1, pageSize: PAGE_SIZE })
        .unwrap()
        .then(({ data: reqs, success, errors }) => {
          if (success && !errors.length) {
            setOutgoingPage((prev) => prev + 1);
            setOutgoingRequests((prev) => ({
              ...prev,
              specialists: [...prev.specialists, ...reqs.specialists],
              count: reqs.count,
            }));
          } else {
            setOutgoingError(true);
          }
        })
        .catch(() => setOutgoingError(true));
    }
  }, [
    getOutgoing,
    outgoingPage,
    outgoingRequests.count,
    outgoingRequests.specialists.length,
    projectId,
  ]);

  const TABLES_DATA = useMemo(
    () => ({
      [TABLE_TAB.INCOMING]: incomingRequests,
      [TABLE_TAB.OUTGOING]: outgoingRequests,
    }),
    [incomingRequests, outgoingRequests]
  );

  const TABLE_FETCHERS = useMemo(
    () => ({
      [TABLE_TAB.INCOMING]: fetchIncomingRequests,
      [TABLE_TAB.OUTGOING]: fetchOutgoingRequests,
    }),
    [fetchIncomingRequests, fetchOutgoingRequests]
  );

  const TABLE_ERRORS = useMemo(
    () => ({
      [TABLE_TAB.INCOMING]: incomingError,
      [TABLE_TAB.OUTGOING]: outgoingError,
    }),
    [incomingError, outgoingError]
  );

  const hideNotification = () => dispatch(setVisible(false));

  const addUserInTeam = async () => {
    const setter = isSmallScreen ? setAddUserSheetVisible : setAddPersonVisible;
    setter(true);
  };

  useEffect(() => {
    if (incomingRequests.count < 0 && !incomingError) {
      fetchIncomingRequests();
    }
  }, [fetchIncomingRequests, incomingError, incomingRequests.count]);

  useEffect(() => {
    if (outgoingRequests.count < 0 && !outgoingError) {
      fetchOutgoingRequests();
    }
  }, [fetchOutgoingRequests, outgoingError, outgoingRequests.count]);

  const onRequestActionSuccess = useCallback(
    (messageKey: string) => {
      setNotification({
        message: t(messageKey),
        type: NOTIFICATION_TYPE.NOTIFICATION,
      });
      dispatch(setVisible(true));
    },
    [dispatch, t]
  );

  const onRequestActionError = useCallback(() => {
    setNotification({
      message: t('notifications.failed'),
      type: NOTIFICATION_TYPE.ERROR,
    });
    dispatch(setVisible(true));
  }, [dispatch, t]);

  const deleteUser = useCallback(
    (id: string) => {
      deleteProjectSpecialist({
        projectSpecialistId: id,
      });
      dispatch(setSelectedUserId(id));
      setNotification({
        message: t('deletionNotification'),
        type: NOTIFICATION_TYPE.NOTIFICATION,
      });
      dispatch(setVisible(true));
    },
    [deleteProjectSpecialist, dispatch, t]
  );

  const memberDeleteButton = useCallback(
    (memberId: string) => {
      return (
        <Button
          type={BUTTON_TYPE.TEXT_SECONDARY}
          icon
          className="team-member__delete-button"
          onClick={() => deleteUser(memberId)}
        >
          <Icon icon={IconType.Bin} />
        </Button>
      );
    },
    [deleteUser]
  );

  const onClickSelectionButton = useCallback(
    (selectorType: MobileListSelector) => {
      setActiveMobileSelector(selectorType);
    },
    []
  );

  const closeBottomSheet = (event: SpringEvent) => {
    if (event.type === 'CLOSE') {
      setSelectedUser(null);
      setSelectedRequestId(null);
      clearProjects();
    }
  };

  const renderButtons = useCallback(() => {
    return (
      <div className="team__buttons">
        <Button
          type={BUTTON_TYPE.TEXT_SECONDARY}
          className={`team-buttons__button ${
            activeMobileSelector === MobileListSelector.TEAM
              ? 'team-buttons__button--active'
              : ''
          }`}
          onClick={() => onClickSelectionButton(MobileListSelector.TEAM)}
        >
          {t('mobileMenu.team')}
        </Button>
        <Button
          type={BUTTON_TYPE.TEXT_SECONDARY}
          className={`team-buttons__button ${
            activeMobileSelector === MobileListSelector.INCOMING
              ? 'team-buttons__button--active'
              : ''
          }`}
          onClick={() => onClickSelectionButton(MobileListSelector.INCOMING)}
        >
          {t('mobileMenu.incoming', {
            count: incomingRequests.count > 0 ? incomingRequests.count : 0,
          })}
        </Button>
        <Button
          type={BUTTON_TYPE.TEXT_SECONDARY}
          className={`team-buttons__button ${
            activeMobileSelector === MobileListSelector.OUTGOING
              ? 'team-buttons__button--active'
              : ''
          }`}
          onClick={() => onClickSelectionButton(MobileListSelector.OUTGOING)}
        >
          {t('mobileMenu.outcoming', {
            count: outgoingRequests.count > 0 ? outgoingRequests.count : 0,
          })}
        </Button>
      </div>
    );
  }, [
    t,
    activeMobileSelector,
    incomingRequests.count,
    outgoingRequests.count,
    onClickSelectionButton,
  ]);

  const renderUsers = useCallback(() => {
    return (
      <div
        className={`team__members ${team.length ? '' : 'team__members-no-one'}`}
      >
        <TeamMembersList
          projectId={projectId as string}
          isAdmin={isAdmin}
          isSmallScreen={isSmallScreen}
          isInitiatorProjectOwner={
            currentProject?.ownerId === whoami?.data.userID
          }
          displayInitiator={false}
          emptyTeamLabel={t('emptyTeam')}
          memberButtonComponent={memberDeleteButton}
          memberContainerClassName={'team-member__container'}
          onUserClick={(userId: string) => {
            setUserInfoSheetVisible(true);
            getUserProfile(userId).then((response) => {
              setSelectedUser(response.data?.data || null);
            });
            clearProjects();
          }}
          typeRender={
            isSmallScreen
              ? TYPE_RENDER.SHOW_ALL_TEAM
              : TYPE_RENDER.PREVIEW_MEMBER_WITH_BUTTON_SHOW_MORE
          }
          previewTeamMembers={!isSmallScreen}
        />
      </div>
    );
  }, [
    currentProject?.ownerId,
    whoami?.data.userID,
    getUserProfile,
    isAdmin,
    memberDeleteButton,
    projectId,
    t,
    team.length,
    isSmallScreen,
  ]);

  const listPlaceholder = useMemo(
    () => (
      <span className="team__requests-placeholder">
        {t('noMemberRequests')}
      </span>
    ),
    [t]
  );

  const openUserProfile = useCallback(
    (specialist: ProjectSpecialist) => {
      getUserProfile(specialist.userId).then(({ data: response }) => {
        if (response) {
          setSelectedUser(response.data);
          setUserInfoSheetVisible(true);
          clearProjects();
        }
      });
    },
    [getUserProfile]
  );

  const onMemberRequestClick = useCallback(
    (specialist: ProjectSpecialist, requestId: string) => {
      openUserProfile(specialist);
      setSelectedRequestId(requestId);
    },
    [openUserProfile]
  );

  const onApproveSuccess = useCallback(
    (specialist: ProjectSpecialist) => {
      dispatch(setMemberToAdd(specialist));
      kickFromIncoming(specialist.id);
      onRequestActionSuccess('notifications.approved');
    },
    [dispatch, onRequestActionSuccess]
  );

  const onRejectSuccess = (specialist: ProjectSpecialist) => {
    kickFromIncoming(specialist.id);
    onRequestActionSuccess('notifications.declined');
  };

  const onRequestAccept = useCallback(() => {
    if (selectedRequestId) {
      acceptRequest({ requestId: selectedRequestId })
        .unwrap()
        .then(({ data: response, success, errors }) => {
          if (!success || errors.length) {
            onRequestActionError();
          } else {
            setUserInfoSheetVisible(false);
            setSelectedUser(null);
            setSelectedRequestId(null);
            const specialist = {
              ...response.request.requestBody.projectSpecialist,
              id: response.request.requestBody.projectSpecialist
                .project_specialistID,
            };
            onApproveSuccess(specialist);
          }
        })
        .catch(() => onRequestActionError());
    }
  }, [
    selectedRequestId,
    acceptRequest,
    onRequestActionError,
    onApproveSuccess,
  ]);

  const onPersonInvited = () => {
    setOutgoingError(false);
    setOutgoingPage(0);
    setOutgoingRequests(noRequests);
    fetchOutgoingRequests();
  };

  const kickFromIncoming = (id: string, compareByRequestId = false) =>
    setIncomingRequests((prev) => ({
      ...prev,
      specialists: prev.specialists.filter((s) =>
        compareByRequestId ? s.request.requestID !== id : s.specialist.id !== id
      ),
      count: prev.count > 0 ? prev.count - 1 : 0,
    }));

  const renderIncomingList = useCallback(
    () => (
      <MemberRequestsList
        useTags
        infinite
        className="team__requests-list"
        requests={incomingRequests}
        type={MemberRequestCardType.INCOMING}
        placeholder={listPlaceholder}
        error={incomingError}
        onCardClick={onMemberRequestClick}
        onEndReached={fetchIncomingRequests}
      />
    ),
    [
      incomingError,
      incomingRequests,
      listPlaceholder,
      onMemberRequestClick,
      fetchIncomingRequests,
    ]
  );

  const renderOutgoingList = useCallback(
    () => (
      <MemberRequestsList
        useRoles
        infinite
        className="team__requests-list"
        requests={outgoingRequests}
        type={MemberRequestCardType.OUTGOING}
        placeholder={listPlaceholder}
        error={outgoingError}
        onDelete={() => null} // TODO: attach delete request (postponed for later)
        onCardClick={openUserProfile}
        onEndReached={fetchOutgoingRequests}
      />
    ),
    [
      outgoingError,
      outgoingRequests,
      listPlaceholder,
      openUserProfile,
      fetchOutgoingRequests,
    ]
  );

  const renderButton = () => {
    return isAdmin || whoami?.data.userID === currentProject?.ownerId ? (
      <Button
        className="team__add-button team__add-button--show"
        type={BUTTON_TYPE.SECONDARY}
        icon
        onClick={addUserInTeam}
      >
        <Icon icon={IconType.Personplus} />
        {t('addMember')}
      </Button>
    ) : null;
  };

  const renderTables = () => {
    const Table = TABLES[currentTable];
    const tablePlaceholder = (
      <span className="team__table-placeholder">{t('emptyTable')}</span>
    );

    return (
      <>
        <h2 className="team__table-header">{t('tablesHeader')}</h2>
        <div className="team__table-tabs">
          <CategoryButton
            className="team__table-tab"
            selected={currentTable === TABLE_TAB.INCOMING}
            onClick={() => setCurrentTable(TABLE_TAB.INCOMING)}
          >
            <div className="team__tables-tab-label">
              {t('inboxTab')}
              &nbsp;
              <span className="team__tables-tab-conter">
                {incomingRequests.count > 0 ? incomingRequests.count : 0}
              </span>
            </div>
          </CategoryButton>
          <CategoryButton
            className="team__table-tab"
            selected={currentTable === TABLE_TAB.OUTGOING}
            onClick={() => setCurrentTable(TABLE_TAB.OUTGOING)}
          >
            <div className="team__tables-tab-label">
              {t('sentTab')}
              &nbsp;
              <span className="team__tables-tab-conter">
                {outgoingRequests.count > 0 ? outgoingRequests.count : 0}
              </span>
            </div>
          </CategoryButton>
        </div>
        <Table
          infinite
          className="team__table"
          placeholder={tablePlaceholder}
          data={TABLES_DATA[currentTable]}
          error={TABLE_ERRORS[currentTable]}
          onEndReached={TABLE_FETCHERS[currentTable]}
          requestAdditionalData={{
            onError: onRequestActionError,
            onReject: onRejectSuccess,
            onApprove: onApproveSuccess,
            message,
            setMessage,
          }}
        />
      </>
    );
  };

  const mobileTabRenderers = {
    [MobileListSelector.TEAM]: renderUsers,
    [MobileListSelector.INCOMING]: renderIncomingList,
    [MobileListSelector.OUTGOING]: renderOutgoingList,
  };

  const cancelRejection = () => {
    setDisplayRejectSheet(false);
    setRejectComment('');
  };

  const hideBottomSheet = useCallback(() => {
    cancelRejection();
    setUserInfoSheetVisible(false);
    setSelectedRequestId(null);
  }, []);

  const onRejectClick = useCallback(() => {
    selectedRequestId &&
      rejectTeamMemberRequest({
        requestId: selectedRequestId,
        message: rejectComment,
      })
        .unwrap()
        .then(({ data: response, success, errors }) => {
          if (!success || errors.length) {
            onRequestActionError();
          } else {
            kickFromIncoming(response.requestId, true);
            onRequestActionSuccess('notifications.declined');
          }
        })
        .catch(() => onRequestActionError());

    hideBottomSheet();
  }, [
    hideBottomSheet,
    onRequestActionError,
    onRequestActionSuccess,
    rejectComment,
    rejectTeamMemberRequest,
    selectedRequestId,
  ]);

  const renderUser = useCallback(() => {
    return selectedUser ? (
      <>
        <UserCard
          imageComponent={
            <CatalogAvatar
              rerender
              avatar={(selectedUser.avatar as MinIoUrl[])?.[0]?.key ?? ''}
            />
          }
          showProjects
          user={selectedUser}
          titles={userCardTitles}
          translateStatus={translateStatus}
          getProjectPath={paths.projectById}
          projectsRef={getProjects}
          projects={projects}
          hasNextPage={hasProjectsNextPage}
        />
        {activeMobileSelector === MobileListSelector.INCOMING && (
          <div className="team-member__buttons">
            <Button
              type={BUTTON_TYPE.PRIMARY_OUTLINE}
              className="team-member__button"
              onClick={() => setDisplayRejectSheet(true)}
            >
              <span>{t('reject')}</span>
            </Button>
            <Button
              type={BUTTON_TYPE.PRIMARY}
              className="team-member__button"
              onClick={onRequestAccept}
            >
              <span>{t('accept')}</span>
            </Button>
          </div>
        )}
      </>
    ) : null;
  }, [
    activeMobileSelector,
    getProjects,
    hasProjectsNextPage,
    onRequestAccept,
    projects,
    selectedUser,
    t,
    translateStatus,
    userCardTitles,
  ]);

  const renderRejectForm = useCallback(() => {
    return selectedUser ? (
      <>
        <div className="team-member-reject__container">
          <p className="team-member-reject__title">{t('rejectForm.title')}</p>
          <TextArea
            placeholder={t('declineComment')}
            className="team-member-reject__comment"
            value={rejectComment}
            onChange={(e) => setRejectComment(e.target.value)}
          />
        </div>
        <div className="team-member__buttons">
          <Button
            type={BUTTON_TYPE.PRIMARY_OUTLINE}
            className="team-member__button"
            onClick={cancelRejection}
          >
            <span>{t('rejectForm.cancel')}</span>
          </Button>
          <Button
            type={BUTTON_TYPE.PRIMARY}
            className="team-member__button"
            onClick={onRejectClick}
          >
            <span>{t('rejectForm.send')}</span>
          </Button>
        </div>
      </>
    ) : null;
  }, [onRejectClick, rejectComment, selectedUser, t]);

  const renderName = useCallback(() => {
    const name =
      currentProject?.owner?.name || whoami?.data.profile?.name || '-';
    if (currentProject?.ownerId) {
      const belongsToYou = whoami?.data.userID === currentProject?.ownerId;
      return `${name} ${belongsToYou ? t('you') : ''}`;
    } else {
      return `${name} ${t('you')}`;
    }
  }, [currentProject, whoami?.data.profile?.name, whoami?.data.userID, t]);

  return (
    <>
      <div className={'team team-edit'}>
        <div className="team__title-block">
          <StageHeader
            className="team__title"
            header={t('title')}
            subheader={t('description')}
          />
          {!isSmallScreen ? (
            <div className="team__title-block-button">{renderButton()}</div>
          ) : null}
        </div>

        <div className="team__label-and-name">
          <span className="team__initiator-label">{`${t('initiator')}: `}</span>
          <span className="team__initiator-name">{renderName()}</span>
        </div>

        {isSmallScreen ? renderButtons() : null}

        {isSmallScreen ? (
          <>
            {mobileTabRenderers[activeMobileSelector]()}
            <div className="team__mob-infinite-trigger" />
          </>
        ) : (
          renderUsers()
        )}

        {isSmallScreen ? renderButton() : null}

        {!isSmallScreen ? renderTables() : null}

        <BottomSheet
          open={userInfoSheetVisible}
          blocking
          onDismiss={() => setUserInfoSheetVisible(false)}
          scrollLocking={false}
          onSpringEnd={closeBottomSheet}
        >
          {displayRejectSheet ? renderRejectForm() : renderUser()}
        </BottomSheet>

        <NotificationPopup
          type={notification.type}
          isVisible={isVisible}
          onTimeoutFinished={hideNotification}
          className="team__notification"
        >
          {notification.message}
        </NotificationPopup>

        <BottomSheet
          open={addUserSheetVisible}
          blocking
          onDismiss={() => setAddUserSheetVisible(false)}
          scrollLocking={false}
        >
          <AddPersonToTeam
            onSubmit={onPersonInvited}
            setBottomSheetVisible={setAddUserSheetVisible}
          />
        </BottomSheet>
      </div>
      {addPersonVisible ? (
        <AddPersonToTeam
          onSubmit={onPersonInvited}
          onClose={() => setAddPersonVisible(false)}
        />
      ) : null}
    </>
  );
};

export default EditingTeam;
