import clsx from 'clsx';
import { Props } from 'recharts/types/component/Label';

import { formatNumber } from 'src/shared/functions/numbers';

import { PaymentsStatisticData } from 'src/features/payment/models';

import './ReportingChartLabel.scss';

interface ReportingChartLabelProps extends Props {
  responseData: PaymentsStatisticData[];
}

const ReportingChartLabel = ({
  x,
  y,
  width,
  index,
  value,
  responseData,
}: ReportingChartLabelProps) => (
  <text
    x={(x as number) + (width as number) / 2}
    y={(y as number) - 7}
    className={clsx('reporting-chart-label', {
      'reporting-chart-label_empty': !responseData.find(
        (element: PaymentsStatisticData) => element.month === (index ?? 0) + 1
      ),
    })}
  >
    {formatNumber(Math.round(value as number) ?? 0)}
  </text>
);

export default ReportingChartLabel;
