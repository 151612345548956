import { useEffect, useState } from 'react';

import { Icon, IconType } from '@platform-for-public-places/components-library';

import { useGetImagesPresignedUrlsQuery } from 'src/features/minIo/api';

import './CatalogAvatar.scss';

interface CatalogAvatarProps {
  avatar: string;
  className?: string;
  rerender?: boolean;
  alt?: string;
  url?: boolean;
}

interface UrlAvatarPros {
  avatar: string;
  className?: string;
  rerender?: boolean;
  alt?: string;
}

const ALT = "User's profile";

const UrlAvatar = ({ avatar, className, rerender, alt }: UrlAvatarPros) => {
  const image = useGetImagesPresignedUrlsQuery({
    keys: [avatar],
  });
  const [error, setError] = useState(false);

  useEffect(() => {
    if (rerender && error) {
      setError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  if (image.data?.data.length && !error) {
    return (
      <img
        className={`user-card__avatar ${className}`}
        src={image.data?.data[0]?.url}
        alt={alt ?? ALT}
        onError={() => setError(true)}
      />
    );
  }
  return (
    <Icon className={`user-card__avatar ${className}`} icon={IconType.Avatar} />
  );
};

const CatalogAvatar = ({
  avatar,
  className,
  rerender,
  alt,
  url = true,
}: CatalogAvatarProps) => {
  const renderAvatar = () => {
    if (avatar) {
      if (url) {
        return (
          <UrlAvatar
            className={className}
            avatar={avatar}
            rerender={rerender}
            alt={alt}
          />
        );
      } else {
        return (
          <img
            className={`user-card__avatar ${className}`}
            src={avatar}
            alt={alt ?? ALT}
          />
        );
      }
    } else {
      return (
        <Icon
          className={`user-card__avatar ${className}`}
          icon={IconType.Avatar}
        />
      );
    }
  };
  return renderAvatar();
};

export default CatalogAvatar;
