export enum DATE_TIME_FORMAT {
  LONG = 'long',
  SHORT = 'short',
}

export function getMonthByNumber(
  index: number,
  format:
    | DATE_TIME_FORMAT.LONG
    | DATE_TIME_FORMAT.SHORT = DATE_TIME_FORMAT.LONG,
  locales?: string | string[]
): string {
  const year = new Date().getFullYear();
  const formatter = new Intl.DateTimeFormat(locales, {
    month: format,
  });

  const getMonthName = (monthIndex: number) =>
    formatter.format(new Date(year, monthIndex));

  return getMonthName(index - 1).replace('.', '');
}
