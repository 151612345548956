import { GetFilesResponse } from '@platform-for-public-places/components-library';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const PHOTOS = 'PHOTOS';

interface PhotosModalState {
  photos: GetFilesResponse[];
  openedIndex: number;
}

const initialState: PhotosModalState = {
  photos: [],
  openedIndex: 0,
};

const photosModalSlice = createSlice({
  name: PHOTOS,
  initialState,
  reducers: {
    changePhotos: (
      state: PhotosModalState,
      action: PayloadAction<GetFilesResponse[]>
    ) => {
      state.photos = action.payload;
    },
    changeOpenedIndex: (
      state: PhotosModalState,
      action: PayloadAction<number>
    ) => {
      state.openedIndex = action.payload;
    },
  },
});

export const { changePhotos, changeOpenedIndex } = photosModalSlice.actions;

export default photosModalSlice.reducer;
