import BottomLeftControl from 'src/features/control/BottomLeftControl';
import BottomRightControl from 'src/features/control/BottomRightControl';
import MobileInfoBlock from 'src/features/control/MobileInfoBlock';
import TopLeftControl from 'src/features/control/TopLeftControl';
import TopRightControl from 'src/features/control/TopRightControl';

const Controls = () => {
  return (
    <>
      <TopLeftControl />
      <TopRightControl />
      <BottomLeftControl />
      <BottomRightControl />
      <MobileInfoBlock />
    </>
  );
};

export default Controls;
