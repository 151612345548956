import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  GetFilesResponse,
  Icon,
  IconType,
  timestampToString,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import Digest from 'src/shared/components/Digest/Digest';
import { paths } from 'src/shared/routes';

import { USER_ROLE } from 'src/features/auth/models';
import { useLazyGetDiaryEntriesQuery } from 'src/features/diary/api';
import DiaryEntry from 'src/features/diary/components/DiaryEntry/DiaryEntry';
import { GetDiaryEntriesResponse } from 'src/features/diary/models';
import { useCheckUser, useTranslationStatus } from 'src/features/hooks';
import MobileHeader from 'src/features/mobile/components/MobileHeader/MobileHeader';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import {
  changeOpenedIndex,
  changePhotos,
} from 'src/features/modal/slices/photosModalSlice';
import {
  useGetProjectAndCheck,
  useGetProjectInfoAndCheck,
} from 'src/features/project/hooks';
import { PROJECT_STATUS } from 'src/features/status/enums';

import './DetailedDiary.scss';

const PAGE_SIZE = 20;

interface StateProps {
  goBack: boolean;
}

const DetailedDiary = (): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const mobileView = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'detailedDiary' });

  const [page, setPage] = useState<number>(0);
  const [error] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [digestVisible, setDigestVisible] = useState<boolean>(false);
  const [currentEntryIndex, setEntryIndex] = useState<number>(0);
  const [posts, setPosts] = useState<GetDiaryEntriesResponse>({
    documents: [],
  });

  const project = useGetProjectAndCheck(id);
  const { data: projectInfo } = useGetProjectInfoAndCheck(id);

  const projectType = projectInfo?.data.projectInfo.layer;
  const translateStatus = useTranslationStatus(projectType);

  const [getDiaryEntries] = useLazyGetDiaryEntriesQuery();

  const res = useCheckUser([
    { role: USER_ROLE.ADMIN },
    { role: USER_ROLE.USER, userId: project.data?.data?.initiator?.userID },
  ]);

  // Close photos modal on unmount
  useEffect(() => {
    return () => {
      dispatch(changeModal(null));
      dispatch(changePhotos([]));
      dispatch(changeOpenedIndex(0));
    };
  }, [dispatch]);

  const onPhotoClick = (photos: GetFilesResponse[], openIndex: number) => {
    dispatch(changeModal(MODALS.PICTURE));
    dispatch(changePhotos(photos));
    dispatch(changeOpenedIndex(openIndex));
  };

  const onDigestClick = (index: number) => {
    setDigestVisible(false);
    setTimeout(() => {
      setEntryIndex(index);
    }, 50);
  };

  const toggleDigest = () => setDigestVisible((p) => !p);

  const entries: JSX.Element[] | null = posts.documents.map((e) => (
    <DiaryEntry
      projectId={e.projectId}
      key={e.id}
      entry={e}
      onPhotoClick={onPhotoClick}
    />
  ));

  const entriesDigest: JSX.Element[] = posts.documents.map((e, i) => (
    <Digest
      key={e.id}
      index={i}
      title={
        e.autoCreation && projectType
          ? translateStatus(e.title as PROJECT_STATUS)
          : e.title
      }
      findingClassName="diary-entry"
      current={currentEntryIndex}
      onClick={onDigestClick}
    >
      <span className="digest__date">{timestampToString(e.created, true)}</span>
    </Digest>
  ));
  const visibleClass = (s: TemplateStringsArray) => {
    return digestVisible ? `${s[0]} ${s[0]}--visible` : s[0];
  };

  const onBackClick = () => {
    const locationState = state as StateProps;
    if (locationState?.goBack) {
      navigate(-1);
    } else {
      navigate(paths.projectById(id ?? ''));
    }
  };

  const loadMore = () => {
    if (id) {
      setLoading(true);
      getDiaryEntries({
        page: page + 1,
        pageSize: PAGE_SIZE,
        projectId: id as string,
      })
        .then((resp) => {
          setPosts((previous) => ({
            documents: [
              ...previous.documents,
              ...(resp.data?.data.documents || []),
            ],
          }));
          if (
            !resp.data ||
            (resp.data && resp.data.data.documents.length < PAGE_SIZE)
          ) {
            setHasNextPage(false);
          }
        })
        .catch(() => setHasNextPage(false))
        .finally(() => {
          setPage(page + 1);
          setLoading(false);
        });
    }
  };

  const [getDiary] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: error,
  });

  const [getDigest] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: error,
  });

  return (
    <div className="detailed-diary">
      {mobileView ? (
        <MobileHeader title={t('title')} />
      ) : (
        <Button
          className="detailed-diary__back"
          type={BUTTON_TYPE.TEXT_DARK}
          icon
          onClick={onBackClick}
        >
          <Icon icon={IconType.Arrow} />
          <span>{t('back')}</span>
        </Button>
      )}
      <main className="detailed-diary__diary">
        <div className="detailed-diary__diary-content">
          <div className="detailed-diary__title">
            <div className="detailed-diary__header">
              <h1 className="detailed-diary__header-text">{t('title')}</h1>
              {res ? (
                <Button
                  className="detailed-info__edit"
                  type={BUTTON_TYPE.TEXT_SECONDARY}
                  icon
                  onClick={() => navigate(`${paths.diary}/${id}`)}
                >
                  <Icon icon={IconType.Pencil} />
                  <span>{t('edit')}</span>
                </Button>
              ) : null}
            </div>
            <p className="detailed-diary__subheader-text">{t('subtitle')}</p>
          </div>
          <div className={visibleClass`detailed-diary__digest-dropdown`}>
            <button
              className="detailed-diary__digest-controls"
              onClick={toggleDigest}
            >
              <h4>{t('allEntries')}</h4>
              <Icon icon={IconType.Chevron} />
            </button>
            {digestVisible ? (
              <div className="detailed-diary__digest-list">
                {entriesDigest}
                {posts.documents.length && hasNextPage ? (
                  <div
                    className="digest-list__loading-trigger"
                    ref={getDigest}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
          <div
            className={`detailed-diary__scrollable-content${
              digestVisible ? '--not-display' : ''
            }`}
          >
            {entries}
            {hasNextPage ? (
              <div className="digest-list__loading-trigger" ref={getDiary} />
            ) : null}
          </div>
        </div>

        <aside className="detailed-diary__diary-side">
          <h3 className="detailed-diary__side-title">{t('allEntries')}</h3>
          <div className="detailed-diary__side-scrollable">
            {entriesDigest}
            {posts.documents.length && hasNextPage ? (
              <div className="digest-list__loading-trigger" ref={getDigest} />
            ) : null}
          </div>
        </aside>
      </main>
    </div>
  );
};

export default DetailedDiary;
