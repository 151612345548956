import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import AutopaymentsManagementSent from 'src/features/autopayments/components/AutopaymentsManagementSent/AutopaymentsManagementSent';
import AuthModal from 'src/features/modal/components/AuthModal/AuthModal';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';

import './AutopaymentsManagementSentModal.scss';

const AutopaymentsManagementSentModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('app', {
    keyPrefix: 'autopaymentsManagementSentModal',
  });

  const onClickBack = () => {
    dispatch(changeModal(MODALS.AUTOPAYMENTS_MANAGEMENT));
  };

  return (
    <AuthModal
      className="autopayments-management-sent-modal"
      header={t('header')}
      onClickBack={onClickBack}
    >
      <AutopaymentsManagementSent />
    </AuthModal>
  );
};

export default AutopaymentsManagementSentModal;
