import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  TextInput,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import TextEditor from 'src/shared/components/TextEditor/TextEditor';
import { paths } from 'src/shared/routes';
import { IdeaWidgetProps } from 'src/shared/widgets/models';

import StageHeader from 'src/features/layout/components/StageHeader/StageHeader';
import {
  PROJ_CREATE,
  setIdea,
  setName,
} from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import './IdeaWidget.scss';

const WAR_NAME_SYMBOLS = 100;
const MAX_NAME_SYMBOLS = 500;
const MAX_IDEA_SYMBOLS = 3000;
const ACCEPTABLE_IDEA_REGEX =
  /^<[p|h]{1}[1-3]{0,1}>[<br>|\s]+<\/[p|h]{1}[1-3]{0,1}>$/;

const enum ERRORS {
  EMPTY = 'errors.empty',
  TOO_LONG_NAME = 'errors.tooLongName',
  TOO_LONG_IDEA = 'errors.tooLongIdea',
  ALREADY_EXISTS = 'errors.alreadyExists',
}

const IdeaWidget = ({ stepConfig, onModified }: IdeaWidgetProps) => {
  const dispatch = useDispatch();
  const isSmallScreen = useCheckSmallScreen();

  const projectName = useSelector((s: State) => s[PROJ_CREATE].name);
  const projectIdea = useSelector((s: State) => s[PROJ_CREATE].idea);

  const currentTypeProject = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );
  const currentCreationStep = useSelector(
    (s: State) => s[PROJ_CREATE].currentCreationStep
  );

  const { t } = useTranslation('app', {
    keyPrefix: `creation.${currentTypeProject}.idea`,
  });

  const [nameWarning, setNameWarning] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [ideaError, setIdeaError] = useState<string>('');

  const checkNameInput = () => {
    const trimmedName = projectName.trim();

    if (trimmedName.length) {
      setNameError('');
    } else {
      setNameError(t(ERRORS.EMPTY));
    }

    if (trimmedName.length > WAR_NAME_SYMBOLS) {
      setNameWarning(t(ERRORS.TOO_LONG_NAME));
    } else {
      setNameWarning('');
    }
  };

  const checkIdeaInput = () => {
    const trimmedIdea = projectIdea.trim();

    if (trimmedIdea.length === 0 || trimmedIdea.match(ACCEPTABLE_IDEA_REGEX)) {
      setIdeaError(t(ERRORS.EMPTY));
    } else if (trimmedIdea.length > MAX_IDEA_SYMBOLS) {
      setIdeaError(t(ERRORS.TOO_LONG_IDEA));
    } else {
      setIdeaError('');
    }
  };

  const onNameChange = (value: string) => {
    setNameWarning('');
    setNameError('');
    dispatch(setName(value));
    onModified?.(true);
  };

  const onIdeaChange = (value: string) => {
    setIdeaError('');
    dispatch(setIdea(value));
    onModified?.(true);
  };

  const instructionsLink = (
    <Link
      className="text-link idea-widget__link"
      to={paths.instruction}
      target="_blank"
    />
  );

  const renderStageHeader = () => {
    return (
      <StageHeader
        className="idea-widget__title"
        header={`${isSmallScreen ? `${currentCreationStep + 1}. ` : ''}${t(
          'title'
        )}`}
      />
    );
  };

  const renderNameInputBlock = () => {
    return (
      <div className="idea-widget__input-wrapper--line">
        <p className="idea-widget__subtitle">{t('nameDescription')}</p>
        <TextInput
          value={projectName}
          maxLength={MAX_NAME_SYMBOLS}
          placeholder={t('namePlaceholder')}
          onChange={(e) => onNameChange(e.target.value)}
          onFocusLeave={checkNameInput}
        />
        <span className={`idea-widget__error${nameError ? '' : '--hidden'}`}>
          {nameError}
        </span>
        <span
          className={`idea-widget__warning${nameWarning ? '' : '--hidden'}`}
        >
          {nameWarning}
        </span>
      </div>
    );
  };

  const renderIdeaInputBlock = () => {
    return (
      <div className="idea-widget__input-wrapper--area">
        <p className="idea-widget__idea-description">
          <Trans
            i18nKey={`creation.${currentTypeProject}.idea.ideaDescription`}
            components={{ lnk: instructionsLink }}
          />
        </p>
        <TextEditor
          className="idea-widget__input"
          placeholder={t('ideaPlaceholder')}
          value={projectIdea}
          onChange={onIdeaChange}
          maxLength={MAX_IDEA_SYMBOLS}
          onBlur={checkIdeaInput}
        />
        <span className={`idea-widget__error${ideaError ? '' : '--hidden'}`}>
          {ideaError}
        </span>
      </div>
    );
  };

  const renderMap = new Map<string, () => JSX.Element>([
    ['stageHeader', renderStageHeader],
    ['nameInput', renderNameInputBlock],
    ['ideaInput', renderIdeaInputBlock],
  ]);

  return (
    <>
      <div className="idea-widget">
        {stepConfig.properties.visible.map((item) => renderMap.get(item)?.())}
      </div>
    </>
  );
};

export default IdeaWidget;
