import {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SORTING_ORDER_DIRECTION } from '@platform-for-public-places/components-library';
import debounce from 'lodash.debounce';

import { DEBOUNCE_TIMEOUT } from 'src/app/constants';

import {
  CONTROL,
  setSearchPanelState,
} from 'src/features/control/slices/controlSlice';
import { TYPE_SEARCH_PAGE } from 'src/features/map/components/SearchPane/components/PaginationList/PaginationList';
import { PaginationListProvider } from 'src/features/map/components/SearchPane/components/PaginationList/PaginationListProvider';
import { SearchPanelState } from 'src/features/map/components/SearchPane/components/SearchFilterInput/SearchFilterInput';
import { useLazyGetProjectsCatalogInfoQuery } from 'src/features/project/api';
import { ProjectCatalogData } from 'src/features/project/models';
import {
  useProjectStatuses,
  useSelectDefaultStatus,
} from 'src/features/searchWithFilter/filter/lib/statusService';
import { DEFAULT_PROJECT_TYPE } from 'src/features/searchWithFilter/filter/lib/typeService';
import { SORTING_ORDER_BY } from 'src/features/searchWithFilter/filter/searchFilters/FilterOrder/enums';
import { FilterOrderProvider } from 'src/features/searchWithFilter/filter/searchFilters/FilterOrder/FilterOrderProvider';
import {
  addRemoveStatus,
  FILTER,
  setSearchString,
  setStatus,
  setType,
} from 'src/features/searchWithFilter/filter/slices/filterSlice';
import { State } from 'src/features/store/store';

type SearchProviderProps = {
  children: ReactElement | ReactElement[];
  typePage: TYPE_SEARCH_PAGE;
  defaultType?: string[];
};

const PAGE_SIZE = 25;

type SearchContextType = {
  projects: ProjectCatalogData[];
  onTypeChange: (
    newValues: string[],
    changedValue: string,
    isNew: boolean
  ) => void;
  onStatusChange: (
    newValues: string[],
    changedValue: string,
    isNew: boolean
  ) => void;
  resetSearch: () => void;
  resetFilters: () => void;
  typePage: TYPE_SEARCH_PAGE;
  disabled?: boolean;
  projectsCount: number;
};
export const SearchContext = createContext<SearchContextType>({
  projects: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTypeChange: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onStatusChange: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetSearch: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetFilters: () => {},
  typePage: TYPE_SEARCH_PAGE.PROJECT_MAP,
  disabled: false,
  projectsCount: 0,
});

const SearchProvider = ({
  children,
  typePage,
  defaultType = DEFAULT_PROJECT_TYPE,
}: SearchProviderProps) => {
  const dispatch = useDispatch();
  const { getStatusByConfig } = useProjectStatuses();
  const [reset, setReset] = useState<boolean>(false);
  const [projects, setProjects] = useState<ProjectCatalogData[]>([]);
  const { sortingOrderType, sortingOrderDirection } = useSelector(
    (s: State) => s[FILTER]
  );
  const searchPanelState = useSelector(
    (s: State) => s[CONTROL].searchPanelState
  );
  const defaultStatus = useSelectDefaultStatus(typePage);

  const [count, setCount] = useState(0);

  const [getProjectsInfo] = useLazyGetProjectsCatalogInfoQuery();

  const searchProjects = (
    isUserPage: boolean,
    searchString: string,
    page: number,
    setPage: (v: number) => void,
    setHasNextPage: (v: boolean) => void,
    setError: (v: boolean) => void,
    setLoading: (v: boolean) => void,
    type?: string[],
    status?: string[],
    sortingOrderType?: SORTING_ORDER_BY,
    sortingOrderDirection?: SORTING_ORDER_DIRECTION
  ) => {
    getProjectsInfo({
      isUserPage,
      searchString: `%${searchString}%`,
      types: type ?? defaultType,
      flowStepIds: status ?? defaultStatus,
      pageSize: PAGE_SIZE,
      page,
      orderBy: sortingOrderType ?? SORTING_ORDER_BY.NAME,
      orderDirection: sortingOrderDirection ?? SORTING_ORDER_DIRECTION.ASC,
    })
      .then((res) => {
        setProjects((prev) => [...prev, ...(res.data?.data.projectInfo ?? [])]);
        setPage(page + 1);
        setHasNextPage(res.data?.data.projectInfo.length === PAGE_SIZE);
        setCount(res.data?.data.aggregators?.count ?? 0);
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    resetFilters();
    return () => {
      dispatch(setSearchPanelState(SearchPanelState.CLOSE));
      dispatch(setSearchString(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultType, defaultStatus]);

  useEffect(() => {
    if (sortingOrderType && sortingOrderDirection) {
      debouncedSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingOrderType, sortingOrderDirection]);

  const resetSearch = () => {
    setReset(true);
    setProjects([]);
  };

  const debouncedSearch = useRef(
    debounce(resetSearch, DEBOUNCE_TIMEOUT)
  ).current;

  const destroy = useCallback(() => {
    debouncedSearch.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, dispatch]);

  useEffect(() => destroy, [destroy]);
  const onTypeChange = (
    newValues: string[],
    changedValue: string,
    isNew: boolean
  ) => {
    dispatch(setType(newValues));
    onStatusChangeAllByType(changedValue, isNew);
    debouncedSearch();
  };

  const resetFilters = () => {
    dispatch(setType(defaultType));
    dispatch(setStatus(defaultStatus));
    debouncedSearch();
  };

  const onStatusChange = (newValues: string[]) => {
    dispatch(setStatus(newValues));
    debouncedSearch();
  };

  const onStatusChangeAllByType = (type: string, isNew: boolean) => {
    const statusByType = getStatusByConfig(type).map((value) => value.id);
    dispatch(addRemoveStatus({ isNew: isNew, value: statusByType }));
  };

  return (
    <PaginationListProvider
      typeSearchPage={typePage}
      searchProjects={searchProjects}
      disabled={
        typePage === TYPE_SEARCH_PAGE.PROJECT_MAP &&
        searchPanelState === SearchPanelState.CLOSE
      } //todo вынести логику дизейбла в хук (не надо выносить из provider)
      projects={projects}
      reset={reset}
      setReset={setReset} //not good but not better(
    >
      <FilterOrderProvider typePage={typePage}>
        <SearchContext.Provider
          value={{
            projects: projects,
            onTypeChange,
            onStatusChange,
            resetSearch: debouncedSearch,
            resetFilters,
            typePage,
            projectsCount: count,
          }}
        >
          {children}
        </SearchContext.Provider>
      </FilterOrderProvider>
    </PaginationListProvider>
  );
};

export default SearchProvider;
