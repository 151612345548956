import './StageHeader.scss';

interface StageHeaderProps {
  header: string;
  subheader?: string;
  className?: string;
}

const StageHeader = ({ className, header, subheader }: StageHeaderProps) => {
  return (
    <div className={`stage-header ${className ? className : ''}`}>
      <h1
        className={`stage-header__header-text${
          className ? ` ${className}__header-text` : ''
        }`}
      >
        {header}
      </h1>
      {subheader ? (
        <p
          className={`stage-header__subheader-text${
            className ? ` ${className}__subheader-text` : ''
          }`}
        >
          {subheader}
        </p>
      ) : null}
    </div>
  );
};

export default StageHeader;
