import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import Box from 'src/shared/components/Box/Box';

import { CONTROL } from 'src/features/control/slices/controlSlice';
import { TYPE_SEARCH_PAGE } from 'src/features/map/components/SearchPane/components/PaginationList/PaginationList';
import {
  SearchFilterInputCatalog,
  SearchFilterInputUserProjects,
  SearchPanelState,
} from 'src/features/map/components/SearchPane/components/SearchFilterInput/SearchFilterInput';
import SearchPanelContentMapMobile from 'src/features/map/components/SearchPane/widget/map/SearchPanelContentMapMobile';
import MobileHeader from 'src/features/mobile/components/MobileHeader/MobileHeader';
import { DEFAULT_PROJECT_TYPE_CATALOG } from 'src/features/searchWithFilter/filter/lib/typeService';
import SearchProvider from 'src/features/searchWithFilter/filter/search/SearchProvider';
import SearchProjectFilters from 'src/features/searchWithFilter/filter/searchFilters/SearchFilters';
import SearchProjectList from 'src/features/searchWithFilter/listProjects/SearchProjectList';
import { State } from 'src/features/store/store';

import './ProjectsCatalog.scss';

const ProjectsCatalog = (): JSX.Element => {
  const { t } = useTranslation();
  const isSmallScreen = useCheckSmallScreen();
  return (
    <div className="projects-catalog">
      {isSmallScreen ? (
        <MobileHeader title={t('header.catalogProject')} />
      ) : null}
      <SearchProvider
        typePage={TYPE_SEARCH_PAGE.PROJECT_CATALOG}
        defaultType={DEFAULT_PROJECT_TYPE_CATALOG}
      >
        {isSmallScreen ? (
          <SearchPanelContentMapMobile />
        ) : (
          <SearchPaneCatalog />
        )}
      </SearchProvider>
    </div>
  );
};

export default ProjectsCatalog;

export const SearchPaneCatalog = () => {
  const visible = useSelector((s: State) => s[CONTROL].searchPanelState);
  return (
    <Box className={'search-pane__catalog-container'}>
      <div
        className={`search-pane  ${
          visible === SearchPanelState.OPEN_WITH_FILTER
            ? 'project-catalog__search-pane search-pane--visible-with-filters-catalog'
            : ''
        }`}
      >
        <div style={{ display: 'flex', overflow: 'hidden auto', flex: 1 }}>
          {visible === SearchPanelState.OPEN_WITH_FILTER ? (
            <SearchProjectFilters
              className={
                visible === SearchPanelState.OPEN_WITH_FILTER
                  ? 'search-pane__filters search-pane__filters-catalog project-catalog__filters'
                  : 'search-pane__filters--close'
              }
            />
          ) : null}
        </div>
      </div>
      <Box
        flex={1}
        flexDirection={'column'}
        gap={'0.625em'}
        className="search-pane__frame-catalog search-pane__frame"
      >
        <SearchFilterInputCatalog />
        <SearchProjectList />
      </Box>
    </Box>
  );
};

export const SearchPaneUserProjects = () => {
  return (
    <Box
      flex={1}
      flexDirection={'column'}
      className="search-pane__frame-catalog search-pane__frame"
    >
      <SearchFilterInputUserProjects />
      <SearchProjectList />
    </Box>
  );
};
