import { useState } from 'react';
import CreatableSelect from 'react-select/creatable';

import './TimePicker.scss';

interface Option {
  label: string;
  value: string;
}

interface TimePickerProps {
  className?: string;
  time?: Option;
  setTime?: (value: string) => void;
  onFocus?: () => void;
  onFocusLeave?: (value?: string) => void;
}

const TimePicker = ({
  className,
  time,
  setTime = () => ({}),
  onFocus = () => ({}),
  onFocusLeave = () => ({}),
}: TimePickerProps): JSX.Element => {
  const [isCreatable, setIsCreatable] = useState(false);
  const value = time?.label;

  const handleInput = (inputValue: string) => {
    setIsCreatable(false);

    // is first symbol is 012.
    if (!/^[012]$/.test(inputValue[0])) {
      return '';
    }

    // is hours from 00 to 23.
    if (!/^([01]?[0-9]|2[0-3])$/.test(inputValue.slice(0, 2))) {
      return inputValue.substr(0, 1);
    }

    // equate 1221 to 12:21.
    let shift = 0;
    if (inputValue[2] === ':') {
      shift++;
    }

    // is minute decades is 012345.
    if (!/^[0-5]$/.test(inputValue[2 + shift])) {
      return inputValue.substr(0, 2 + shift);
    }

    // is minutes are numbers.
    if (!/^[0-9]$/.test(inputValue[3 + shift])) {
      return inputValue.substr(0, 3 + shift);
    }

    // all previous conditions failed, therefore the number has a valid format.
    setIsCreatable(true);
    return inputValue.substr(0, 4 + shift);
  };

  const handleTimeCreation = (inputValue: string) => {
    return inputValue.length === 4
      ? inputValue.slice(0, 2) + ':' + inputValue.slice(2, 4)
      : inputValue;
  };

  const handleOnChange = (inputValue: string) => {
    onFocusLeave(value || handleTimeCreation(inputValue));
    return setTime(handleTimeCreation(inputValue));
  };

  const times: Option[] = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 4; j++) {
      const time = `${('0' + i).slice(-2)}:${j === 0 ? `00` : 15 * j}`;
      times.push({
        label: time,
        value: time,
      });
    }
  }

  return (
    <CreatableSelect
      key={`date-picker--${value}`}
      className={`time-picker ${className}`}
      classNamePrefix="time-picker"
      options={times}
      isSearchable
      placeholder="12:00"
      value={value !== '' && value !== undefined ? time : undefined}
      onChange={(option) => handleOnChange(option?.value || '00:00')}
      onInputChange={handleInput}
      isValidNewOption={() => isCreatable}
      formatCreateLabel={handleTimeCreation}
      onMenuOpen={onFocus}
      onMenuClose={() => onFocusLeave(value)}
    />
  );
};

export default TimePicker;
