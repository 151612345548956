export const filterToNumeric: Filter = (input) => {
  if (!input) {
    return '';
  }

  // Strip out everything except the numbers.
  let output = '';
  for (let i = 0; i < input.length; i++) {
    const charCode = input.charCodeAt(i);
    if (48 <= charCode && charCode <= 57) {
      output += input[i];
    }
  }

  return output;
};

/**
 * Accepts an input string and returns a version that has had invalid characters
 * removed.
 * @param next The value to filter.
 * @param previous The previous filtered value.
 */
export interface Filter {
  (next: string, previous?: string): string;
}

/**
 * Optionally accepts one or more locales and returns a Filter.
 * @param locales The locales to use when the Filter is invoked.
 */
export interface FilterFactory {
  (locale?: string | string[]): Filter;
}
