import { useTranslation } from 'react-i18next';

import SignIn from 'src/features/auth/components/SignIn/SignIn';

import AuthModal from '../AuthModal/AuthModal';

interface SignInModalProps {
  strict?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

const SignInModal = ({ strict, onClose, onSuccess }: SignInModalProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'signIn' });

  return (
    <AuthModal strict={strict} onClose={onClose} header={t('header')}>
      <SignIn strict={strict} onSuccess={onSuccess} />
    </AuthModal>
  );
};

export default SignInModal;
