import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import ReportingDesktop from './components/ReportingDesktop/ReportingDesktop';
import ReportingMobile from './components/ReportingMobile/ReportingMobile';

const Reporting = (): JSX.Element => {
  const isSmallScreen = useCheckSmallScreen();

  return isSmallScreen ? <ReportingMobile /> : <ReportingDesktop />;
};

export default Reporting;
