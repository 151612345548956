import HCaptcha from '@hcaptcha/react-hcaptcha';

import { useLazyVerifyCaptchaQuery } from 'src/features/auth/api';

export enum CaptchaSize {
  NORMAL = 'normal',
  COMPACT = 'compact',
  INVISIBLE = 'invisible',
}

export enum CaptchaTheme {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface CaptchaProps {
  sitekey?: string;
  id?: string;
  className?: string;
  size?: CaptchaSize;
  theme?: CaptchaTheme;
  tabIndex?: number;
  languageOverride?: string;
  reCaptchaCompat?: boolean;
  onError?: (err: string) => void;
  onExpire?: () => void;
  onLoad?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  onChalExpired?: () => void;
  onSuccess?: () => void;
  onReject?: () => void;
  onVerify?: (token: string, eKey: string) => void;
}

const Captcha = ({
  sitekey,
  id,
  className = '',
  size = CaptchaSize.NORMAL,
  theme = CaptchaTheme.LIGHT,
  tabIndex,
  languageOverride = 'ru',
  reCaptchaCompat = false,
  onError,
  onExpire,
  onLoad,
  onOpen,
  onClose,
  onChalExpired,
  onVerify,
  onSuccess,
  onReject,
}: CaptchaProps) => {
  const _sitekey = sitekey ?? process.env.REACT_APP_CAPTCHA_SITEKEY;

  const [verifyCaptcha] = useLazyVerifyCaptchaQuery();

  const requestValidation = (token: string, eKey: string) => {
    onVerify?.(token, eKey);
    verifyCaptcha({ token })
      .unwrap()
      .then(({ data, success, errors }) => {
        if (success) {
          if (data.success) {
            onSuccess?.();
          } else {
            onReject?.();
            if (data['error-codes']?.length) {
              onError?.(data['error-codes'][0]);
            }
          }
        } else {
          onError?.(errors.length ? errors[0] : '');
        }
      })
      .catch((reason: unknown) => {
        typeof reason === 'string' && onError?.(reason);
      });
  };

  return _sitekey ? (
    <div className={`captcha ${className}`}>
      <HCaptcha
        sitekey={_sitekey}
        id={id}
        size={size}
        theme={theme}
        tabIndex={tabIndex}
        languageOverride={languageOverride}
        reCaptchaCompat={reCaptchaCompat}
        onError={onError}
        onExpire={onExpire}
        onLoad={onLoad}
        onOpen={onOpen}
        onClose={onClose}
        onChalExpired={onChalExpired}
        onVerify={requestValidation}
      />
    </div>
  ) : null;
};

export default Captcha;
