import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useGetProjectTypesQuery } from 'src/features/project/api/index';
import { ProjectFilterOption } from 'src/features/searchWithFilter/filter/models';
import { setAllType } from 'src/features/searchWithFilter/filter/slices/filterSlice';

export const DEFAULT_PROJECT_TYPE = ['default', 'event', 'news']; //todo make hook
export const DEFAULT_PROJECT_TYPE_CATALOG = ['default'];
export const useGetAllProjectTypes = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('app', { keyPrefix: 'projectsList' });
  const dataFromConfig = [
    {
      label: t('projectsType.default'),
      id: 'default',
      color: '--green-main',
    },
    {
      label: t('projectsType.news'),
      id: 'news',
      color: '--tag-purple',
    },
    {
      label: t('projectsType.gov'),
      id: 'gov',
      color: '--tag-blue',
    },
    {
      label: t('projectsType.event'),
      id: 'event',
      color: '--yellow-dark',
    },
  ];
  const { data: projectTypes } = useGetProjectTypesQuery('read'); //todo update for anonimus

  useEffect(() => {
    if (projectTypes) {
      dispatch(
        setAllType(
          projectTypes?.data.types?.reduce((prev, next) => {
            const value: ProjectFilterOption | undefined = dataFromConfig.find(
              (f) => f.id === next
            );
            return value ? [...prev, value] : prev;
          }, [] as ProjectFilterOption[])
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectTypes]);
};
