import clsx from 'clsx';
import { ru } from 'date-fns/locale';

import { DATE_TIME_FORMAT, getMonthByNumber } from 'src/shared/functions/dates';

import { PaymentsStatisticData } from 'src/features/payment/models';

import { TickObject } from '../../models';

import './ReportingChartBottomLabel.scss';

interface ReportingChartBottomLabelProps {
  tickObject: TickObject;
  data: PaymentsStatisticData[];
}

const ReportingChartBottomLabel = ({
  tickObject,
  data,
}: ReportingChartBottomLabelProps) => {
  return (
    <text
      x={tickObject.x}
      y={'95%'}
      textAnchor="middle"
      className={clsx('reporting-chart-bottom-label', {
        'reporting-chart-bottom-label_empty': !data.find(
          (element: PaymentsStatisticData) =>
            element.month === tickObject.payload.value
        ),
      })}
    >
      {getMonthByNumber(
        tickObject.payload.value,
        DATE_TIME_FORMAT.SHORT,
        ru.code
      )}
    </text>
  );
};

export default ReportingChartBottomLabel;
