import React, { useRef } from 'react';

import { TextInput } from '@platform-for-public-places/components-library';

interface FormattedInputProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'onChange' | 'value'
  > {
  formattedValue: string;
  placeholer: string;
  onChange: (value: string, changeType: 'add' | 'backspace' | 'delete') => void;
}

const FormattedInput = ({
  onChange,
  formattedValue,
  placeholer,
  ...props
}: FormattedInputProps) => {
  const key = useRef('');

  function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
    onChange(
      evt.target.value,
      key.current === 'Backspace'
        ? 'backspace'
        : key.current === 'Delete' || key.current === 'Del'
        ? 'delete'
        : 'add'
    );
  }

  function handleKeyDown(evt: React.KeyboardEvent<HTMLInputElement>) {
    key.current = evt.key;
  }
  return (
    <TextInput
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      value={formattedValue}
      placeholder={placeholer}
      {...props}
    />
  );
};
export default FormattedInput;
