import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  stringUTCDateToUTCDate,
} from '@platform-for-public-places/components-library';

import StatusCard from 'src/shared/components/StatusCard/StatusCard';
import { truncateZeroDecimal } from 'src/shared/functions/numbers';
import { paths } from 'src/shared/routes';

import { usePaymentStatusTranslation } from 'src/features/payment/components/AllUserPaymentsInfo/components';
import {
  RECURRENT_PAYMENT_STATUS,
  RecurrentUserPaymentData,
} from 'src/features/payment/models';

import './StatusRecurrentPayment.scss';

interface RecurrentUserPaymentItemListProps {
  payment: RecurrentUserPaymentData;
  action: (paymentId: string, projectName: string) => void;
}

const StatusRecurrentPayment = ({
  payment,
  action,
}: RecurrentUserPaymentItemListProps) => {
  const { t } = useTranslation('app', {
    keyPrefix: 'profile.payments.recurrent.list',
  });

  const [bottomSheetVisible, setBottomSheetVisible] = useState(false);

  const paymentType = usePaymentStatusTranslation(payment.paymentMethod);

  const renderContentInsideCard = () => (
    <div className={`status-recurrent-payment`}>
      <h2 className={`status-recurrent-payment__project-name`}>
        {payment.projectName}
      </h2>
      <div className="status-recurrent-payment__payment-details">
        <p className="status-recurrent-payment__amount">
          <Trans
            i18nKey="profile.payments.recurrent.list.amount"
            values={{
              amount: payment.amount,
              formatParams: {
                amount: {
                  maximumFractionDigits: truncateZeroDecimal(payment.amount),
                },
              },
            }}
            components={{
              span: (
                <span className="status-recurrent-payment__payment-details_bold" />
              ),
            }}
          />
        </p>
        <p className="status-recurrent-payment__payment-date">
          <Trans
            i18nKey="profile.payments.recurrent.list.date.nextPaymentDate"
            values={{
              nextPaymentDate: stringUTCDateToUTCDate(payment.nextPaymentDate),
              formatParams: {
                createdAt: {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                },
              },
            }}
            components={{
              span: (
                <span className="status-recurrent-payment__payment-details_bold" />
              ),
            }}
          />
        </p>
      </div>
    </div>
  );

  const renderContentBottomSheet = () => (
    <div className="sheet-recurrent-payment">
      <a
        className="sheet-recurrent-payment__project-link"
        href={
          payment.projectId
            ? paths.projectById(payment.projectId)
            : paths.frontPage
        }
        rel="noopener noreferrer"
        target="_blank"
      >
        {payment.projectName}
      </a>
      <p
        className={`sheet-recurrent-payment__status sheet-recurrent-payment__status_${payment.status}`}
      >
        {t(`status.${payment.status}`)}
      </p>

      <div className="sheet-recurrent-payment__payment-details">
        <p className="sheet-recurrent-payment__amount">
          <Trans
            i18nKey="profile.payments.recurrent.list.amount"
            values={{
              amount: payment.amount,
              formatParams: {
                amount: {
                  maximumFractionDigits: truncateZeroDecimal(payment.amount),
                },
              },
            }}
            components={{
              span: (
                <span className="sheet-recurrent-payment__payment-details_bold" />
              ),
            }}
          />
        </p>
        <p className="sheet-recurrent-payment__payment-date">
          <Trans
            i18nKey="profile.payments.recurrent.list.date.nextPaymentDate"
            values={{
              nextPaymentDate: stringUTCDateToUTCDate(payment.nextPaymentDate),
              formatParams: {
                createdAt: {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                },
              },
            }}
            components={{
              span: (
                <span className="sheet-recurrent-payment__payment-details_bold" />
              ),
            }}
          />
        </p>
        <p className="sheet-recurrent-payment__payment-type">
          <Trans
            i18nKey="profile.payments.recurrent.list.paymentType"
            values={{
              paymentSystem: paymentType,
            }}
            components={{
              span: (
                <span className="sheet-recurrent-payment__payment-details_bold" />
              ),
            }}
          />
        </p>
      </div>
      <div className="sheet-recurrent-payment__action">
        {payment.status == RECURRENT_PAYMENT_STATUS.ACTIVE ? (
          <Button
            className="sheet-recurrent-payment__action-button"
            onClick={() => {
              action(payment.recurrent_paymentsID, payment.projectName);
              setBottomSheetVisible(false);
            }}
            type={BUTTON_TYPE.TEXT_RED}
            icon
            fill
          >
            <Icon
              className="sheet-recurrent-payment__button-icon"
              icon={IconType.Cross}
            />
            {t('actions.stopSupport')}
          </Button>
        ) : null}
      </div>
    </div>
  );

  return (
    <StatusCard
      bottomSheetVisible={bottomSheetVisible}
      setBottomSheetVisible={setBottomSheetVisible}
      contentInsideCard={() => renderContentInsideCard()}
      contentBottomSheet={() => renderContentBottomSheet()}
      className={`status-card_${payment.status}`}
    />
  );
};

export default StatusRecurrentPayment;
