import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { useClearCreation } from 'src/features/hooks';
import { useLazyCreateProjectQuery } from 'src/features/project/api';
import {
  PROJ_CREATE,
  setNextStep,
  setPrevStep,
  setProjectId,
} from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import './CreationFooter.scss';

export interface CreationFooterProps {
  updateStepData?: (projectId: string) => void;
  valid?: boolean;
  nextButtonText?: string;
  className?: string;
}

const CreationFooter = ({
  updateStepData,
  valid = true,
  nextButtonText,
  className = '',
}: CreationFooterProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clearCreation = useClearCreation();
  const { t } = useTranslation('app', { keyPrefix: 'creation' });

  const [createProject] = useLazyCreateProjectQuery();

  const projectId = useSelector((s: State) => s[PROJ_CREATE].projectId);
  const currentProjectType = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );
  const configCreationSteps = useSelector(
    (s: State) => s[PROJ_CREATE].configCreationSteps
  );
  const currentCreationStep = useSelector(
    (s: State) => s[PROJ_CREATE].currentCreationStep
  );

  const navigateToNextStep = () => {
    const currentStepPath = paths.creationStep(
      configCreationSteps[currentCreationStep].name
    );
    const nextStep = configCreationSteps[currentCreationStep + 1];
    const nextPath = nextStep
      ? paths.creationStep(nextStep.name)
      : paths.thanks;
    navigate(nextPath, { state: { prevUrl: currentStepPath } });
    dispatch(setNextStep());
  };

  const onPrevClick = () => {
    const currentStepPath = paths.creationStep(
      configCreationSteps[currentCreationStep].name
    );
    const prevStep = configCreationSteps[currentCreationStep - 1];
    const prevPath = prevStep
      ? paths.creationStep(prevStep.name)
      : paths.creation;

    //if prevPath is creation
    if (!prevStep) {
      clearCreation();
    }
    navigate(prevPath, { state: { prevUrl: currentStepPath } });
    dispatch(setPrevStep());
  };

  const onNextClick = () => {
    if (projectId) {
      updateStepData?.(projectId);
    } else {
      createProject({ type: currentProjectType }).then(({ data: response }) => {
        if (response) {
          dispatch(setProjectId(response.data.projectId));
          updateStepData?.(response.data.projectId);
        }
      });
    }
    navigateToNextStep();
  };

  return (
    <div className={`creation__buttons ${className}`}>
      <Button
        className="creation__button creation__button-previous"
        type={BUTTON_TYPE.ACCENTED_OUTLINE}
        onClick={onPrevClick}
      >
        {t('previousButton')}
      </Button>
      <Button
        disabled={!valid}
        className="creation__button"
        type={BUTTON_TYPE.ACCENTED}
        onClick={onNextClick}
      >
        {nextButtonText ? nextButtonText : t('continueButton')}
      </Button>
    </div>
  );
};

export default CreationFooter;
