import { Icon, IconType } from '@platform-for-public-places/components-library';

import Box from 'src/shared/components/Box/Box';
import Checkbox from 'src/shared/components/Checkbox/Checkbox';
import Typography from 'src/shared/components/Typography/Typography';

import './CheckBoxFilterListElement.scss';

type CheckBoxFilterListElementProps = {
  color: string;
  label: string;
  checked: boolean;
  changeCheck: (id: string, check: boolean) => void;
  id: string;
  icon?: IconType;
};

const CheckBoxFilterListElement = ({
  color,
  label,
  checked,
  changeCheck,
  id,
  icon = IconType.Marker,
}: CheckBoxFilterListElementProps) => {
  return (
    <button
      className={'check-box-filter-list'}
      onClick={() => changeCheck(id, checked)}
    >
      <Box gap={'1em'} alignItems={'center'}>
        <Icon
          icon={icon}
          color={`${icon !== IconType.Marker ? `var(${color})` : 'none'}`}
          fill={`${icon === IconType.Marker ? `var(${color})` : 'none'}`}
        />
        <Typography type={'body3'}>{label}</Typography>
      </Box>
      <Checkbox
        checked={checked}
        onChange={() => changeCheck(id, checked)}
        id={`${id}`}
      />
    </button>
  );
};

export default CheckBoxFilterListElement;
