import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { paths } from 'src/shared/routes';
import { datesWidgetsMap, getDateWithTime } from 'src/shared/widgets/dates';

import { useCreateProjectTimelineMutation } from 'src/features/project/api';
import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import { CreationOutletProps } from '../../models';

const CreationDates = () => {
  const navigate = useNavigate();
  const { renderFooter }: CreationOutletProps = useOutletContext();

  const startDate = useSelector((s: State) => s[PROJ_CREATE].startDate);
  const endDate = useSelector((s: State) => s[PROJ_CREATE].endDate);
  const startTime = useSelector((s: State) => s[PROJ_CREATE].startTime);
  const endTime = useSelector((s: State) => s[PROJ_CREATE].endTime);

  const [isValid, setIsValid] = useState<boolean>(false);
  const [createDates] = useCreateProjectTimelineMutation();

  const updateDates = (projectId: string) => {
    if (isValid) {
      createDates({
        projectId,
        startDate: getDateWithTime(
          new Date(startDate),
          startTime
        ).toISOString(),
        endDate: getDateWithTime(new Date(endDate), endTime).toISOString(),
      });
    }
  };

  const currentStep = useSelector(
    (s: State) => s[PROJ_CREATE].currentCreationStep
  );
  const stepConfig = useSelector(
    (s: State) => s[PROJ_CREATE].configCreationSteps
  );

  useEffect(() => {
    if (stepConfig.length === 0) {
      navigate(paths.creation);
    }
  }, [navigate, stepConfig]);

  const DatesWidget = datesWidgetsMap.get(
    stepConfig[currentStep] ? stepConfig[currentStep].type : ''
  );
  return (
    <div className="creation-dates">
      {DatesWidget?.({
        stepConfig: stepConfig[currentStep],
        setIsValid: setIsValid,
      }) || null}
      {renderFooter?.({
        updateStepData: updateDates,
        valid: isValid,
        className: 'creation-dates__footer',
      })}
    </div>
  );
};

export default CreationDates;
