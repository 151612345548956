import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { GetFilesResponse } from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';
import { filesUploaderWidgetsMap } from 'src/shared/widgets/files';

import {
  useCreateFileKeysMutation,
  useGetFilesQuery,
  useGetProjectCoverQuery,
  useLazyGetFilesQuery,
  useSetProjectCoverMutation,
} from 'src/features/files/api';
import { FileCategories, PhotoFileCategories } from 'src/features/files/enums';
import { PHOTOS_TYPE } from 'src/features/project/enums';
import {
  PROJ_CREATE,
  setPhotos,
  setPhotosCover,
} from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import { EditingOutletProps } from 'src/pages/layouts/EditLayout/models';

const STAGE_NAME = 'photos';
const PHOTO_LIMIT = 10;
const PHOTO_OFFSET = 0;
const REFETCH_TIMEOUT = 500;

const EditingPhotos = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { renderFooter }: EditingOutletProps = useOutletContext();

  const projectId = useSelector((s: State) => s[PROJ_CREATE].projectId);

  const photosCover = useSelector((s: State) => s[PROJ_CREATE].photosCover);
  const photosAfter = useSelector((s: State) => s[PROJ_CREATE].photosAfter);
  const photosBefore = useSelector((s: State) => s[PROJ_CREATE].photosBefore);
  const photosConcept = useSelector((s: State) => s[PROJ_CREATE].photosConcept);

  function compare(
    first?: GetFilesResponse | GetFilesResponse[] | null,
    second?: GetFilesResponse | GetFilesResponse[] | null
  ) {
    return JSON.stringify(first) === JSON.stringify(second);
  }

  const [getPhotos] = useLazyGetFilesQuery();
  const [updatePhotos] = useCreateFileKeysMutation();
  const [updateCover] = useSetProjectCoverMutation();

  const stepsConfig = useSelector(
    (s: State) => s[PROJ_CREATE].configEditingSteps
  );
  const photosStep = stepsConfig.find((step) => step.name === STAGE_NAME);

  const currentProjectType = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );
  const { t } = useTranslation('app', {
    keyPrefix: `editing.${currentProjectType}.${STAGE_NAME}`,
  });

  const { data: remoteBefore } = useGetFilesQuery(
    {
      entityId: projectId as string,
      category: FileCategories.PHOTO_BEFORE,
      limit: PHOTO_LIMIT,
      offset: PHOTO_OFFSET,
    },
    { skip: !projectId }
  );

  const { data: remoteConcept } = useGetFilesQuery(
    {
      entityId: projectId as string,
      category: FileCategories.PHOTO_CONCEPT,
      limit: PHOTO_LIMIT,
      offset: PHOTO_OFFSET,
    },
    { skip: !projectId }
  );

  const { data: remoteAfter } = useGetFilesQuery(
    {
      entityId: projectId as string,
      category: FileCategories.PHOTO_AFTER,
      limit: PHOTO_LIMIT,
      offset: PHOTO_OFFSET,
    },
    { skip: !projectId }
  );

  const { data: remoteCover } = useGetProjectCoverQuery(
    { projectId: projectId as string },
    { skip: !projectId }
  );

  const categoryFields = {
    [FileCategories.PHOTO_BEFORE]: {
      src: photosBefore,
      remote: remoteBefore?.data,
      type: PHOTOS_TYPE.BEFORE,
    },
    [FileCategories.PHOTO_CONCEPT]: {
      src: photosConcept,
      remote: remoteConcept?.data,
      type: PHOTOS_TYPE.CONCEPT,
    },
    [FileCategories.PHOTO_AFTER]: {
      src: photosAfter,
      remote: remoteAfter?.data,
      type: PHOTOS_TYPE.AFTER,
    },
  };

  const initData = () => {
    const updatePhotos = (type: PHOTOS_TYPE, photos?: GetFilesResponse[]) =>
      photos && dispatch(setPhotos({ photos, type }));

    updatePhotos(PHOTOS_TYPE.BEFORE, remoteBefore?.data);
    updatePhotos(PHOTOS_TYPE.CONCEPT, remoteConcept?.data);
    updatePhotos(PHOTOS_TYPE.AFTER, remoteAfter?.data);

    if (remoteCover?.data.cover) {
      dispatch(
        setPhotosCover({
          key: '',
          url: remoteCover.data.cover.url,
          name: remoteCover?.data.cover.name,
          entityId: remoteCover?.data.cover.projectId,
          project_filesID: remoteCover?.data.cover.fileId,
        })
      );
    } else {
      dispatch(setPhotosCover(null));
    }

    return () => {
      dispatch(setPhotos({ photos: [], type: PHOTOS_TYPE.BEFORE }));
      dispatch(setPhotos({ photos: [], type: PHOTOS_TYPE.CONCEPT }));
      dispatch(setPhotos({ photos: [], type: PHOTOS_TYPE.AFTER }));
      dispatch(setPhotosCover(null));
    };
  };

  useEffect(initData, [
    dispatch,
    remoteAfter,
    remoteBefore,
    remoteConcept,
    remoteCover,
  ]);

  const onPhotosSave = async (
    photos: GetFilesResponse[],
    category: PhotoFileCategories
  ) => {
    if (projectId) {
      return updatePhotos({
        entityId: projectId,
        category,
        newFiles: {
          list: photos.map((photo) => ({
            key: photo.key,
            name: photo.name ?? '',
          })),
        },
      })
        .unwrap()
        .then(() => {
          dispatch(setPhotos({ photos, type: categoryFields[category].type }));
          return Promise.resolve();
        })
        .catch(() => Promise.reject());
    } else {
      return Promise.reject();
    }
  };

  const onCoverSave = (c: GetFilesResponse) => {
    if (projectId && c.project_filesID) {
      updateCover({
        projectId,
        fileId: c.project_filesID,
      });
    }
  };

  const onCancel = () => {
    Object.values(categoryFields).map(({ remote, type }) =>
      dispatch(setPhotos({ photos: remote ?? [], type }))
    );
    if (remoteCover?.data.cover && photosCover) {
      const reuquestCover: GetFilesResponse = {
        key: '',
        url: remoteCover.data.cover.url,
        name: remoteCover?.data.cover.name,
        entityId: remoteCover?.data.cover.projectId,
        project_filesID: remoteCover?.data.cover.fileId,
      };
      dispatch(setPhotosCover(reuquestCover));
    } else {
      dispatch(setPhotosCover(null));
    }
  };

  const onSave = () => {
    Object.entries(categoryFields).map(([k, { src, type }]) =>
      onPhotosSave(src, k as PhotoFileCategories).then(() => {
        if (projectId) {
          setTimeout(() => {
            getPhotos({
              entityId: projectId,
              category: k as PhotoFileCategories,
              limit: PHOTO_LIMIT,
              offset: PHOTO_OFFSET,
            })
              .unwrap()
              .then(({ data }) => {
                if (data.length) {
                  dispatch(setPhotos({ photos: data, type }));
                  const updatedCover = data.find(
                    (p) => p.key === photosCover?.key
                  );
                  if (updatedCover) {
                    onCoverSave(updatedCover);
                  }
                }
              });
          }, REFETCH_TIMEOUT);
        }
      })
    );
  };

  const valid = useMemo(
    () => photosBefore.length > 0 && !!photosCover,
    [photosBefore, photosCover]
  );

  const modified = useMemo(() => {
    const requestCover: GetFilesResponse | null = remoteCover
      ? {
          key: '',
          url: remoteCover?.data.cover?.url ?? '',
          name: remoteCover?.data.cover?.name,
          entityId: remoteCover?.data.cover?.projectId,
          project_filesID: remoteCover?.data.cover?.fileId,
        }
      : null;
    return (
      !compare(requestCover, photosCover) ||
      !compare(remoteBefore?.data ?? [], photosBefore) ||
      !compare(remoteConcept?.data ?? [], photosConcept) ||
      !compare(remoteAfter?.data ?? [], photosAfter)
    );
  }, [
    photosAfter,
    photosBefore,
    photosConcept,
    photosCover,
    remoteAfter,
    remoteBefore,
    remoteConcept,
    remoteCover,
  ]);

  if (!photosStep) {
    navigate(paths.map);
  }

  const FilesUploaderWidgetComponent = filesUploaderWidgetsMap.get(
    photosStep?.type ?? ''
  );

  return (
    <>
      {photosStep
        ? FilesUploaderWidgetComponent?.({
            stepConfig: photosStep,
            t,
          })
        : null}
      {renderFooter?.({ onCancel, onSave, valid, modified })}
    </>
  );
};

export default EditingPhotos;
