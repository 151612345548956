import {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  useState,
} from 'react';

import { Icon, IconType } from '@platform-for-public-places/components-library';

import './PasswordInput.scss';

interface PasswordInputProps {
  id?: string;
  value: string;
  placeholder?: string;
  maxLength?: number;
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onFocusLeave?: FocusEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
}

const PasswordInput = ({
  id,
  value,
  placeholder,
  maxLength,
  className,
  onChange,
  onFocus,
  onFocusLeave,
  onKeyDown,
}: PasswordInputProps): JSX.Element => {
  const [isHide, setHide] = useState<boolean>(true);

  return (
    <div className={`password-input ${className}`}>
      <input
        id={id}
        type={isHide ? 'password' : 'text'}
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        className="password-input__input input:-internal-autofill-selected"
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onFocusLeave}
        onKeyDown={onKeyDown}
      />
      <button
        type="button"
        className={`password-input__button ${
          isHide ? '' : 'password-input__button--active'
        }`}
        onClick={() => setHide((prevState) => !prevState)}
      >
        <Icon icon={isHide ? IconType.CloseEye : IconType.Eye} />
      </button>
    </div>
  );
};

export default PasswordInput;
