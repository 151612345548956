import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MODALS } from 'src/features/modal/models';

import { PlatformTeamMember } from 'src/pages/main/FrontPage/components/PlatformTeam/PlatformTeam';

export const MODAL = 'MODAL';

interface ModalState {
  openedModal: MODALS | null;
  email: string;
  currentPlatformTeamMember: PlatformTeamMember | null;
}

const initialState: ModalState = {
  openedModal: null,
  email: '',
  currentPlatformTeamMember: null,
};

const modalSlice = createSlice({
  name: MODAL,
  initialState,
  reducers: {
    changeModal: (state: ModalState, action: PayloadAction<MODALS | null>) => {
      state.openedModal = action.payload;
    },
    changeEmail: (state: ModalState, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    changeCurrentPlatformTeamMember: (
      state: ModalState,
      action: PayloadAction<PlatformTeamMember | null>
    ) => {
      state.currentPlatformTeamMember = action.payload;
    },
  },
});

export const { changeModal, changeEmail, changeCurrentPlatformTeamMember } =
  modalSlice.actions;

export default modalSlice.reducer;
