import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IconType } from '@platform-for-public-places/components-library';
import { useLeafletContext } from '@react-leaflet/core';

import Locator from 'src/features/map/components/Locator/Locator';
import { useOpenHookCard } from 'src/features/project/components/OverviewCard/openCardHook';
import { PROJ_LAYER } from 'src/features/project/slices/projectsLayerSlice';
import { ProjectFilterOption } from 'src/features/searchWithFilter/filter/models';
import { State } from 'src/features/store/store';

type ProjectListLocatorsProps = {
  archived?: boolean;
  type: ProjectFilterOption;
  statuses?: ProjectFilterOption[];
};

const ProjectListLocators = ({
  archived,
  type,
  statuses,
}: ProjectListLocatorsProps) => {
  const projects = useSelector(
    (s: State) => s[PROJ_LAYER].projectsLayerList[type.id]
  );

  const map = useLeafletContext().map;

  const { openOverviewCard } = useOpenHookCard();
  if (projects) {
    if (!map.getPane(archived ? 'archived' : type.id)) {
      map.createPane(archived ? 'archived' : type.id);
    }
  }
  return useMemo(() => {
    return (
      <>
        {projects?.length
          ? projects.map((project) => {
              const icon =
                statuses?.find((f) => f.id === project.status.flow_stepID)
                  ?.icon ?? IconType.Error;
              return (
                <Locator
                  key={project.projectId}
                  project={project}
                  icon={icon}
                  pane={archived ? 'archived' : type.id}
                  type={type}
                  onClick={(projectId) => openOverviewCard(projectId, archived)}
                />
              );
            })
          : null}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects?.length, statuses]);
};
export default ProjectListLocators;
