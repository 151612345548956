import Control from 'react-leaflet-custom-control';
import { useSelector } from 'react-redux';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { VIEW_CARD } from 'src/features/control/enums';
import { CONTROL } from 'src/features/control/slices/controlSlice';
import InformationCard from 'src/features/project/components/InformationCard/InformationCard';
import { State } from 'src/features/store/store';

const BottomRightControl = () => {
  const isSmallScreen = useCheckSmallScreen();
  const currentCard = useSelector((s: State) => s[CONTROL].currentCard);

  return (
    <Control prepend position="bottomright">
      {isSmallScreen ? (
        <></>
      ) : (
        <>
          {currentCard === VIEW_CARD.LAND_INFORMATION ? (
            <InformationCard />
          ) : null}
        </>
      )}
    </Control>
  );
};

export default BottomRightControl;
