import { useCallback, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  GetFilesResponse,
  MIME_JPEG,
  MIME_PDF,
  MIME_PNG,
  NOTIFICATION_TYPE,
  NotificationPopup,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';
import { FilesUploaderWidgetProps } from 'src/shared/widgets/models';

import { FileCategories, PhotoFileCategories } from 'src/features/files/enums';
import StageHeader from 'src/features/layout/components/StageHeader/StageHeader';
import {
  NOTIFICATION,
  setNotification,
  setVisible,
} from 'src/features/notification/slices/notificationSlice';
import DocsUploader from 'src/features/project/components/DocsUploader/DocsUploader';
import PhotosUploader from 'src/features/project/components/PhotosUploader/PhotosUploader';
import { PHOTOS_TYPE } from 'src/features/project/enums';
import {
  PROJ_CREATE,
  setDesigns,
  setDocs,
  setPhotos,
  setPhotosCover,
} from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import './FilesUploaderWidget.scss';

const DOCS_LIMIT = 50;
const DESIGN_LIMIT = 10;
const CONCEPT_TYPES = [MIME_PNG, MIME_JPEG, MIME_PDF];

const FilesUploaderWidget = ({
  stepConfig,
  t,
  onModified,
}: FilesUploaderWidgetProps) => {
  const dispatch = useDispatch();

  const photosCover = useSelector((s: State) => s[PROJ_CREATE].photosCover);
  const photosAfter = useSelector((s: State) => s[PROJ_CREATE].photosAfter);
  const photosBefore = useSelector((s: State) => s[PROJ_CREATE].photosBefore);
  const photosConcept = useSelector((s: State) => s[PROJ_CREATE].photosConcept);

  const docs = useSelector((s: State) => s[PROJ_CREATE].docs);
  const designs = useSelector((s: State) => s[PROJ_CREATE].designs);

  const isVisible = useSelector((s: State) => s[NOTIFICATION].isVisible);
  const notification = useSelector((s: State) => s[NOTIFICATION].notification);

  const categoryFields = {
    [FileCategories.PHOTO_BEFORE]: {
      src: photosBefore,
      type: PHOTOS_TYPE.BEFORE,
    },
    [FileCategories.PHOTO_CONCEPT]: {
      src: photosConcept,
      type: PHOTOS_TYPE.CONCEPT,
    },
    [FileCategories.PHOTO_AFTER]: {
      src: photosAfter,
      type: PHOTOS_TYPE.AFTER,
    },
  };

  const onCover = (cover: GetFilesResponse | null) => {
    onModified?.(true);
    dispatch(setPhotosCover(cover));
  };

  const onUpload = (
    photos: GetFilesResponse[],
    category: PhotoFileCategories
  ) => {
    onModified?.(true);
    const { src, type } = categoryFields[category];
    dispatch(setPhotos({ photos: [...src, ...photos], type }));
    dispatch(setVisible(true));
    dispatch(
      setNotification({
        type: NOTIFICATION_TYPE.NOTIFICATION,
        message: t('uploadNotification'),
      })
    );
  };

  const onDelete = (photo: GetFilesResponse, category: PhotoFileCategories) => {
    onModified?.(true);
    const { src, type } = categoryFields[category];
    const photos = src.filter((f) => f !== photo);
    dispatch(setPhotos({ photos, type }));

    if (photos.length && photosCover) {
      if (photo.project_filesID && photosCover.project_filesID) {
        if (photo.project_filesID === photosCover.project_filesID) {
          dispatch(setPhotosCover(photos[0]));
        }
      } else if (photo.url === photosCover.url) {
        dispatch(setPhotosCover(photos[0]));
      }
    } else if (!photos.length) {
      dispatch(setPhotosCover(null));
    }
  };

  const onDocsUpload = (files: GetFilesResponse[]) => {
    onModified?.(true);
    dispatch(setDocs([...docs, ...files]));
  };

  const onDocDelete = (file: GetFilesResponse) => {
    onModified?.(true);
    dispatch(setDocs(docs.filter((f) => f !== file)));
  };

  const onDesignsUpload = (files: GetFilesResponse[]) => {
    onModified?.(true);
    dispatch(setDesigns([...designs, ...files]));
  };

  const onDesignDelete = (file: GetFilesResponse) => {
    onModified?.(true);
    dispatch(setDesigns(designs.filter((f) => f !== file)));
  };

  const instructionsLink = (
    <Link className="text-link" to={paths.instruction} target="_blank" />
  );

  const createDescription = (description: string) => (
    <Trans defaults={description} components={{ lnk: instructionsLink }} />
  );

  const hideNotification = useCallback(
    () => dispatch(setVisible(false)),
    [dispatch]
  );

  useEffect(() => {
    return () => {
      hideNotification();
    };
  }, [hideNotification]);

  const renderStageHeader = () => {
    return (
      <StageHeader
        className="files-uploader-widget__title"
        header={t('title')}
      />
    );
  };

  const renderSubHeader = () => {
    return (
      <p className="photos__subtitle">
        <Trans
          defaults={t('mainHint')}
          components={{ lnk: instructionsLink }}
        />
      </p>
    );
  };

  const renderPhotoBefore = () => {
    return (
      <PhotosUploader
        required
        className="files-uploader-widget__photos-uploader"
        title={t('beforeTitle')}
        description={createDescription(t('beforeDescription'))}
        photos={photosBefore}
        cover={photosCover}
        coverHint={t('beforeCoverHint')}
        onCover={onCover}
        onUpload={(p) => onUpload(p, FileCategories.PHOTO_BEFORE)}
        onDelete={(p) => onDelete(p, FileCategories.PHOTO_BEFORE)}
      />
    );
  };

  const renderPhotoConcept = () => {
    return (
      <PhotosUploader
        className="files-uploader-widget__photos-uploader"
        title={t('conceptTitle')}
        description={createDescription(t('conceptDescription'))}
        photos={photosConcept}
        cover={photosCover}
        coverHint={t('conceptCoverHint')}
        onCover={onCover}
        onUpload={(p) => onUpload(p, FileCategories.PHOTO_CONCEPT)}
        onDelete={(p) => onDelete(p, FileCategories.PHOTO_CONCEPT)}
      />
    );
  };

  const renderPhotoAfter = () => {
    return (
      <PhotosUploader
        className="files-uploader-widget__photos-uploader"
        title={t('afterTitle')}
        description={createDescription(t('afterDescription'))}
        photos={photosAfter}
        cover={photosCover}
        coverHint={t('afterCoverHint')}
        onCover={onCover}
        onUpload={(p) => onUpload(p, FileCategories.PHOTO_AFTER)}
        onDelete={(p) => onDelete(p, FileCategories.PHOTO_AFTER)}
      />
    );
  };

  const renderDesigns = () => {
    return (
      <DocsUploader
        className="files-uploader-widget__designs-uploader"
        title={t('designsTitle')}
        description={createDescription(t('designsDescription'))}
        max={DESIGN_LIMIT}
        accept={CONCEPT_TYPES}
        files={designs}
        onUpload={onDesignsUpload}
        onDelete={onDesignDelete}
      />
    );
  };

  const renderDocs = () => {
    return (
      <DocsUploader
        className="files-uploader-widget__docs-uploader"
        title={t('docsTitle')}
        description={createDescription(t('docsDescription'))}
        max={DOCS_LIMIT}
        files={docs}
        onUpload={onDocsUpload}
        onDelete={onDocDelete}
      />
    );
  };

  const renderNotification = () => {
    return (
      <NotificationPopup
        className="files-uploader-widget__notification"
        type={notification.type}
        isVisible={isVisible}
        onTimeoutFinished={hideNotification}
      >
        {notification.message}
      </NotificationPopup>
    );
  };

  const filesUploaderComponentsMap = new Map<string, () => JSX.Element>([
    ['stageHeader', renderStageHeader],
    ['subheader', renderSubHeader],
    ['photosBefore', renderPhotoBefore],
    ['photosConcept', renderPhotoConcept],
    ['photosAfter', renderPhotoAfter],
    ['designs', renderDesigns],
    ['docs', renderDocs],
    ['notification', renderNotification],
  ]);

  return (
    <>
      <div className="files-uploader-widget">
        {stepConfig.properties.visible.map(
          (item) => filesUploaderComponentsMap.get(item)?.() || null
        )}
      </div>
    </>
  );
};

export default FilesUploaderWidget;
