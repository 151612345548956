import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  CategoryButton,
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import MenuSteps from 'src/shared/components/MenuStep/MenuSteps';
import Tooltip from 'src/shared/components/Tooltip/Tooltip';
import { paths } from 'src/shared/routes';

import AllUserPaymentsInfo from 'src/features/payment/components/AllUserPaymentsInfo/AllUserPaymentsInfo';
import RecurrentUserPaymentsInfo from 'src/features/payment/components/RecurrentUserPaymentsInfo/RecurrentUserPaymentsInfo';
import UserTotalDonation from 'src/features/payment/components/UserTotalDonation/UserTotalDonation';
import { PAYMENT_TYPE } from 'src/features/payment/models';

import './UserPayments.scss';

interface PaymentLocationProps {
  paymentType: PAYMENT_TYPE;
}

const PAYMENTS = {
  all: {
    path: paths.profileAllPayments,
    name: 'profile.payments.all.tabName',
  },
  recurrent: {
    path: paths.profileRecurrentPayments,
    name: 'profile.payments.recurrent.tabName',
  },
};

const UserPayments = () => {
  const location = useLocation();
  const isSmallScreen = useCheckSmallScreen();

  const [paymentsType, setPaymentsType] = useState(PAYMENT_TYPE.ALL);
  const { t } = useTranslation('app', {
    keyPrefix: `profile.payments`,
  });

  const onCategoryClick = (type: PAYMENT_TYPE) => {
    if (paymentsType !== type) {
      setPaymentsType(type);
    }
  };

  useEffect(() => {
    const locationState = location.state as PaymentLocationProps;
    if (locationState?.paymentType) {
      setPaymentsType(locationState?.paymentType);
    }
  }, [location, location.state]);

  const getTable = () =>
    paymentsType === PAYMENT_TYPE.ALL ? (
      <AllUserPaymentsInfo />
    ) : (
      <RecurrentUserPaymentsInfo />
    );

  const renderPaymentsMenu = () => {
    return (
      <div className="profile-menu">
        <MenuSteps steps={PAYMENTS} />
      </div>
    );
  };

  const renderTable = () => (
    <>
      <div className="user-payments__header">
        <div className="user-payments__header-block">
          <h2 className="profile-layout__title user-payments__title">
            {t('tabName')}
          </h2>
          <Tooltip
            className="user-payments__header-block-tooltip-container"
            text={t('warningMessage')}
            tooltipClassName="user-payments__header-block-tooltip"
          >
            <Icon icon={IconType.Error} />
          </Tooltip>
        </div>
        <UserTotalDonation />
      </div>
      <div className="user-payments__categories">
        <CategoryButton
          onClick={() => onCategoryClick(PAYMENT_TYPE.ALL)}
          selected={paymentsType === PAYMENT_TYPE.ALL}
        >
          {t('categories.all')}
        </CategoryButton>
        <CategoryButton
          onClick={() => onCategoryClick(PAYMENT_TYPE.RECURRENT)}
          selected={paymentsType === PAYMENT_TYPE.RECURRENT}
        >
          {t('categories.recurrent')}
        </CategoryButton>
      </div>
      {getTable()}
    </>
  );

  return (
    <div className="user-payments">
      {isSmallScreen ? renderPaymentsMenu() : renderTable()}
    </div>
  );
};

export default UserPayments;
