import { useTranslation } from 'react-i18next';

import {
  Button,
  BUTTON_TYPE,
} from '@platform-for-public-places/components-library';

import { ReactComponent as ConfirmedImage } from './img/confirmed.svg';

import './EmailConfirmed.scss';

interface EmailConfirmedProps {
  onClick: () => void;
}

const EmailConfirmed = ({ onClick }: EmailConfirmedProps): JSX.Element => {
  const { t } = useTranslation('app', { keyPrefix: 'emailConfirmed' });

  return (
    <div className="email-confirmed">
      <h2 className="email-confirmed__header">{t('header')}</h2>
      <p className="email-confirmation__invitation">{t('invitation')}</p>
      <ConfirmedImage className="email-confirmation__image" />
      <Button
        className="email-confirmed__button"
        type={BUTTON_TYPE.ACCENTED}
        onClick={onClick}
      >
        {t('continue')}
      </Button>
    </div>
  );
};

export default EmailConfirmed;
