import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Icon, IconType } from '@platform-for-public-places/components-library';

import SearchInput from 'src/shared/components/SearchInput/SearchInput';

import {
  FILTER,
  setSearchString,
} from 'src/features/searchWithFilter/filter/slices/filterSlice';
import { State } from 'src/features/store/store';

import './SearchInputControl.scss';

const MAX_INPUT_LENGTH = 500;

interface SearchProps {
  className?: string;
  textStyle?: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
}

const SearchInputControl = ({
  className = '',
  textStyle = '',
  onChange,
  onFocus,
}: SearchProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const searchString = useSelector((s: State) => s[FILTER].searchString);

  const filled = !!searchString.length;

  const flushSearch = () => {
    dispatch(setSearchString(''));
    onChange?.('');
  };

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchString(e.target.value));
    onChange?.(e.target.value);
  };

  const onAction = filled ? flushSearch : () => null;

  return (
    <div className={`search-control ${className}`}>
      <SearchInput
        className={`search-control__input ${textStyle}`}
        placeholder={t('controls.search.placeholder')}
        maxLength={MAX_INPUT_LENGTH}
        value={searchString}
        onChange={onValueChange}
        onFocus={onFocus}
      />
      <button
        className="search-control__action"
        onClick={onAction}
        disabled={!filled}
      >
        <Icon
          className="search-control__icon"
          icon={filled ? IconType.Cross : IconType.Search}
        />
      </button>
    </div>
  );
};

export default SearchInputControl;
