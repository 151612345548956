import { useEffect, useState } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { useDispatch } from 'react-redux';

import { Remarkable } from 'remarkable';
import { BlockContentToken, StateInline } from 'remarkable/lib';

import { useLazyGetImagesPresignedUrlsQuery } from 'src/features/minIo/api';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { changePhotos } from 'src/features/modal/slices/photosModalSlice';

const reg = /%\[img\]([\s\S]*?)\[\/img]/i;

interface Image {
  url: string;
  id: string;
}

const ButtonImage = (md: Remarkable) => {
  const dispatch = useDispatch();
  const [images, setImages] = useState<Image[]>([]);

  const parse = (state: StateInline, silent: boolean) => {
    if (state.src[state.pos] !== '%') return false;
    const match = reg.exec(state.src.slice(state.pos));
    if (!match) return false;
    if (!silent) {
      state.push({
        type: 'img',
        content: match[1],
        level: state.level,
      });
    }
    state.pos += match[0].length;
    if (state.pos > state.posMax) {
      state.pos = state.posMax;
    }
    return true;
  };

  const [targetPhoto] = useLazyGetImagesPresignedUrlsQuery();

  useEffect(() => {
    images.map((value) => {
      const button = document.getElementById(value.id);
      const img = document.getElementById(
        `${value.id}img`
      ) as HTMLImageElement | null;
      if (img) {
        img.src = value.url;
      }
      if (button) {
        button.onclick = () => {
          dispatch(changePhotos([{ key: '', url: value.url }]));
          dispatch(changeModal(MODALS.PICTURE));
        };
      }
    });
  }, [dispatch, images]);

  const render = (tokens: BlockContentToken[], idx: number) => {
    const content = tokens[idx].content;
    if (content && !images.some((v) => (v.id = content))) {
      setImages((prevState) => [...prevState, { url: '', id: content }]);

      targetPhoto({
        keys: [content ?? ''],
      }).then((url) => {
        if (url?.data?.data?.[0]) {
          const urlData = url.data.data[0].url;
          setImages((prevState) => [
            ...prevState,
            { url: urlData, id: content },
          ]);
        }
      });
    }

    return ReactDOMServer.renderToString(
      <button id={content} className="remarkable__button-img">
        <img src={content} alt="instruction data" id={content + 'img'} />
      </button>
    );
  };

  md.inline.ruler.push('img', parse, {});
  md.renderer.rules.img = render;
};
export default ButtonImage;
