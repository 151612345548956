import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';

import Box from 'src/shared/components/Box/Box';
import Typography from 'src/shared/components/Typography/Typography';

import 'src/features/searchWithFilter/filter/searchFilters/SearchFilters.scss';
import {
  CONTROL,
  setMobileVariantFilter,
} from 'src/features/control/slices/controlSlice';
import { SearchContext } from 'src/features/searchWithFilter/filter/search/SearchProvider';
import FilterByStatus, {
  StatusFilters,
} from 'src/features/searchWithFilter/filter/searchFilters/FilterList/FilterByStatus';
import FilterByType, {
  TypeFilters,
} from 'src/features/searchWithFilter/filter/searchFilters/FilterList/FilterByType';
import { VariantFilterMobile } from 'src/features/searchWithFilter/filter/searchFilters/StatusButton/enum';
import StatusButton from 'src/features/searchWithFilter/filter/searchFilters/StatusButton/StatusButton';
import { FILTER } from 'src/features/searchWithFilter/filter/slices/filterSlice';
import { State } from 'src/features/store/store';

type SearchFiltersProps = {
  className?: string;
};

const SearchProjectFilters = ({ className }: SearchFiltersProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'searchFilter' });
  return (
    <div className={`${className} search-project-filters`}>
      <Box justifyContent={'space-between'}>
        <Typography type={'h5'}>{t('title')}</Typography>
        <ResetButton />
      </Box>
      <FilterByType />
      <FilterByStatus />
    </div>
  );
};

const ResetButton = () => {
  const { t } = useTranslation('app', { keyPrefix: 'searchFilter' });
  const { resetFilters } = useContext(SearchContext);
  return (
    <Button
      className="search-project-filters__button"
      type={BUTTON_TYPE.TEXT}
      onClick={resetFilters}
    >
      <Typography type={'body3'}>{t('reset')}</Typography>
    </Button>
  );
};

const ResetButtonIcon = () => {
  const { resetFilters } = useContext(SearchContext);
  return (
    <StatusButton
      className="search-project-filters__button"
      onClick={resetFilters}
      active={false}
      indicator={false}
    >
      <Icon icon={IconType.Cycle} />
    </StatusButton>
  );
};

export default SearchProjectFilters;

export const SearchProjectFiltersMobile = ({
  className,
}: SearchFiltersProps) => {
  const { mobileVariantFilter } = useSelector((s: State) => s[CONTROL]);

  return (
    <div
      className={`${
        className ?? ''
      } search-project-filters search-project-filters-mobile`}
    >
      <Box justifyContent={'left'} gap={'0.5em'}>
        <StatusFilterMobile />
        <ResetButtonIcon />
      </Box>
      {mobileVariantFilter ? mapFilterMap[mobileVariantFilter] : null}
    </div>
  );
};

export const StatusFilterMobile = ({
  onSelectFilter,
}: {
  onSelectFilter?: () => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('app', { keyPrefix: 'searchFilter' });
  const { type, status } = useSelector((s: State) => s[FILTER]);

  const { mobileVariantFilter } = useSelector((s: State) => s[CONTROL]);

  const selectFilter = (variant: VariantFilterMobile) => {
    onSelectFilter && onSelectFilter();
    dispatch(
      setMobileVariantFilter(
        variant !== mobileVariantFilter ? variant : VariantFilterMobile.NONE
      )
    );
  };

  return (
    <>
      <StatusButton
        active={VariantFilterMobile.TYPE === mobileVariantFilter}
        onClick={() => selectFilter(VariantFilterMobile.TYPE)}
        indicator={!!type?.length}
      >
        {t('projectType')}
      </StatusButton>

      <StatusButton
        active={VariantFilterMobile.STATUS === mobileVariantFilter}
        onClick={() => selectFilter(VariantFilterMobile.STATUS)}
        indicator={!!status?.length}
      >
        {t('projectStatus')}
      </StatusButton>
    </>
  );
};

const mapFilterMap: Record<VariantFilterMobile, ReactElement> = {
  [VariantFilterMobile.STATUS]: (
    <StatusFilters className={'search-project-filters-mobile-frame'} />
  ),
  [VariantFilterMobile.TYPE]: (
    <TypeFilters className={'search-project-filters-mobile-frame'} />
  ),
  [VariantFilterMobile.NONE]: <div>NET</div>,
};
