import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import '@geoman-io/leaflet-geoman-free';
import { useLeafletContext } from '@react-leaflet/core';
import booleanIntersects from '@turf/boolean-intersects';
import { Feature, Point, Polygon as GeoJSONPolygon } from 'geojson';
import { Layer, Polygon } from 'leaflet';

import { VIEW_CARD } from 'src/features/control/enums';
import { setCurrentCard } from 'src/features/control/slices/controlSlice';
import { MAP } from 'src/features/map/slices/mapSlice';
import { State } from 'src/features/store/store';

import {
  changeNeedClear,
  changeShowInformation,
  DRAW,
  finishDraw,
} from '../../slices/drawSlice';

import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import './DrawTool.scss';

interface DrawWithPolygon {
  Polygon: { _removeLastVertex: () => void };
}

const DrawTool = () => {
  const dispatch = useDispatch();
  const map = useLeafletContext().map;
  const { t, i18n } = useTranslation();

  const [polygon, setPolygon] = useState<Layer | null>(null);

  const drawHint = useSelector((s: State) => s[DRAW].drawHint);
  const needClear = useSelector((s: State) => s[DRAW].needClear);
  const isDrawEnable = useSelector((s: State) => s[DRAW].isDrawEnable);

  const mapGeodata = useSelector((s: State) => s[MAP].geodata);

  useEffect(() => {
    if (needClear) {
      if (polygon) {
        polygon.remove();
        setPolygon(null);
      } else {
        map.pm.disableDraw();
        map.pm.enableDraw('Polygon');
      }
      dispatch(changeNeedClear());
    }
  }, [dispatch, map.pm, needClear, polygon]);

  useEffect(() => {
    map.pm.setGlobalOptions({
      cursorMarker: false,
      draggable: true,
    });

    map.pm.setLang(
      'ru',
      {
        tooltips: {
          firstVertex: drawHint,
          finishPoly: t('drawTool.lastPointTooltip'),
        },
      },
      i18n.language
    );

    map.on('pm:drawstart', (e) => {
      e.workingLayer.on('pm:vertexadded', (event) => {
        const pointGeoJSON: Feature<Point> = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [event.latlng.lng, event.latlng.lat],
          },
          properties: {},
        };
        if (!booleanIntersects(mapGeodata, pointGeoJSON)) {
          (
            map.pm.Draw as unknown as DrawWithPolygon
          ).Polygon._removeLastVertex();
          if (!map.pm.Draw.getActiveShape()) {
            map.pm.disableDraw();
            map.pm.enableDraw('Polygon');
          }
        } else {
          dispatch(changeShowInformation(true));
          dispatch(setCurrentCard(VIEW_CARD.LAND_INFORMATION));
        }
      });
    });

    map.on('pm:create', (e) => {
      setPolygon(e.layer);
      const polygon = e.layer as Polygon;
      const centerGeoJSON = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            polygon.getBounds().getCenter().lng,
            polygon.getBounds().getCenter().lat,
          ],
        },
      };
      dispatch(
        finishDraw({
          polygon: polygon.toGeoJSON() as Feature<GeoJSONPolygon>,
          center: centerGeoJSON as Feature<Point>,
        })
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDrawEnable) {
      map.pm.enableDraw('Polygon');
    } else {
      map.pm.disableDraw();
    }
  }, [isDrawEnable, map.pm]);

  return null;
};

export default DrawTool;
