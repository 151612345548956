import { useState } from 'react';

import {
  ActorBaseResponse,
  FakeResponse,
  GetCoverResponse,
  GetFilesResponse,
} from '@platform-for-public-places/components-library';

import { fetchMock } from 'src/shared/mock/utils';

import {
  CreateFileKeysRequest,
  GetCoverRequest,
  GetFilesRequest,
  SetProjectCoverRequest,
} from '../models';

interface FakeGetFilesResponse extends FakeResponse {
  data: ActorBaseResponse<GetFilesResponse[]>;
  refetch: () => void;
}

interface FakeCreateFileKeysResponse extends FakeResponse {
  reset: () => void;
}

interface FakeGetCoverResponse extends FakeResponse {
  data: ActorBaseResponse<GetCoverResponse>;
  refetch: () => void;
}

interface FakeSetProjectCoverResponse extends FakeResponse {
  reset: () => void;
}

export const useGetFilesQuery = (
  request: GetFilesRequest
): FakeGetFilesResponse => {
  const [response, setResponse] = useState<FakeGetFilesResponse>({
    isSuccess: false,
    isFetching: true,
    isError: false,
    refetch: () => null,
    data: {
      data: [],
      success: false,
      errors: [],
    },
  });

  if (response.isFetching && request.entityId) {
    fetchMock(
      `get-files/get-files-${request.category}/${request.entityId}.json`
    ).then((data) => {
      setResponse({
        isSuccess: true,
        isFetching: false,
        isError: false,
        refetch: () => null,
        data,
      });
    });
  }
  return response;
};

export const useLazyGetFilesQuery = (): [
  (request: GetFilesRequest) => Promise<FakeGetFilesResponse> & {
    unwrap: () => Promise<ActorBaseResponse<GetFilesResponse[]>>;
  },
  FakeGetFilesResponse
] => {
  const [response, setResponse] = useState<FakeGetFilesResponse>({
    isSuccess: false,
    isFetching: true,
    isError: false,
    refetch: () => null,
    data: {
      data: [],
      success: false,
      errors: [],
    },
  });

  const trigger = (request: GetFilesRequest) => {
    if (response.isFetching && request.entityId) {
      const fetchResponse = fetchMock(
        `get-files/get-files-${request.category}/${request.entityId}.json`
      )
        .then((data) => {
          const updatedResponse = {
            isSuccess: true,
            isFetching: false,
            isError: false,
            refetch: () => null,
            data,
          };
          setResponse(updatedResponse);
          return Promise.resolve(updatedResponse);
        })
        .catch(() => {
          console.warn(
            `File for with does'n get-files/get-files-${request.category}/${request.entityId}.json exist `
          );
          return Promise.resolve(response);
        });
      return {
        ...Promise.resolve(fetchResponse),
        unwrap: () =>
          response.data
            ? Promise.resolve(fetchResponse.then((r) => r.data))
            : Promise.reject(),
      };
    }
    return {
      ...Promise.resolve(response),
      unwrap: () =>
        response.data ? Promise.resolve(response.data) : Promise.reject(),
    };
  };
  return [trigger, response];
};

export const useCreateFileKeysMutation = (): [
  (
    arg0: CreateFileKeysRequest
  ) => Promise<FakeCreateFileKeysResponse> & { unwrap: () => Promise<void> },
  FakeCreateFileKeysResponse
] => {
  const reset = () => setResponse(init);

  const init = {
    isSuccess: false,
    isFetching: true,
    isError: false,
    reset,
  };

  const [response, setResponse] = useState<FakeCreateFileKeysResponse>(init);

  const trigger = () => {
    const resp = {
      ...init,
      isSuccess: true,
      isFetching: false,
    };
    setResponse(resp);
    return {
      ...Promise.resolve(resp),
      unwrap: () => Promise.resolve(),
    };
  };

  return [trigger, response];
};

export const useGetProjectCoverQuery = (
  request: GetCoverRequest
): FakeGetCoverResponse => {
  const [response, setResponse] = useState<FakeGetCoverResponse>({
    isSuccess: false,
    isFetching: true,
    isError: false,
    refetch: () => null,
    data: {
      data: {},
      success: false,
      errors: [],
    },
  });

  if (response.isFetching && request.projectId) {
    fetchMock(`get-files/get-files-cover/${request.projectId}.json`)
      .then((data) => {
        setResponse({
          isSuccess: true,
          isFetching: false,
          isError: false,
          refetch: () => null,
          data,
        });
      })
      .catch(() => {
        setResponse({
          isSuccess: true,
          isFetching: false,
          isError: false,
          refetch: () => null,
          data: {
            data: {},
            success: true,
            errors: [],
          },
        });
      });
  }
  return response;
};

export const useLazyGetProjectCoverQuery = (): [
  (request: GetCoverRequest) => Promise<FakeGetCoverResponse> & {
    unwrap: () => Promise<ActorBaseResponse<GetCoverResponse>>;
  },
  FakeGetCoverResponse
] => {
  const [response, setResponse] = useState<FakeGetCoverResponse>({
    isSuccess: false,
    isFetching: true,
    isError: false,
    refetch: () => null,
    data: {
      data: {},
      success: false,
      errors: [],
    },
  });

  const trigger = (request: GetCoverRequest) => {
    if (response.isFetching && request.projectId) {
      const fetchResponse = fetchMock(
        `get-files/get-files-cover/${request.projectId}.json`
      ).then((data) => {
        const updatedResponse = {
          isSuccess: true,
          isFetching: false,
          isError: false,
          refetch: () => null,
          data,
        };
        setResponse(updatedResponse);
        return Promise.resolve(updatedResponse);
      });
      return {
        ...Promise.resolve(fetchResponse),
        unwrap: () =>
          response.data
            ? Promise.resolve(fetchResponse.then((r) => r.data))
            : Promise.reject(),
      };
    }
    return {
      ...Promise.resolve(response),
      unwrap: () =>
        response.data ? Promise.resolve(response.data) : Promise.reject(),
    };
  };
  return [trigger, response];
};

export const useSetProjectCoverMutation = (): [
  (
    arg0: SetProjectCoverRequest
  ) => Promise<FakeSetProjectCoverResponse> & { unwrap: () => Promise<void> },
  FakeSetProjectCoverResponse
] => {
  const reset = () => setResponse(init);

  const init = {
    isSuccess: false,
    isFetching: true,
    isError: false,
    reset,
  };

  const [response, setResponse] = useState<FakeSetProjectCoverResponse>(init);

  const trigger = () => {
    const resp = {
      ...init,
      isSuccess: true,
      isFetching: false,
    };
    setResponse(resp);
    return {
      ...Promise.resolve(resp),
      unwrap: () => Promise.resolve(),
    };
  };

  return [trigger, response];
};
