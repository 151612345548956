import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { paths } from 'src/shared/routes';

import { UserPaymentData } from 'src/features/payment/models';

import UserPaymentItemList from './components/UserPaymentItemList';

import './AllUserPaymentsList.scss';

interface AllUserPaymentsProps {
  allUserPayments: UserPaymentData[];
}

const AllUserPaymentsList = ({ allUserPayments }: AllUserPaymentsProps) => {
  const renderPlaceholder = () => {
    if (allUserPayments.length === 0) {
      return (
        <p className="all-user-payments-list__placeholder">
          <Trans
            i18nKey={'profile.payments.emptyPaymentsMessage.mobile'}
            components={{
              lnkCatalog: (
                <Link
                  className="text-link"
                  to={paths.projects}
                  rel="noreferrer"
                  target="_blank"
                />
              ),
              lnkFrontPage: (
                <Link
                  className="text-link"
                  to={paths.frontPageDonation}
                  rel="noreferrer"
                  target="_blank"
                />
              ),
            }}
          />
        </p>
      );
    } else {
      return null;
    }
  };
  const renderPaymentsList = () => {
    return allUserPayments.map((payment: UserPaymentData) => (
      <UserPaymentItemList key={payment.paymentId} payment={payment} />
    ));
  };

  return (
    <div className="all-user-payments-list">
      {renderPlaceholder()}
      {renderPaymentsList()}
    </div>
  );
};

export default AllUserPaymentsList;
