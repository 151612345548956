import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProjectSpecialist } from 'src/features/user/models';

export const PROJ_EDIT = 'PROJ_EDIT';

interface EditingProjectState {
  memberToAdd: ProjectSpecialist | null;
}

const initialState: EditingProjectState = {
  memberToAdd: null,
};

const editingProjectSlice = createSlice({
  name: PROJ_EDIT,
  initialState,
  reducers: {
    setMemberToAdd: (
      state: EditingProjectState,
      action: PayloadAction<ProjectSpecialist | null>
    ) => {
      state.memberToAdd = action.payload;
    },
    reset: () => initialState,
  },
});

export const { setMemberToAdd, reset } = editingProjectSlice.actions;

export default editingProjectSlice.reducer;
