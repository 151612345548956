import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import CatalogUsers from 'src/shared/components/CatalogUsers/CatalogUsers';
import { routes } from 'src/shared/routes';

import { useGetAllProjectStatus } from 'src/features/searchWithFilter/filter/lib/statusService';
import { useGetAllProjectTypes } from 'src/features/searchWithFilter/filter/lib/typeService';
import { store } from 'src/features/store/store';

import BaseLayout from 'src/pages/layouts/BaseLayout/BaseLayout';
import ChoiseProjectTypeLayout from 'src/pages/layouts/ChoiseProjectTypeLayout/ChoiseProjectTypeLayout';
import CookieLayout from 'src/pages/layouts/CookieLayout/CookieLayout';
import CreationLayout from 'src/pages/layouts/CreationLayout/CreationLayout';
import {
  CreationAlgorithm,
  CreationArea,
  CreationDates,
  CreationIdea,
  CreationTeam,
  CreationUploadFiles,
} from 'src/pages/layouts/CreationLayout/steps';
import Creation from 'src/pages/layouts/CreationLayout/steps/Creation/Creation';
import EditLayout from 'src/pages/layouts/EditLayout/EditLayout';
import {
  EditingArea,
  EditingBudget,
  EditingDates,
  EditingDocuments,
  EditingIdea,
  EditingMenu,
  EditingParticipants,
  EditingPhotos,
  EditingStatus,
  EditingTeam,
} from 'src/pages/layouts/EditLayout/steps';
import LayoutWithLogo from 'src/pages/layouts/LayoutWithLogo/LayoutWithLogo';
import OnlyMapLayout from 'src/pages/layouts/OnlyMapLayout/OnlyMapLayout';
import ProfileLayout from 'src/pages/layouts/ProfileLayout/ProfileLayout';
import TokenInterceptorLayout from 'src/pages/layouts/TokenInterceptorLayout/TokenInterceptorLayout';
import FrontPage from 'src/pages/main/FrontPage/FrontPage';
import Instruction from 'src/pages/main/Instruction/Instruction';
import Map from 'src/pages/main/Map/Map';
import ProjectsCatalog from 'src/pages/main/ProjectsCatalog/ProjectsCatalog';
import RecurrentUnauthorizedUserPayments from 'src/pages/main/RecurrentUnauthorizedUserPayments/RecurrentUnauthorizedUserPayments';
import Reporting from 'src/pages/main/Reporting/Reporting';
import AllUserPayments from 'src/pages/profile/AllUserPayments/AllUserPayments';
import AutopaymentsManagement from 'src/pages/profile/AutopaymentsManagement/AutopaymentsManagement';
import AutopaymentsManagementSent from 'src/pages/profile/AutopaymentsManagementSent/AutopaymentsManagementSent';
import BecomeSpecialist from 'src/pages/profile/BecomeSpecialist/BecomeSpecialist';
import Confirm from 'src/pages/profile/Confirm/Confirm';
import EmailConfirmed from 'src/pages/profile/EmailConfirmed/EmailConfirmed';
import ForgotPassword from 'src/pages/profile/ForgotPassword/ForgotPassword';
import ForgotPasswordSent from 'src/pages/profile/ForgotPasswordSent/ForgotPasswordSent';
import Profile from 'src/pages/profile/Profile/Profile';
import ProfileMenu from 'src/pages/profile/ProfileMenu/ProfileMenu';
import ProfileParticipations from 'src/pages/profile/ProfileParticipations/ProfileParticipations';
import RecoveryPassword from 'src/pages/profile/RecoveryPassword/RecoveryPassword';
import RecoveryPasswordSuccess from 'src/pages/profile/RecoveryPasswordSuccess/RecoveryPasswordSuccess';
import RecurrentUserPayments from 'src/pages/profile/RecurrentUserPayments/RecurrentUserPayments';
import SignIn from 'src/pages/profile/SignIn/SignIn';
import SignUp from 'src/pages/profile/SignUp/SignUp';
import UserPayments from 'src/pages/profile/UserPayments/UserPayments';
import UserProjects from 'src/pages/profile/UserProjects/UserProjects';
import Comments from 'src/pages/project/Comments/Comments';
import Deny from 'src/pages/project/Deny/Deny';
import DetailedDiary from 'src/pages/project/DetailedDiary/DetailedDiary';
import DetailedProjectInfo from 'src/pages/project/DetailedProjectInfo/DetailedProjectInfo';
import Diary from 'src/pages/project/Diary/Diary';
import DiaryEntryMobile from 'src/pages/project/DiaryEntryMobile/DiaryEntryMobile';
import DonationFailurePage from 'src/pages/project/DonationFailurePage/DonationFailurePage';
import DonationProjectMobile from 'src/pages/project/DonationProjectMobile/DonationProjectMobile';
import DonationSuccessPage from 'src/pages/project/DonationSuccessPage/DonationSuccessPage';
import MobileFormAddToProject from 'src/pages/project/MobileFormAddToProject/MobileFormAddToProject';
import SuccessfulCreation from 'src/pages/project/SuccessfulCreation/SuccessfulCreation';
import Unsubscribe from 'src/pages/project/Unsubscribe/Unsubscribe';

import CustomRouter from './CustomRouter';
import history from './history';

import './App.scss';

const App = (): JSX.Element => {
  return (
    <Provider store={store}>
      <ProjectTypeStatusProvider>
        <AppRouters />
      </ProjectTypeStatusProvider>
    </Provider>
  );
};

const ProjectTypeStatusProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  useGetAllProjectStatus();
  useGetAllProjectTypes();
  return children;
};

const AppRouters = () => {
  const { t } = useTranslation('app', { keyPrefix: 'onlyMapLayout.buttons' });

  return (
    <Provider store={store}>
      <CustomRouter history={history}>
        <Routes>
          <Route element={<CookieLayout />}>
            <Route path={routes.root.index} element={<BaseLayout />}>
              <Route path={routes.root.index} element={<LayoutWithLogo />}>
                <Route index element={<FrontPage />} />
                <Route path={routes.root.map} element={<Map />} />
              </Route>

              <Route
                path={`${routes.payments.requestAccessToRecurrentPayments}`}
                element={<RecurrentUnauthorizedUserPayments />}
              />

              <Route path={routes.root.projects}>
                <Route index element={<ProjectsCatalog />} />
                <Route
                  path={`${routes.project.diary}/${routes.id}`}
                  element={<DetailedDiary />}
                />
                <Route
                  path={`${routes.id}/${routes.donationSuccess}`}
                  element={<DonationSuccessPage />}
                />
                <Route
                  path={`${routes.id}/${routes.donationFailure}`}
                  element={<DonationFailurePage />}
                />
              </Route>
              <Route path={routes.root.members} element={<CatalogUsers />} />
              <Route path={routes.root.instruction} element={<Instruction />} />
              <Route path={routes.root.reporting} element={<Reporting />} />
              <Route
                path={`${routes.root.projects}/${routes.id}/${routes.project.donationMobile}`}
                element={<DonationProjectMobile />}
              />
              <Route
                path={routes.root.notifications}
                element={<Navigate to={routes.root.index} replace />}
              />
            </Route>

            <Route path={routes.profile.index} element={<ProfileLayout />}>
              <Route index element={<ProfileMenu />} />
              <Route path={routes.profile.signIn} element={<SignIn />} />
              <Route path={routes.profile.signUp} element={<SignUp />} />
              <Route path={routes.profile.confirm} element={<Confirm />} />
              <Route
                path={routes.profile.confirmed}
                element={<EmailConfirmed />}
              />
              <Route
                path={routes.profile.forgotPassword}
                element={<ForgotPassword />}
              />
              <Route
                path={routes.profile.forgotPasswordSent}
                element={<ForgotPasswordSent />}
              />
              <Route
                path={routes.profile.autopaymentsManagement}
                element={<AutopaymentsManagement />}
              />
              <Route
                path={routes.profile.autopaymentsManagementSent}
                element={<AutopaymentsManagementSent />}
              />
            </Route>

            <Route
              path={routes.profile.index}
              element={<ProfileLayout hideHeaderBack={true} />}
            >
              <Route
                path={routes.profile.recover}
                element={<RecoveryPassword />}
              />
              <Route
                path={routes.profile.recoverSuccess}
                element={<RecoveryPasswordSuccess />}
              />
            </Route>

            <Route path={routes.profile.index} element={<ProfileLayout />}>
              <Route path={routes.profile.info} element={<Profile />} />
              <Route
                path={routes.profile.participations}
                element={<ProfileParticipations />}
              />
              <Route
                path={routes.profile.projects}
                element={<UserProjects />}
              />
              <Route
                path={routes.profile.payments}
                element={<UserPayments />}
              />
              <Route
                path={routes.profile.subscriptions}
                element={<Navigate to={routes.profile.info} replace />}
              />
              <Route
                path={routes.profile.becomeSpecialist}
                element={<BecomeSpecialist />}
              />
              <Route path={routes.profile.menu} element={<ProfileMenu />} />
              <Route path={routes.profile.signIn} element={<SignIn />} />
              <Route path={routes.profile.signUp} element={<SignUp />} />
              <Route path={routes.profile.confirm} element={<Confirm />} />
              <Route
                path={routes.profile.confirmed}
                element={<EmailConfirmed />}
              />
              <Route
                path={routes.wildcard}
                element={<Navigate to={routes.profile.info} replace />}
              />
              <Route
                index
                element={<Navigate to={routes.profile.info} replace />}
              />
            </Route>
            <Route
              path={`${routes.profile.index}${routes.payments.index}`}
              element={<ProfileLayout />}
            >
              <Route path={routes.payments.all} element={<AllUserPayments />} />
              <Route
                path={routes.payments.recurrent}
                element={<RecurrentUserPayments />}
              />
            </Route>

            <Route path={routes.root.index} element={<LayoutWithLogo />}>
              <Route
                path={routes.root.index}
                element={<TokenInterceptorLayout />}
              >
                <Route path={routes.root.confirmation} element={<Map />} />
              </Route>
            </Route>

            <Route path={routes.project.editingIndex} element={<EditLayout />}>
              <Route
                path={`${routes.project.menu}/${routes.id}`}
                element={<EditingMenu />}
              />
              <Route
                path={`${routes.project.status}/${routes.id}`}
                element={<EditingStatus />}
              />
              <Route
                path={`${routes.project.idea}/${routes.id}`}
                element={<EditingIdea />}
              />
              <Route
                path={`${routes.project.area}/${routes.id}`}
                element={<EditingArea />}
              />
              <Route
                path={`${routes.project.dates}/${routes.id}`}
                element={<EditingDates />}
              />
              <Route
                path={`${routes.project.photos}/${routes.id}`}
                element={<EditingPhotos />}
              />
              <Route
                path={`${routes.project.docs}/${routes.id}`}
                element={<EditingDocuments />}
              />
              <Route
                path={`${routes.project.participants}/${routes.id}`}
                element={<EditingParticipants />}
              />
              <Route
                path={`${routes.project.team}/${routes.id}`}
                element={<EditingTeam />}
              />
              <Route
                path={`${routes.project.budget}/${routes.id}`}
                element={<EditingBudget />}
              />
              <Route
                index
                element={
                  <Navigate
                    to={`${routes.project.menu}/${routes.id}`}
                    replace
                  />
                }
              />
            </Route>

            <Route
              path={`${routes.project.editingIndex}/${routes.project.diary}/${routes.id}`}
              element={<EditLayout className="edit-layout__gate--diary" />}
            >
              <Route index element={<Diary />} />
              <Route
                path={routes.project.diaryCreation}
                element={<DiaryEntryMobile />}
              />
            </Route>

            <Route
              path={routes.project.creationIndex}
              element={<ChoiseProjectTypeLayout />}
            >
              <Route index element={<Creation />} />
            </Route>

            <Route
              path={routes.project.creationIndex}
              element={<CreationLayout />}
            >
              <Route
                path={routes.project.algorithm}
                element={<CreationAlgorithm />}
              />
              <Route path={routes.project.idea} element={<CreationIdea />} />
              <Route path={routes.project.area} element={<CreationArea />} />
              <Route
                path={routes.project.docs}
                element={<CreationUploadFiles />}
              />
              <Route path={routes.project.team} element={<CreationTeam />} />
              <Route path={routes.project.dates} element={<CreationDates />} />
              <Route
                path={routes.wildcard}
                element={<Navigate to={routes.project.creationIndex} replace />}
              />
              <Route
                index
                element={<Navigate to={routes.project.creationIndex} replace />}
              />
            </Route>

            <Route
              path={`${routes.project.creationIndex}/${routes.project.area}/${routes.project.map}`}
              element={<OnlyMapLayout backButtonText={t('backCreating')} />}
            >
              <Route index element={<Map />} />
            </Route>

            <Route
              path={`${routes.project.editingIndex}/${routes.project.area}/${routes.project.map}/${routes.id}`}
              element={<OnlyMapLayout backButtonText={t('backEditing')} />}
            >
              <Route index element={<Map />} />
            </Route>

            <Route
              path={`${routes.project.creationIndex}/${routes.project.thanks}`}
              element={<BaseLayout hideCreation hideFooter />}
            >
              <Route index element={<SuccessfulCreation />} />
            </Route>

            <Route
              path={`${routes.project.unsubscribe}`}
              element={<BaseLayout hideCreation hideFooter />}
            >
              <Route index element={<Unsubscribe />} />
            </Route>

            <Route
              path={routes.root.projects}
              element={<BaseLayout hideFooter />}
            >
              <Route path={routes.id} element={<DetailedProjectInfo />} />
              <Route
                path={`${routes.id}/${routes.project.donation}`}
                element={<DetailedProjectInfo />}
              />
              <Route
                path={`${routes.id}/${routes.project.deny}/${routes.requestId}`}
                element={<Deny />}
              />
              <Route
                path={`${routes.id}/${routes.project.accept}/${routes.requestId}`}
                element={<DetailedProjectInfo />}
              />
              <Route
                path={`${routes.id}/${routes.project.comments}`}
                element={<Comments />}
              />
              <Route
                path={`${routes.id}/${routes.project.support}`}
                element={<MobileFormAddToProject />}
              />
            </Route>

            <Route
              path={routes.donationSuccess}
              element={<DonationSuccessPage />}
            />
            <Route
              path={routes.donationFailure}
              element={<DonationFailurePage />}
            />

            <Route
              path={routes.wildcard}
              element={<Navigate to={routes.root.index} replace />}
            />
          </Route>
        </Routes>
      </CustomRouter>
    </Provider>
  );
};

export default App;
