import { Navigate, useSearchParams } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { paths, routes } from 'src/shared/routes';

import RecoveryPasswordForm from 'src/features/auth/components/RecoveryPassword/RecoveryPassword';
import { MODALS } from 'src/features/modal/models';

import './RecoveryPassword.scss';

const RecoveryPassword = () => {
  const isSmallScreen = useCheckSmallScreen();

  const [searchParams] = useSearchParams();
  const token = searchParams.get(routes.tokenId) ?? '';

  return isSmallScreen ? (
    <div className="recover-password-page">
      <RecoveryPasswordForm />
    </div>
  ) : (
    <Navigate
      to={paths.mapWithRecoveryToken(token)}
      replace
      state={{ modalState: MODALS.RECOVERY_PASSWORD }}
    />
  );
};

export default RecoveryPassword;
