import { useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Circles } from 'react-loader-spinner';

import {
  SORTING_ORDER_DIRECTION,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import { useLazyGetUserPaymentsQuery } from 'src/features/payment/api';
import {
  SORTING_USER_PAYMENTS_ORDER_BY,
  UserPaymentData,
} from 'src/features/payment/models';

import AllUserPaymentsList from './components/AllUserPaymentsList/AllUserPaymentsList';
import AllUserPaymentsTable from './components/AllUserPaymentsTable/AllUserPaymentsTable';

import './AllUserPaymentsInfo.scss';

export interface AllUserPaymentsInfoProps {
  className?: string;
}

const PAGE_SIZE_DESKTOP = 20;
const PAGE_SIZE_MOBILE = 10;

const AllUserPaymentsInfo = ({ className = '' }: AllUserPaymentsInfoProps) => {
  const isSmallScreen = useCheckSmallScreen();
  const [allUserPaymentsPage, setAllUserPaymentsPage] = useState<number>(1);
  const [hasNextAllPaymentsPage, setHasNextAllPaymentsPage] =
    useState<boolean>(true);

  const [allUserPayments, setAllUserPayment] = useState<UserPaymentData[]>([]);

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [orderBy, setOrderBy] = useState<SORTING_USER_PAYMENTS_ORDER_BY>(
    SORTING_USER_PAYMENTS_ORDER_BY.DATE
  );
  const [orderDirection, setOrderDirection] = useState<SORTING_ORDER_DIRECTION>(
    SORTING_ORDER_DIRECTION.ASC
  );

  const [
    getAllUserPayments,
    { isUninitialized: isUninitializedGetAllUserPayments },
  ] = useLazyGetUserPaymentsQuery();

  const resetScroll = () => {
    setError(false);
    setLoading(false);
    setHasNextAllPaymentsPage(true);
    setAllUserPaymentsPage(1);
    setAllUserPayment([]);
  };

  const changeSortDirection = (src: string) => {
    if (orderBy !== src) {
      setOrderBy(src as SORTING_USER_PAYMENTS_ORDER_BY);
      setOrderDirection(SORTING_ORDER_DIRECTION.ASC);
    } else {
      if (orderDirection === SORTING_ORDER_DIRECTION.ASC) {
        setOrderDirection(SORTING_ORDER_DIRECTION.DESC);
      } else {
        setOrderDirection(SORTING_ORDER_DIRECTION.ASC);
      }
    }
    resetScroll();
  };

  const [getAllUserPaymentsByPages] = useInfiniteScroll({
    loading,
    hasNextPage: hasNextAllPaymentsPage,
    onLoadMore: () => {
      setLoading(true);
      const pageSize = isSmallScreen ? PAGE_SIZE_MOBILE : PAGE_SIZE_DESKTOP;
      getAllUserPayments({
        searchString: '%%',
        page: allUserPaymentsPage,
        pageSize: pageSize,
        orderBy,
        orderDirection,
      })
        .unwrap()
        .then(({ data }) => {
          setAllUserPayment((prev) => [...prev, ...(data.payments ?? [])]);
          setAllUserPaymentsPage((prev) => prev + 1);
          setHasNextAllPaymentsPage(data.payments.length === pageSize);
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    },
    disabled: error,
  });

  const renderTable = () => (
    <AllUserPaymentsTable
      allUserPayments={allUserPayments}
      changeSortDirection={changeSortDirection}
      showPlaceholder={!loading && !hasNextAllPaymentsPage}
    />
  );
  const renderList = () => (
    <AllUserPaymentsList allUserPayments={allUserPayments} />
  );

  const renderPayments = () => (isSmallScreen ? renderList() : renderTable());

  return (
    <div className={`all-user-payments-info ${className}`}>
      {loading ? (
        <Circles
          wrapperClass={`all-user-payments-info__loader all-user-payments-info__loader_${
            isSmallScreen ? 'mobile' : 'desktop'
          }`}
        />
      ) : null}
      {isUninitializedGetAllUserPayments ? null : renderPayments()}
      {hasNextAllPaymentsPage ? (
        <div
          className="all-user-payments-info__loading-trigger"
          ref={getAllUserPaymentsByPages}
        />
      ) : null}
    </div>
  );
};

export default AllUserPaymentsInfo;
