import { configureStore, Middleware, MiddlewareArray } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { authMiddleware } from 'src/features/auth/api/authApi';
import { commentsMiddleware } from 'src/features/comments/api/commentsApi';
import { diaryMiddleware } from 'src/features/diary/api/diaryApi';
import { exhibitionMiddleware } from 'src/features/exhibition/api/exhibitionApi';
import { filesMiddleware } from 'src/features/files/api/filesApi';
import { instructionMiddleware } from 'src/features/instruction/api/instructionApi';
import { mapMiddleware } from 'src/features/map/api/mapApi';
import { minIoMiddleware } from 'src/features/minIo/api/minIoApi';
import { minIoUrlMiddleware } from 'src/features/minIo/api/minIoUrlApi';
import { paymentMiddleware } from 'src/features/payment/api/paymentApi';
import { pkkMiddleware } from 'src/features/pkk/api/pkkApi';
import { projectMiddleware } from 'src/features/project/api/projectApi';
import { reactionMiddleware } from 'src/features/reaction/api/reactionApi';
import { requestMiddleware } from 'src/features/request/api/requestApi';
import { requisitesMiddleware } from 'src/features/requisites/api/requisitesApi';
import { statusMiddleware } from 'src/features/status/api/statusApi';
import { userMiddleware } from 'src/features/user/api/userApi';

import logger from './middleware/logger';
import rootReducer from './rootReducer';

/* eslint-disable @typescript-eslint/no-explicit-any */
const addLogger = (middleware: MiddlewareArray<any>) =>
  process.env.REACT_APP_LOGGER_ENABLED === 'true'
    ? middleware.concat(logger as Middleware)
    : middleware;

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    addLogger(
      getDefaultMiddleware().concat(
        pkkMiddleware,
        authMiddleware,
        userMiddleware,
        filesMiddleware,
        diaryMiddleware,
        mapMiddleware,
        minIoMiddleware,
        statusMiddleware,
        projectMiddleware,
        paymentMiddleware,
        requestMiddleware,
        requisitesMiddleware,
        minIoUrlMiddleware,
        reactionMiddleware,
        commentsMiddleware,
        exhibitionMiddleware,
        instructionMiddleware
      )
    ),
});

setupListeners(store.dispatch);

export type State = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
