export enum FileCategories {
  PHOTO_BEFORE = 'photo-before',
  PHOTO_CONCEPT = 'photo-concept',
  PHOTO_AFTER = 'photo-after',
  DESIGN = 'design',
  DOCUMENT = 'document',
  DIARY_FILE = 'diary-file',
  BUDGET_FILE = 'estimation',
}

export type PhotoFileCategories =
  | FileCategories.PHOTO_BEFORE
  | FileCategories.PHOTO_CONCEPT
  | FileCategories.PHOTO_AFTER;
