import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Button,
  BUTTON_TYPE,
  ErrorResponse,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';

import { useWhoamiQuery } from 'src/features/auth/api';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';

import { useChangeLikeMutation } from '../../api';

import './LikeButtonCompact.scss';

const ANIMATION_DURATION = 1000;

interface LikeButtonCompactProps {
  projectId: string;
  disabled?: boolean;
  wasLiked?: boolean;
  className?: string;
}

const LikeButtonCompact = ({
  projectId,
  disabled = false,
  wasLiked = false,
  className = '',
}: LikeButtonCompactProps) => {
  const dispatch = useDispatch();

  const [buttonExtraClassName, setButtonExtraClassName] = useState('');
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  const { isSuccess } = useWhoamiQuery();
  const [changeLike, { isLoading: isChangeLikeLoading }] =
    useChangeLikeMutation();

  const toggleLike = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(undefined);
      setButtonExtraClassName('');
    } else if (!isChangeLikeLoading) {
      changeLike({ projectId }).then((response) => {
        if (!(response as ErrorResponse)?.error) {
          setButtonExtraClassName(
            wasLiked
              ? ' like-button-compact--clicked-unlike'
              : ' like-button-compact--clicked-like'
          );
          setTimeoutId(
            setTimeout(() => {
              setButtonExtraClassName('');
              setTimeoutId(undefined);
            }, ANIMATION_DURATION)
          );
        }
      });
    }
  };

  const onLikeClick = () => {
    if (isSuccess) {
      toggleLike();
    } else {
      dispatch(changeModal(MODALS.SIGN_IN));
    }
  };

  useEffect(() => () => clearTimeout(timeoutId), [timeoutId]);

  return (
    <Button
      className={`like-button-compact${buttonExtraClassName} ${className}`}
      type={BUTTON_TYPE.TEXT_LIGHT}
      onClick={onLikeClick}
      disabled={disabled}
    >
      <Icon
        className={`like-button-compact__icon${
          wasLiked ? ' like-button-compact__icon--liked' : ''
        }`}
        icon={IconType.Heart}
      />
    </Button>
  );
};

export default LikeButtonCompact;
