import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import Spinner from 'src/shared/components/Spinner/Spinner';
import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import { changeDrawHint } from 'src/features/draw/slices/drawSlice';
import MobileHeader from 'src/features/mobile/components/MobileHeader/MobileHeader';
import { ModalBase, ModalSmallScreen } from 'src/features/modal/ModalBase';
import { useLazyGetProjectStepsQuery } from 'src/features/project/api';
import { useGetProjectInfoAndCheck } from 'src/features/project/hooks';
import {
  PROJ_CREATE,
  setConfigEditingSteps,
  setCurrentEditingStep,
  setCurrentTypeProject,
  setProjectId,
} from 'src/features/project/slices/creatingProjectSlice';
import {
  PROJ_LAYER,
  setCurrentProjectInfo,
} from 'src/features/project/slices/projectsLayerSlice';
import { State } from 'src/features/store/store';

import Header from 'src/pages/layouts/components/Header/Header';
import EditingFooter, {
  EditingFooterProps,
} from 'src/pages/layouts/EditLayout/components/EditingFooter/EditingFooter';
import SideMenu from 'src/pages/layouts/EditLayout/components/SideMenu/SideMenu';

import './EditLayout.scss';

interface EditLayoutProps {
  className?: string;
}

export const EditLayout = ({
  className = '',
}: EditLayoutProps): JSX.Element => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isSmallScreen = useCheckSmallScreen();

  const currentProjectInfo = useSelector(
    (s: State) => s[PROJ_LAYER].currentProjectInfo
  );

  const projectType = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );

  const [configAllowed, setConfigAllowed] = useState<boolean>(false);

  const [getProjectSteps] = useLazyGetProjectStepsQuery();

  const {
    data: projectInfo,
    isLoading: projectInfoLoading,
    isSuccess: projectInfoSuccess,
    isUninitialized: projectInfoIsUninitialized,
    refetch,
  } = useGetProjectInfoAndCheck(id);

  const {
    data: whoami,
    isLoading: whoamiLoading,
    isSuccess: whoamiSuccess,
    isUninitialized: whoamiIsUninitialized,
  } = useWhoamiQuery();

  const dataReceived =
    !whoamiIsUninitialized &&
    !projectInfoIsUninitialized &&
    !whoamiLoading &&
    !projectInfoLoading;

  const accessAllowed =
    whoamiSuccess &&
    projectInfoSuccess &&
    whoami &&
    projectInfo &&
    (whoami.data.role === 'ADMIN' ||
      whoami.data.userID === projectInfo.data.projectInfo.ownerId);

  const checkAccess = () => {
    if (dataReceived) {
      if (!accessAllowed) {
        navigate(paths.map);
      }
    }
  };

  const fillCurrentProjectInfo = () => {
    if (projectInfo && projectInfo.data.projectInfo.type) {
      dispatch(setCurrentProjectInfo(projectInfo.data));
      dispatch(setProjectId(projectInfo.data.projectInfo.projectID));
      dispatch(setCurrentTypeProject(projectInfo.data.projectInfo.type.type));
      getProjectSteps({
        type: projectInfo.data.projectInfo.type.type,
      })
        .unwrap()
        .then((response) => {
          dispatch(setConfigEditingSteps(response.data.editingSteps));
          dispatch(setCurrentEditingStep(0));
          setConfigAllowed(true);
        });
    }
    return () => {
      dispatch(setCurrentProjectInfo(null));
    };
  };

  useEffect(checkAccess, [accessAllowed, dataReceived, navigate]);

  useEffect(() => {
    refetch();
  }, [refetch, currentProjectInfo]);

  useEffect(fillCurrentProjectInfo, [
    projectInfo,
    dispatch,
    getProjectSteps,
    navigate,
  ]);

  useEffect(() => {
    dispatch(changeDrawHint(t('drawTool.firstPointTooltipEditing')));
  }, [dispatch, t]);

  const renderFooter = (props: EditingFooterProps) => (
    <EditingFooter {...props} />
  );

  const getHeaderTitle = (): string => {
    const pathParts = pathname.split('/');
    if (projectType) {
      if (pathParts.includes('menu')) {
        return t(`editing.${projectType}.headerTitle`);
      } else {
        return t(`editing.${projectType}.${pathParts[2]}.stageName`);
      }
    } else {
      return t('editing.title');
    }
  };

  const renderLayout = () =>
    isSmallScreen ? (
      <>
        <main className="edit-layout">
          <MobileHeader title={getHeaderTitle()} />
          <div className={`edit-layout__gate ${className}`}>
            <Outlet context={{ renderFooter }} />
            <ModalSmallScreen />
          </div>
        </main>
      </>
    ) : (
      <>
        <Header />
        <main className="edit-layout">
          <SideMenu />
          <div className={`edit-layout__gate ${className}`}>
            <Outlet context={{ renderFooter }} />
            <ModalBase />
          </div>
        </main>
      </>
    );

  const renderLoader = () => (
    <div className={'edit-layout-loader'}>
      <Spinner />
    </div>
  );

  return configAllowed && accessAllowed ? renderLayout() : renderLoader();
};

export default EditLayout;
