import { LegacyRef, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { Status } from '@platform-for-public-places/components-library';

import {
  useLazyGetConfirmedParticipantsQuery,
  useLazyGetEventParticipantsQuery,
} from 'src/features/project/api';
import { PROJECT_STATUS } from 'src/features/status/enums';
import { EventParticipants } from 'src/features/user/models';

import {
  PARTICIPANTS_FIRST_PAGE_NUMBER,
  PARTICIPANTS_PAGE_SIZE,
  PARTICIPANTS_PAGE_SIZE_MOBILE,
} from 'src/pages/project/constants';

interface UseEventParticipantsHookArgs {
  archived?: boolean;
  projectId: string;
  isSmallScreen?: boolean;
  status: Status;
}

interface UseEventParticipantsHookResult {
  hasNextPage: boolean;
  isLoading: boolean;
  participants: EventParticipants;
  participantsCatalog: LegacyRef<HTMLDivElement>;
  refetchParticipants?: () => void;
}

export const useEventParticipants = ({
  archived,
  projectId,
  isSmallScreen,
  status,
}: UseEventParticipantsHookArgs): UseEventParticipantsHookResult => {
  const [eventParticipantsPage, setEventParticipantsPage] = useState<number>(
    PARTICIPANTS_FIRST_PAGE_NUMBER
  );
  const [hasEventParticipantsNextPage, setHasEventParticipantsNextPage] =
    useState<boolean>(true);
  const [eventParticipantsError, setEventParticipantsError] =
    useState<boolean>(false);
  const [eventParticipantsLoading, setEventParticipantsLoading] =
    useState<boolean>(false);
  const [eventParticipants, setEventParticipants] = useState<EventParticipants>(
    {
      eventParticipants: [],
      count: 0,
    }
  );

  const [confirmedParticipantsPage, setConfirmedParticipantsPage] =
    useState<number>(PARTICIPANTS_FIRST_PAGE_NUMBER);
  const [
    hasConfirmedParticipantsNextPage,
    setHasConfirmedParticipantsNextPage,
  ] = useState<boolean>(true);
  const [confirmedParticipantsError, setConfirmedParticipantsError] =
    useState<boolean>(false);
  const [confirmedParticipantsLoading, setConfirmedParticipantsLoading] =
    useState<boolean>(false);
  const [confirmedParticipants, setConfirmedParticipants] =
    useState<EventParticipants>({
      eventParticipants: [],
      count: 0,
    });

  const [getParticipants] = useLazyGetEventParticipantsQuery();
  const [getConfirmedParticipants] = useLazyGetConfirmedParticipantsQuery();

  const [eventParticipantsTrigger] = useInfiniteScroll({
    loading: eventParticipantsLoading,
    hasNextPage: hasEventParticipantsNextPage,
    onLoadMore: () => {
      setEventParticipantsLoading(true);
      getParticipants({
        projectId: projectId,
        page: eventParticipantsPage,
        pageSize: isSmallScreen
          ? PARTICIPANTS_PAGE_SIZE_MOBILE
          : PARTICIPANTS_PAGE_SIZE,
        archived,
      })
        .unwrap()
        .then(({ data }) => {
          if (data) {
            const addData = (prev: EventParticipants): EventParticipants => ({
              eventParticipants: [
                ...prev.eventParticipants,
                ...data.eventParticipants,
              ],
              count: data.aggregators.count,
            });
            setEventParticipants((prev) => addData(prev));
            setEventParticipantsPage(eventParticipantsPage + 1);
            setHasEventParticipantsNextPage(
              data.eventParticipants.length ===
                (isSmallScreen
                  ? PARTICIPANTS_PAGE_SIZE_MOBILE
                  : PARTICIPANTS_PAGE_SIZE)
            );
          }
        })
        .catch(() => setEventParticipantsError(true))
        .finally(() => setEventParticipantsLoading(false));
    },
    disabled: eventParticipantsError,
  });

  const refetchEventParticipants = () => {
    setEventParticipantsPage(PARTICIPANTS_FIRST_PAGE_NUMBER);
    setHasEventParticipantsNextPage(true);
    setEventParticipantsLoading(false);
    setEventParticipantsError(false);
    setEventParticipants({
      eventParticipants: [],
      count: 0,
    });
  };

  const [confirmedParticipantsTrigger] = useInfiniteScroll({
    loading: confirmedParticipantsLoading,
    hasNextPage: hasConfirmedParticipantsNextPage,
    onLoadMore: () => {
      setConfirmedParticipantsLoading(true);
      getConfirmedParticipants({
        projectId: projectId,
        page: confirmedParticipantsPage,
        pageSize: isSmallScreen
          ? PARTICIPANTS_PAGE_SIZE_MOBILE
          : PARTICIPANTS_PAGE_SIZE,
        archived,
      })
        .unwrap()
        .then(({ data }) => {
          if (data) {
            const addData = (prev: EventParticipants): EventParticipants => ({
              eventParticipants: [
                ...prev.eventParticipants,
                ...data.eventParticipants,
              ],
              count: data.aggregators.count,
            });
            setConfirmedParticipants((prev) => addData(prev));
            setConfirmedParticipantsPage(confirmedParticipantsPage + 1);
            setHasConfirmedParticipantsNextPage(
              data.eventParticipants.length ===
                (isSmallScreen
                  ? PARTICIPANTS_PAGE_SIZE_MOBILE
                  : PARTICIPANTS_PAGE_SIZE)
            );
          }
        })
        .catch(() => setConfirmedParticipantsError(true))
        .finally(() => setConfirmedParticipantsLoading(false));
    },
    disabled: confirmedParticipantsError,
  });

  const eventParticipantsResult: UseEventParticipantsHookResult = {
    hasNextPage: hasEventParticipantsNextPage,
    isLoading: eventParticipantsLoading,
    participants: eventParticipants,
    participantsCatalog: eventParticipantsTrigger,
    refetchParticipants: refetchEventParticipants,
  };

  const confirmedParticipantsResult: UseEventParticipantsHookResult = {
    hasNextPage: hasConfirmedParticipantsNextPage,
    isLoading: confirmedParticipantsLoading,
    participants: confirmedParticipants,
    participantsCatalog: confirmedParticipantsTrigger,
  };

  return status.value === PROJECT_STATUS.FINISHED
    ? confirmedParticipantsResult
    : eventParticipantsResult;
};
