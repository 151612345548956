import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import ModalWindow from 'src/features/modal/components/ModalWindow/ModalWindow';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import SwiperCarousel, {
  SWIPER_CAROUSEL_TYPE,
} from 'src/features/project/components/SwiperCarousel/SwiperCarousel';
import { State } from 'src/features/store/store';

import { changeOpenedIndex, PHOTOS } from '../../slices/photosModalSlice';

import './PictureModal.scss';

enum ARROW_KEY {
  LEFT = 'ArrowLeft',
  RIGHT = 'ArrowRight',
}

function PictureModal() {
  const dispatch = useDispatch();
  const isSmallScreen = useCheckSmallScreen();

  const photos = useSelector((s: State) => s[PHOTOS].photos);
  const openedIndex = useSelector((s: State) => s[PHOTOS].openedIndex);

  const handlePrev = useCallback(() => {
    dispatch(
      changeOpenedIndex(openedIndex > 0 ? openedIndex - 1 : photos.length - 1)
    );
  }, [dispatch, openedIndex, photos.length]);

  const handleNext = useCallback(() => {
    dispatch(
      changeOpenedIndex(openedIndex + 1 < photos.length ? openedIndex + 1 : 0)
    );
  }, [dispatch, openedIndex, photos.length]);

  useEffect(() => {
    const handleArrows = (e: KeyboardEvent) => {
      if (e.code === ARROW_KEY.LEFT) {
        handlePrev();
      } else if (e.code === ARROW_KEY.RIGHT) {
        handleNext();
      }
    };

    document.addEventListener('keydown', handleArrows);
    return () => document.removeEventListener('keydown', handleArrows);
  }, [handleNext, handlePrev]);

  const renderDesktop = () => (
    <>
      <Button
        className={`button-round ${
          photos.length <= 1 ? 'button-round--hiden' : ''
        }`}
        type={BUTTON_TYPE.PRIMARY}
        onClick={handlePrev}
      >
        <Icon
          className="button-round__icon button-round__icon_left"
          icon={IconType.Arrow}
        />
      </Button>
      <div className="picture-modal__container">
        <img
          alt={`Issue ${openedIndex}`}
          src={photos[openedIndex].url}
          className="picture-modal__picture"
        />
        <button
          className="overview-card__close-action picture-modal__close"
          onClick={() => dispatch(changeModal(null))}
        >
          <Icon className="icon-cross" icon={IconType.Cross} />
        </button>
      </div>
      <Button
        className={`button-round ${
          photos.length <= 1 ? 'button-round--hiden' : ''
        }`}
        type={BUTTON_TYPE.PRIMARY}
        onClick={handleNext}
      >
        <Icon className="button-round__icon" icon={IconType.Arrow} />
      </Button>
    </>
  );

  const renderMobile = () => (
    <SwiperCarousel
      openedPhoto={openedIndex}
      className="picture-modal__mobile-carousel"
      photos={photos}
      type={SWIPER_CAROUSEL_TYPE.MODAL}
    />
  );

  return (
    <ModalWindow className="picture-modal">
      {isSmallScreen ? renderMobile() : renderDesktop()}
    </ModalWindow>
  );
}

export default PictureModal;
