import { paths } from 'src/shared/routes';

export const EXCLUDED_PATHS = [
  paths.profile,
  paths.signIn,
  paths.signUp,
  paths.recoverPassword,
  paths.recoverPasswordSuccess,
  paths.forgotPassword,
  paths.forgotPasswordSent,
  paths.confirm,
  paths.confirmed,
  paths.confirmation,
  paths.autopaymentsManagement,
  paths.autopaymentsManagementSent,
];
