import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import Box from 'src/shared/components/Box/Box';

import SearchInputControl from 'src/features/control/components/SearchInputControl/SearchInputControl';
import { setSearchPanelState } from 'src/features/control/slices/controlSlice';
import NumberProjectSearch from 'src/features/map/components/SearchPane/components/NumberProjectSearch/NumberProjectSearch';
import { SearchContext } from 'src/features/searchWithFilter/filter/search/SearchProvider';
import FilterButton from 'src/features/searchWithFilter/filter/searchFilters/FilterButton/FilterButton';
import FilterOrder from 'src/features/searchWithFilter/filter/searchFilters/FilterOrder/FilterOrder';

import './SearchFilterInput.scss';

export enum SearchPanelState {
  CLOSE,
  OPEN,
  OPEN_WITH_FILTER,
}

const SearchFilterInput = () => {
  const { resetSearch } = useContext(SearchContext);
  return (
    <Box wrap={'wrap'} gap={'1.7em'}>
      <Box flex={1} gap={'0.8em'}>
        <SearchInputControl
          className="search-pane__input"
          onChange={resetSearch}
        />
        <FilterButton />
      </Box>
      <Box alignItems={'center'} flex={1} justifyContent={'space-between'}>
        <NumberProjectSearch />
        <FilterOrder />
      </Box>
    </Box>
  );
};

export const SearchFilterInputCatalog = () => {
  const { resetSearch } = useContext(SearchContext);
  return (
    <Box flexDirection={'column'} gap={'2em'}>
      <Box flex={1} gap={'1.25em'}>
        <FilterButton className={'search-filter-button__catalog'} />
        <SearchInputControl
          className="search-pane__input_catalog search-pane__input"
          onChange={resetSearch}
        />
        <FilterOrder />
      </Box>
      <Box alignItems={'center'} flex={1} justifyContent={'space-between'}>
        <NumberProjectSearch />
      </Box>
    </Box>
  );
};

export const SearchFilterInputUserProjects = () => {
  const { resetSearch } = useContext(SearchContext);
  return (
    <Box flexDirection={'column'} gap={'0.8em'}>
      <Box flex={1} gap={'6.8em'} justifyContent={'space-between'}>
        <SearchInputControl
          className="search-pane__input"
          onChange={resetSearch}
        />
      </Box>
      <Box alignItems={'center'} flex={1} justifyContent={'space-between'}>
        <NumberProjectSearch />
        <FilterOrder />
      </Box>
    </Box>
  );
};

export default SearchFilterInput; //todo delete default

export const SearchFilterInputOnlyButtons = () => {
  const dispatch = useDispatch();
  return (
    <Box gap={'0.8em'} className={'search-pane__input-map'}>
      <SearchInputControl
        className="search-pane__input-map"
        textStyle={'search-pane__input-map'}
        onFocus={() => dispatch(setSearchPanelState(SearchPanelState.OPEN))}
      />
      <FilterButton className="filter-button__map" />
    </Box>
  );
};
