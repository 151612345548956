import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import debounce from 'lodash.debounce';

import { DEBOUNCE_TIMEOUT } from 'src/app/constants';

import ProjectListLocators from 'src/features/map/components/ProjectsLayer/components/ProjectListLocators';
import { useLazyGetProjectsInfoWithGeodataQuery } from 'src/features/project/api';
import {
  addProjectsByKey,
  removeProjectsByKey,
} from 'src/features/project/slices/projectsLayerSlice';
import { ProjectFilterOption } from 'src/features/searchWithFilter/filter/models';
import { FILTER } from 'src/features/searchWithFilter/filter/slices/filterSlice';
import { State } from 'src/features/store/store';

import './ProjectsLayer.scss';

const INITIAL_PAGE = 1;
const PAGE_SIZE = 50;

type ProjectsLayerProps = {
  archived?: boolean;
  type: ProjectFilterOption;
  statuses?: ProjectFilterOption[];
};

type ProjectsLayerProviderProps = {
  archived?: boolean;
  type: ProjectFilterOption;
  children: ReactElement | ReactElement[];
};

const ProjectLayerProvider = ({
  children,
  type,
}: ProjectsLayerProviderProps) => {
  //todo можно вынести пагинацию в отдельный provider
  const dispatch = useDispatch();
  const status = useSelector((s: State) => s[FILTER].status);
  const searchString = useSelector((s: State) => s[FILTER].searchString);

  const { type: typeList } = useSelector((s: State) => s[FILTER]);
  const [page, setPage] = useState<number>(INITIAL_PAGE);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [bufferedStatus, setBufferedStatus] = useState<string[] | undefined>(
    status
  );
  const [bufferedSearchString, setBufferedSearchString] = useState<string>('');

  const [getProjects] = useLazyGetProjectsInfoWithGeodataQuery();

  const fetchProjects = useCallback(
    () => {
      if (typeList?.includes(type.id)) {
        //todo optimisation every time rerender all layers (проверка что если тип,строка,status не обновился то не запроашивать?
        getProjects({
          pageSize: PAGE_SIZE,
          page: page,
          types: [type.id],
          flowStepIds: status ?? [],
          searchString: `%${searchString}%`,
        })
          .unwrap()
          .then(({ data }) => {
            dispatch(
              addProjectsByKey({
                reset: INITIAL_PAGE === page,
                key: type.id,
                data: data.projectInfo,
              })
            );
            setPage(page + 1);
            setHasNextPage(
              (data?.aggregators.count ?? PAGE_SIZE) > page * PAGE_SIZE
            );
          });
      } else {
        dispatch(removeProjectsByKey(type.id));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchString, type, typeList, page, hasNextPage]
  );

  const resetProjects = useRef(
    debounce(() => {
      setPage(INITIAL_PAGE);
      setHasNextPage(true);
    }, DEBOUNCE_TIMEOUT)
  ).current;

  // Loads projects until it loads all of them. Triggers on `page` change.
  useEffect(() => {
    if (hasNextPage) {
      fetchProjects();
    }
  }, [fetchProjects, page, hasNextPage]);

  // Forces projects refetch on search
  useEffect(() => {
    if (
      searchString !== bufferedSearchString ||
      status !== bufferedStatus //todo fix?
    ) {
      resetProjects();
      setBufferedSearchString(searchString);
      setBufferedStatus(status);
    }
    return () => resetProjects.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetProjects, searchString, status]);
  return <>{children}</>;
};

const ProjectsLayer = ({
  archived,
  type,
  statuses,
}: ProjectsLayerProps): JSX.Element => {
  return (
    <ProjectLayerProvider archived={archived} type={type}>
      <ProjectListLocators
        archived={archived}
        type={type}
        statuses={statuses}
      />
    </ProjectLayerProvider>
  );
};

export default ProjectsLayer;
