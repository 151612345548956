import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';
import clsx from 'clsx';

import Box from 'src/shared/components/Box/Box';

import FilterOrderCards from 'src/features/searchWithFilter/filter/searchFilters/FilterOrder/FilterOrderCards/FilterOrderCards';
import { FilterOrderContext } from 'src/features/searchWithFilter/filter/searchFilters/FilterOrder/FilterOrderProvider';

import './FilterOrder.scss';

type FilterOrderProps = {
  className?: string;
};

const FilterOrder = ({ className }: FilterOrderProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'projectsList' });

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const onClickFilterButton = () => {
    setIsFilterOpen((prev) => !prev);
  };
  const { currentTypeSortOrderOptions } = useContext(FilterOrderContext);

  //todo add typogtaphy
  const isSmallScreen = useCheckSmallScreen();
  return (
    <Box alignItems={'center'} gap={'0.5em'}>
      <>
        {!isSmallScreen ? (
          <p className="filter-order__title">{t('sorting.label')}</p>
        ) : null}
      </>
      <div className="filter-order__wrapper">
        <button
          className={clsx('filter-order__background', {
            'filter-order__background--open': isFilterOpen,
          })}
          onClick={onClickFilterButton}
        ></button>
        <Button
          className={clsx('filter-order__button', className, {
            'filter-order__button--close': isFilterOpen,
          })}
          type={BUTTON_TYPE.TEXT_SECONDARY}
          onClick={onClickFilterButton}
        >
          {currentTypeSortOrderOptions?.label}
          <Icon icon={IconType.Chevron} />
        </Button>
        <div
          className={clsx('filter-order__card', className, {
            'filter-order__card--invisible': !isFilterOpen,
          })}
        >
          <FilterOrderCards />
        </div>
      </div>
    </Box>
  );
};

export default FilterOrder;
