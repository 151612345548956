import { createRoot } from 'react-dom/client';

import 'src/app/translation/translation';
import App from 'src/app/App';

import '@platform-for-public-places/components-library/styles/_colors.scss';
import '@platform-for-public-places/components-library/styles/_common.scss';
import 'react-spring-bottom-sheet/dist/style.css';
// Есть какой-то баг с этим плагином для этого импорта, хз какой
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
import './index.scss';

const container = document.getElementById('root') as Element;
const root = createRoot(container);

root.render(<App />);
