import { Icon, IconType } from '@platform-for-public-places/components-library';
import clsx from 'clsx';

import Box from 'src/shared/components/Box/Box';
import Spinner from 'src/shared/components/Spinner/Spinner';

import { useGetPresignedUrlsQuery } from 'src/features/minIo/api';
import { PresignedUrlByKeyMapId } from 'src/features/minIo/models';
import { PaymentsReportsData } from 'src/features/payment/models';

import './AnnualReportDocs.scss';

interface AnnualReportDocsProps {
  reports: PaymentsReportsData[];
  className?: string;
}

const AnnualReportDocs = ({
  reports,
  className = '',
}: AnnualReportDocsProps): JSX.Element => {
  const { data: presignedUrls, isLoading } = useGetPresignedUrlsQuery({
    messageMapId: PresignedUrlByKeyMapId.PAYMENTS_REPORTS,
    keys: reports.map((report) => report.key),
  });

  return isLoading ? (
    <Box justifyContent={'center'}>
      <Spinner className="annual-report-docs__spinner" />
    </Box>
  ) : (
    <div className={clsx('annual-report-docs', className)}>
      {presignedUrls?.data.map((report, index) =>
        reports[index] ? (
          <a
            key={index}
            className="annual-report-docs__document"
            rel="noreferrer"
            href={report.url}
            target="_blank"
          >
            <Icon
              icon={IconType.Docs}
              className="annual-report-docs__docs-icon"
            />
            {reports[index].fileName}
          </a>
        ) : (
          <></>
        )
      )}
    </div>
  );
};

export default AnnualReportDocs;
