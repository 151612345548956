import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import DetailedPlatformTeamMember from 'src/features/modal/components/DetailedPlatformTeamMember/DetailedPlatformTeamMember';
import { MODALS } from 'src/features/modal/models';
import {
  changeCurrentPlatformTeamMember,
  changeModal,
} from 'src/features/modal/slices/modalSlice';

import team from '../../jsons/team.json';

import './PlatformTeam.scss';

export interface PlatformTeamMember {
  id: string;
  name: string;
  role: string;
  avatar: string;
  email?: string;
  phone?: string;
  citation: string;
  achievements: {
    value: string;
    url?: string;
  }[];
}

interface PlatformTeamSectionProps {
  className?: string;
}

const PlatformTeam = ({ className }: PlatformTeamSectionProps) => {
  const dispatch = useDispatch();
  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'frontPage.team' });

  const [moreInfo, setMoreInfo] = useState(false);
  const [selectedMobileMember, setSelectedMobileMember] = useState('0');

  const showDetailedInfo = useCallback(
    (member: PlatformTeamMember) => {
      dispatch(changeCurrentPlatformTeamMember(member));
      dispatch(changeModal(MODALS.DETAILED_PLATFORM_TEAM_MEMBER));
    },
    [dispatch]
  );

  const teamMembersCells = useMemo(() => {
    return team.map((member: PlatformTeamMember) => (
      <div
        className="platform-team__flex-item"
        id={member.role}
        key={member.id}
      >
        <button
          className="platform-team__item-content"
          onClick={() => showDetailedInfo(member)}
        >
          <img
            alt={member.name}
            className="platform-team__avatar"
            src={require(`../../images/team/${member.avatar}`)}
          />
          <h3 className="platform-team__name">{member.name}</h3>
          <h4 className="platform-team__role">{t(`roles.${member.role}`)}</h4>
        </button>
      </div>
    ));
  }, [showDetailedInfo, t]);

  const renderMobileTeam = () => {
    return team.map((member, i) => {
      return (
        <div key={i} id={i.toString()} className="platform-team__member">
          <div
            ref={(el) => (titleRef.current[i] = el)}
            id={i.toString()}
            className="platform-team__member--before"
          ></div>
          <DetailedPlatformTeamMember
            moreInfo={moreInfo}
            setMoreInfo={setMoreInfo}
            memberMobile={member}
          />
        </div>
      );
    });
  };

  const titleRef = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target.id) {
              setSelectedMobileMember(entry.target.id);
            }
          }
        });
      },
      {
        rootMargin: `0px -50%  0px -50% `,
      }
    );

    titleRef.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });
  }, []);

  const executeScroll = async (key: number) => {
    await titleRef.current[key]?.scrollIntoView({
      block: 'nearest',
    });
  };

  const renderMobilePreviewTeam = () => {
    return team.map((member, i) => {
      return (
        <button
          key={i}
          onClick={() => {
            executeScroll(i);
          }}
        >
          <img
            alt={member.name}
            className={`platform-team__avatar ${
              selectedMobileMember === i.toString()
                ? 'platform-team__avatar--active'
                : ''
            }`}
            src={require(`../../images/team/${member.avatar}`)}
          />
        </button>
      );
    });
  };

  return (
    <section className={`platform-team ${className}`}>
      <h2 className="platform-team__title">{t('title')}</h2>
      {isSmallScreen ? (
        <div className="platform-team__mobile-container">
          <div className="platform-team__mobile-preview">
            {renderMobilePreviewTeam()}
          </div>
          <div className="platform-team__mobile">{renderMobileTeam()}</div>
        </div>
      ) : (
        <div className="platform-team__flex">{teamMembersCells}</div>
      )}
    </section>
  );
};

export default PlatformTeam;
