import { TYPE_SEARCH_PAGE } from 'src/features/map/components/SearchPane/components/PaginationList/PaginationList';
import { ProjectCatalogData } from 'src/features/project/models';
import ListProjectsCatalog from 'src/features/searchWithFilter/listProjects/catalog/ListProjectsCatalog';
import ListProjectsOnMap from 'src/features/searchWithFilter/listProjects/map/ListProjectsOnMap';

export const mapPageTypeRenderProject: Record<
  TYPE_SEARCH_PAGE,
  ({ projects }: { projects: ProjectCatalogData[] }) => JSX.Element[]
> = {
  [TYPE_SEARCH_PAGE.PROJECT_MAP]: ListProjectsOnMap,
  [TYPE_SEARCH_PAGE.PROJECT_CATALOG]: ListProjectsCatalog,
  [TYPE_SEARCH_PAGE.USER_PROJECTS]: ListProjectsCatalog,
};

export const mapPageTypeStyle: Record<TYPE_SEARCH_PAGE, string> = {
  [TYPE_SEARCH_PAGE.PROJECT_MAP]: 'search-pane__list',
  [TYPE_SEARCH_PAGE.PROJECT_CATALOG]: 'search-pane__list-catalog',
  [TYPE_SEARCH_PAGE.USER_PROJECTS]: 'search-pane__list-catalog',
};
