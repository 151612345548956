import { Outlet } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import MobileHeader, {
  HeaderType,
} from 'src/features/mobile/components/MobileHeader/MobileHeader';

import './LayoutWithLogo.scss';

interface LayoutWithLogoProps {
  className?: string;
  hideHeader?: boolean;
}

export const LayoutWithLogo = ({
  className = '',
  hideHeader = false,
}: LayoutWithLogoProps): JSX.Element => {
  const isSmallScreen = useCheckSmallScreen();

  return isSmallScreen ? (
    <main className="layout-with-logo">
      {hideHeader ? null : <MobileHeader headerType={HeaderType.Logo} />}
      <div className={`layout-with-logo__gate ${className}`}>
        <Outlet />
      </div>
    </main>
  ) : (
    <Outlet />
  );
};

export default LayoutWithLogo;
