import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  useCheckSmallScreen,
  USER_ROLE,
} from '@platform-for-public-places/components-library';

import MenuSteps from 'src/shared/components/MenuStep/MenuSteps';
import { paths } from 'src/shared/routes';

import { useLazyLogoutQuery, useWhoamiQuery } from 'src/features/auth/api';

import { ReactComponent as NoAuthProfileLogo } from '../Profile/img/mobile-profile-logo.svg';

import './ProfileMenu.scss';

const STEPS = {
  info: {
    name: 'profile.info.tabName',
    path: paths.profileInfo,
    roles: [USER_ROLE.ADMIN, USER_ROLE.USER],
  },
  projects: {
    name: 'profile.projects.tabName',
    path: paths.profileProjects,
    roles: [USER_ROLE.ADMIN, USER_ROLE.USER],
  },
  specialist: {
    name: 'profile.specialist.tabName',
    path: paths.becomeSpecialist,
    roles: [USER_ROLE.USER],
  },
  participations: {
    path: paths.profileParticipations,
    name: 'profile.participations.tabName',
    roles: [USER_ROLE.ADMIN, USER_ROLE.USER],
  },
  payments: {
    path: paths.profilePayments,
    name: 'profile.payments.tabName',
    roles: [USER_ROLE.ADMIN, USER_ROLE.USER],
  },
};

const ProfileMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSmallScreen = useCheckSmallScreen();
  const [requestLogout] = useLazyLogoutQuery();
  const user = useWhoamiQuery();

  const { isSuccess: isAuthorized } = useWhoamiQuery();

  const logout = () => {
    requestLogout().then(() => {
      navigate(paths.map);
      document.location.reload();
    });
  };

  const renderMobileNoAuth = () => (
    <div className="profile-no-auth">
      <h1 className="profile-no-auth__header">{t('profile.mobile.header')}</h1>
      <p className="profile-no-auth__description">
        {t('profile.mobile.description')}
      </p>
      <NoAuthProfileLogo className="profile-no-auth__logo" />
      <Button
        className="profile-no-auth__button"
        onClick={() => navigate(paths.signUp)}
        type={BUTTON_TYPE.ACCENTED}
      >
        {t('profile.mobile.signUp')}
      </Button>
      <Button
        className="profile-no-auth__button"
        onClick={() => navigate(paths.signIn)}
        type={BUTTON_TYPE.ACCENTED_OUTLINE}
      >
        {t('profile.mobile.signIn')}
      </Button>
    </div>
  );

  const renderProfileMenu = () => {
    return (
      <div className="profile-menu">
        <MenuSteps
          steps={STEPS}
          userRole={(user.data?.data?.role as USER_ROLE) ?? ''}
        />
        <Button
          className="profile-menu__logout"
          type={BUTTON_TYPE.TEXT}
          onClick={logout}
        >
          <Icon icon={IconType.Exit} />
          {t('profilePan.logout')}
        </Button>
      </div>
    );
  };

  const renderMobile = () =>
    isAuthorized ? renderProfileMenu : renderMobileNoAuth;

  const render = isSmallScreen
    ? renderMobile()
    : () => <Navigate to={paths.profileInfo} replace />;

  return render();
};

export default ProfileMenu;
