import { ReactElement, useState } from 'react';

import clsx from 'clsx';

import './Tooltip.scss';

interface TooltipProps {
  className?: string;
  children: ReactElement;
  text: string;
  tooltipClassName?: string;
}

const Tooltip = ({
  className,
  children,
  text,
  tooltipClassName,
}: TooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const onMouseEnterHandler = () => {
    setShowTooltip(true);
  };

  const onMouseLeaveHandler = () => {
    setShowTooltip(false);
  };

  return (
    <div
      className={clsx('container', className)}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      {children}
      {showTooltip ? (
        <div className={clsx('tooltip', tooltipClassName)}>{text}</div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Tooltip;
