import { paths } from 'src/shared/routes';

import ProjectPreviewCatalog from 'src/features/project/components/ProjectPreviewContainer/ProjectPreviewCatalog/ProjectPreviewCatalog';
import { ProjectCatalogData } from 'src/features/project/models';

const ListProjectsCatalog = ({
  projects,
}: {
  projects: ProjectCatalogData[];
}) =>
  projects.map((project) => (
    <ProjectPreviewCatalog
      key={project.projectId}
      linkTo={paths.projectById(project.projectId)}
      large
      project={project}
      className="projects-list__project"
      statusClassName="project__status"
    />
  ));
export default ListProjectsCatalog;
