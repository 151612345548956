import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ru } from 'date-fns/locale';
import { TooltipProps } from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

import { Card, CARD_TYPE } from 'src/shared/components/Card/Card';
import { DATE_TIME_FORMAT, getMonthByNumber } from 'src/shared/functions/dates';

import './ReportingChartTooltip.scss';

const ReportingChartTooltip = ({
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  const month = label
    ? getMonthByNumber(label, DATE_TIME_FORMAT.LONG, ru.code)
    : null;
  const { t } = useTranslation('app', { keyPrefix: 'reporting' });

  const valuesSum = payload
    ?.map((element) => element.value as number)
    .reduce((partialSum, a) => partialSum + a, 0);

  const getAmount = (value: number) => {
    return t('amountValue', {
      amount: value,
      formatParams: { amount: { maximumFractionDigits: 0 } },
    });
  };

  const renderPayload = () => (
    <ul className="reporting-chart-tooltip__reporting-types-list reporting-types-list">
      <li className="reporting-types-list__element">
        {`${t('amount')}:  ${getAmount(valuesSum ?? 0)}`}
      </li>
      {payload?.reverse().map((element) => (
        <li
          className={`reporting-types-list__element reporting-types-list__${element.dataKey}`}
          key={element.name}
        >
          {`${element.name}:  ${getAmount((element.value as number) ?? 0)}`}
        </li>
      ))}
    </ul>
  );

  return (
    <Card className="reporting-chart-tooltip" type={CARD_TYPE.SMALL}>
      <p className="reporting-chart-tooltip__label">{month}</p>
      {renderPayload()}
    </Card>
  );
};

const ReportingChartTooltipMemo = memo(
  ReportingChartTooltip,
  (oldProps, newProps) => oldProps.label === newProps.label
);

ReportingChartTooltipMemo.displayName = 'ReportingChartTooltipMemo';

export default ReportingChartTooltipMemo;
