import { ReactElement } from 'react-markdown/lib/react-markdown';

import MobileHeaderWithLogo from '../MobileHeaderWithLogo/MobileHeaderWithLogo';
import MobileHeaderWithTitle from '../MobileHeaderWithTitle/MobileHeaderWithTitle';

import './MobileHeader.scss';

interface MobileHeaderProps {
  title?: string;
  path?: string;
  replace?: boolean;
  hideBackButton?: boolean;
  headerType?: HeaderType;
}

export const enum HeaderType {
  Logo,
  Title,
}

const map: Record<
  HeaderType,
  (
    title?: string,
    path?: string,
    replace?: boolean,
    hideBackButton?: boolean
  ) => ReactElement
> = {
  [HeaderType.Logo]: (): ReactElement => {
    return <MobileHeaderWithLogo />;
  },
  [HeaderType.Title]: (title, path, replace, hideBackButton): ReactElement => {
    return (
      <MobileHeaderWithTitle
        title={title ?? ''}
        path={path}
        replace={replace}
        hideBackButton={hideBackButton}
      />
    );
  },
};

const MobileHeader = ({
  title,
  path,
  replace = false,
  hideBackButton = false,
  headerType = HeaderType.Title,
}: MobileHeaderProps): JSX.Element => {
  const getHeaderComponentByType = (headerType: HeaderType) => {
    return map[headerType](title, path, replace, hideBackButton);
  };

  return <>{getHeaderComponentByType(headerType)}</>;
};

export default MobileHeader;
