import { GetFilesResponse } from '@platform-for-public-places/components-library';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature, Point, Polygon } from 'geojson';

import { EventParticipant } from 'src/features/user/models';

import { PhotosPayload, ProjectDatesProperties, StepConfig } from '../models';

import { PHOTOS_TYPE } from '../enums';

export const PROJ_CREATE = 'PROJ_CREATE';

interface CreatingProjectState {
  feature?: Feature<Polygon>;
  featureEdit?: Feature<Polygon>;
  center?: Feature<Point>;
  centerEdit?: Feature<Point>;
  photosBefore: GetFilesResponse[];
  photosConcept: GetFilesResponse[];
  photosAfter: GetFilesResponse[];
  photosCover: GetFilesResponse | null;
  designs: GetFilesResponse[];
  docs: GetFilesResponse[];
  budgetDocs: GetFilesResponse[];
  budget?: number;
  projectId?: string;
  name: string;
  idea: string;
  currentCreationStep: number;
  configCreationSteps: StepConfig[];
  currentEditingStep: number;
  configEditingSteps: StepConfig[];
  currentTypeProject: string;
  meetingPoint?: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  allParticipants: EventParticipant[];
}

const initialState: CreatingProjectState = {
  photosBefore: [],
  photosConcept: [],
  photosAfter: [],
  photosCover: null,
  designs: [],
  docs: [],
  budgetDocs: [],
  name: '',
  idea: '',
  currentCreationStep: 0,
  configCreationSteps: [],
  currentEditingStep: 0,
  configEditingSteps: [],
  currentTypeProject: '',
  meetingPoint: '',
  startDate: '',
  endDate: '',
  startTime: '',
  endTime: '',
  allParticipants: [],
};

const creatingProjectSlice = createSlice({
  name: PROJ_CREATE,
  initialState,
  reducers: {
    setFeature: (
      state: CreatingProjectState,
      action: PayloadAction<Feature<Polygon> | undefined>
    ) => {
      state.feature = action.payload;
    },
    resetFeature: (state: CreatingProjectState) => {
      state.feature = initialState.feature;
    },
    setFeatureEdit: (
      state: CreatingProjectState,
      action: PayloadAction<Feature<Polygon>>
    ) => {
      state.featureEdit = action.payload;
    },
    setProjectId: (
      state: CreatingProjectState,
      action: PayloadAction<string>
    ) => {
      state.projectId = action.payload;
    },
    setName: (state: CreatingProjectState, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setIdea: (state: CreatingProjectState, action: PayloadAction<string>) => {
      state.idea = action.payload;
    },
    setCenter: (
      state: CreatingProjectState,
      action: PayloadAction<Feature<Point> | undefined>
    ) => {
      state.center = action.payload;
    },
    setCenterEdit: (
      state: CreatingProjectState,
      action: PayloadAction<Feature<Point>>
    ) => {
      state.centerEdit = action.payload;
    },
    setPhotos: (
      state: CreatingProjectState,
      action: PayloadAction<PhotosPayload>
    ) => {
      if (action.payload.type === PHOTOS_TYPE.BEFORE) {
        state.photosBefore = action.payload.photos;
      } else if (action.payload.type === PHOTOS_TYPE.CONCEPT) {
        state.photosConcept = action.payload.photos;
      } else if (action.payload.type === PHOTOS_TYPE.AFTER) {
        state.photosAfter = action.payload.photos;
      } else {
        state.photosBefore = action.payload.photos;
      }
    },
    setPhotosCover: (
      state: CreatingProjectState,
      action: PayloadAction<GetFilesResponse | null>
    ) => {
      state.photosCover = action.payload;
    },
    setDesigns: (
      state: CreatingProjectState,
      action: PayloadAction<GetFilesResponse[]>
    ) => {
      state.designs = action.payload;
    },
    setStartDate: (
      state: CreatingProjectState,
      action: PayloadAction<string>
    ) => {
      state.startDate = action.payload;
    },
    setStartTime: (
      state: CreatingProjectState,
      action: PayloadAction<string>
    ) => {
      state.startTime = action.payload;
    },
    setEndDate: (
      state: CreatingProjectState,
      action: PayloadAction<string>
    ) => {
      state.endDate = action.payload;
    },
    setEndTime: (
      state: CreatingProjectState,
      action: PayloadAction<string>
    ) => {
      state.endTime = action.payload;
    },
    setDates: (
      state: CreatingProjectState,
      action: PayloadAction<ProjectDatesProperties>
    ) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.startTime = action.payload.startTime;
      state.endTime = action.payload.endTime;
    },
    setDocs: (
      state: CreatingProjectState,
      action: PayloadAction<GetFilesResponse[]>
    ) => {
      state.docs = action.payload;
    },
    setBudgetDocs: (
      state: CreatingProjectState,
      action: PayloadAction<GetFilesResponse[]>
    ) => {
      state.budgetDocs = action.payload;
    },
    setBudget: (state: CreatingProjectState, action: PayloadAction<number>) => {
      state.budget = action.payload;
    },
    setCurrentCreationStep: (
      state: CreatingProjectState,
      action: PayloadAction<number>
    ) => {
      state.currentCreationStep = action.payload;
    },
    setNextStep: (state: CreatingProjectState) => {
      state.currentCreationStep += 1;
    },
    setPrevStep: (state: CreatingProjectState) => {
      state.currentCreationStep -= 1;
    },
    setConfigCreationSteps: (
      state: CreatingProjectState,
      action: PayloadAction<StepConfig[]>
    ) => {
      state.configCreationSteps = action.payload;
    },
    setCurrentTypeProject: (
      state: CreatingProjectState,
      action: PayloadAction<string>
    ) => {
      state.currentTypeProject = action.payload;
    },
    setMeetingPoint: (
      state: CreatingProjectState,
      action: PayloadAction<string>
    ) => {
      state.meetingPoint = action.payload;
    },
    setConfigEditingSteps: (
      state: CreatingProjectState,
      action: PayloadAction<StepConfig[]>
    ) => {
      state.configEditingSteps = action.payload;
    },
    setCurrentEditingStep: (
      state: CreatingProjectState,
      action: PayloadAction<number>
    ) => {
      state.currentEditingStep = action.payload;
    },
    setAllParticipants: (
      state: CreatingProjectState,
      action: PayloadAction<EventParticipant[]>
    ) => {
      state.allParticipants = action.payload;
    },
    resetCreation: (state) => {
      return {
        ...initialState,
        currentTypeProject: state.currentTypeProject,
        currentCreationStep: state.currentCreationStep,
        configCreationSteps: state.configCreationSteps,
        currentEditingStep: state.currentEditingStep,
        configEditingSteps: state.configEditingSteps,
      };
    },
  },
});

export const {
  resetCreation,
  setCurrentCreationStep,
  setNextStep,
  setPrevStep,
  setConfigCreationSteps,
  setCurrentTypeProject,
  setName,
  setIdea,
  setDates,
  setDocs,
  setCenter,
  setPhotos,
  setPhotosCover,
  setDesigns,
  setFeature,
  resetFeature,
  setProjectId,
  setCenterEdit,
  setBudgetDocs,
  setBudget,
  setFeatureEdit,
  setMeetingPoint,
  setConfigEditingSteps,
  setCurrentEditingStep,
  setStartDate,
  setStartTime,
  setEndDate,
  setEndTime,
  setAllParticipants,
} = creatingProjectSlice.actions;

export default creatingProjectSlice.reducer;
