import { useRef } from 'react';
import { useDispatch } from 'react-redux';

import {
  Button,
  BUTTON_TYPE,
  GetFilesResponse,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';

import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import {
  changeOpenedIndex,
  changePhotos,
} from 'src/features/modal/slices/photosModalSlice';

import './SwiperCarousel.scss';

export enum SWIPER_CAROUSEL_TYPE {
  MOBILE_DEFAULT,
  MODAL,
  DESKTOP_DEFAULT,
}

interface SwiperCarouselProps {
  photos: GetFilesResponse[];
  clickable?: boolean;
  openedPhoto?: number;
  className?: string;
  type?: SWIPER_CAROUSEL_TYPE;
  mobile?: boolean;
}

const carouselProps = {
  [SWIPER_CAROUSEL_TYPE.MOBILE_DEFAULT]: {
    className: 'mobile-photo-carousel--default',
    breakpoints: {
      // when window width is >= 1px
      1: {
        slidesPerView: 1.2,
        spaceBetween: 8,
      },
    },
  },
  [SWIPER_CAROUSEL_TYPE.MODAL]: {
    className: 'mobile-photo-carousel--modal',
    breakpoints: {
      // when window width is >= 1px
      1: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
    },
  },
  [SWIPER_CAROUSEL_TYPE.DESKTOP_DEFAULT]: {
    className: 'desktop-photo-carousel--default',
    breakpoints: {
      // when window width is >= 1000px
      1000: {
        slidesPerView: 1.5,
        spaceBetween: 15,
      },
      // when window width is >= 1280px
      1280: {
        slidesPerView: 1.5,
        spaceBetween: 5,
      },
      // when window width is >= 1920px
      1920: {
        slidesPerView: 1.5,
        spaceBetween: 7,
      },
      3840: {
        slidesPerView: 1.5,
        spaceBetween: 2,
      },
    },
  },
};

const SwiperCarousel = ({
  photos,
  openedPhoto,
  className = '',
  clickable = false,
  type = SWIPER_CAROUSEL_TYPE.MOBILE_DEFAULT,
  mobile = true,
}: SwiperCarouselProps) => {
  const dispatch = useDispatch();

  const swiperRef = useRef<SwiperCore>();

  const renderPhotos = () =>
    photos.map((photo, index) => (
      <SwiperSlide className="swiper-photo-carousel__slide" key={photo.key}>
        <button
          disabled={!clickable}
          onClick={() => {
            dispatch(changePhotos(photos));
            dispatch(changeOpenedIndex(index));
            dispatch(changeModal(MODALS.PICTURE));
          }}
        >
          <img
            className="swiper-photo-carousel__image"
            src={photo.url}
            alt={photo.name || 'photo'}
          />
        </button>
      </SwiperSlide>
    ));

  const renderArrow = mobile || photos.length <= 1;
  return (
    <Swiper
      modules={mobile ? undefined : [Navigation]}
      loop={photos.length > 1}
      initialSlide={openedPhoto ?? 0}
      className={`swiper-photo-carousel ${carouselProps[type].className} ${className}`}
      loopedSlides={photos.length}
      loopAdditionalSlides={1}
      centeredSlides
      onBeforeInit={(swiper) => {
        swiperRef.current = swiper;
      }}
      breakpoints={carouselProps[type].breakpoints}
    >
      {renderArrow ? null : (
        <Button
          className={'button-round swiper-button-prev'}
          type={BUTTON_TYPE.PRIMARY_CAROUSEL}
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <Icon
            className="button-round__icon button-round__icon_left"
            icon={IconType.Arrow}
          />
        </Button>
      )}
      {renderPhotos()}
      {renderArrow ? null : (
        <Button
          className={'button-round swiper-button-next'}
          type={BUTTON_TYPE.PRIMARY_CAROUSEL}
          onClick={() => swiperRef.current?.slideNext()}
        >
          <Icon
            className="button-round__icon button-round__icon_right"
            icon={IconType.Arrow}
          />
        </Button>
      )}
    </Swiper>
  );
};

export default SwiperCarousel;
