export enum PresignedUrlByKeyMapId {
  PRIVACY_POLICY = 'get-privacy-policy-presigned-url-by-key',
  PAYMENT_DOCS = 'get-payment-documents-presigned-url-by-key',
  FRONTPAGE_PROJECTS = 'get-frontpage-projects-presigned-url-by-key',
  INSTRUCTIONS = 'get-instructions-presigned-url-by-key',
  PAYMENTS_REPORTS = 'payments-statistic-get-files-presigned-url-by-key',
}

import { MinIoUrl } from '@platform-for-public-places/components-library';

export interface GeneratePresignedUrlsRequest {
  urlCount: number;
}

export interface GetPresignedUrlsRequest {
  keys: string[];
}

export interface GetStaticPresignedUrlsRequest extends GetPresignedUrlsRequest {
  messageMapId: PresignedUrlByKeyMapId;
}

export interface UploadFileRequestData {
  tempUrl: string;
  data: Buffer;
  contentType: string;
}

export interface PresignedUrlsResponse {
  isSuccess: boolean;
  isFetching: boolean;
  isError: boolean;
  data: {
    data: MinIoUrl[];
  };
}
