import { ChangeEvent, useState } from 'react';

import { emailRegex } from '@platform-for-public-places/components-library';
import { useLocalStorage } from '@uidotdev/usehooks';
import { TFunction } from 'i18next';

const enum ERRORS {
  EMPTY_EMAIL = 'errors.emptyEmail',
  INVALID_EMAIL_FORMAT = 'errors.invalidEmailFormat',
}

export const SUM = 'SUM';
export const IS_SUPPORTED = 'IS_SUPPORTED';
export const IS_ACCEPTED = 'IS_ACCEPTED';
export const PAGE_DONATION_FORM = 'PAGE_DONATION_FORM';

export const sumRegex = new RegExp(/^(?:[0-9]{1,9}(?:\.[0-9]{0,2})?)$/);

export const useIsSupported = () => {
  const [isSupported, setIsSupported] = useLocalStorage<boolean>(
    IS_SUPPORTED,
    false
  );

  const onSupported = () => {
    setIsSupported((prev) => !prev);
  };

  return [isSupported, setIsSupported, onSupported] as const;
};

export const useIsAccepted = () => {
  const [isAccepted, setIsAccepted] = useLocalStorage<boolean>(
    IS_ACCEPTED,
    false
  );

  const onAccepted = () => {
    setIsAccepted((prev) => !prev);
  };

  return [isAccepted, setIsAccepted, onAccepted] as const;
};

export const useSum = () => {
  const [sum, setSum] = useLocalStorage<string>(SUM, '');

  const onSumChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (sumRegex.test(e.target.value) || e.target.value === '') {
      setSum(e.target.value);
    }
  };

  return [sum, setSum, onSumChange] as const;
};

export const useEmail = (t: TFunction) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<string>('');

  const onEmailChange = (value: string) => {
    setEmail(value);
  };

  const onEmailFocusLeave = () => {
    if (!email.length) {
      setEmailError(t(ERRORS.EMPTY_EMAIL));
    } else if (!emailRegex.test(email)) {
      setEmailError(t(ERRORS.INVALID_EMAIL_FORMAT));
    } else {
      setEmailError('');
    }
  };

  return [email, emailError, onEmailChange, onEmailFocusLeave] as const;
};
