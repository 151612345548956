import { Dispatch, SetStateAction, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import {
  changeCurrentPlatformTeamMember,
  MODAL,
} from 'src/features/modal/slices/modalSlice';
import { State } from 'src/features/store/store';

import { PlatformTeamMember } from 'src/pages/main/FrontPage/components/PlatformTeam/PlatformTeam';

import ModalWindow from '../ModalWindow/ModalWindow';

import './DetailedPlatformTeamMember.scss';

interface DetailedPlatformTeamMemberProps {
  memberMobile?: PlatformTeamMember;
  moreInfo?: boolean;
  setMoreInfo?: Dispatch<SetStateAction<boolean>>;
}

const DetailedPlatformTeamMember = ({
  memberMobile,
  moreInfo,
  setMoreInfo,
}: DetailedPlatformTeamMemberProps) => {
  const dispatch = useDispatch();
  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'frontPage.team.roles' });

  const member = useSelector((s: State) => s[MODAL].currentPlatformTeamMember);

  const flushMember = () => dispatch(changeCurrentPlatformTeamMember(null));

  const writeClipboard = (s?: string) => s && navigator.clipboard.writeText(s);

  const avatar = useMemo(() => {
    const memb = memberMobile ?? member;
    return memb
      ? require(`../../../../pages/main/FrontPage/images/team/${memb.avatar}`)
      : null;
  }, [member, memberMobile]);

  const memberAchievements = useMemo(() => {
    const memb = memberMobile ?? member;
    return memb?.achievements.map((a, i) => {
      const lnk = (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          href={a.url}
          rel="noreferrer"
          target="_blank"
          className="platform-member__achievement-link"
        />
      );
      return (
        <li key={i} className="platform-member__achievement">
          <Trans components={{ lnk }}>{a.value}</Trans>
        </li>
      );
    });
  }, [member, memberMobile]);

  const renderButtonMore = () => {
    return (
      <Button
        className={`members__button ${
          moreInfo ? 'members__button--open' : ''
        } platform-member__button-more`}
        type={BUTTON_TYPE.TEXT_DARK}
        onClick={() => {
          setMoreInfo && setMoreInfo((prev) => !prev);
        }}
        icon
        fill
      >
        {t(moreInfo ? 'button.less' : 'button.more')}
        <Icon icon={IconType.Chevron} />
      </Button>
    );
  };

  const renderContacts = (member: PlatformTeamMember) => {
    return (
      <div className="platform-member__contacts">
        {member.email ? (
          <Button
            icon
            type={BUTTON_TYPE.TEXT_SECONDARY}
            onClick={() => writeClipboard(member.email)}
          >
            <Icon icon={IconType.Email} />
          </Button>
        ) : null}
        {member.phone ? (
          <Button
            icon
            type={BUTTON_TYPE.TEXT_SECONDARY}
            onClick={() => writeClipboard(member.phone)}
          >
            <Icon icon={IconType.Phone} />
          </Button>
        ) : null}
      </div>
    );
  };

  const renderMobile = () => {
    return memberMobile ? (
      <div className="platform-member">
        {avatar ? (
          <img
            alt={memberMobile.name}
            className="platform-member__avatar"
            src={avatar}
          />
        ) : null}
        <h3 className="platform-member__name">{memberMobile.name}</h3>
        <h4 className="platform-member__role">{t(memberMobile.role)}</h4>
        <p className="platform-member__citation">{`«${memberMobile.citation}»`}</p>
        {moreInfo ? (
          <>
            {memberAchievements?.length ? (
              <ul className="platform-member__achievements">
                {memberAchievements}
              </ul>
            ) : null}
            {renderContacts(memberMobile)}
          </>
        ) : null}
        {renderButtonMore()}
      </div>
    ) : null;
  };

  const renderModal = () => {
    return member ? (
      <ModalWindow onClose={flushMember}>
        <div className="platform-member">
          <div className="platform-member__left">
            {avatar ? (
              <img
                alt={member.name}
                className="platform-member__avatar"
                src={avatar}
              />
            ) : null}
            {renderContacts(member)}
          </div>
          <div className="platform-member__right">
            <h3 className="platform-member__name">{member.name}</h3>
            <h4 className="platform-member__role">{t(member.role)}</h4>
            <p className="platform-member__citation">{`«${member.citation}»`}</p>
            {memberAchievements?.length ? (
              <ul className="platform-member__achievements">
                {memberAchievements}
              </ul>
            ) : null}
          </div>
        </div>
      </ModalWindow>
    ) : null;
  };
  return isSmallScreen ? renderMobile() : renderModal();
};

export default DetailedPlatformTeamMember;
