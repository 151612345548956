import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  NOTIFICATION_TYPE,
  TextArea,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import ModalWindow from 'src/features/modal/components/ModalWindow/ModalWindow';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import {
  setNotification,
  setVisible,
} from 'src/features/notification/slices/notificationSlice';
import {
  useGetTagsQuery,
  useLazyCreateMemberRequestQuery,
} from 'src/features/request/api';
import { Tag, TagData } from 'src/features/request/models';

import FormSpecialist, {
  DEFAULT_STATUS,
  RequestCardInfo,
  SpecialistError,
} from 'src/pages/profile/BecomeSpecialist/FormSpecialist';

import DropdownTag from './components/DropdownTag';

import './FormAddToProject.scss';

const MAX_MESSAGE_SIZE = 500;
const INITIAL_ERROR: SpecialistError = { reason: null, exists: false };

const FormAddToProject = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data } = useWhoamiQuery();
  const { t } = useTranslation('app', { keyPrefix: 'formAddToProject' });

  const [selectTag, setSelectTag] = useState<TagData[]>([]);
  const [message, setMessage] = useState<string>();

  const [tagError, setTagError] = useState<SpecialistError>(INITIAL_ERROR);
  const [requestExists, setRequestExists] = useState<boolean>(true);
  const [, setRequestOpened] = useState<boolean>(false);
  const [, setRequestCardInfo] = useState<RequestCardInfo>({
    status: DEFAULT_STATUS,
    date: '',
    comment: '',
  });

  const category = useGetTagsQuery();
  const [applyForProject] = useLazyCreateMemberRequestQuery();

  const isSpecialist =
    data?.data?.profile?.isDeveloper || data?.data?.profile?.isImplementer;
  const renderError = (e: SpecialistError) => (
    <div
      className={`become-specialist__error-container${
        e.exists ? '--visible' : ''
      }`}
    >
      {e.exists ? (
        <span className="become-specialist__error-text">{e.reason}</span>
      ) : null}
    </div>
  );
  const revealNotification = (positive = true) => {
    const profileHref = `${window.location.protocol}//${window.location.host}${paths.profileParticipations}`;
    const message = positive ? (
      <Trans
        i18nKey="formAddToProject.positiveInviteNotification"
        components={{
          lnk: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              className="request-form__notification-link"
              rel="noreferrer"
              href={profileHref}
              target="_blank"
            />
          ),
        }}
      />
    ) : (
      t('negativeInviteNotification')
    );

    const type = positive
      ? NOTIFICATION_TYPE.NOTIFICATION
      : NOTIFICATION_TYPE.ERROR;

    dispatch(setNotification({ message, type }));
    dispatch(setVisible(true));

    if (positive) {
      dispatch(changeModal(null));
    }
  };

  const submitApplyingForProject = () => {
    if (id) {
      const castedTags: Tag[] = selectTag.map((tag) => ({
        name: tag.label,
        tagId: tag.value,
      }));

      applyForProject({
        projectId: id,
        tags: castedTags,
        message,
      })
        .unwrap()
        .then((resp) => {
          if (resp.success && !resp.errors.length) {
            revealNotification();
          } else {
            revealNotification(false);
          }
        })
        .catch(() => revealNotification(false));
    }
  };
  useEffect(() => {
    if (selectTag.length > 0) {
      setTagError(INITIAL_ERROR);
    }
  }, [selectTag]);

  const renderTitle = () => (
    <h1 className={'request-form__title'}>{t('titleSupport')}</h1>
  );
  return (
    <ModalWindow>
      <div
        className={`request-form__container ${
          !isSpecialist ? 'request-form__container-huge' : ''
        }`}
      >
        <div className="request-form__close-wrapper">
          <button
            className="request-form__close"
            onClick={() => dispatch(changeModal(null))}
          >
            <Icon icon={IconType.Cross} />
          </button>
        </div>
        {!isSpecialist ? (
          <div className="request-form__huge">
            {renderTitle()}
            <FormSpecialist
              isSupport
              requestExists={requestExists}
              setRequestExists={setRequestExists}
              setRequestCardInfo={setRequestCardInfo}
              setRequestOpened={setRequestOpened}
              category={category?.data?.data}
              onSuccess={() => revealNotification()}
              onFailure={() => revealNotification(false)}
            />
          </div>
        ) : (
          <div className="request-form__block">
            {renderTitle()}
            <p className="request-form__title-role">{t('titleRole')}</p>
            <div className="request-form__selector-roles">
              <DropdownTag
                category={category?.data?.data}
                selectTag={selectTag}
                setSelectTag={setSelectTag}
                error={tagError}
                setError={setTagError}
              />
              {renderError(tagError)}
            </div>
            <p className="request-form__title-comment">{t('titleComment')}</p>
            <TextArea
              className="request-form__comment"
              value={message}
              maxLength={MAX_MESSAGE_SIZE}
              onChange={(e) => setMessage(e.target.value)}
              placeholder={t('placeHolderComment')}
            />
            <Button
              className="request-form__button"
              type={BUTTON_TYPE.PRIMARY}
              disabled={selectTag.length <= 0 || !id}
              onClick={submitApplyingForProject}
            >
              {t('button')}
            </Button>
          </div>
        )}
      </div>
    </ModalWindow>
  );
};

export default FormAddToProject;
