import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import SignInModal from 'src/features/modal/components/SignInModal/SignInModal';
import { BackdoorPayload, BypassPayload } from 'src/features/modal/models';
import { useResetSubscriptionsMutation } from 'src/features/project/api';

import image from './img/letter.svg';

import './Unsubscibe.scss';

const Unsubscribe = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('app', { keyPrefix: 'unsubscribe' });
  const { pathname, search, state } = useLocation();
  const isSmallScreen = useCheckSmallScreen();

  const { isSuccess, isLoading } = useWhoamiQuery();

  const [modal, setModal] = useState<boolean>(false);

  const [resetSubscriptions] = useResetSubscriptionsMutation();

  const reload = () => document.location.reload();

  const unsubscribe = () => {
    resetSubscriptions().then(() => navigate(paths.map));
  };

  useEffect(() => {
    if (!isLoading && !isSuccess) {
      if (isSmallScreen) {
        const _state = state as BypassPayload | undefined;
        const payload: BackdoorPayload = { backdoor: `${pathname}${search}` };
        if (!_state?.bypass) {
          navigate(paths.signIn, { state: payload, replace: true });
        }
      } else {
        setModal(true);
      }
    }
  }, [isLoading, isSmallScreen, isSuccess, navigate, pathname, search, state]);

  return (
    <div className="unsubscribe">
      <div className="unsubscribe__info">
        <h3 className="unsubscribe__title">{t('title')}</h3>
        <p className="unsubscribe__description">
          <Trans i18nKey={t('description')} components={{ b: <b /> }} />
        </p>
        <p className="unsubscribe__clarifying">{t('clarifying')}</p>
        <Button
          className="unsubscribe__button"
          type={BUTTON_TYPE.PRIMARY}
          onClick={unsubscribe}
        >
          {t('send')}
        </Button>
      </div>
      <img src={image} className="unsubscribe__image" alt={'letter'} />
      {modal ? <SignInModal strict onSuccess={reload} /> : null}
    </div>
  );
};

export default Unsubscribe;
