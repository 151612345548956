import { ActorBaseResponse } from '@platform-for-public-places/components-library';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { Geodata } from '../models';

export const MAP_API = 'MAP_API';

export const mapApi = createApi({
  reducerPath: MAP_API,
  baseQuery: retry(
    fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URLS }),
    { maxRetries: 0 }
  ),
  endpoints: (builder) => ({
    getMapData: builder.query<ActorBaseResponse<Geodata>, void>({
      query: () => ({
        method: 'POST',
        body: {
          messageMapId: 'geodata/get-geodata-default',
        },
      }),
    }),
  }),
});

export const { useGetMapDataQuery } = mapApi;
export const mapMiddleware = mapApi.middleware;

export default mapApi.reducer;
