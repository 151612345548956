import { useEffect, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDispatch, useSelector } from 'react-redux';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import Spinner from 'src/shared/components/Spinner/Spinner';

import { State } from 'src/features/store/store';

import { useDeleteCommentMutation, useLazyGetCommentsQuery } from '../../api';

import {
  COMMENT,
  reset,
  setUpdatingComment,
  setUpdatingStage,
} from '../../slices/commentSlice';

import { COMMENT_UPDATING_STAGE, CommentAndRequest } from '../../models';

import Comment from '../Comment/Comment';
import { COMMENT_FORM_ANCHOR } from '../CommentCreation/CommentCreation';

import './CommentsList.scss';

interface CommentsListProps {
  projectId: string;
  className?: string;
  archived?: boolean;
}

const PAGE_SIZE = 20;

const CommentsList = ({
  projectId,
  className = '',
  archived = false,
}: CommentsListProps) => {
  const dispatch = useDispatch();
  const mobile = useCheckSmallScreen();

  const [hasMore, setHasMore] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [comments, setComments] = useState<CommentAndRequest[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const newComment = useSelector((s: State) => s[COMMENT].newComment);
  const updatingStage = useSelector((s: State) => s[COMMENT].updatingStage);
  const updatingComment = useSelector((s: State) => s[COMMENT].updatingComment);

  const [getComments, { isError }] = useLazyGetCommentsQuery();
  const [deleteComment] = useDeleteCommentMutation();

  useEffect(() => {
    if (newComment) {
      setComments((prev) => [newComment, ...prev]);
      dispatch(reset());
    }
  }, [dispatch, newComment]);

  useEffect(() => {
    return () => {
      dispatch(setUpdatingComment(null));
      dispatch(setUpdatingStage(COMMENT_UPDATING_STAGE.NONE));
    };
  }, [dispatch]);

  useEffect(() => {
    if (updatingStage === COMMENT_UPDATING_STAGE.FINISHED && updatingComment) {
      const _comments = comments.map((oldComment) => {
        return oldComment.comment.project_commentID ===
          updatingComment.comment.project_commentID
          ? updatingComment
          : oldComment;
      });
      setComments(_comments);
      dispatch(setUpdatingStage(COMMENT_UPDATING_STAGE.NONE));
      dispatch(setUpdatingComment(null));
    }
  }, [comments, dispatch, updatingComment, updatingStage]);

  const onLoadMore = () => {
    setLoading(true);
    getComments({
      projectId,
      pageSize: PAGE_SIZE,
      page: currentPage + 1,
    })
      .then(({ data: response }) => {
        setComments((prev) => [...prev, ...(response?.data.comments || [])]);
        setHasMore(response?.data.comments.length === PAGE_SIZE);
        setCurrentPage((prev) => prev + 1);
      })
      .catch(() => {
        setHasMore(false);
      })
      .finally(() => setLoading(false));
  };

  const onEdit = (payload: CommentAndRequest) => {
    dispatch(setUpdatingComment(payload));
    dispatch(setUpdatingStage(COMMENT_UPDATING_STAGE.PENDING));

    if (!mobile) {
      document
        .getElementById(COMMENT_FORM_ANCHOR)
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const onDelete = (commentId: string) =>
    deleteComment({ commentId }).then(() =>
      setComments((comments) =>
        comments.filter(
          ({ comment }) => comment.project_commentID !== commentId
        )
      )
    );

  const [tailRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasMore,
    onLoadMore: onLoadMore,
    disabled: isError,
  });

  return (
    <div className={`comments-list ${className}`}>
      {comments.map((c, i) => (
        <Comment
          key={i}
          comment={c.comment}
          request={c.request}
          archived={archived}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))}
      <div className="comments-list__tail" ref={tailRef}>
        <Spinner visible={loading} />
      </div>
    </div>
  );
};

export default CommentsList;
