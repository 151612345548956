import { FormEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  TextInput,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { useEmailInput } from 'src/features/autopayments/components/hooks';
import { MODALS } from 'src/features/modal/models';
import { changeEmail, changeModal } from 'src/features/modal/slices/modalSlice';
import { useLazyRequestAccessToRecurrentPaymentsQuery } from 'src/features/payment/api';

import './AutopaymentsManagementForm.scss';

const AutopaymentsManagementForm = () => {
  const { t } = useTranslation('app', {
    keyPrefix: 'autopaymentsManagementModal.form',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSmallScreen = useCheckSmallScreen();

  const { email, emailError, onEmailChange } = useEmailInput();

  const submitDisabled = !email;

  const [requestAccessToRecurrentPayments] =
    useLazyRequestAccessToRecurrentPaymentsQuery();

  const goToNextModal = () => {
    dispatch(changeEmail(email));
    dispatch(changeModal(MODALS.AUTOPAYMENTS_MANAGEMENT_SENT));
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (email) {
      requestAccessToRecurrentPayments({ email: email.trim() }).then(() => {
        isSmallScreen
          ? navigate(paths.autopaymentsManagementSent, {
              replace: true,
              state: { email: email.trim() },
            })
          : goToNextModal();
      });
    }
  };

  const onClickSignInButton = () => {
    isSmallScreen
      ? navigate(paths.signIn)
      : dispatch(changeModal(MODALS.SIGN_IN));
  };

  return (
    <div className="autopayments-management-form-container">
      <h3 className="auth-title autopayments-management-form-container__title">
        {t('title')}
      </h3>
      <p className="auth-description autopayments-management-form-container__description">
        {t('description')}
      </p>
      <form className="autopayments-management-form" onSubmit={onSubmit}>
        <TextInput
          value={email}
          className={`auth-input autopayments-management-form__email${
            emailError ? 'error' : ''
          }`}
          placeholder={t('placeholders.email')}
          onChange={(e) => onEmailChange(e.target.value)}
          type="email"
        />
        <p className="autopayments-management-form__inctruction">
          <Trans
            i18nKey={t('inctruction')}
            components={{
              btn: (
                <button
                  className="text-link autopayments-management-form__sign-in-button"
                  onClick={onClickSignInButton}
                />
              ),
            }}
          />
        </p>
        <Button
          submit
          disabled={submitDisabled}
          className="auth-button autopayments-management-form__button"
          type={BUTTON_TYPE.ACCENTED}
        >
          {t('buttons.continue')}
        </Button>
      </form>
    </div>
  );
};

export default AutopaymentsManagementForm;
