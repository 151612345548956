import Control from 'react-leaflet-custom-control';
import { useSelector } from 'react-redux';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import LayerControl from 'src/features/control/components/LayerControl/LayerControl';
import ZoomControl from 'src/features/control/components/ZoomControl/ZoomControl';
import { VIEW_CARD } from 'src/features/control/enums';
import { CONTROL } from 'src/features/control/slices/controlSlice';
import OverviewCard from 'src/features/project/components/OverviewCard/OverviewCard';
import { PROJ_LAYER } from 'src/features/project/slices/projectsLayerSlice';
import { State } from 'src/features/store/store';

const TopRightControl = () => {
  const isSmallScreen = useCheckSmallScreen();
  const currentCard = useSelector((s: State) => s[CONTROL].currentCard);
  const projectInfo = useSelector(
    (s: State) => s[PROJ_LAYER].currentProjectInfo
  );

  return (
    <Control prepend position="topright">
      {isSmallScreen ? (
        <>
          <LayerControl />
          <ZoomControl />
        </>
      ) : (
        <>
          {currentCard === VIEW_CARD.OVERVIEW && (
            <OverviewCard project={projectInfo} />
          )}
          <LayerControl />
        </>
      )}
    </Control>
  );
};

export default TopRightControl;
