import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { paths } from 'src/shared/routes';

import {
  useGetFilesQuery,
  useGetProjectCoverQuery,
} from 'src/features/files/api';
import { FileCategories } from 'src/features/files/enums';
import { useGetDonationDetailsQuery } from 'src/features/payment/api';
import {
  useGetProjectByIdQuery,
  useGetProjectTimelineQuery,
} from 'src/features/project/api';

import ProjectPreviewContainer from '../ProjectPreviewContainer';

const PHOTO_LIMIT = 1;
const PHOTO_OFFSET = 0;
interface ProjectPreviewProps {
  className?: string;
  statusClassName?: string;
  projectId: string;
  large?: boolean;
}

const ProjectPreview = ({
  className = '',
  statusClassName = '',
  projectId,
  large = false,
}: ProjectPreviewProps) => {
  const { data: project, refetch } = useGetProjectByIdQuery({ projectId });
  const { data: donationDetails } = useGetDonationDetailsQuery({ projectId });

  const {
    data: photosCover,
    isFetching: coverFetching,
    isSuccess: coverSuccess,
  } = useGetProjectCoverQuery({ projectId });

  const { data: photos } = useGetFilesQuery(
    {
      entityId: projectId,
      category: FileCategories.PHOTO_BEFORE,
      limit: PHOTO_LIMIT,
      offset: PHOTO_OFFSET,
    },
    {
      skip: coverFetching || (coverSuccess && !!photosCover?.data.cover),
    }
  );

  const { data: projectTimeline } = useGetProjectTimelineQuery(
    { projectId: projectId as string },
    { skip: !projectId }
  );

  const url = useMemo(() => {
    if (photosCover?.data.cover) {
      return photosCover.data.cover.url;
    } else if (photos?.data.length) {
      return photos.data[0].url;
    } else {
      return undefined;
    }
  }, [photos?.data, photosCover?.data.cover]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Link
      to={paths.projectById(projectId)}
      className={className}
      state={{ goOnMap: false }}
      target="_blank"
      rel="noreferrer"
    >
      <ProjectPreviewContainer
        image={url}
        name={project?.data.name ?? ''}
        status={project?.data.status}
        layer={project?.data.layer}
        large={large}
        className={className}
        statusClassName={statusClassName}
        projectId={projectId}
        comments={project?.data.comments}
        wasLiked={project?.data.liked}
        likesCount={project?.data.likes}
        donationTotal={donationDetails?.data.paymentDetails.donationTotal}
        budget={donationDetails?.data.paymentDetails.budget}
        timeline={projectTimeline?.data.timeline}
        // TODO: add request for getting info about joined when it will be ready in task #427
        // joined={project?.joined}
        // countOfJoined={project?.countOfJoined}
      />
    </Link>
  );
};

export default ProjectPreview;
