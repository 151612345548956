import clsx from 'clsx';

import './Indicator.scss';

type IndicatorProps = {
  show: boolean;
  className?: string;
};

const Indicator = ({ show, className }: IndicatorProps) => {
  return (
    <div
      className={clsx('indicator', className, {
        'indicator--show': show,
      })}
    />
  );
};

export default Indicator;
