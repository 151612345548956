import { MouseEventHandler } from 'react';
import {
  CaptionProps,
  DayPicker,
  SelectSingleEventHandler,
  useDayPicker,
  useNavigation as useDPNavigation,
} from 'react-day-picker';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';
import { ru } from 'date-fns/locale';

import './DatePicker.scss';

interface DatePickerProps {
  selected?: Date;
  className?: string;
  onSelect?: SelectSingleEventHandler;
  onOutsideClick?: () => void;
}

const DatePicker = ({
  selected,
  className,
  onSelect,
  onOutsideClick,
}: DatePickerProps): JSX.Element => {
  const { onMonthChange } = useDayPicker();
  const { previousMonth, currentMonth, nextMonth, goToMonth } =
    useDPNavigation();

  const onPreviousClick: MouseEventHandler = () => {
    if (previousMonth) {
      goToMonth(previousMonth);
      onMonthChange?.(previousMonth);
    }
  };

  const onNextClick: MouseEventHandler = () => {
    if (nextMonth) {
      goToMonth(nextMonth);
      onMonthChange?.(nextMonth);
    }
  };

  const DatePickerCaption = ({ displayMonth }: CaptionProps): JSX.Element => {
    const month = displayMonth.toLocaleDateString(ru.code, { month: 'long' });

    return (
      <div className="date-picker-caption">
        <Button
          className="date-picker-caption__button--prev"
          type={BUTTON_TYPE.TEXT_SECONDARY}
          onClick={onPreviousClick}
        >
          <Icon icon={IconType.Chevron} />
        </Button>
        <span className="date-picker-caption__month">
          {`${month[0].toUpperCase()}${month.slice(
            1
          )}, ${displayMonth.getFullYear()}`}
        </span>
        <Button
          className="date-picker-caption__button--next"
          type={BUTTON_TYPE.TEXT_SECONDARY}
          onClick={onNextClick}
        >
          <Icon icon={IconType.Chevron} />
        </Button>
      </div>
    );
  };

  return (
    <>
      <div
        aria-hidden
        className="date-picker__overlay"
        role="button"
        tabIndex={0}
        onClick={onOutsideClick}
      />
      <div className={`date-picker ${className}`}>
        <DayPicker
          locale={ru}
          mode="single"
          showOutsideDays
          selected={selected}
          month={currentMonth}
          onSelect={onSelect}
          components={{ Caption: DatePickerCaption }}
        />
      </div>
    </>
  );
};

export default DatePicker;
