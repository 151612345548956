import { ReactElement, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';

import 'src/features/map/components/SearchPane/components/SearchPanelMobileBottomSheet/SearchPanelMobileBottomSheet.scss';
import {
  CONTROL,
  setSearchPanelState,
} from 'src/features/control/slices/controlSlice';
import { DRAW } from 'src/features/draw/slices/drawSlice';
import { SearchPanelState } from 'src/features/map/components/SearchPane/components/SearchFilterInput/SearchFilterInput';
import { State } from 'src/features/store/store';

type SearchPanelMobileBottomSheetProps = {
  children?: ReactElement | ReactElement[];
};

const SearchPanelMobileBottomSheet = ({
  children,
}: SearchPanelMobileBottomSheetProps) => {
  const dispatch = useDispatch();

  const visible = useSelector((s: State) => s[CONTROL].searchPanelState);
  const mobileMenuOpen = useSelector((s: State) => s[CONTROL].mobileMenuOpen);
  const isDrawEnable = useSelector((s: State) => s[DRAW].isDrawEnable);
  const drawnShape = useSelector((s: State) => s[DRAW].drawnShape);

  const focusRef = useRef<HTMLButtonElement>(null);
  const sheetRef = useRef<BottomSheetRef>(null);

  useEffect(() => {
    if (
      visible === SearchPanelState.OPEN_WITH_FILTER ||
      visible === SearchPanelState.OPEN
    ) {
      sheetRef?.current?.snapTo(({ snapPoints }) => Math.max(...snapPoints));
    }
  }, [visible]);
  return (
    <BottomSheet
      open={!(isDrawEnable || drawnShape !== null || mobileMenuOpen)}
      className={'search-panel-bottom-sheet'}
      skipInitialTransition
      ref={sheetRef}
      scrollLocking={true}
      initialFocusRef={focusRef}
      defaultSnap={({ maxHeight }) => maxHeight / 2}
      snapPoints={({ maxHeight }) => [
        maxHeight - maxHeight / 10,
        maxHeight / 9,
      ]}
      blocking={false}
      onSpringStart={(event) => {
        if (event.type === 'SNAP' && event.source === 'dragging') {
          dispatch(setSearchPanelState());
        }
      }}
    >
      {children}
    </BottomSheet>
  );
};

export default SearchPanelMobileBottomSheet;
