import { ActorBaseResponse } from '@platform-for-public-places/components-library';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { InstructionData } from '../models';

export const INSTRUCTION_API = 'INSTRUCTION_API';

export const instructionApi = createApi({
  reducerPath: INSTRUCTION_API,
  baseQuery: retry(
    fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URLS }),
    { maxRetries: 0 }
  ),
  endpoints: (builder) => ({
    getInstructions: builder.query<
      ActorBaseResponse<InstructionData[]>,
      string
    >({
      query: (url) => url,
    }),
  }),
});

export const { useGetInstructionsQuery } = instructionApi;
export const instructionMiddleware = instructionApi.middleware;

export default instructionApi.reducer;
