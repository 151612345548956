import { useTranslation } from 'react-i18next';

import {
  Icon,
  IconType,
  stringUTCDateToUTCDate,
} from '@platform-for-public-places/components-library';

import { truncateZeroDecimal } from 'src/shared/functions/numbers';
import { paths } from 'src/shared/routes';

import { usePaymentStatusTranslation } from 'src/features/payment/components/AllUserPaymentsInfo/components/index';
import { UserPaymentData } from 'src/features/payment/models';

import './UserPaymentItemList.scss';

interface UserPaymentItemListProps {
  payment: UserPaymentData;
}

const UserPaymentItemList = ({ payment }: UserPaymentItemListProps) => {
  const { t } = useTranslation('app', {
    keyPrefix: 'profile.payments.all.list',
  });

  const paymentType = usePaymentStatusTranslation(payment.paymentType);

  return (
    <div key={payment.paymentId} className="user-payments-item-list">
      <Icon
        icon={IconType.SuccesfulPayment}
        className="user-payments-item-list__icon"
      />

      <div className="user-payments-item-list__payment-project">
        <a
          className="user-payments-item-list__link"
          href={
            payment.projectId
              ? paths.projectById(payment.projectId)
              : paths.frontPage
          }
          rel="noopener noreferrer"
          target="_blank"
        >
          {payment.projectName}
        </a>
        <p className="user-payments-item-list__payment-status">
          {t('status.success')}
          {paymentType}
        </p>
      </div>
      <div className="user-payments-item-list__payment-info">
        <h6 className="user-payments-item-list__amount">
          {t('amount', {
            amount: payment.amount,
            formatParams: {
              amount: {
                maximumFractionDigits: truncateZeroDecimal(payment.amount),
              },
            },
          })}
        </h6>
        <p className="user-payments-item-list__payment-date">
          {t('date.createdAt', {
            createdAt: stringUTCDateToUTCDate(payment.date),
            formatParams: {
              createdAt: { year: 'numeric', month: 'numeric', day: 'numeric' },
            },
          })}
        </p>
      </div>
    </div>
  );
};

export default UserPaymentItemList;
