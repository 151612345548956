import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { BottomSheet } from 'react-spring-bottom-sheet';

import {
  GetProjectResponse,
  MinIoUrl,
  PROJECT_STATUS,
  UserCard,
  UserProfile,
} from '@platform-for-public-places/components-library';

import CatalogAvatar from 'src/shared/components/CatalogUsers/components/CatalogAvatar/CatalogAvatar';
import { paths } from 'src/shared/routes';

import {
  useGetProjectsWithImages,
  useTranslationStatus,
  useUserCardTranslation,
} from 'src/features/hooks';
import { useLazyGetProjectsBySpecialistQuery } from 'src/features/project/api';

const PAGE_SIZE = 20;

interface BottomUserCardProps {
  selectedUser: UserProfile | null;
  userInfoSheetVisible: boolean;
  setUserInfoSheetVisible: Dispatch<SetStateAction<boolean>>;
}

const BottomUserCard = ({
  selectedUser,
  userInfoSheetVisible,
  setUserInfoSheetVisible,
}: BottomUserCardProps) => {
  const [projectsPage, setProjectsPage] = useState<number>(1);
  const [projectsError] = useState<boolean>(false);
  const [projectsLoading, setProjectsLoading] = useState<boolean>(false);
  const [hasProjectsNextPage, setHasProjectsNextPage] = useState<boolean>(true);
  const [projects, setProjects] = useState<GetProjectResponse[]>([]);

  const userCardTitles = useUserCardTranslation();
  const translateStatus = useTranslationStatus();

  const [getProjectsBySpecialist] = useLazyGetProjectsBySpecialistQuery();
  const [getProjectsWithImages] = useGetProjectsWithImages();

  const [getProjects] = useInfiniteScroll({
    loading: projectsLoading,
    hasNextPage: hasProjectsNextPage,
    onLoadMore: () => {
      if (selectedUser) {
        setProjectsLoading(true);
        getProjectsBySpecialist({
          userId: selectedUser.userId,
          statuses: [
            PROJECT_STATUS.ACCEPTED,
            PROJECT_STATUS.APPROVED,
            PROJECT_STATUS.IMPLEMENTED,
            PROJECT_STATUS.FINISHED,
            PROJECT_STATUS.TRANSFERRED,
          ],
          page: projectsPage,
          pageSize: PAGE_SIZE,
        }).then((response) => {
          if (response.data?.data) {
            getProjectsWithImages(response.data?.data ?? []).then((res) =>
              setProjects((prev) => [...prev, ...res])
            );
            setProjectsPage(projectsPage + 1);
            setProjectsLoading(false);
            setHasProjectsNextPage(response.data?.data.length === PAGE_SIZE);
          }
        });
      }
    },
    disabled: projectsError,
  });

  const renderUser = useCallback(() => {
    return selectedUser ? (
      <>
        <UserCard
          imageComponent={
            <CatalogAvatar
              rerender
              avatar={(selectedUser.avatar as MinIoUrl[])?.[0]?.key ?? ''}
            />
          }
          showProjects
          user={selectedUser}
          titles={userCardTitles}
          translateStatus={translateStatus}
          getProjectPath={paths.projectById}
          projectsRef={getProjects}
          projects={projects}
          hasNextPage={hasProjectsNextPage}
        />
      </>
    ) : null;
  }, [
    getProjects,
    hasProjectsNextPage,
    projects,
    selectedUser,
    translateStatus,
    userCardTitles,
  ]);

  return (
    <BottomSheet
      open={userInfoSheetVisible}
      blocking
      onDismiss={() => setUserInfoSheetVisible(false)}
      scrollLocking={false}
    >
      {renderUser()}
    </BottomSheet>
  );
};

export default BottomUserCard;
