import { ChangeEvent, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggle';

import {
  AvailableStatusesResponse,
  Icon,
  IconType,
  PROJECT_STATUS,
  SPECIFIC_LAYER,
  Status,
  timestampToString,
  useCheckSmallScreen,
  USER_ROLE,
} from '@platform-for-public-places/components-library';

import 'src/shared/components/Toggle/Toggle.scss';

import { useCheckUser } from 'src/features/hooks';
import { filterEnabledStatuses } from 'src/features/project/hooks';
import {
  useDisableProjectFlowStepMutation,
  useEnableProjectFlowStepMutation,
} from 'src/features/status/api';

import './ProjectStatus.scss';

interface ProjectStatusProps {
  className?: string;
  statusType?: TypeStatus;
  statuses?: Status[];
  archived?: boolean;
  projectId?: string;
  availableStatuses?: AvailableStatusesResponse;
  projectType?: SPECIFIC_LAYER | string;
}

export enum TypeStatus {
  OWERVIEW_CARD,
  EDIT_STATUS_PAGE,
}

const NUMBERS = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'];

const ProjectStatus = ({
  className,
  statusType,
  statuses,
  archived = false,
  projectId = '',
  availableStatuses,
  projectType = SPECIFIC_LAYER.DEFAULT,
}: ProjectStatusProps): JSX.Element => {
  const { t } = useTranslation();
  const isSmallScreen: boolean = useCheckSmallScreen();
  const isAdmin = useCheckUser([{ role: USER_ROLE.ADMIN }]);

  const [visibleHint, setVisibleHint] = useState<number | null>(null);

  const transferredStep = availableStatuses?.flowSteps.filter(
    (step) => step.name === PROJECT_STATUS.TRANSFERRED
  )[0];
  const [transferredDisabled, setTransferredDisabled] =
    useState<boolean>(false);

  useEffect(() => {
    setTransferredDisabled(!!transferredStep?.disabled);
  }, [transferredStep]);

  const [disableFlowStep] = useDisableProjectFlowStepMutation();
  const [enableFlowStep] = useEnableProjectFlowStepMutation();

  const onToggleClick = (
    event: ChangeEvent<HTMLInputElement>,
    flowStepId: string
  ) => {
    const checked = event.target.checked;
    setTransferredDisabled(!checked);
    if (checked) {
      enableFlowStep({ projectId: projectId, flowStepId: flowStepId });
    } else {
      disableFlowStep({ projectId: projectId, flowStepId: flowStepId });
    }
  };

  const projectStatuses = useMemo(() => {
    if (isAdmin) {
      return availableStatuses?.flowSteps.map((status) => status.name) || [];
    } else {
      return filterEnabledStatuses(availableStatuses?.flowSteps);
    }
  }, [availableStatuses, isAdmin]);

  const projectStatusesOverview = useMemo(
    () => filterEnabledStatuses(availableStatuses?.flowSteps, true),
    [availableStatuses?.flowSteps]
  );

  const dynamicClass = (s: TemplateStringsArray) => {
    const modifier = archived ? `${s[0]}--archived` : '';
    return `${s[0]} ${modifier} ${className}`;
  };

  const status = statuses?.length ? statuses[statuses.length - 1] : null;

  const drawBarRowEdit = (values: string[], className: string) =>
    values.map((title, i) => {
      if (isAdmin && transferredDisabled && values.length - 2 === i) {
        return <div key={i} className={`${className}--disabled`} />;
      }
      if (values.length - 1 !== i) {
        const passed = status && i < status.order;
        const computedClass = `${className}${passed ? '--passed' : ''}`;
        return <div key={i} className={computedClass} />;
      }
    });

  const drawBarRow = (values: string[], className: string) =>
    values.map((title, i) => {
      if (isAdmin && transferredDisabled && values.length - 2 === i) {
        return <div key={i} className={`${className}--disabled`} />;
      }
      if (values.length - 1 !== i) {
        const passed = status && i < status.order - 1;
        const computedClass = `${className}${passed ? '--passed' : ''}`;
        return <div key={i} className={computedClass} />;
      }
    });

  const drawPoints = (values: string[], className: string) =>
    values.map((title, i) => {
      const passed = status && i < status.order;
      return (
        <div
          key={i}
          onMouseOver={() => setVisibleHint(i)}
          onMouseOut={() => setVisibleHint(null)}
          onFocus={() => setVisibleHint(i)}
          onBlur={() => setVisibleHint(null)}
        >
          <Icon
            icon={IconType.StatusPoint}
            className={`${className}${passed ? '--passed' : ''}`}
          />
        </div>
      );
    });

  const drawPointsEdit = (values: string[]) =>
    values.map((title, i) => {
      const passed = i === 0 || (status && i <= status.order);

      let icon;
      if (isAdmin && transferredDisabled && i === values.length - 1) {
        icon = (
          <Icon
            key={i}
            icon={IconType.CrossRound}
            className="project-status__point-edit--disabled"
          />
        );
      } else {
        icon = (
          <Icon
            key={i}
            icon={passed ? IconType.Checkmark : IconType.StatusPoint}
            className={`project-status__point-edit${passed ? '' : '--passed'}`}
          />
        );
      }

      return (
        <div
          key={i}
          className={className}
          onMouseOver={() => setVisibleHint(i)}
          onMouseOut={() => setVisibleHint(null)}
          onFocus={() => setVisibleHint(i)}
          onBlur={() => setVisibleHint(null)}
        >
          {icon}
        </div>
      );
    });

  const renderTitles = (values: string[], className: string) =>
    values.map((title, i) => {
      const passed = status && i < status.order;

      if (
        isAdmin &&
        transferredDisabled &&
        statusType === TypeStatus.EDIT_STATUS_PAGE &&
        i === values.length - 1
      ) {
        return (
          <span
            key={i}
            className={`${className}--disabled`}
            onMouseOver={() => setVisibleHint(i)}
            onMouseOut={() => setVisibleHint(null)}
            onFocus={() => setVisibleHint(i)}
            onBlur={() => setVisibleHint(null)}
          >
            {t(`cards.overview.projectStatus.${projectType}.${title}`)}
          </span>
        );
      }
      return (
        <span
          key={i}
          className={`${className}${passed ? ` ${className}--passed` : ''}`}
          onMouseOver={() => setVisibleHint(i)}
          onMouseOut={() => setVisibleHint(null)}
          onFocus={() => setVisibleHint(i)}
          onBlur={() => setVisibleHint(null)}
        >
          {t(`cards.overview.projectStatus.${projectType}.${title}`)}
        </span>
      );
    });

  const renderDates = (
    values: Status[] | undefined,
    titles: string[],
    className: string,
    startPosition: number
  ) => {
    if (status && values && values.length > 0) {
      const lastDate = status.updated;
      const statusesDates = values.map((value, i) =>
        i < startPosition ? null : (
          <span
            key={i - startPosition}
            className={className}
            onMouseOver={() => setVisibleHint(i - startPosition)}
            onMouseOut={() => setVisibleHint(null)}
            onFocus={() => setVisibleHint(i - startPosition)}
            onBlur={() => setVisibleHint(null)}
          >
            {timestampToString(value.updated, true)}
          </span>
        )
      );
      for (let i = values.length; i < titles.length + startPosition; i++) {
        statusesDates.push(
          <span
            key={i}
            className={className}
            onMouseOver={() => setVisibleHint(i - startPosition)}
            onMouseOut={() => setVisibleHint(null)}
            onFocus={() => setVisibleHint(i - startPosition)}
            onBlur={() => setVisibleHint(null)}
          >
            {i <= status.order && timestampToString(lastDate, true)}
          </span>
        );
      }
      if (
        isAdmin &&
        statusType === TypeStatus.EDIT_STATUS_PAGE &&
        !isSmallScreen
      ) {
        transferredStep &&
          statusesDates.splice(
            statusesDates.length - 1,
            1,
            <Toggle
              className="project-status-edit__toggle"
              icons={false}
              checked={!transferredDisabled}
              onChange={(event) =>
                onToggleClick(event, transferredStep?.flowStepID ?? '')
              }
            />
          );
      }
      return statusesDates;
    }
    return [];
  };

  const renderHint = (
    numberOfActiveStatuses: number,
    projectStatuses: PROJECT_STATUS[]
  ) => {
    if (visibleHint != null) {
      const hintClassName = `project-status-hint project-status-hint--${
        NUMBERS[visibleHint]
      } ${
        visibleHint >= numberOfActiveStatuses
          ? 'project-status-hint--non-active'
          : ''
      }`;
      return (
        <div
          className={`project-status-hint-container project-status-hint-container--${projectStatuses.length}`}
        >
          {projectStatuses.map((hint, i) => (
            <div
              key={i}
              className={`${hintClassName} ${
                i === visibleHint ? 'project-status-hint--visible' : ''
              }`}
            >
              {t(`hints.projectStatus.${projectType}.${hint}`)}
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const renderEditStatusPage = () => (
    <div className={`project-status ${className}`}>
      <div className="project-status__text-row">
        {renderTitles(
          projectStatuses,
          'project-status__text project-status-edit__text'
        )}
      </div>
      <div className="project-status__bar-row-edit">
        {drawBarRowEdit(projectStatuses, 'project-status__bar-part-edit')}
      </div>
      <div className="project-status__point-row-edit">
        {drawPointsEdit(projectStatuses)}
      </div>
      <div className="project-status__text-row-edit-date">
        {renderDates(statuses, projectStatuses, 'project-status-edit__date', 0)}
      </div>
      {isSmallScreen
        ? null
        : renderHint(statuses ? statuses.length : 0, projectStatuses)}
    </div>
  );

  const renderOverviewCard = () => (
    <div className={`${dynamicClass`project-status`} ${className}`}>
      <div
        className={`project-status__text-row project-status__text-row--${projectStatusesOverview.length}`}
      >
        {renderTitles(projectStatusesOverview, 'project-status__text')}
      </div>
      <div className="project-status__bar-row">
        {drawBarRow(projectStatusesOverview, 'project-status__bar-part')}
      </div>
      <div className="project-status__point-row">
        {drawPoints(projectStatusesOverview, 'project-status__point')}
      </div>
      {statuses?.length !== 1 ? (
        <div className="project-status__text-row-date">
          {renderDates(
            statuses,
            projectStatusesOverview,
            'project-status__date',
            1
          )}
        </div>
      ) : (
        <></>
      )}
      {isSmallScreen
        ? null
        : renderHint(
            statuses ? statuses.length + 1 : 0,
            projectStatusesOverview
          )}
    </div>
  );
  return statusType ? renderEditStatusPage() : renderOverviewCard();
};

export default memo(ProjectStatus);
