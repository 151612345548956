import { useState } from 'react';

import { ActorBaseResponse } from '@platform-for-public-places/components-library';

import { LazyQueryTrigger, QueryStateResult } from './models';

export const fetchMock = (url: string, externalUrl = false) =>
  fetch(externalUrl ? url : `/mockapi/${url}`).then((response) =>
    response.json()
  );

/**
 * Lazy query mock creator hook
 *
 * Usage example:
 * ```
 * export const useLazyGetProjectByIdQuery = (): LazyQuery<GetProjectResponse> => {
 *   const createPath = (req: unknown) => {
 *     const src = req as GetProjectRequest;
 *     return `get-project/${src.projectId}.json`;
 *   };
 *   return useCreateLazyQueryMock<GetProjectResponse>(createPath);
 * }
 * ```
 * @param pathBuilder function that accept trigger's argument and return path to mock's source (e.g. json file)
 * @returns array w/ lazy query trigger and current state
 */
export const useCreateLazyQueryMock = <T>(
  pathBuilder: (arg: unknown) => string
): [LazyQueryTrigger<T>, QueryStateResult<T>] => {
  const initialState = {
    isUninitialized: true,
    isLoading: true,
    isFetching: false,
    isSuccess: false,
    isError: false,
  };

  const [response, setResponse] = useState<QueryStateResult<T>>(initialState);

  const trigger: LazyQueryTrigger<T> = (arg: unknown) => {
    const path = pathBuilder(arg);
    const fetcher = fetchMock(path)
      .then((data: ActorBaseResponse<T>) => {
        const resp: QueryStateResult<T> = {
          ...response,
          isUninitialized: false,
          isLoading: false,
          isSuccess: true,
          currentData: data,
          data,
        };
        setResponse(resp);
        return resp;
      })
      .catch(() => {
        const resp: QueryStateResult<T> = {
          ...response,
          isUninitialized: false,
          isLoading: false,
          data: {
            data: response.data?.data as T,
            success: false,
            errors: ["Can't fetch mock"],
          },
        };
        setResponse(resp);
        return resp;
      });

    const unwrap = () =>
      Promise.resolve(
        fetcher.then(({ data }) =>
          data ? Promise.resolve(data) : Promise.reject()
        )
      );

    const refetch = () => setResponse(initialState);

    const abort = () =>
      setResponse({
        ...initialState,
        isLoading: false,
        isFetching: false,
      });

    return {
      ...Promise.resolve(fetcher.then((data) => Promise.resolve(data))),
      unwrap,
      refetch,
      abort,
    };
  };

  return [trigger, response];
};

export default {
  fetchMock,
  useCreateLazyQueryMock,
};
