import {
  useConfirmPasswordRecoveryMutation as realUseConfirmPasswordRecoveryMutation,
  useGetPasswordRecoveryLinkMutation as realUseGetPasswordRecoveryLinkMutation,
  useLazyConfirmSignUpQuery as realUseLazyConfirmSignUpQuery,
  useLazyLogoutQuery as realUseLazyLogoutQuery,
  useLazySignInQuery as realUseLazySignInQuery,
  useLazySignUpQuery as realUseLazySignUpQuery,
  useLazyVerifyCaptchaQuery as realUseLazyVerifyCaptchaQuery,
  useLazyWhoamiQuery as realUseLazyWhoamiQuery,
  useWhoamiQuery as realUseWhoamiQuery,
} from './authApi';
import {
  useConfirmPasswordRecoveryMutation as mockUseConfirmPasswordRecoveryMutation,
  useGetPasswordRecoveryLinkMutation as mockUseGetPasswordRecoveryLinkMutation,
  useLazyConfirmSignUpQuery as mockUseLazyConfirmSignUpQuery,
  useLazyLogoutQuery as mockUseLazyLogoutQuery,
  useLazySignInQuery as mockUseLazySignInQuery,
  useLazySignUpQuery as mockUseLazySignUpQuery,
  useLazyVerifyCaptchaQuery as mockUseLazyVerifyCaptchaQuery,
  useLazyWhoamiQuery as mockUseLazyWhoamiQuery,
  useWhoamiQuery as mockUseWhoamiQuery,
} from './mockAuthApi';

const mocked = process.env.REACT_APP_MOCKS_ENABLED === 'true';

export const useLazySignInQuery = mocked
  ? mockUseLazySignInQuery
  : realUseLazySignInQuery;

export const useLazySignUpQuery = mocked
  ? mockUseLazySignUpQuery
  : realUseLazySignUpQuery;

export const useLazyConfirmSignUpQuery = mocked
  ? mockUseLazyConfirmSignUpQuery
  : realUseLazyConfirmSignUpQuery;

export const useLazyWhoamiQuery = mocked
  ? mockUseLazyWhoamiQuery
  : realUseLazyWhoamiQuery;

export const useWhoamiQuery = mocked ? mockUseWhoamiQuery : realUseWhoamiQuery;

export const useLazyLogoutQuery = mocked
  ? mockUseLazyLogoutQuery
  : realUseLazyLogoutQuery;

export const useConfirmPasswordRecoveryMutation = mocked
  ? mockUseConfirmPasswordRecoveryMutation
  : realUseConfirmPasswordRecoveryMutation;

export const useGetPasswordRecoveryLinkMutation = mocked
  ? mockUseGetPasswordRecoveryLinkMutation
  : realUseGetPasswordRecoveryLinkMutation;

export const useLazyVerifyCaptchaQuery = mocked
  ? mockUseLazyVerifyCaptchaQuery
  : realUseLazyVerifyCaptchaQuery;
