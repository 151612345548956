import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Button,
  BUTTON_TYPE,
  ErrorResponse,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';
import clsx from 'clsx';

import { useWhoamiQuery } from 'src/features/auth/api';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { useChangePlanForEventMutation } from 'src/features/reaction/api';

import './JoinButtonCompact.scss';

const ANIMATION_DURATION = 1000;

interface JoinButtonCompactProps {
  projectId: string;
  changeJoinLocal: () => void;
  disabled?: boolean;
  wasJoined?: boolean;
  className?: string;
  countOfJoined: number;
  showCount?: boolean;
}

const JoinButtonCompact = ({
  projectId,
  changeJoinLocal,
  disabled = false,
  wasJoined = false,
  className = '',
  countOfJoined,
  showCount = true,
}: JoinButtonCompactProps) => {
  const dispatch = useDispatch();

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const { isSuccess } = useWhoamiQuery();
  const [changePlanForEvent, { isLoading: isChangePlanLoading }] =
    useChangePlanForEventMutation();

  const toggleJoin = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    } else if (!isChangePlanLoading) {
      changePlanForEvent({ projectId }).then((response) => {
        if (!(response as ErrorResponse)?.error) {
          const timeoutId = setTimeout(() => {
            setTimeoutId(null);
          }, ANIMATION_DURATION);

          setTimeoutId(timeoutId);

          changeJoinLocal();
        }
      });
    }
  };

  const onJoinClick = () => {
    if (isSuccess) {
      toggleJoin();
    } else {
      dispatch(changeModal(MODALS.SIGN_IN));
    }
  };

  useEffect(() => () => clearTimeout(timeoutId || ''), [timeoutId]);

  return (
    <Button
      className={clsx('join-button-compact', className, {
        'join-button-compact--clicked-join': wasJoined,
      })}
      type={BUTTON_TYPE.TEXT_LIGHT}
      onClick={onJoinClick}
      disabled={disabled}
    >
      <Icon
        className={clsx('join-button-compact__icon', {
          'join-button-compact__icon--joined': wasJoined,
          'join-button-compact--clicked-unjoin': !wasJoined,
        })}
        icon={wasJoined ? IconType.HandFilled : IconType.HandUnfilled}
      />
      {showCount ? (
        <span className="join-button__count">{countOfJoined}</span>
      ) : null}
    </Button>
  );
};

export default JoinButtonCompact;
