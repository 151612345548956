import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import {
  Icon,
  IconType,
  USER_ROLE,
} from '@platform-for-public-places/components-library';

import './MenuSteps.scss';

interface Step {
  name: string;
  path: string;
  roles?: USER_ROLE[];
}

interface MenuStepsProps {
  className?: string;
  steps: { [key: string]: Step };
  userRole?: USER_ROLE;
}

const MenuSteps = ({ className = '', steps, userRole }: MenuStepsProps) => {
  const { t } = useTranslation();

  const renderSteps = () => {
    return Object.entries(steps).map(([key, value]) => {
      const isVisible =
        !value.roles || !userRole || value.roles.includes(userRole);

      if (isVisible) {
        return (
          <NavLink to={value.path} className="menu-steps__step" key={key}>
            <>
              {t(value.name)}
              <Icon className="menu-steps__step-icon" icon={IconType.Chevron} />
            </>
          </NavLink>
        );
      }

      return null;
    });
  };

  return <div className={`menu-steps ${className}`}>{renderSteps()}</div>;
};

export default MenuSteps;
