export enum MODALS {
  PICTURE,
  SIGN_IN,
  SIGN_UP,
  CONFIRMATION,
  EMAIL_CONFIRMED,
  INITIATORS_USER_CARD,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SENT,
  RECOVERY_PASSWORD,
  RECOVERY_PASSWORD_SUCCESS,
  SPECIALIST_USER_CARD,
  DETAILED_PLATFORM_TEAM_MEMBER,
  FORM_ADD_TO_PROJECT,
  PAYMENT,
  PAYMENT_SUCCESS,
  PAYMENT_FAILURE,
  AUTOPAYMENTS_MANAGEMENT,
  AUTOPAYMENTS_MANAGEMENT_SENT,
}

export interface BackdoorPayload {
  backdoor?: string;
}

export interface BypassPayload {
  bypass?: boolean;
}
