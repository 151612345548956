import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';

interface MobileShareProps {
  className: string;
}

const MobileShare = ({ className }: MobileShareProps) => {
  const handleOnClick = () => {
    if (navigator.share) {
      navigator
        .share({
          url: window.location.href,
        })
        .then(() => {
          console.log('Successfully shared');
        })
        .catch((error) => {
          console.log('Something went wrong sharing the url', error);
        })
        .finally(() => {
          console.log('Finally are called after shared');
        });
    }
  };

  return (
    <Button
      className={className}
      type={BUTTON_TYPE.TEXT}
      icon
      fill
      onClick={handleOnClick}
    >
      <Icon icon={IconType.Share} />
    </Button>
  );
};

export default MobileShare;
