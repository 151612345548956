import { useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { ProjectTeamRequests } from 'src/features/project/models';
import { ProjectSpecialist } from 'src/features/user/models';

import MemberRequestCard, {
  MemberRequestCardType,
} from '../MemberRequestCard/MemberRequestCard';

export interface MemberRequestsListProps {
  className?: string;
  requests: ProjectTeamRequests;
  type: MemberRequestCardType;
  placeholder: JSX.Element;
  error?: boolean;
  infinite?: boolean;
  useTags?: boolean;
  useRoles?: boolean;
  onDelete?: () => void;
  onCardClick?:
    | ((specialist: ProjectSpecialist, requestId: string) => void)
    | ((specialist: ProjectSpecialist) => void);
  onEndReached?: () => void;
}

const MemberRequestsList = ({
  className,
  requests,
  type,
  placeholder,
  error = false,
  infinite = false,
  useTags = false,
  useRoles = false,
  onDelete,
  onCardClick,
  onEndReached,
}: MemberRequestsListProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const onLoadMore = () => {
    setLoading(true);
    onEndReached?.();
    setLoading(false);
  };

  const [tailRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: requests.specialists.length < requests.count,
    disabled: error,
    onLoadMore,
  });

  const cards = requests.specialists.map((s) => (
    <MemberRequestCard
      type={type}
      key={s.specialist.id}
      name={s.specialist.name}
      comment={s.request.message}
      requestStatus={s.request.status}
      tags={useTags ? s.specialist.tags : undefined}
      roles={useRoles ? s.specialist.roles : undefined}
      loader={type === MemberRequestCardType.INCOMING}
      onDelete={onDelete}
      onCardClick={() => onCardClick?.(s.specialist, s.request.requestID)}
    />
  ));
  return (
    <div className={className}>
      {cards && cards?.length > 0 ? cards : placeholder}
      {infinite && onEndReached ? <div ref={tailRef} /> : null}
    </div>
  );
};

export default MemberRequestsList;
