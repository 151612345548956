import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { TYPE_SEARCH_PAGE } from 'src/features/map/components/SearchPane/components/PaginationList/PaginationList';
import SearchPanelMobileBottomSheet from 'src/features/map/components/SearchPane/components/SearchPanelMobileBottomSheet/SearchPanelMobileBottomSheet';
import SearchPanelContentMapDesktop from 'src/features/map/components/SearchPane/widget/map/SearchPanelContentMapDesktop';
import SearchPanelContentMapMobile from 'src/features/map/components/SearchPane/widget/map/SearchPanelContentMapMobile';
import SearchProvider from 'src/features/searchWithFilter/filter/search/SearchProvider';

import './SearchPane.scss';

export const SearchPanelMap = () => {
  const isSmallScreen = useCheckSmallScreen();
  return (
    <SearchProvider typePage={TYPE_SEARCH_PAGE.PROJECT_MAP}>
      {isSmallScreen ? (
        <SearchPanelMobileBottomSheet>
          <SearchPanelContentMapMobile />
        </SearchPanelMobileBottomSheet>
      ) : (
        <SearchPanelContentMapDesktop />
      )}
    </SearchProvider>
  );
};
