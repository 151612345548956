import Control from 'react-leaflet-custom-control';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import SearchPaneControl from 'src/features/control/components/SearchPaneControl/SearchPaneControl';
import DrawControl from 'src/features/draw/components/DrawControl/DrawControl';

const TopLeftControl = () => {
  const isSmallScreen = useCheckSmallScreen();

  return (
    <Control prepend position="topleft">
      {isSmallScreen ? <DrawControl /> : <SearchPaneControl />}
    </Control>
  );
};

export default TopLeftControl;
