import { useMap } from 'react-leaflet';

import { Icon, IconType } from '@platform-for-public-places/components-library';

import { useDisablePropagationRef } from 'src/features/hooks';

import './ZoomControl.scss';

const ZoomControl = (): JSX.Element => {
  const controlRef = useDisablePropagationRef();

  const map = useMap();

  const zoomIn = () => map.zoomIn(1);
  const zoomOut = () => map.zoomOut(1);

  return (
    <div className="zoom-control" ref={controlRef}>
      <button className="zoom-control__button" onClick={zoomIn}>
        <Icon className="zoom-control__icon" icon={IconType.Plus} />
      </button>
      <button className="zoom-control__button" onClick={zoomOut}>
        <Icon className="zoom-control__icon" icon={IconType.Minus} />
      </button>
    </div>
  );
};

export default ZoomControl;
