import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  useCheckSmallScreen,
  useKeyboardVisible,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import { changeDrawHint } from 'src/features/draw/slices/drawSlice';
import { useCheckPkkAccessability } from 'src/features/hooks';
import MobileHeader from 'src/features/mobile/components/MobileHeader/MobileHeader';
import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import Header from 'src/pages/layouts/components/Header/Header';
import CreationFooter, {
  CreationFooterProps,
} from 'src/pages/layouts/CreationLayout/components/CreationFooter/CreationFooter';

import './CreationLayout.scss';

export const DEFAULT_TYPE_PROJECT = 'default';

interface CreationLayoutProps {
  creationSteps?: boolean;
}

const CreationLayout = ({ creationSteps = false }: CreationLayoutProps) => {
  useCheckPkkAccessability();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const mobile = useCheckSmallScreen();
  const keyboardVisible = useKeyboardVisible();
  const currentTypeProject = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );
  const stepConfigs = useSelector(
    (s: State) => s[PROJ_CREATE].configCreationSteps
  );

  const { isUninitialized, isLoading, isSuccess } = useWhoamiQuery();

  useEffect(() => {
    dispatch(changeDrawHint(t('drawTool.firstPointTooltipCreating')));
  }, [dispatch, t]);

  useEffect(() => {
    if (!isUninitialized && !isLoading && !isSuccess) {
      navigate(paths.map);
    }
  }, [navigate, isUninitialized, isLoading, isSuccess]);

  const activeClass = (s: TemplateStringsArray, active: boolean) => {
    const modifier = active ? `${s[0]}--active` : '';
    return `${s[0]} ${modifier}`;
  };

  const renderSteps = () => {
    const predicate = (key: string) => location.pathname.endsWith(key);
    const activeIndex = stepConfigs.findIndex((step) => predicate(step.name));
    return stepConfigs.slice(0, -1).map((step, i) => (
      <div
        key={step.name}
        className={activeClass`creation-layout__step${i <= activeIndex}`}
      >
        <div className="creation-layout__step-number">{i + 1}</div>
        {mobile ? null : (
          <div className="creation-layout__step-name">
            {t(`creation.${currentTypeProject}.${step.name}.stageName`)}
          </div>
        )}
      </div>
    ));
  };

  const renderFooter = (props: CreationFooterProps) =>
    keyboardVisible ? null : <CreationFooter {...props} />;

  const renderBackButton = () => (
    <Button
      className="creation-layout__back"
      type={BUTTON_TYPE.TEXT_DARK}
      icon
      onClick={() => navigate(paths.map)}
    >
      <Icon icon={IconType.Arrow} />
      <span>{t('creation.backButton')}</span>
    </Button>
  );

  const renderSideNavigation = () => (
    <aside className="creation-layout__aside-steps">
      <div className="creation-layout__back-wrapper">{renderBackButton()}</div>
      {creationSteps ? null : (
        <div className="creation-layout__steps">{renderSteps()}</div>
      )}
    </aside>
  );

  const renderTopNavigation = () => (
    <header
      className={`${
        creationSteps
          ? 'creation-layout__aside-choose'
          : 'creation-layout__aside-steps'
      }`}
    >
      <MobileHeader
        title={t(`creation.type.${currentTypeProject}.title`)}
        path={paths.map}
        hideBackButton={!creationSteps}
      />
      {creationSteps ? null : (
        <div className="creation-layout__steps">{renderSteps()}</div>
      )}
    </header>
  );

  const renderNavigation = () =>
    mobile ? renderTopNavigation() : renderSideNavigation();

  const renderLayout = () => (
    <>
      {mobile ? null : <Header hideCreation />}
      <main className="creation-layout">
        {renderNavigation()}
        <div className="creation-layout__gate">
          <Outlet context={{ renderFooter }} />
        </div>
      </main>
    </>
  );

  return !isUninitialized && !isLoading && isSuccess ? renderLayout() : null;
};

export default CreationLayout;
