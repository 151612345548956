import { Outlet } from 'react-router-dom';

import CookieCard from 'src/features/cookies/components/CookieCard/CookieCard';

const CookieLayout = () => {
  return (
    <>
      <Outlet />
      <CookieCard />
    </>
  );
};

export default CookieLayout;
