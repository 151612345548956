import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';

import { ReactComponent as RecoverySuccessImage } from './img/recoveryPasswordSuccess.svg';

import './RecoveryPasswordSuccess.scss';

const RecoveryPasswordSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'recoveryPasswordSuccess' });

  const onClick = () => {
    isSmallScreen
      ? navigate(paths.signIn, { replace: true })
      : dispatch(changeModal(MODALS.SIGN_IN));
  };

  return (
    <div className="recovery-password-success">
      <h3 className="auth-title recovery-password-success__title">
        {t('title')}
      </h3>
      <RecoverySuccessImage />
      <Button
        className="auth-button recovery-password-success__button"
        type={BUTTON_TYPE.ACCENTED}
        onClick={onClick}
      >
        {t('signIn')}
      </Button>
    </div>
  );
};

export default RecoveryPasswordSuccess;
