import clsx from 'clsx';

import './Digest.scss';

interface DigestProps {
  index: number;
  current: number;
  title: string;
  findingClassName: string;
  onClick?: (index: number) => void;
  children?: React.ReactNode;
  className?: string;
}

const Digest = ({
  index,
  current,
  title,
  findingClassName,
  onClick = () => ({}),
  children = null,
  className = '',
}: DigestProps): JSX.Element => {
  const onDigestClick = () => {
    setTimeout(() => {
      document
        .getElementsByClassName(findingClassName)
        ?.[index].scrollIntoView({ behavior: 'smooth' });
    }, 50);
    onClick(index);
  };

  return (
    <div className={clsx('digest', className)}>
      <svg
        className={clsx('digest__marker', {
          'digest__marker--active': index === current,
        })}
      >
        <circle cx="8" cy="8" r="8" />
      </svg>
      <button className="digest__button" onClick={onDigestClick}>
        <h4
          className={clsx('digest__title', {
            'digest__title--active': index === current,
          })}
        >
          {title}
        </h4>
        {children}
      </button>
    </div>
  );
};

export default Digest;
