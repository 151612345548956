import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { DRAW } from 'src/features/draw/slices/drawSlice';
import {
  PROJ_LAYER,
  setCurrentProjectInfo,
} from 'src/features/project/slices/projectsLayerSlice';
import { State } from 'src/features/store/store';

export const useOpenHookCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSmallScreen = useCheckSmallScreen();
  const wasNotDrawn = useSelector((state: State) => !state[DRAW].drawnShape);
  const isDrawDisabled = useSelector(
    (state: State) => !state[DRAW].isDrawEnable
  );
  const currentProjectInfo = useSelector(
    (s: State) => s[PROJ_LAYER].currentProjectInfo
  );
  const openOverviewCard = (projectId: string, archived?: boolean) => {
    if (wasNotDrawn && isDrawDisabled) {
      if (currentProjectInfo?.projectInfo.projectID !== projectId) {
        dispatch(setCurrentProjectInfo(null));
      }
      isSmallScreen
        ? navigate(paths.projectById(projectId), { state: { goOnMap: true } })
        : navigate(
            `${paths.projectOverviewCardById(projectId)}${
              archived ? '&archived=true' : '' //todo check need archive?
            }`,
            { replace: true }
          );
    }
  };
  return { openOverviewCard };
};
