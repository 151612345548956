import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';
import clsx from 'clsx';

import DatePickerButton from 'src/shared/components/DatePickerButton/DatePickerButton';
import TimePicker from 'src/shared/components/TimePicker/TimePicker';

import StageHeader from 'src/features/layout/components/StageHeader/StageHeader';
import { StepConfig } from 'src/features/project/models';
import {
  PROJ_CREATE,
  setEndDate,
  setEndTime,
  setStartDate,
  setStartTime,
} from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import { getDateWithTime } from '..';

import './DatesWidget.scss';

export interface DatesWidgetProps {
  stepConfig: StepConfig;
  setIsValid: (valid: boolean) => void;
}

enum DatesErrorType {
  NEGATIVE_DURATION = 'NEGATIVE_DURATION',
  EARLIER_THAN_CURRENT = 'EARLIER_THAN_CURRENT',
  EMPTY_DATE = 'EMPTY_DATE',
}

interface ValidationResult {
  error?: DatesErrorType;
  isValid: boolean;
}

const DatesWidget = ({ stepConfig, setIsValid }: DatesWidgetProps) => {
  const dispatch = useDispatch();
  const startDate = useSelector((s: State) => s[PROJ_CREATE].startDate);
  const endDate = useSelector((s: State) => s[PROJ_CREATE].endDate);
  const startTime = useSelector((s: State) => s[PROJ_CREATE].startTime);
  const endTime = useSelector((s: State) => s[PROJ_CREATE].endTime);

  const currentTypeProject = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );
  const currentCreationStep = useSelector(
    (s: State) => s[PROJ_CREATE].currentCreationStep
  );

  const [startDateValidation, setStartDateValidation] =
    useState<ValidationResult>({
      isValid: true,
    });

  const [endDateValidation, setEndDateValidation] = useState<ValidationResult>({
    isValid: true,
  });

  const [isFocused, setIsFocused] = useState(true);

  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', {
    keyPrefix: `creation.${currentTypeProject}.dates`,
  });

  const dateValid = !!startDate && !!endDate && !!startTime && !!endTime;

  const formatErrorType = (errorType: string) => {
    return errorType
      .toLowerCase()
      .replace(/_\w/g, (match) => match[1].toUpperCase());
  };

  const validateDateDuration = useCallback(
    (
      startDate: string,
      startTime: string,
      endDate: string,
      endTime: string
    ): ValidationResult => {
      if (!isFocused) {
        const currentDate = new Date();
        const startDateWithTime = getDateWithTime(
          new Date(startDate),
          startTime
        );

        const endDateWithTime = getDateWithTime(new Date(endDate), endTime);

        const isNegativeDuration = startDateWithTime > endDateWithTime;
        const isEarlierThanCurrent = startDateWithTime < currentDate;

        if (isNegativeDuration) {
          return { error: DatesErrorType.NEGATIVE_DURATION, isValid: false };
        } else if (isEarlierThanCurrent) {
          return { error: DatesErrorType.EARLIER_THAN_CURRENT, isValid: false };
        } else if (!!startDate && !!startTime) {
          return { isValid: true };
        }
      }
      return startDateValidation;
    },
    [isFocused, startDateValidation]
  );

  useEffect(() => {
    if (!!startDate || !!startTime) {
      const validation = validateDateDuration(
        startDate,
        startTime,
        endDate,
        endTime
      );
      setIsValid(dateValid && validation.isValid);
      setStartDateValidation(validation);
    }
  }, [
    validateDateDuration,
    startDate,
    startTime,
    endDate,
    endTime,
    dateValid,
    setIsValid,
  ]);

  const renderStageHeader = () => {
    return (
      <StageHeader
        className="dates__title"
        header={`${isSmallScreen ? `${currentCreationStep + 1}. ` : ''}${t(
          'title'
        )}`}
      />
    );
  };

  const handleOnFocus = () => {
    setIsFocused(true);
  };

  const handleOnStartDateFocusLeave = (value?: string) => {
    setIsFocused(false);
    if (!value) {
      setIsValid(false);
      setStartDateValidation({
        error: DatesErrorType.EMPTY_DATE,
        isValid: false,
      });
    } else {
      setIsValid(true);
      setStartDateValidation({
        isValid: true,
      });
    }
  };

  const handleOnEndDateFocusLeave = (value?: string) => {
    setIsFocused(false);
    if (!value) {
      setIsValid(false);
      setEndDateValidation({
        error: DatesErrorType.EMPTY_DATE,
        isValid: false,
      });
    } else {
      setIsValid(true);
      setEndDateValidation({
        isValid: true,
      });
    }
  };

  const renderDate = (
    date: Date | undefined,
    setDate: (value: Date) => void,
    time: string,
    setTime: (value: string) => void,
    componentType: string,
    validation: ValidationResult,
    onFocusLeave: (value?: string) => void
  ) => {
    return (
      <>
        <div className="dates__date-widget date-widget">
          <h5 className="date-widget__title">{t(`${componentType}.title`)}</h5>
          <p className="date-widget__subtitle">
            {t(`${componentType}.subtitle`)}
          </p>
          <div className="date-widget__content">
            <DatePickerButton
              className={clsx('date-widget__date', {
                'date-widget__date--error': !isFocused && !validation.isValid,
              })}
              date={date}
              setDate={setDate}
              onFocus={handleOnFocus}
              onFocusLeave={onFocusLeave}
            />
            <TimePicker
              className={clsx('date-widget__time', {
                'date-widget__time--error': !isFocused && !validation.isValid,
              })}
              time={{ label: time, value: time }}
              setTime={setTime}
              onFocus={handleOnFocus}
              onFocusLeave={onFocusLeave}
            />
          </div>
        </div>
        <span
          className={clsx('dates__error', {
            'dates__error--active': !isFocused && !validation.isValid,
          })}
        >
          {validation?.error
            ? t(`errors.${formatErrorType(validation?.error)}`)
            : null}
        </span>
      </>
    );
  };

  const renderStartDate = () => {
    return renderDate(
      startDate ? new Date(startDate) : undefined,
      (date: Date) => dispatch(setStartDate(date?.toISOString() || '')),
      startTime,
      (time: string) => dispatch(setStartTime(time || '')),
      'startDate',
      startDateValidation,
      handleOnStartDateFocusLeave
    );
  };

  const renderEndDate = () => {
    return renderDate(
      endDate ? new Date(endDate) : undefined,
      (date: Date) => dispatch(setEndDate(date?.toISOString() || '')),
      endTime,
      (time: string) => dispatch(setEndTime(time || '')),
      'endDate',
      endDateValidation,
      handleOnEndDateFocusLeave
    );
  };

  const renderMap = new Map<string, () => JSX.Element>([
    ['stageHeader', renderStageHeader],
    ['startDate', renderStartDate],
    ['endDate', renderEndDate],
  ]);

  return (
    <div className="dates">
      {stepConfig.properties.visible.map((item) => renderMap.get(item)?.())}
    </div>
  );
};

export default DatesWidget;
