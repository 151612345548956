import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { USER_ROLE } from 'src/features/auth/models';
import { useCheckUser } from 'src/features/hooks';
import StageHeader from 'src/features/layout/components/StageHeader/StageHeader';
import ProjectStatus, {
  TypeStatus,
} from 'src/features/project/components/ProjectStatus/ProjectStatus';
import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import {
  useGetAvailableStatusesByProjectIdQuery,
  useGetStatusesQuery,
} from 'src/features/status/api';
import StatusEditor from 'src/features/status/components/StatusEditor/StatusEditor';
import { State } from 'src/features/store/store';

import './EditingStatus.scss';

const EditingStatus = () => {
  const { id } = useParams();
  const isSmallScreen = useCheckSmallScreen();

  const currentProjectType = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );

  const { t } = useTranslation('app', {
    keyPrefix: `editing.${currentProjectType}.status`,
  });

  const isAdmin = useCheckUser([{ role: USER_ROLE.ADMIN }]);

  const { data: statuses } = useGetStatusesQuery(
    { projectId: id as string },
    { skip: !id }
  );

  const availableStatuses = useGetAvailableStatusesByProjectIdQuery(
    { projectId: id as string },
    { skip: !id }
  );

  return (
    <div className="status-container">
      {isSmallScreen ? null : (
        <StageHeader className="status-container__title" header={t('title')} />
      )}
      <ProjectStatus
        statuses={statuses?.data || []}
        className="status-container__bar"
        statusType={TypeStatus.EDIT_STATUS_PAGE}
        projectId={id}
        availableStatuses={availableStatuses.data?.data}
        projectType={currentProjectType}
      />
      {!isSmallScreen && isAdmin ? (
        <StatusEditor
          statuses={statuses?.data || []}
          availableStatuses={availableStatuses.data?.data ?? { flowSteps: [] }}
        />
      ) : null}
    </div>
  );
};

export default EditingStatus;
