import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import { randUuid } from '@ngneat/falso';
import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import ProjectUserList from 'src/shared/components/ProjectUserList/ProjectUserList';

import { useWhoamiQuery } from 'src/features/auth/api';
import StageHeader from 'src/features/layout/components/StageHeader/StageHeader';
import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import { PROJ_LAYER } from 'src/features/project/slices/projectsLayerSlice';
import {
  FILTER_DATA,
  setTeam,
} from 'src/features/searchWithFilter/filter/slices/filterSliceData';
import { State } from 'src/features/store/store';
import { ProjectSpecialist } from 'src/features/user/models';

import StatusTeamUsers from 'src/pages/layouts/CreationLayout/components/StatusTeamUsers/StatusTeamUsers';
import { CreationOutletProps } from 'src/pages/layouts/CreationLayout/models';
import { useRequestSaveTeam } from 'src/pages/project/hooks';

import './CreationTeamWidget.scss';

const CreationTeamWidget = () => {
  const dispatch = useDispatch();
  const isSmallScreen = useCheckSmallScreen();
  const { renderFooter }: CreationOutletProps = useOutletContext();

  const team = useSelector((s: State) => s[FILTER_DATA].team);
  const projectId = useSelector((s: State) => s[PROJ_CREATE].projectId);
  const projectInfo = useSelector(
    (s: State) => s[PROJ_LAYER].currentProjectInfo
  );

  const currentTypeProject = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );
  const currentCreationStep = useSelector(
    (s: State) => s[PROJ_CREATE].currentCreationStep
  );
  const configCreationSteps = useSelector(
    (s: State) => s[PROJ_CREATE].configCreationSteps
  );

  const { t } = useTranslation('app', {
    keyPrefix: `creation.${currentTypeProject}.team`,
  });

  const [error, setError] = useState(false);
  const [marginLastElement, setMarginLastElement] = useState(false);

  const { data: whoami } = useWhoamiQuery();
  const modifyTeam = useRequestSaveTeam(projectId);

  const updateTeam = () => {
    const err = !team.every((value) => {
      return value.name && value.roles;
    });
    setError(err);
    if (!err) {
      modifyTeam(team);
    }
  };

  const specialistRef = useRef<Array<HTMLDivElement | null>>([]);

  const initialProjectUser: ProjectSpecialist = {
    userId: randUuid(),
    name: '',
    id: randUuid(),
  };

  const executeScroll = (key: number) => {
    specialistRef.current[key]?.scrollIntoView({ behavior: 'smooth' });
  };

  const addUserInTeam = () => {
    setError(false);
    dispatch(setTeam([...team, initialProjectUser]));
    executeScroll(team.length);
  };

  const renderUsers = useMemo(() => {
    return team.map((value, index) => {
      if (value.requestStatus) {
        return <StatusTeamUsers key={index} user={value} />;
      } else {
        return (
          <ProjectUserList
            inref={(el) => (specialistRef.current[index] = el)}
            scrollToUser={() => {
              executeScroll(index);
            }}
            setMarginLastElement={setMarginLastElement}
            marginLastElement={marginLastElement}
            key={index}
            id={value.id}
            user={{ data: value, value: value.userId, label: value.name }}
            error={error}
            setError={setError}
          />
        );
      }
    });
  }, [error, marginLastElement, team]);

  const renderName = useCallback(() => {
    const name =
      projectInfo?.projectInfo.owner?.name || whoami?.data.profile?.name || '-';
    if (projectInfo?.projectInfo.ownerId) {
      const belongsToYou =
        whoami?.data.userID === projectInfo?.projectInfo.ownerId;
      return `${name} ${belongsToYou ? t('you') : ''}`;
    } else {
      return `${name} ${t('you')}`;
    }
  }, [projectInfo, whoami, t]);

  const renderStageHeader = () => {
    return (
      <StageHeader
        className="team__title"
        header={`${isSmallScreen ? `${currentCreationStep + 1}. ` : ''}${t(
          'title'
        )}`}
        subheader={t('description')}
      />
    );
  };

  const renderInitiatorInfo = () => {
    return (
      <div className="team__label-and-name">
        <span className="team__initiator-label">{`${t('initiator')}: `}</span>
        <span className="team__initiator-name">{renderName()}</span>
      </div>
    );
  };

  const renderAddMemberBlock = () => {
    return (
      <>
        <div
          className={`team__members ${
            team.length ? '' : 'team__members-no-one'
          }`}
        >
          {renderUsers}
        </div>
        <Button
          className={`team__add-button team__add-button-create team__add-button--show ${
            team.length > 0 ? 'team__add-button--no-margin' : ''
          }`}
          type={BUTTON_TYPE.SECONDARY}
          icon
          onClick={addUserInTeam}
        >
          <Icon icon={IconType.Personplus} />
          {t('addMember')}
        </Button>
        {team.length > 0 ? (
          <div className="team__notification-create">
            <Icon icon={IconType.Error} />
            <p>{t('notification')}</p>
          </div>
        ) : null}
      </>
    );
  };

  const renderMap = new Map<string, () => JSX.Element>([
    ['stageHeader', renderStageHeader],
    ['initiatorInfo', renderInitiatorInfo],
    ['addMemberBlock', renderAddMemberBlock],
  ]);

  return (
    <>
      <div className="team team-create">
        {configCreationSteps[currentCreationStep].properties.visible.map(
          (item) => renderMap.get(item)?.()
        )}
      </div>
      {renderFooter?.({
        updateStepData: updateTeam,
        valid: true,
        nextButtonText: isSmallScreen ? t('continueMobile') : t('continue'),
        className: 'team-create__footer',
      })}
    </>
  );
};

export default CreationTeamWidget;
