import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import {
  PROJ_CREATE,
  setCurrentEditingStep,
  setFeature,
} from 'src/features/project/slices/creatingProjectSlice';
import { PROJ_LAYER } from 'src/features/project/slices/projectsLayerSlice';
import { State } from 'src/features/store/store';

import './SideMenu.scss';

const SideMenu = (): JSX.Element => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editingStepsConfig = useSelector(
    (s: State) => s[PROJ_CREATE].configEditingSteps
  );

  const feature = useSelector((s: State) => s[PROJ_CREATE].feature);
  const featureEdit = useSelector((s: State) => s[PROJ_CREATE].featureEdit);
  const type = useSelector((s: State) => s[PROJ_CREATE].currentTypeProject);
  const projectGeodata = useSelector((s: State) => s[PROJ_LAYER].geodata);

  const renderSteps = () => {
    return editingStepsConfig.map((step, i) => {
      return (
        <NavLink
          to={paths.editingStep(step.name, id || '')}
          onClick={() => {
            dispatch(setCurrentEditingStep(i));
            if (projectGeodata && feature !== featureEdit) {
              dispatch(setFeature(projectGeodata.polygon));
            }
          }}
          className="edit-layout-side-menu__step"
          key={i}
        >
          {t(`editing.${type}.${step.name}.stageName`)}
        </NavLink>
      );
    });
  };

  return (
    <aside className="edit-layout-side-menu">
      <div className="edit-layout-side-menu__back-wrapper">
        <Button
          className="edit-layout-side-menu__back"
          type={BUTTON_TYPE.TEXT_DARK}
          onClick={() => navigate(id ? paths.projectById(id) : paths.map)}
          icon
        >
          <Icon icon={IconType.Arrow} />
          <span>{t('creation.backButton')}</span>
        </Button>
      </div>
      <div className="edit-layout-side-menu__steps">{renderSteps()}</div>
    </aside>
  );
};

export default SideMenu;
