export enum SORTING_ORDER_DIRECTION {
  ASC = 'asc',
  DESC = 'desc',
}

export enum SORTING_ORDER_BY {
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  APPROVED_AT = 'approvedAt',
  NAME = 'name',
}
