import { useState } from 'react';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import DonationForm from '../DonationForm/DonationForm';
import DonationTotalBudget from '../DonationTotalBudget/DonationTotalBudget';

import './Donation.scss';

interface DonationProps {
  title: string;
  description: string;
  buttonText: string;
  projectId: string;
  archived?: boolean;
  isDefaultBehaviour?: boolean;
  idCheckox?: string;
}

const Donation = ({
  title,
  description,
  buttonText,
  projectId,
  archived,
  isDefaultBehaviour = true,
  idCheckox = 'accept',
}: DonationProps) => {
  const isSmallScreen = useCheckSmallScreen();
  const [teamMore, setTeamMore] = useState(false);

  return isDefaultBehaviour && isSmallScreen ? (
    <div className="donation__mobile">
      <Button
        type={BUTTON_TYPE.TEXT_DARK}
        onClick={() => {
          setTeamMore((prev) => !prev);
        }}
        icon
        fill
        className={`members__title members__button ${
          teamMore ? 'members__button--open' : ''
        }`}
        disabled={!isSmallScreen}
      >
        {title}
        {isSmallScreen ? <Icon icon={IconType.Chevron} /> : null}
      </Button>
      {teamMore ? (
        <div className="donation__block-data">
          <DonationTotalBudget
            className="detailed-info__total-budget"
            archived={archived}
          />
          <DonationForm
            showDelay
            donateForProject
            archived={archived}
            className="detailed-info__donation-form"
            title={title}
            projectId={projectId}
            description={description}
            buttonText={buttonText}
          />
        </div>
      ) : null}
    </div>
  ) : (
    <>
      <h5 className="donation-form__title">{title}</h5>
      <DonationTotalBudget
        className="detailed-info__total-budget"
        archived={archived}
      />
      <DonationForm
        showDelay
        donateForProject
        archived={archived}
        className="detailed-info__donation-form"
        title={title}
        projectId={projectId}
        description={description}
        buttonText={buttonText}
        idCheckox={idCheckox}
      />
    </>
  );
};

export default Donation;
