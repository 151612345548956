import { Icon, IconType } from '@platform-for-public-places/components-library';
import clsx from 'clsx';

import './WarningMessage.scss';

interface WarningMessageProps {
  className?: string;
  text: string;
}

const WarningMessage = ({ className, text }: WarningMessageProps) => {
  return (
    <div className={clsx('warning-message', className)}>
      <Icon icon={IconType.Error} />
      <p className={clsx('warning-message-text')}>{text}</p>
    </div>
  );
};

export default WarningMessage;
