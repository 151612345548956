import {
  useGetLazyUsersQuery as mockUseLazyGetUsersQuery,
  useGetUserCountQuery as mockUserCountQuery,
  useLazyGetProfileQuery as mockUseLazyGetProfileQuery,
  useLazyGetUsersWithProfileLink as mockUseLazyGetUsersWithProfileLink,
  useLazyUpdateProfileQuery as mockUseLazyUpdateProfileQuery,
} from './mockUserApi';
import {
  useGetUserCountQuery as realUserCountQuery,
  useLazyGetProfileQuery as realUseLazyGetProfileQuery,
  useLazyGetUsersQuery as realUseLazyGetUsersQuery,
  useLazyGetUsersWithProfileLinkQuery as realUseLazyGetUsersWithProfileLink,
  useLazyUpdateProfileQuery as realUseLazyUpdateProfileQuery,
} from './userApi';

export const useLazyGetUsersQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetUsersQuery
    : realUseLazyGetUsersQuery;

export const useLazyGetProfileQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetProfileQuery
    : realUseLazyGetProfileQuery;

export const useGetUserCountQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUserCountQuery
    : realUserCountQuery;

export const useLazyGetUsersWithProfileLinkQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetUsersWithProfileLink
    : realUseLazyGetUsersWithProfileLink;

export const useLazyUpdateProfileQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyUpdateProfileQuery
    : realUseLazyUpdateProfileQuery;
