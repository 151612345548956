import {
  useGetCategoryWithTagsQuery as mockUseGetCategoryWithTagsQuery,
  useLazyAcceptSpecialistsByRequestIdQuery as mockUseLazyAcceptSpecialistsByRequestIdQuery,
  useLazyCreateMemberRequestQuery as mockUseLazyCreateMemberRequestQuery,
  useLazyCreateSpecialistRequestQuery as mockUseLazyCreateSpecialistRequestQuery,
  useLazyDeleteRequestByIdRequestQuery as mockUseLazyDeleteRequestByIdRequestQuery,
  useLazyGetSpecialistRequestQuery as mockUseLazyGetSpecialistRequestQuery,
  useLazyGetUserRequestsToParticipateQuery as mockUseLazyGetUserRequestsToParticipateQuery,
  useRejectRequestByIdRequestQuery as mockUseRejectRequestByIdRequestQuery,
} from './mockRequestApi';
import {
  useGetCategoryWithTagsQuery as realUseGetCategoryWithTagsQuery,
  useLazyAcceptSpecialistsByRequestIdQuery as realUseLazyAcceptSpecialistsByRequestIdQuery,
  useLazyCreateMemberRequestQuery as realUseLazyCreateMemberRequestQuery,
  useLazyCreateSpecialistRequestQuery as realUseLazyCreateSpecialistRequestQuery,
  useLazyDeleteRequestByIdRequestQuery as realUseLazyDeleteRequestByIdRequestQuery,
  useLazyGetSpecialistRequestQuery as realUseLazyGetSpecialistRequestQuery,
  useLazyGetUserRequestsToParticipateQuery as realUseLazyGetUserRequestsToParticipateQuery,
  useLazyRejectRequestByIdRequestQuery as realUseLazyRejectRequestByIdRequestQuery,
} from './requestApi';

export const useLazyGetUserRequestsToParticipateQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetUserRequestsToParticipateQuery
    : realUseLazyGetUserRequestsToParticipateQuery;

export const useLazyCreateSpecialistRequestQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyCreateSpecialistRequestQuery
    : realUseLazyCreateSpecialistRequestQuery;

export const useLazyGetSpecialistRequestQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetSpecialistRequestQuery
    : realUseLazyGetSpecialistRequestQuery;

export const useLazyRejectRequestByIdRequestQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseRejectRequestByIdRequestQuery
    : realUseLazyRejectRequestByIdRequestQuery;

export const useLazyAcceptSpecialistsByRequestIdQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyAcceptSpecialistsByRequestIdQuery
    : realUseLazyAcceptSpecialistsByRequestIdQuery;

export const useGetTagsQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetCategoryWithTagsQuery
    : realUseGetCategoryWithTagsQuery;

export const useLazyCreateMemberRequestQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyCreateMemberRequestQuery
    : realUseLazyCreateMemberRequestQuery;

export const useLazyDeleteRequestByIdRequestQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyDeleteRequestByIdRequestQuery
    : realUseLazyDeleteRequestByIdRequestQuery;
