import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import MobileHeader from 'src/features/mobile/components/MobileHeader/MobileHeader';
import { MODALS } from 'src/features/modal/models';
import { changeModal, MODAL } from 'src/features/modal/slices/modalSlice';
import DonationSuccess from 'src/features/payment/components/DonationSuccess/DonationSuccess';
import { State } from 'src/features/store/store';

import './DonationSuccessPage.scss';

const DonationSuccessPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'paymentSuccess' });

  const openedModal = useSelector((s: State) => s[MODAL].openedModal);

  useEffect(() => {
    if (!isSmallScreen && openedModal !== MODALS.PAYMENT_SUCCESS) {
      dispatch(changeModal(MODALS.PAYMENT_SUCCESS));
    }
  });

  if (isSmallScreen) {
    return (
      <div className="donation-success-page">
        {isSmallScreen ? <MobileHeader title={t('mobileHeader')} /> : null}
        <DonationSuccess />
      </div>
    );
  } else {
    const redirectPath = id ? paths.projectById(id) : paths.frontPage;
    return <Navigate to={redirectPath} replace />;
  }
};

export default DonationSuccessPage;
