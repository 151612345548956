import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import Digest from 'src/shared/components/Digest/Digest';
import Spinner from 'src/shared/components/Spinner/Spinner';

import { useLazyGetPaymentsStatisticQuery } from 'src/features/payment/api';
import { PaymentsStatistic } from 'src/features/payment/models';

import { filterStatistic } from '../../functions';
import AnnualReportDocs from '../AnnualReportDocs/AnnualReportDocs';
import ReportingBarChart from '../ReportingBarChart/ReportingBarChart';

import './ReportingDesktop.scss';

const PAGE_SIZE_CHART_REPORTS = 10;

const ReportingDesktop = (): JSX.Element => {
  const { t } = useTranslation('app', { keyPrefix: 'reporting' });

  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [paymentsStatisticPage, setPaymentsStatisticPage] = useState(1);
  const [paymentsStatistic, setPaymentsStatistic] = useState<
    PaymentsStatistic[]
  >([]);
  const [isPaymentsStatisticError, setIsPaymentsStatisticError] =
    useState(false);
  const [currentDidgestIndex, setCurrentDidgestIndex] = useState(0);

  const [getPaymentsStatistic] = useLazyGetPaymentsStatisticQuery();

  const [paymentsStatisticRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: () => {
      setIsLoading(true);
      getPaymentsStatistic({
        page: paymentsStatisticPage,
        pageSize: PAGE_SIZE_CHART_REPORTS,
      })
        .then((value) => {
          if (value.isSuccess) {
            const statistic = value.data.data.paymentsStatistic;

            setPaymentsStatistic((prev) => [
              ...prev,
              ...filterStatistic(statistic ?? []),
            ]);
            setPaymentsStatisticPage((prev) => prev + 1);
            setHasNextPage(statistic?.length === PAGE_SIZE_CHART_REPORTS);
          }
        })
        .catch(() => setIsPaymentsStatisticError(true))
        .finally(() => setIsLoading(false));
    },
    disabled: isPaymentsStatisticError,
  });

  const onDigestClick = (index: number) => {
    setCurrentDidgestIndex(index);
  };

  const renderDigest = () =>
    paymentsStatistic.map((payments, index) => (
      <Digest
        key={index}
        current={currentDidgestIndex}
        index={index}
        findingClassName="reporting-desktop__annual-report"
        title={t('year', { year: payments.year })}
        onClick={onDigestClick}
      />
    ));

  const renderAnnualReport = (payments: PaymentsStatistic) => (
    <div
      key={payments.year}
      className="reporting-desktop__annual-report annual-report"
    >
      {isLoading ? (
        <Spinner className="annual-report__spinner" />
      ) : (
        <>
          <header className="annual-report__header">
            <h3 className="annual-report__year">
              {t('year', { year: payments.year })}
            </h3>
            <h3 className="annual-report__total-count">
              <Trans
                t={t}
                i18nKey="totalCount"
                values={{
                  amount: payments.total,
                  formatParams: { amount: { maximumFractionDigits: 0 } },
                }}
                components={{
                  span: <span />,
                }}
              />
            </h3>
          </header>
          <section className="annual-report__main">
            <ReportingBarChart
              className="annual-report__chart"
              data={payments.statistic ?? []}
            />
            <AnnualReportDocs
              reports={payments.reports}
              className="reporting-desktop__annual_report_docs"
            />
          </section>
        </>
      )}
    </div>
  );

  const renderReports = () =>
    paymentsStatistic.map((element) => renderAnnualReport(element));

  return (
    <div className="reporting-desktop">
      <aside className="reporting-desktop__reporting-sidebar reporting-sidebar">
        <h4 className="reporting-sidebar__title">{t('annualReports')}</h4>
        <div className="reporting-sidebar__digest">{renderDigest()}</div>
      </aside>
      <section className="reporting-desktop__content">
        <h1 className="reporting-desktop__title">{t('title')}</h1>
        <div className="reporting-desktop__reports">
          {renderReports()}
          {hasNextPage ? (
            <div
              className="reporting-desktop__loading-trigger"
              ref={paymentsStatisticRef}
            />
          ) : (
            <></>
          )}
        </div>
      </section>
    </div>
  );
};

export default ReportingDesktop;
