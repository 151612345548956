import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { GeoJSON, MapContainer, useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  TextInput,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';
import { Feature, Polygon } from 'geojson';
import { latLngBounds, LatLngTuple } from 'leaflet';

import {
  convertBoundsToString,
  convertSizeToString,
} from 'src/shared/converters';
import { paths } from 'src/shared/routes';

import { changeCadastralLayerVisibility } from 'src/features/control/slices/controlSlice';
import {
  changeDrawEnable,
  resetDraw,
  setDraw,
} from 'src/features/draw/slices/drawSlice';
import StageHeader from 'src/features/layout/components/StageHeader/StageHeader';
import CadasterLayer from 'src/features/map/components/CadasterLayer/CadasterLayer';
import MapLayers from 'src/features/map/components/MapLayers/MapLayers';
import { ACTION_TYPE, BACKGROUND_TYPE } from 'src/features/map/enums';
import {
  changeAction,
  changeBbox,
  changeSize,
} from 'src/features/map/slices/mapSlice';
import {
  useGetAreaInfoByPolygonQuery,
  useGetInfoAboutAreaByIdQuery,
} from 'src/features/pkk/api/pkkApi';
import CadasterPolygons from 'src/features/pkk/components/CadasterPolygons/CadasterPolygons';
import { Cadaster } from 'src/features/pkk/models';
import { changeCadasters } from 'src/features/pkk/slices/pkkSlice';
import { StepConfig } from 'src/features/project/models';
import {
  PROJ_CREATE,
  setMeetingPoint,
} from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import areaExample from './img/area-example.svg';

import './AreaWidget.scss';

const INITIAL_ZOOM = 19;

const MAX_MEETING_POINT_SYMBOLS = 500;

const enum ERRORS {
  TOO_LONG_MEETING_POINT = 'errors.tooLongMeetingPoint',
}

export interface AreaWidgetProps {
  configStep: StepConfig;
  onChange?: (changed: boolean) => void;
}

const AreaWidget = ({ configStep, onChange }: AreaWidgetProps) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSmallScreen = useCheckSmallScreen();

  const { t: tc } = useTranslation('app', { keyPrefix: 'cadasterInformation' });

  const scrollabelAreaRef = useRef<HTMLDivElement>(null);

  const [pkkError, setPkkError] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const [selectedCadasterId, selectCadasterId] = useState<string>('');
  const [meetingPointError, setMeetingPointError] = useState<string>('');

  const center = useSelector((s: State) => s[PROJ_CREATE].center);
  const feature = useSelector((s: State) => s[PROJ_CREATE].feature);
  const meetingPoint = useSelector((s: State) => s[PROJ_CREATE].meetingPoint);

  const currentTypeProject = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );

  const currentCreationStep = useSelector(
    (s: State) => s[PROJ_CREATE].currentCreationStep
  );

  const { t } = useTranslation('app', {
    keyPrefix: `creation.${currentTypeProject}.area`,
  });

  const {
    data: cadasters,
    error,
    isFetching,
  } = useGetAreaInfoByPolygonQuery(feature as Feature<Polygon>, {
    skip: !feature,
  });

  const { data: cadasterData } = useGetInfoAboutAreaByIdQuery(
    selectedCadasterId,
    { skip: !selectedCadasterId }
  );

  useEffect(() => {
    if (cadasters && cadasters.length > 0 && !isSmallScreen) {
      selectCadasterId(cadasters[0].id);
    }
    if (isSmallScreen) {
      selectCadasterId('');
    }
  }, [cadasters, isSmallScreen]);

  useEffect(() => {
    dispatch(changeCadasters(cadasters || []));
    return () => {
      changeCadasters([]);
    };
  }, [dispatch, cadasters]);

  useEffect(() => {
    dispatch(changeCadastralLayerVisibility(true));
  }, [dispatch]);

  useEffect(() => {
    if (isFetching || error) {
      setTimeoutId(
        setTimeout(() => {
          setPkkError(true);
        }, 5000)
      );
    } else {
      setPkkError(false);
      clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isFetching]);

  useEffect(() => () => clearTimeout(timeoutId), [timeoutId]);

  const BoundsListener = (): null => {
    const map = useMap();

    useEffect(() => {
      if (feature && center) {
        dispatch(changeBbox(convertBoundsToString(map.getBounds())));
        dispatch(changeSize(convertSizeToString(map.getSize())));
        dispatch(
          setDraw({
            polygon: feature,
            center,
          })
        );

        const drawBounds = feature.geometry.coordinates[0].length
          ? latLngBounds(
              feature.geometry.coordinates[0].map((pos) => [pos[1], pos[0]])
            )
          : null;
        drawBounds && map.fitBounds(drawBounds, { maxZoom: INITIAL_ZOOM });
      }

      return () => {
        dispatch(resetDraw());
      };
    }, [map]);

    return null;
  };

  const renderCadasterFields = (cadaster: Cadaster): JSX.Element => (
    <>
      {renderKeyValue(tc('number'), cadaster.number)}
      {renderKeyValue(tc('district'), cadaster.district || '-')}
      {renderKeyValue(tc('address'), cadaster.address || '-')}
      {renderKeyValue(
        tc('objectType'),
        cadaster.objectType ? tc(`objectTypes.${cadaster.objectType}`) : '-'
      )}
      {renderKeyValue(
        tc('areaType'),
        cadaster.areaType ? tc(`areaTypes.${cadaster.areaType}`) : '-'
      )}
      {renderKeyValue(
        tc('ownershipForm'),
        cadaster.ownershipForm
          ? tc(`ownershipForms.${cadaster.ownershipForm}`)
          : '-'
      )}
      {renderKeyValue(
        tc('landType'),
        cadaster.landType ? tc(`landTypes.${cadaster.landType}`) : '-'
      )}
      {renderKeyValue(tc('allowedFor'), cadaster.allowedFor || '-')}
    </>
  );

  const renderTextNoCadasterInfo = () => {
    return pkkError ? (
      t('pkkError')
    ) : (
      <p className="area-selection__no-cadaster-info-text">
        {t('nonSelectedInfo.cadaster')}
      </p>
    );
  };

  const renderCadasterInfo = () => {
    return (
      <div ref={scrollabelAreaRef} className="area-selection__cadaster-info">
        {cadasterData
          ? renderCadasterFields(cadasterData)
          : renderTextNoCadasterInfo()}
      </div>
    );
  };

  const renderArea = () => {
    const centerPos = center?.geometry.coordinates;
    const invertedCenter =
      centerPos && ([centerPos[1], centerPos[0]] as LatLngTuple);

    const renderPreview = () =>
      feature && invertedCenter ? (
        <div className="area-selection__area-map">
          <MapContainer
            className="area-selection__map"
            center={invertedCenter}
            scrollWheelZoom={false}
            doubleClickZoom={false}
            zoomControl={false}
            touchZoom={false}
            dragging={false}
            keyboard={false}
            boxZoom={false}
            tap={false}
            zoom={INITIAL_ZOOM}
          >
            <BoundsListener />
            <MapLayers layer={BACKGROUND_TYPE.SCHEME} />
            <CadasterLayer />
            <CadasterPolygons />
            <GeoJSON data={feature} />
          </MapContainer>
        </div>
      ) : null;

    const renderStub = () => (
      <div className="area-selection__area-stub">
        <p className="area-selection__area-stub-text">
          {t('nonSelectedInfo.area')}
        </p>
        <img
          src={areaExample}
          className="area-selection__area-stub-img"
          alt="area-example"
        ></img>
      </div>
    );

    return invertedCenter && feature ? renderPreview() : renderStub();
  };

  const renderMobileAreaCadasterInfo = selectedCadasterId
    ? renderCadasterInfo
    : renderArea;

  const renderCadasters = () => {
    if (pkkError && isSmallScreen) {
      return (
        <button
          onClick={() => {
            selectCadasterId('0');
          }}
          className={`area-selection__cadaster${
            selectedCadasterId === '0'
              ? ' area-selection__cadaster--selected'
              : ''
          }`}
        >
          <Icon
            className="area-selection__cadaster-icon"
            icon={IconType.Chevron}
          />
          <Icon
            className="area-selection__cadaster-icon--pkk-error"
            icon={IconType.Error}
          />
        </button>
      );
    }
    return cadasters
      ? cadasters.map((cadaster) => (
          <button
            key={cadaster.number}
            onClick={() => {
              selectCadasterId((prev) =>
                prev === cadaster.id ? '' : cadaster.id
              );
              scrollabelAreaRef.current?.scrollTo({ top: 0 });
            }}
            className={`area-selection__cadaster${
              selectedCadasterId === cadaster.id
                ? ' area-selection__cadaster--selected'
                : ''
            }`}
          >
            <p>
              <span className="area-selection__cadaster-field-name">
                {t('district')}
              </span>
              <span className="area-selection__cadaster-text--regular">
                {cadaster.number.split(':')[3]}
              </span>
            </p>
            <Icon
              className="area-selection__cadaster-icon"
              icon={IconType.Chevron}
            />
          </button>
        ))
      : !isSmallScreen && (
          <div
            className={`area-selection__cadaster area-selection__no-cadaster ${
              pkkError ? 'area-selection__cadaster_pkk-error' : null
            }`}
          >
            {pkkError ? (
              <Icon icon={IconType.Error} />
            ) : (
              <>
                <p className="area-selection__no-cadaster-text">
                  {t('nonSelectedInfo.cadasters')}
                </p>
                <Icon icon={IconType.Chevron} />
              </>
            )}
          </div>
        );
  };

  const renderKeyValue = (key: string, value: string): JSX.Element => (
    <h5 className="cadaster-information__key">
      {key}
      <span className="cadaster-information__value">{value}</span>
    </h5>
  );

  const onSelectAreaClick = () => {
    dispatch(changeAction(ACTION_TYPE.SAVE));
    dispatch(changeDrawEnable(true));
    dispatch(changeCadastralLayerVisibility(true));
    dispatch(resetDraw());
    if (id) {
      navigate(`${paths.areaMapEdit}/${id}`);
    } else {
      navigate(paths.selectAreaMap);
    }
  };

  const checkMeetingPointInput = () => {
    const trimmedMeetingPoint = meetingPoint?.trim() ?? '';

    if (trimmedMeetingPoint.length > MAX_MEETING_POINT_SYMBOLS) {
      setMeetingPointError(t(ERRORS.TOO_LONG_MEETING_POINT));
    } else {
      setMeetingPointError('');
    }
  };

  const onMeetingPointChange = (value: string) => {
    setMeetingPointError('');
    dispatch(setMeetingPoint(value));
    onChange?.(true);
  };

  const renderStageHeader = () => {
    return (
      <StageHeader
        className="area-selection__stage-header"
        header={`${isSmallScreen ? `${currentCreationStep + 1}. ` : ''}${t(
          'title'
        )}`}
      />
    );
  };

  const renderSelectButton = () => {
    return (
      <>
        <p className="area-selection__subtitle">{t('subtitle')}</p>
        <Button
          className="area-selection__select-button"
          onClick={onSelectAreaClick}
          type={BUTTON_TYPE.SECONDARY}
        >
          <Icon
            icon={IconType.Marker}
            className="area-selection__marker-icon"
          />
          {t('buttons.selectArea')}
        </Button>
      </>
    );
  };

  const renderAreaInfo = () => {
    return (
      <>
        <h3 className="area-selection__area-info-header">{t('areaInfo')}</h3>
        <div className="area-selection__info-row">
          {isSmallScreen ? renderMobileAreaCadasterInfo() : renderArea()}
          <div className="area-selection__cadasters">{renderCadasters()}</div>
          {isSmallScreen ? null : renderCadasterInfo()}
        </div>
        {feature && !isSmallScreen ? (
          <p className="area-selection__attention-text">
            <Trans
              i18nKey={`creation.${currentTypeProject}.area.attentionText`}
              components={{
                instructionLink: (
                  <Link
                    className="text-link"
                    to={paths.instruction}
                    target="_blank"
                  />
                ),
              }}
            />
          </p>
        ) : null}
      </>
    );
  };

  const renderMeetingPointInputBlock = () => {
    return (
      <div className="creation-area__input-wrapper">
        <p className="creation-area__subtitle">
          {t('meetingPointDescription')}
        </p>
        <TextInput
          value={meetingPoint ?? ''}
          maxLength={MAX_MEETING_POINT_SYMBOLS}
          className={'creation-area__input'}
          placeholder={t('meetingPointPlaceholder')}
          onChange={(e) => onMeetingPointChange(e.target.value)}
          onFocusLeave={checkMeetingPointInput}
        />
        <span
          className={`creation-area__error${
            meetingPointError ? '' : '--hidden'
          }`}
        >
          {meetingPointError}
        </span>
      </div>
    );
  };

  const renderMap = new Map<string, () => JSX.Element>([
    ['stageHeader', renderStageHeader],
    ['selectButton', renderSelectButton],
    ['areaInfo', renderAreaInfo],
    ['meetingPointInput', renderMeetingPointInputBlock],
  ]);

  return (
    <>
      <div className="creation-area">
        <div className="area-selection">
          {configStep.properties.visible.map((item) => renderMap.get(item)?.())}
        </div>
      </div>
    </>
  );
};

export default AreaWidget;
