import { useEffect, useMemo, useState } from 'react';
import {
  DayPickerProvider,
  NavigationProvider as DPNavigationProvider,
  SelectSingleEventHandler,
} from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  GetFilesResponse,
  Icon,
  IconType,
  MIME_JPEG,
  MIME_PNG,
  TextArea,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';
import { ru } from 'date-fns/locale';

import { EMPTY_EDITOR_STRING } from 'src/app/constants';
import DatePicker from 'src/shared/components/DatePicker/DatePicker';
import FileInput from 'src/shared/components/inputs/FileInput/FileInput';
import TextEditor from 'src/shared/components/TextEditor/TextEditor';
import { htmlToMarkdown, markdownToHtml } from 'src/shared/converters';
import { paths } from 'src/shared/routes';

import { useUpsertDiaryEntryMutation } from 'src/features/diary/api';
import {
  ACCEPTABLE_FILES_TYPES,
  FILES_LIMIT,
  FILES_OFFSET,
  HTML_TAG_REGEX,
  MAX_PHOTOS,
  MAX_TEXT_SYMBOLS,
  MAX_TITLE_SYMBOLS,
  MILLSECS_FACTOR,
} from 'src/features/diary/components/DiaryEntry/DiaryEntry';
import { DIARY } from 'src/features/diary/slices/diarySlice';
import { useGetFilesQuery } from 'src/features/files/api';
import { FileCategories } from 'src/features/files/enums';
import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import { useUploadFiles } from '../hooks';

import './DiaryEntryMobile.scss';

const TITLE_INITIAL_HEIGHT = 46;

/*
 * This implementation of DiaryEntryMobile is used by mobile realization.
 * All processes with diary entries on mobile are use this component.
 */
const DiaryEntryMobile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isSmallScreen = useCheckSmallScreen();

  const diary = useSelector((s: State) => s[DIARY].diary);
  const projectType = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );

  const { t } = useTranslation('app', {
    keyPrefix: `editing.${projectType}.diary`,
  });
  const { t: tfi } = useTranslation('app', {
    keyPrefix: 'fileInput.placeholders',
  });

  const {
    data: fetchedFiles,
    isFetching: filesFetching,
    isSuccess: filesSuccess,
  } = useGetFilesQuery(
    {
      entityId: diary?.id ?? '',
      category: FileCategories.DIARY_FILE,
      limit: FILES_LIMIT,
      offset: FILES_OFFSET,
    },
    { skip: !diary }
  );

  const [text, setText] = useState<string>(markdownToHtml(diary?.text ?? ''));
  const [title, setTitle] = useState<string>(diary?.title ?? '');
  const [date, setDate] = useState<Date | undefined>(
    diary?.created ? new Date(diary.created * MILLSECS_FACTOR) : undefined
  );
  const [isPickerVisible, setPickerVisible] = useState(false);
  const [titleError, setTitleError] = useState<string>('');
  const [textError, setTextError] = useState<string>('');
  const [datePickerError, setDatePickerError] = useState<string>('');
  const [files, setFiles] = useState<GetFilesResponse[]>(
    fetchedFiles?.data ?? []
  );
  const [fileError, setFileError] = useState<string>('');

  const [upsertDiary] = useUpsertDiaryEntryMutation();

  const uploadFiles = useUploadFiles(
    [MIME_JPEG, MIME_PNG],
    (files) => setFiles((prev) => [...prev, ...files]),
    setFileError
  );

  const filesOverflow = useMemo(
    () => files.length >= FILES_LIMIT,
    [files.length]
  );

  useEffect(() => {
    if (!filesFetching && filesSuccess && fetchedFiles?.data) {
      setFiles(fetchedFiles.data);
    }
  }, [filesFetching, filesSuccess, fetchedFiles?.data]);

  const checkTitleInput = () => {
    const trimmedText = title.trim();
    setTitle(trimmedText);
    const length = trimmedText.length;
    if (length) {
      setTitleError('');
    } else if (!length) {
      setTitleError(t('error.fieldEmpty'));
    }
  };

  const onTitleChange = (value: string) => {
    if (titleError) {
      setTitleError('');
    }
    setTitle(value);
  };

  const checkTextInput = () => {
    const trimmedText = text.trim();
    setText(trimmedText);
    const length = trimmedText.length;
    if (!length || EMPTY_EDITOR_STRING === trimmedText) {
      setTextError(t('error.fieldEmpty'));
    } else if (
      trimmedText.replace(HTML_TAG_REGEX, '').length > MAX_TEXT_SYMBOLS
    ) {
      setTextError(t('error.tooLongIdea'));
    } else {
      setTextError('');
    }
  };

  const onTextChange = (value: string) => {
    if (textError) {
      setTextError('');
    }
    setText(value);
  };

  const hideDatePicker = () => {
    setPickerVisible(false);
    if (!date) {
      setDatePickerError(t('error.dateNotSelected'));
    }
  };

  const onDatePick: SelectSingleEventHandler = (_, selectedDay) => {
    setDate(selectedDay);
    setPickerVisible(false);
    setDatePickerError('');
  };

  const onFiles = (files: File[]) => {
    uploadFiles(files, MAX_PHOTOS - files.length);
  };

  const onSave = () => {
    if (date && id) {
      upsertDiary({
        diaryEntryId: diary?.id,
        category: FileCategories.DIARY_FILE,
        projectId: id,
        date: date.toISOString(),
        name: title,
        comment: htmlToMarkdown(text),
        newFiles: {
          list: files.map((file) => ({ key: file.key })),
        },
      }).then(() => navigate(-1));
    }
  };

  const removePhoto = (file: GetFilesResponse) =>
    setFiles(files.filter((doc) => doc !== file));

  const renderMobileVersion = () => {
    return (
      <div className="diary-entry-creation">
        <div className="diary-entry-creation__scrollable-content">
          <div className="diary-entry-creation__pick-date-block">
            <Button
              className="diary-entry-creatio__form-button diary-entry-creation__pick-date-button"
              onClick={() => setPickerVisible(true)}
              type={BUTTON_TYPE.PRIMARY_OUTLINE}
            >
              <Icon icon={IconType.Calendar} />
              <span>
                {date ? date.toLocaleDateString(ru.code) : t('pickDate')}
              </span>
            </Button>
            <span
              className={`diary-entry-creation__error${
                !date && datePickerError ? '--active' : ''
              }`}
            >
              {datePickerError}
            </span>
          </div>

          {isPickerVisible ? (
            <DayPickerProvider initialProps={{ mode: 'single' }}>
              <DPNavigationProvider>
                <DatePicker
                  className="diary-entry-creation__date-picker"
                  selected={date}
                  onSelect={onDatePick}
                  onOutsideClick={hideDatePicker}
                />
              </DPNavigationProvider>
            </DayPickerProvider>
          ) : null}

          <div className="diary-entry-creation__title-wrapper">
            <TextArea
              className="diary-entry-creation__title"
              value={title}
              maxLength={MAX_TITLE_SYMBOLS}
              fitContent={false}
              placeholder={t('titlePlaceholder')}
              onChange={(e) => onTitleChange(e.target.value)}
              onBlur={() => checkTitleInput()}
              initialEditingHeight={TITLE_INITIAL_HEIGHT}
            />
            <span
              className={`diary-entry-creation__error${
                titleError ? '--active' : ''
              }`}
            >
              {titleError}
            </span>
          </div>

          <div className="diary-entry-creation__text-wrapper">
            <TextEditor
              className="diary-entry-creation__text"
              placeholder={t('textPlaceholder')}
              value={text}
              onChange={onTextChange}
              onBlur={() => checkTextInput()}
              maxLength={MAX_TEXT_SYMBOLS}
            />
            <span
              className={`diary-entry-creation__error${
                textError ? '--active' : ''
              }`}
            >
              {textError}
            </span>
          </div>

          <FileInput
            className="diary-entry-creation__file-input"
            onLink={() => null}
            onFiles={onFiles}
            disabled={filesOverflow}
            accept={ACCEPTABLE_FILES_TYPES}
            error={fileError}
            overflow={filesOverflow}
            placeholder={tfi('files')}
            onError={setFileError}
          />

          {files.length ? (
            <div className="diary-entry-creation__files">
              {files.map((file, index) => (
                <div
                  className="diary-entry-creation__block-file"
                  key={`block-${index}`}
                >
                  <a
                    className="diary-entry-creation__preview-file"
                    href={file.url}
                    target="_blank"
                    download
                    rel="noreferrer"
                    key={`file-${index}`}
                  >
                    <Icon icon={IconType.Docs} />
                    <span>{file.name ?? file.key}</span>
                  </a>
                  <button
                    className="diary-entry-creation__button-close"
                    key={`button-cross-${index}`}
                    onClick={() => removePhoto(file)}
                  >
                    <Icon
                      className="diary-entry-creation__icon-cross"
                      icon={IconType.Cross}
                    />
                  </button>
                </div>
              ))}
            </div>
          ) : null}
        </div>

        <div className="diary-entry-creation__buttons-block">
          <Button
            className="diary-entry-creation__control-button diary-entry-creation__cancel-button"
            onClick={() => navigate(-1)}
            type={BUTTON_TYPE.PRIMARY_OUTLINE}
          >
            {t('cancel')}
          </Button>
          <Button
            className="diary-entry-creation__control-button diary-entry-creation__save-button"
            onClick={onSave}
            type={BUTTON_TYPE.PRIMARY}
            disabled={!date || !title || !text || EMPTY_EDITOR_STRING === text}
          >
            {t('save')}
          </Button>
        </div>
      </div>
    );
  };

  return isSmallScreen && (diary || id) ? (
    renderMobileVersion()
  ) : (
    <Navigate to={`${paths.diary}/${id}`} />
  );
};

export default DiaryEntryMobile;
