import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  TextInput,
  urlRegexp,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import { MAX_UPLOAD_BYTES } from 'src/app/constants';

import './FileInput.scss';

const DEFAULT_MAX_LENGTH = 500;

interface FileInputProps {
  uploadText?: string;
  placeholder?: string;
  accept?: string;
  multiple?: boolean;
  disabled?: boolean;
  erase?: boolean;
  overflow?: boolean;
  error?: string;
  className?: string;
  maxBytes?: number;
  maxLinkLength?: number;
  buttonType?: BUTTON_TYPE;
  onLink: (link: string) => void;
  onLinkChange?: (link: string) => void;
  onFiles: (files: File[]) => void;
  onError?: (error: string) => void;
  onBlur?: (link: string) => void;
  icon?: IconType;
  fillIcon?: boolean;
  id?: string;
  notEmptyLink?: boolean;
  isRequired?: boolean;
  showMobileLinkInput?: boolean;
}

const FileInput = ({
  uploadText,
  placeholder,
  accept,
  multiple = false,
  disabled = false,
  erase = false,
  overflow = false,
  error = '',
  className,
  maxBytes = MAX_UPLOAD_BYTES,
  maxLinkLength = DEFAULT_MAX_LENGTH,
  buttonType = BUTTON_TYPE.PRIMARY,
  onLink,
  onLinkChange,
  onFiles,
  onError,
  onBlur,
  icon = IconType.Docs,
  fillIcon = false,
  id,
  notEmptyLink = false,
  isRequired = true,
  showMobileLinkInput = false,
}: FileInputProps): JSX.Element => {
  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'fileInput' });

  const fileInput = useRef<HTMLInputElement>(null);

  const [link, setLink] = useState<string>('');

  const checkLinkInput = useCallback(
    (value: string) => {
      if (value.length) {
        const isUrl = urlRegexp.test(value);
        onError?.(isUrl ? '' : t('errors.urlError'));
      } else {
        if (isRequired && notEmptyLink) {
          onError?.(t('required'));
        }
      }
    },
    [isRequired, notEmptyLink, onError, t]
  );

  const onChange = useCallback(
    (value: string) => {
      const trimmedValue = value.trim();
      onLinkChange?.(trimmedValue);
      setLink(trimmedValue);
      checkLinkInput(trimmedValue);
    },
    [checkLinkInput, onLinkChange]
  );

  const onLinkBlur = useCallback(
    (value: string) => {
      checkLinkInput(value);
      onBlur?.(value);
    },
    [checkLinkInput, onBlur]
  );

  const onFilesSubmitted = (files: FileList | null) => {
    if (!files) return [];
    if (maxBytes) {
      const _files: File[] = [];
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        if (file && file.size <= maxBytes) {
          _files.push(file);
        } else {
          onError?.(t('errors.largeFile'));
        }
      }
      onFiles(_files);
    } else {
      onFiles(Array.from(files));
    }
    fileInput.current && (fileInput.current.value = '');
  };

  const onTrigger = () => {
    onError?.('');
    fileInput.current?.click();
  };

  const onEnterDown = (key: string) => {
    if (key === 'Enter' && !error) {
      if (link.length) {
        onLink(link);
      }
      if (erase) {
        setLink('');
      }
    }
  };

  const mobileLinkInput = useMemo(() => {
    return showMobileLinkInput ? (
      <>
        <span className="file-input__label">{t('or')}</span>
        <div
          className={`file-input__text ${
            error ? 'file-input__text--error' : ''
          }`}
        >
          <TextInput
            className={`file-input__text-field--mobile ${
              error ? 'file-input__text-field--error' : ''
            }`}
            value={link}
            maxLength={maxLinkLength}
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)}
            onFocusLeave={(e) => onLinkBlur(e.target.value)}
            onFocus={() => onError?.('')}
          />
          <Button
            disabled={!link || error !== ''}
            className="file-input__submit-button"
            type={BUTTON_TYPE.TEXT}
            onClick={() => onLink(link)}
          >
            <Icon icon={IconType.Send} />
          </Button>
        </div>
      </>
    ) : null;
  }, [
    error,
    link,
    maxLinkLength,
    onChange,
    onError,
    onLink,
    onLinkBlur,
    placeholder,
    showMobileLinkInput,
    t,
  ]);

  const placeholderString = useMemo(() => {
    if (overflow) {
      return t('placeholders.fileLimit');
    } else {
      return placeholder || t('placeholders.photos');
    }
  }, [overflow, placeholder, t]);

  return (
    <div className={`file-input${className ? ` ${className}` : ''}`}>
      <div className="file-input__main">
        {isSmallScreen ? (
          <Button
            className="file-input__trigger"
            type={BUTTON_TYPE.SECONDARY}
            onClick={onTrigger}
            icon
            fill={fillIcon}
            disabled={disabled}
          >
            <Icon icon={icon ?? IconType.Docs} />
            {uploadText || t('uploadFile')}
          </Button>
        ) : (
          <Button
            className="file-input__trigger"
            type={buttonType}
            onClick={onTrigger}
            disabled={disabled}
          >
            {uploadText || t('uploadFile')}
          </Button>
        )}
        <input
          ref={fileInput}
          className="file-input__file-input"
          type="file"
          accept={accept}
          multiple={multiple}
          disabled={disabled}
          onChange={(e) => onFilesSubmitted(e.target.files)}
          id={id}
        />
        {isSmallScreen ? (
          mobileLinkInput
        ) : (
          <div className="file-input__text">
            <TextInput
              value={link}
              disabled={disabled}
              className="file-input__text-field"
              placeholder={placeholderString}
              onChange={(e) => onChange(e.target.value)}
              onFocus={() => onError?.('')}
              onFocusLeave={(e) => onLinkBlur(e.target.value)}
              onKeyDown={(e) => onEnterDown(e.key)}
              id={id}
              maxLength={maxLinkLength}
            />
            <Button
              disabled={!link || error !== ''}
              className="file-input__submit-button"
              type={BUTTON_TYPE.TEXT}
              onClick={() => onLink(link)}
            >
              <Icon icon={IconType.Send} />
            </Button>
          </div>
        )}
      </div>
      <span className={`file-input__error${error ? '--active' : ''}`}>
        {error}
      </span>
    </div>
  );
};

export default FileInput;
