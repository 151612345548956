import { ActorBaseResponse } from '@platform-for-public-places/components-library';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import {
  CreateCommentRequest,
  CreateCommentResponse,
  DeleteCommentRequest,
  GetCommentsRequest,
  GetCommentsResponse,
  UpdateCommentRequest,
  UpdateCommentResponse,
} from '../models';

export const COMMENTS_API = 'COMMENTS_API';

const commentsApi = createApi({
  reducerPath: COMMENTS_API,
  baseQuery: retry(
    fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URLS }),
    { maxRetries: 0 }
  ),
  endpoints: (builder) => ({
    createComment: builder.query<
      ActorBaseResponse<CreateCommentResponse>,
      CreateCommentRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'comment/create-comment',
          arguments: request,
        },
      }),
    }),
    getComments: builder.query<
      ActorBaseResponse<GetCommentsResponse>,
      GetCommentsRequest
    >({
      query: (request) => {
        const { archived, ...req } = request;
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-projects/get-comments-by-project-id'
              : 'comment/get-comments-by-project-id',
            arguments: req,
          },
        };
      },
    }),
    updateComment: builder.mutation<
      ActorBaseResponse<UpdateCommentResponse>,
      UpdateCommentRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'comment/update-comment',
          arguments: request,
        },
      }),
    }),
    deleteComment: builder.mutation<
      ActorBaseResponse<Record<string, never>>,
      DeleteCommentRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'comment/delete-comment',
          arguments: request,
        },
      }),
    }),
  }),
});

export const {
  useLazyCreateCommentQuery,
  useLazyGetCommentsQuery,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
} = commentsApi;

export const commentsMiddleware = commentsApi.middleware;

export default commentsApi.reducer;
