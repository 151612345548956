import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import { changeDrawHint } from 'src/features/draw/slices/drawSlice';
import { useCheckPkkAccessability } from 'src/features/hooks';
import MobileHeader from 'src/features/mobile/components/MobileHeader/MobileHeader';

import Header from '../components/Header/Header';

import './ChoiseProjectTypeLayout.scss';

export const DEFAULT_TYPE_PROJECT = 'default';

const ChoiseProjectTypeLayout = () => {
  useCheckPkkAccessability();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mobile = useCheckSmallScreen();
  const { isUninitialized, isLoading, isSuccess } = useWhoamiQuery();

  useEffect(() => {
    dispatch(changeDrawHint(t('drawTool.firstPointTooltipCreating')));
  }, [dispatch, t]);

  useEffect(() => {
    if (!isUninitialized && !isLoading && !isSuccess) {
      navigate(paths.map);
    }
  }, [navigate, isUninitialized, isLoading, isSuccess]);

  const renderBackButton = () => (
    <Button
      className="choise-project-type-layout__back"
      type={BUTTON_TYPE.TEXT_DARK}
      icon
      onClick={() => navigate(paths.map)}
    >
      <Icon icon={IconType.Arrow} />
      <span>{t('creation.backButton')}</span>
    </Button>
  );

  const renderSideNavigation = () => (
    <div className="choise-project-type-layout__back-wrapper">
      {renderBackButton()}
    </div>
  );

  const renderTopNavigation = () => (
    <MobileHeader
      title={t(`creation.type.${DEFAULT_TYPE_PROJECT}.title`)}
      path={paths.map}
    />
  );

  const renderNavigation = () =>
    mobile ? renderTopNavigation() : renderSideNavigation();

  const renderLayout = () => (
    <>
      {mobile ? null : <Header hideCreation />}
      <main className="choise-project-type-layout">
        {renderNavigation()}
        <div className="choise-project-type-layout__gate">
          <Outlet />
        </div>
      </main>
    </>
  );

  return !isUninitialized && !isLoading && isSuccess ? renderLayout() : null;
};

export default ChoiseProjectTypeLayout;
