import { useState } from 'react';

import { FakeResponse } from '@platform-for-public-places/components-library';

import { fetchMock } from 'src/shared/mock/utils';

import { ExhibitionData } from '../models';

interface FakeGetExhibitionResponse extends FakeResponse {
  data: ExhibitionData;
}

export const useGetExhibitionQuery = (
  url: string
): FakeGetExhibitionResponse => {
  const [response, setResponse] = useState<FakeGetExhibitionResponse>({
    isSuccess: false,
    isFetching: true,
    isError: false,
    data: {
      newsProjectId: '',
      currentProjects: [],
    },
  });

  if (!response.isSuccess) {
    fetchMock(url, true).then((data) =>
      setResponse({
        ...data,
        isSuccess: true,
        isFetching: false,
        isError: false,
      })
    );
  }

  return response;
};
