const PATH = 'path';
const DOMAIN = 'domain';
const EXPIRES = 'expires';
const MAX_AGE = 'max-age';
const SECURE = 'secure';

interface CookieOptions {
  path?: string;
  domain?: string;
  expires?: Date;
  maxAge?: number;
  secure?: boolean;
}

type CookieOptionValues = string | number | boolean | undefined;

const getCookieStringParam = (value: CookieOptionValues) =>
  // 'value === true' because bollean cookie options set without value
  value === true ? '' : `=${value}`;

export const getCookie = (name: string): string | undefined => {
  // A regular expression for searching for the necessary cookie by name because document.cookie is string
  const regex = new RegExp(
    `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`
  );

  const matches = document.cookie.match(regex);

  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (
  name: string,
  value: string,
  options: CookieOptions = {}
) => {
  const cookieOptions = new Map<string, CookieOptionValues>();
  cookieOptions.set(PATH, options.path || '/');
  cookieOptions.set(DOMAIN, options.domain);
  cookieOptions.set(EXPIRES, options.expires?.toUTCString());
  cookieOptions.set(MAX_AGE, options.maxAge);
  cookieOptions.set(SECURE, !!options.secure);

  let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  cookieOptions.forEach((value, key) => {
    if (value) {
      cookie = `${cookie}; ${key}${getCookieStringParam(value)}`;
    }
  });

  document.cookie = cookie;
};

export const updateCookie = (
  name: string,
  value: string,
  options: CookieOptions = {}
) => {
  setCookie(name, value, options);
};

export const deleteCookie = (name: string) => {
  setCookie(name, '', { maxAge: -1 });
};
