import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import StageHeader from 'src/features/layout/components/StageHeader/StageHeader';
import { useLazyGetSpecialistRequestQuery } from 'src/features/request/api';
import {
  BaseGetRequestResponse,
  BaseRequestData,
  SpecialistRequestBody,
} from 'src/features/request/models';
import SpecialistRequestCard from 'src/features/user/components/SpecialistRequestCard/SpecialistRequestCard';

import FormSpecialist, {
  DEFAULT_STATUS,
  RequestCardInfo,
} from './FormSpecialist';

import './BecomeSpecialist.scss';

const BecomeSpecialist = () => {
  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'profile.specialist' });

  const { data: userData } = useWhoamiQuery();
  const [specialistRequest] = useLazyGetSpecialistRequestQuery();

  const [requestExists, setRequestExists] = useState<boolean>(true);
  const [requestOpened, setRequestOpened] = useState<boolean>(false);
  const [requestData, setRequestData] = useState<
    BaseGetRequestResponse<BaseRequestData<SpecialistRequestBody>> | undefined
  >();
  const [requestCardInfo, setRequestCardInfo] = useState<RequestCardInfo>({
    status: DEFAULT_STATUS,
    date: '',
    comment: '',
  });

  useEffect(() => {
    if (userData?.data?.userID && userData?.data?.profile?.profileID) {
      specialistRequest({
        initiatorId: userData?.data?.userID,
        entityId: userData?.data?.profile?.profileID,
      }).then(({ data }) => {
        const requestData = data?.data;

        if (data?.success && requestData) {
          setRequestExists(true);
          setRequestData(requestData);
          setRequestCardInfo({
            status: requestData.request.status,
            date: requestData.request.updatedAt,
            comment: requestData.request.message,
          });
        } else {
          setRequestExists(false);
        }
      });
    }
  }, [userData, specialistRequest]);

  return (
    <div className="become-specialist-container">
      {isSmallScreen ? null : (
        <StageHeader
          header={t('tabName')}
          className="become-specialist-container__stage-header"
        />
      )}
      <h3 className="become-specialist-container__subtitle">{t('subtitle')}</h3>
      <p className="become-specialist-container__description">
        <Trans
          i18nKey="profile.specialist.description"
          components={{
            specialistsLink: (
              <Link className="text-link" to={paths.members} target="_blank" />
            ),
          }}
        />
      </p>
      {requestExists ? (
        <SpecialistRequestCard
          className="become-specialist-container__request-card"
          onButtonClick={() => setRequestOpened((prev) => !prev)}
          status={requestCardInfo.status}
          dateTime={requestCardInfo.date}
          comment={requestCardInfo.comment}
        />
      ) : (
        <p className="become-specialist-container__prompt">{t('prompt')}</p>
      )}
      {requestOpened || !requestExists ? (
        <FormSpecialist
          requestExists={requestExists}
          setRequestExists={setRequestExists}
          setRequestCardInfo={setRequestCardInfo}
          setRequestOpened={setRequestOpened}
          requestData={requestData}
        />
      ) : null}
    </div>
  );
};

export default BecomeSpecialist;
