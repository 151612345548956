import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { SpringEvent } from 'react-spring-bottom-sheet/dist/types';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';
import { Remarkable } from 'remarkable';

import { useGetInstructionsQuery } from 'src/features/instruction/api';
import { useGetPresignedUrlsQuery } from 'src/features/minIo/api';
import { PresignedUrlByKeyMapId } from 'src/features/minIo/models';
import MobileHeader from 'src/features/mobile/components/MobileHeader/MobileHeader';

import ButtonImage from './components/ButtonImage';
import remarkableLink from './components/RemarkableLink';

import './Instruction.scss';

interface InstructionData {
  title: string;
  text: string;
  points?: PointData[];
}

interface PointData {
  title: string;
  text: string;
}

type MapNumber = {
  [key: string]: number;
};

const MAIN_ID = 'MAIN_TITLE_ID';
const MAIN_HEADER_ID = 'MAIN_HEADER_ID';
const INSTRUCTIONS_KEYS = ['ppp-instructions.json'];

const VIEW_PORT_HEIGHT_BOTTOM_INTERSECTING = 0.5;
const VIEW_PORT_HEIGHT_TOP_INTERSECTING = 0.3;
const MARGIN_INTERSECTING_BOTTOM =
  window.innerHeight * VIEW_PORT_HEIGHT_BOTTOM_INTERSECTING;
const MARGIN_INTERSECTING_TOP =
  window.innerHeight * VIEW_PORT_HEIGHT_TOP_INTERSECTING;

const Instruction = (): JSX.Element => {
  const { t } = useTranslation();
  const isSmallScreen = useCheckSmallScreen();

  const mainTitleId = (mainID: string) => `${MAIN_ID}${mainID}`;
  const mainTitleIdHeader = (mainID: string) => `${MAIN_HEADER_ID}${mainID}`;
  const subTitleId = (mainId: string, subId: string) => `${mainId},${subId}`;

  const titleRef = useRef<Array<HTMLDivElement | null>>([]);

  const instructionsLink = useGetPresignedUrlsQuery({
    messageMapId: PresignedUrlByKeyMapId.INSTRUCTIONS,
    keys: INSTRUCTIONS_KEYS,
  });

  const instructions = useGetInstructionsQuery(
    instructionsLink.data?.data[0]?.url as string,
    { skip: !instructionsLink.isSuccess }
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target.id.includes(MAIN_ID)) {
              setSelectedLink(entry.target.id);
            } else if (entry.target.id.includes(MAIN_HEADER_ID)) {
              setSelectedSubLink('');
            } else {
              setSelectedSubLink(entry.target.id);
            }
          }
        });
      },
      {
        rootMargin: `-${MARGIN_INTERSECTING_TOP}px 0px -${MARGIN_INTERSECTING_BOTTOM}px 0px`,
      }
    );

    titleRef.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });
  }, [instructions]);

  const executeScroll = (key: number) =>
    titleRef.current[key]?.scrollIntoView({ behavior: 'smooth' });

  const refMapInstruction: MapNumber = {};
  // eslint-disable-next-line
  const markdown = new (Remarkable as any)();

  const [showMobileLinks, setShowMobileLinks] = useState(false);
  const [selectedLink, setSelectedLink] = useState('');
  const [selectedSubLink, setSelectedSubLink] = useState('');

  markdown.use(remarkableLink, {
    beforeLink: '',
    beforeLinkText: '',
    afterLinkText: '',
    afterLink: '',
  });
  markdown.use(ButtonImage);
  const renderInstruction = (data: InstructionData[] | undefined) => {
    let index = 0;
    const renderPoints = (data: PointData[] | undefined, mainTitle: string) => {
      return data?.map(({ title, text }) => {
        index++;
        refMapInstruction[subTitleId(mainTitle, title)] = index;
        return (
          <div
            key={subTitleId(mainTitle, title)}
            id={subTitleId(mainTitle, title)}
            ref={(el) =>
              (titleRef.current[
                refMapInstruction[subTitleId(mainTitle, title)]
              ] = el)
            }
          >
            <div
              key={subTitleId(mainTitle, title)}
              dangerouslySetInnerHTML={{
                __html: markdown.render(title),
              }}
            />
            <div
              key={title}
              dangerouslySetInnerHTML={{ __html: markdown.render(text) }}
            />
          </div>
        );
      });
    };

    return data?.map(({ title, text, points }) => {
      index++;
      refMapInstruction[mainTitleId(title)] = index;
      index++;
      refMapInstruction[mainTitleIdHeader(title)] = index;
      return (
        <div
          key={title}
          id={mainTitleId(title)}
          ref={(el) =>
            (titleRef.current[refMapInstruction[mainTitleId(title)]] = el)
          }
        >
          <div
            key={index}
            dangerouslySetInnerHTML={{ __html: markdown.render(title) }}
          />
          <div
            key={title}
            id={mainTitleIdHeader(title)}
            ref={(el) =>
              (titleRef.current[refMapInstruction[mainTitleIdHeader(title)]] =
                el)
            }
            dangerouslySetInnerHTML={{ __html: markdown.render(text) }}
          />
          {renderPoints(points, title)}
        </div>
      );
    });
  };

  const renderLinks = (data?: InstructionData[], reverted?: boolean) =>
    data?.map(({ title, points }) => {
      const buttonClassName = `instruction-link ${
        selectedLink === mainTitleId(title) ? 'instruction-link--selected' : ''
      } ${reverted ? 'instruction-link--reverted' : ''}`;

      return (
        <div key={title} className={'instruction-links'}>
          <Button
            className={buttonClassName}
            type={BUTTON_TYPE.TEXT}
            key={title}
            onClick={() => {
              executeScroll(refMapInstruction[mainTitleId(title)]);
            }}
          >
            <div
              className={
                points === undefined
                  ? 'instruction-link__no-icon'
                  : 'instruction-link__icon'
              }
            >
              <div
                className={`instruction-link__chevron${
                  selectedLink === mainTitleId(title)
                    ? ' instruction-link__chevron--active'
                    : ''
                }`}
              >
                <Icon icon={IconType.Chevron} />
              </div>
            </div>
            {title.replace(/#/gi, '')}
          </Button>
          <div
            className={`instruction-sub-links ${
              selectedLink === mainTitleId(title)
                ? 'instruction-sub-links_open'
                : ''
            }`}
          >
            {renderTableItems(points, title)}
          </div>
        </div>
      );
    });

  const renderTableItems = (
    data: PointData[] | undefined,
    mainTitle: string
  ) => {
    return data?.map(({ title }) => (
      <Button
        className={`instruction-sub-link ${
          selectedSubLink == subTitleId(mainTitle, title)
            ? 'instruction-sub-link--selected'
            : ''
        }`}
        type={BUTTON_TYPE.TEXT}
        key={subTitleId(mainTitle, title)}
        onClick={() => {
          executeScroll(refMapInstruction[subTitleId(mainTitle, title)]);
        }}
      >
        {title.replace(/#/gi, '')}
      </Button>
    ));
  };

  const getEmail = () => {
    return t('instruction.footer').replaceAll(
      'email',
      process.env.REACT_APP_SUPPORT_EMAIL
        ? process.env.REACT_APP_SUPPORT_EMAIL
        : ''
    );
  };

  const renderTableOfContent = (inputHeader: string) => (
    <div className="instruction-table-of-content">
      <div className="instuction-table-of-content__header-container">
        <h3 className="instruction-table-of-content__header">{inputHeader}</h3>
        <Button
          className="instruction-table-of-content__button-close"
          type={BUTTON_TYPE.TEXT}
          onClick={() => setShowMobileLinks(false)}
        >
          <Icon icon={IconType.Cross} />
        </Button>
      </div>
      {renderLinks(instructions.data?.data, isSmallScreen)}
    </div>
  );

  return isSmallScreen ? (
    <div className="instruction-container instruction-container--mobile">
      <MobileHeader title={t('instruction.header')} />
      <div className="instruction-middle-container">
        {renderInstruction(instructions.data?.data)}
        <div
          className="instruction-footer"
          dangerouslySetInnerHTML={{
            __html: markdown.render(t('instruction.footer')),
          }}
        ></div>
      </div>
      <Button
        className={'instruction-links__open-button'}
        type={BUTTON_TYPE.COMMENTARY}
        onClick={() => setShowMobileLinks(true)}
      >
        {t('instruction.openTableOfContent')}
      </Button>
      <BottomSheet
        className="instruction-links__bottom-sheet"
        open={showMobileLinks}
        blocking={false}
        onDismiss={() => setShowMobileLinks(false)}
        scrollLocking
        onSpringEnd={(event: SpringEvent) => {
          if (event.type === 'CLOSE' && setShowMobileLinks) {
            setShowMobileLinks(false);
          }
        }}
      >
        {renderTableOfContent(t('instruction.tableOfContent'))}
      </BottomSheet>
    </div>
  ) : (
    <div className="instruction-container">
      <div className="instruction-left-container">
        {renderTableOfContent(t('instruction.linksHeader'))}
      </div>
      <div className="instruction-middle-container">
        {renderInstruction(instructions.data?.data)}
        <div
          className="instruction-footer"
          dangerouslySetInnerHTML={{
            __html: markdown.render(getEmail()),
          }}
        ></div>
      </div>

      <div className="instruction-right-container" />
    </div>
  );
};

export default Instruction;
