import { ReactElement } from 'react';
import { TileLayer, useMap } from 'react-leaflet';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { BACKGROUND_TYPE } from 'src/features/map/enums';

const GOOGLE_ATTRIBUTION = 'Map data ©2022 Google';
const GOOGLE_TILE_LINK = 'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}';

const OSM_COPY = 'https://www.openstreetmap.org/copyright';
const OSM_TILE_LINK = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
const OSM_ATTRIBUTION = `&copy; <a href="${OSM_COPY}">OpenStreetMap</a> contributors`;

interface MapLayersProps {
  layer: BACKGROUND_TYPE;
}

const AttributionPositionControl = () => {
  const map = useMap();
  const isSmallScreen = useCheckSmallScreen();

  if (isSmallScreen) {
    map.attributionControl.setPosition('topright');
  }

  return null;
};

const MapLayers = ({ layer }: MapLayersProps): ReactElement => {
  const Scheme = (
    <TileLayer
      key={BACKGROUND_TYPE.SCHEME}
      url={OSM_TILE_LINK}
      attribution={OSM_ATTRIBUTION}
      maxZoom={20}
      maxNativeZoom={19}
    />
  );

  const Satellite = (
    <TileLayer
      key={BACKGROUND_TYPE.SATELLITE}
      url={GOOGLE_TILE_LINK}
      attribution={GOOGLE_ATTRIBUTION}
      subdomains={['mt1', 'mt2', 'mt3']}
      maxZoom={20}
      maxNativeZoom={19}
    />
  );

  const LAYER_COMPONENTS = {
    [BACKGROUND_TYPE.SCHEME]: Scheme,
    [BACKGROUND_TYPE.SATELLITE]: Satellite,
  };

  return (
    <>
      <AttributionPositionControl />
      {LAYER_COMPONENTS[layer]}
    </>
  );
};

export default MapLayers;
