import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  MobileHeaderMap,
  useCheckSmallScreen,
  useGetMobileHeader,
} from '@platform-for-public-places/components-library';

import { paths, routes } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import { USER_ROLE } from 'src/features/auth/models';
import MobileHeader from 'src/features/mobile/components/MobileHeader/MobileHeader';
import { MODALS } from 'src/features/modal/models';

import SideMenu from './components/SideMenu/SideMenu';
import { EXCLUDED_PATHS } from './consts';
import Header from '../components/Header/Header';

import './ProfileLayout.scss';

const HEADER_MAP: MobileHeaderMap = [
  { route: routes.profile.info, key: 'profile.info.tabName' },
  { route: routes.profile.projects, key: 'profile.projects.tabName' },
  { route: routes.profile.subscriptions, key: 'profile.subscriptions.tabName' },
  { route: routes.profile.becomeSpecialist, key: 'profile.specialist.tabName' },
  { route: routes.profile.signIn, key: 'signIn.header' },
  { route: routes.profile.signUp, key: 'signUp.header' },
  { route: routes.profile.confirmed, key: 'signIn.header' },
  { route: routes.profile.confirm, key: 'confirmEmail.header' },
  { route: routes.profile.forgotPassword, key: 'forgotPassword.header' },
  { route: routes.profile.participations, key: 'creation.tablesHeader' },
  {
    route: routes.profile.forgotPasswordSent,
    key: 'forgotPasswordSent.header',
  },
  {
    route: routes.profile.recover,
    key: 'recoveryPassword.header',
  },
  {
    route: routes.profile.recoverSuccess,
    key: 'recoveryPasswordSuccess.header',
  },
  {
    route: routes.profile.autopaymentsManagement,
    key: 'autopaymentsManagement.header',
  },
  {
    route: routes.profile.autopaymentsManagementSent,
    key: 'autopaymentsManagementSent.header',
  },
  {
    route: routes.payments.all,
    key: 'profile.payments.all.header',
  },
  {
    route: routes.payments.recurrent,
    key: 'profile.payments.recurrent.header',
  },
  {
    route: routes.profile.payments,
    key: 'profile.payments.header',
  },
];

interface ProfileLayoutProps {
  className?: string;
  hideHeader?: boolean;
  hideHeaderBack?: boolean;
}

export const ProfileLayout = ({
  className = '',
  hideHeader = false,
  hideHeaderBack = false,
}: ProfileLayoutProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSmallScreen = useCheckSmallScreen();

  const header = useGetMobileHeader(HEADER_MAP, 'profile.title');

  const whoami = useWhoamiQuery();

  useEffect(() => {
    const conditions = [{ role: USER_ROLE.ADMIN }, { role: USER_ROLE.USER }];
    if (!whoami.isLoading) {
      if (whoami.isSuccess) {
        if (
          !conditions.some(
            (condition) =>
              !condition.role || condition.role === whoami.data?.data.role
          )
        ) {
          navigate(paths.map, {
            replace: true,
            state: { modalState: MODALS.SIGN_IN },
          });
        }
      } else if (whoami.isError && !isSmallScreen) {
        navigate(paths.map, {
          replace: true,
          state: { modalState: MODALS.SIGN_IN },
        });
      } else if (
        isSmallScreen &&
        !EXCLUDED_PATHS.find((e) => location.pathname.endsWith(e))
      ) {
        navigate(paths.profile);
      }
    }
  }, [
    whoami.data,
    whoami.isSuccess,
    whoami.isLoading,
    whoami.isError,
    navigate,
    isSmallScreen,
  ]);

  return isSmallScreen ? (
    <>
      <main className="profile-layout">
        {hideHeader ? null : (
          <MobileHeader title={t(header)} hideBackButton={hideHeaderBack} />
        )}
        <div className={`profile-layout__gate ${className}`}>
          <Outlet />
        </div>
      </main>
    </>
  ) : (
    <>
      <Header />
      <main className="profile-layout">
        <SideMenu />
        <div className={`profile-layout__gate ${className}`}>
          <Outlet />
        </div>
      </main>
    </>
  );
};

export default ProfileLayout;
