import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  AvailableStatusesResponse,
  Button,
  BUTTON_TYPE,
  Status,
  StatusOption,
} from '@platform-for-public-places/components-library';

import Selector from 'src/shared/components/selectors/Selector/Selector';

import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import { useSetStatusMutation } from 'src/features/status/api';
import { State } from 'src/features/store/store';

import './StatusEditor.scss';

interface StatusEditorProps {
  statuses: Status[];
  availableStatuses: AvailableStatusesResponse;
}

const StatusEditor = ({
  statuses,
  availableStatuses,
}: StatusEditorProps): JSX.Element => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [statusValue, setStatusValue] = useState<StatusOption>();

  const currentTypeProject = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );

  const [setStatus] = useSetStatusMutation();

  const options = availableStatuses.flowSteps
    .filter((step) => !step.disabled)
    .map((flowStep) => ({
      label: t(
        `cards.overview.projectStatus.${currentTypeProject}.${flowStep.name}`
      ),
      name: flowStep.name,
      value: flowStep.order,
    }));

  useEffect(() => {
    if (!statusValue) {
      setStatusValue(
        options?.find(
          (option) => option.name === statuses[statuses.length - 1]?.value
        )
      );
    }
  }, [statuses, availableStatuses, options, statusValue]);

  const onSave = () => {
    if (id && statusValue) {
      setStatus({
        projectId: id,
        order: statusValue.value,
      });
    }
  };

  const onCancel = () => {
    setStatusValue(
      options?.find(
        (option) => option.name === statuses[statuses.length - 1].value
      )
    );
  };

  return (
    <div className="status-editor">
      <label className="status-editor__dropdown-label">
        {t(`editing.${currentTypeProject}.status.changeStatus`)}
        <Selector
          className="status-editor__dropdown"
          defaultOptions={options}
          value={statusValue}
          onChange={(value) => setStatusValue(value as StatusOption)}
        />
      </label>
      <div className="status-editor__buttons">
        <Button
          className="status-editor__save-button"
          onClick={onSave}
          type={BUTTON_TYPE.PRIMARY}
        >
          {t(`editing.${currentTypeProject}.status.buttons.save`)}
        </Button>
        <Button
          className="status-editor__cancel-button"
          onClick={onCancel}
          type={BUTTON_TYPE.TEXT_SECONDARY}
        >
          {t(`editing.${currentTypeProject}.status.buttons.cancel`)}
        </Button>
      </div>
    </div>
  );
};

export default StatusEditor;
