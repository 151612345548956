import { ReactNode, useState } from 'react';
import {
  ActionMeta,
  GroupBase,
  OnChangeValue,
  OptionsOrGroups,
  PropsValue,
} from 'react-select';
import Select from 'react-select/async';

import './Selector.scss';

interface SelectProps {
  value?: PropsValue<unknown>;
  placeholder?: ReactNode;
  className?: string;
  defaultOptions?: OptionsOrGroups<unknown, GroupBase<unknown>> | boolean;
  onChange: (
    newValue: OnChangeValue<unknown, boolean>,
    actionMeta: ActionMeta<unknown>
  ) => void;
  sorting?: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isMenuChanging?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}

const Selector = ({
  value,
  defaultOptions,
  placeholder,
  className,
  onChange,
  onBlur,
  sorting = false,
  isMulti = false,
  isClearable = false,
  isSearchable = false,
  isMenuChanging = false,
  onFocus,
}: SelectProps): JSX.Element => {
  const name = sorting ? 'sorting-selector' : 'selector';
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  return (
    <Select
      classNamePrefix={name}
      className={`${name}__dropdown ${className}`}
      placeholder={placeholder}
      defaultOptions={defaultOptions}
      value={value}
      onFocus={() => {
        onFocus && onFocus();
        setMenuIsOpen(isMenuChanging);
      }}
      onBlur={onBlur}
      isMulti={isMulti}
      isClearable={isClearable}
      onChange={onChange}
      onMenuClose={() => setMenuIsOpen(isMenuChanging)}
      onMenuOpen={() => {
        onFocus && onFocus();
        setMenuIsOpen(true);
      }}
      menuIsOpen={menuIsOpen}
      isSearchable={isSearchable}
    />
  );
};

export default Selector;
