import { useDispatch } from 'react-redux';

import Box from 'src/shared/components/Box/Box';

import { setSearchPanelState } from 'src/features/control/slices/controlSlice';
import { SearchPanelState } from 'src/features/map/components/SearchPane/components/SearchFilterInput/SearchFilterInput';
import { StatusFilterMobile } from 'src/features/searchWithFilter/filter/searchFilters/SearchFilters';

export const StatusFilterMobileControls = () => {
  const dispatch = useDispatch();
  return (
    <Box gap={'0.5em'}>
      <StatusFilterMobile
        onSelectFilter={() =>
          dispatch(setSearchPanelState(SearchPanelState.OPEN))
        }
      />
    </Box>
  );
};
