import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';

import i18n from 'src/app/translation/translation';

import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import {
  useLazyGetEventParticipantsQuery,
  useLazyGetProjectTeamQuery,
} from 'src/features/project/api';
import {
  PROJ_EDIT,
  setMemberToAdd,
} from 'src/features/project/slices/editingProjectSlice';
import { PROJ_LAYER } from 'src/features/project/slices/projectsLayerSlice';
import {
  FILTER_DATA,
  setSelectedUserId,
} from 'src/features/searchWithFilter/filter/slices/filterSliceData';
import { State } from 'src/features/store/store';
import {
  ProjectSpecialist,
  ProjectSpecialists,
} from 'src/features/user/models';

import {
  TEAM_DEFAULT_PAGE_SIZE,
  TEAM_FIRST_PAGE_NUMBER,
  TEAM_PAGE_SIZE,
  TEAM_PAGE_SIZE_MOBILE,
} from 'src/pages/project/constants';

import './TeamMembersList.scss';

export enum TYPE_RENDER {
  PREVIEW_MEMBER_WITH_BUTTON_SHOW_MORE = 'PREVIEW_MEMBER_WITH_BUTTON_SHOW_MORE',
  HIDE_TEAM_MEMBERS = 'HIDE_TEAM_MEMBERS',
  SHOW_ALL_TEAM = 'SHOW_ALL_TEAM',
}

export interface TeamMembersListProps {
  archived?: boolean;
  projectId: string;
  isAdmin: boolean;
  isSmallScreen: boolean;
  displayInitiator?: boolean;
  isInitiatorProjectOwner: boolean;
  initiatorName?: string;
  initiatorId?: string;
  onUserClick?: (userId: string) => void;
  emptyTeamLabel?: string;
  memberButtonComponent?: (memberId: string) => JSX.Element;
  memberContainerClassName?: string;
  hideTeamMembers?: boolean;
  typeRender: TYPE_RENDER;
  previewTeamMembers: boolean;
  previewMemberIdByRedirect?: string;
}

const TeamMembersList = ({
  archived = false,
  projectId,
  isAdmin,
  isSmallScreen,
  displayInitiator = true,
  isInitiatorProjectOwner,
  initiatorName,
  initiatorId,
  onUserClick,
  emptyTeamLabel,
  memberButtonComponent,
  memberContainerClassName,
  hideTeamMembers = false,
  typeRender,
  previewTeamMembers,
  previewMemberIdByRedirect = '',
}: TeamMembersListProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('app', { keyPrefix: 'detailedProject' });

  const [wasMemberOpenByRedirect, setWasMemberOpenByRedirect] =
    useState<boolean>(false);
  const memberToAdd = useSelector((s: State) => s[PROJ_EDIT].memberToAdd);
  const deletedUserId = useSelector(
    (s: State) => s[FILTER_DATA].selectedUserId
  );

  const [teamPage, setTeamPage] = useState<number>(TEAM_FIRST_PAGE_NUMBER);
  const [hasTeamNextPage, setHasTeamNextPage] = useState<boolean>(true);
  const [teamError, setTeamError] = useState<boolean>(false);
  const [teamLoading, setTeamLoading] = useState<boolean>(false);
  const [teamMore, setTeamMore] = useState(false);
  const [team, setTeam] = useState<ProjectSpecialists>({
    specialists: [],
    count: -1,
  });

  const countOfJoined = useSelector((s: State) => s[PROJ_LAYER].countOfJoined);

  const [getTeam] = useLazyGetProjectTeamQuery();
  const [getParticipants] = useLazyGetEventParticipantsQuery();

  useEffect(() => {
    if (projectId && !hideTeamMembers && hasTeamNextPage) {
      getTeam({
        projectId: projectId,
        page: teamPage,
        pageSize: isSmallScreen ? TEAM_PAGE_SIZE_MOBILE : TEAM_PAGE_SIZE,
        archived,
      }).then((value) => {
        setTeamPage(teamPage + 1);
        setTeam((prev) => value.data?.data ?? prev);
        setHasTeamNextPage(
          value.data?.data?.specialists?.length ===
            (isSmallScreen ? TEAM_PAGE_SIZE_MOBILE : TEAM_PAGE_SIZE)
        );
      });
    }
  }, [
    projectId,
    dispatch,
    countOfJoined,
    archived,
    getParticipants,
    getTeam,
    hasTeamNextPage,
    isSmallScreen,
    hideTeamMembers,
    teamPage,
  ]);

  useEffect(() => {
    if (memberToAdd) {
      setTeam((prev) => ({
        ...prev,
        specialists: [...prev.specialists, memberToAdd],
        count: prev.count + 1,
      }));
      dispatch(setMemberToAdd(null));
    }
  }, [dispatch, memberToAdd]);

  useEffect(() => {
    setTeam((prev) => {
      const specialists = prev.specialists?.filter(
        (specialist) => specialist.id !== deletedUserId
      );
      return {
        specialists: specialists,
        count: specialists.length,
      };
    });
  }, [deletedUserId, dispatch]);

  const onMemberClick = useCallback(
    (userId: string | undefined) => {
      dispatch(setSelectedUserId(userId ?? ''));
      if (onUserClick && isSmallScreen) {
        onUserClick(userId ?? '');
      } else {
        dispatch(changeModal(MODALS.SPECIALIST_USER_CARD));
      }
    },
    [dispatch, onUserClick, isSmallScreen]
  );

  useEffect(() => {
    if (!wasMemberOpenByRedirect && previewMemberIdByRedirect) {
      setWasMemberOpenByRedirect(true);
      onMemberClick(previewMemberIdByRedirect);
    }
  }, [onMemberClick, previewMemberIdByRedirect, wasMemberOpenByRedirect]);

  const renderMemberWithButton = useCallback(
    (memberButton: JSX.Element, projectSpecialistId?: string) => (
      <div className={`member__container ${memberContainerClassName ?? ''}`}>
        {memberButton}
        {memberButtonComponent && projectSpecialistId
          ? memberButtonComponent(projectSpecialistId as string)
          : null}
      </div>
    ),
    [memberButtonComponent, memberContainerClassName]
  );

  const renderPerson = useCallback(
    (
      role: string,
      name: string,
      index?: number,
      userId?: string,
      projectSpecialistId?: string
    ) => {
      const memberButton = (
        <Button
          key={index}
          className="member__block"
          type={BUTTON_TYPE.TEXT_SECONDARY}
          onClick={() => onMemberClick(userId)}
        >
          {typeRender === TYPE_RENDER.HIDE_TEAM_MEMBERS ? (
            <div className="members__mobile-container">
              <p className="members__mobile-container-name">{name}</p>
              <p className="members__mobile-container-role">{role}</p>
            </div>
          ) : (
            <p className="member__name">
              <Trans
                i18nKey={'detailedProject.teamMember'}
                values={{
                  name: name,
                  type: role,
                }}
                components={{
                  span: (
                    <span
                      className={`member__name_clickable${
                        isSmallScreen ? '-reverted' : ''
                      }`}
                    />
                  ),
                }}
              />
            </p>
          )}
        </Button>
      );
      return memberButtonComponent
        ? renderMemberWithButton(memberButton, projectSpecialistId)
        : memberButton;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, isAdmin, team]
  );

  const renderTags = useCallback(
    (person: ProjectSpecialist) => {
      if (!person?.roles && !person?.tags) {
        return '';
      }

      const tagsNames = person?.tags?.map((value) => value.name);

      const stringRoles = person?.roles
        ? person?.roles.map((data) => data as string)
        : [];

      return stringRoles
        .concat(tagsNames ?? [])
        .reduce((prev: string, value, index) => {
          const valueLowerCase = value ? value.toLowerCase() : '';
          return prev
            .concat(index > 0 ? ', ' : '')
            .concat(
              i18n.exists(`detailedProject.${valueLowerCase}`)
                ? t(valueLowerCase)
                : value
            );
        }, '');
    },
    [t]
  );

  const renderTeam = useCallback(() => {
    const isEmptyTeam = !isInitiatorProjectOwner && team.count === 0;

    if (isEmptyTeam) {
      return (
        <p className="members__empty-tag">{emptyTeamLabel ?? t('emptyTeam')}</p>
      );
    } else if (previewTeamMembers && !teamMore) {
      let agreedPerson = 0;
      return team.specialists
        ?.filter((value) => {
          if (agreedPerson < TEAM_DEFAULT_PAGE_SIZE) {
            agreedPerson += 1;
            return value;
          }
        })
        .map((value, index) =>
          renderPerson(
            renderTags(value),
            value.name,
            index,
            value.userId,
            value.id
          )
        );
    } else {
      return team.specialists.map((value, index) =>
        renderPerson(
          renderTags(value),
          value.name,
          index,
          value.userId,
          value.id
        )
      );
    }
  }, [
    emptyTeamLabel,
    isInitiatorProjectOwner,
    previewTeamMembers,
    renderPerson,
    renderTags,
    t,
    team.count,
    team.specialists,
    teamMore,
  ]);

  const renderCollapseAndExpandButton = useCallback(() => {
    if (team.specialists.length > TEAM_DEFAULT_PAGE_SIZE) {
      return (
        <Button
          className={`members__button ${
            teamMore ? 'members__button--open' : ''
          }`}
          type={BUTTON_TYPE.TEXT_DARK}
          onClick={() => {
            setTeamMore((prev) => !prev);
          }}
          icon
          fill
        >
          {t(teamMore ? 'buttonLess' : 'buttonMore')}
          <Icon icon={IconType.Chevron} />
        </Button>
      );
    }
  }, [team, teamMore, t]);

  const [userCatalog] = useInfiniteScroll({
    loading: teamLoading,
    hasNextPage: hasTeamNextPage,
    onLoadMore: () => {
      setTeamLoading(true);
      getTeam({
        projectId: projectId,
        page: teamPage,
        pageSize: isSmallScreen ? TEAM_PAGE_SIZE_MOBILE : TEAM_PAGE_SIZE,
        archived,
      })
        .then((value) => {
          if (value.data?.data) {
            const addData = (prev: ProjectSpecialists): ProjectSpecialists => {
              const newSpecialist = value?.data?.data?.specialists;
              const newCount = value?.data?.data?.count;
              return {
                specialists: [...prev.specialists, ...(newSpecialist ?? [])],
                count: newCount ?? 0,
              };
            };
            setTeam((prev) => addData(prev));
            setTeamPage(teamPage + 1);
            setHasTeamNextPage(
              value.data?.data?.specialists?.length ===
                (isSmallScreen ? TEAM_PAGE_SIZE_MOBILE : TEAM_PAGE_SIZE)
            );
          }
        })
        .catch(() => setTeamError(true))
        .finally(() => setTeamLoading(false));
    },
    disabled: teamError,
  });

  const conditionInvokeInfinityScroll = () => {
    if (typeRender === TYPE_RENDER.PREVIEW_MEMBER_WITH_BUTTON_SHOW_MORE) {
      return team.specialists.length < TEAM_DEFAULT_PAGE_SIZE || teamMore;
    } else if (typeRender === TYPE_RENDER.HIDE_TEAM_MEMBERS) {
      return !hideTeamMembers;
    } else return true;
  };

  return (
    <>
      <div
        className={`members__button-list ${
          teamMore ? 'members__button-list--open' : ''
        }`}
      >
        {displayInitiator
          ? renderPerson(
              t('initiator'),
              `${initiatorName || ''} ${
                isInitiatorProjectOwner ? t('you') : ''
              }`,
              initiatorId ? +initiatorId : undefined,
              initiatorId
            )
          : null}
        {hideTeamMembers ? null : renderTeam()}
        {hasTeamNextPage && conditionInvokeInfinityScroll() ? (
          <div className="user-container__loading-trigger" ref={userCatalog} />
        ) : null}
      </div>
      {typeRender === TYPE_RENDER.PREVIEW_MEMBER_WITH_BUTTON_SHOW_MORE
        ? renderCollapseAndExpandButton()
        : null}
    </>
  );
};

export default TeamMembersList;
