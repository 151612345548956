import { ReactNode, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  GetFilesResponse,
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import { COMPRESSOR_URL_A, COMPRESSOR_URL_B } from 'src/app/constants';
import FileInput from 'src/shared/components/inputs/FileInput/FileInput';

import { useUploadFileFromLink, useUploadFiles } from 'src/pages/project/hooks';

import './DocsUploader.scss';

export interface DocsUploaderProps {
  max: number;
  title: string;
  files: GetFilesResponse[];
  className?: string;
  description?: ReactNode;
  accept?: string[];
  onUpload: (files: GetFilesResponse[]) => void;
  onDelete: (file: GetFilesResponse) => void;
}

const DocsUploader = ({
  max,
  title,
  files,
  className = '',
  description,
  accept = [],
  onUpload,
  onDelete,
}: DocsUploaderProps) => {
  const mobile = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'fileInput.placeholders' });

  const [error, setError] = useState<string>('');

  const acceptString = useMemo(() => accept.join(', '), [accept]);
  const filesOverflow = useMemo(() => files.length >= max, [files.length, max]);

  const uploadFiles = useUploadFiles(accept, onUpload, setError);

  const onUploadFromLink = (file: GetFilesResponse) => onUpload([file]);

  const uploadFileFromLink = useUploadFileFromLink(
    accept,
    onUploadFromLink,
    setError
  );

  const onFiles = (files: File[]) => uploadFiles(files, max - files.length);

  const onLink = (link: string) => {
    if (!filesOverflow) {
      uploadFileFromLink(link);
    }
  };

  const renderPreview = (f: GetFilesResponse) => (
    <div key={`${f.key}`} className="docs-uploader__preview-block">
      <Icon icon={IconType.Docs} />
      <span className="preview-block__name">{f.name ?? f.url}</span>
      <button className="preview-block__cross" onClick={() => onDelete(f)}>
        <Icon icon={IconType.Cross} />
      </button>
    </div>
  );

  const createLink = (href: string) => (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a className="text-link" rel="noreferrer" target="_blank" href={href} />
  );

  const hint =
    mobile && files.length ? null : (
      <p className="docs-uploader__hint">
        <Trans
          i18nKey="fileInput.hints.docsHint"
          components={{
            linkA: createLink(COMPRESSOR_URL_A),
            linkB: createLink(COMPRESSOR_URL_B),
            accent: <span className="docs-uploader__hint-accent" />,
          }}
        />
      </p>
    );

  return (
    <div className={`docs-uploader ${className}`}>
      <h3 className="docs-uploader__title">{title}</h3>

      {description ? (
        <p className="docs-uploader__description">{description}</p>
      ) : null}

      <FileInput
        erase
        multiple
        accept={acceptString}
        error={error}
        disabled={filesOverflow}
        overflow={filesOverflow}
        placeholder={t('files')}
        onLink={onLink}
        onFiles={onFiles}
        onError={setError}
      />
      {hint}
      <div className="docs-uploader__preview-wrapper">
        {files.length ? (
          <div className="docs-uploader__preview">
            {files.map((f) => renderPreview(f))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DocsUploader;
