import { ActorBaseResponse } from '@platform-for-public-places/components-library';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import {
  ConfirmPasswordRecoveryRequest,
  ConfirmSignUpRequest,
  GetPasswordRecoveryLinkRequest,
  SignInRequest,
  SignUpRequest,
  VerifyCaptchaRequest,
  VerifyCaptchaResponse,
  WhoamiResponse,
} from '../models';

export const AUTH_API = 'AUTH_API';

const authApi = createApi({
  reducerPath: AUTH_API,
  baseQuery: retry(
    fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URLS }),
    { maxRetries: 0 }
  ),
  endpoints: (builder) => ({
    signIn: builder.query<ActorBaseResponse<void>, SignInRequest>({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'sign-in',
          arguments: request,
        },
      }),
    }),
    signUp: builder.query<ActorBaseResponse<void>, SignUpRequest>({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'sign-up',
          arguments: request,
        },
      }),
    }),
    confirmSignUp: builder.query<ActorBaseResponse<void>, ConfirmSignUpRequest>(
      {
        query: (request) => ({
          method: 'POST',
          body: {
            messageMapId: 'confirm-sign-up',
            arguments: request,
          },
        }),
      }
    ),
    whoami: builder.query<ActorBaseResponse<WhoamiResponse>, void>({
      query: () => ({
        method: 'POST',
        body: { messageMapId: 'whoami' },
      }),
      keepUnusedDataFor: 0,
    }),
    logout: builder.query<ActorBaseResponse<void>, void>({
      query: () => ({
        method: 'POST',
        body: { messageMapId: 'logout' },
      }),
      keepUnusedDataFor: 0,
    }),
    getPasswordRecoveryLink: builder.mutation<
      ActorBaseResponse<void>,
      GetPasswordRecoveryLinkRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'get-password-recovery-link',
          arguments: request,
        },
      }),
    }),
    confirmPasswordRecovery: builder.mutation<
      ActorBaseResponse<void>,
      ConfirmPasswordRecoveryRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'confirm-password-recovery',
          arguments: request,
        },
      }),
    }),
    verifyCaptcha: builder.query<
      ActorBaseResponse<VerifyCaptchaResponse>,
      VerifyCaptchaRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'captcha/verify',
          arguments: request,
        },
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useLazySignInQuery,
  useLazySignUpQuery,
  useLazyConfirmSignUpQuery,
  useLazyWhoamiQuery,
  useWhoamiQuery,
  useLazyLogoutQuery,
  useGetPasswordRecoveryLinkMutation,
  useConfirmPasswordRecoveryMutation,
  useLazyVerifyCaptchaQuery,
} = authApi;

export const authMiddleware = authApi.middleware;

export default authApi.reducer;
