import { LegacyRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import {
  ListType,
  ListUser,
} from 'src/shared/components/SearchDropdown/models';
import SearchDropdown from 'src/shared/components/SearchDropdown/SearchDropdown';
import Selector from 'src/shared/components/selectors/Selector/Selector';

import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import {
  FILTER_DATA,
  setTeam,
} from 'src/features/searchWithFilter/filter/slices/filterSliceData';
import { State } from 'src/features/store/store';
import {
  ProjectSpecialist as ProjectSpecialist,
  UserType,
} from 'src/features/user/models';

interface SearchDropdownProps {
  user: ListUser;
  error: boolean;
  setError: (val: boolean) => void;
  id: string;
  inref?: LegacyRef<HTMLDivElement>;
  scrollToUser?: () => void;
  setMarginLastElement: (val: boolean) => void;
  marginLastElement: boolean;
}

const ProjectUserList = ({
  user,
  id,
  inref,
  scrollToUser,
  setMarginLastElement,
  marginLastElement,
  error: globalError,
  setError: setGlobalError,
}: SearchDropdownProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSmallScreen = useCheckSmallScreen();

  const [type, setType] = useState<ListType>();
  const [error, setError] = useState<boolean>(false);
  const [participant, setParticipant] = useState<ListUser>();

  const projectType = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );

  const team = useSelector((s: State) => s[FILTER_DATA].team);

  useEffect(() => {
    if (user.data.name) {
      setParticipant(user);
    } else {
      setParticipant(undefined);
    }
    if (user.data.roles !== type) {
      if (user.data.roles) {
        setType({
          label: t(`userCatalog.tags.${user.data.roles[0]}`),
          value: [user.data.roles[0]],
        });
      } else {
        setType(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, user]);

  useEffect(() => {
    if (globalError) {
      if (!type || !participant) {
        setError(true);
      }
    }
  }, [globalError, participant, type]);

  useEffect(() => {
    if (type && participant) {
      setError(false);
    }
  }, [participant, team, type]);

  const specialistTypes = useMemo(
    () => [
      {
        label: t('userCatalog.tags.developer'),
        value: [UserType.developer],
      },
      {
        label: t('userCatalog.tags.implementer'),
        value: [UserType.implementer],
      },
    ],
    [t]
  );

  const deleteUser = () => {
    const users = team.filter((value) => {
      if (value.id !== id) {
        return value;
      }
    });
    setGlobalError(false);
    setError(false);
    dispatch(setTeam(users as ProjectSpecialist[]));
  };

  const updateUser = (incomingUser: ListUser) => {
    const users = team.map((value) => {
      if (value.id === id) {
        return {
          ...incomingUser.data,
          roles: user.data.roles,
          id: user.data.id,
        };
      }
      return value;
    });

    dispatch(setTeam(users as ProjectSpecialist[]));
  };

  const updateRole = (incomingRole: UserType | undefined) => {
    const users = team.map((value) => {
      if (value.id === id) {
        return { ...value, roles: [incomingRole] };
      }
      return value;
    });

    dispatch(setTeam(users as ProjectSpecialist[]));
  };

  const scroll = () => {
    setMarginLastElement(true);
    scrollToUser?.();
  };

  const isError = (value: ListType | ListUser | undefined): boolean => {
    if (error) {
      return !value;
    }
    return false;
  };

  const renderErrorText = (
    errorText: string,
    value: ListType | ListUser | undefined
  ) => {
    return (
      <p
        className={`selector__error-text ${
          error ? 'selector__error-text--visible' : ''
        } ${isError(value) ? 'selector__error-text--error' : ''}`}
      >
        {errorText}
      </p>
    );
  };

  const renderTypeSelector = () => (
    <div className="dropdown-container">
      <Selector
        defaultOptions={specialistTypes}
        value={type || ''}
        onFocus={scroll}
        onBlur={() => setMarginLastElement(false)}
        placeholder={t(`creation.${projectType}.team.placeholderRole`)}
        onChange={(v) => {
          const value = v as ListType;
          updateRole(value ? (value.value[0] as UserType) : undefined);
          setType(value);
          setMarginLastElement(false);
          setGlobalError(false);
        }}
        className={`selector__dropdown search-dropdown__selector ${
          isError(type) ? 'selector__error' : ''
        }`}
      />
      {renderErrorText(t(`creation.${projectType}.team.errorType`), type)}
    </div>
  );

  const renderSearchDropdown = () => (
    <div className="dropdown-container">
      <SearchDropdown
        participant={participant}
        onFocus={scroll}
        onBlur={() => setMarginLastElement(false)}
        setParticipant={(val) => {
          updateUser(val);
          setParticipant(val);
          setMarginLastElement(false);
          setGlobalError(false);
        }}
        className={`${isError(participant) ? 'selector__error' : ''}`}
      />
      {renderErrorText(
        t(`creation.${projectType}.team.errorSpecialist`),
        participant
      )}
    </div>
  );

  return isSmallScreen ? (
    <div
      className={`search-dropdown__container ${
        marginLastElement ? 'search-dropdown__container--margin' : ''
      }`}
      ref={inref}
    >
      {renderSearchDropdown()}

      <div className="search-dropdown__type-delete">
        {renderTypeSelector()}
        <Button
          type={BUTTON_TYPE.TEXT_RED}
          icon
          className="search-dropdown__delete-button"
          onClick={deleteUser}
        >
          {t(`creation.${projectType}.team.delete`)}
          <Icon icon={IconType.Bin} />
        </Button>
      </div>
    </div>
  ) : (
    <div
      className={`search-dropdown__container ${
        marginLastElement ? 'search-dropdown__container--margin' : ''
      }`}
      ref={inref}
    >
      {renderSearchDropdown()}

      {renderTypeSelector()}
      <Button
        type={BUTTON_TYPE.TEXT_SECONDARY}
        icon
        className="search-dropdown__delete-button"
        onClick={deleteUser}
      >
        <Icon icon={IconType.Bin} />
      </Button>
    </div>
  );
};
export default ProjectUserList;
