import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';

import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import { REQUEST_STATUS } from 'src/features/request/enums';
import { Tag } from 'src/features/request/models';
import { State } from 'src/features/store/store';
import { UserType } from 'src/features/user/models';

import { ReactComponent as LoaderCircle } from './images/loader.svg';

import './MemberRequestCard.scss';

export enum MemberRequestCardType {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}

export interface MemberRequestCardProps {
  name: string;
  roles?: UserType[];
  tags?: Tag[];
  type: MemberRequestCardType;
  requestStatus: REQUEST_STATUS;
  loader?: boolean;
  comment?: string;
  onDelete?: () => void;
  onCardClick?: () => void;
}

const MemberRequestCard = ({
  name,
  roles,
  tags,
  type,
  requestStatus,
  loader = false,
  comment,
  onDelete,
  onCardClick,
}: MemberRequestCardProps) => {
  const projectType = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );
  const { t } = useTranslation('app', {
    keyPrefix: `creation.${projectType}.team`,
  });

  const typeClass = `member-request-card--${type}`;
  const statusClass = `member-request-card--${requestStatus.toLowerCase()}`;

  const specialistInfo = useMemo(() => {
    if (type === MemberRequestCardType.INCOMING) {
      const _tags = tags?.map((r) => r.name).join(', ');
      return _tags ? `${_tags}: ${name}` : name;
    } else {
      const _roles = roles
        ?.filter((r) => r !== UserType.all)
        .map((r) => t(`roles.${r.toLowerCase()}`))
        .join(', ');
      return _roles ? `${_roles}: ${name}` : name;
    }
  }, [name, roles, t, tags, type]);

  const status = useMemo(() => {
    if (type === MemberRequestCardType.INCOMING) {
      return comment?.trim() || t('awaitingInitiator');
    } else {
      return requestStatus === REQUEST_STATUS.ACTIVE
        ? t('awaitingSpecialist')
        : t('specialistRejected');
    }
  }, [comment, requestStatus, type, t]);

  const allowStatus = !(
    requestStatus === REQUEST_STATUS.ACTIVE &&
    type === MemberRequestCardType.INCOMING &&
    comment?.trim()
  );

  const allowComment =
    comment?.trim() &&
    !(
      requestStatus === REQUEST_STATUS.ACTIVE &&
      type === MemberRequestCardType.OUTGOING
    );

  const allowCommentLabel =
    requestStatus === REQUEST_STATUS.REJECTED &&
    type === MemberRequestCardType.OUTGOING;

  return (
    <div className={`member-request-card ${typeClass} ${statusClass}`}>
      {loader ? <LoaderCircle className="member-request-card__loader" /> : null}
      <button className="member-request-card__content" onClick={onCardClick}>
        <span className="member-request-card__specialist">
          {specialistInfo}
        </span>

        {allowStatus ? (
          <div className="member-request-card__status">{status}</div>
        ) : null}

        {allowComment ? (
          <p className="member-request-card__comment">
            {allowCommentLabel ? (
              <span className="member-request-card__comment-label">
                {`${t('requestComment')}:`}
                &nbsp;
              </span>
            ) : null}
            <span className="member-request-card__comment-body">{comment}</span>
          </p>
        ) : null}
      </button>
      {onDelete ? (
        <Button className="member-request-card__button" type={BUTTON_TYPE.TEXT}>
          <Icon className="member-request-card__bin" icon={IconType.Bin} />
        </Button>
      ) : null}
    </div>
  );
};

export default MemberRequestCard;
