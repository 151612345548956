import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Spinner from 'src/shared/components/Spinner/Spinner';

import Donation from 'src/features/payment/components/Donation/Donation';
import { PROJ_LAYER } from 'src/features/project/slices/projectsLayerSlice';
import { State } from 'src/features/store/store';

import { useIsArchived } from 'src/pages/project/hooks';

import ModalWindow from '../ModalWindow/ModalWindow';

import './DonationModal.scss';

const DonationModal = () => {
  const projectInfo = useSelector(
    (s: State) => s[PROJ_LAYER].currentProjectInfo
  );
  const { t } = useTranslation('app', { keyPrefix: 'detailedProject' });
  const archived = useIsArchived();
  return projectInfo ? (
    <ModalWindow>
      <div className="donation-modal">
        <Donation
          archived={archived}
          title={t('donation.title')}
          description={t('donation.description')}
          buttonText={t('donation.button')}
          projectId={projectInfo.projectInfo.projectID}
          idCheckox={'accept-modal'}
        />
      </div>
    </ModalWindow>
  ) : (
    <Spinner className={'donation-spinner'} visible />
  );
};

export default DonationModal;
