import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { ExhibitionData } from '../models';

export const EXHIBITION_API = 'EXHIBITION_API';

export const exhibitionApi = createApi({
  reducerPath: EXHIBITION_API,
  baseQuery: retry(
    fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URLS }),
    { maxRetries: 0 }
  ),
  endpoints: (builder) => ({
    getExhibition: builder.query<ExhibitionData, string>({
      query: (url) => url,
    }),
  }),
});

export const { useGetExhibitionQuery } = exhibitionApi;
export const exhibitionMiddleware = exhibitionApi.middleware;

export default exhibitionApi.reducer;
