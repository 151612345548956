import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/namespace
import { PaginationList } from 'src/features/map/components/SearchPane/components/PaginationList/PaginationList';
import { PaginationContext } from 'src/features/map/components/SearchPane/components/PaginationList/PaginationListProvider';
import SpinnerSearch from 'src/features/searchWithFilter/components/SpinnerSearch/SpinnerSearch';
import { SearchContext } from 'src/features/searchWithFilter/filter/search/SearchProvider';
import {
  mapPageTypeRenderProject,
  mapPageTypeStyle,
} from 'src/features/searchWithFilter/listProjects/const';

const SearchProjectList = () => {
  const { t } = useTranslation('app', { keyPrefix: 'searchPane' });
  const { projects, typePage } = useContext(SearchContext);
  const { emptyList } = useContext(PaginationContext);

  const listClass = (s: string) => (emptyList ? `${s} ${s}--empty` : s);
  return (
    <>
      <PaginationList
        className={listClass(mapPageTypeStyle[typePage])}
        emptyListLabel={t('placeholders.emptyList')}
      >
        {useMemo(() => {
          return mapPageTypeRenderProject[typePage]({
            projects: projects,
          });
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [projects])}
      </PaginationList>
      <SpinnerSearch />
    </>
  );
};

export default SearchProjectList;
