import {
  ActorBaseResponse,
  AvailableStatusesResponse,
  EnableFlowStepRequest,
  GetStatusesRequest,
  OrderedStatus,
  SetStatusRequest,
  Status,
} from '@platform-for-public-places/components-library';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { GetStatusRequest, GetStatusResponse } from '../models';

export const STATUS_API = 'STATUS_API';

const statusApi = createApi({
  reducerPath: STATUS_API,
  baseQuery: retry(
    fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URLS }),
    {
      maxRetries: 0,
    }
  ),
  tagTypes: ['STATUS', 'FLOW_STEP'],
  endpoints: (builder) => ({
    getStatuses: builder.query<ActorBaseResponse<Status[]>, GetStatusesRequest>(
      {
        query: (request) => {
          const { archived, ...req } = request;
          return {
            method: 'POST',
            body: {
              messageMapId: archived
                ? 'archived-projects/get-statuses-by-project-id'
                : 'project-flow/get-statuses-by-project-id',
              arguments: req,
            },
          };
        },
        providesTags: ['STATUS'],
      }
    ),
    getStatus: builder.query<
      ActorBaseResponse<GetStatusResponse>,
      GetStatusRequest
    >({
      query: (request) => {
        const { archived, ...req } = request;
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-project-flow/get-status-by-project-id'
              : 'project-flow/get-status-by-project-id',
            arguments: req,
          },
        };
      },
      providesTags: ['STATUS'],
    }),
    getAvailableStatusesByProjectId: builder.query<
      ActorBaseResponse<AvailableStatusesResponse>,
      GetStatusesRequest
    >({
      query: (request) => {
        const { archived, ...req } = request;
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-projects/get-available-flow-steps-by-project-id'
              : 'project-flow/get-available-flow-steps-by-project-id',
            arguments: req,
          },
        };
      },
      transformResponse: (
        response: ActorBaseResponse<{
          flowSteps: (Omit<OrderedStatus, 'flowStepId'> & {
            flow_stepID: string;
          })[];
        }>
      ): ActorBaseResponse<AvailableStatusesResponse> => {
        const flowSteps: OrderedStatus[] = response.data.flowSteps.map(
          (step) => ({
            ...step,
            flowStepID: step.flow_stepID,
          })
        );
        return {
          data: { flowSteps },
          success: response.success,
          errors: response.errors,
        };
      },
      providesTags: ['FLOW_STEP'],
    }),
    setStatus: builder.mutation<ActorBaseResponse<void>, SetStatusRequest>({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-flow/set-status',
          arguments: request,
        },
      }),
      invalidatesTags: ['STATUS'],
    }),
    disableProjectFlowStep: builder.mutation<
      ActorBaseResponse<void>,
      EnableFlowStepRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-flow/disable-project-flow-step',
          arguments: request,
        },
      }),
      invalidatesTags: ['FLOW_STEP'],
    }),
    enableProjectFlowStep: builder.mutation<
      ActorBaseResponse<void>,
      EnableFlowStepRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'project-flow/enable-project-flow-step',
          arguments: request,
        },
      }),
      invalidatesTags: ['FLOW_STEP'],
    }),
  }),
});

export const {
  useGetStatusesQuery,
  useGetStatusQuery,
  useGetAvailableStatusesByProjectIdQuery,
  useSetStatusMutation,
  useDisableProjectFlowStepMutation,
  useEnableProjectFlowStepMutation,
} = statusApi;
export const statusMiddleware = statusApi.middleware;

export default statusApi.reducer;
