import DonationSuccess from 'src/features/payment/components/DonationSuccess/DonationSuccess';

import ModalWindow from '../ModalWindow/ModalWindow';

import './DonationSuccessModal.scss';

const DonationSuccessModal = () => {
  return (
    <ModalWindow>
      <div className="donation-success-modal">
        <DonationSuccess />
      </div>
    </ModalWindow>
  );
};

export default DonationSuccessModal;
