import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { GetFilesResponse } from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';
import { filesUploaderWidgetsMap } from 'src/shared/widgets/files';

import {
  useCreateFileKeysMutation,
  useGetFilesQuery,
} from 'src/features/files/api';
import { FileCategories } from 'src/features/files/enums';
import {
  PROJ_CREATE,
  setDesigns,
  setDocs,
} from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import { EditingOutletProps } from 'src/pages/layouts/EditLayout/models';

const STAGE_NAME = 'docs';
const DOCS_LIMIT = 50;
const DOCS_OFFSET = 0;
const DESIGN_LIMIT = 10;
const DESIGN_OFFSET = 0;

const EditingDocuments = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { renderFooter }: EditingOutletProps = useOutletContext();

  function compare<T = GetFilesResponse>(
    first?: T | T[] | null,
    second?: T | T[] | null
  ) {
    return JSON.stringify(first) === JSON.stringify(second);
  }

  const projectId = useSelector((s: State) => s[PROJ_CREATE].projectId);

  const docs = useSelector((s: State) => s[PROJ_CREATE].docs);
  const designs = useSelector((s: State) => s[PROJ_CREATE].designs);

  const currentProjectType = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );

  const stepsConfig = useSelector(
    (s: State) => s[PROJ_CREATE].configEditingSteps
  );
  const docsStep = stepsConfig.find((step) => step.name === STAGE_NAME);

  const { t } = useTranslation('app', {
    keyPrefix: `editing.${currentProjectType}.${STAGE_NAME}`,
  });

  const [updateFiles] = useCreateFileKeysMutation();

  const { data: remoteDisigns } = useGetFilesQuery(
    {
      entityId: projectId as string,
      category: FileCategories.DESIGN,
      limit: DESIGN_LIMIT,
      offset: DESIGN_OFFSET,
    },
    { skip: !projectId }
  );

  const { data: remoteDocs } = useGetFilesQuery(
    {
      entityId: projectId as string,
      category: FileCategories.DOCUMENT,
      limit: DOCS_LIMIT,
      offset: DOCS_OFFSET,
    },
    { skip: !projectId }
  );

  const initData = () => {
    if (remoteDisigns) {
      dispatch(setDesigns(remoteDisigns.data));
    }
    if (remoteDocs) {
      dispatch(setDocs(remoteDocs.data));
    }

    return () => {
      dispatch(setDesigns([]));
      dispatch(setDocs([]));
    };
  };

  useEffect(initData, [dispatch, remoteDisigns, remoteDocs]);

  useEffect(() => {
    if (!projectId && !docsStep) {
      navigate(paths.map);
    }
  }, [docsStep, navigate, projectId]);

  const onFilesSave = (
    entityId: string,
    files: GetFilesResponse[],
    category: FileCategories
  ) =>
    updateFiles({
      entityId,
      category,
      newFiles: {
        list: files?.map((f) => ({
          key: f.key,
          name: f.name || 'file',
        })),
      },
    }).unwrap();

  const onSave = () => {
    if (projectId) {
      onFilesSave(projectId, docs, FileCategories.DOCUMENT).then(() =>
        dispatch(setDocs(docs))
      );
      onFilesSave(projectId, designs, FileCategories.DESIGN).then(() =>
        dispatch(setDesigns(designs))
      );
    }
  };

  const onCancel = () => {
    dispatch(setDocs(remoteDocs?.data ?? []));
    dispatch(setDesigns(remoteDisigns?.data ?? []));
  };

  const modified = useMemo(() => {
    return (
      !compare(remoteDisigns?.data, designs) || !compare(remoteDocs?.data, docs)
    );
  }, [designs, docs, remoteDisigns, remoteDocs]);

  const FilesUploaderWidgetComponent = filesUploaderWidgetsMap.get(
    docsStep?.type ?? ''
  );

  return (
    <>
      {docsStep
        ? FilesUploaderWidgetComponent?.({
            stepConfig: docsStep,
            t,
          })
        : null}
      {renderFooter?.({ onCancel, onSave, valid: true, modified })}
    </>
  );
};

export default EditingDocuments;
