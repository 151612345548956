import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook';
import { useDispatch, useSelector } from 'react-redux';
import Toggle from 'react-toggle';

import {
  Button,
  BUTTON_TYPE,
  useCheckSmallScreen,
  UserProfile,
} from '@platform-for-public-places/components-library';

import { useWhoamiQuery } from 'src/features/auth/api';
import StageHeader from 'src/features/layout/components/StageHeader/StageHeader';
import BottomUserCard from 'src/features/modal/components/BottomUserCard/BottomUserCard';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { StepConfig } from 'src/features/project/models';
import {
  PROJ_CREATE,
  setAllParticipants,
} from 'src/features/project/slices/creatingProjectSlice';
import { PROJ_LAYER } from 'src/features/project/slices/projectsLayerSlice';
import { setSelectedUserId } from 'src/features/searchWithFilter/filter/slices/filterSliceData';
import { State } from 'src/features/store/store';
import { useLazyGetProfileQuery } from 'src/features/user/api';
import { EventParticipant } from 'src/features/user/models';

import './ParticipantsWidget.scss';

export interface ParticipantsWidgetProps {
  configStep: StepConfig;
  participantsList?: UseInfiniteScrollHookRefCallback;
}

const ParticipantsWidget = ({
  configStep,
  participantsList,
}: ParticipantsWidgetProps) => {
  const [userInfoSheetVisible, setUserInfoSheetVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserProfile | null>(null);

  const dispatch = useDispatch();
  const isSmallScreen = useCheckSmallScreen();

  const projectInfo = useSelector(
    (s: State) => s[PROJ_LAYER].currentProjectInfo
  );
  const currentTypeProject = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );

  const allParticipants = useSelector(
    (s: State) => s[PROJ_CREATE].allParticipants
  );

  const { t } = useTranslation('app', {
    keyPrefix: `editing.${currentTypeProject}.participants`,
  });

  const { data: whoami } = useWhoamiQuery();

  const [getUserProfile] = useLazyGetProfileQuery();

  const onMemberClick = (userId: string | undefined) => {
    if (isSmallScreen) {
      setUserInfoSheetVisible(true);
      getUserProfile(userId ?? '').then((response) => {
        setSelectedUser(response.data?.data || null);
      });
    } else {
      dispatch(setSelectedUserId(userId ?? ''));
      dispatch(changeModal(MODALS.SPECIALIST_USER_CARD));
    }
  };

  const onToggleChange = (userId: string) => {
    // The field change is confirmed only for the specified user. The rest remain unchanged
    const changedParticipants = allParticipants.map((item) =>
      item.userId === userId ? { ...item, confirmed: !item.confirmed } : item
    );
    dispatch(setAllParticipants(changedParticipants));
  };

  const renderName = useCallback(() => {
    const name = projectInfo?.projectInfo.owner?.name || '-';
    if (projectInfo?.projectInfo.ownerId) {
      const belongsToYou =
        whoami?.data.userID === projectInfo?.projectInfo.ownerId;
      return `${name} ${belongsToYou ? t('you') : ''}`;
    } else {
      return name;
    }
  }, [projectInfo, whoami, t]);

  const renderStageHeader = () => {
    return (
      <StageHeader
        className="participants__title"
        header={t('title')}
        subheader={t('description')}
      />
    );
  };

  const renderInitiator = () => {
    return (
      <div className="participants__initiator initiator">
        <span className="initiator initiator__label">
          {`${t('initiator')}: `}
        </span>
        <span className="initiator initiator__name">{renderName()}</span>
      </div>
    );
  };

  const renderParticipant = (participant: EventParticipant) => {
    return (
      <li
        className="participants__participant participant"
        key={participant.userId}
      >
        <Toggle
          className="participant__toogle"
          icons={false}
          checked={participant.confirmed}
          onChange={() => onToggleChange(participant.userId)}
        />
        <Button
          className="participant__name"
          type={BUTTON_TYPE.TEXT_SECONDARY}
          onClick={() => onMemberClick(participant.userId)}
        >
          <p>{participant.name}</p>
        </Button>
      </li>
    );
  };

  const renderParticipantsList = () => {
    return (
      <>
        <ul className="participants__list">
          {allParticipants ? (
            <>
              {allParticipants.map(renderParticipant)}
              <div
                className="user-container__loading-trigger"
                ref={participantsList}
              />
            </>
          ) : null}
        </ul>
        {selectedUser ? (
          <BottomUserCard
            selectedUser={selectedUser}
            userInfoSheetVisible={userInfoSheetVisible}
            setUserInfoSheetVisible={setUserInfoSheetVisible}
          />
        ) : null}
      </>
    );
  };

  const renderMap = new Map<string, () => JSX.Element>([
    ['stageHeader', renderStageHeader],
    ['initiator', renderInitiator],
    ['participantsList', renderParticipantsList],
  ]);

  return (
    <div className="participants">
      {configStep.properties.visible.map((item) => renderMap.get(item)?.())}
    </div>
  );
};

export default ParticipantsWidget;
