import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  GetFilesResponse,
  Icon,
  IconType,
  NOTIFICATION_TYPE,
  NotificationPopup,
  PROJECT_STATUS,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';
import { useLeafletContext } from '@react-leaflet/core';
import { Feature, Point } from 'geojson';
import { LatLng, latLngBounds } from 'leaflet';

import PhotoCategoriesTabs from 'src/shared/components/PhotoCategoriesTabs/PhotoCategoriesTabs';
import ProjectPhotoMock from 'src/shared/components/ProjectPhotoMock/ProjectPhotoMock';
import TextEditorPreview from 'src/shared/components/TextEditorPreview/TextEditorPreview';
import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api/authApi';
import { USER_ROLE } from 'src/features/auth/models';
import { VIEW_CARD } from 'src/features/control/enums';
import { setCurrentCard } from 'src/features/control/slices/controlSlice';
import { DRAW } from 'src/features/draw/slices/drawSlice';
import { useGetFilesQuery, useLazyGetFilesQuery } from 'src/features/files/api';
import { FileCategories, PhotoFileCategories } from 'src/features/files/enums';
import { useCheckUser, useDisablePropagationRef } from 'src/features/hooks';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import {
  NOTIFICATION,
  setNotification,
  setVisible,
} from 'src/features/notification/slices/notificationSlice';
import {
  useGetAreaInfoByPolygonQuery,
  useGetInfoAboutAreaByIdQuery,
} from 'src/features/pkk/api/pkkApi';
import {
  useGetEventParticipantsInfoQuery,
  useGetProjectCommentsQuery,
  useGetProjectDescriptionQuery,
  useGetProjectGeodataQuery,
  useGetProjectSubscriptionQuery,
  useGetProjectTimelineQuery,
} from 'src/features/project/api';
import ArchivedBadge from 'src/features/project/components/ArchivedBadge/ArchivedBadge';
import ProjectStatus from 'src/features/project/components/ProjectStatus/ProjectStatus';
import SwiperCarousel, {
  SWIPER_CAROUSEL_TYPE,
} from 'src/features/project/components/SwiperCarousel/SwiperCarousel';
import {
  GetProjectInfoResponse,
  SPECIFIC_LAYER,
} from 'src/features/project/models';
import {
  changeProjectFollow,
  changeProjectJoined,
  changeProjectLiked,
  resetProjectInfo,
  setCurrentProjectInfo,
  setProjectComments,
  setProjectCountOfJoined,
  setProjectFollowed,
  setProjectGeodata,
  setProjectIdea,
  setProjectJoined,
  setProjectLiked,
  setProjectLikes,
  setProjectName,
  setProjectStatus,
} from 'src/features/project/slices/projectsLayerSlice';
import { useGetProjectLikesQuery } from 'src/features/reaction/api';
import FollowButton from 'src/features/reaction/components/FollowButton/FollowButton';
import JoinButton from 'src/features/reaction/components/JoinButton/JoinButton';
import LikeButton from 'src/features/reaction/components/LikeButton/LikeButton';
import {
  useGetAvailableStatusesByProjectIdQuery,
  useGetStatusesQuery,
  useGetStatusQuery,
} from 'src/features/status/api';
import { State } from 'src/features/store/store';

import SharePlate from 'src/pages/project/DetailedProjectInfo/component/SharePlate/SharePlate';
import { useIsArchived } from 'src/pages/project/hooks';

import MeetingPoint from '../MeetingPoint/MeetingPoint';
import StatusWithTimeline from '../StatusWithTimeline/StatusWithTimeline';

import './OverviewCard.scss';

const MAX_ZOOM = 19;
const OFFSET = 0.001;
const PHOTO_LIMIT = 10;
const PHOTO_OFFSET = 0;

export interface OverviewCardProps {
  project: GetProjectInfoResponse | null;
}

const OverviewCard = ({ project }: OverviewCardProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mobile = useCheckSmallScreen();
  const map = useLeafletContext().map;
  const archived = useIsArchived();
  const cardRef = useDisablePropagationRef();

  const { data: projectStatus } = useGetStatusQuery(
    { projectId: project?.projectInfo.projectID as string, archived },
    { skip: !project?.projectInfo.projectID }
  );
  const { data: projectLikes, refetch: projectLikesRefetch } =
    useGetProjectLikesQuery(
      { projectId: project?.projectInfo.projectID as string, archived },
      { skip: !project?.projectInfo.projectID }
    );
  const { data: projectSubscription, refetch: refetchProjectSubscription } =
    useGetProjectSubscriptionQuery(
      { projectId: project?.projectInfo.projectID as string, archived },
      { skip: !project?.projectInfo.projectID }
    );
  const { data: projectComments } = useGetProjectCommentsQuery(
    { projectId: project?.projectInfo.projectID as string, archived },
    { skip: !project?.projectInfo.projectID }
  );
  const { data: projectDescription } = useGetProjectDescriptionQuery(
    { projectId: project?.projectInfo.projectID as string, archived },
    { skip: !project }
  );
  const { data: projectGeodata } = useGetProjectGeodataQuery(
    { projectId: project?.projectInfo.projectID as string, archived },
    { skip: !project }
  );
  const { data: eventParticipantsInfo, refetch: refetchEventParticipantsInfo } =
    useGetEventParticipantsInfoQuery(
      { projectId: project?.projectInfo.projectID as string, archived },
      { skip: !project?.projectInfo.projectID }
    );

  const eventParticipants = eventParticipantsInfo?.data.joinedToEventInfo;

  useEffect(() => {
    dispatch(
      setProjectFollowed(projectSubscription?.data.subscriptionInfo.followed)
    );
    dispatch(setProjectComments(projectComments?.data.commentInfo.comments));
    dispatch(setProjectLikes(projectLikes?.data.likeInfo.likes));
    dispatch(setProjectLiked(projectLikes?.data.likeInfo.liked));
    dispatch(setProjectStatus(projectStatus?.data.statusInfo.status));
    dispatch(setProjectGeodata(projectGeodata?.data.geodata));
    dispatch(setProjectName(projectDescription?.data.description.name));
    dispatch(setProjectIdea(projectDescription?.data.description.idea));
    dispatch(setProjectJoined(eventParticipants?.joined));
    dispatch(setProjectCountOfJoined(eventParticipants?.countOfJoined));
  }, [
    dispatch,
    projectStatus,
    projectLikes,
    projectSubscription,
    projectComments,
    projectDescription,
    projectGeodata,
    eventParticipants?.joined,
    eventParticipants?.countOfJoined,
  ]);

  const [prevZoom, setPrevZoom] = useState<number>();
  const [prevPosition, setPrevPosition] = useState<LatLng>();
  const [showSharePlate, setShowSharePlate] = useState<boolean>(false);
  const [photoCategory, setPhotoCategory] = useState<PhotoFileCategories>(
    FileCategories.PHOTO_BEFORE
  );
  const [wasLiked, setWasLiked] = useState<boolean>(
    projectLikes?.data.likeInfo === null
      ? false
      : !!projectLikes?.data.likeInfo.liked
  );
  const [likesCount, setLikesCount] = useState<number>(
    projectLikes?.data.likeInfo === null
      ? 0
      : projectLikes?.data.likeInfo.likes ?? 0
  );
  const [wasFollowed, setWasFollowed] = useState<boolean>(
    projectSubscription?.data.subscriptionInfo === null
      ? false
      : !!projectSubscription?.data.subscriptionInfo.followed
  );
  const currentStatus = projectStatus?.data.statusInfo.status?.value;
  const isNews = (currentStatus as string) === SPECIFIC_LAYER.NEWS;
  const isEvent = project?.projectInfo.layer === SPECIFIC_LAYER.EVENT;
  const [wasJoined, setWasJoined] = useState<boolean>(
    eventParticipants === null ? false : !!eventParticipants?.joined
  );
  const [countOfJoined, setCountOfJoined] = useState<number>(
    eventParticipants === null ? 0 : eventParticipants?.countOfJoined ?? 0
  );

  const wasDrawn = useSelector((s: State) => !!s[DRAW].drawnShape);

  const isVisible = useSelector((s: State) => s[NOTIFICATION].isVisible);
  const notification = useSelector((s: State) => s[NOTIFICATION].notification);

  const { data: userData } = useWhoamiQuery();

  const beforePhotos = useGetFilesQuery(
    {
      entityId: project?.projectInfo.projectID ?? '',
      category: FileCategories.PHOTO_BEFORE,
      limit: PHOTO_LIMIT,
      offset: PHOTO_OFFSET,
      archived,
    },
    { skip: !project }
  );

  const isInitiatorProjectOwner =
    userData?.data?.userID === project?.projectInfo.ownerId;

  const [photos, setPhotos] = useState<GetFilesResponse[]>([]);

  useEffect(() => {
    setPhotos(beforePhotos.data?.data ?? []);
  }, [beforePhotos]);

  const {
    data: areaInfo,
    isLoading: areaInfoIsLoading,
    refetch: refetchAreaInfo,
  } = useGetAreaInfoByPolygonQuery(
    projectGeodata?.data.geodata?.center as Feature<Point>,
    { skip: !projectGeodata?.data.geodata?.center }
  );

  const {
    data: pkkInfo,
    isLoading: pkkIsLoading,
    refetch: refetchPkkInfo,
  } = useGetInfoAboutAreaByIdQuery(areaInfo?.[0]?.id as string, {
    skip: !areaInfo?.[0],
  });

  const statuses = useGetStatusesQuery(
    { projectId: project?.projectInfo.projectID as string, archived },
    { skip: !project }
  );
  const { data: availableStatuses } = useGetAvailableStatusesByProjectIdQuery(
    { projectId: project?.projectInfo.projectID as string, archived },
    { skip: !project }
  );

  const { data: projectTimeline } = useGetProjectTimelineQuery(
    { projectId: project?.projectInfo.projectID as string, archived },
    { skip: !project, refetchOnMountOrArgChange: true }
  );

  const timeline = projectTimeline?.data.timeline;

  const carouselPhotos = useMemo(() => (photos.length ? photos : []), [photos]);

  const [getPhotos] = useLazyGetFilesQuery();

  const onTabClick = (category: PhotoFileCategories) => {
    if (category !== photoCategory && project) {
      setPhotoCategory(category);
      getPhotos({
        entityId: project?.projectInfo.projectID,
        category: category,
        limit: PHOTO_LIMIT,
        offset: PHOTO_OFFSET,
        archived,
      })
        .unwrap()
        .then((photos) => setPhotos(photos?.data ?? []));
    }
  };

  const res = useCheckUser([
    { role: USER_ROLE.ADMIN },
    { role: USER_ROLE.USER, userId: project?.projectInfo.ownerId },
  ]);
  const isAdmin = useCheckUser([{ role: USER_ROLE.ADMIN }]);

  const onInitiatorClick = () => {
    if (isAdmin) {
      dispatch(changeModal(MODALS.INITIATORS_USER_CARD));
    }
  };

  useEffect(() => {
    const poly = projectGeodata?.data.geodata?.polygon.geometry.coordinates[0];
    if (poly) {
      if (!prevZoom && !prevPosition) {
        setPrevZoom(map.getZoom());
        setPrevPosition(map.getCenter());
      }
      map.fitBounds(latLngBounds(poly.map((c) => [c[1], c[0] + OFFSET])), {
        maxZoom: MAX_ZOOM,
      });
    }
  }, [map, navigate, prevPosition, prevZoom, projectGeodata]);

  useEffect(() => {
    if (!areaInfo) {
      refetchAreaInfo();
    }
  }, [areaInfo, areaInfoIsLoading, refetchAreaInfo]);

  useEffect(() => {
    if (!pkkInfo && !pkkIsLoading) {
      refetchPkkInfo();
    }
  }, [pkkInfo, pkkIsLoading, refetchPkkInfo]);

  useEffect(() => {
    setLikesCount(
      projectLikes?.data.likeInfo === null
        ? 0
        : projectLikes?.data.likeInfo.likes ?? 0
    );
    setWasLiked(
      projectLikes?.data.likeInfo === null
        ? false
        : !!projectLikes?.data.likeInfo.liked
    );
  }, [projectLikes]);

  useEffect(() => {
    setWasFollowed(
      projectSubscription?.data.subscriptionInfo === null
        ? false
        : !!projectSubscription?.data.subscriptionInfo.followed
    );
  }, [projectSubscription]);

  useEffect(() => {
    setWasJoined(
      eventParticipants === null ? false : !!eventParticipants?.joined
    );
    setCountOfJoined(
      eventParticipants === null ? 0 : eventParticipants?.countOfJoined ?? 0
    );
  }, [eventParticipants]);

  const singleClass = photos.length === 1 ? '--single' : '';

  const changeView = () => {
    dispatch(setCurrentProjectInfo(null));
    dispatch(setCurrentCard(VIEW_CARD.LAND_INFORMATION));
  };

  const closeCard = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(resetProjectInfo());
    dispatch(setCurrentCard(null));
    if (prevZoom && prevPosition) {
      map.setView(prevPosition, prevZoom);
      navigate(paths.map, { replace: true });
    }
  };

  const closeCardHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => (wasDrawn ? changeView() : closeCard(e));

  const showFull = () => {
    const id = project?.projectInfo.projectID;
    if (id) {
      navigate(`${paths.projectById(id)}${archived ? '?archived=true' : ''}`, {
        state: { goOnMap: true },
      });
    }
  };

  const showComments = () => {
    const id = project?.projectInfo.projectID;
    if (id) {
      navigate(
        `${paths.projectByIdWithComments(id)}${
          archived ? '&archived=true' : ''
        }`,
        { state: { goOnMap: true } }
      );
    }
  };

  const parsePropertyStatus = () =>
    pkkInfo?.ownershipForm
      ? t(`cadasterInformation.ownershipForms.${pkkInfo?.ownershipForm}`)
      : '';
  const parseAddress = () => (pkkInfo?.address ? `${pkkInfo?.address} ,` : '');

  const ChangeViewButton = (): JSX.Element => {
    return (
      <Button
        type={BUTTON_TYPE.PRIMARY}
        onClick={changeView}
        className="overview-card__change-view"
      >
        <Icon icon={IconType.CurlyArrow} />
        <span>{t('cards.overview.backToDrawing')}</span>
      </Button>
    );
  };

  const drawEditButton = () => {
    return res ? (
      <Button
        type={BUTTON_TYPE.TEXT_SECONDARY}
        className="overview-card__edit-button"
        icon
        onClick={() => {
          navigate(
            paths.editProjectStatusById(project?.projectInfo.projectID ?? '')
          );
        }}
      >
        <Icon icon={IconType.Pencil} />
        <span>{t('cards.overview.edit')}</span>
      </Button>
    ) : null;
  };

  const changeLikeLocal = () => {
    setWasLiked((prev) => !prev);
    setLikesCount((prev) => (wasLiked ? prev - 1 : prev + 1));
    dispatch(changeProjectLiked());
    projectLikesRefetch();
  };

  const followReactionNotifications = useCallback(
    (reactionMessage: string) => {
      dispatch(
        setNotification({
          message: t(reactionMessage),
          type: NOTIFICATION_TYPE.NOTIFICATION,
        })
      );
      dispatch(setVisible(true));
    },
    [dispatch, t]
  );

  const changeFollowLocal = () => {
    const reactionMessage = wasFollowed
      ? 'cards.overview.reaction.follow.unfollowNotification'
      : 'cards.overview.reaction.follow.followNotification';
    setWasFollowed((prev) => !prev);
    followReactionNotifications(reactionMessage);
    dispatch(changeProjectFollow());
    refetchProjectSubscription();
  };

  const hideNotification = () => dispatch(setVisible(false));

  const renderPkkInfo = () => {
    if (!pkkIsLoading && pkkInfo) {
      return `${parseAddress()} ${parsePropertyStatus()}`;
    } else {
      return null;
    }
  };

  const changeJoinLocal = () => {
    setWasJoined((prev) => !prev);
    setCountOfJoined((prev) => (wasJoined ? prev - 1 : prev + 1));
    dispatch(changeProjectJoined());
    refetchEventParticipantsInfo();
  };

  return (
    <section
      className={`overview-card${project ? ' overview-card--active' : ''}`}
      onWheel={(e) => e.stopPropagation()}
      ref={cardRef}
    >
      {wasDrawn ? <ChangeViewButton /> : null}
      <div className="overview-card__close-wrapper">
        <button className="overview-card__close" onClick={closeCardHandler}>
          <Icon icon={IconType.Cross} />
        </button>
      </div>

      <div className="overview-card__content-block">
        <header className="overview-card__header">
          <div className="overview-card__name-container">
            <h1 className="overview-card__name">
              {projectDescription?.data.description.name}
            </h1>
            {archived ? <ArchivedBadge /> : null}
          </div>
          {projectStatus?.data.statusInfo.status &&
          project?.projectInfo.layer === SPECIFIC_LAYER.EVENT &&
          timeline ? (
            <StatusWithTimeline
              className="overview-card__status-with-timeline"
              status={projectStatus?.data.statusInfo.status}
              startDate={timeline.startDate}
              endDate={timeline.endDate}
            />
          ) : null}
          <div className="overview-card__address-row">
            {isEvent &&
            (!!projectGeodata?.data.geodata?.meetingPoint ||
              pkkInfo?.address) ? (
              <MeetingPoint className="overview-card__meeting-point">
                {projectGeodata?.data.geodata?.meetingPoint || pkkInfo?.address}
              </MeetingPoint>
            ) : (
              <span className="overview-card__address">{renderPkkInfo()}</span>
            )}
            {archived ? null : drawEditButton()}
          </div>
          <Button
            className="overview-card__initiator"
            type={BUTTON_TYPE.TEXT_SECONDARY}
            onClick={onInitiatorClick}
            disabled={!isAdmin}
          >
            {t('cards.overview.initiator', {
              initiator: `${project?.projectInfo.owner?.name || '-'} ${
                userData?.data.userID === project?.projectInfo.ownerId
                  ? t('cards.overview.you')
                  : ''
              }`,
            })}
          </Button>
        </header>

        <div className="overview-card__content">
          {!isNews && !isEvent ? (
            <ProjectStatus
              statuses={statuses.data?.data || []}
              className="overview-card__status"
              archived={archived}
              availableStatuses={availableStatuses?.data}
              projectType={project?.projectInfo.layer}
            />
          ) : null}
          {isNews ? null : (
            <PhotoCategoriesTabs
              layer={project?.projectInfo.layer}
              category={photoCategory}
              onTabClick={onTabClick}
            />
          )}
          {carouselPhotos.length ? (
            <SwiperCarousel
              className={`overview-card__photos${singleClass} ${
                isNews ? 'overview-card__photos-news' : ''
              }`}
              photos={carouselPhotos}
              clickable
              type={SWIPER_CAROUSEL_TYPE.DESKTOP_DEFAULT}
              mobile={mobile}
            />
          ) : (
            <ProjectPhotoMock
              category={photoCategory}
              className="detailed-info__photo-mock"
            />
          )}
          <div className="overview-card__description">
            <TextEditorPreview>
              {projectDescription?.data.description.idea ?? ''}
            </TextEditorPreview>
          </div>
          <button onClick={showComments} className="overview-card__comments">
            {t('cards.overview.comments', {
              count: projectComments?.data.commentInfo.comments ?? 0,
            })}
          </button>
        </div>

        <footer className="overview-card__footer">
          <div className="overview-card__footer-block">
            {archived || project?.projectInfo.layer === 'event' ? null : (
              <Button
                className="overview-card__support-button"
                type={BUTTON_TYPE.PRIMARY}
                onClick={showFull}
              >
                {t('cards.overview.support')}
              </Button>
            )}
            {project?.projectInfo.layer === 'event' &&
            !isInitiatorProjectOwner ? (
              <JoinButton
                projectId={project?.projectInfo.projectID as string}
                changeJoinLocal={changeJoinLocal}
                wasJoined={wasJoined}
                countOfJoined={countOfJoined}
                className="overview-card__join-button"
                disabled={
                  projectStatus?.data.statusInfo.status?.value ===
                  PROJECT_STATUS.FINISHED
                }
              />
            ) : null}
            <LikeButton
              projectId={project?.projectInfo.projectID as string}
              changeLikeLocal={changeLikeLocal}
              wasLiked={wasLiked}
              likesCount={likesCount}
              className="overview-card__like-button"
              disabled={archived || currentStatus === PROJECT_STATUS.FRAMED}
              showText={false}
            />
            <FollowButton
              projectId={project?.projectInfo.projectID as string}
              changeFollowLocal={changeFollowLocal}
              wasFollowed={wasFollowed}
              className="overview-card__follow-button"
              disabled={archived || currentStatus === PROJECT_STATUS.FRAMED}
              showText={false}
            />
            {archived ? null : (
              <Button
                className="overview-card__share-button"
                type={BUTTON_TYPE.TEXT_DARK}
                fill
                icon
                onClick={() => {
                  setShowSharePlate(!showSharePlate);

                  navigator.clipboard.writeText(
                    `${window.location.origin}${paths.projectById(
                      project?.projectInfo.projectID ?? ''
                    )}`
                  );
                }}
              >
                <Icon icon={IconType.Share} />
              </Button>
            )}
            <Button
              type={BUTTON_TYPE.ACCENTED_OUTLINE}
              className="overview-card__watch-full-button"
              onClick={showFull}
            >
              {t('cards.overview.watchFull')}
              <Icon icon={IconType.Arrow} />
            </Button>
            {showSharePlate ? (
              <SharePlate
                className="overview-card__share-plate"
                projectId={project?.projectInfo.projectID || ''}
                setClickedOutside={() => setShowSharePlate(false)}
              />
            ) : null}
          </div>
        </footer>
      </div>
      <NotificationPopup
        className="detailed-info__notification"
        type={notification.type}
        isVisible={isVisible}
        onTimeoutFinished={hideNotification}
      >
        {notification.message}
      </NotificationPopup>
    </section>
  );
};

export default OverviewCard;
