import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import { setCurrentCard } from 'src/features/control/slices/controlSlice';
import {
  changeDrawEnable,
  changeNeedClear,
  DRAW,
  resetDraw,
} from 'src/features/draw/slices/drawSlice';
import { ACTION_TYPE } from 'src/features/map/enums';
import { changeAction } from 'src/features/map/slices/mapSlice';
import { State } from 'src/features/store/store';

import './OnlyMapLayout.scss';

interface OnlyMapLayoutProps {
  backButtonText: string;
}

const OnlyMapLayout = ({ backButtonText }: OnlyMapLayoutProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isSmallScreen = useCheckSmallScreen();

  const isDrawEnable = useSelector((s: State) => s[DRAW].isDrawEnable);
  const wasNotDrawn = useSelector((state: State) => !state[DRAW].drawnShape);

  useEffect(() => {
    if (!isDrawEnable && wasNotDrawn) {
      navigate(-1);
    }
  }, [isDrawEnable, navigate, wasNotDrawn]);

  useEffect(
    () => () => {
      dispatch(changeAction(ACTION_TYPE.CREATE));
    },
    [dispatch]
  );

  const onClick = () => {
    dispatch(changeNeedClear());
    dispatch(resetDraw());
    dispatch(setCurrentCard(null));
    dispatch(changeDrawEnable(false));
  };

  return (
    <main
      className={`only-map-layout${
        isDrawEnable ? ' only-map-layout--drawing' : ''
      }`}
    >
      <Outlet />
      {!isSmallScreen ? (
        <Button
          className="only-map-layout__button"
          type={BUTTON_TYPE.PRIMARY}
          onClick={onClick}
        >
          <Icon
            className="only-map-layout__button-icon"
            icon={IconType.CurlyArrow}
          />
          {backButtonText}
        </Button>
      ) : null}
    </main>
  );
};

export default OnlyMapLayout;
