import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDispatch, useSelector } from 'react-redux';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { SpringEvent } from 'react-spring-bottom-sheet/dist/types';

import {
  GetProjectResponse,
  Icon,
  IconType,
  PROJECT_STATUS,
  useCheckSmallScreen,
  UserCard,
  UserProfile,
  UserTag,
} from '@platform-for-public-places/components-library';
import debounce from 'lodash.debounce';

import { DEBOUNCE_TIMEOUT } from 'src/app/constants';
import SearchInput from 'src/shared/components/SearchInput/SearchInput';
import Spinner from 'src/shared/components/Spinner/Spinner';
import { paths } from 'src/shared/routes';

import {
  useGetProjectsWithImages,
  useTranslationStatus,
  useUserCardTranslation,
} from 'src/features/hooks';
import MobileHeader from 'src/features/mobile/components/MobileHeader/MobileHeader';
import { useLazyGetProjectsBySpecialistQuery } from 'src/features/project/api';
import {
  FILTER,
  setPage,
  setSearchString,
} from 'src/features/searchWithFilter/filter/slices/filterSlice';
import {
  FILTER_DATA,
  setParticipants,
  setParticipantsType,
} from 'src/features/searchWithFilter/filter/slices/filterSliceData';
import { State } from 'src/features/store/store';
import {
  useGetUserCountQuery,
  useLazyGetUsersWithProfileLinkQuery,
} from 'src/features/user/api';
import { resetApiState } from 'src/features/user/api/userApi';
import { UserType } from 'src/features/user/models';

import CatalogAvatar from './components/CatalogAvatar/CatalogAvatar';

import './CatalogUsers.scss';

const PAGE_SIZE = 25;
const MOBILE_PAGE_SIZE = 10;

const CatalogUsers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSmallScreen = useCheckSmallScreen();
  const translateStatus = useTranslationStatus();
  const userCardTitles = useUserCardTranslation();

  const [projectsPage, setProjectsPage] = useState<number>(1);
  const [projectsError] = useState<boolean>(false);
  const [projectsLoading, setProjectsLoading] = useState<boolean>(false);
  const [hasProjectsNextPage, setHasProjectsNextPage] = useState<boolean>(true);
  const [projects, setProjects] = useState<GetProjectResponse[]>([]);

  const [getProjectsBySpecialist] = useLazyGetProjectsBySpecialistQuery();

  const [getProjectsWithImages] = useGetProjectsWithImages();

  const [getProjects] = useInfiniteScroll({
    loading: projectsLoading,
    hasNextPage: hasProjectsNextPage,
    onLoadMore: () => {
      if (selectedUser) {
        setProjectsLoading(true);
        getProjectsBySpecialist({
          userId: selectedUser.userId,
          statuses: [
            PROJECT_STATUS.ACCEPTED,
            PROJECT_STATUS.APPROVED,
            PROJECT_STATUS.IMPLEMENTED,
            PROJECT_STATUS.FINISHED,
            PROJECT_STATUS.TRANSFERRED,
          ],
          page: projectsPage,
          pageSize: MOBILE_PAGE_SIZE,
        }).then((response) => {
          if (response.data?.data) {
            getProjectsWithImages(response.data?.data ?? []).then((res) =>
              setProjects((prev) => [...prev, ...res])
            );
            setProjectsPage(projectsPage + 1);
            setProjectsLoading(false);
            setHasProjectsNextPage(
              response.data?.data.length === MOBILE_PAGE_SIZE
            );
          }
        });
      }
    },
    disabled: projectsError,
  });

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [selectedUser, setSelectedUser] = useState<UserProfile | null>(null);
  const [bottomSheetVisible, setBottomSheetVisible] = useState(false);

  const page = useSelector((s: State) => s[FILTER].page);
  const participants = useSelector((s: State) => s[FILTER_DATA].participants);
  const searchString = useSelector((s: State) => s[FILTER].searchString);
  const participantsType = useSelector(
    (s: State) => s[FILTER_DATA].participantsType
  );

  const { data: participantsCount } = useGetUserCountQuery({
    searchString: `%${searchString}%`,
  });

  const [getParticipantsInfoWithProfileLink] =
    useLazyGetUsersWithProfileLinkQuery();

  useEffect(() => {
    dispatch(setSearchString(''));

    return () => {
      dispatch(setSearchString(''));
    };
  }, [dispatch]);

  useEffect(() => {
    if (participants.length && !selectedUser && !isSmallScreen) {
      setSelectedUser(participants[0]);
    }
  }, [isSmallScreen, participants, selectedUser]);

  useEffect(() => {
    if (!participantsType) {
      dispatch(setPage(1));
      dispatch(setSearchString(''));
      dispatch(setParticipantsType(UserType.all));
      dispatch(setParticipants([]));
    }
  }, [dispatch, participantsType]);

  const resetScroll = useCallback(() => {
    dispatch(setPage(1));
    dispatch(setParticipants([]));
    setError(false);
    setHasNextPage(true);
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(resetApiState());
      resetScroll();
    },
    [dispatch, resetScroll]
  );

  const pageSize = useMemo(
    () => (isSmallScreen ? MOBILE_PAGE_SIZE : PAGE_SIZE),
    [isSmallScreen]
  );

  const clearProjects = () => {
    setHasProjectsNextPage(true);
    setProjectsPage(0);
    setProjects([]);
  };

  const renderTags = (
    isDeveloper: boolean | undefined,
    isImplementer: boolean | undefined
  ) => {
    return (
      <div className="user-catalog-card__tags">
        {isDeveloper ? (
          <div
            className="user-catalog-card__tag"
            style={{
              color: UserTag.developer,
              borderColor: UserTag.developer,
            }}
          >
            {t('userCatalog.tags.developer')}
          </div>
        ) : null}
        {isImplementer ? (
          <div
            className="user-catalog-card__tag"
            style={{
              color: UserTag.implementer,
              borderColor: UserTag.implementer,
            }}
          >
            {t('userCatalog.tags.implementer')}
          </div>
        ) : null}
      </div>
    );
  };

  const textWithImage = (userData: UserProfile | null) => {
    return (
      <a
        rel="noreferrer"
        className="user-catalog-card__links-portfolio-block"
        href={userData?.portfolio?.link ?? ''}
        target="_blank"
      >
        <Icon
          className="user-catalog-card__links-portfolio-icon"
          icon={IconType.Brush}
        />

        <p className="user-catalog-card__links-portfolio-text">
          {t('userCatalog.portfolio')}
        </p>
      </a>
    );
  };

  const renderUserLinks = (mainLink?: string) => {
    return (
      <>
        <div className="user-catalog-card__links">
          {textWithImage(selectedUser)}
          <button
            className="user-card__contacts-button"
            onClick={() => {
              navigator.clipboard.writeText(mainLink ? mainLink : '');
            }}
          >
            <p className="user-catalog-card__links__main-contact">{mainLink}</p>
          </button>
        </div>
        <Icon className="user-catalog-card__arrow" icon={IconType.Chevron} />
      </>
    );
  };

  const renderUsers = () => {
    if (participants) {
      return participants.map((user, i) => {
        return (
          <button
            className={`user-catalog-card ${
              selectedUser?.userId === user.userId
                ? 'user-catalog-card--selected'
                : ''
            }`}
            key={i}
            onClick={() => {
              setBottomSheetVisible(true);
              setSelectedUser(user);
              clearProjects();
            }}
            disabled={!!isSmallScreen && !!selectedUser}
          >
            <div className="user-catalog-card__main-info">
              <CatalogAvatar
                className={'user-card__avatar-small'}
                avatar={user.avatar as string}
              />
              <div className={'user-catalog-card__about'}>
                <p className="user-catalog-card__name">{user.name}</p>
                {renderTags(user.isDeveloper, user.isImplementer)}
              </div>
            </div>
            {isSmallScreen ? null : renderUserLinks(user.socialLinks?.email)}
          </button>
        );
      });
    } else {
      return null;
    }
  };

  const selectCategory = (category: string) => {
    dispatch(setParticipantsType(category));
    resetScroll();
  };

  const renderFilter = (
    categoryType: string,
    categoryTitle: string,
    number: number | undefined
  ) => (
    <li
      className={`project-category-filter__element ${
        participantsType === categoryType
          ? 'project-category-filter__element_selected'
          : ''
      }`}
    >
      <button onClick={() => selectCategory(categoryType)}>
        {categoryTitle}
        <p className="project-category-filter__element_counter">
          {number ?? 0}
        </p>
      </button>
    </li>
  );

  const renderUserCategoryFilter = () => (
    <ul className="catalog-user__project-category-filter">
      {renderFilter(
        UserType.all,
        t('userCatalog.tags.all'),
        participantsCount?.data[0]?.all
      )}
      {renderFilter(
        UserType.developer,
        t('userCatalog.tags.developer'),
        participantsCount?.data[0]?.developers
      )}
      {renderFilter(
        UserType.implementer,
        t('userCatalog.tags.implementer'),
        participantsCount?.data[0]?.implementers
      )}
    </ul>
  );

  const requestSpecialists = (
    searchString: string,
    type: string,
    pageSize: number,
    page: number,
    participants: UserProfile[]
  ) =>
    getParticipantsInfoWithProfileLink({
      searchString: `%${searchString}%`,
      type,
      pageSize,
      page,
    })
      .then((response) => {
        dispatch(
          setParticipants([...participants, ...(response.data?.data ?? [])])
        );
        dispatch(setPage(page + 1));
        setLoading(false);
        setHasNextPage(response.data?.data.length === pageSize);
      })
      .catch(() => setError(true));

  const debouncedRequest = useRef(
    debounce(requestSpecialists, DEBOUNCE_TIMEOUT)
  ).current;

  // Request cancel on unmount
  useEffect(() => () => debouncedRequest.cancel(), [debouncedRequest]);

  const [userCatalog] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => {
      setLoading(true);
      debouncedRequest(
        searchString,
        participantsType,
        pageSize,
        page,
        participants
      );
    },
    disabled: error,
  });

  const renderSpinner = () =>
    loading ? (
      <div className="catalog-user__spinner">
        <Spinner />
      </div>
    ) : null;

  return isSmallScreen ? (
    <>
      <div className="catalog-user--mobile">
        <MobileHeader title={t('header.catalogParticipation')} />
        <div className="catalog-user__container">
          <SearchInput
            className="catalog-user__search"
            placeholder={t('userCatalog.search.placeholder')}
            value={searchString}
            onChange={(value) => {
              dispatch(setSearchString(value.target.value));
              resetScroll();
            }}
          />
          {renderUserCategoryFilter()}
          <div className="catalog-user__user-container">
            {renderUsers()}
            {hasNextPage ? (
              <div
                className="user-container__loading-trigger"
                ref={userCatalog}
              />
            ) : null}
          </div>
          {renderSpinner()}
        </div>
      </div>
      <BottomSheet
        open={bottomSheetVisible}
        blocking
        onDismiss={() => setBottomSheetVisible(false)}
        scrollLocking={false}
        expandOnContentDrag
        onSpringEnd={(event: SpringEvent) => {
          if (event.type === 'CLOSE') {
            setSelectedUser(null);
          }
        }}
      >
        {selectedUser ? (
          <UserCard
            showProjects
            imageComponent={
              <CatalogAvatar avatar={selectedUser.avatar as string} rerender />
            }
            user={selectedUser}
            titles={userCardTitles}
            translateStatus={translateStatus}
            getProjectPath={paths.projectById}
            projectsRef={getProjects}
            projects={projects}
            hasNextPage={hasProjectsNextPage}
          />
        ) : null}
      </BottomSheet>
    </>
  ) : (
    <div className="catalog-user__container">
      <div className="catalog-user__left-container">
        <SearchInput
          className="catalog-user__search"
          placeholder={t('userCatalog.search.placeholder')}
          value={searchString}
          onChange={(value) => {
            dispatch(setSearchString(value.target.value));
            resetScroll();
          }}
        />
        {renderUserCategoryFilter()}
        <div className="catalog-user__user-container">
          {renderUsers()}
          {hasNextPage ? (
            <div
              className="user-container__loading-trigger"
              ref={userCatalog}
            />
          ) : null}
        </div>
        {renderSpinner()}
      </div>
      {selectedUser ? (
        <UserCard
          showProjects
          imageComponent={
            <CatalogAvatar avatar={selectedUser.avatar as string} rerender />
          }
          className="catalog-user__right-container"
          user={selectedUser}
          titles={userCardTitles}
          translateStatus={translateStatus}
          getProjectPath={paths.projectById}
          projectsRef={getProjects}
          projects={projects}
          hasNextPage={hasProjectsNextPage}
        />
      ) : null}
    </div>
  );
};

export default CatalogUsers;
