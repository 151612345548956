import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { Category, TagData } from 'src/features/request/models';

import { SpecialistError } from 'src/pages/profile/BecomeSpecialist/FormSpecialist';

import './DropdownTag.scss';

const MAX_LENGTH = 3;

interface DropdownTagProps {
  category: Category[] | undefined;
  selectTag: TagData[];
  setSelectTag: (value: TagData[]) => void;
  error?: SpecialistError;
  setError?: (value: SpecialistError) => void;
}

const DropdownTag = ({
  category,
  selectTag,
  setSelectTag,
  error,
  setError,
}: DropdownTagProps) => {
  const { t } = useTranslation();
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<Category[] | undefined>(category);

  useEffect(() => {
    setOptions(category);
  }, [category]);

  interface GroupedOption {
    readonly label: string;
    readonly options: readonly TagData[];
  }

  const formatGroupLabel = (data: GroupedOption) => (
    <div className={'dropdown-tag__group'}>
      <span>{data.label}</span>
      <span className={'dropdown-tag__group-badge'}>{data.options.length}</span>
    </div>
  );

  const checkError = (data: TagData[]) => {
    if (data.length <= 0) {
      setError &&
        setError({
          exists: true,
          reason: t('profile.specialist.required'),
        });
    }
  };
  return (
    <div className={'search-dropdown__input'}>
      <Select<TagData, true, GroupedOption>
        classNamePrefix={'selector'}
        className={`selector__dropdown dropdown-tag__selector  ${
          error?.exists ? 'selector__dropdown-error' : ''
        }`}
        placeholder={t('formAddToProject.tagPlaceholder')}
        options={options}
        value={selectTag}
        isMulti
        onChange={(newValue) => {
          const data: TagData[] = newValue.map((val) => {
            return val;
          });
          setMenuIsOpen(true);
          if (data.length >= MAX_LENGTH || selectTag.length >= MAX_LENGTH) {
            setOptions((prevState) => {
              return prevState?.map((value) => {
                return {
                  ...value,
                  options: value.options.map((data) => {
                    return {
                      ...data,
                      isDisabled: !data.isDisabled,
                    };
                  }),
                };
              });
            });
          }
          checkError(data);
          setSelectTag(data);
        }}
        onMenuClose={() => {
          setMenuIsOpen(false);
          checkError(selectTag);
        }}
        onMenuOpen={() => {
          setMenuIsOpen(true);
        }}
        menuIsOpen={menuIsOpen}
        formatGroupLabel={formatGroupLabel}
      />
    </div>
  );
};

export default DropdownTag;
