import {
  useCreateFileKeysMutation as realUseCreateFileKeysMutation,
  useGetFilesQuery as realUseGetFilesQuery,
  useGetProjectCoverQuery as realUseGetProjectCoverQuery,
  useLazyGetFilesQuery as realUseLazyGetFilesQuery,
  useLazyGetProjectCoverQuery as realUseLazyGetProjectCoverQuery,
  useSetProjectCoverMutation as realUseSetProjectCoverMutation,
} from './filesApi';
import {
  useCreateFileKeysMutation as mockUseCreateFileKeysMutation,
  useGetFilesQuery as mockUseGetFilesQuery,
  useGetProjectCoverQuery as mockUseGetProjectCoverQuery,
  useLazyGetFilesQuery as mockUseLazyGetFilesQuery,
  useLazyGetProjectCoverQuery as mockUseLazyGetProjectCoverQuery,
  useSetProjectCoverMutation as mockUseSetProjectCoverMutation,
} from './mockFilesApi';

export const useGetFilesQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetFilesQuery
    : realUseGetFilesQuery;

export const useLazyGetFilesQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetFilesQuery
    : realUseLazyGetFilesQuery;

export const useCreateFileKeysMutation =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseCreateFileKeysMutation
    : realUseCreateFileKeysMutation;

export const useGetProjectCoverQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseGetProjectCoverQuery
    : realUseGetProjectCoverQuery;

export const useLazyGetProjectCoverQuery =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseLazyGetProjectCoverQuery
    : realUseLazyGetProjectCoverQuery;

export const useSetProjectCoverMutation =
  process.env.REACT_APP_MOCKS_ENABLED === 'true'
    ? mockUseSetProjectCoverMutation
    : realUseSetProjectCoverMutation;
