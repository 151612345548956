import { FormEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  TextArea,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import { useWhoamiQuery } from 'src/features/auth/api';
import { REQUEST_STATUS, REQUEST_TYPE } from 'src/features/request/enums';
import { State } from 'src/features/store/store';

import { useLazyCreateCommentQuery, useUpdateCommentMutation } from '../../api';

import {
  COMMENT,
  setNewComment,
  setUpdatingComment,
  setUpdatingStage,
} from '../../slices/commentSlice';

import { COMMENT_UPDATING_STAGE } from '../../models';

import './CommentCreation.scss';

interface CommentsProps {
  projectId: string;
  extended?: boolean;
  className?: string;
  refreshNewComments?: boolean;
}

const SINGLE_ROW = 1;
const MILTIPLE_ROWS = 3;
export const COMMENT_FORM_ANCHOR = 'comment-form-anchor';

const CommentCreation = ({
  projectId,
  extended = false,
  className = '',
  refreshNewComments = true,
}: CommentsProps) => {
  const dispatch = useDispatch();
  const mobile = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'comments' });

  const [comment, setComment] = useState<string>('');

  const updatingComment = useSelector((s: State) => s[COMMENT].updatingComment);

  useEffect(() => {
    if (updatingComment) {
      setComment(
        updatingComment.comment.approved
          ? updatingComment.comment.text
          : updatingComment.request?.requestBody.comment.text || ''
      );
    } else {
      setComment('');
    }
  }, [updatingComment]);

  const whoami = useWhoamiQuery();
  const [createComment] = useLazyCreateCommentQuery();
  const [updateComment] = useUpdateCommentMutation();

  const watchExtended = (s: TemplateStringsArray) =>
    extended ? `${s[0]} ${s[0]}--extended` : s[0];

  const onCancel = () => {
    dispatch(setUpdatingStage(COMMENT_UPDATING_STAGE.NONE));
    dispatch(setUpdatingComment(null));
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (updatingComment) {
      updateComment({
        text: comment,
        commentId: updatingComment.comment.project_commentID,
      }).then(() => {
        if (updatingComment.request) {
          dispatch(
            setUpdatingComment({
              ...updatingComment,
              request: {
                ...updatingComment.request,
                status: REQUEST_STATUS.ACTIVE,
                requestBody: {
                  ...updatingComment.request.requestBody,
                  comment: {
                    ...updatingComment.request.requestBody.comment,
                    text: comment,
                  },
                },
              },
            })
          );
        }
        dispatch(setUpdatingStage(COMMENT_UPDATING_STAGE.FINISHED));
      });
    } else {
      const name = whoami.data?.data.profile?.name ?? '';
      const userId = whoami.data?.data.userID ?? '';

      createComment({
        projectId,
        text: comment,
        name,
      }).then(({ data: resp }) => {
        if (resp) {
          const now = new Date().toISOString();
          const _comment = {
            name,
            userId,
            projectId,
            text: comment,
            approved: false,
            project_commentID: resp.data.commentId,
            createdAt: now,
            updatedAt: now,
          };
          if (refreshNewComments) {
            dispatch(
              setNewComment({
                comment: _comment,
                request: {
                  type: REQUEST_TYPE.COMMENT,
                  status: REQUEST_STATUS.ACTIVE,
                  entityId: resp.data.commentId,
                  requestID: resp.data.requestId,
                  initiatorId: userId,
                  requestBody: {
                    projectName: '',
                    comment: _comment,
                  },
                  createdAt: now,
                  updatedAt: now,
                },
              })
            );
          }
        }
      });
    }
    setComment('');
  };

  return (
    <form
      id={COMMENT_FORM_ANCHOR}
      className={`comment-creation ${className}`}
      onSubmit={onSubmit}
    >
      <TextArea
        rows={mobile && !extended ? SINGLE_ROW : MILTIPLE_ROWS}
        value={comment}
        maxLength={500}
        className="comment-creation__textarea"
        placeholder={mobile ? t('create') : t('placeholder')}
        onChange={(e) => setComment(e.target.value)}
      />
      {mobile ? (
        <Button
          submit
          disabled={!comment}
          className={watchExtended`comment-creation__textarea-send`}
          type={BUTTON_TYPE.TEXT}
        >
          <Icon icon={IconType.Send} />
        </Button>
      ) : (
        <div className="comment-creation__buttons">
          {updatingComment ? (
            <Button
              disabled={!comment}
              className="comment-creation__cancel-button"
              type={BUTTON_TYPE.SECONDARY}
              onClick={onCancel}
            >
              <span>{t('cancel')}</span>
            </Button>
          ) : null}
          <Button
            submit
            disabled={!comment}
            className="comment-creation__create-button"
            type={BUTTON_TYPE.SECONDARY}
          >
            <span>{t(updatingComment ? 'update' : 'create')}</span>
          </Button>
        </div>
      )}
    </form>
  );
};

export default CommentCreation;
