import { initReactI18next } from 'react-i18next';

import libRu from '@platform-for-public-places/components-library/translations/ru.json';
import i18n from 'i18next';

import appRu from './translations/ru.json';

const resources = {
  ru: {
    ...libRu,
    ...appRu,
  },
};

i18n.use(initReactI18next).init({
  resources,
  ns: ['lib', 'app'],
  defaultNS: 'app',
  lng: 'ru',
  fallbackLng: 'ru',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

// How to use:
// import { useTranslation } from 'react-i18next';
// const Component = () => {
//   const { t } = useTranslation();
//   return <h1>{t('key-from-config)}</h1>
// }
