import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import SearchInputControl from 'src/features/control/components/SearchInputControl/SearchInputControl';
import { setSearchPanelState } from 'src/features/control/slices/controlSlice';
import NumberProjectSearch from 'src/features/map/components/SearchPane/components/NumberProjectSearch/NumberProjectSearch';
import { SearchPanelState } from 'src/features/map/components/SearchPane/components/SearchFilterInput/SearchFilterInput';
import { SearchContext } from 'src/features/searchWithFilter/filter/search/SearchProvider';
import FilterOrder from 'src/features/searchWithFilter/filter/searchFilters/FilterOrder/FilterOrder';
import { SearchProjectFiltersMobile } from 'src/features/searchWithFilter/filter/searchFilters/SearchFilters';
import SearchProjectList from 'src/features/searchWithFilter/listProjects/SearchProjectList';

const SearchPanelContentMapMobile = () => {
  const dispatch = useDispatch();
  const { resetSearch } = useContext(SearchContext);
  return (
    <div className={'search-pane__mobile-container'}>
      <div className={'search-pane__input-mobile'}>
        <SearchInputControl
          className="search-pane__input"
          onChange={resetSearch}
          onFocus={() => dispatch(setSearchPanelState(SearchPanelState.OPEN))}
        />
      </div>
      <SearchProjectFiltersMobile
        className={'search-pane__input-mobile-filter'}
      />
      <div className={'search-pane__mobile-order-number'}>
        <NumberProjectSearch />
        <FilterOrder />
      </div>
      <SearchProjectList />
    </div>
  );
};
export default SearchPanelContentMapMobile;
