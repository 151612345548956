import { ReactElement, useContext } from 'react';

import { PaginationContext } from 'src/features/map/components/SearchPane/components/PaginationList/PaginationListProvider';

import './PaginationList.scss';

export enum TYPE_SEARCH_PAGE {
  PROJECT_CATALOG = 'PROJECT_CATALOG',
  PROJECT_MAP = 'PROJECT_MAP',
  USER_PROJECTS = 'USER_PROJECTS',
}
//todo vinisti

type PaginationListProps = {
  className?: string;
  children: ReactElement | ReactElement[];
  emptyListLabel?: string;
};

export const PaginationList = ({
  className,
  children,
  emptyListLabel = '',
}: PaginationListProps) => {
  const { emptyList, hasNextPage, paginationData } =
    useContext(PaginationContext);
  let trigger, rootRef;
  if (paginationData) {
    [trigger, { rootRef }] = paginationData;
  }
  return (
    <div className={className} ref={rootRef}>
      {children}
      {hasNextPage ? (
        <div className="pagination__trigger" ref={trigger} />
      ) : null}
      {emptyList ? (
        <span className="pagination__empty-placeholder">{emptyListLabel}</span>
      ) : null}
    </div>
  );
};
//todo fix
