import { ReactElement } from 'react';

import { DataType } from 'csstype';

import './Box.scss';

type BoxProps = {
  className?: string;
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  flexDirection?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  alignContent?:
    | DataType.ContentDistribution
    | DataType.ContentPosition
    | 'baseline'
    | 'normal';
  justifyContent?:
    | DataType.ContentDistribution
    | DataType.ContentPosition
    | 'left'
    | 'normal'
    | 'right';
  alignItems?: DataType.SelfPosition | 'baseline' | 'normal' | 'stretch';
  gap?: string;
  flex?: number | string;
  children: ReactElement | ReactElement[] | null;
};

const Box = ({
  className = '',
  wrap = 'nowrap',
  flexDirection = 'row',
  alignContent = 'center',
  justifyContent,
  alignItems,
  gap,
  flex,
  children,
}: BoxProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: wrap,
        flexDirection: flexDirection,
        alignContent: alignContent,
        justifyContent: justifyContent,
        alignItems: alignItems,
        gap: gap,
        flex: flex,
      }}
      className={className}
    >
      {children}
    </div>
  );
};

export default Box;
