import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Icon, IconType } from '@platform-for-public-places/components-library';

import ProfileHeader from 'src/shared/components/ProfileHeader/ProfileHeader';
import { YANDEX_ZEN } from 'src/shared/constants/externalLinks';
import { paths } from 'src/shared/routes';

import { setMobileMenuOpen } from 'src/features/control/slices/controlSlice';
import CreateProjectButton from 'src/features/project/components/CreateProjectButton/CreateProjectButton';

import './MobileSidebar.scss';

interface MobileSidebarProps {
  className?: string;
}

interface SidebarLinks {
  path: string;
  icon: number;
  text: string;
}
const MobileSidebar = ({ className = '' }: MobileSidebarProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation('app', { keyPrefix: 'mobileSidebar' });

  const links: SidebarLinks[] = [
    {
      path: paths.map,
      icon: IconType.MapIcon,
      text: t('map'),
    },
    {
      path: paths.projects,
      icon: IconType.Projects,
      text: t('projects'),
    },
    {
      path: paths.members,
      icon: IconType.Participants,
      text: t('specialists'),
    },
    {
      path: paths.instruction,
      icon: IconType.Instructions,
      text: t('instructions'),
    },
    {
      path: paths.reporting,
      icon: IconType.Reports,
      text: t('reporting'),
    },
  ];

  const rendererLinks: JSX.Element = (
    <ul className="mobile-sidebar__links">
      {links.map((element) => (
        <li className="mobile-sidebar__link-element" key={element.text}>
          <NavLink
            className="mobile-sidebar__link"
            to={element.path}
            onClick={() => dispatch(setMobileMenuOpen(false))}
          >
            <>
              <Icon
                icon={element.icon}
                className="mobile-sidebar__link-icon mobile-sidebar__link-icon--circled"
              />
              {element.text}
            </>
          </NavLink>
        </li>
      ))}
      <li className="mobile-sidebar__link-element">
        <a
          target="_blank"
          rel="noreferrer"
          href={YANDEX_ZEN}
          className="mobile-sidebar__link"
        >
          <Icon
            icon={IconType.YandexZen}
            className="mobile-sidebar__link-icon mobile-sidebar__link-icon--filled"
          />
          {t('bestPractices')}
        </a>
      </li>
    </ul>
  );

  return (
    <div className={`${className} mobile-sidebar`}>
      <ProfileHeader className="mobile-sidebar__profile-header" />
      {rendererLinks}
      <CreateProjectButton
        className="mobile-sidebar__create-project-button"
        text={t('createProject')}
      />
    </div>
  );
};

export default MobileSidebar;
