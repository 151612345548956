import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { UploadFileRequestData } from '../models';

export const MINIO_API = 'MINIO_API';

export const minIoApi = createApi({
  reducerPath: MINIO_API,
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    uploadFile: builder.mutation<void, UploadFileRequestData>({
      query: ({ tempUrl, data, contentType }) => {
        return {
          url: tempUrl,
          method: 'PUT',
          headers: { 'Content-Type': contentType },
          body: data,
        };
      },
    }),
  }),
});

export const { useUploadFileMutation } = minIoApi;
export const minIoMiddleware = minIoApi.middleware;

export default minIoApi.reducer;
