import { LegacyRef, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  PROJECT_STATUS,
  Status,
} from '@platform-for-public-places/components-library';

import Spinner from 'src/shared/components/Spinner/Spinner';

import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { setSelectedUserId } from 'src/features/searchWithFilter/filter/slices/filterSliceData';
import { EventParticipants } from 'src/features/user/models';

import { PARTICIPANTS_DEFAULT_PAGE_SIZE } from 'src/pages/project/constants';

export enum TYPE_RENDER {
  PREVIEW_MEMBER_WITH_BUTTON_SHOW_MORE = 'PREVIEW_MEMBER_WITH_BUTTON_SHOW_MORE',
  HIDE_TEAM_MEMBERS = 'HIDE_TEAM_MEMBERS',
  SHOW_ALL_TEAM = 'SHOW_ALL_TEAM',
}

export interface EventParticipantsListProps {
  projectId: string;
  isAdmin: boolean;
  isSmallScreen: boolean;
  displayInitiator?: boolean;
  isInitiatorProjectOwner: boolean;
  initiatorName?: string;
  initiatorId?: string;
  onUserClick?: (userId: string) => void;
  emptyTeamLabel?: string;
  memberButtonComponent?: (memberId: string) => JSX.Element;
  memberContainerClassName?: string;
  hideTeamMembers?: boolean;
  typeRender: TYPE_RENDER;
  previewTeamMembers: boolean;
  previewMemberIdByRedirect?: string;
  hasParticipantsNextPage: boolean;
  participants: EventParticipants;
  userCatalog: LegacyRef<HTMLDivElement>;
  isLoading?: boolean;
  countOfJoined: number;
  countOfConfirmed: number;
  status: Status;
}

const EventParticipantsList = ({
  isAdmin,
  isSmallScreen,
  displayInitiator = true,
  isInitiatorProjectOwner,
  initiatorName,
  initiatorId,
  onUserClick,
  emptyTeamLabel,
  memberButtonComponent,
  memberContainerClassName,
  hideTeamMembers = false,
  typeRender,
  previewTeamMembers,
  previewMemberIdByRedirect = '',
  hasParticipantsNextPage,
  userCatalog,
  participants,
  isLoading = false,
  countOfJoined,
  countOfConfirmed,
  status,
}: EventParticipantsListProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('app', { keyPrefix: 'detailedProject' });

  const [participantsMore, setParticipantsMore] = useState(false);
  const [wasMemberOpenByRedirect, setWasMemberOpenByRedirect] =
    useState<boolean>(false);

  const onMemberClick = useCallback(
    (userId: string | undefined) => {
      dispatch(setSelectedUserId(userId ?? ''));
      if (onUserClick && isSmallScreen) {
        onUserClick(userId ?? '');
      } else {
        dispatch(changeModal(MODALS.SPECIALIST_USER_CARD));
      }
    },
    [dispatch, onUserClick, isSmallScreen]
  );

  useEffect(() => {
    if (!wasMemberOpenByRedirect && previewMemberIdByRedirect) {
      setWasMemberOpenByRedirect(true);
      onMemberClick(previewMemberIdByRedirect);
    }
  }, [onMemberClick, previewMemberIdByRedirect, wasMemberOpenByRedirect]);

  const renderMemberWithButton = useCallback(
    (memberButton: JSX.Element, projectSpecialistId?: string) => (
      <div className={`member__container ${memberContainerClassName ?? ''}`}>
        {memberButton}
        {memberButtonComponent && projectSpecialistId
          ? memberButtonComponent(projectSpecialistId)
          : null}
      </div>
    ),
    [memberButtonComponent, memberContainerClassName]
  );

  const renderSpinner = () => <Spinner className={'members__spinner'} />;

  const renderPerson = useCallback(
    (
      role: string,
      name: string,
      index?: number,
      userId?: string,
      projectSpecialistId?: string
    ) => {
      const memberButton = (
        <Button
          key={index}
          className="member__block"
          type={BUTTON_TYPE.TEXT_SECONDARY}
          onClick={() => onMemberClick(userId)}
        >
          {typeRender === TYPE_RENDER.HIDE_TEAM_MEMBERS ? (
            <div className="members__mobile-container">
              <p className="members__mobile-container-name">{name}</p>
              <p className="members__mobile-container-role">{role}</p>
            </div>
          ) : (
            <p className="member__name">
              <Trans
                i18nKey={'detailedProject.teamMember'}
                values={{
                  name: name,
                  type: role,
                }}
                components={{
                  span: (
                    <span
                      className={`member__name_clickable${
                        isSmallScreen ? '-reverted' : ''
                      }`}
                    />
                  ),
                }}
              />
            </p>
          )}
        </Button>
      );
      return memberButtonComponent
        ? renderMemberWithButton(memberButton, projectSpecialistId)
        : memberButton;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, isAdmin]
  );

  const renderParticipants = useCallback(
    (name: string, index?: number, userId?: string) => {
      const memberButton = (
        <Button
          key={index}
          className="member__block member__block-small-margin"
          type={BUTTON_TYPE.TEXT_SECONDARY}
          onClick={() => onMemberClick(userId)}
        >
          {typeRender === TYPE_RENDER.HIDE_TEAM_MEMBERS ? (
            <div className="members__mobile-container">
              <p className="members__mobile-container-name">{name}</p>
            </div>
          ) : (
            <p className="member__name">
              <span
                className={`member__name_clickable${
                  isSmallScreen ? '-reverted' : ''
                }`}
              >
                {name}
              </span>
            </p>
          )}
        </Button>
      );
      return memberButtonComponent
        ? renderMemberWithButton(memberButton, userId)
        : memberButton;
    },
    [
      isSmallScreen,
      memberButtonComponent,
      onMemberClick,
      renderMemberWithButton,
      typeRender,
    ]
  );

  const renderTeam = useCallback(() => {
    const isEmptyTeam =
      !isInitiatorProjectOwner &&
      countOfJoined === 0 &&
      status.value !== PROJECT_STATUS.FINISHED;

    if (isEmptyTeam) {
      return (
        <p className="members__empty-tag">
          {emptyTeamLabel ?? t('emptyParticipants')}
        </p>
      );
    } else if (previewTeamMembers && !participantsMore) {
      let agreedPerson = 0;
      return (
        <>
          {!isSmallScreen ? (
            <p className="member__subheader">
              <Trans
                i18nKey={
                  status.value === PROJECT_STATUS.FINISHED
                    ? 'detailedProject.confirmedParticipants'
                    : 'detailedProject.possibleParticipants'
                }
                values={{
                  count:
                    status.value === PROJECT_STATUS.FINISHED
                      ? countOfConfirmed
                      : countOfJoined,
                }}
              />
            </p>
          ) : null}
          {participants.eventParticipants
            ?.filter((value) => {
              if (agreedPerson < PARTICIPANTS_DEFAULT_PAGE_SIZE) {
                agreedPerson += 1;
                return value;
              }
            })
            .map((value, index) =>
              renderParticipants(value.name, index, value.userId)
            )}
        </>
      );
    } else {
      return (
        <>
          {!isSmallScreen ? (
            <p className="member__subheader">
              <Trans
                i18nKey={
                  status.value === PROJECT_STATUS.FINISHED
                    ? 'detailedProject.confirmedParticipants'
                    : 'detailedProject.possibleParticipants'
                }
                values={{
                  count:
                    status.value === PROJECT_STATUS.FINISHED
                      ? countOfConfirmed
                      : countOfJoined,
                }}
              />
            </p>
          ) : null}
          {participants.eventParticipants.map((value, index) =>
            renderParticipants(value.name, index, value.userId)
          )}
        </>
      );
    }
  }, [
    emptyTeamLabel,
    isInitiatorProjectOwner,
    previewTeamMembers,
    renderParticipants,
    t,
    participantsMore,
    status,
    participants.eventParticipants,
    isSmallScreen,
    countOfJoined,
    countOfConfirmed,
  ]);

  const renderCollapseAndExpandButton = useCallback(() => {
    if (
      participants.eventParticipants.length > PARTICIPANTS_DEFAULT_PAGE_SIZE
    ) {
      return (
        <Button
          className={`members__button ${
            participantsMore ? 'members__button--open' : ''
          }`}
          type={BUTTON_TYPE.TEXT_DARK}
          onClick={() => {
            setParticipantsMore((prev) => !prev);
          }}
          icon
          fill
        >
          {t(participantsMore ? 'buttonLess' : 'buttonMore')}
          <Icon icon={IconType.Chevron} />
        </Button>
      );
    }
  }, [participants, participantsMore, t, setParticipantsMore]);

  const conditionInvokeInfinityScroll = () => {
    if (typeRender === TYPE_RENDER.PREVIEW_MEMBER_WITH_BUTTON_SHOW_MORE) {
      return (
        participants.eventParticipants.length <
          PARTICIPANTS_DEFAULT_PAGE_SIZE || participantsMore
      );
    } else if (typeRender === TYPE_RENDER.HIDE_TEAM_MEMBERS) {
      return !hideTeamMembers;
    } else return true;
  };

  const renderTeamContent = () => (isLoading ? renderSpinner() : renderTeam());

  return (
    <>
      <div
        className={`members__button-list ${
          participantsMore ? 'members__button-list--open' : ''
        }`}
      >
        {displayInitiator
          ? renderPerson(
              t('initiator'),
              `${initiatorName || ''} ${
                isInitiatorProjectOwner ? t('you') : ''
              }`,
              initiatorId ? +initiatorId : undefined,
              initiatorId
            )
          : null}
        {hideTeamMembers ? null : renderTeamContent()}
        {hasParticipantsNextPage && conditionInvokeInfinityScroll() ? (
          <div className="user-container__loading-trigger" ref={userCatalog} />
        ) : null}
      </div>
      {typeRender === TYPE_RENDER.PREVIEW_MEMBER_WITH_BUTTON_SHOW_MORE
        ? renderCollapseAndExpandButton()
        : null}
    </>
  );
};

export default EventParticipantsList;
