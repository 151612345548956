import { ReactElement } from 'react';

import { Icon, IconType } from '@platform-for-public-places/components-library';

import './Accordion.scss';

type AccordionProps = {
  id: string;
  label: ReactElement | string;
  children: ReactElement;
};

const Accordion = ({ id, label, children }: AccordionProps) => {
  return (
    <section className="accordion__container">
      <div className="accordion">
        <input type="checkbox" id={`accordion-${id}`} />
        <label htmlFor={`accordion-${id}`} className="accordion__label">
          {label}
          <Icon icon={IconType.Chevron} className="accordion__icon" />
        </label>
        <div className="accordion__content">{children}</div>
      </div>
    </section>
  );
};

export default Accordion;
