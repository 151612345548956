import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  SORTING_ORDER_BY,
  SORTING_ORDER_DIRECTION,
} from 'src/features/searchWithFilter/filter/searchFilters/FilterOrder/enums';

import { ProjectFilterOption, ProjectFilterStatus } from '../models';

export const FILTER = 'FILTER';

interface FilterState {
  searchString: string;
  selectedCategory: string[];
  sortingOrderType: SORTING_ORDER_BY;
  sortingOrderDirection: SORTING_ORDER_DIRECTION;
  page: number;
  type?: string[];
  status?: string[];
  allTypes?: ProjectFilterOption[];
  allStatus?: ProjectFilterStatus[];
}

const initialState: FilterState = {
  searchString: '',
  selectedCategory: [],
  sortingOrderType: SORTING_ORDER_BY.NAME,
  sortingOrderDirection: SORTING_ORDER_DIRECTION.ASC,
  type: [],
  status: [],
  page: 1,
  allTypes: [],
  allStatus: [],
};

export const filterSlice = createSlice({
  name: FILTER,
  initialState,
  reducers: {
    setSearchString: (state: FilterState, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    setSelectedCategory: (
      state: FilterState,
      action: PayloadAction<string[]>
    ) => {
      state.selectedCategory = action.payload;
    },
    setSortingOrderType: (
      state: FilterState,
      action: PayloadAction<SORTING_ORDER_BY>
    ) => {
      state.sortingOrderType = action.payload;
    },
    setSortingOrderDirection: (
      state: FilterState,
      action: PayloadAction<SORTING_ORDER_DIRECTION>
    ) => {
      state.sortingOrderDirection = action.payload;
    },
    setPage: (state: FilterState, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setType: (
      state: FilterState,
      action: PayloadAction<string[] | undefined>
    ) => {
      state.type = action.payload;
    },
    setStatus: (
      state: FilterState,
      action: PayloadAction<string[] | undefined>
    ) => {
      state.status = action.payload;
    },
    addRemoveStatus: (
      state: FilterState,
      action: PayloadAction<{ isNew?: boolean; value: string[] | undefined }>
    ) => {
      state.status = action.payload.isNew
        ? [...(state.status ?? []), ...(action.payload.value ?? [])]
        : state.status?.filter((f) => !action.payload.value?.includes(f));
    },
    setAllType: (
      state: FilterState,
      action: PayloadAction<ProjectFilterOption[] | undefined>
    ) => {
      state.allTypes = action.payload;
    },
    setAllStatus: (
      state: FilterState,
      action: PayloadAction<ProjectFilterStatus[] | undefined>
    ) => {
      state.allStatus = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  setSearchString,
  setSelectedCategory,
  setSortingOrderType,
  setSortingOrderDirection,
  setPage,
  setType,
  setStatus,
  reset,
  setAllType,
  setAllStatus,
  addRemoveStatus,
} = filterSlice.actions;

export default filterSlice.reducer;
