import { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import './ProjectDocs.scss';

interface ProjectDocsProps {
  title: string;
  style?: CSSProperties;
  renderDocs: () => JSX.Element[];
}

const ProjectDocs = ({ title, style, renderDocs }: ProjectDocsProps) => {
  const isSmallScreen = useCheckSmallScreen();
  const [docsMore, setDocsMore] = useState(false);
  const { t } = useTranslation('app', { keyPrefix: 'detailedProject' });

  const docs = renderDocs();
  const projectDocs =
    docs.length > 0 ? (
      docs
    ) : (
      <p className="project-docs-list--empty">{t('emptyDocs')}</p>
    );

  return isSmallScreen ? (
    <div className="project-docs__mobile">
      <Button
        type={BUTTON_TYPE.TEXT_DARK}
        onClick={() => {
          setDocsMore((prev) => !prev);
        }}
        icon
        fill
        className={`project-docs__title project-docs__button ${
          docsMore ? 'project-docs__button--open' : ''
        }`}
        disabled={!isSmallScreen}
      >
        {title}
        {isSmallScreen ? <Icon icon={IconType.Chevron} /> : null}
      </Button>
      {docsMore ? (
        <div className="project-docs__list">{projectDocs}</div>
      ) : null}
    </div>
  ) : (
    <>
      <h5 className="project-docs__title">{title}</h5>
      <div className="project-docs__list" style={style}>
        {projectDocs}
      </div>
    </>
  );
};

export default ProjectDocs;
