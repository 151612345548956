import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';

import './FilterItem.scss';

interface FilterItemProps {
  name: string;
  onClick: () => void;
  isSelected?: boolean;
}

const FilterItem = ({
  name,
  onClick,
  isSelected = false,
}: FilterItemProps): JSX.Element => {
  const onFilterClick = () => {
    onClick();
  };

  return (
    <Button
      type={BUTTON_TYPE.TEXT}
      onClick={onFilterClick}
      className={`filter-item ${isSelected ? 'filter-item_active' : ''}`}
      icon
    >
      <Icon
        icon={IconType.CheckmarkTiny}
        className={`filter-item__icon ${
          isSelected ? 'filter-item__icon_active' : 'filter-item__icon_hide'
        }`}
      />
      {name}
    </Button>
  );
};

export default FilterItem;
