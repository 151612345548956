import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature, Polygon } from 'geojson';

import { ACTION_TYPE, BACKGROUND_TYPE } from '../enums';

export const MAP = 'MAP';

export interface MapState {
  background: BACKGROUND_TYPE;
  action: ACTION_TYPE;
  geodata: Feature<Polygon>;
  bbox: string;
  size: string;
  zoom: number;
}

const initialState: MapState = {
  background: BACKGROUND_TYPE.SCHEME,
  action: ACTION_TYPE.CREATE,
  geodata: {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [55.14591884159685, 73.08998107910158],
          [55.14591884159685, 73.6736297607422],
          [54.8267990912, 73.6736297607422],
          [54.8267990912, 73.08998107910158],
          [55.14591884159685, 73.08998107910158],
        ],
      ],
    },
    properties: {},
  },
  bbox: '',
  size: '',
  zoom: 13,
};

const mapSlice = createSlice({
  name: MAP,
  initialState,
  reducers: {
    setGeodata: (state: MapState, action: PayloadAction<Feature<Polygon>>) => {
      state.geodata = action.payload;
    },
    changeBackground: (state: MapState, action: PayloadAction<number>) => {
      state.background = action.payload;
    },
    changeBbox: (state: MapState, action: PayloadAction<string>) => {
      state.bbox = action.payload;
    },
    changeSize: (state: MapState, action: PayloadAction<string>) => {
      state.size = action.payload;
    },
    changeZoom: (state: MapState, action: PayloadAction<number>) => {
      state.zoom = action.payload;
    },
    changeAction: (state: MapState, action: PayloadAction<ACTION_TYPE>) => {
      state.action = action.payload;
    },
  },
});

export const {
  setGeodata,
  changeBackground,
  changeBbox,
  changeSize,
  changeZoom,
  changeAction,
} = mapSlice.actions;

export default mapSlice.reducer;
