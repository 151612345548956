import { Trans, useTranslation } from 'react-i18next';

import { truncateZeroDecimal } from 'src/shared/functions/numbers';

import { useGetTotalAmountUserPaymentsQuery } from 'src/features/payment/api';

import './UserTotalDonation.scss';

const UserTotalDonation = () => {
  const { t } = useTranslation('app', {
    keyPrefix: `profile.payments`,
  });

  const { data: totalAmount } = useGetTotalAmountUserPaymentsQuery({
    searchString: '%%',
  });

  return (
    <>
      {totalAmount?.data.total ? (
        <div className="user-total-donation">
          <h6 className="user-total-donation__total-donation">
            <Trans
              i18nKey="profile.payments.donation"
              values={{
                amount: t('amount', {
                  amount: totalAmount?.data.total ?? 0,
                  formatParams: {
                    amount: {
                      maximumFractionDigits: truncateZeroDecimal(
                        totalAmount?.data.total ?? 0
                      ),
                    },
                  },
                }),
              }}
              components={{
                span: (
                  <span className="user-total-donation__total-donation-amount" />
                ),
              }}
            />
          </h6>
        </div>
      ) : null}
    </>
  );
};

export default UserTotalDonation;
