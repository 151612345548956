import { useTranslation } from 'react-i18next';

import { stringUTCDateToUTCDate } from '@platform-for-public-places/components-library';

import { truncateZeroDecimal } from 'src/shared/functions/numbers';
import { paths } from 'src/shared/routes';

import { usePaymentStatusTranslation } from 'src/features/payment/components/AllUserPaymentsInfo/components/index';
import { PAYMENT_METHOD, PAYMENT_STATUS } from 'src/features/payment/models';

import './AllUserPaymentsTableRow.scss';

export interface AllUserPaymentsTableContent {
  createdAt: string;
  amount: number;
  projectId: string | null;
  projectName: string;
  paymentType: PAYMENT_METHOD;
  status?: PAYMENT_STATUS;
}

export interface AllUserPaymentsTableRowProps {
  data: AllUserPaymentsTableContent;
  className?: string;
}

const AllUserPaymentsTableRow = ({
  data,
  className,
}: AllUserPaymentsTableRowProps) => {
  const { t } = useTranslation('app', {
    keyPrefix: 'profile.payments.all.row',
  });

  const paymentType = usePaymentStatusTranslation(data.paymentType);
  const paymentStatusTranslation =
    data.status && Object.values(PAYMENT_STATUS).includes(data.status)
      ? t(`status.${data.status}`)
      : t('status.unknown');

  return (
    <div className={`all-user-payments-table-row ${className}`}>
      <span>
        {t('date.createdAt', {
          createdAt: stringUTCDateToUTCDate(data.createdAt),
          formatParams: {
            createdAt: { year: 'numeric', month: 'numeric', day: 'numeric' },
          },
        })}
      </span>

      <span>
        {t('amount', {
          amount: data.amount,
          formatParams: {
            amount: {
              maximumFractionDigits: truncateZeroDecimal(data.amount),
            },
          },
        })}
      </span>

      <a
        className="all-user-payments-table-row__link"
        href={
          data.projectId ? paths.projectById(data.projectId) : paths.frontPage
        }
        rel="noopener noreferrer"
        target="_blank"
      >
        {data.projectName}
      </a>

      <span>{paymentType}</span>

      {data.status ? (
        <span className={`all-user-payments-table-row__status--${data.status}`}>
          {paymentStatusTranslation}
        </span>
      ) : null}
    </div>
  );
};

export default AllUserPaymentsTableRow;
