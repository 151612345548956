import {
  createContext,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { UseInfiniteScrollHookResult } from 'react-infinite-scroll-hook/dist/useInfiniteScroll';
import { useSelector } from 'react-redux';

import { SORTING_ORDER_DIRECTION } from '@platform-for-public-places/components-library';

import { TYPE_SEARCH_PAGE } from 'src/features/map/components/SearchPane/components/PaginationList/PaginationList';
import { ProjectCatalogData } from 'src/features/project/models';
import { SORTING_ORDER_BY } from 'src/features/searchWithFilter/filter/searchFilters/FilterOrder/enums';
import { FILTER } from 'src/features/searchWithFilter/filter/slices/filterSlice';
import { State } from 'src/features/store/store';

type PaginationContextType = {
  emptyList: boolean;
  hasNextPage: boolean;
  paginationData?: UseInfiniteScrollHookResult;
  loading: boolean;
};

export const PaginationContext = createContext<PaginationContextType>({
  emptyList: false,
  hasNextPage: false,
  paginationData: undefined,
  loading: true,
} as PaginationContextType);

type PaginationListProviderProps = {
  children: ReactElement | ReactElement[];
  typeSearchPage: TYPE_SEARCH_PAGE;
  searchProjects: (
    //todo fix add type
    isUserPage: boolean,
    searchString: string,
    page: number,
    setPage: (v: number) => void,
    setHasNextPage: (v: boolean) => void,
    setError: (v: boolean) => void,
    setLoading: (v: boolean) => void,
    type?: string[],
    status?: string[],
    sortingOrderType?: SORTING_ORDER_BY,
    sortingOrderDirection?: SORTING_ORDER_DIRECTION
  ) => void;
  disabled?: boolean;
  projects?: ProjectCatalogData[];
  emptyListLabel?: string;
  reset?: boolean;
  setReset?: (v: boolean) => void;
};

export const PaginationListProvider = ({
  children,
  typeSearchPage,
  searchProjects,
  disabled = false,
  projects,
  reset,
  setReset,
}: PaginationListProviderProps) => {
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [error, setError] = useState<boolean>(false);

  const {
    searchString,
    type,
    status,
    sortingOrderType,
    sortingOrderDirection,
  } = useSelector((s: State) => s[FILTER]);

  const paginationData = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => {
      setLoading(true);
      searchProjects(
        typeSearchPage === TYPE_SEARCH_PAGE.USER_PROJECTS,
        searchString,
        page,
        setPage,
        setHasNextPage,
        setError,
        setLoading,
        type,
        status,
        sortingOrderType,
        sortingOrderDirection
      );
    },
    disabled: disabled || error,
    delayInMs: 500,
  });
  useEffect(() => {
    if (reset) {
      setReset && setReset(false);
      setPage(1);
      setError(false);
      setHasNextPage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, projects]);

  const emptyList = useMemo(
    () => !(loading || hasNextPage || projects?.length),
    [loading, hasNextPage, projects?.length]
  );
  return (
    <PaginationContext.Provider
      value={{
        paginationData,
        loading,
        emptyList,
        hasNextPage,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};
