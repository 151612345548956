import { useSelector } from 'react-redux';

import clsx from 'clsx';

import { CONTROL } from 'src/features/control/slices/controlSlice';
import SearchFilterInput, {
  SearchPanelState,
} from 'src/features/map/components/SearchPane/components/SearchFilterInput/SearchFilterInput';
import SearchProjectFilters from 'src/features/searchWithFilter/filter/searchFilters/SearchFilters';
import SearchProjectList from 'src/features/searchWithFilter/listProjects/SearchProjectList';
import { State } from 'src/features/store/store';

const SearchPanelContentMapDesktop = () => {
  const visible = useSelector((s: State) => s[CONTROL].searchPanelState);
  return (
    <div
      className={clsx('search-pane', {
        'search-pane--visible':
          visible === SearchPanelState.OPEN ||
          visible === SearchPanelState.OPEN_WITH_FILTER,
        'search-pane--visible-with-filters':
          visible === SearchPanelState.OPEN_WITH_FILTER,
      })}
    >
      <div className="search-pane__frame search-pane__frame-map">
        <SearchFilterInput />
        <SearchProjectList />
      </div>
      {visible ? (
        <SearchProjectFilters
          className={
            visible === SearchPanelState.OPEN_WITH_FILTER
              ? 'search-pane__filters search-pane__filters-map'
              : 'search-pane__filters--close'
          }
        />
      ) : null}
    </div>
  );
};

export default SearchPanelContentMapDesktop;
