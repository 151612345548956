import { useState } from 'react';

import { randUuid } from '@ngneat/falso';
import {
  ActorBaseResponse,
  FakeResponse,
} from '@platform-for-public-places/components-library';

import { LazyQuery } from 'src/shared/mock/models';
import { useCreateLazyQueryMock } from 'src/shared/mock/utils';

import {
  ConfirmSignUpRequest,
  GetPasswordRecoveryLinkRequest,
  SignInRequest,
  SignUpRequest,
  USER_ROLE,
  VerifyCaptchaResponse,
  WhoamiResponse,
} from '../models';

export const AUTH_KEY = 'AUTH_KEY';

const WHOAMI_DATA: ActorBaseResponse<WhoamiResponse> = {
  data: {
    userID: 'e6a2210c-b7bd-4d99-a8e4-7fdc9e0083f9',
    role: 'ADMIN',
    email: 'test@mail.com',
    profile: {
      name: 'Довольный Валентин',
      socialLink: 'admin-group l.t.d.',
      userId: 'e6a2210c-b7bd-4d99-a8e4-7fdc9e0083f9',
      createdAt: '1970-01-01T00:00:00.000+0000',
      updatedAt: '1970-01-01T00:00:00.000+0000',
      profileID: '30048e4a-20fd-4424-8b76-e49ae6a10178',
      privacyPolicyConfirmed: true,
    },
  },
  success: true,
  errors: [],
};

interface FakeWhoamiResponse extends FakeResponse {
  data: ActorBaseResponse<WhoamiResponse>;
}

export const useLazySignInQuery = (): [
  (arg0: SignInRequest) => Promise<FakeResponse>,
  FakeResponse
] => {
  const [response, setResponse] = useState<FakeResponse>({
    isSuccess: false,
    isFetching: true,
    isError: false,
  });

  const trigger = (request: SignInRequest) => {
    if (request.email && request.password) {
      localStorage.setItem(AUTH_KEY, randUuid());
      const _response = {
        isSuccess: true,
        isFetching: false,
        isError: false,
      };
      setResponse(_response);
      return Promise.resolve(_response);
    } else {
      localStorage.removeItem(AUTH_KEY);
      const _response = {
        isSuccess: false,
        isFetching: false,
        isError: true,
      };
      setResponse(_response);
      return Promise.reject(_response);
    }
  };
  return [trigger, response];
};

export const useLazySignUpQuery = (): [
  (arg0: SignUpRequest) => Promise<FakeResponse>,
  FakeResponse
] => {
  const [response, setResponse] = useState<FakeResponse>({
    isSuccess: false,
    isFetching: true,
    isError: false,
  });

  const trigger = (request: SignUpRequest) => {
    if (request.name && request.email && request.password) {
      const _response = {
        isSuccess: true,
        isFetching: false,
        isError: false,
      };
      setResponse(_response);
      return Promise.resolve(_response);
    } else {
      const _response = {
        isSuccess: false,
        isFetching: false,
        isError: true,
      };
      setResponse(_response);
      return Promise.reject(_response);
    }
  };
  return [trigger, response];
};

export const useLazyConfirmSignUpQuery = (): [
  (request: ConfirmSignUpRequest) => Promise<FakeResponse>,
  FakeResponse
] => {
  const [response, setResponse] = useState<FakeResponse>({
    isSuccess: false,
    isFetching: true,
    isError: false,
  });

  const trigger = (request: ConfirmSignUpRequest) => {
    if (request.userId) {
      setResponse({
        isSuccess: true,
        isFetching: false,
        isError: false,
      });
    } else {
      setResponse({
        isSuccess: false,
        isFetching: false,
        isError: true,
      });
    }
    return Promise.resolve(response);
  };
  return [trigger, response];
};

export const useLazyWhoamiQuery = (): [
  () => Promise<FakeWhoamiResponse>,
  FakeWhoamiResponse
] => {
  const [response, setResponse] = useState<FakeWhoamiResponse>({
    isSuccess: false,
    isFetching: true,
    isError: false,
    data: {
      data: {
        email: '',
        userID: '',
        role: USER_ROLE.USER,
      },
      errors: [],
      success: false,
    },
  });

  const trigger = () => {
    if (localStorage.getItem(AUTH_KEY)) {
      const res: FakeWhoamiResponse = {
        isSuccess: true,
        isFetching: false,
        isError: false,
        data: {
          ...WHOAMI_DATA,
        },
      };
      setResponse(res);
      return Promise.resolve(res);
    } else {
      setResponse({
        isSuccess: false,
        isFetching: false,
        isError: true,
        data: {
          data: {
            email: '',
            userID: '',
            role: USER_ROLE.USER,
            profile: {
              name: '',
              socialLink: '',
              userId: '',
              createdAt: '1970-01-01T00:00:00.000+0000',
              updatedAt: '1970-01-01T00:00:00.000+0000',
              profileID: '',
              privacyPolicyConfirmed: false,
            },
          },
          errors: [],
          success: false,
        },
      });
      return Promise.reject();
    }
  };
  return [trigger, response];
};

export const useWhoamiQuery = (): FakeWhoamiResponse => {
  const [wasNotExecuted, setNotExecuted] = useState<boolean>(true);
  const [response, setResponse] = useState<FakeWhoamiResponse>({
    isSuccess: false,
    isFetching: false,
    isLoading: true,
    isError: false,
    data: {
      data: {
        email: '',
        userID: '',
        role: USER_ROLE.USER,
      },
      errors: [],
      success: false,
    },
  });

  if (wasNotExecuted) {
    setNotExecuted(false);
    if (localStorage.getItem(AUTH_KEY)) {
      const res = {
        isSuccess: true,
        isFetching: false,
        isLoading: false,
        isError: false,
        data: {
          ...WHOAMI_DATA,
        },
      };
      setResponse(res);
    } else {
      setResponse({
        isSuccess: false,
        isFetching: false,
        isLoading: false,
        isError: true,
        data: {
          data: {
            email: '',
            userID: '',
            role: USER_ROLE.USER,
            profile: {
              name: '',
              socialLink: '',
              userId: '',
              createdAt: '1970-01-01T00:00:00.000+0000',
              updatedAt: '1970-01-01T00:00:00.000+0000',
              profileID: '',
              privacyPolicyConfirmed: false,
            },
          },
          errors: [],
          success: false,
        },
      });
    }
  }

  return response;
};

export const useLazyLogoutQuery = (): [
  () => Promise<FakeResponse>,
  FakeResponse
] => {
  const [response, setResponse] = useState<FakeResponse>({
    isSuccess: false,
    isFetching: true,
    isError: false,
  });

  const trigger = () => {
    setResponse({
      isSuccess: true,
      isFetching: false,
      isError: false,
    });
    localStorage.removeItem(AUTH_KEY);
    return Promise.resolve(response);
  };
  return [trigger, response];
};

export const useConfirmPasswordRecoveryMutation = (): [
  () => Promise<FakeResponse>,
  FakeResponse
] => {
  const [response, setResponse] = useState<FakeResponse>({
    isSuccess: false,
    isFetching: true,
    isError: false,
  });

  const trigger = () => {
    const resp = {
      isSuccess: true,
      isFetching: false,
      isError: false,
    };
    setResponse(resp);
    return Promise.resolve(resp);
  };
  return [trigger, response];
};

export const useGetPasswordRecoveryLinkMutation = (): [
  (request: GetPasswordRecoveryLinkRequest) => Promise<FakeResponse>,
  FakeResponse
] => {
  const [response, setResponse] = useState<FakeResponse>({
    isSuccess: false,
    isFetching: true,
    isError: false,
  });

  const trigger = () => {
    const resp = {
      isSuccess: true,
      isFetching: false,
      isError: false,
    };
    setResponse(resp);
    return Promise.resolve(resp);
  };
  return [trigger, response];
};

export const useLazyVerifyCaptchaQuery =
  (): LazyQuery<VerifyCaptchaResponse> => {
    const createPath = () => 'captcha/verify.json';
    return useCreateLazyQueryMock<VerifyCaptchaResponse>(createPath);
  };
