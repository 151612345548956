import { useMemo } from 'react';

import Box from 'src/shared/components/Box/Box';

import { ProjectFilterOption } from 'src/features/searchWithFilter/filter/models';
import CheckBoxFilterListElement from 'src/features/searchWithFilter/filter/searchFilters/CheckBoxFilterList/components/CheckBoxFilterListElement';

const CheckBoxFilterList = ({
  listValues,
  color,
  onChange,
  selectedList,
  className,
}: {
  listValues: ProjectFilterOption[];
  color?: string;
  onChange: (newValues: string[], changedValue: string, isNew: boolean) => void;
  selectedList?: string[];
  className?: string;
}) => {
  const updateCheck = (id: string, check: boolean) => {
    const value = listValues.find((f) => f.id === id);
    const prevList = selectedList ?? [];
    if (value) {
      onChange(
        !check ? [...prevList, value?.id] : prevList.filter((f) => f !== id),
        value.id,
        !check
      );
    }
  };

  return useMemo(
    () => (
      <Box
        className={className}
        flexDirection={'column'}
        gap={'0.5em'}
        flex={1}
      >
        {listValues.map((v) => (
          <CheckBoxFilterListElement
            key={v.id}
            label={v.label}
            changeCheck={updateCheck}
            checked={selectedList?.includes(v.id) ?? false}
            color={color ?? v.color ?? 'var(--grey-dark)'}
            id={v.id}
            icon={v.icon}
          />
        ))}
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedList, listValues]
  );
};

export default CheckBoxFilterList;
