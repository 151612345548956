import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import { changeModal, MODAL } from 'src/features/modal/slices/modalSlice';
import { State } from 'src/features/store/store';

import { ReactComponent as LinkSentImage } from './img/recoveryPasswordLinkSent.svg';

import './AutopaymentsManagementSent.scss';

interface StateProps {
  email: string;
}

const AutopaymentsManagementSent = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const locationState = state as StateProps;
  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', {
    keyPrefix: 'autopaymentsManagementSentModal',
  });

  const email = useSelector((s: State) => s[MODAL].email);

  const onClick = () => {
    dispatch(changeModal(null));
  };

  return (
    <div className="autopayments-management-sent">
      <h3 className="auth-title autopayments-management-sent__title">
        {t('title')}
      </h3>
      <p className="auth-description autopayments-management-sent__description">
        {t('description')}
      </p>
      <p className="autopayments-management-sent__email">
        {isSmallScreen && locationState.email ? locationState.email : email}
      </p>
      <p className="auth-description autopayments-management-sent__go-by-link">
        {t('goByLink')}
      </p>
      {!isSmallScreen ? (
        <Button
          className="auth-button autopayments-management-sent__button"
          type={BUTTON_TYPE.ACCENTED}
          onClick={onClick}
        >
          {t('buttons.continue')}
        </Button>
      ) : (
        <LinkSentImage className="autopayments-management-sent__image" />
      )}
    </div>
  );
};

export default AutopaymentsManagementSent;
