import { useTranslation } from 'react-i18next';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import MobileHeader, {
  HeaderType,
} from 'src/features/mobile/components/MobileHeader/MobileHeader';
import RecurrentUserPaymentsInfo from 'src/features/payment/components/RecurrentUserPaymentsInfo/RecurrentUserPaymentsInfo';

import './RecurrentUnauthorizedUserPayments.scss';

const RecurrentUnauthorizedUserPayments = () => {
  const { t } = useTranslation('app', { keyPrefix: 'autopaymentsManagement' });

  const mobile = useCheckSmallScreen();

  return (
    <>
      {mobile ? (
        <MobileHeader
          headerType={HeaderType.Title}
          title={t('header')}
          hideBackButton
        />
      ) : null}
      <div className="recurrent-unauthorized-user-payments">
        {mobile ? null : (
          <h1 className="recurrent-unauthorized-user-payments__header">
            {t('header')}
          </h1>
        )}
        <RecurrentUserPaymentsInfo />
      </div>
    </>
  );
};

export default RecurrentUnauthorizedUserPayments;
