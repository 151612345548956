import Confirm from 'src/features/auth/components/Confirm/Confirm';

import ModalWindow from '../ModalWindow/ModalWindow';

const ConfirmModal = () => (
  <ModalWindow>
    <Confirm />
  </ModalWindow>
);

export default ConfirmModal;
