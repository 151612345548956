import { Navigate } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import ForgotPasswordForm from 'src/features/auth/components/ForgotPassword/ForgotPassword';
import { MODALS } from 'src/features/modal/models';

import './ForgotPassword.scss';

const ForgotPassword = () => {
  const isSmallScreen = useCheckSmallScreen();

  return isSmallScreen ? (
    <div className="forgot-password-page">
      <ForgotPasswordForm />
    </div>
  ) : (
    <Navigate
      to={paths.map}
      replace
      state={{ modalState: MODALS.FORGOT_PASSWORD }}
    />
  );
};

export default ForgotPassword;
