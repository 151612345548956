function formatNumericString(formatting: string[], number: string) {
  let output = '';
  for (let i = 0; i < number.length && i < formatting.length; i++) {
    output += formatting[i] + number[i];
  }

  return output;
}

export const formatTelephoneNumber: FormatterFactory = () => (number) => {
  return formatNumericString(
    ['', ' ', '', '', ' ', '', '', ' ', '', ' ', ''],
    number
  );
};
/**
 * Accepts an input string and returns a string formatted for display.
 * @param input The value to format.
 */
export interface Formatter {
  (input: string): string;
}

/**
 * Optionally accepts one or more locales and returns a Formatter.
 * @param locales The locales to use when the Formatter is invoked.
 */
export interface FormatterFactory {
  (locales?: string | string[]): Formatter;
}
