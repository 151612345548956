import { ActorBaseResponse } from '@platform-for-public-places/components-library';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import {
  ChangeFollowRequest,
  ChangeLikeRequest,
  ChangePlanForEventRequest,
  GetProjectLikesRequest,
  GetProjectLikesResponse,
} from '../models';

export const REACTION_API = 'REACTION_API';

const reactionApi = createApi({
  reducerPath: REACTION_API,
  baseQuery: retry(
    fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URLS }),
    { maxRetries: 0 }
  ),
  tagTypes: ['LIKE'],
  endpoints: (builder) => ({
    changeLike: builder.mutation<ActorBaseResponse<boolean>, ChangeLikeRequest>(
      {
        query: (request) => ({
          method: 'POST',
          body: {
            messageMapId: 'like/update-like',
            arguments: request,
          },
        }),
        invalidatesTags: ['LIKE'],
      }
    ),
    getProjectLikes: builder.query<
      ActorBaseResponse<GetProjectLikesResponse>,
      GetProjectLikesRequest
    >({
      query: (request) => {
        const { archived, ...req } = request;
        return {
          method: 'POST',
          body: {
            messageMapId: archived
              ? 'archived-like/get-like-info-by-project-id'
              : 'like/get-like-info-by-project-id',
            arguments: req,
          },
        };
      },
      providesTags: ['LIKE'],
    }),
    changeFollow: builder.mutation<
      ActorBaseResponse<boolean>,
      ChangeFollowRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'subscribe-to-project/update-project-subscriber',
          arguments: request,
        },
      }),
    }),
    changePlanForEvent: builder.mutation<
      ActorBaseResponse<boolean>,
      ChangePlanForEventRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'join-to-event/update-joined-to-event',
          arguments: request,
        },
      }),
    }),
  }),
});

export const {
  useChangeLikeMutation,
  useGetProjectLikesQuery,
  useChangeFollowMutation,
  useChangePlanForEventMutation,
} = reactionApi;

export const reactionMiddleware = reactionApi.middleware;

export default reactionApi.reducer;
