import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDispatch, useSelector } from 'react-redux';

import {
  Table,
  TableData,
} from '@platform-for-public-places/components-library';
import { ru } from 'date-fns/locale';

import i18n from 'src/app/translation/translation';

import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { ProjectTeamRequests } from 'src/features/project/models';
import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import { setSelectedUserId } from 'src/features/searchWithFilter/filter/slices/filterSliceData';
import { State } from 'src/features/store/store';

import { MembersOutgoingTableContent, MembersTableProps } from '../../models';

import MembersOutgoingRow from '../MembersOutgoingRow/MembersOutgoingRow';

import './MembersOutgoingTable.scss';

const MembersOutgoingTable = ({
  className,
  data,
  placeholder,
  error = false,
  infinite = false,
  onEndReached,
}: MembersTableProps) => {
  const dispatch = useDispatch();

  const projectType = useSelector(
    (s: State) => s[PROJ_CREATE].currentTypeProject
  );

  const { t } = useTranslation('app', {
    keyPrefix: `creation.${projectType}.team.tableColumns`,
  });
  const { t: translate } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const onLoadMore = () => {
    setLoading(true);
    onEndReached?.();
    setLoading(false);
  };

  const [tailRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: data.specialists.length < data.count,
    disabled: error,
    onLoadMore,
  });

  const getRoles = (roles: string[] = []) => {
    return roles.map((role) => {
      const roleToTranslate = `detailedProject.${role.toLowerCase()}`;
      return i18n.exists(roleToTranslate) ? translate(roleToTranslate) : role;
    });
  };

  const onMemberClick = useCallback(
    (userId: string | undefined) => {
      dispatch(setSelectedUserId(userId ?? ''));
      dispatch(changeModal(MODALS.SPECIALIST_USER_CARD));
    },
    [dispatch]
  );

  const getData = (
    data: ProjectTeamRequests
  ): MembersOutgoingTableContent[] => {
    return data.specialists.map(({ request: r, specialist }) => {
      return {
        date: new Date(r.updatedAt).toLocaleDateString(ru.code),
        specialist: {
          name: specialist.name,
          expand: () => onMemberClick(r.requestBody.projectSpecialist.userId),
        },
        roles: getRoles(r.requestBody.projectSpecialist.roles),
        status: {
          requestStatus: r.status,
          comment: r.message,
          revoke: () => null,
        },
      };
    });
  };

  const prepareTableData = (): TableData<MembersOutgoingTableContent> => ({
    columns: [
      { src: 'date', label: t('date') },
      { src: 'specialist', label: t('specialist') },
      { src: 'role', label: t('role') },
      { src: 'status', label: t('status') },
    ],
    content: getData(data),
  });

  return (
    <>
      <Table<MembersOutgoingTableContent>
        className={className}
        placeholder={placeholder}
        data={prepareTableData()}
        rowComponent={MembersOutgoingRow}
        headerClass="members-outgoing-table__header"
        columnsClass="members-outgoing-table__columns"
        bodyClass="members-outgoing-table__body"
      />
      {infinite && onEndReached ? <div ref={tailRef} /> : null}
    </>
  );
};

export default MembersOutgoingTable;
