import { ReactElement, useCallback, useEffect } from 'react';
import { MapContainer } from 'react-leaflet';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';
import { LatLngBoundsExpression, LatLngExpression, LatLngTuple } from 'leaflet';

import { BBOX_COOKIE_KEY, ZOOM_COOKIE_KEY } from 'src/app/constants';
import Spinner from 'src/shared/components/Spinner/Spinner';
import { convertFromGoogleToWgs84 } from 'src/shared/converters/index';
import { getCookie } from 'src/shared/cookies';

import {
  setCurrentCard,
  setSearchPanelState,
} from 'src/features/control/slices/controlSlice';
import {
  changeDrawEnable,
  changeNeedClear,
  resetDraw,
} from 'src/features/draw/slices/drawSlice';
import { useGetMapDataQuery } from 'src/features/map/api/index';
import MapProjectWatcher from 'src/features/map/components/MapProjectWatcher/MapProjectWatcher';
import { SearchPanelState } from 'src/features/map/components/SearchPane/components/SearchFilterInput/SearchFilterInput';
import { setGeodata } from 'src/features/map/slices/mapSlice';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';

interface StateProps {
  modalState: MODALS;
}

const MapProvider = ({
  children,
}: {
  children: ReactElement | ReactElement[];
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isSmallScreen = useCheckSmallScreen();

  const { data: mapGeodata, isFetching: isFetchingMapGeodata } =
    useGetMapDataQuery();
  const destructor = useCallback(() => {
    dispatch(changeNeedClear());
    dispatch(resetDraw());
    dispatch(setCurrentCard(null));
    dispatch(changeDrawEnable(false));
    dispatch(setSearchPanelState(SearchPanelState.CLOSE));
  }, [dispatch]);

  const bboxCookie = getCookie(BBOX_COOKIE_KEY);
  const zoomCookie = getCookie(ZOOM_COOKIE_KEY);

  useEffect(() => destructor, [destructor]);

  useEffect(() => {
    const locationState = location.state as StateProps;
    if (locationState?.modalState) {
      dispatch(changeModal(locationState?.modalState));
    }
  });

  useEffect(() => {
    if (mapGeodata && !isFetchingMapGeodata) {
      dispatch(setGeodata(mapGeodata.data.geodataJson));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingMapGeodata, mapGeodata]);

  const calculateCenter = () => {
    const bboxCoords = bboxCookie
      ? bboxCookie.split(',').map((item) => Number(item))
      : [0, 0, 0, 0];
    const NW = convertFromGoogleToWgs84({
      x: bboxCoords[0],
      y: bboxCoords[1],
    });
    const SE = convertFromGoogleToWgs84({
      x: bboxCoords[2],
      y: bboxCoords[3],
    });

    return [(NW.lat + SE.lat) / 2, (NW.lng + SE.lng) / 2] as LatLngExpression;
  };

  const DEFAULT_ZOOM = zoomCookie
    ? Number(zoomCookie)
    : mapGeodata?.data.defaultZoom;
  const MIN_ZOOM = mapGeodata?.data.minZoom;
  const MIN_ZOOM_MOBILE = mapGeodata?.data.minZoomMobile;
  const REGION_BOUNDS =
    mapGeodata?.data.geodataJson.geometry.coordinates[0].map((pos) => [
      pos[1],
      pos[0],
    ]) as LatLngBoundsExpression;

  const getRegionCapital = () => {
    if (mapGeodata) {
      return bboxCookie
        ? calculateCenter()
        : ([
            (mapGeodata.data.capital as LatLngTuple)[1],
            (mapGeodata.data.capital as LatLngTuple)[0],
          ] as LatLngExpression);
    }
  };
  return isFetchingMapGeodata ? (
    <div className="map__spinner">
      <Spinner />
    </div>
  ) : (
    <MapContainer
      className="map-container"
      zoomControl={false}
      zoom={DEFAULT_ZOOM}
      minZoom={isSmallScreen ? MIN_ZOOM_MOBILE : MIN_ZOOM}
      center={getRegionCapital()}
      maxBounds={REGION_BOUNDS}
      maxBoundsViscosity={1.0}
    >
      <MapProjectWatcher>{children}</MapProjectWatcher>
    </MapContainer>
  );
};

export default MapProvider;
