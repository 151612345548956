import { useContext } from 'react';

import Spinner from 'src/shared/components/Spinner/Spinner';

import 'src/features/searchWithFilter/components/SpinnerSearch/Spinner.scss';
import { PaginationContext } from 'src/features/map/components/SearchPane/components/PaginationList/PaginationListProvider';

const SpinnerSearch = () => {
  const { loading } = useContext(PaginationContext);
  return loading ? <Spinner className={'search-pane__spinner'} /> : null;
};

export default SpinnerSearch;
