import { FormEventHandler, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  TextInput,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import Checkbox from 'src/shared/components/Checkbox/Checkbox';
import { paths, routes } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import { useGetPresignedUrlsQuery } from 'src/features/minIo/api';
import { PresignedUrlByKeyMapId } from 'src/features/minIo/models';
import {
  BackdoorPayload,
  BypassPayload,
  MODALS,
} from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { useLazyCreatePaymentQuery } from 'src/features/payment/api';
import {
  CreatePaymentRequest,
  NewPaymentConfirmationRedirect,
} from 'src/features/payment/models';
import { useGetProjectDescriptionQuery } from 'src/features/project/api';

import { useEmail, useIsAccepted, useIsSupported, useSum } from '../hooks';
import SupportBlock from '../SupportBlock/SupportBlock';

import './DonationForm.scss';

interface DonationFormProps {
  donateForProject?: boolean;
  className?: string;
  description?: string;
  title?: string;
  projectId?: string;
  buttonText: string;
  showTitle?: boolean;
  showDelay?: boolean;
  archived?: boolean;
  idCheckox?: string;
}

const PAYMENT_DOCUMENT_KEYS = ['public_offer.pdf'];

const PROJECT_LINK = 'https://ppp.7bits.it/projects';

const DonationForm = ({
  donateForProject = false,
  className = '',
  description = '',
  title = '',
  projectId,
  buttonText,
  showTitle = false,
  showDelay = false,
  archived = false,
  idCheckox = 'accept',
}: DonationFormProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'donation.form' });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();

  const { data: projectDescription } = useGetProjectDescriptionQuery(
    { projectId: projectId as string, archived },
    { skip: !projectId }
  );

  const isSmallScreen = useCheckSmallScreen();

  const [sum, setSum, onSumChange] = useSum();
  const [email, emailError, onEmailChange, onEmailFocusLeave] = useEmail(t);

  const [isAccepted, setIsAccepted, onAccepted] = useIsAccepted();
  const [isSupported, setIsSupported, onSupported] = useIsSupported();

  const { data: whoami } = useWhoamiQuery();

  const { isSuccess } = useWhoamiQuery();
  const [createPayment] = useLazyCreatePaymentQuery();

  useEffect(
    () => {
      return () => {
        setSum('');
        setIsAccepted(false);
        setIsSupported(false);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const showEmailInput = !isSuccess && isSupported;

  const submitRestricted =
    archived ||
    !isAccepted ||
    !sum ||
    sum === '0' ||
    (showEmailInput && !!emailError.length) ||
    (showEmailInput && !email);

  const firePaymentCreation = (
    payload: CreatePaymentRequest<NewPaymentConfirmationRedirect>
  ) => {
    const failurePath = payload.metadata?.projectId
      ? paths.donationFailureProject(payload.metadata.projectId)
      : paths.donationFailurePlatform;

    createPayment(payload)
      .unwrap()
      .then(({ data, success }) => {
        if (success && data?.payment?.confirmation?.confirmation_url) {
          window.location.assign(data.payment.confirmation.confirmation_url);
          setIsAccepted(false);
          setSum('');
        } else {
          navigate(failurePath);
          console.error("Can't send to the payment gate");
        }
      })
      .catch(() => {
        navigate(failurePath);
        console.error("Can't create payment");
      });
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (submitRestricted) return;

    const origin = window.location.origin;

    const baseRequest = {
      amount: {
        value: Number(sum).toFixed(2),
        currency: 'RUB',
      },
      confirmation: {
        type: 'redirect',
        locale: 'ru_RU',
      },
      capture: true,
      metadata: { userId: whoami?.data.userID, email: email },
      recurrent: isSupported,
    };

    if (donateForProject && projectDescription) {
      firePaymentCreation({
        ...baseRequest,
        description: `${t('paymentDescription.project')} ${PROJECT_LINK}/${
          projectDescription.data.description.projectID
        }`,
        confirmation: {
          ...baseRequest.confirmation,
          returnUrlConfirmation: `${origin}${paths.projectById(
            projectDescription.data.description.projectID
          )}`,
          returnUrlFailed: `${origin}${paths.donationFailureProject(
            projectDescription.data.description.projectID
          )}`,
          returnUrlPassed: `${origin}${paths.projectById(
            projectDescription.data.description.projectID
          )}/${routes.donationSuccess}`,
        },
        metadata: {
          ...baseRequest.metadata,
          projectId: projectDescription.data.description.projectID,
          projectName: projectDescription.data.description.name || '',
          projectLink: `${origin}${paths.projectById(
            projectDescription.data.description.projectID
          )}`,
        },
      });
    } else if (!donateForProject) {
      firePaymentCreation({
        ...baseRequest,
        description: t('paymentDescription.platform'),
        confirmation: {
          ...baseRequest.confirmation,
          returnUrlConfirmation: `${origin}`,
          returnUrlFailed: `${origin}${paths.donationFailurePlatform}`,
          returnUrlPassed: `${origin}/${routes.donationSuccess}`,
        },
      });
    }
  };

  const { data: paymentDocumentLinks } = useGetPresignedUrlsQuery({
    messageMapId: PresignedUrlByKeyMapId.PAYMENT_DOCS,
    keys: PAYMENT_DOCUMENT_KEYS,
  });

  const publicOfferLink = useMemo(
    () => paymentDocumentLinks?.data[0]?.url ?? '/',
    [paymentDocumentLinks?.data]
  );

  const onClickSignInButton = () => {
    if (isSmallScreen) {
      const _state = state as BypassPayload;
      const payload: BackdoorPayload = { backdoor: `${pathname}${search}` };
      if (!_state?.bypass) {
        navigate(paths.signIn, { state: payload, replace: true });
      }
    } else {
      dispatch(changeModal(MODALS.SIGN_IN));
    }
  };

  return (
    <form
      className={`donation-form ${className}`}
      acceptCharset="utf-8"
      onSubmit={onSubmit}
    >
      {showTitle ? <h5 className="donation-form__title">{title}</h5> : null}
      {description ? (
        <p className="donation-form__description">{description}</p>
      ) : null}
      <TextInput
        name="sum"
        placeholder={t('placeholders.sum')}
        className="donation-from__sum"
        type="number"
        value={sum}
        onChange={onSumChange}
        disabled={archived}
      />
      <div className="donation-form__checkbox-container">
        <Checkbox
          id={idCheckox}
          className="donation-form__checkbox"
          onChange={onAccepted}
          checked={isAccepted}
          disabled={archived}
          label={
            <Trans
              i18nKey="donation.form.checkboxes.terms"
              components={{
                lnk: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    className="text-link"
                    rel="noreferrer"
                    href={publicOfferLink}
                    target="_blank"
                  />
                ),
              }}
            />
          }
        />
        <SupportBlock
          className="donation-form"
          isSupported={isSupported}
          onSupported={onSupported}
          showEmailInput={showEmailInput}
          email={email}
          onEmailChange={onEmailChange}
          onEmailFocusLeave={onEmailFocusLeave}
          emailError={emailError}
          onClickSignInButton={onClickSignInButton}
          archived={archived}
        />
      </div>
      {showDelay ? (
        <div className="donation-form__delay-warning">
          <Icon icon={IconType.Error} />
          <span>{t('delayWarning')}</span>
        </div>
      ) : null}
      <Button
        submit
        type={donateForProject ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.ACCENTED}
        className="donation-form__button"
        disabled={submitRestricted}
      >
        {buttonText}
      </Button>
    </form>
  );
};

export default DonationForm;
