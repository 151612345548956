import { useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';
import TypeIt from 'typeit';

import Box from 'src/shared/components/Box/Box';
import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import { useGetDiaryEntriesQuery } from 'src/features/diary/api';
import { GetDiaryEntryResponse } from 'src/features/diary/models';
import { useGetExhibitionQuery } from 'src/features/exhibition/api';
import { useGetFilesQuery } from 'src/features/files/api';
import { FileCategories } from 'src/features/files/enums';
import { useGetPresignedUrlsQuery } from 'src/features/minIo/api';
import { PresignedUrlByKeyMapId } from 'src/features/minIo/models';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import DonationForm from 'src/features/payment/components/DonationForm/DonationForm';
import { PAYMENT_TYPE } from 'src/features/payment/models';
import CreateProjectButton from 'src/features/project/components/CreateProjectButton/CreateProjectButton';
import ProjectPreview from 'src/features/project/components/ProjectPreviewContainer/ProjectPreview/ProjectPreview';

import { ArrowLink } from './components/ArrowLink/ArrowLink';
import { FrontPageFooter } from './components/FrontPageFooter/FrontPageFooter';
import PlatformTeam from './components/PlatformTeam/PlatformTeam';
import steps from './jsons/steps.json';

import { ReactComponent as AddressImg } from './images/address-icon.svg';
import { ReactComponent as DonationImg } from './images/donation.svg';
import { ReactComponent as EmailImg } from './images/email-icon.svg';

import './FrontPage.scss';

const FILES_LIMIT = 1;
const FILES_OFFSET = 0;

const NEWS_PAGE = 1;
const NEWS_PAGE_SIZE = 4;
const NEWS_MAX_COUNT = 4;

const PURPOSE_KEYS = [
  'forMicroprojects',
  'forNotification',
  'forAccess',
  'forInvolvement',
];

const FEATURE_KEYS = ['coordination', 'tracking', 'supporting', 'registration'];

const FRONTPAGE_PROJECTS_KEYS = ['ppp-frontpage-projects.json'];
const PAYMENT_DOCUMENT_KEYS = ['public_offer.pdf', 'requisites.pdf'];

type RenderNewsProps = {
  entry: GetDiaryEntryResponse;
  linkText: string;
  className: string;
};

const RenderNews = ({
  entry,
  linkText,
  className,
}: RenderNewsProps): JSX.Element => {
  const navigate = useNavigate();

  const fetchedPhotos = useGetFilesQuery({
    entityId: entry.id,
    category: FileCategories.DIARY_FILE,
    limit: FILES_LIMIT,
    offset: FILES_OFFSET,
  });

  return (
    <div key={entry.id} className={`front-page__news-post ${className}`}>
      {fetchedPhotos.data?.data[0] ? (
        <img
          className="front-page__news-post-cover"
          alt={
            fetchedPhotos.data?.data[0].name || fetchedPhotos.data?.data[0].key
          }
          src={fetchedPhotos.data?.data[0].url}
        />
      ) : null}
      <h3 className="front-page__news-post-title">{entry.title}</h3>
      <p className="front-page__news-post-text">
        {<ReactMarkdown linkTarget="_blank">{entry.text}</ReactMarkdown>}
      </p>
      <Button
        className="front-page__news-post-button"
        type={BUTTON_TYPE.TEXT}
        onClick={() => navigate(paths.diaryById(entry.projectId || ''))}
      >
        <span>{linkText}</span>
        <Icon icon={IconType.Arrow} />
      </Button>
    </div>
  );
};

const FrontPage = (): JSX.Element => {
  const { isSuccess: authorized } = useWhoamiQuery();
  const navigate = useNavigate();
  const mobile = useCheckSmallScreen();
  const dispatch = useDispatch();
  const { t } = useTranslation('app', { keyPrefix: 'frontPage' });

  let newsCounter = 0;

  const { data: paymentDocumentLinks } = useGetPresignedUrlsQuery({
    messageMapId: PresignedUrlByKeyMapId.PAYMENT_DOCS,
    keys: PAYMENT_DOCUMENT_KEYS,
  });

  const exhibitionLink = useGetPresignedUrlsQuery({
    messageMapId: PresignedUrlByKeyMapId.FRONTPAGE_PROJECTS,
    keys: FRONTPAGE_PROJECTS_KEYS,
  });

  const {
    data: exhibitionData,
    isLoading: exhibitionIsLoading,
    isSuccess: exhibitionIsSuccess,
    isUninitialized: exhibitionUninitialized,
  } = useGetExhibitionQuery(exhibitionLink.data?.data[0]?.url as string, {
    skip: !exhibitionLink.isSuccess || exhibitionLink.isFetching,
  });

  const {
    data: diaryEntries,
    isLoading: diaryIsLoading,
    isUninitialized: diaryIsUninitialized,
  } = useGetDiaryEntriesQuery(
    {
      page: NEWS_PAGE,
      pageSize: NEWS_PAGE_SIZE,
      projectId: exhibitionData?.newsProjectId ?? '',
    },
    { skip: exhibitionIsLoading || !exhibitionIsSuccess }
  );

  const diaryEntriesData = useMemo(
    () =>
      diaryEntries?.data.documents.length
        ? diaryEntries?.data.documents
        : [
            {
              id: '',
              title: t('news.emptyNewsTitle'),
              text: t('news.emptyNewsText'),
              created: 0,
              diaryId: '',
              projectId: '',
              autoCreation: false,
            },
          ],
    [diaryEntries?.data.documents, t]
  );

  const requisitesUrl = useMemo(
    () => paymentDocumentLinks?.data[1]?.url ?? '/',
    [paymentDocumentLinks?.data]
  );

  const publicOfferUrl = useMemo(
    () => paymentDocumentLinks?.data[0]?.url ?? '/',
    [paymentDocumentLinks?.data]
  );

  // Strange workaround for broken anchors in Safari
  useEffect(() => {
    const anchor = location.hash.substring(1);
    if (
      anchor &&
      !(exhibitionUninitialized || exhibitionIsLoading) &&
      !(diaryIsUninitialized || diaryIsLoading)
    ) {
      document.getElementById(anchor)?.scrollIntoView(true);
    }
  }, [
    diaryIsLoading,
    diaryIsUninitialized,
    exhibitionIsLoading,
    exhibitionUninitialized,
  ]);

  useEffect(() => {
    // eslint-disable-next-line
    new (TypeIt as any)('.front-page__main-typewriter', {
      strings: [
        t('main.typewriter.s2'),
        t('main.typewriter.s3'),
        t('main.typewriter.s4'),
        t('main.typewriter.s5'),
        t('main.typewriter.s1'),
      ],
      breakLines: false,
      loop: true,
      nextStringDelay: [2000, 0],
      startDelete: true,
    }).go();
  }, [t]);

  const onClickCancelAutopaymentsButton = () => {
    if (authorized) {
      navigate(paths.profilePayments, {
        state: { paymentType: PAYMENT_TYPE.RECURRENT },
      });
    } else {
      mobile
        ? navigate(paths.autopaymentsManagement)
        : dispatch(changeModal(MODALS.AUTOPAYMENTS_MANAGEMENT));
    }
  };

  return (
    <div className="front-page">
      <section className="front-page__section--main">
        <h6 className="front-page__main-slogan">{t('main.slogan')}</h6>
        <h1 className="front-page__main-title">{t('main.title')}</h1>
        <p className="front-page__main-description">{t('main.subtitle')}</p>
        <CreateProjectButton className="front-page__main-link">
          <ArrowLink text={t('main.startNow')} link={paths.frontPage} />
        </CreateProjectButton>
        <h4 className="front-page__main-typewriter">
          {t('main.typewriter.s1')}
        </h4>
      </section>

      <section className="front-page__section--purpose">
        <h2 className="front-page__section-title">{t('purpose.title')}</h2>
        <p className="front-page__purpose-description">
          {t('purpose.description')}
        </p>
        <div className="front-page__purpose-grid">
          {PURPOSE_KEYS.map((k) => (
            <p key={k} className="front-page__purpose-cell">
              <Trans
                i18nKey={`frontPage.purpose.cells.${k}`}
                components={{ span: <span /> }}
              />
            </p>
          ))}
        </div>
      </section>

      <PlatformTeam className="front-page__section--team" />

      <section className="front-page__section--projects">
        <h2 className="front-page__section-title">{t('projects.title')}</h2>
        <div className="front-page__projects">
          <div className="front-page__projects-row">
            {exhibitionData?.currentProjects.map((id) => (
              <ProjectPreview
                className="front-page-project"
                key={id}
                projectId={id}
                large
              />
            ))}
          </div>
          <Box justifyContent={'flex-end'}>
            <ArrowLink
              className="front-page__projects-catalog-link"
              text={t('projects.catalogLink')}
              link={paths.projects}
            />
          </Box>
        </div>
      </section>

      <section className="front-page__section--features">
        <h2 className="front-page__section-title">{t('features.title')}</h2>
        <div className="front-page__features-row">
          {FEATURE_KEYS.map((k) => (
            <div key={k} id={k}>
              <div className="front-page__feature-illustration" />
              <p className="front-page__feature-description">
                <Trans i18nKey={`frontPage.features.list.${k}`} />
              </p>
            </div>
          ))}
        </div>
      </section>

      <section className="front-page__section--news">
        <h2 className="front-page__section-title">{t('news.title')}</h2>
        <div className="front-page__news">
          {diaryEntriesData.map((entry, index) => {
            if (newsCounter < NEWS_MAX_COUNT && entry.text) {
              newsCounter++;
              return (
                <RenderNews
                  key={entry.id}
                  className={`front-page__news--${index}`}
                  entry={entry}
                  linkText={t('news.readMore')}
                />
              );
            }
            return null;
          })}
        </div>
      </section>

      <section className="front-page__section--algorithm">
        <div className="front-page__algorithm-decoration--top" />
        <h2 className="front-page__section-title">{t('algorithm.title')}</h2>
        <div className="front-page__algorithm">
          {steps.map((s) => (
            <div key={s.id} className="front-page__algorithm-row">
              <div className="front-page__algorithm-step">
                <div id={s.id} className="front-page__algorithm-step-icon" />
                <div className="front-page__algorithm-step-wrapper">
                  <h4 className="front-page__algorithm-step-name">{s.title}</h4>
                  <p className="front-page__algorithm-step-decription">
                    {s.description}
                  </p>
                </div>
              </div>
              <div className="front-page__algorithm-arrow" />
            </div>
          ))}
        </div>
        <CreateProjectButton
          className="front-page__algorithm-button"
          text={t('algorithm.startButton')}
        />
        <div className="front-page__algorithm-decoration--bottom" />
      </section>

      <section className="front-page__donation-block" id="donation">
        <div className="front-page__donation-form">
          <h3 className="front-page__donation-form-header">
            {t('donation.header')}
          </h3>
          <p className="front-page__donation-recurring-payments">
            {t('donation.recurringPayments')}
          </p>
          <p className="front-page__donation-form-description">
            {t('donation.description')}
          </p>
          <DonationForm
            title={t('donation.form.title')}
            className="front-page__donation-form-element"
            buttonText={t('donation.form.buttonText')}
            showTitle
          />
          <p className="front-page__donation-bottom-info">
            <Trans
              i18nKey="frontPage.donation.bottomInfo"
              components={{
                btn: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <button
                    className="text-link front-page__donation-btn"
                    onClick={onClickCancelAutopaymentsButton}
                  />
                ),
              }}
            />
          </p>
        </div>
        <div className="front-page__donation-img">
          <DonationImg className="front-page__donation-img-element" />
        </div>
        <div className="front-page__donation-info">
          <h4 className="front-page__donation-info-title">
            {t('donation.info.title')}
          </h4>
          <p className="front-page__donation-info-description">
            {t('donation.info.description')}
          </p>
          <a
            className="text-link front-page__donation-info-link"
            href={requisitesUrl}
            target="_blank"
            rel="noreferrer"
          >
            {t('donation.info.requisites')}
          </a>
          <a
            className="text-link front-page__donation-info-link"
            href={publicOfferUrl}
          >
            {t('donation.info.publicOffer')}
          </a>
        </div>
        <div className="front-page__donation-contact">
          <h4 className="front-page__donation-contact-title">
            {t('donation.contacts.title')}
          </h4>
          <p className="front-page__donation-contact-description">
            {t('donation.contacts.emailDescription')}
          </p>
          <div className="front-page__donation-text-with-icon">
            <EmailImg />
            <p className="front-page__donation-contact-email">
              {process.env.REACT_APP_SUPPORT_EMAIL}
            </p>
          </div>
          <p className="front-page__donation-contact-description">
            {t('donation.contacts.addressDescription')}
          </p>
          <div className="front-page__donation-text-with-icon">
            <AddressImg />
            <p className="front-page__donation-contact-address">
              {process.env.REACT_APP_ADDRESS}
            </p>
          </div>
        </div>
      </section>

      <FrontPageFooter newsProjectId={exhibitionData?.newsProjectId ?? ''} />
    </div>
  );
};

export default FrontPage;
