import {
  ActorBaseResponse,
  MinIoUrl,
  UserProfile,
} from '@platform-for-public-places/components-library';
import {
  BaseQueryApi,
  BaseQueryFn,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import {
  GetUserCountRequest,
  GetUserCountResponse,
  GetUserInfoRequest,
  GetUserResponse,
  GetUsersResponse,
  UpdateProfileRequest,
  UpdateProfileResponse,
} from '../models';

export const USER_API = 'USER_API';

export interface ActorProfileResponse<t> {
  profile: t;
  errors: string[];
  success: boolean;
}

export const userApi = createApi({
  reducerPath: USER_API,
  baseQuery: retry(
    fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URLS }),
    { maxRetries: 0 }
  ),
  endpoints: (builder) => ({
    getUsers: builder.query<
      ActorBaseResponse<UserProfile[]>,
      GetUserInfoRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'profile/get-specialists',
          arguments: request,
        },
      }),
    }),
    getUsersWithProfileLink: builder.query<
      ActorBaseResponse<UserProfile[]>,
      GetUserInfoRequest
    >({
      async queryFn(
        _arg: GetUserInfoRequest,
        _queryApi: BaseQueryApi,
        _extraOptions: object,
        fetchWithBQ: (
          arg: Parameters<BaseQueryFn>[0]
        ) => ReturnType<BaseQueryFn>
      ) {
        const participantsResponse = await fetchWithBQ({
          method: 'POST',
          body: {
            messageMapId: 'profile/get-specialists',
            arguments: {
              searchString: `%${_arg.searchString}%`,
              type: _arg.type,
              pageSize: _arg.pageSize,
              page: _arg.page,
            },
          },
        });

        if (participantsResponse.error) {
          return {
            ...participantsResponse,
            isSuccess: false,
            isFetching: false,
            isError: true,
            data: {
              data: [],
              errors: [],
              success: false,
            },
          } as GetUsersResponse;
        }

        const participantsData = participantsResponse.data as ActorBaseResponse<
          UserProfile[]
        >;
        const listOfKeys = participantsData.data
          .filter((element: UserProfile) => !!element.portfolio?.key)
          .map((element: UserProfile) => element.portfolio?.key);

        const portfolioLinksResponse = await fetchWithBQ({
          method: 'POST',
          body: {
            messageMapId: 'get-images-presigned-url-by-key',
            arguments: {
              keys: listOfKeys,
            },
          },
        });

        if (portfolioLinksResponse.error) {
          return {
            ...participantsResponse,
            isSuccess: false,
            isFetching: false,
            isError: true,
            data: {
              data: [],
              errors: [],
              success: false,
            },
          } as GetUsersResponse;
        }

        const portfolioLinks = portfolioLinksResponse.data as ActorBaseResponse<
          MinIoUrl[]
        >;

        let index = 0;
        const updatedParticipants = participantsData.data.map(
          (element: UserProfile) => {
            if (
              portfolioLinks.data[index] &&
              element.portfolio?.key === portfolioLinks.data[index].key
            ) {
              index++;
              return {
                ...element,
                portfolio: { link: portfolioLinks.data[index - 1].url },
              };
            }
            return element;
          }
        );

        return {
          ...participantsResponse,
          isSuccess: true,
          isFetching: false,
          isError: false,
          data: {
            data: updatedParticipants,
            errors: [],
            success: true,
          },
        } as GetUsersResponse;
      },
    }),
    getProfile: builder.query<ActorBaseResponse<UserProfile>, string>({
      async queryFn(
        _arg: string,
        _queryApi: BaseQueryApi,
        _extraOptions: object,
        fetchWithBQ: (
          arg: Parameters<BaseQueryFn>[0]
        ) => ReturnType<BaseQueryFn>
      ) {
        const participantResponse = await fetchWithBQ({
          method: 'POST',
          body: {
            messageMapId: 'profile/get-profile-by-user-id',
            arguments: {
              userId: _arg,
            },
          },
        });

        if (participantResponse.error) {
          return {
            isSuccess: false,
            isFetching: false,
            isError: true,
            data: {
              data: {
                userId: '',
                createdAt: '',
                updatedAt: '',
                profileID: '',
                privacyPolicyConfirmed: false,
              },
              errors: [],
              success: false,
            },
          } as GetUserResponse;
        }

        const participantData = participantResponse.data as ActorBaseResponse<{
          profile: UserProfile;
        }>;

        if (!participantData.data.profile.portfolio?.key) {
          return {
            isSuccess: true,
            isFetching: false,
            isError: false,
            data: {
              data: participantData.data.profile,
              errors: participantData.errors,
              success: participantData.success,
            },
          } as GetUserResponse;
        }

        const portfolioLinksResponse = await fetchWithBQ({
          method: 'POST',
          body: {
            messageMapId: 'get-images-presigned-url-by-key',
            arguments: {
              keys: [participantData.data.profile.portfolio?.key],
            },
          },
        });

        if (portfolioLinksResponse.error) {
          return {
            isSuccess: true,
            isFetching: false,
            isError: false,
            data: {
              data: participantData.data.profile,
              errors: participantData.errors,
              success: participantData.success,
            },
          } as GetUserResponse;
        }

        const portfolioLinks = portfolioLinksResponse.data as ActorBaseResponse<
          MinIoUrl[]
        >;

        return {
          isSuccess: true,
          isFetching: false,
          isError: false,
          data: {
            data: {
              ...participantData.data.profile,
              portfolio: {
                ...participantData.data.profile.portfolio,
                link: portfolioLinks.data[0].url,
              },
            },
            errors: participantData.errors,
            success: participantData.success,
          },
        };
      },
    }),
    getUserCount: builder.query<
      ActorBaseResponse<GetUserCountResponse[]>,
      GetUserCountRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'profile/get-specialist-counts',
          arguments: request,
        },
      }),
    }),
    updateProfile: builder.query<
      ActorBaseResponse<UpdateProfileResponse>,
      UpdateProfileRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: {
          messageMapId: 'profile/update-profile',
          arguments: request,
        },
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetUserCountQuery,
  useLazyGetProfileQuery,
  useLazyUpdateProfileQuery,
  useLazyGetUsersWithProfileLinkQuery,
} = userApi;

export const { resetApiState } = userApi.util;

export const userMiddleware = userApi.middleware;

export default userApi.reducer;
