import { FormEvent, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  GetFilesResponse,
  Icon,
  IconType,
  MIME_JPEG,
  MIME_PNG,
  telegramUrlRegexp,
  TextArea,
  TextInput,
  urlRegexp,
  useCheckSmallScreen,
  vkUrlRegexp,
  whatsAppUrlRegexp,
} from '@platform-for-public-places/components-library';

import Checkbox from 'src/shared/components/Checkbox/Checkbox';
import FileInput from 'src/shared/components/inputs/FileInput/FileInput';
import TelephoneInput from 'src/shared/components/inputs/TelephoneInput/TelephoneInput';

import { useLazyWhoamiQuery } from 'src/features/auth/api';
import { WhoamiResponse } from 'src/features/auth/models';
import FilePreview from 'src/features/files/components/FilePreview/FilePreview';
import {
  useGetPresignedUrlsQuery,
  useLazyGetImagesPresignedUrlsQuery,
} from 'src/features/minIo/api';
import { PresignedUrlByKeyMapId } from 'src/features/minIo/models';
import DropdownTag from 'src/features/modal/components/FormAddToProject/components/DropdownTag';
import PhotoUploader from 'src/features/profile/components/PhotoUploader/PhotoUploader';
import {
  useLazyCreateMemberRequestQuery,
  useLazyCreateSpecialistRequestQuery,
  useLazyGetSpecialistRequestQuery,
} from 'src/features/request/api';
import { REQUEST_STATUS } from 'src/features/request/enums';
import {
  BaseGetRequestResponse,
  BaseRequestData,
  BaseRequestModel,
  Category,
  OptionalSpecialistRequest,
  Portfolio,
  SpecialistRequestBody,
  Tag,
  TagData,
} from 'src/features/request/models';
import { useLazyUpdateProfileQuery } from 'src/features/user/api';
import {
  PortfolioFile,
  PortfolioLink,
  UpdateProfileRequest,
} from 'src/features/user/models';

import { useUploadFiles } from 'src/pages/project/hooks';

const MAX_INPUT_LENGTH = 500;
const PHOTO_AMOUNT = 1;
const FILE_INPUT_ACCEPT = '.jpg, .png, .pdf';

const POLICY_KEYS = ['ppp-privacy-policy.pdf'];

const VK_LINK = 'https://vk.com/';
const TELEGRAM_LINK = 'https://t.me/';
const WHATSAPP_LINK = 'https://wa.me/qr/';

export const DEFAULT_STATUS = REQUEST_STATUS.ACTIVE;

export type SpecialistError = {
  reason: string | null;
  exists: boolean;
};

const INITIAL_ERROR: SpecialistError = { reason: null, exists: false };

interface FormSpecialistProps {
  requestExists: boolean;
  setRequestExists: (value: boolean) => void;
  setRequestCardInfo: (value: RequestCardInfo) => void;
  setRequestOpened: (value: boolean) => void;
  isSupport?: boolean;
  category?: Category[];
  requestData?: BaseGetRequestResponse<BaseRequestData<SpecialistRequestBody>>;
  onSuccess?: () => void;
  onFailure?: () => void;
}

export interface RequestCardInfo {
  status: REQUEST_STATUS;
  date: string;
  comment?: string;
}

const FormSpecialist = ({
  requestExists,
  setRequestExists,
  setRequestCardInfo,
  setRequestOpened,
  isSupport = false,
  category = [],
  requestData,
  onSuccess,
  onFailure,
}: FormSpecialistProps) => {
  const { id } = useParams();
  const { t } = useTranslation('app', { keyPrefix: 'profile.specialist' });
  const { t: tr } = useTranslation();

  const [triggerWhoami] = useLazyWhoamiQuery();

  const [userData, setUserData] = useState<WhoamiResponse>();

  const [selectTag, setSelectTag] = useState<TagData[]>([]);
  const [specialistRequest] = useLazyGetSpecialistRequestQuery();
  const [getFile] = useLazyGetImagesPresignedUrlsQuery();
  const isSmallScreen = useCheckSmallScreen();

  const [name, setName] = useState<string>('');
  const [workplace, setWorkplace] = useState<string>('');
  const [about, setAbout] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [portfolioLink, setPortfolioLink] = useState<string>('');
  const [portfolioMobileLink, setPortfolioMobileLink] = useState<string>('');
  const [vk, setVk] = useState<string>('');
  const [telegram, setTelegram] = useState<string>('');
  const [whatsapp, setWhatsapp] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [isDeveloper, setIsDeveloper] = useState<boolean>(false);
  const [isImplementer, setIsImplementer] = useState<boolean>(false);
  const [specialistTypeError, setSpecialistTypeError] =
    useState<boolean>(false);
  const [socialLinksError, setSocialLinksError] = useState<boolean>(false);

  const [nameError, setNameError] = useState<SpecialistError>(INITIAL_ERROR);
  const [tagError, setTagError] = useState<SpecialistError>(INITIAL_ERROR);
  const [aboutError, setAboutError] = useState<SpecialistError>(INITIAL_ERROR);
  const [portfolioLinkError, setPortfolioLinkError] =
    useState<SpecialistError>(INITIAL_ERROR);

  const [localPhoto, setLocalPhoto] = useState<GetFilesResponse[]>([]);
  const [photoError, setPhotoError] = useState<string>('');
  const [localDocs, setLocalDocs] = useState<GetFilesResponse[]>([]);
  const [docsError, setDocsError] = useState<string>('');

  const submitDisabled =
    !name ||
    nameError.exists ||
    (!about && !isSupport) ||
    aboutError.exists ||
    socialLinksError ||
    specialistTypeError ||
    (!vk && !telegram && !whatsapp) ||
    (!isDeveloper && !isImplementer && !isSupport) ||
    (!isSmallScreen &&
      localDocs.length === 0 &&
      !portfolioLink &&
      !isSupport) ||
    (isSmallScreen &&
      localDocs.length === 0 &&
      !portfolioMobileLink &&
      !isSupport) ||
    portfolioLinkError.exists ||
    (isSupport && selectTag.length <= 0);

  const [createSpecialist] = useLazyCreateSpecialistRequestQuery();
  const [applyForProject] = useLazyCreateMemberRequestQuery();
  const [updateProfile] = useLazyUpdateProfileQuery();

  const policyLink =
    useGetPresignedUrlsQuery({
      messageMapId: PresignedUrlByKeyMapId.PRIVACY_POLICY,
      keys: POLICY_KEYS,
    }).data?.data[0]?.url || '/';

  useEffect(() => {
    triggerWhoami().then((v) => {
      setUserData(v?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const fillForm = useCallback(
    (req: OptionalSpecialistRequest) => {
      if (req) {
        setName(req.name);
        setWorkplace(req.job);
        setAbout(req.about);
        setVk(req.socialLinks?.vk ?? '');
        setTelegram(req.socialLinks?.telegram ?? '');
        setWhatsapp(req.socialLinks?.whatsapp ?? '');
        setPhone(req.socialLinks?.phone ?? '');
        setIsDeveloper(req.isDeveloper);
        setIsImplementer(req.isImplementer);

        if (req.avatar) {
          getFile({
            keys: [req.avatar as string],
          }).then((data) => {
            data.data && setLocalPhoto(data.data.data);
          });
        }

        if (req.portfolio?.key) {
          getFile({
            keys: [req.portfolio.key],
          }).then((data) => {
            data.data &&
              setLocalDocs([
                { ...data.data.data[0], name: req.portfolio?.name },
              ]);
          });
        }

        const p = req.portfolio;
        if (p?.key) {
          getFile({
            keys: [p.key],
          }).then(({ data }) => {
            data && setLocalDocs([{ ...data.data[0], name: p.name }]);
          });
        } else if (p?.link) {
          if (isSmallScreen) {
            setPortfolioMobileLink(p.link);
          } else {
            setPortfolioLink(p.link);
          }
        }
      }
    },
    [getFile, isSmallScreen]
  );

  const fillFromRequest = () => {
    if (requestData) {
      fillForm(requestData.request.requestBody);
      if (requestData.request.message) {
        setComment(requestData.request.message);
      }
    } else {
      if (userData?.userID && userData?.profile?.profileID) {
        specialistRequest({
          initiatorId: userData?.userID,
          entityId: userData?.profile?.profileID,
        }).then(({ data }) => {
          const requestData = data?.data;

          if (data?.success && requestData) {
            setRequestExists(true);
            fillForm(requestData.request.requestBody);
            setRequestCardInfo({
              status: requestData.request.status,
              date: requestData.request.updatedAt,
              comment: requestData.request.message,
            });
            if (requestData.request.message) {
              setComment(requestData.request.message);
            }
          } else {
            setRequestExists(false);
          }
        });
      }
    }
  };

  const fillFromProfile = () => {
    if (userData?.profile) {
      const p = userData?.profile;
      const data: OptionalSpecialistRequest = {
        job: p.job || '',
        name: p.name || '',
        about: p.about || '',
        avatar: p.avatar as string,
        isDeveloper: false,
        isImplementer: false,
        portfolio: p.portfolio,
        socialLinks: p.socialLinks,
      };

      setRequestExists(true);
      fillForm(data);
      setRequestCardInfo({
        status: REQUEST_STATUS.EXECUTED,
        date: p.updatedAt,
      });
    } else {
      setRequestExists(false);
    }
  };

  useEffect(() => {
    setName(userData?.profile?.name ?? '');
    if (isSupport) {
      fillFromProfile();
    } else {
      fillFromRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, specialistRequest, fillForm]);

  const checkLinkInput = (
    value: string,
    onError: (error: SpecialistError) => void
  ) => {
    if (value.length || isSupport) {
      const isUrl = urlRegexp.test(value);
      onError(
        isUrl
          ? INITIAL_ERROR
          : { exists: true, reason: tr('fileInput.errors.urlError') }
      );
    } else {
      onError({ exists: true, reason: t('required') });
    }
  };

  const checkNameInput = () => {
    const trimmedText = name.trim();
    setName(trimmedText);
    const length = trimmedText.length;
    if (!length) {
      setNameError({ exists: true, reason: t('required') });
    } else {
      setNameError(INITIAL_ERROR);
    }
  };

  const onNameChange = (value: string) => {
    if (nameError) {
      setNameError(nameError);
    }
    setName(value);
    const length = value.trim().length;
    if (!length) {
      setNameError({ exists: true, reason: t('required') });
    } else {
      setNameError(INITIAL_ERROR);
    }
  };

  useEffect(() => {
    if (selectTag.length > 0) {
      setTagError(INITIAL_ERROR);
    }
  }, [selectTag, t]);

  const onWorkplaceChange = (text: string) => {
    setWorkplace(text);
  };

  const checkWorkplaceInput = () => {
    const trimmedText = workplace.trim();
    setWorkplace(trimmedText);
  };

  const checkAboutInput = () => {
    const trimmedText = about.trim();
    setAbout(trimmedText);
    const length = trimmedText.length;
    if (length || isSupport) {
      setAboutError(INITIAL_ERROR);
    } else {
      setAboutError({ exists: true, reason: t('required') });
    }
  };

  const onAboutChange = (value: string) => {
    if (aboutError) {
      setAboutError(aboutError);
    }
    setAbout(value);
    const length = value.trim().length;
    if (length || isSupport) {
      setAboutError(INITIAL_ERROR);
    } else {
      setAboutError({ exists: true, reason: t('required') });
    }
  };

  const onCommentChange = (value: string) => setComment(value);

  const onPortfolioLinkChange = (value: string) => {
    const trimmedText = value.trim();
    setPortfolioMobileLink(trimmedText);
    checkLinkInput(trimmedText, setPortfolioLinkError);
  };

  const checkSocialLinks = () =>
    !vk && !telegram && !whatsapp
      ? setSocialLinksError(true)
      : setSocialLinksError(false);

  const onVkChange = (text: string) => {
    const trimmedText = text.trim();
    if (vk === '') {
      setVk(VK_LINK + trimmedText);
    }
    if (text === '' || vkUrlRegexp.test(text)) {
      setVk(trimmedText);
    }
    checkSocialLinks();
  };

  const onTelegramChange = (text: string) => {
    const trimmedText = text.trim();
    if (telegram === '') {
      setTelegram(TELEGRAM_LINK + trimmedText);
    }
    if (text === '' || telegramUrlRegexp.test(text)) {
      setTelegram(trimmedText);
    }
    checkSocialLinks();
  };

  const onWhatsappChange = (text: string) => {
    const trimmedText = text.trim();
    if (whatsapp === '') {
      setWhatsapp(WHATSAPP_LINK + trimmedText);
    }
    if (text === '' || whatsAppUrlRegexp.test(text)) {
      setWhatsapp(trimmedText);
    }
    checkSocialLinks();
  };

  const onPhoneChange = (number: string) => {
    const trimmedNumber = number.trim();
    setPhone(trimmedNumber);
  };

  const renderError = (e: SpecialistError) => (
    <div
      className={`become-specialist__error-container${
        e.exists ? '--visible' : ''
      }`}
    >
      {e.exists ? (
        <span className="become-specialist__error-text">{e.reason}</span>
      ) : null}
    </div>
  );

  const successUploadPhoto = (files: GetFilesResponse[]) => {
    setLocalPhoto(files);
  };

  const uploadPhoto = useUploadFiles(
    [MIME_JPEG, MIME_PNG],
    successUploadPhoto,
    setPhotoError
  );

  const onPhotoUpload = (files: File[]) => uploadPhoto(files, PHOTO_AMOUNT);

  const successUploadFiles = (files: GetFilesResponse[]) => {
    setLocalDocs(files);
  };

  const uploadFiles = useUploadFiles([], successUploadFiles, setDocsError);

  const onDocsFiles = (files: File[]) => uploadFiles(files, 1);

  const onDocsLink = (link: string) => {
    setPortfolioLink(link);
  };

  const removeDoc = () => setLocalDocs([]);

  const selectImplementer = () => {
    if (isImplementer && !isDeveloper) {
      setSpecialistTypeError(true);
    } else {
      setSpecialistTypeError(false);
    }
    setIsImplementer(!isImplementer);
  };

  const selectDeveloper = () => {
    if (!isImplementer && isDeveloper) {
      setSpecialistTypeError(true);
    } else {
      setSpecialistTypeError(false);
    }
    setIsDeveloper(!isDeveloper);
  };

  const getPortfolio = (): PortfolioLink | PortfolioFile | null => {
    if (isSmallScreen) {
      if (portfolioMobileLink || localDocs.length) {
        return portfolioMobileLink
          ? {
              link: portfolioMobileLink,
            }
          : {
              key: localDocs[0]?.key,
              name: localDocs[0]?.name,
            };
      } else {
        return null;
      }
    } else {
      if (portfolioLink || localDocs.length) {
        return portfolioLink
          ? {
              link: portfolioLink,
            }
          : {
              key: localDocs[0]?.key,
              name: localDocs[0]?.name,
            };
      } else {
        return null;
      }
    }
  };

  const fireSpecialistCreation = (
    req: BaseRequestModel<SpecialistRequestBody>
  ) =>
    createSpecialist(req).then((result) => {
      if (result.isSuccess) {
        setRequestExists(true);
        setRequestOpened(false);
        setRequestCardInfo({
          status: DEFAULT_STATUS,
          date: new Date().toLocaleString(),
          comment: '',
        });
      }
    });

  const fireProfileUpdating = (req: UpdateProfileRequest) =>
    updateProfile(req)
      .unwrap()
      .then((resp) => {
        if (resp.success) {
          setRequestExists(true);
          setRequestOpened(false);
          setRequestCardInfo({
            status: DEFAULT_STATUS,
            date: new Date().toLocaleString(),
            comment: '',
          });
          return Promise.resolve(resp);
        } else {
          onFailure?.();
          return Promise.reject();
        }
      })
      .catch(() => {
        onFailure?.();
        return Promise.reject();
      });

  const fireApplyingToProject = (projectId?: string) => {
    if (projectId) {
      const castedTags: Tag[] = selectTag.map((tag) => ({
        name: tag.label,
        tagId: tag.value,
      }));

      return applyForProject({
        projectId,
        tags: castedTags,
        message: comment.trim(),
      })
        .unwrap()
        .then((resp) => {
          if (resp.success && !resp.errors.length) {
            onSuccess?.();
            return Promise.resolve(resp);
          } else {
            onFailure?.();
            return Promise.reject();
          }
        })
        .catch(() => {
          onFailure?.();
          return Promise.reject();
        });
    } else {
      onFailure?.();
      return Promise.reject();
    }
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const profileId = userData?.profile?.profileID ?? '';
    const base = {
      name: name.trim(),
      avatar: localPhoto.length !== 0 ? localPhoto[0].key : '',
      about: about.trim(),
      job: workplace.trim(),
      portfolio: getPortfolio(),
      socialLinks: {
        vk,
        telegram,
        whatsapp,
        phone,
        email: userData?.email,
      },
    };

    if (isSupport) {
      fireProfileUpdating({
        ...base,
        profileId,
      }).then(() => fireApplyingToProject(id));
    } else {
      fireSpecialistCreation({
        entityId: profileId,
        requestBody: {
          ...base,
          isDeveloper,
          isImplementer,
        },
      });
    }
  };

  const getPortfolioWrapper = () => {
    const portfolio =
      localDocs.length !== 0
        ? (localDocs[0] as Portfolio)
        : { link: portfolioLink };
    return (
      <div className="become-specialist-form__portfolio-preview-wrapper">
        <div className="become-specialist-form__portfolio-preview">
          <FilePreview
            onRemoveClick={() => {
              setPortfolioMobileLink('');
              setPortfolioLink('');
              removeDoc();
            }}
            document={portfolio}
          />
        </div>
      </div>
    );
  };

  return (
    <form className="become-specialist-form" onSubmit={onSubmit}>
      {isSupport ? (
        <p className="become-specialist-container__prompt">{t('prompt')}</p>
      ) : null}
      <PhotoUploader
        className="become-specialist-form__photo-uploader"
        error={photoError}
        onFile={onPhotoUpload}
        onError={setPhotoError}
        image={localPhoto[0]?.key ? localPhoto[0]?.url : ''}
        setLocalPhoto={() => setLocalPhoto([])}
      />
      {isSupport ? (
        <div className="become-specialist-form__element">
          <label className="become-specialist-form__label" htmlFor="tag">
            {t('inputLabel.tag')}
            <span className="become-specialist-form__element_required">
              {' *'}
            </span>
          </label>
          <DropdownTag
            category={category}
            selectTag={selectTag}
            setSelectTag={setSelectTag}
            error={tagError}
            setError={setTagError}
          />
          {renderError(tagError)}
        </div>
      ) : null}
      {isSupport ? (
        <div className="become-specialist-form__element">
          <label className="become-specialist-form__label" htmlFor="comment">
            {t('inputLabel.comment')}
          </label>
          <TextArea
            className="become-specialist-form__input become-specialist-form__comment-input"
            value={comment}
            maxLength={MAX_INPUT_LENGTH}
            fitContent={false}
            placeholder={t('placeholder.comment')}
            onChange={(e) => onCommentChange(e.target.value)}
            initialEditingHeight={120}
            initialHeight={120}
            id="comment"
          />
        </div>
      ) : null}
      <div className="become-specialist-form__element become-specialist-form__name">
        <label className="become-specialist-form__label" htmlFor="name">
          {t('inputLabel.name')}
          <span className="become-specialist-form__element_required">
            {' *'}
          </span>
        </label>
        <TextInput
          className={`become-specialist-form__input become-specialist-form__name-input${
            nameError.exists ? '--error' : ''
          }`}
          value={name}
          id="name"
          maxLength={MAX_INPUT_LENGTH}
          placeholder={t('placeholder.name')}
          onChange={(e) => onNameChange(e.target.value)}
          onFocusLeave={() => checkNameInput()}
        />
        {renderError(nameError)}
      </div>
      <div className="become-specialist-form__element">
        <label className="become-specialist-form__label" htmlFor="workplace">
          {t('inputLabel.workplace')}
        </label>
        <TextInput
          className="become-specialist-form__input become-specialist-form__workplace-input"
          value={workplace}
          maxLength={MAX_INPUT_LENGTH}
          placeholder={t('placeholder.workplace')}
          onChange={(e) => onWorkplaceChange(e.target.value)}
          onFocusLeave={checkWorkplaceInput}
          id="workplace"
        />
      </div>
      <div className="become-specialist-form__element">
        <label className="become-specialist-form__label" htmlFor="about">
          {t('inputLabel.about')}
          {isSupport ? null : (
            <span className="become-specialist-form__element_required">
              {' *'}
            </span>
          )}
        </label>
        <TextArea
          className={`become-specialist-form__input become-specialist-form__about-input${
            aboutError.exists ? '--error' : ''
          }`}
          value={about}
          maxLength={MAX_INPUT_LENGTH}
          fitContent={false}
          placeholder={t('placeholder.about')}
          onChange={(e) => onAboutChange(e.target.value)}
          onBlur={checkAboutInput}
          initialEditingHeight={120}
          initialHeight={120}
          id="about"
        />
        {renderError(aboutError)}
      </div>
      <div className="become-specialist-form__element">
        <label className="become-specialist-form__label" htmlFor="portfolio">
          {t('inputLabel.portfolio')}
          {isSupport ? null : (
            <span className="become-specialist-form__element_required">
              {' *'}
            </span>
          )}
        </label>
        {localDocs.length === 0 && portfolioLink === '' ? (
          <>
            <FileInput
              erase
              buttonType={BUTTON_TYPE.SECONDARY}
              error={docsError || portfolioLinkError.reason || ''}
              accept={FILE_INPUT_ACCEPT}
              onLink={onDocsLink}
              onFiles={onDocsFiles}
              onError={setDocsError}
              className="become-specialist-form__portfolio-input"
              id="portfolio"
              placeholder={t('placeholder.portfolio')}
              disabled={!!portfolioMobileLink}
              notEmptyLink
              isRequired={!isSupport}
            />
          </>
        ) : (
          getPortfolioWrapper()
        )}
        {isSmallScreen && localDocs.length === 0 && portfolioLink === '' ? (
          <>
            <label
              className={`become-specialist-form__label become-specialist-form__label_portfolio-link ${
                docsError || portfolioLinkError.reason
                  ? 'become-specialist-form__label_portfolio-link__error'
                  : ''
              }`}
              htmlFor="portfolioLink"
            >
              {t('inputLabel.portfolioLink')}
            </label>
            <TextInput
              className={`become-specialist-form__input become-specialist-form__portfolio-link-input${
                portfolioLinkError.exists ? '--error' : ''
              }`}
              value={portfolioMobileLink}
              maxLength={MAX_INPUT_LENGTH}
              placeholder={t('placeholder.portfolioLink')}
              onChange={(e) => onPortfolioLinkChange(e.target.value)}
              id="portfolioLink"
            />
            <span
              className={`file-input__error${
                portfolioLinkError.exists ? '--active' : '--hidden'
              }`}
            >
              {portfolioLinkError.reason}
            </span>
          </>
        ) : null}
      </div>
      <div className="become-specialist-form__element">
        <label
          className={`become-specialist-form__label ${
            socialLinksError ? 'become-specialist-form__label--error' : ''
          }`}
        >
          <Trans
            i18nKey="profile.specialist.inputLabel.socialLinks"
            components={{
              necessarily: (
                <span className="become-specialist-form__label--span-error" />
              ),
            }}
          />
          <span className="become-specialist-form__label--span become-specialist-form__label--span-error become-specialist-form__element_required">
            {' *'}
          </span>
        </label>

        <ul className="become-specialist-form__social-links">
          <li>
            <Icon icon={IconType.Vk} />
            <TextInput
              className={`become-specialist-form__input become-specialist-form__social-link-input ${
                socialLinksError
                  ? 'become-specialist-form__social-link-input_error'
                  : ''
              }`}
              value={vk}
              maxLength={MAX_INPUT_LENGTH}
              placeholder={t('placeholder.vk')}
              onChange={(e) => onVkChange(e.target.value)}
              onFocus={() => setSocialLinksError(false)}
              onFocusLeave={checkSocialLinks}
            />
          </li>
          <li>
            <Icon icon={IconType.Telegram} />
            <TextInput
              className={`become-specialist-form__input become-specialist-form__social-link-input ${
                socialLinksError
                  ? 'become-specialist-form__social-link-input_error'
                  : ''
              }`}
              value={telegram}
              maxLength={MAX_INPUT_LENGTH}
              placeholder={t('placeholder.telegram')}
              onChange={(e) => onTelegramChange(e.target.value)}
              onFocus={() => setSocialLinksError(false)}
              onFocusLeave={checkSocialLinks}
            />
          </li>
          <li>
            <Icon icon={IconType.Whatsapp} />
            <TextInput
              className={`become-specialist-form__input become-specialist-form__social-link-input ${
                socialLinksError
                  ? 'become-specialist-form__social-link-input_error'
                  : ''
              }`}
              value={whatsapp}
              maxLength={MAX_INPUT_LENGTH}
              placeholder={t('placeholder.whatsapp')}
              onChange={(e) => onWhatsappChange(e.target.value)}
              onFocus={() => setSocialLinksError(false)}
              onFocusLeave={checkSocialLinks}
            />
          </li>
        </ul>
      </div>
      <div className="become-specialist-form__element">
        <label className="become-specialist-form__label" htmlFor="phone">
          {t('inputLabel.phoneNumber')}
        </label>
        <label
          htmlFor="phone"
          className="become-specialist-form__phone-input-container"
        >
          <Icon icon={IconType.Phone} />
          <TelephoneInput
            className="become-specialist-form__input become-specialist-form__phone-input"
            numericValue={phone}
            placeholer={t('placeholder.phoneNumber')}
            onNumericChange={onPhoneChange}
          />
        </label>
      </div>
      {isSupport ? null : (
        <div className="become-specialist-form__element">
          <label
            className={`become-specialist-form__label ${
              specialistTypeError ? 'specialist-type-input__checkbox_error' : ''
            }`}
          >
            {t('inputLabel.specialistType')}
            <span className="become-specialist-form__element_required">
              {' *'}
            </span>
          </label>
          <ul className="become-specialist-form__specialist-type-list">
            <li className="become-specialist-form__specialist-type-input">
              <Checkbox
                id="developer"
                checked={isDeveloper}
                onChange={() => selectDeveloper()}
                inputClassName={`specialist-type-input__checkbox ${
                  specialistTypeError
                    ? 'specialist-type-input__checkbox_error'
                    : ''
                }`}
              />
              <label
                htmlFor="developer"
                className="become-specialist-form__specialist-label"
              >
                <Icon
                  icon={IconType.Developer}
                  className="specialist-type-input__icon"
                />
                <div className="become-specialist-form__specialist-type-label">
                  <label className="become-specialist-form__specialist-type-title">
                    {t('designer.title')}
                  </label>
                  <p className="become-specialist-form__specialist-type-description">
                    {t('designer.description')}
                  </p>
                </div>
              </label>
            </li>
            <li className="become-specialist-form__specialist-type-input">
              <Checkbox
                id="implementer"
                checked={isImplementer}
                onChange={() => selectImplementer()}
                inputClassName={`specialist-type-input__checkbox ${
                  specialistTypeError
                    ? 'specialist-type-input__checkbox_error'
                    : ''
                }`}
              />
              <label
                htmlFor="implementer"
                className="become-specialist-form__specialist-label"
              >
                <Icon
                  icon={IconType.Implementer}
                  className="specialist-type-input__icon"
                />
                <div className="become-specialist-form__specialist-type-label">
                  <label className="become-specialist-form__specialist-type-title">
                    {t('executor.title')}
                  </label>
                  <p className="become-specialist-form__specialist-type-description">
                    {t('executor.description')}
                  </p>
                </div>
              </label>
            </li>
          </ul>
        </div>
      )}
      <div className="become-specialist-form__submit-wrapper">
        <Button
          submit
          type={BUTTON_TYPE.PRIMARY}
          className="become-specialist-form__submit-button"
          disabled={submitDisabled}
        >
          {t(`${requestExists && !isSupport ? 'update' : 'submit'}`)}
        </Button>
        {!requestExists && (
          <p className="become-specialist-form__submit-description">
            <Trans
              i18nKey="profile.specialist.submitLabel"
              components={{
                lnk: (
                  <Link
                    className="text-link"
                    rel="noreferrer"
                    to={policyLink}
                    target="_blank"
                  />
                ),
              }}
            />
          </p>
        )}
      </div>
    </form>
  );
};

export default FormSpecialist;
