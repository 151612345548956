import {
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  GetFilesResponse,
  Icon,
  IconType,
  MIME_JPEG,
  MIME_PNG,
} from '@platform-for-public-places/components-library';

import FileInput from 'src/shared/components/inputs/FileInput/FileInput';

import { useUploadFileFromLink, useUploadFiles } from 'src/pages/project/hooks';

import './PhotosUploader.scss';

export interface PhotosUploaderProps {
  className?: string;
  title?: string;
  description?: ReactNode;
  coverHint?: string;
  required?: boolean;
  photos?: GetFilesResponse[];
  cover: GetFilesResponse | null;
  onUpload: (photos: GetFilesResponse[]) => void;
  onCover: (cover: GetFilesResponse | null) => void;
  onDelete: (photo: GetFilesResponse) => void;
}

const MAX_PHOTOS = 10;
const IMAGE_TYPES = `${MIME_PNG}, ${MIME_JPEG}`;
const SELECTED_CLASS = 'photos-uploader__overlay--selected';

const PhotosUploader = ({
  className = '',
  title,
  description,
  coverHint,
  required = false,
  photos = [],
  cover = null,
  onUpload,
  onCover,
  onDelete,
}: PhotosUploaderProps): JSX.Element => {
  const { t: tfi } = useTranslation('app', { keyPrefix: 'fileInput.hints' });
  const { t: tpu } = useTranslation('app', { keyPrefix: 'photosUploader' });

  const [photosError, setPhotosError] = useState<string>('');

  const photosOverflow = photos.length >= MAX_PHOTOS;

  useEffect(() => {
    if (!cover && photos.length) {
      onCover(photos[0]);
    }
  }, [cover, photos, onCover]);

  const onFileUploadSuccess = (files: GetFilesResponse[]) => {
    if (files.length) {
      !cover && onCover(files[0]);
      onUpload(files);
    }
  };

  const onLinkUploadSuccess = (file: GetFilesResponse) => {
    !cover && onCover(file);
    onUpload([file]);
  };

  const uploadFiles = useUploadFiles(
    [MIME_JPEG, MIME_PNG],
    onFileUploadSuccess,
    setPhotosError
  );

  const uploadFileFromLink = useUploadFileFromLink(
    [MIME_JPEG, MIME_PNG],
    onLinkUploadSuccess,
    setPhotosError
  );

  const onPhotoLink = (link: string) => {
    !photosOverflow && uploadFileFromLink(link);
  };

  const checkForEmptiness = useCallback(
    (target: FileReader[] | GetFilesResponse[]) => {
      if (required && !target.length) {
        setPhotosError(tpu('errors.fileLowLimit'));
      }
    },
    [tpu, required]
  );

  const onPhotoFiles = (files: File[]) => {
    uploadFiles(files, MAX_PHOTOS - photos.length);
  };

  const thumbnails = useMemo(() => {
    const getSelectedClass = (p: GetFilesResponse) => {
      if (p.project_filesID && cover?.project_filesID) {
        return p.project_filesID === cover?.project_filesID
          ? SELECTED_CLASS
          : '';
      } else {
        return p.key === cover?.key ? SELECTED_CLASS : '';
      }
    };

    return photos.map((p, i) => {
      const selectedClass = getSelectedClass(p);

      const onCoverPick = () => {
        onCover(p);
      };

      const onRemove: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.stopPropagation();
        onDelete(p);

        if (required) {
          const _photos = photos.filter((photo) => photo != p);
          checkForEmptiness(_photos);
        }
      };

      return (
        <div key={`photos-${i}`} className="photos-uploader__preview-image">
          <img src={p.url} alt="thumbnail" />
          <div
            aria-hidden
            className={`photos-uploader__overlay ${selectedClass}`}
            onClick={onCoverPick}
          >
            <button onClick={onRemove}>
              <Icon icon={IconType.Cross} />
            </button>
            <Icon icon={IconType.Image} />
            <span>{tpu('cover')}</span>
          </div>
        </div>
      );
    });
  }, [
    checkForEmptiness,
    cover?.key,
    cover?.project_filesID,
    onCover,
    onDelete,
    photos,
    required,
    tpu,
  ]);

  return (
    <div className={`photos-uploader ${className}`}>
      {title ? <h3 className="photos-uploader__title">{title}</h3> : null}
      {description ? (
        <p className="photos-uploader__description">{description}</p>
      ) : null}
      <FileInput
        erase
        multiple
        fillIcon
        disabled={photosOverflow}
        accept={IMAGE_TYPES}
        error={photosError}
        onLink={onPhotoLink}
        onFiles={onPhotoFiles}
        onError={setPhotosError}
        onBlur={() => checkForEmptiness(photos)}
        overflow={MAX_PHOTOS === photos.length}
        className="photos-uploader__input"
        icon={IconType.Image}
      />
      {photos.length ? (
        <>
          <div className="photos-uploader__preview">{thumbnails}</div>
          <span className="photos-uploader__cover-hint">{coverHint}</span>
        </>
      ) : (
        <span className="photos-uploader__annotation">{tfi('photosHint')}</span>
      )}
    </div>
  );
};

export default PhotosUploader;
