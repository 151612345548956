import { useDispatch, useSelector } from 'react-redux';

import { Icon, IconType } from '@platform-for-public-places/components-library';

import {
  changeCadastralLayerErrorVisibility,
  changeCadastralLayerVisibility,
  CONTROL,
  setCurrentCard,
} from 'src/features/control/slices/controlSlice';
import { State } from 'src/features/store/store';

import {
  changeDrawEnable,
  changeNeedClear,
  DRAW,
  resetDraw,
} from '../../slices/drawSlice';

import './DrawControl.scss';

const DrawControl = (): JSX.Element => {
  const dispatch = useDispatch();

  const isDrawEnable = useSelector((s: State) => s[DRAW].isDrawEnable);
  const wasDraw = useSelector((state: State) => !!state[DRAW].drawnShape);
  const showCadastralLayer = useSelector(
    (s: State) => s[CONTROL].showCadastralLayerError
  );

  const onDrawClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (isDrawEnable || wasDraw) {
      dispatch(changeDrawEnable(false));
      dispatch(changeNeedClear());
      dispatch(resetDraw());
    } else {
      dispatch(changeDrawEnable(true));
      dispatch(changeCadastralLayerVisibility(true));
      if (showCadastralLayer === false) {
        dispatch(changeCadastralLayerErrorVisibility(true));
      }
    }
    dispatch(setCurrentCard(null));
  };

  return (
    <button className="draw-control" onClick={onDrawClick}>
      <Icon
        className={`draw-control__icon${
          isDrawEnable || wasDraw ? '--active' : ''
        }`}
        icon={IconType.Pen}
      />
    </button>
  );
};

export default DrawControl;
