import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

import {
  Button,
  BUTTON_TYPE,
  Icon,
  IconType,
} from '@platform-for-public-places/components-library';
import clsx from 'clsx';

import { ReactComponent as Logo } from 'src/app/images/logo.svg';
import { YANDEX_ZEN } from 'src/shared/constants/externalLinks';
import { paths } from 'src/shared/routes';

import { useWhoamiQuery } from 'src/features/auth/api';
import { AUTH_KEY } from 'src/features/auth/api/mockAuthApi';
import ProfilePane from 'src/features/auth/components/ProfilePane/ProfilePane';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import CreateProjectButton from 'src/features/project/components/CreateProjectButton/CreateProjectButton';

import { ReactComponent as Profile } from './images/profile.svg';

import './Header.scss';

interface HeaderProps {
  hideCreation?: boolean;
  className?: string;
}

const Header = ({
  hideCreation = false,
  className,
}: HeaderProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation('app', { keyPrefix: 'header' });

  const { isSuccess } = useWhoamiQuery();

  const isLoggedIn = useMemo(() => {
    const mocked = process.env.REACT_APP_MOCKS_ENABLED === 'true';
    return mocked ? !!localStorage.getItem(AUTH_KEY) : isSuccess;
  }, [isSuccess]);

  const [isPanVisible, setPanVisibility] = useState<boolean>(false);

  const close = () => setPanVisibility(false);

  const onSignInClick = () => {
    dispatch(changeModal(MODALS.SIGN_IN));
  };

  const onProfileClick = () => setPanVisibility(true);

  const renderProfileStuff = () => (
    <>
      <div className="header__profile-wrapper">
        <Button
          className={`header__link header__link--profile${
            isPanVisible ? ' header__link--profile--active' : ''
          }`}
          onClick={onProfileClick}
          type={BUTTON_TYPE.TEXT_DARK}
        >
          <Profile className="header__icon header__icon--profile" />
        </Button>
        {isPanVisible ? <ProfilePane onClose={close} /> : null}
      </div>
    </>
  );

  const renderSignIn = () => (
    <Button
      type={BUTTON_TYPE.TEXT}
      className="header__link header__link--sign-in"
      onClick={onSignInClick}
    >
      <span>{t('signIn')}</span>
    </Button>
  );

  return (
    <header className={clsx('header', className)}>
      <ul className="header__links">
        <li>
          <Link
            to={paths.frontPage}
            className="header__link"
            title={process.env.REACT_APP_VERSION}
          >
            <Logo className="header__logo" />
          </Link>
        </li>
        <li>
          <NavLink to={paths.map} className="header__link">
            {t('map')}
          </NavLink>
        </li>
        <li>
          <NavLink to={paths.projects} className="header__link">
            {t('catalogProject')}
          </NavLink>
        </li>
        <li>
          <NavLink to={paths.members} className="header__link">
            {t('catalogParticipation')}
          </NavLink>
        </li>
        <li>
          <NavLink to={paths.instruction} className="header__link">
            {t('instruction')}
          </NavLink>
        </li>
        <li>
          <NavLink to={paths.reporting} className="header__link">
            {t('reporting')}
          </NavLink>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href={YANDEX_ZEN}
            className="header__link"
          >
            <Icon icon={IconType.YandexZen} className="header__link-icon" />
            {t('bestPractices')}
          </a>
        </li>
      </ul>

      <div className="header__buttons">
        {isLoggedIn ? renderProfileStuff() : renderSignIn()}
        {!hideCreation && <CreateProjectButton text={t('createProject')} />}
      </div>
    </header>
  );
};

export default Header;
