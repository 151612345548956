import { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import {
  base64ContentType,
  base64DocumentHeader,
  GetFilesResponse,
  MIME_JPEG,
  MIME_PNG,
} from '@platform-for-public-places/components-library';
import { Buffer } from 'buffer';

import { ARCHIVED_MARK } from 'src/app/constants';

import { useLazyGeneratePresignedUrlsQuery } from 'src/features/minIo/api';
import { useUploadFileMutation } from 'src/features/minIo/api/minIoApi';
import {
  useDeleteProjectSpecialistMutation,
  useLazyCreateProjectSpecialistQuery,
} from 'src/features/project/api';
import { REQUEST_STATUS } from 'src/features/request/enums';
import {
  FILTER_DATA,
  setTeam,
  setTeamPrevious,
} from 'src/features/searchWithFilter/filter/slices/filterSliceData';
import { State } from 'src/features/store/store';
import { ProjectSpecialist } from 'src/features/user/models';

const MAX_HEIGHT = 2000;
const MAX_WIDTH = 2000;

interface ResolvedFile {
  data: string;
  name: string;
  type: string;
}

const optimizeImage = (img: HTMLImageElement) => {
  const canvas = document.createElement('canvas');

  let width, height;
  if (img.width < MAX_WIDTH && img.height < MAX_HEIGHT) {
    width = img.width;
    height = img.height;
  } else if (img.width > img.height) {
    width = MAX_WIDTH;
    height = (img.height * width) / img.width;
  } else {
    height = MAX_HEIGHT;
    width = (img.width * height) / img.height;
  }

  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');
  ctx?.drawImage(img, 0, 0, width, height);

  return canvas.toDataURL(MIME_JPEG, 0.8);
};

const promiseCreator = (file: File): Promise<ResolvedFile> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = ({ target }) => {
      if (target?.result) {
        // It's always a base64 encoded string, not an ArrayBuffer,
        // because we use readAsDataURL, not readAsArrayBuffer
        const result = target.result as string;
        if (file.type === MIME_JPEG || file.type === MIME_PNG) {
          const image = new Image();
          image.onload = () => {
            resolve({
              data: optimizeImage(image),
              name: file.name,
              type: file.type,
            });
          };
          image.onerror = (error) => reject(error);
          image.src = result;
        } else {
          resolve({
            data: result,
            name: file.name,
            type: file.type,
          });
        }
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export const useUploadFileFromLink = (
  mimeTypes: string[],
  setFile: (v: GetFilesResponse) => void,
  setError: (v: SetStateAction<string>) => void
): ((arg0: string) => void) => {
  const { t } = useTranslation();
  const [uploadFile] = useUploadFileMutation();
  const [generatePresignedUrls] = useLazyGeneratePresignedUrlsQuery();

  return (link: string) => {
    if (link) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', link, true);
      xhr.onload = () => {
        const blobType = (xhr.response as Blob).type;
        const contentType = xhr.getResponseHeader('Content-Type');
        const mime = blobType || contentType;
        if (mime && (!mimeTypes.length || mimeTypes.includes(mime))) {
          const reader = new FileReader();
          reader.readAsDataURL(xhr.response);
          reader.onloadend = () => {
            generatePresignedUrls({ urlCount: 1 }).then((res) => {
              const dataUrl = reader.result as string;
              if (mime === MIME_PNG || mime === MIME_JPEG) {
                const image = new Image();
                image.src = dataUrl;
                image.onload = () => {
                  const convertedImage = optimizeImage(image);
                  generatePresignedUrls({ urlCount: 1 }).then((res) => {
                    setFile({
                      url: convertedImage,
                      name: link,
                      key: res.data?.data[0]?.key as string,
                    });
                    const data = Buffer.from(
                      convertedImage.replace(base64DocumentHeader, ''),
                      'base64'
                    );
                    uploadFile({
                      tempUrl: res.data?.data[0]?.url as string,
                      data,
                      contentType: MIME_JPEG,
                    });
                  });
                };
              } else {
                setFile({
                  url: dataUrl,
                  name: link,
                  key: res.data?.data[0]?.key as string,
                });
                const data = Buffer.from(
                  dataUrl.replace(base64DocumentHeader, ''),
                  'base64'
                );
                uploadFile({
                  tempUrl: res.data?.data[0]?.url as string,
                  data,
                  contentType: contentType ?? '',
                });
              }
            });
          };
        } else {
          setError(t('photosUploader.errors.badFormat'));
        }
      };
      xhr.onerror = () => setError(t('photosUploader.errors.badFormat'));
      xhr.responseType = 'blob';
      xhr.send();
    }
  };
};

export const useUploadFiles = (
  mimeTypes: string[],
  setFiles: (v: GetFilesResponse[]) => void,
  setError: (v: SetStateAction<string>) => void,
  errorText?: string
) => {
  const { t } = useTranslation();
  const [uploadFile] = useUploadFileMutation();
  const [generatePresignedUrls] = useLazyGeneratePresignedUrlsQuery();

  return (files: File[], remained: number) => {
    if (!files.length) return;
    setError('');

    if (remained <= 0) return;

    const promises: Promise<ResolvedFile>[] = [];
    for (let i = 0; i < Math.min(remained, files.length); i++) {
      const file = files[i];
      if (!mimeTypes.length || mimeTypes.includes(file.type)) {
        promises.push(promiseCreator(file));
      } else {
        setError(errorText ?? t('photosUploader.errors.badFormat'));
      }
    }

    Promise.all(promises).then((files) => {
      generatePresignedUrls({ urlCount: files.length }).then((res) => {
        setFiles(
          files.map((file, index) => {
            const data = Buffer.from(
              file.data.replace(base64DocumentHeader, ''),
              'base64'
            );
            const contentType = file.data.match(base64ContentType)?.[0];
            uploadFile({
              tempUrl: res.data?.data[index]?.url as string,
              data,
              contentType: contentType ?? '',
            });
            return {
              url: file.data,
              name: file.name,
              key: res.data ? res.data.data[index].key : '',
            };
          })
        );
      });
    });
  };
};

export const useRequestSaveTeam = (
  projectId?: string
): ((value: ProjectSpecialist[]) => Promise<void>) => {
  const dispatch = useDispatch();

  const team = useSelector((s: State) => s[FILTER_DATA].team);
  const teamPrevious = useSelector((s: State) => s[FILTER_DATA].teamPrevious);

  const [createProjectSpecialist] = useLazyCreateProjectSpecialistQuery();
  const [deleteProjectSpecialist] = useDeleteProjectSpecialistMutation();

  const filterSpecialists = (
    team: ProjectSpecialist[]
  ): {
    toAdd: ProjectSpecialist[];
    toDelete: ProjectSpecialist[];
  } => {
    const toAdd = [];
    const toDelete = [];

    const length =
      team.length > teamPrevious.length ? team.length : teamPrevious.length;
    for (let i = 0; i < length; i++) {
      if (team[i] && !teamPrevious.includes(team[i])) {
        toAdd.push(team[i]);
      }
      if (teamPrevious[i] && !team.includes(teamPrevious[i])) {
        toDelete.push(teamPrevious[i]);
      }
    }
    return { toAdd, toDelete };
  };

  const modify = async (specialists: {
    toAdd: ProjectSpecialist[];
    toDelete: ProjectSpecialist[];
  }) => {
    if (projectId) {
      const updatedTeam = [...team];
      const promises = specialists.toAdd.map((specialist) =>
        specialist.roles
          ? createProjectSpecialist({
              projectId: projectId,
              userId: specialist.userId,
              roles: specialist.roles,
            }).then((response) => {
              const addedSpecialist = {
                ...specialist,
                id: response.data?.data.projectSpecialistId ?? specialist.id,
                requestStatus: REQUEST_STATUS.ACTIVE,
              };
              updatedTeam[
                team.findIndex((user) => user.userId === specialist.userId)
              ] = addedSpecialist;
            })
          : null
      );
      specialists.toDelete.map((specialist) =>
        specialist.id
          ? deleteProjectSpecialist({
              projectSpecialistId: specialist.id,
            })
          : null
      );

      Promise.all(promises).then(() => {
        dispatch(setTeamPrevious(updatedTeam));
        dispatch(setTeam(updatedTeam));
      });
    }
  };

  const modifyTeam = (team: ProjectSpecialist[]) => {
    const specialist = filterSpecialists(team);
    return modify(specialist);
  };

  return modifyTeam;
};

export const useIsArchived = () => {
  const [searchParams] = useSearchParams();
  return searchParams.get(ARCHIVED_MARK) === 'true';
};
