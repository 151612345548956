import './SearchInput.scss';

export interface SearchInputProps {
  className?: string;
  maxLength?: number;
  placeholder?: string;
  value: string;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
}

const SearchInput = ({
  className = '',
  maxLength,
  placeholder,
  value,
  onChange,
  onFocus,
}: SearchInputProps) => {
  return (
    <input
      type="text"
      className={`search-input ${className}`}
      placeholder={placeholder}
      maxLength={maxLength}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
};

export default SearchInput;
