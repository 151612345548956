import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  TextInput,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';

import TextEditor from 'src/shared/components/TextEditor/TextEditor';
import { paths } from 'src/shared/routes';

import StageHeader from 'src/features/layout/components/StageHeader/StageHeader';

import './ProjectIdea.scss';

export interface IdeaStateProp<T> {
  value: T;
  setter: (value: T) => void;
}

export interface ProjectIdeaData {
  name: IdeaStateProp<string>;
  idea: IdeaStateProp<string>;
}

export interface ProjectIdeaErrors {
  name: IdeaStateProp<string>;
  idea: IdeaStateProp<string>;
}

export interface ProjectIdeaProps {
  className?: string;
  fields: ProjectIdeaData;
  errors: ProjectIdeaErrors;
  onChange?: (changed: boolean) => void;
}

export enum ERRORS {
  EMPTY = 'errors.empty',
  TOO_LONG_NAME = 'errors.tooLongName',
  TOO_LONG_IDEA = 'errors.tooLongIdea',
  ALREADY_EXISTS = 'errors.alreadyExists',
}

const STAGE = 2;
const WAR_NAME_SYMBOLS = 100;
const MAX_NAME_SYMBOLS = 500;
const MAX_IDEA_SYMBOLS = 3000;
export const ACCEPTABLE_IDEA_REGEX =
  /^<[p|h]{1}[1-3]{0,1}>[<br>|\s]+<\/[p|h]{1}[1-3]{0,1}>$/;

const ProjectIdea = ({
  className = '',
  fields,
  errors,
  onChange,
}: ProjectIdeaProps): JSX.Element => {
  const isSmallScreen = useCheckSmallScreen();
  const { t } = useTranslation('app', { keyPrefix: 'creation.idea' });

  const [nameWarning, setNameWarning] = useState<string>('');

  const checkNameInput = () => {
    const trimmedName = fields.name.value.trim();

    if (trimmedName.length) {
      errors.name.setter('');
    } else {
      errors.name.setter(t(ERRORS.EMPTY));
    }

    if (trimmedName.length > WAR_NAME_SYMBOLS) {
      setNameWarning(t(ERRORS.TOO_LONG_NAME));
    } else {
      setNameWarning('');
    }
  };

  const checkIdeaInput = () => {
    const trimmedIdea = fields.idea.value.trim();

    if (trimmedIdea.length === 0 || trimmedIdea.match(ACCEPTABLE_IDEA_REGEX)) {
      errors.idea.setter(t(ERRORS.EMPTY));
    } else if (trimmedIdea.length > MAX_IDEA_SYMBOLS) {
      errors.idea.setter(t(ERRORS.TOO_LONG_IDEA));
    } else {
      errors.idea.setter('');
    }
  };

  const onNameChange = (value: string) => {
    setNameWarning('');
    errors.name.setter('');
    fields.name.setter(value);
    onChange?.(true);
  };

  const onIdeaChange = (value: string) => {
    errors.idea.setter('');
    fields.idea.setter(value);
    if ('' !== fields.idea.value) {
      onChange?.(true);
    }
  };

  const instructionsLink = (
    <Link
      className="text-link project-idea__link"
      to={paths.instruction}
      target="_blank"
    />
  );

  return (
    <div className={`project-idea ${className}`}>
      <StageHeader
        className="project-idea__title"
        header={`${isSmallScreen ? `${STAGE}. ` : ''}${t('title')}`}
        subheader={t('nameDescription')}
      />

      <div className="project-idea__input-wrapper--line">
        <TextInput
          value={fields.name.value}
          maxLength={MAX_NAME_SYMBOLS}
          placeholder={t('namePlaceholder')}
          onChange={(e) => onNameChange(e.target.value)}
          onFocusLeave={checkNameInput}
        />
        <span
          className={`project-idea__error${
            errors.name.value ? '' : '--hidden'
          }`}
        >
          {errors.name.value}
        </span>
        <span
          className={`project-idea__warning${nameWarning ? '' : '--hidden'}`}
        >
          {nameWarning}
        </span>
      </div>

      <p className="project-idea__idea-description">
        <Trans
          i18nKey="creation.idea.ideaDescription"
          components={{ lnk: instructionsLink }}
        />
      </p>
      <div className="project-idea__input-wrapper--area">
        <TextEditor
          className="project-idea__input"
          placeholder={t('ideaPlaceholder')}
          value={fields.idea.value}
          onChange={onIdeaChange}
          maxLength={MAX_IDEA_SYMBOLS}
          onBlur={checkIdeaInput}
        />
        <span
          className={`project-idea__error${
            errors.idea.value ? '' : '--hidden'
          }`}
        >
          {errors.idea.value}
        </span>
      </div>
    </div>
  );
};

export default ProjectIdea;
