import { Navigate } from 'react-router-dom';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';

import { paths } from 'src/shared/routes';

import RecoverPasswordSuccessForm from 'src/features/auth/components/RecoveryPasswordSuccess/RecoveryPasswordSuccess';

import './RecoveryPasswordSuccess.scss';

const RecoveryPasswordSuccess = () => {
  const isSmallScreen = useCheckSmallScreen();

  return isSmallScreen ? (
    <div className="recovery-password-success-page">
      <RecoverPasswordSuccessForm />
    </div>
  ) : (
    <Navigate to={paths.map} replace />
  );
};

export default RecoveryPasswordSuccess;
