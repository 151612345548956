import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Icon,
  IconType,
  useCheckSmallScreen,
} from '@platform-for-public-places/components-library';
import { ru } from 'date-fns/locale';

import { useWhoamiQuery } from 'src/features/auth/api';
import { USER_ROLE } from 'src/features/auth/models';
import { REQUEST_STATUS } from 'src/features/request/enums';
import { BaseRequestData } from 'src/features/request/models';

import {
  Comment as CommentType,
  CommentAndRequest,
  CommentRequestBody,
} from '../../models';

import './Comment.scss';

interface CommentProps {
  comment: CommentType;
  request?: BaseRequestData<CommentRequestBody>;
  className?: string;
  archived?: boolean;
  onEdit: (arg: CommentAndRequest) => void;
  onDelete: (commentId: string) => void;
}

const TEN = 10;

const Comment = ({
  comment,
  request,
  className = '',
  archived = false,
  onEdit,
  onDelete,
}: CommentProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'comments' });
  const mobile = useCheckSmallScreen();
  const {
    isSuccess: authorized,
    data: user,
    isFetching: fetching,
  } = useWhoamiQuery();

  const [open, setOpen] = useState<boolean>(false);

  const renderPendingWarning = () => (
    <div className="comment__warning--pending">
      <Icon icon={IconType.Error} />
      <span className="comment__warning-text--pending">
        {t('pendingMessage')}
      </span>
    </div>
  );

  const renderRejectedWarning = () => (
    <div className="comment__warning--denied">
      <Icon icon={IconType.Cross} />
      <p className="comment__warning-text--denied">
        {t('deniedMessage')}
        <span>{request?.message}</span>
      </p>
    </div>
  );

  const warningRenderMap = {
    [REQUEST_STATUS.EXECUTED]: () => null,
    [REQUEST_STATUS.ACTIVE]: renderPendingWarning,
    [REQUEST_STATUS.REJECTED]: renderRejectedWarning,
  };

  const renderWarning =
    warningRenderMap[request?.status || REQUEST_STATUS.EXECUTED];

  const editComment = () => {
    setOpen(false);
    onEdit({ comment, request });
  };

  const deleteComment = () => {
    setOpen(false);
    onDelete(comment.project_commentID);
  };

  const initiator = user?.data.userID === request?.initiatorId;
  const moderator = user?.data.role === USER_ROLE.ADMIN;
  const executed =
    !request?.status || request?.status === REQUEST_STATUS.EXECUTED;

  const haveActionsAccess =
    !archived && !fetching && authorized && (initiator || moderator);

  const renderActions = () => {
    if (haveActionsAccess) {
      if (mobile) {
        return (
          <div className="comment__actions">
            <button
              className="comment__actions-trigger"
              onClick={() => setOpen((p) => !p)}
            >
              <Icon icon={IconType.Dots} />
            </button>
            <div className={`comment__actions-popup${open ? '--visible' : ''}`}>
              {executed ? null : (
                <button
                  className="comment__action-button"
                  onClick={editComment}
                >
                  <Icon
                    className="comment__action-icon"
                    icon={IconType.Pencil}
                  />
                  <span className="comment__action-label">{t('edit')}</span>
                </button>
              )}
              <button
                className="comment__action-button"
                onClick={deleteComment}
              >
                <Icon className="comment__action-icon" icon={IconType.Bin} />
                <span className="comment__action-label">{t('delete')}</span>
              </button>
            </div>
          </div>
        );
      } else {
        return (
          <div className="comment__actions">
            {executed ? null : (
              <button className="comment__action-button" onClick={editComment}>
                <Icon className="comment__action-icon" icon={IconType.Pencil} />
              </button>
            )}
            <button className="comment__action-button" onClick={deleteComment}>
              <Icon className="comment__action-icon" icon={IconType.Bin} />
            </button>
          </div>
        );
      }
    } else {
      return null;
    }
  };

  const mainClass = `comment ${
    comment.approved ? '' : 'comment--pending'
  } ${className}`;

  const parseDateTime = () => {
    const appendZero = (t: number) => (t < TEN ? `0${t}` : t);

    // n - now, m - moment
    const n = new Date();
    const m = new Date(comment.createdAt);

    const date = m.getDate();
    const time = `${appendZero(m.getHours())}:${appendZero(m.getMinutes())}`;
    const year = m.getFullYear();

    const thisYear = year === n.getFullYear();
    const thisMonth = m.getMonth() === n.getMonth();
    const today = thisYear && thisMonth && date === n.getDate();
    const yesterday = thisYear && thisMonth && date === n.getDate() - 1;

    // Today, yesterday, or date-month
    const head = () => {
      if (today) {
        return t('today');
      } else if (yesterday) {
        return t('yesterday');
      } else {
        return new Intl.DateTimeFormat(ru.code, {
          day: 'numeric',
          month: 'long',
        }).format(m);
      }
    };

    // Time for current year, and year for other
    const tail = thisYear ? time : year;

    return `${head()}, ${tail}`;
  };

  return (
    <div className={mainClass}>
      <div className="comment__header--wrapper">
        <div className="comment__header">
          <h6 className="comment__author">{comment.name}</h6>
          <span className="comment__time">{parseDateTime()}</span>
        </div>
        {renderActions()}
      </div>
      <p className="comment__text">
        {comment.approved ? comment.text : request?.requestBody.comment.text}
      </p>
      {renderWarning()}
    </div>
  );
};

export default Comment;
