import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useSelector } from 'react-redux';

import { useCheckSmallScreen } from '@platform-for-public-places/components-library';
import { useLocalStorage } from '@uidotdev/usehooks';
import { latLngBounds } from 'leaflet';

import { COOKIE } from 'src/features/cookies/slices/cookieSlice';
import { PROJ_CREATE } from 'src/features/project/slices/creatingProjectSlice';
import { PROJ_LAYER } from 'src/features/project/slices/projectsLayerSlice';
import { State } from 'src/features/store/store';

const ZOOM = 19;

const MapProjectZoom = (): null => {
  const map = useMap();
  const mobile = useCheckSmallScreen();

  const center = useSelector((s: State) => s[PROJ_CREATE].centerEdit);
  const projectGeodata = useSelector((s: State) => s[PROJ_LAYER].geodata);

  const [acceptedCookie] = useLocalStorage(COOKIE);

  useEffect(() => {
    if (center?.geometry?.coordinates) {
      map.setView(
        {
          lat: center.geometry.coordinates[1],
          lng: center.geometry.coordinates[0],
        },
        ZOOM
      );
    }
  }, [center, map]);

  useEffect(() => {
    if (mobile && acceptedCookie && projectGeodata) {
      map.fitBounds(
        latLngBounds(
          projectGeodata.polygon.geometry.coordinates[0].map((c) => [
            c[1],
            c[0],
          ])
        )
      );
    }
  }, [projectGeodata, map, mobile, acceptedCookie]);

  return null;
};

export default MapProjectZoom;
