import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { EMPTY_EDITOR_STRING } from 'src/app/constants';
import { htmlToMarkdown } from 'src/shared/converters';
import { paths } from 'src/shared/routes';
import { ideaWidgetsMap } from 'src/shared/widgets/idea';

import { useLazyCreateDescriptionQuery } from 'src/features/project/api';
import {
  PROJ_CREATE,
  setIdea,
  setName,
} from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import { CreationOutletProps } from 'src/pages/layouts/CreationLayout/models';
import { ACCEPTABLE_IDEA_REGEX } from 'src/pages/project/ProjectIdea/ProjectIdea';

const CreationIdea = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { renderFooter }: CreationOutletProps = useOutletContext();

  const projectName = useSelector((s: State) => s[PROJ_CREATE].name);
  const projectIdea = useSelector((s: State) => s[PROJ_CREATE].idea);

  const currentStep = useSelector(
    (s: State) => s[PROJ_CREATE].currentCreationStep
  );
  const stepConfigs = useSelector(
    (s: State) => s[PROJ_CREATE].configCreationSteps
  );

  const [createDescription] = useLazyCreateDescriptionQuery();

  useEffect(() => {
    if (stepConfigs.length === 0) {
      navigate(paths.creation);
    }
  }, [navigate, stepConfigs]);

  const updateDescription = useCallback(
    (projectId: string) => {
      const name = projectName.trim();
      const idea = projectIdea.trim();
      createDescription({ projectId, name, idea: htmlToMarkdown(idea) }).then(
        (result) => {
          if (result.isSuccess) {
            dispatch(setName(name));
            dispatch(setIdea(idea));
          }
        }
      );
    },
    [createDescription, dispatch, projectIdea, projectName]
  );

  const IdeaWidgetComponent = ideaWidgetsMap.get(
    stepConfigs[currentStep] ? stepConfigs[currentStep].type : ''
  );

  // Project idea contains at least <p><br></p> string even if there is no text.
  // Due to that fact, serialized idea have length = 1 for empty field.
  const valid = useMemo(
    () =>
      !!projectName.trim().length &&
      !!projectIdea.trim().length &&
      !projectIdea.match(ACCEPTABLE_IDEA_REGEX) &&
      projectIdea !== EMPTY_EDITOR_STRING,
    [projectIdea, projectName]
  );

  return (
    <>
      {IdeaWidgetComponent?.({ stepConfig: stepConfigs[currentStep] }) || null}
      {renderFooter?.({
        updateStepData: updateDescription,
        valid,
        className: 'creation-idea__footer',
      })}
    </>
  );
};

export default CreationIdea;
