import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { EMPTY_EDITOR_STRING } from 'src/app/constants';
import { htmlToMarkdown, markdownToHtml } from 'src/shared/converters';
import { paths } from 'src/shared/routes';
import { ideaWidgetsMap } from 'src/shared/widgets/idea';

import {
  useGetProjectDescriptionQuery,
  useLazyCreateDescriptionQuery,
} from 'src/features/project/api';
import {
  PROJ_CREATE,
  setIdea,
  setName,
} from 'src/features/project/slices/creatingProjectSlice';
import { State } from 'src/features/store/store';

import { EditingOutletProps } from 'src/pages/layouts/EditLayout/models';
import { useIsArchived } from 'src/pages/project/hooks';
import { ACCEPTABLE_IDEA_REGEX } from 'src/pages/project/ProjectIdea/ProjectIdea';

const STEP_NAME = 'idea';

const EditingIdea = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { renderFooter }: EditingOutletProps = useOutletContext();

  const archived = useIsArchived();

  const stepsConfig = useSelector(
    (s: State) => s[PROJ_CREATE].configEditingSteps
  );
  const ideaStep = stepsConfig.find((step) => step.name === STEP_NAME);

  const projectId = useSelector((s: State) => s[PROJ_CREATE].projectId);

  const projectName = useSelector((s: State) => s[PROJ_CREATE].name);
  const projectIdea = useSelector((s: State) => s[PROJ_CREATE].idea); // always in markdown

  const {
    data: projectDescription,
    isLoading: isDescriptionLoading,
    refetch,
  } = useGetProjectDescriptionQuery(
    { projectId: projectId as string, archived },
    { skip: !projectId }
  );

  const [createDescription] = useLazyCreateDescriptionQuery();

  const initData = () => {
    if (projectDescription && !isDescriptionLoading) {
      dispatch(setName(projectDescription.data.description.name));
      dispatch(
        setIdea(markdownToHtml(projectDescription.data.description.idea))
      );
    }

    return () => {
      dispatch(setName(''));
      dispatch(setIdea(''));
    };
  };

  // The following `useEffect` is needed for correct initialization of fields if they are empty.
  // Disabling the linter prevents a repeated request after updating the data
  useEffect(initData, [dispatch, isDescriptionLoading, projectDescription]);

  const onCancel = () => {
    dispatch(setName(projectDescription?.data.description.name ?? ''));
    dispatch(
      setIdea(markdownToHtml(projectDescription?.data.description.idea ?? ''))
    );
  };

  const onSave = () => {
    if (projectId) {
      const name = projectName.trim();
      const idea = projectIdea.trim();
      createDescription({
        projectId,
        name,
        idea: htmlToMarkdown(idea),
      }).then((result) => {
        if (result.isSuccess) {
          dispatch(setName(name));
          dispatch(setIdea(idea));
          refetch();
        }
      });
    }
  };

  // Project idea contains at least <p><br></p> string even if there is no text.
  // Due to that fact, serialized idea have length = 1 for empty field.
  const valid = useMemo(
    () =>
      !!projectName.trim().length &&
      !!projectIdea.trim().length &&
      !projectIdea.match(ACCEPTABLE_IDEA_REGEX) &&
      projectIdea !== EMPTY_EDITOR_STRING,
    [projectIdea, projectName]
  );

  const modified = useMemo(() => {
    if (projectIdea || projectName) {
      if (!projectDescription) {
        return true;
      } else if (projectDescription && !isDescriptionLoading) {
        return (
          projectDescription.data.description.name !== projectName ||
          projectDescription.data.description.idea.trim() !==
            htmlToMarkdown(projectIdea).trim()
        );
      }
    }
    return false;
  }, [projectDescription, isDescriptionLoading, projectName, projectIdea]);

  if (!ideaStep) {
    navigate(paths.map);
  }

  const IdeaWidgetComponent = ideaWidgetsMap.get(ideaStep?.type ?? '');

  return (
    <>
      {ideaStep ? IdeaWidgetComponent?.({ stepConfig: ideaStep }) : null}
      {renderFooter?.({ onCancel, onSave, valid, modified })}
    </>
  );
};

export default EditingIdea;
